import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import Pagination from '../../common/Pagination';
import moment from 'moment';
import '../../styles/GlobalStyles.css'

const dateFormat = "YYYY-MM-DD" 
const todayYMD = moment().format(dateFormat) 

export default class DayEnd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salesReportList: [],
            startTime: todayYMD,
            endTime: todayYMD,
            billingListAfterPagination: [],
            startTimeDayEndReport: "",
            endTimeDayEndReport: "",

            // for pagination
            current_page: 1,
            records_per_page: 10,
            // for pagination, from get data
            total_count: 0,
            no_of_pages: 0
        };

        this.service = new Service();
        this._paginationChangedValues = this._paginationChangedValues.bind(this) 
    }

    componentDidMount() {
        this._getDayEnd()
    }

    componentWillReceiveProps(props) {
        if ( props.isclear && (props.startTime!=this.state.startTime || props.endTime!=this.state.endTime)) {
            this.setState({
                startTime: props.startTime,
                endTime: props.endTime,
                current_page: 1,
                records_per_page: 10
            }, () => {
                this._getDayEnd()
                props.handlepropsvalueclear(false) 
            })
        }else if ((props.startTimeDayEndReport!=this.state.startTimeDayEndReport || props.endTimeDayEndReport!=this.state.endTimeDayEndReport)) {
            this.setState({
                startTimeDayEndReport: props.startTimeDayEndReport,
                endTimeDayEndReport: props.endTimeDayEndReport,
                current_page: 1,
                records_per_page: 10
            }, () => {
                this._getDayEnd()
                props.handlepropsvalueclear(false) 
            })
        }
    }

    // Get Api 
    _getDayEnd() {

        var url = ""
        let { current_page, records_per_page } = this.state;

        url = Constants.GET_DAYEND_REPORT+ "?page="+current_page+"&page_size="+records_per_page;

        if (this.state.startTime && this.state.endTime) {
            url = url + "&from_date=" + this.state.startTime + "&to_date=" + this.state.endTime+ "?page=" + current_page+"&page_size="+records_per_page;
        }
        if (this.state.startTimeDayEndReport && this.state.endTimeDayEndReport) {
            url = url + "&start_time=" + this.state.startTimeDayEndReport + "&end_time=" + this.state.endTimeDayEndReport;
        }

        this.service.getApiCall(
            url,
            this._getDayEndSuccess,
            this._getDayEndFailure
        )
    }

    _getDayEndSuccess = response => {

        let { salesReportList } = this.state;

        if (response.status === "success") {

            salesReportList = response.data.result
         
            this.setState({
                salesReportList,
                no_of_pages: response.data?response.data.no_of_pages:0,
                total_count: response.data?response.data.total_count:0
            })

            var data = {
                "total_amount": response.data.total_amount,
                "cash_total": response.data.cash_total,
                "card_total": response.data.card_total,
                "upi_total": response.data.upi_total,
                "bank_transfer_total": response.data.bank_transfer_total,
                "insurance_total": response.data.insurance_total,
                "return_total":response.data.total_return_amount
            }
            this.props.gettotalscoreValues("Day End", data)

        }
    };

    _getDayEndFailure = error => {

        console.log(error);

    };
    // Report List Views Function
    renderContent() {
        return (
            // Report List Views
            <View style={{ marginHorizontal: 20, backgroundColor: "#F9F9F9", marginTop: 15 }}>
                {this.renderContentRow()}



                {/* End Report List View */}

            </View>
        )
    }
    // Common History Section Header function
    renderHeader() {
        return (

            <View style={styles.HeaderView}>
                <CommonReportSectionHeader
                    heading1={"S.No"}
                    heading2={"Bill Date"}
                    heading3={"Bill Number"}
                    heading4={"Cash"}
                    heading5={"Card"}
                    heading6={"UPI"}
                    heading7={"Bank Transfer"}
                    heading8={"Insurance"}
                    heading9={"Outstanding Amount"}
                    heading10={"Total"}
                    columnSize={[0.10, 0.10, 0.10, 0.10, 0.10, 0.10, 0.10, 0.10, 0.13, 0.007]}  //total value is == of 1
                    noOfColumn={10}

                />
            </View>
        )
    }


    paginationChangedValues(data) {
        this.setState({
            billingListAfterPagination: data
        })
    }


    // Render Content Row function Key and 



    renderContentRow() {
        if (this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.length < 11) {

            return (
                <View style={{}}>
                    {
                        this.state.salesReportList && this.state.salesReportList.map((item, index) => {
                            let invoice_date = item.invoice_date?moment(item.invoice_date).format('DD-MM-YYYY'):""
                            return (
                                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0 }}>
                                    <View style={[{ flex: 0.10 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{invoice_date}</Text></View>
                                    <View style={[[{ flex: 0.10, marginLeft: 20 }]]}><Text>{item.invoice_number}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.cash}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.card}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.upi}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.bank_transfer}</Text></View>
                                    <View style={[{ flex: 0.12 }]}><Text>{item.total}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.total}</Text></View>
                                    {/* 0.1, 0.2, 0.1, 0.2, 0.1, 0.2, 0.1, */}
                                </View>
                            )
                        })}



                </View>
            )
        }
        else if (this.state.salesReportList && this.state.salesReportList.length > 0) {
            return (
                <View style={{}}>
                    {
                        this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
                            return (
                                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0 }}>
                                    <View style={[{ flex: 0.10 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.invoice_date}</Text></View>
                                    <View style={[[{ flex: 0.10, marginLeft: 20 }]]}><Text>{item.invoice_number}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.cash}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.card}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.upi}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.bank_transfer}</Text></View>
                                    <View style={[{ flex: 0.12 }]}><Text>{item.total}</Text></View>
                                    <View style={[{ flex: 0.10 }]}><Text>{item.total}</Text></View>
                                    {/* 0.1, 0.2, 0.1, 0.2, 0.1, 0.2, 0.1, */}
                                </View>
                            )
                        })}



                </View>
            )
        }

        else {
            return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100, alignItems: 'center' }}>
                <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
            </View>
        }
    }

    _paginationChangedValues(next_page, records_per_page_change) {

        let { current_page, records_per_page } = this.state
    
        if( current_page != next_page  || records_per_page!=records_per_page_change) {
            
            this.setState({
                records_per_page: records_per_page_change,
                current_page: next_page
            }, ()=>{
                this._getDayEnd()
            })
        }
    }
    
    render() {
        
        let { total_count, no_of_pages } = this.state

        total_count = parseInt(total_count) || 0

        return (
            <View>
                {this.renderHeader()}
                {this.renderPaginateContent()}
                {
                    total_count > 0 ?
                        <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
                        type={Constants.PAGINATION_TYPE_SERVER} 
                        totalServerRecordsCount={total_count} 
                        totalServerPagesCount={no_of_pages} />
                        : null
                }
            </View>
        );
    }

    renderPaginateContent() { 

        let { current_page, records_per_page } = this.state
    
        if(this.state.salesReportList && this.state.salesReportList.length > 0) {
          return (
            // Report List Views
            <View style={styles.TableDataContainer}>   
              {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
                  let sno = (current_page-1)*records_per_page + index + 1; 
                  let invoice_date = item.invoice_date?moment(item.invoice_date).format('DD-MM-YYYY'):""
                  return (
                    <View key={sno} style={styles.TableDataWrapper}>
                        <View style={[{ flex: 0.10, marginLeft: "1.30vw" }]}><Text style={styles.TableDataText}>{sno}</Text></View>
                        <View style={[{ flex: 0.10 }]}><Text style={styles.TableDataText}>{invoice_date}</Text></View>
                        <View style={[{ flex: 0.10 }]}><Text style={styles.TableDataText}>{item.invoice_number}</Text></View>
                        <View style={[{ flex: 0.10 }]}><Text style={styles.TableDataText}>{parseFloat(item.cash).toFixed(2)}</Text></View>
                        <View style={[{ flex: 0.10 }]}><Text style={styles.TableDataText}>{parseFloat(item.card).toFixed(2)}</Text></View>
                        <View style={[{ flex: 0.10 }]}><Text style={styles.TableDataText}>{parseFloat(item.upi).toFixed(2)}</Text></View>
                        <View style={[{ flex: 0.10 }]}><Text style={styles.TableDataText}>{parseFloat(item.bank_transfer).toFixed(2)}</Text></View>
                        <View style={[{ flex: 0.10 }]}><Text style={styles.TableDataText}>{parseFloat(item.insurance).toFixed(2)}</Text></View>
                        <View style={[{ flex: 0.12 }]}><Text style={styles.TableDataText}>{item.invoice_number?"0":item.total}</Text></View>
                        <View style={[{ flex: 0.07 }]}><Text style={styles.TableDataText}>{parseFloat(item.total).toFixed(2)}</Text></View>
                        {/* 0.1, 0.2, 0.1, 0.2, 0.1, 0.2, 0.1, */}
                    </View>
                  )
    
                })
              }
            </View>
          )
        }else{
          return(
            <View style={styles.NoRecordsView}>
              <Text style={styles.NorecordsText}>{"No records to be shown"}</Text>
            </View>
          )
        }
    }
}

const styles = StyleSheet.create({
    HeaderView:{
       marginHorizontal: '1.30vw', marginTop: '0.65vw' 
    },
    TableDataText: {
       fontSize:'1vw'
    },
    TableDataContainer:{
       marginHorizontal: '1.30vw', backgroundColor: "#F9F9F9", marginTop: '0.97vw' 
    },
    TableDataWrapper:{
        flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: '1.30vw', marginHorizontal: 0
    },
    NoRecordsView:{
      flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: '9.76vw'
    },
    NorecordsText:{
      color:"#8888888",
      fontSize:'1vw'
    },
  
  });
  
