import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, TextInput, Dimensions, TouchableWithoutFeedback } from 'react-native';
import { color } from '../styles/Color';
import Constants from "../networks/Constants";
import Service from '../networks/service'; 
import Pagination from "../common/Pagination"
import { SuccessAlert, ErrorAlert, CommonReportSectionHeader, CommonDateFilter } from '../common/BaseFunctionComponent'
import Icon from "react-native-vector-icons/Entypo";


const screenHeight = Dimensions.get("window").height;


export default class CreditHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditPatientList : [],
      totalOutStanding : 0,
      creditPatientListAfterPagination : [],
      searchText : "",
      totalPaid : 0,
      totalBill : 0,
      isNoBillPopUp : false,
      // datefilter
      fromDate: new Date().getFullYear() + "-" + parseInt(new Date().getMonth() + 1) + "-" + new Date().getDate(),
      toDate: new Date().getFullYear() + "-" + parseInt(new Date().getMonth() + 1) + "-" + new Date().getDate(),
      filterDate: "",
      showDateFilter: false,
      defaultSelectedDateFilter: "Today",
      currentPage: 1,
      recordsPerPage: 10
    };
    this.service = new Service()
  }
  componentDidMount() {
    {this._getCreditPatientList()}
  }
  _getCreditPatientList = () => {
    let mobileNumber = ""
    let patient_account_number = ""
    let patient_name = ""
    var { searchText ,fromDate , toDate } = this.state
    let searchAccountNo1 = searchText.split(/(\d+)/)
      let accountNumber = parseInt(searchAccountNo1[1])
    if(Constants.REG_EX_MOBILENO.test(searchText)) {
      mobileNumber = searchText
    } else if( searchAccountNo1[0].length > 0 &&  searchAccountNo1[1] && searchAccountNo1[1].length > 0){
      if( typeof(searchAccountNo1[0] === "string") && typeof(accountNumber) === "number" ){
       patient_account_number = searchText
      }  
    } else {
      patient_name = searchText
    }
      this.setState({
        creditPatientList: [],
        creditPatientListAfterPagination: [],
        currentPage: 1,
        recordsPerPage: 10
      })
   
    let url = Constants.GET_CREDIT_PATIENT_LIST + "?patient_mobile_number=" + mobileNumber + "&patient_account_number="+ patient_account_number +"&patient_name=" + patient_name +"&from_date=" + fromDate + "&to_date=" + toDate;

    this.service.getApiCall(
      url,
      this._getCreditPatientListSuccess,
      this._getCreditPatientListFailure
    )
  }

  _getCreditPatientListSuccess = response => {

    if (response.status === "success") {
      this.setState({
        creditPatientList: response.data,
        totalOutStanding : response.total_outstanding_amount,
        totalPaid : response.total_paid_amount,
        totalBill : response.total_bill_amount
      });
    }
  };

  _getCreditPatientListFailure = response => {
  }

  renderHeader = () => {
    return (
      <View style={{ marginTop : 20 }}>

                <CommonReportSectionHeader
                   heading1={"S.No"}
                   heading2={"Patient Details"}
                   heading3={"Mobile Number"}
                   heading4={"Total Bills"}
                   heading5={"Bill Amount ( ₹ )"}
                   heading6={"Paid Amount ( ₹ )"}
                   heading7={"Outstanding Payment ( ₹ )"}
                   heading8={"View"}
                   columnSize={[0.12, 0.12, 0.12, 0.13, 0.14, 0.14, 0.14, 0.08]}
                   noOfColumn={9}
                   />
      </View>
    )
  }
  renderHeaderData = () => {
    return (
    <View> 
     { this.state.creditPatientListAfterPagination && this.state.creditPatientListAfterPagination.length > 0 ? 
      <View>
        {
          this.state.creditPatientListAfterPagination && this.state.creditPatientListAfterPagination.map((item, index) => {
            let sno = (this.state.currentPage-1)*this.state.recordsPerPage + index + 1;
            return (
              <TouchableOpacity style={creditStyle.dataContainer} onPress={() => {
                this.props.changeScreen("creditBilling", item.patient_id, item)

                // if (item.total_outstanding_amount > 0) {
                //   this.props.changeScreen("creditBilling", item.patient_id, item)
                // } else {
                //   this.responseSuccessErrorMessage("Their is no outstanding bill to view", false)
                //   setTimeout(() => {
                //     this.responseSuccessErrorMessage("", false)
                //   }, 3000)
                // }
              }}>
                <View style={{ flex: 0.12, flexDirection: "row" }}><Text style={{ marginLeft: 20, fontSize: '1vw' }}>{sno}</Text></View>
                <View style={{ flex: 0.12, marginLeft: 10 }}>
                  <Text style={{fontSize: '1vw'}}>{item.billing_customer_name}</Text>
                  <Text style={{ fontSize: '.7vw' }}>{item.patient_account_number}</Text>
                </View>
                <View style={{ flex: 0.12 }}><Text style={{fontSize: '1vw'}}>{item.billing_customer_mobile_no}</Text></View>
                <View style={{ flex: 0.13 }}><Text style={{fontSize: '1vw'}}>{item.total_bill}</Text></View>
                <View style={{ flex: 0.14 }}><Text style={{fontSize: '1vw'}}>{parseFloat(item.total_bill_amount).toFixed(2)}</Text></View>
                <View style={{ flex: 0.14 }}><Text style={{fontSize: '1vw'}}>{parseFloat(item.total_paid_amount).toFixed(2)}</Text></View>
                <View style={{ flex: 0.14 }}><Text style={{fontSize: '1vw'}}>{parseFloat(item.total_outstanding_amount).toFixed(2)}</Text></View>
                <View style={{ flex: 0.08, marginLeft: 10 }}>
                  <TouchableOpacity onPress={() => {
                      this.props.changeScreen("creditBilling", item.patient_id, item)
                   }}>
                   <Icon name='eye' size={'1.8vw'} color={color.themeDark}></Icon>
                  </TouchableOpacity> 
                </View>
              </TouchableOpacity>
            )
          })}
      </View> : 
      <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: 150 }}>
       <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
      </View>}
    </View> 
    )
  }
  renderCard = (color, value, placehold) => {
    return (
      <View style={[creditStyle.TotalCard, { backgroundColor: color }] }>
        <View style={ creditStyle.cardMain }>
          <Text style={creditStyle.cardText}>{parseFloat(value).toFixed(2)}</Text>
          <Text style={creditStyle.cardText}>{"₹"}</Text>
        </View>
        <Text style={creditStyle.cardPlacehold}>{placehold}</Text>
      </View>
    )
  }
 
  printCreditPatientDetails = () => {
    let mobileNumber = ""
    let patient_account_number = ""
    let patient_name = ""
    var { searchText ,fromDate , toDate} = this.state
    let searchAccountNo1 = searchText.split(/(\d+)/)
      let accountNumber = parseInt(searchAccountNo1[1])
    if(Constants.REG_EX_MOBILENO.test(searchText)) {
      mobileNumber = searchText
    } else if( searchAccountNo1[0].length > 0 &&  searchAccountNo1[1] && searchAccountNo1[1].length > 0){
      if( typeof(searchAccountNo1[0] === "string") && typeof(accountNumber) === "number" ){
       patient_account_number = searchText
      }  
    } else {
      patient_name = searchText
    }
    var url = Constants.GET_CREDIT_PATIENT_LIST+ "?export_type=pdf" +"&patient_mobile_number=" + mobileNumber + "&patient_account_number="+ patient_account_number +"&patient_name=" + patient_name + "&from_date=" + fromDate + "&to_date=" + toDate  ;

    this.service.getInstance().documentUploadGet(
      url,
      this.printCreditPatientSuccess,
      this.printCreditPatientFailure,
      "pdf"
    )
  }
  printCreditPatientSuccess = (response) => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  }
  printCreditPatientFailure = (error) => {
    console.log(error);
  }

  _showSelectedFilteredDate(date) {
    this.setState({
        isclear: true,
        fromDate: date.fromDate,
        toDate: date.toDate,
        showDateFilter: false,
        // reset pagination
        current_page: 1,
        records_per_page: 10,

    },()=>{
      this._getCreditPatientList()
    })
}
_disableDateFilter=(flag)=>{
    
    this.setState({
        showDateFilter: flag
    })
}
  renderTopHeader = () => {
    var { vendor_list_suggest, isSelected } = this.state
    return (
      <View style={[creditStyle.topHeaderStyle, { zIndex: 1 }]}>
        <View style={[creditStyle.searchContainer,{ marginRight:"0.9vw" }]}>

        <TouchableOpacity
                onPress={() => {
                  this.props.changeScreen("invoice")
                }}
                style={[creditStyle.printContainer, { width: "4vw"}]}>
                <Text style={{ textAlign:"center",fontSize:"1vw", color: color.white}}>Back</Text>
          </TouchableOpacity>

         <Text style={creditStyle.creditText}>{"Credit Bills"}</Text>
         <TextInput value={this.state.searchText} placeholder = {"Search Patient..."}
          style={creditStyle.SearchBox}
          onChangeText={(text)=>{
           this.setState({
             searchText : text
            },()=>{
              this._getCreditPatientList()
            })
           }}/>


        <View style={{ flexDirection: "row", justifyContent:"flex-end", flex : 1 }}>
         <View style={creditStyle.cardContainer}>
            {this.renderCard("#9D358C", this.state.totalBill, "Total Bill")}
            {this.renderCard("#58B727", this.state.totalPaid, "Total Paid")}
            {this.renderCard("#EA3434", this.state.totalOutStanding, "Total Payable")}

         </View>
        <View style={{ flexDirection : "row" }}> 
        <View style={{marginRight:"0.9vw"}}>
        <CommonDateFilter
                startDate={this.state.fromDate}
                endDate={this.state.toDate}
                filterDate={this.state.filterDate}
                showSelectedFilteredDate={this._showSelectedFilteredDate.bind(this)}
                defaultSelectedDateFilter={this.state.defaultSelectedDateFilter}
                // on outside focus, close popup
                showDateFilter={this.state.showDateFilter}
                disableDateFilter={this._disableDateFilter}
              />
        </View>
             
              <TouchableOpacity
                onPress={() => {
                  this.printCreditPatientDetails()
                }}
                style={[creditStyle.printContainer, { marginRight: 10 }]}>
                <Image style={creditStyle.Image}
                  source={require('../../assets/images/HomeScreenImages/Print.png')} />
          </TouchableOpacity>
          {/* <TouchableOpacity
            onPress={() => {
                this.printVendorDetails("excel")
            }}
            style={creditStyle.printContainer}>
            <Image style={creditStyle.Image}
              source={require('../../assets/images/HomeScreenImages/Export.png')} />
          </TouchableOpacity> */}
          </View> 
          </View> 
        </View>
      </View>
    )
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
       currentPage: current_page,
       recordsPerPage: records_per_page
    })
}

  renderPaginationView = () => {
    return (
      <View style={{ marginHorizontal: 20 }}>
        {
          this.state.creditPatientList && this.state.creditPatientList.length > 0 ?
            <Pagination
              paginationChangedValues={this.paginationChangedValues.bind(this)}
              totalClientItems={this.state.creditPatientList}
              paginationCurrentPage = {this.paginationCurrentPage.bind(this)}
            /> : null
        }
      </View>
    )
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
       currentPage: current_page,
       recordsPerPage: records_per_page
    })
}
  paginationChangedValues = (data) => {
    this.setState({
      creditPatientListAfterPagination: data
    })
  }
  render() {
    let { showResponse, showErrorResponse, responseMessage } = this.state
    return (
      <TouchableWithoutFeedback onPress={()=>this._disableDateFilter(false)}>
      <View style={{ margin : 20 }}>
          {this.renderTopHeader()}
          {this.renderHeader()}
          {this.renderHeaderData()}
          {this.renderPaginationView()}

        <View style={{  position :"absolute", width : "100%", flex : 1, top:screenHeight-85, zIndex:4 }}>
              {
                showResponse ?
                  <SuccessAlert message={responseMessage} /> : null
              }
              {
                showErrorResponse ?
                  <ErrorAlert message={responseMessage} /> : null
              }
       </View> 
      </View>
      </TouchableWithoutFeedback>
    );
  }
  responseSuccessErrorMessage(message, type) {
    if (message != "") {
      if (type) {
        this.setState({
          showResponse: true,
          responseMessage: message,
          showErrorResponse: false
        })
      } else {
        this.setState({
          showResponse: false,
          showErrorResponse: true,
          responseMessage: message
        })
      }
    } else {
      this.setState({
        showResponse: false,
        showErrorResponse: false,
        responseMessage : ""
      })
    }
  }
}
const creditStyle = StyleSheet.create({
    topHeaderStyle: {
        flex: 1,
        justifyContent: "flex-end",
      },
      creditText: {
        fontWeight: "500", fontSize: '1.5vw', paddingHorizontal : 10
      },
      searchContainer: {
        flexDirection: "row", flex: 1
      },
      Image: {
        height: '1.5vw',
        width: '1.5vw',
      },
      dataContainer: {
        flexDirection: 'row',
        borderBottomColor: "#888888",
        borderBottomWidth: 1,
        paddingVertical: 10,
        justifyContent: 'center'
      },
      printContainer : { width: '2.5vw', height: '2.5vw', borderRadius: 4, backgroundColor: color.themeBackgroud, paddingHorizontal: '.5vw', paddingVertical: '.5vw',},
      commonFlex: {
        flex: 0.14
      },
      cardContainer : { flexDirection: "row",paddingHorizontal: 10  },
      cardMain : { marginRight: 5, paddingLeft: 5, flexDirection: "row", justifyContent: "space-between", minWidth: '8.5vw' },
      cardPlacehold : { color: "white", fontSize: '0.9vw', marginLeft: 10 },
      SearchBox: { 
        borderWidth : 1, 
        borderColor:color.gray, 
        width:"18vw", 
        height:'2.5vw', 
        borderRadius : 4, 
        paddingLeft: 10, 
        fontSize: '1vw'
      },
      cardText: {
        fontSize: '1vw', 
        fontWeight:"bold", 
        color: "white"
      },
      TotalCard: { 
        borderRadius: 4, 
        marginLeft: 10,
        height: '3.5vw',
        justifyContent: "center"
      }
})
