import React ,{ useState, useEffect, useRef } from "react";
import {
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
  TextInput,
  FlatList,
  ScrollView,
  CheckBox,
  TouchableWithoutFeedback,
  StyleSheet
} from "react-native";
import { color } from "../styles/Color";
import Style from "../styles/Style";
import Icon from "react-native-vector-icons/FontAwesome";
import 'antd/dist/antd.css';
import { DatePicker, Tooltip } from 'antd';
import moment from "moment";
import Add from "react-native-vector-icons/MaterialCommunityIcons";
import Cart from "react-native-vector-icons/FontAwesome"; 
import { SimpleLineIcons } from '@expo/vector-icons'
import Pagination from "../common/Pagination"
import Constants from '../networks/Constants';

const { RangePicker } = DatePicker

import AsyncStorage from "../AsyncStorage";
import { report } from "process";

const dateFormat = 'YYYY-MM-DD';
const dateFormatDisplay = 'DD-MM-YYYY';
const expiryDateFormat = 'MM-YYYY';

// const platform = Platform.OS;
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

export const ShowCommonButton = (props) => {
  return (
    <View>
      {props.item &&
        props.item.value == "DROPS" &&
        props.selectedvalue &&
        props.selectedvalue.value == "DROPS" &&
        props.buttonKey == "selectedDosage" ? (
          <View
            style={{
              flexDirection: "row",
              width: 140,
              borderRadius: 30,
              height: 30,
              paddingLeft: 5,
              paddingRight: 5,
              alignItems: "center",
              justifyContent: "space-between",
              alignSelf: "center",
              backgroundColor: color.red,
            }}
          >
            <TouchableOpacity
              style={{
                alignSelf: "center",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: 20,
                width: 20,
                borderRadius: 40,
                backgroundColor: color.white,
              }}
              onPress={() => {
                props.selectedDosageCount > 1 ? props.buttonAction(props.buttonKey, props.item, "sub") : null;
              }}
            >
              <Text
                style={{
                  color: color.themeBlue,
                }}
              >
                {"-"}
              </Text>
            </TouchableOpacity>
            <View>
              <Text
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  color: color.white,
                  fontWeight: "400",
                }}
              >
                {props.selectedDosageCount}
                {" Drops"}
              </Text>
            </View>
            <TouchableOpacity
              style={{
                alignSelf: "center",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                height: 20,
                width: 20,
                borderRadius: 40,
                backgroundColor: color.white,
              }}
              onPress={() => {
                props.buttonAction(props.buttonKey, props.item, "add");
              }}
            >
              <Text style={{ color: color.themeBlue }}>{"+"}</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <TouchableOpacity
            style={[
              {
                borderWidth: 1,
                borderRadius: 30,
                paddingTop: 8,
                paddingLeft: 18,
                paddingRight: 18,
                paddingBottom: 8,
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: props.disable
                  ? color.disableComponentColor
                  : color.themeShadeBackground,
                alignSelf: "center",
              },
              props.item &&
                props.item.value &&
                props.selectedvalue &&
                props.selectedvalue.value &&
                props.item.value == props.selectedvalue.value
                ? {
                  backgroundColor: color.themeDark,
                  borderColor: color.themeDark,
                }
                : props.buttonType == "outlineTheme"
                  ? { borderColor: color.themeDark }
                  : props.buttonType == "outlineNonTheme"
                    ? { borderColor: "#888888" }
                    : props.buttonType == "theme"
                      ? {
                        backgroundColor: color.themeDark,
                        borderColor: color.themeDark,
                      }
                      : props.buttonType == "darkBlue"
                        ? { backgroundColor: "#2A65D5", borderColor: "#2A65D5" }
                        : null,

              Style.allButtonBorderRadius
            ]}
            onPress={() => {
              props.buttonAction(props.buttonKey, props.item);
            }}
            disabled={props.disable ? true : false}
          >
            {props.buttonIcon && props.rightIcon ? (
              <Image
                style={{ height: 20, width: 20, marginRight: 15 }}
                source={props.buttonIcon}
              />
            ) : null}

            <Text
              style={[
                props.item &&
                  props.item.value &&
                  props.selectedvalue &&
                  props.selectedvalue.value &&
                  props.item.value == props.selectedvalue.value
                  ? { color: "white", fontSize: '0.68vw' }
                  : props.buttonType == "outlineTheme"
                    ? { color: "#04c6bf", fontSize: '0.68vw' }
                    : props.buttonType == "outlineNonTheme"
                      ? { color: "black", fontSize: '0.68vw' }
                      : props.buttonType == "theme"
                        ? { color: "white", fontSize: '0.68vw' }
                        : props.buttonType == "darkBlue"
                          ? { color: "white", fontSize: '0.68vw' }
                          : null,
              ]}
            >
              {props.butttonText}
            </Text>

            {props.buttonIcon && !props.rightIcon ? (
              <Image
                style={{ height: 20, width: 20, marginLeft: 15 }}
                source={props.buttonIcon}
              />
            ) : null}
          </TouchableOpacity>
        )}
    </View>
  );
};

export const CommonButton = (props) => {
  var selectedDosageCount = 0;
  if (props.selectedDosageCount) {
    selectedDosageCount = props.selectedDosageCount;
  }

  if (
    props.buttonKey == "selectedHistory" ||
    props.buttonKey == "selectedDrug"
  ) {
    if (props.selectedvalue && props.selectedvalue.length > 0) {
      const found = props.selectedvalue.some(
        (el) => el.label === props.butttonText
      );
      if (!found) {
        return (
          <ShowCommonButton
            selectedDosageCount={selectedDosageCount}
            buttonAction={props.buttonAction}
            item={props.item}
            buttonType={props.buttonType}
            disable={props.disable}
            buttonKey={props.buttonKey}
            buttonIcon={props.buttonIcon}
            rightIcon={props.rightIcon}
            butttonText={props.butttonText}
          />
        );
      } else {
        return (
          <ShowCommonButton
            selectedDosageCount={selectedDosageCount}
            buttonAction={props.buttonAction}
            item={props.item}
            selectedvalue={props.item}
            buttonType={props.buttonType}
            disable={props.disable}
            buttonKey={props.buttonKey}
            buttonIcon={props.buttonIcon}
            rightIcon={props.rightIcon}
            butttonText={props.butttonText}
          />
        );
      }
    } else {
      return (
        <ShowCommonButton
          selectedDosageCount={selectedDosageCount}
          buttonAction={props.buttonAction}
          item={props.item}
          selectedvalue={props.selectedvalue}
          buttonType={props.buttonType}
          disable={props.disable}
          buttonKey={props.buttonKey}
          buttonIcon={props.buttonIcon}
          rightIcon={props.rightIcon}
          butttonText={props.butttonText}
        />
      );
    }
  } else {
    return (
      <ShowCommonButton
        selectedDosageCount={selectedDosageCount}
        buttonAction={props.buttonAction}
        item={props.item}
        selectedvalue={props.selectedvalue}
        buttonType={props.buttonType}
        disable={props.disable}
        buttonKey={props.buttonKey}
        buttonIcon={props.buttonIcon}
        rightIcon={props.rightIcon}
        butttonText={props.butttonText}
      />
    );
  }
};

export const CommonAddButton = (props) => {
  return (
    <TouchableOpacity onPress={() => { props.onPressAddButton() }} style={{ marginTop: 10, backgroundColor: color.themeDark, borderRadius: 8, paddingVertical: 10, alignItems: 'center' }}>
      <Text style={{ color: color.white, fontWeight: "700", fontSize: 16 }}>{"Add"}</Text>
    </TouchableOpacity>
  )
}

export const CommonPicker = (props) => {
  return (
    <View >
      <TouchableOpacity onPress={() => {
        props.changePickerDropDownValue(!props.showPickerDropDownFlag, "", props.pickerKey)
      }} style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center', paddingHorizontal: 10,
        height: 32,
        backgroundColor: color.white,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: color.textBoxBorderColor,
        marginRight: 15
      }}>

        <Text style={{ color: "#888888", fontSize: 11 }}>{props.selectedValue && props.selectedValue.value ? props.selectedValue.value : ""}</Text>
        <Icon name="angle-down" size={14} color={"#888888"} />
      </TouchableOpacity>
      {
        props.showPickerDropDownFlag ?
          <View style={{ position: 'absolute', marginTop: 34 }}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
              style={{ width: 150, borderColor: color.black, borderRadius: 8, borderWidth: 1, backgroundColor: color.white, maxHeight: 200 }}>
              {
                props.arrayOfData && props.arrayOfData.length > 0 && props.arrayOfData.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index} onPress={() => {
                        props.changePickerDropDownValue(!props.showPickerDropDownFlag, item, props.pickerKey)
                      }} >
                      <Text style={{
                        fontSize: 11,
                        color: props.selectedValue.id == item.id ? color.white : "#888888",
                        backgroundColor: props.selectedValue.id == item.id ? color.themeDark : null,
                        paddingHorizontal: 10, paddingVertical: 4
                      }}>{item.value}</Text>
                    </TouchableOpacity>
                  )
                })
              }

            </ScrollView>
          </View> : null
      }
    </View>
  )
}


export const CommonHeaderPicker = (props) => {
  console.log("test  +++++++  2  " + JSON.stringify(props.selectedValue))
  return (
    <View >
      <TouchableOpacity onPress={() => {
        props.changePickerDropDownValue(!props.showPickerDropDownFlag, "", props.pickerKey)
      }} style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 170,
        alignItems: 'center', paddingHorizontal: 10,
        paddingRight: 15,
        height: 32,
        backgroundColor: color.white,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      }}>
        <Text style={{ color: "#888888", fontSize: 11 }}>{props.selectedValue.value}</Text>
      </TouchableOpacity>
      {
        props.showPickerDropDownFlag ?
          <View style={{ position: 'absolute', marginTop: 34 }}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
              style={{ width: 150, borderColor: color.black, borderRadius: 8, borderWidth: 1, backgroundColor: color.white, maxHeight: 200 }}>
              {
                props.arrayOfData && props.arrayOfData.length > 0 && props.arrayOfData.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index} onPress={() => {
                        props.changePickerDropDownValue(!props.showPickerDropDownFlag, item, props.pickerKey)
                      }} >
                      <Text style={{
                        fontSize: 11,
                        color: props.selectedValue.id == item.id ? color.white : "#888888",
                        backgroundColor: props.selectedValue.id == item.id ? color.themeDark : null,
                        paddingHorizontal: 10, paddingVertical: 4
                      }}>{item.value}</Text>
                    </TouchableOpacity>
                  )
                })
              }
            </ScrollView>
          </View> : null
      }
    </View>
  )
}

export const CommonHeaderPatientDetails = (props) => {
  return (
    <View></View>
  )
}
export const CommonHeader = (props) => {
  return (
    <View style={{ flexDirection: "row", height: "4.23vw", width: "100%" }}>
      <View style={{ width: "5.85%", borderRadius: 4 }}>
        <Image style={{ height: "90%", width: "90%", justifyContent: 'center' }} resizeMode='contain' source={require('../../assets/emedhub-logo.png')} />
      </View>
      <View style={{ width: "94.15%", backgroundColor: color.themeDark, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

        { props.reportCurrentPage && props.reportCurrentPage ? 

          <View style={{ flexDirection: "row", marginLeft: "0.65vw" }}>
            <TouchableOpacity onPress = {() => {
                props.changeScreen("")
                setTimeout(() => {
                  props.changeScreen("report")
                }, 200)
            }}>
             <Image source={require("../../assets/images/Back.png")} style={{ height: "1.62vw", width: "1.62vw" }} />
            </TouchableOpacity>
            <Text style={{ color: color.white, fontSize: "1.17vw", fontWeight: '500', marginHorizontal: "0.78vw" }}>{"Reports - "}{ props.reportCurrentPage}</Text>
          </View> : null

        }
        {props.selectedScreen === 'referredDoctors' && props.pharmacyType === "Standalone Pharmacy" ? 
        <View style={{ flexDirection: "row", marginLeft: "0.65vw" }}>
            <TouchableOpacity onPress = {() => {
                // props.changeScreen("")
                // setTimeout(() => {
                  props.changeScreen("billing")
                // }, 200)
            }}>
             <Image source={require("../../assets/images/Back.png")} style={{ height: "1.62vw", width: "1.62vw" }} />
            </TouchableOpacity>
            <Text style={{ color: color.white, fontSize: "1.17vw", fontWeight: '500', marginHorizontal: "0.78vw" }}>{"Referral Doctors"}</Text>
        </View> : null
        }
        {props.selectedScreen == 'billing' ? 
          <View style={{ flexDirection: "row", justifyContent: 'flex-start' ,marginLeft:"2.92vw"}}>
            <TouchableWithoutFeedback onPress={props.closePatienSearchModal}>
              <PatientSearchBar   
                placeHolderText={"Search patient..."}
                getAllPatientList={props.getAllPatientList}
                getPatientListWithSearchText={props.getPatientListWithSearchText}
                />
              </TouchableWithoutFeedback>
          </View>:null}
          {props.selectedScreen == "creditBilling"?
           <View style={{  marginLeft : "0.65vw"}}>
              {/* <TouchableOpacity style={{ justifyContent : "center", alignItems : "center" }}
                onPress={()=>{
                props.changeScreen("creditHome")
              }}><Image source={require('../../assets/images/return.png')} style={{ height : 30, width : 30 }}></Image></TouchableOpacity> */}
              <View style={{ marginLeft : ".65vw" }}>
                <Text style={{ fontSize : "1.30vw", color : "white" }}>{props.selectedPatientDetail.billing_customer_name}</Text>
                <View style={{flexDirection : "row"}}>
                 <Text style={{ fontSize :".8vw", color : "white", paddingHorizontal: ".1vw" }}>{props.selectedPatientDetail.patient_account_number}</Text>
                 <Text style={{ fontSize : ".8vw", color : "white", borderLeftWidth : ".2vw", borderLeftColor : "lightgrey", paddingHorizontal: ".3vw", marginLeft:'.2vw' }}>{props.selectedPatientDetail.billing_customer_mobile_no}</Text>
              </View>
             </View> 
           </View> : null
          }
        <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: color.white, borderRadius: 4, marginLeft: 20 }}>
          {(!props.currentPage === "inventory"&& props.subScreen != "new_drug_entry" && props.subScreen != "new_drug_request" && props.subScreen != "OTindentRequest"  && props.subScreen != "order_list" && props.subScreen != "confirm_order_list") || props.currentPage === "invoice"?
             <CommonHeaderSearch searchPatient={props.searchPatient} onChangeSearchText={props.onChangeSearchText} currentPage={props.currentPage} searchValue={props.searchValue} {...props} />
          : null}
          {/* <HomeScreenCameraIcon openCameraModal={props.openCameraModal} /> */}
        </View>
        {props.selectedScreen === "billing" ? 
        <View style={{ flexDirection: 'row', justifyContent: "flex-end", alignContent: "center" }}>
          {props.pharmacyType === "Standalone Pharmacy" ? 
          <HomeScreenHeaderOption buttonKey="referredDoctors"
          image={require('../../assets/Icon/appointment-list.svg')} label={"Referral"} subLabel={"Doctors"}
          updatePopupStatus={props.updatePopupStatus} changeScreen={props.changeScreen} />
          : null}
          
          {props.pharmacyType != "Standalone Pharmacy" ?
          <HomeScreenHeaderOption buttonKey="appointmentList"
            image={require('../../assets/Icon/appointment-list.svg')} label={"Appointments"} subLabel={""}
            updatePopupStatus={props.updatePopupStatus} />
          : null }

          <View style={{ flexDirection: "row" }}>
            <HomeScreenHeaderOption buttonKey="savedBills"
              image={require('../../assets/Icon/SavedBills.png')} label={"Saved"} subLabel={"Bills"}
              updatePopupStatus={props.updatePopupStatus} />
            <View style={{ position: "absolute", marginLeft: ".65vw" }}>
              <Text style={{ color: color.white, fontSize: ".7vw", backgroundColor: color.red, borderRadius: "5vw",  width:'.8vw', height:'.9vw', display:'flex', alignItems:'center', justifyContent:'center' }}>{props.transmittedPrescriptionList && props.transmittedPrescriptionList.length}</Text>
            </View>
          </View>
          {props.pharmacyType != "Standalone Pharmacy" ?
          <HomeScreenHeaderOption buttonKey="sharedPrescriptions"
            image={require('../../assets/Icon/Prescriptions.png')} label={"Prescriptions"} subLabel={""}
            updatePopupStatus={props.updatePopupStatus}
          />
          : null }
          <CommonHeaderProfileField
            doctorName={props.user_name}
            doctorProfilePhoto={""}
            userType={props.service_type} 
            showProfileDropDownFlag={props.showProfileDropDownFlag} 
            profileDropDownFlagChange={props.profileDropDownFlagChange}
            logoutFunction={props.logoutFunction} 
            defaultImage={require('../../assets/images/sample_profile.png')}
          />
        </View>
        :
        <View style={{ flexDirection: 'row', justifyContent: "flex-end", alignContent: "center" }}>
           { props.currentPage === "invoice"?
            <HomeScreenHeaderOption buttonKey="creditBill"
              image={require("../../assets/Icon/Billing.png")} label={"Credit Bill"} subLabel={""}
              updatePopupStatus={props.updatePopupStatus} changeScreen={props.changeScreen}
            />
          : null }
          
        <CommonHeaderProfileField
            doctorName={props.user_name}
            doctorProfilePhoto={""}
            userType={props.service_type} 
            showProfileDropDownFlag={props.showProfileDropDownFlag} 
            profileDropDownFlagChange={props.profileDropDownFlagChange}
            logoutFunction={props.logoutFunction} 
            defaultImage={require('../../assets/images/sample_profile.png')}
          />
        </View>
        }
      </View>
    </View>
  )
};


export const CommonHeaderSearch = (props) => {
  let searchPlaceholder = ""
  
  if(props.currentPage) {
    if(props.currentPage=="invoice") 
    {
      searchPlaceholder="Search Invoice"
    }else if(  props.currentPage === "inventory" ) {
      if((props.subScreen && props.subScreen=="stock_list"))
      {
        searchPlaceholder="Search Drug"
      } else if(props.subScreen === "wanted_list" ){
        searchPlaceholder="Search Wanted List" 
      } else if( props.subScreen != "new_drug_entry" ){
        searchPlaceholder="Search Inventory" 
      }
    }else{
      searchPlaceholder="Search Patient"
    }
  } else {
    searchPlaceholder="Search Patient"
  }

  return (
    <View style={{
      width: "19.5vw",
      paddingHorizontal: "1.65vw",
      height: "2vw",
      borderLeftColor: "#888888",
      borderLeftWidth: 0.5,
      borderRightColor: "#888888",
      borderRightWidth: 0.5
    }}>

      <TextInput
        value={props.searchValue || ""}
        placeholder={searchPlaceholder} 
        onChangeText={(text) => { props.onChangeSearchText(text, false) }} 
        style={{
          backgroundColor: color.white, color: "black", paddingVertical: ".3vw", fontSize:'.95vw'
        }}
      />
      {!props.isSelected && (props.suggestionList && props.suggestionList.length>0)?
          <View style={{
              borderRadius: ".7vw",
              width: '100%',
              justifyContent: 'center',
              alignContent: 'center',
              padding: ".7vw",
              marginTop: "1.95vw",
              position: 'absolute',
              backgroundColor: color.themeBlue
            }}>
              <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: screenHeight - 250 }}>
                <FlatList
                  data={props.suggestionList} 
                  renderItem={({ item }) => (
                    <View style={{
                      flexDirection: "row",
                      bottom: "0.7vw",
                      marginBottom: "0.7vw",
                      justifyContent: "space-evenly",
                      width: "100%",
                      alignItems: "center",
                      marginTop: "0.7vw",
                    }}
                    >
                      <View style={{ width: '100%' }}>
                        <TouchableOpacity
                          onPress={()=> { props.onChangeSearchText(item, true) }} 
                        >
                          <Text style={{ color: color.white, fontSize:'.95vw' }}>{item}</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                  enableEmptySections={true}
                />
              </ScrollView>
            </View>:null}
    </View>
  )
}

export const PopupSearch = (props) => {
  const [ searchValue, setSearchValue ] = useState("")
  const [ placeHolderText, setPlaceHolderText ] = useState(props.placeHolderText || "Search patient ...") 
  const textInput = useRef(null);

  function processData(){

    props.searchPatient(searchValue)
  }

  return (
    <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>

      <View style={{
        flex: 0.9,
        height: "2.3vw",
        backgroundColor: color.placeholder,
        borderTopLeftRadius: ".26vw",
        borderBottomLeftRadius: ".26vw"
      }}>

        <TextInput
          ref={textInput} 
          value={searchValue}
          placeholder={placeHolderText}
          onChangeText={(text) => { setSearchValue(text) }} 
          onSubmitEditing={processData}
          style={{
            height: "2.3vw",
            borderTopLeftRadius: ".26vw",
            borderBottomLeftRadius: ".26vw",
            backgroundColor: color.placeholder,
            color: "black",
            paddingHorizontal: "1vw",
            paddingVertical: ".3vw",
            fontSize:'.95vw'
          }}
        />
      </View>
      <TouchableOpacity style={{
        flex: 0.1, backgroundColor: color.themeDark, alignItems: "center",
        borderTopRightRadius: ".26vw", borderBottomRightRadius: ".3vw", paddingVertical: "0.45vw"
      }}
        onPress={processData}>
        <Icon style={{marginTop:'.25vw'}} name="search" size={"1vw"} color={color.white} />
      </TouchableOpacity>
    </View>
  )
}

export const PatientSearchBar = (props) => {
  const [ searchValue, setSearchValue ] = useState("")
  const [ placeHolderText, setPlaceHolderText ] = useState(props.placeHolderText || "Search patient ...") 
  const textInput = useRef(null);
  
  function processData(){
    props.getPatientListWithSearchText(searchValue)
    props.getAllPatientList(true)
  }
  // function focuson (){
  //   props.getAllPatientList(true)
  // }
  return (
    <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",width:"11.7vw" }}>
      <View style={{flex: 0.9,height: "1.95vw",backgroundColor:'white',borderTopLeftRadius: ".26vw",borderBottomLeftRadius: ".26vw"}}>
        <TextInput 
          ref={textInput} value={searchValue} placeholder={placeHolderText} 
          onChangeText={(text) => {
            if(text != "") { 
             setSearchValue(text) 
            } else {
              setSearchValue(text) 
              props.getAllPatientList(false)
            }
          }}
          onKeyPress={(e) => {e.key === "Enter" ? processData() : null}}
          
          style={{height: "1.9vw",borderTopLeftRadius: ".26vw",borderBottomLeftRadius: ".26vw",backgroundColor: 'white',color: "black",paddingHorizontal: "1vw",paddingVertical: ".3vw", fontSize:'.9vw'}}
          // onFocus={focuson}
        />
      </View>
      <TouchableOpacity style={{
            backgroundColor: 'white',alignItems:"center",padding: "0.13vw",paddingVertical: "0.45vw",borderTopRightRadius: ".26vw", borderBottomRightRadius: ".3vw"}}
        onPress={processData}>
        <Icon name="search" size={"1vw"} color={color.themeDark} style={{marginRight:"0.20vw"}}/>
      </TouchableOpacity>
    </View>
)}

export const HomeScreenCameraIcon = (props) => {
  return (
    <TouchableOpacity style={{ borderTopRightRadius: 4, borderBottomRightRadius: ".3vw", paddingHorizontal: "1.65vw", }}>
      <Image style={{ height: "1.5vw", width: "1.5vw" }} source={require("../../assets/images/HomeScreenImages/Camera.png")} />
    </TouchableOpacity>
  )
}


export const HomeScreenHeaderOption = (props) => {
  return (
    <View>
      <TouchableOpacity style={{ alignItems: 'center', marginLeft: ".5vw", marginRight: ".8vw" }}
        onPress={() => {
          if (props.buttonKey == "savedBills")
            props.updatePopupStatus("savedBillFlag", true, [])
          else if (props.buttonKey == "sharedPrescriptions") {
            props.updatePopupStatus("transmittedPrescriptionFlag", true, [])
          } else if (props.buttonKey == "appointmentList") {
            props.updatePopupStatus("appointmentListFlag", true, [])
          } else if (props.buttonKey == "referredDoctors") {
            props.changeScreen("referredDoctors")
          }else if(props.buttonKey == "creditBill"){
            props.changeScreen("creditHome")
          }
          else if (props.buttonKey == "print") { }

        }}
      >
        <Image style={{ height: "1.82vw", width: "1.82vw", tintColor: color.white }} source={props.image} />
        <Text style={{ color: color.white, textAlign: 'center', fontSize: "0.65vw", fontWeight: '500' }}>{props.label}</Text>
        {
          props.subLabel ?
            <Text style={{ color: color.white, textAlign: 'center', fontSize: "0.65vw", fontWeight: '500' }}>{props.subLabel}</Text> : null
        }
      </TouchableOpacity>
    </View>
  )
}

export const CommonHeaderProfileField = (props) => {

  return (
    <View>
    <TouchableOpacity 
      onPress={() => { props.profileDropDownFlagChange(!props.showProfileDropDownFlag) }}
      style={{ flexDirection: 'row', justifyContent: 'flex-end', marginLeft: "0.5vw", paddingRight: "1vw", marginRight: "0.5vw", paddingHorizontal: "1.95vw", borderLeftColor: color.white, borderLeftWidth: 0.5 }}>
      <View>
        <Text style={{ color: color.white, textAlign: 'center', fontSize: "1.1vw", fontWeight: '500' }}>{props.doctorName ? props.doctorName : ""}</Text>
        <Text style={{ color: color.white, textAlign: 'center', fontSize: ".70vw", fontWeight: '500', marginTop: ".3vw" }}>{props.userType ? props.userType : ""}</Text>
      </View>
      <Image style={{ height: "2.4vw", width: "2.4vw", marginLeft: "0.85vw", borderRadius: "1.95vw", marginTop:'.3vw' }} source={props.doctorProfilePhoto ? props.doctorProfilePhoto : props.defaultImage} />
    </TouchableOpacity> 
    {
        props.showProfileDropDownFlag ?
          <View style={{  }}>
            <View style={{
              position: "absolute",
              backgroundColor: color.white,
              paddingHorizontal: "0.65vw",
              borderRadius: ".25vw",
              borderWidth: 1,
              borderTopWidth: 0, 
              borderColor: "#E2E2E2" ,
              marginTop: ".65vw", marginLeft: "1.95vw"
            }}>
              <TouchableOpacity onPress={() => {
                props.logoutFunction()
              }}>
                <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginVertical: ".65vw" }}>
                  <SimpleLineIcons name="logout" size={"1.62vw"} color={color.lightGray} />
                  <Text style={{ marginLeft: "1vw", fontSize:'1vw' }}>{"Logout"}</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
          : null
      }
      </View>
  )
}

export const MenuBreadCrumpList = (props) => {
  // PO id for navigate back to PO List / Stock List
  let purchase_id = props.purchase_id?props.purchase_id:false
  console.log(props.selectedScreen ,"screen")
  
  let isListPage = (props.selectedScreen === "vendors" || props.selectedScreen === "purchase_orders" || props.selectedScreen === "stock_list" || props.selectedScreen === "wanted_list" || props.selectedScreen === "OTindentRequest" ) ? true : false;
  
  let backScreenFromDetailPage = (props.selectedScreen === "add_purchase_order" || props.selectedScreen === "update_purchase_order" || purchase_id) ?"purchase_orders":
  (props.selectedScreen === "add_stock" || props.selectedScreen === "update_stock" || props.selectedScreen === "view_stock")?"stock_list": (props.selectedScreen === "order_list" || props.selectedScreen === "confirm_order_list" )?"wanted_list":"stock_list" 

  let isDisable = props.isDisable? props.isDisable : false;

  return (
    <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginVertical: "1vw" }}>
      <View style={{ flex: 0.75, flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>

        {!isListPage ?
          <View style={{paddingLeft:20}}><ActionButton label={"Back"} keyIndex={backScreenFromDetailPage} onPressAction={props.changeScreen} /></View> : null}

        <View style={{ flexDirection: "row", alignItems: "center" }}>
        {props.menu.length > 0 && props.menu.map((item, index) => {

          return <BreadcrumpTitleLabel key={index} menuItem={item} selectedScreen={props.selectedScreen} changeScreen={props.changeScreen} />
        })}
        </View>

        {props.selectedScreen === "add_purchase_order" || props.selectedScreen === "update_purchase_order" ? (
          <PurchaseDetailsHeader list={props.list} isSelected={props.isSelected} itemSelected={props.itemSelected} vendor_name={props.vendor_name} po_number={props.po_number} po_date={props.po_date} onChangeText={props.onChangeText} objFormat={props.objFormat} />
        ) : null}
      </View>
      <View style={{ flex: 0.25, flexDirection: "row", justifyContent: "flex-end", alignContent: "center" }}>
        { props.selectedScreen === "stock_list" ? <ActionButton label={" + New Drug"} keyIndex={"new_drug_entry"} pageType={"new_drug_entry"} onPressAction={props.changeScreen} /> : null }
        {/* {(props.selectedScreen === "stock_list" || props.selectedScreen === "purchase_orders") ? <ActionButton label={"+ Direct PO"} keyIndex={"add_stock"} pageType={"directPO"} onPressAction={props.changeScreen} /> : null}
        {props.selectedScreen === "wanted_list" ? <ActionButton label={"Order List"} keyIndex={"order_list"} pageType={"order_list"} onPressAction={props.changeScreen} /> : null} */}

        {/* {props.selectedScreen === "purchase_orders" ?
            <ActionButton label={"+ Purchase Order"} keyIndex={"add_purchase_order"} onPressAction={props.changeScreen} /> : null
        } */}

        {/* {props.selectedScreen === "add_vendor" || props.selectedScreen === "update_vendor"  ? (
          <ActionButton label={"Save"} keyIndex={"save_vendor"} onPressAction={props.onSaveVendor} isDisable = {props.disabled} />
        ) : null} */}

        {(props.selectedScreen === "add_purchase_order" || props.selectedScreen === "update_purchase_order" ||
          ( props.selectedScreen === "add_stock" && props.isSaveDisable)   || props.selectedScreen === "update_stock") ? (
            <ActionButton label={"Save"} keyIndex={"save_purchase_order"} onPressAction={props.onSave} isDisable={isDisable} />
          ) : null}
          {props.selectedScreen === "add_stock"  ? (
            <ActionButton label={"Print"} keyIndex={"print_purchase_order"} onPressAction={props.PrintPO} isDisable={isDisable} />
          ) : null}

        {(isListPage && props.selectedScreen != "wanted_list" && props.selectedScreen != "OTindentRequest" )? <TouchableOpacity
                            onPress={() => {
                                !isDisable?props.generateReport('excel'):null
                            }}
                            style={{ width: "2.60vw", height: "1.95vw", borderRadius: "0.26vw", backgroundColor: isDisable?color.lightGray:color.themeBackgroud, paddingHorizontal: ".32vw", paddingVertical: "0.17vw" }}>
                            <Image style={{ width: "1.95vw", height: "1.62vw" }}
                                source={require('../../assets/images/HomeScreenImages/Export.png')} />
                        </TouchableOpacity> : null}
        {(isListPage && props.selectedScreen != "wanted_list" && props.selectedScreen != "OTindentRequest" ) ? <TouchableOpacity
                            onPress={() => {
                                !isDisable?props.generateReport('pdf'):null
                            }}
                            style={{ width: "2.60vw", height: "1.95vw", borderRadius: ".26vw", backgroundColor: isDisable?color.lightGray:color.themeBackgroud, paddingHorizontal: ".3vw", paddingVertical: "0.17vw", marginLeft: "1vw" }}>
                            <Image style={{ width: "1.95vw", height: "1.62vw" }}
                                source={require('../../assets/images/HomeScreenImages/Print.png')} />
                        </TouchableOpacity> : null}

      </View>
    </View>
  )
}

export const BreadcrumpTitleLabel = (props) => {
  let menuItem = props.menuItem;

  return (

    <TouchableOpacity onPress={() => menuItem.isLink ? props.changeScreen(menuItem.key) : null}>
      <View style={[{ flexDirection: "column" },
      ]}
      >
        <Text
          style={{
            marginRight: ".52vw", fontSize: "1.05vw", fontWeight: "500",
            color: menuItem.key === props.selectedScreen ? color.themeDark : color.black
          }}
        >{menuItem.label}</Text>
        {menuItem.key === props.selectedScreen ?
          <View style={{
            width: "1.83vw",
            borderColor: color.themeDark, borderTopWidth: "0.13vw"
          }}></View> : null
        }
      </View>
    </TouchableOpacity>
  )
}

export const SectionTitle = (props) => {

  let title = props.label;
  let flexWidth = props.flexWidth ? props.flexWidth : 1;

  return (
    <View style={{
      flex: flexWidth, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
      backgroundColor: "#E2E2E2", padding: '.5vw', borderRadius: 4, marginTop: '.3vw', zIndex: -1
    }}>
      <Text style={styles.SectionTitleText}>{title}</Text>
    </View>
  )
}


export const BillTableTitle = (props) => {

  let title = props.label;
  let flexWidth = props.flexWidth ? props.flexWidth : 0.05;

  return (
    <View style={{ flex: flexWidth }}>
      <Text style={{ fontSize: '1vw', marginRight: "0.65vw" }}>{title}</Text>
    </View>)
}

export const BillTableData = (props) => {

  let flexWidth = props.flexWidth ? props.flexWidth : 0.1;
  let title = props.label;
  let type = props.labelType;
  let txtColor = type && type=="status" && props.txtColor?props.txtColor:""

  return (
    <View style={{ flex: flexWidth, }}>
      {type === 'label' ?
        <Text style={{ fontSize: "1vw"}}>{title}</Text>
        : <Text style={{ fontSize: "1vw", color: txtColor }}>{title}</Text>
      }
    </View>
  )
}


export const TransmittedPrescription = (props) => {
  const transmittedPrescriptionList = props.transmittedPrescriptionList
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: "100vh",
          width: "100vw",
          justifyContent: "center", // to be uncommented
          alignItems: "center", // to be uncommented
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            backgroundColor: "white",
            width: "26.5vw",
            height: "31vw",
            borderRadius: 0,
            marginTop: "4.55vw",
            position: "relative"
          }}
        >

          <View style={{ flex: 1, flexDirection: "column", padding: "1.30vw", backgroundColor: color.white, borderRadius: 0 }}>

            <View style={{ flexDirection: "row", flex: 1, maxHeight: "1.95vw", marginTop: "-.65vw", marginRight: "-.65vw", justifyContent: "space-between" }} >
              <Text style={{fontSize: "1vw", fontWeight:"bold", marginBottom:"0.65vw"}}>Prescriptions</Text>
              <TouchableOpacity onPress={props.closeModal}>
                <Icon name="close" size={"1.2vw"} color={"#000000"} />
              </TouchableOpacity>
            </View>

            <View style={{ flexDirection: "row" }}>

              <PopupSearch placeHolderText={"Search prescription"} changeSearchText={props.changeSearchText} searchPatient={props.searchPatient} closeModal={props.closeModal} />
              <View style={{ flexBasis: "9.8vw", flexGrow: 0, flexShrink: 0, marginLeft: "1.30vw" }}>
                  <CommonDatePicker disable={false} nolabel={true} showPlaceHolder={true} placeholder={"DD-MM-YYYY"} type={"MI"} stateKey={"prescriptionDate"} value={props.prescriptionDate} onChangeText={props.onChangePrescriptionDate}  />
              </View> 

            </View>     
            <View style={{ marginTop: ".3vw", flexDirection: "column", flex: 1 }}>

              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} >
                {transmittedPrescriptionList && transmittedPrescriptionList.length > 0 && transmittedPrescriptionList.map((item, index) => {
                  return (
                    <View
                      style={{
                        marginVertical: ".15vw",
                        paddingVertical: ".3vw",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        backgroundColor: "#E2E2E2",
                        borderRadius: ".25vw"
                      }} 
                      key={index}
                    >
                      <View
                        style={{
                          width: "5%",
                          alignItems: "center",
                          paddingLeft:".8vw"
                        }}
                      >
                        <Text style={Style.contentTableTitle}>{index+1}</Text>
                      </View>
                      <View
                        style={{
                          width: "13%",
                        }}
                      >
                        <Image style={{ height: "1.95vw", width: "1.95vw", marginLeft: "0.85vw", borderRadius: "1.65vw" }} source={require('../../assets/images/sample_profile.png')} />

                      </View>
                      <View
                        style={{
                          width: "33%",
                          flexDirection: "column",
                          paddingLeft:'.5vw'
                        }}
                      >
                        <Text style={Style.popupTableDataFirst}>{item.patient_name}</Text>
                        <Text style={Style.popupTableDataSecond}>{item.patient_mobile_number}</Text>
                      </View>
                      <View
                        style={{
                          width: "38%",
                          flexDirection: "column",
                          paddingLeft:'.5vw'
                        }}
                      >
                        <Text style={Style.popupTableDataFirst}>{item.doctor_name}</Text>
                        <Text style={Style.popupTableDataSecond}>{item.patient_account_number || "-"}</Text>
                      </View>


                      <View
                        style={{
                          width: "10%",
                          alignItems: "center"
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <TouchableOpacity onPress={() => {
                            props.selectedTransmittedPresc(item)
                          }}
                            style={{ marginRight: "1.3vw" }}>
                            <Image style={{ height: "1.65vw", width: "1.65vw", marginLeft: "0.85vw", borderRadius: "1.30vw" }} source={require('../../assets/Icon/NextBill.png')} />
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  )
                })}
                {/* no data */}
                {!transmittedPrescriptionList || transmittedPrescriptionList.length === 0 ?
                  <View style={{ marginTop: "9.8vw", alignSelf: "center" }}><Text style={{fontSize:'1.1vw'}}>{"No prescription found"}</Text></View> : null
                }
              </ScrollView>
            </View>
          </View>
        </View>

      </View>
    </View>
  );
};

export const SavedBill = (props) => {

  const transmittedPrescriptionList = props.transmittedPrescriptionList
  const [conformationPopupFlag, setConformationPopup] = useState(false) 
  const [billSummaryId, setBillSummaryId] = useState("")
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: "100vh",
          width: "100vw",
          justifyContent: "center", // to be uncommented
          alignItems: "center", // to be uncommented
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            backgroundColor: "white",
            width: "29.5vw",
            height: "31vw",  //"500px",
            borderRadius: 0,
            marginTop: "4.6vw",
            position: "relative"
          }}
        >

          <View style={{ flex: 1, flexDirection: "column", padding: "1.3vw", backgroundColor: color.white, borderRadius: 0 }}>

            <View style={{ flexDirection: "row", flex: 1, maxHeight: "1.95vw", marginTop: "-.65vw", marginRight: "-.65vw", justifyContent: "space-between" }} >
              <Text style={{fontSize: "1vw", fontWeight:"bold", marginBottom:".65vw"}}>Saved Bills</Text>
              <TouchableOpacity onPress={props.closeModal}>
                <Icon name="close" size={"1.2vw"} color={"#000000"} />
              </TouchableOpacity>
            </View>
            <View style={{ flexDirection: "row", justifyContent: 'flex-start' }}>

              <PopupSearch placeHolderText={"Search bill ..."} searchText={props.searchText} changeSearchText={props.changeSearchText} searchPatient={props.searchPatient} />

            </View>
            <View style={{ flexDirection: "column", flex: 1, marginTop:'.3vw'  }}>

              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} >
                {transmittedPrescriptionList && transmittedPrescriptionList.length > 0 && transmittedPrescriptionList.map((item, index) => {

                  // bill_summary_id
                  let sno = index + 1

                  return (
                      <View
                        style={{
                          marginVertical: ".1vw",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          backgroundColor: "#E2E2E2",
                          borderRadius: ".3vw",
                          flex: 1,
                          minHeight : "auto"

                        }}
                      >
                        <TouchableOpacity key={index} onPress={() => { props.selectedSavedBill(item) }} 
                          style={{
                            marginVertical: ".9vw",
                            paddingVertical: ".3vw",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            backgroundColor: "#E2E2E2",
                            borderRadius: ".3vw",
                            flex: 0.05
                          }}
                        >
                          <Text style={[Style.contentTableTitle, { flex: 1, textAlign: "center", fontSize:'.8vw' }]}>{sno}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity key={index} onPress={() => { props.selectedSavedBill(item) }}
                          style={{
                            flex: 0.12
                          }}
                        >
                          <Image style={{ height: '1.95vw', width: "1.95vw", marginLeft: "0.5vw", borderRadius: "1.7vw" }} source={require('../../assets/images/sample_profile.png')} />
                        </TouchableOpacity>
                        <TouchableOpacity key={index} onPress={() => { props.selectedSavedBill(item) }} 
                          style={{
                            flex: 0.26,
                            flexDirection: "column",
                          }}
                        >
                          <Text style={Style.popupTableDataFirst}>{item.billing_customer_name || "-"}</Text>
                          <Text style={Style.popupTableDataSecond}>{item.billing_customer_mobile_no || "-"}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity key={index} onPress={() => { props.selectedSavedBill(item) }}
                          style={{
                            flex: 0.3,
                            flexDirection: "column",
                          }}
                        >
                          <Text style={Style.popupTableDataFirst}>{item.doctor_name || "-"}</Text>
                          <Text style={Style.popupTableDataSecond}>{item.patient_account_number || "-"}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity key={index} onPress={() => { props.selectedSavedBill(item) }}
                          style={{
                            flex: 0.2,
                            flexDirection: "column",
                          }}
                        >
                          <Text style={Style.popupTableDataFirst}>{item.created_date}</Text>
                          <Text style={Style.popupTableDataSecond}>{item.created_time}</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={()=>{ 
                            setConformationPopup(true)
                            setBillSummaryId(item.bill_summary_id)
                           }} 
                           style={{ flex: 0.05, alignSelf: "center"  }}>
                          <Icon name="close" size={"1vw"} color={"#000000"} />
                        </TouchableOpacity>
                      </View>
                  )
                })}
                {/* no data */}
                {!transmittedPrescriptionList || transmittedPrescriptionList.length === 0 ?
                  <View style={{ marginTop: "9.5vw", alignSelf: "center" }}><Text style={{fontSize:'1.1vw'}}>{"No saved bills"}</Text></View> : null
                }
              </ScrollView>
            </View>
          </View>
        </View>

      </View>
      {conformationPopupFlag ?
       <View style={{justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
       {transmittedPrescriptionList && transmittedPrescriptionList.length > 0 && transmittedPrescriptionList.map((item, index) => {
        return(
         <View style={{  position: 'absolute', width: "100%", height: "100%", alignItems: "center", flex: 1, justifyContent: "center", paddingLeft: ".65vw", zIndex: ".65vw" }}>
          <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: ".25vw", padding: "1.3vw" }}>
            <Text style={{ fontSize: "1.2vw", fontWeight: "bold", marginBottom: "10%" }}>{"Are you sure to discard ? "}</Text>

            <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "50%", flexWrap: "wrap-reverse" }}>

              <TouchableOpacity
                style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: ".25vw", width: "35%", paddingVertical: ".5vw" }}
                onPress={() => {
                   props.deleteSavedBill(billSummaryId)
                   setConformationPopup(false)
                  }}
              //  disabled = {this.state.isYesButton}
              >
                <Text style={{ color: 'white', fontSize: ".8vw", }}>{"Yes"}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: ".25vw", width: "35%", paddingVertical: ".5vw" }}
                onPress={() => { setConformationPopup(false) }}>
                <Text style={{ color: 'white', fontSize: ".8vw", }}>{"No"}</Text>
              </TouchableOpacity>

            </View>

          </View>
        </View> )
       })}

        </View> : null
      }
    </View>
  );
};


export const DrugBatchDetails = (props) => {

  const userType = props.userType
  const availableDrugBatchDetails = props.data

  return (

    <View
      style={{
        position: "absolute",
        backgroundColor: "white",
        width: '13vw',
        minHeight: '4.55vw', //200,
        borderRadius: 0,
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 3,
        marginTop: '1.95vw'
      }}
    >

      <View style={{ backgroundColor: color.white, borderRadius: 0 }}>
        <View style={{ flexDirection: "row", justifyContent: 'flex-end', flex: 1 }}>

          <TouchableOpacity onPress={() => {
            props.closePopup()
          }}
            style={{
              paddingTop: '0.65vw', paddingRight: '0.65vw'
            }}
          >
            <Icon name="window-close-o" size={'0.97vw'} color={color.themeDark} />
          </TouchableOpacity>

        </View>
        <View style={{
          flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start",
          borderColor: "grey", borderWidth: 1,
          margin: 5
        }}>
          <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>

            <BorderedTextView flexWidth={0.33} borderColor={"grey"} label={"Batch No"} rightBorder={true} />
            <BorderedTextView flexWidth={0.33} borderColor={"grey"} label={"Expiry Date"} rightBorder={true} />
            <BorderedTextView flexWidth={0.33} borderColor={"grey"} label={"Avl. Qty"} />

          </View>
          <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: '11.06vw' }}>
            {availableDrugBatchDetails && availableDrugBatchDetails.length > 0 && availableDrugBatchDetails.map((item, index) => {
              return (
                <TouchableOpacity key={index} onPress={() => props.selectBatch(item)}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignContent: "center",
                    }}
                  >
                    <BorderedTextView flexWidth={0.33} borderColor={"grey"} label={item.batch_no} rightBorder={true} />
                    <BorderedTextView flexWidth={0.33} borderColor={"grey"} label={item.expiry_date?moment(item.expiry_date).format("MM-YYYY"):""} rightBorder={true} />
                    <BorderedTextView flexWidth={0.33} borderColor={"grey"} label={item.stock_in_quantity} rightBorder={false} />

                  </View>
                </TouchableOpacity>
              )
            })}
            {
              !availableDrugBatchDetails || availableDrugBatchDetails.length === 0 ?
                <TouchableOpacity onPress={() => { props.closePopup() }}>
                  <BorderedTextView flexWidth={1} borderColor={"grey"} label={"No records to be shown"} norecords={true} />
                </TouchableOpacity> : null
            }
          </ScrollView>
        </View>
      </View>
    </View>
  );
};
export const PatientList=(props)=>{
  const appointmentList = props.appointmentSearchList
  const appointmentData = props.appointmentData
  
  const [current_page, setCurrent_page] = useState(1)
  const [records_per_page, setRecords_per_page] = useState(10)

  function _paginationChangedValues (next_page, records_per_page_change){
    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        setRecords_per_page(records_per_page_change)
        setCurrent_page(next_page)
        props.currentPage(next_page, records_per_page_change)
    }
}
  return(

    <View style={{ position:"absolute", marginLeft:"6vw", marginTop:"4.23vw", borderRadius:".3vw"}} >
      <TouchableWithoutFeedback onPress={props.closeModal}>
       <View 
        style={{position: "absolute",zIndex: -1,height: "90.7vh",width: "94vw",backgroundColor: "rgba(0, 0, 0, 0.5)"}}> 
      <TouchableWithoutFeedback onPress={props.showPatientSearchModal}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            backgroundColor: "white",
            width: "40%",
            maxHeight: screenHeight-(240),  //"500px",
            position: "relative",
            marginLeft:"1.30vw",
            borderBottomLeftRadius:".65vw",
            borderBottomRightRadius:".65vw",
          }}>
          <View style={{ flex: 1, flexDirection: "column", padding:"0.4vw",backgroundColor: color.white,borderRadius: ".3vw", height:'22vw'}}>
            <View style={{ flexDirection: "column", flex: 1 ,width:'100%',padding:"0.3vw"}}>

              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} >
                {appointmentList && appointmentList.length > 0 && appointmentList.map((item, index) => {
                  
                  // bill_summary_id
                  let sno = index + 1
                  return (
                      <View
                        style={{marginVertical: "0.2vw",  paddingVertical: ".65vw",flexDirection: "row",justifyContent: "flex-start",alignItems: "center",backgroundColor: "#E2E2E2",borderRadius: "0.3vw",padding:".4vw"}} 
                        key={index}>
                        <View
                          style={{marginVertical: "0.2vw", paddingVertical: "0.25vw",flexDirection: "row",justifyContent: "flex-start",alignItems: "center",backgroundColor: "#E2E2E2",borderRadius: "0.3vw"}}>
                          <Text style={{width:"1.4vw", fontSize:".95vw", display:'flex', alignItems:'center', justifyContent:'center'}} >{sno}</Text>
                        </View>
                        <View style={{width: "10%",alignItems: "center"}}>
                          <Image style={{ height: "2vw", width: "2vw", marginLeft: ".9vw", borderRadius: "1.65vw" }} source={require('../../assets/images/sample_profile.png')} />
                        </View>

                        <View style={{width: "35%",flexDirection: "column", marginLeft: ".7vw"}}>
                          <Text style={[Style.popupTableDataFirst,{fontWeight: 500, fontSize: ".95vw"}]}>{item.first_name || item.last_name ? item.first_name + " " + item.last_name || "|" : item.patient_name}</Text>
                          <View style={{ flexDirection: "row" }}>
                               <Text style={Style.popupTableDataFirst}>{item.patient_account_number ? item.patient_account_number || "-" : null}</Text>
                               <Text style={Style.popupTableDataFirst}>{item.patient_account_number ? " |" : null}</Text>
                               <Text style={[Style.popupTableDataFirst, {marginRight: ".7vw"}]}> {item.mobile_number || "-"}</Text>
                          </View>
                        </View>
                        <View
                          style={{width: "25%",flexDirection: "column",}}>
                          {/* <Text style={Style.popupTableDataFirst}>{item.doctor_name || "-"}</Text> */}
                        </View>
                        <View
                          style={{width: "20%",flexDirection: "column"}}>
                          <TouchableOpacity onPress={() => {
                            props.selectedSearchAppointmentList(item)
                          }}style={[{width:"7.85vw", marginRight: "1vw", paddingVertical: ".5vw", paddingHorizontal: ".7vw", backgroundColor: color.themeDark,}, Style.allButtonBorderRadius]}>
                            <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Create Bill"}</Text>
                          </TouchableOpacity>
                        </View>
                      </View>)
                      })
                }
                { !appointmentList || appointmentList.length === 0 ? 
                <View style={{ margin: "5vw", alignSelf: "center"}}><Text style={{fontSize:'1.5vw'}}>{"No records"}</Text></View>
                 : null }
              </ScrollView>
            </View>
            {/* {appointmentData ? <View style={{marginLeft:5}}>
              <Pagination  
                paginationChangedValues={_paginationChangedValues.bind(this)} 
                totalClientItems={""} 
                type={Constants.PAGINATION_TYPE_SERVER}
                totalServerRecordsCount={appointmentData.total_count? appointmentData.total_count : 0} 
                totalServerPagesCount={appointmentData.no_of_pages ? appointmentData.no_of_pages : 0}
              /></View>:null } */}
          </View>
        </View>
        </TouchableWithoutFeedback>
      </View>
      </TouchableWithoutFeedback>
    </View>
    )             
};

export const AppointmentList = (props) => {

  const appointmentList = props.appointmentList
  const appointmentData = props.appointmentData
  const [showAppointmentFilter,setShowAppointmentFilter] = useState(true)
  const [current_page, setCurrent_page] = useState(1)
  const [records_per_page, setRecords_per_page] = useState(10)

  function _paginationChangedValues (next_page, records_per_page_change){
        if( current_page != next_page  || records_per_page!=records_per_page_change) {
            setRecords_per_page(records_per_page_change)
            setCurrent_page(next_page)
            props.currentPage(next_page, records_per_page_change)
        }
  }
  return (
    <View style={{ position: "absolute" }}>
      <View
        style={{
          position: "absolute",
          zIndex: -1,
          height: "100vh",
          width: "100vw",
          justifyContent: "center", // to be uncommented
          alignItems: "center", // to be uncommented
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            backgroundColor: "white",
            width: "29.3vw",
            height: "31vw",  //"500px",
            borderRadius: ".1vw",
            marginTop: "3.25vw",
            position: "relative"
          }}
        >

          <View style={{ flex: 1, flexDirection: "column", padding: "1.30vw", backgroundColor: color.white, borderRadius: 0 }}>

            <View style={{ flexDirection: "row", flex: 1, maxHeight: "1.95vw", marginTop: "-0.65vw", marginRight: "-0.65vw", justifyContent: "space-between" }} >
              <Text style={{fontSize: "1vw", fontWeight:"bold", marginBottom:"0.65vw"}}>Appointments</Text>
              <TouchableOpacity onPress={props.closeModal}>
                <Icon name="close" size={"1.17vw"} color={"#000000"} />
              </TouchableOpacity>
            </View>
            <View style={{ flexDirection: "row", justifyContent: 'flex-start' }}>

              <View style={{ flexBasis: "13.5vw", flexGrow: 1, flexShrink: 0, marginRight: "1vw" }}>
                <PopupSearch 
                  placeHolderText={"Search patient..."} changeSearchText={props.changeSearchText} searchPatient={props.searchAppointment} closeModal={props.closeModal} />
              </View>
                <View style={{ flexBasis: "9.8vw", flexGrow: 0, flexShrink: 0 }}>
                  <CommonDatePicker disable={false} nolabel={true} showPlaceHolder={true} placeholder={"DD-MM-YYYY"} type={"MI"} stateKey={"appointmentDate"} value={props.appointmentDate || null} onChangeText={props.onChangeAppointmentDate} />
                </View> 

            </View>
            <View style={{ marginTop: ".3vw", flexDirection: "column", flex: 1 }}>

              <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} >
                {appointmentList && appointmentList.length > 0 && appointmentList.map((item, index) => {
                  
                  // bill_summary_id
                  let sno = (current_page-1)*records_per_page + index + 1;

                  return (
                      <View
                        style={{
                          marginVertical: ".2vw",
                          paddingVertical: ".3vw",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          backgroundColor: "#E2E2E2",
                          borderRadius: ".26vw"
                        }} 
                        key={index}
                      >
                        <View
                          style={{
                            marginVertical: ".2vw",
                            paddingVertical: ".3vw",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            backgroundColor: "#E2E2E2",
                            borderRadius: ".26vw",
                            paddingLeft:".5vw"
                          }}
                        >
                          <Text style={Style.contentTableTitle}>{sno}</Text>
                        </View>
                        <View
                          style={{
                            width: "12%",
                          }}
                        >
                          <Image style={{ height: "1.95vw", width: "1.95vw", marginLeft: "0.85vw", borderRadius: "1.62vw" }} source={require('../../assets/images/sample_profile.png')} />

                        </View>
                        <View
                          style={{
                            width: "31%",
                            flexDirection: "column",
                          }}
                        >
                          <Text style={Style.popupTableDataFirst}>{item.patient_name || "-"}</Text>
                          <Text style={Style.popupTableDataSecond}>{item.patient_mobile_number || "-"}</Text>
                        </View>
                        <View
                          style={{
                            width: "31%",
                            flexDirection: "column",
                          }}
                        >
                          <Text style={Style.popupTableDataFirst}>{item.doctor_name || "-"}</Text>
                        </View>


                        <View
                          style={{
                            width: "25%",
                            flexDirection: "column",
                          }}
                        >
                          <TouchableOpacity onPress={() => {
                            props.selectedappointmentList(item)
                          }}
                            style={[{
                              marginRight: "1vw", paddingVertical: ".52vw", paddingHorizontal: ".65vw", backgroundColor: color.themeDark,
                            }, Style.allButtonBorderRadius]}>
                            <Text style={[{ color: color.white, textAlign: 'center' }, Style.fontSizeMedium]}>{"Create Bill"}</Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                  )
                })}
                {/* no data */}
                {!appointmentList || appointmentList.length === 0 ?
                  <View style={{ marginTop: "9.8vw", alignSelf: "center" }}><Text style={{fontSize:'1vw'}}>{"No appointments"}</Text></View> : null
                }
              </ScrollView>
            </View>
            {appointmentData ? 
              <Pagination 
                paginationChangedValues={_paginationChangedValues.bind(this)} 
                totalClientItems={""} 
                type={Constants.PAGINATION_TYPE_SERVER}
                totalServerRecordsCount={appointmentData.total_count? appointmentData.total_count : 0} 
                totalServerPagesCount={appointmentData.no_of_pages ? appointmentData.no_of_pages : 0}
              />:null
            }
          </View>
        </View>

      </View>
    </View>
  );
};

export const BorderedTextView = (props) => {

  let flexWidth = props.flexWidth ? props.flexWidth : 1;
  let borderColor = props.borderColor ? props.borderColor : "grey";

  return (
    <View style={{
      flex: flexWidth, alignItems: "center",
      borderColor: borderColor, borderRightWidth: props.rightBorder ? 1 : 0,
      borderTopWidth: props.norecords ? 1 : 0,
      paddingVertical: props.norecords ? 15 : 5
    }}>
      <Text style={{ fontSize: '0.65vw', color: props.norecords ? "grey" : "black" }}>{props.label}</Text>
    </View>
  )
}

export const ActionButton = (props) => {
  let pageType = props.pageType ? props.pageType : ""
  let isDisable = props.isDisable ? props.isDisable : false;
  let imgPath = props.imgPath? props.imgPath: false

  return (
    <View style={{width: props.width ? props.width : ""}}>
      <TouchableOpacity disabled = {isDisable} onPress={() => {
        if (!isDisable) {
          props.keyIndex ? props.onPressAction(props.keyIndex, pageType) : null
        }
      }}
        style={[{
          marginRight: 15, paddingVertical: '0.52vw', paddingHorizontal: '0.65vw', backgroundColor: isDisable?color.lightGray:color.themeDark, 
          flexDirection: "row", justifyContent: "center", alignContent: "center"
        }, Style.allButtonBorderRadius]}>
          {props.imgPath?
          <Image source={props.imgPath} style={{ width: "1.30vw", height: '0.97vw', marginRight: 5 }} />
          :null}
          <Text style={[{ color: color.white, textAlign: 'center', fontSize: '1vw' }, Style.fontSizeMedium]}>{props.label}</Text>
      </TouchableOpacity>
    </View>
  )
}

export const SuccessAlert = (props) => {
  return (
    <View style={{ flex: 1, zIndex: 3, width: "40%", position: 'absolute', left: '0.65vw', bottom: "0.32vw", right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#DEF2D6", borderRadius: "0.32vw", borderColor: "#B8C7B2", borderWidth: 1, height: '4.55vw', marginRight: '0.65vw', marginTop: '0.65vw' }}>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: '0.65vw', flex: 0.95 }}>
        <Icon size={'1.62vw'} name={"check"} color={"#FFF"} />
        <Text style={{ fontWeight: "700", color: "green", fontSize: '0.78vw', marginLeft: '0.97vw' }}>{"SUCCESS: "}
          <Text style={{ fontWeight: "400", color: "green", fontSize: '0.78vw' }}>{props.message}</Text>
        </Text>
      </View>
      <TouchableOpacity onPress={() => { }} style={{ flex: 0.05, marginRight: '0.97vw', alignItems: 'center', alignContent: 'center', alignSelf: 'center', justifyContent: "center" }}>
        <Icon size={'1.62vw'} name={"close"} color={"green"} />
      </TouchableOpacity>
    </View>
  );
}

export const ErrorAlert = (props) => {

  let errorList = (props.message) ? (props.message + '').split(",") : []
  console.log(" errorList " + errorList)

  return (
    <View style={{ flex: 1, zIndex: 3, width: "35%", minWidth: '13vw', position: 'absolute', left: '0.65vw', bottom: "0.32vw", right: 0, alignItems: 'center', justifyContent: 'space-between', flexDirection: "row", backgroundColor: "#CD3A34", borderRadius: "0.32vw", borderColor: "#A58B8A", borderWidth: 1, minHeight: '4.55vw', marginRight: '0.65vw', marginTop: '0.65vw' }}>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: '0.97vw', marginLeft: '0.65vw', flex: 0.95 }}>
        <View style={{ flex: 0.1, alignSelf: "flex-start", marginBottom: '0.65vw' }}>
          <Icon size={'1.62vw'} name={"ban"} color={"#FFF"} /></View>
        <View style={{ flex: 0.9, flexDirection: "column", justifyContent: "flex-start" }} >
          <View>
            <Text style={{ fontWeight: "700", color: "#FFF", fontSize: '0.78vw' }}>{"ERROR: "}</Text>
          </View>
            <View style={{ flex: 0.9, flexDirection: "column", justifyContent: "flex-start" }} >
              {errorList && errorList.map((item, index) => {
                return <View key={index} style={{ flex: 1 }}><Text style={{ fontWeight: "400", color: "#FFF", fontSize: '0.78vw', marginBottom: '0.52vw' }}>{item}</Text></View>
              })}
            </View>

        </View>
      </View>
      
    </View>
  );
}

export const PurchaseDetailsHeader = (props) => {
  return (
    <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
      <CommonAutoSuggestion width={"16.27vw"} placeholder={"Vendor Name"} type={"MI"} list={props.list} isSelected={props.isSelected} stateKey={"vendor_name"} value={props.vendor_name}
        onChangeText={props.onChangeText} itemSelected={props.itemSelected} objFormat={props.objFormat} />
      <CommonTextBox disable={true} placeholder={"Purchase Order No"} type={"MI"} stateKey={"po_number"} value={props.po_number} onChangeText={props.onChangeText} />
      <CommonDatePicker disable={true} placeholder={"Date"} type={"MI"} stateKey={"po_date"} value={props.po_date} onChangeText={props.onChangeText} />
    </View>
  )
}
export const CommonTextBox = (props) => {
  return (
    <View style={{ width: props.width ? props.width : "7.8vw", justifyContent: props.align ? props.align : 'center', marginRight:(props.placeholder === "Qty")? 0 : '0.97vw', backgroundColor: props.disable ? color.themeShadeBackground : "white"}}>
      <Text style={{ zIndex: 2, fontSize: '0.7vw', position: "absolute", marginTop: '-2.2vw', marginLeft: '0.65vw', backgroundColor: props.type == "IDP" ? color.themeShade : "white", paddingHorizontal: '0.32vw', color: "#888888" }}>
        {props.placeholder}</Text>

      { props.stateKey == "buying_price" ? 
        <Tooltip title = { props.previousPriceDetails ? "Previous buying price - ₹ " + props.previousPriceDetails : null }>
        <TextInput
            style={{
              borderColor: props.error ? "red" : "#CDD1D5",
              borderRadius: 4,
              borderWidth: 1,
              width: "100%",
              height: '2.27vw',
              padding: '0.9vw',
              fontSize:'0.9vw'
              
            }} 
            value={props.value || ""} 
            keyboardType={props.stateKey == "billing_customer_mobile_no" ? "numeric" : null}
            disabled={props.disable}
            onChangeText={(text) => {
              props.onChangeText(props.stateKey, text, props.type);
            }}
          />
        </Tooltip> 
        : 
        <TextInput
            style={{
              borderColor: props.error ? "red" : "#CDD1D5",
              borderRadius: 4,
              borderWidth: 1,
              width: "100%",
              height: '2.27vw',
              padding: '0.9vw',
              fontSize:'0.9vw'
            }} 
            value={props.value} 
            keyboardType={(props.stateKey == "billing_customer_mobile_no" || props.stateKey === "doctor_reg_no" || props.stateKey == "editStockQty" )? "numeric" : null}
            disabled={props.disable}
            onChangeText={(text) => {
              props.onChangeText(props.stateKey, text, props.type);
            }}
          />
      }
    </View>
  )
}

export const MultiLineCommonTextBox = (props) => {
  return (
    <View style={{ width: props.width ? props.width : '10.5vw', justifyContent: props.align ? props.align : 'center', marginRight: '1vw' }}>
      <Text style={{ zIndex: 2, fontSize: '1vw', position: "absolute", marginTop: -93, marginLeft: '.6vw', backgroundColor: props.type == "IDP" ? color.themeShade : "white", paddingHorizontal: '.3vw', color: "#888888" }}>
        {props.placeholder}</Text>

        <TextInput
            style={{
              borderColor: props.error ? "red" : "#CDD1D5",
              borderRadius: 4,
              borderWidth: 1,
              width: "100%",
              padding: '1vw' 
            }} 
            value={props.value || ""} 
            disabled={props.disable}
            onChangeText={(text) => {
              props.onChangeText(props.stateKey, text, props.type);
            }} 
            multiline = {true}
            numberOfLines = {props.numberOfLines?props.numberOfLines:2}
          />
    </View>
  )
}

export const LineItemTextBox = (props) => {
  return (
    <View style={{ flexDirection: "column" }}>
      {
        <TextInput
          style={{
            // flex: 1,
            height: '2vw',
            padding: 6,
            marginRight: 15,
            backgroundColor: props.disable?color.tableHeaderBG:color.white,
            borderColor: "#CDD1D5",
            borderRadius: 4,
            borderWidth: 1,
            fontSize: '0.71vw',
          }}
          editable={props.disable ? false : true} 
          value={props.value || ""}
          keyboardType = {props.keyboardType}
          onChangeText={(text) => {
            props.onChangeText(props.stateKey, text, props.type, props.index);
          }}
        />
      }
    </View>
  )
}

export const CommonDatePicker = (props) => {
  
  let type=props.type?props.type:"";
  
  return (
    <View style={[styles.CommonDatePickerView,{backgroundColor: props.disable ? color.themeShadeBackground : color.white}]}>

      {!props.nolabel ?
        <Text style={styles.CommonDatePickerText}>
          {props.placeholder}</Text> : null}

      {props.disable ?
        <Text style={[Style.commonTextBoxStyles, {
          zIndex: -3,
          color: color.labelColor, paddingTop: '0.65vw', fontSize:'1vw'
        }]} >
          {moment(props.value).format(dateFormatDisplay)}
        </Text> :
        type=="expiry-date"?
        <DatePicker
          disabledDate={current => {
            return current && current < moment().add(0, "days");
          }}
          onChange={date => {
            
            let exp_dt = "";
            if (date) 
            {
              var fulldate = new Date(date);
              // for expiry date, last date of month
              exp_dt = new Date(fulldate.getFullYear(), fulldate.getMonth()+1, 0)
            }
            // set 
            props.onChangeText(props.stateKey, exp_dt, props.type);
          }}
          defaultValue={""}
          placeholder={expiryDateFormat}
          format={expiryDateFormat}
          value={
            props.value ? moment(props.value, dateFormat) : ""
          }
          style={{
            zIndex: -1,
            width: '100%',
            borderColor: '#CDD1D5',
            height: props.noborder ? '1.3vw' : '2.27vw',
            borderRadius: 4,
            borderWidth: props.noborder ? 0 : 1,
            padding: props.noborder ? 0 : '0.9vw'           
          }} 
          picker={"month"}
        />:
        <DatePicker allowClear={props.allowClear}
          disabledDate={current => {
            return current && current > moment().add(0, "days");
          }}
          onChange={date => {

            let converted_date = "";

            if (date) {
              var fulldate = new Date(date);
              converted_date = moment(fulldate).format(dateFormat);
            }
            props.onChangeText(props.stateKey, date, props.type);

          }}
          defaultValue={""}
          placeholder={props.showPlaceHolder?props.placeholder:""}
          format={dateFormatDisplay}
          value={
            props.value ? moment(props.value, dateFormat) : ""
          }
          style={{
            zIndex: -1,
            width: '100%',
            borderColor: '#CDD1D5',
            height: props.noborder ? '1.30vw' : '2.27vw',
            borderRadius: 4,
            borderWidth: props.noborder ? 0 : 1,
            padding: props.noborder ? 0 : '0.97vw',
            fontSize:'1vw'
          }} 
        />}
    </View>
  )
}

export const CommonAutoSuggestion = (props) => {

  let objKeys = props.objFormat || null;

  return (
    <View style={{ width: props.width ? props.width : "9.76vw", justifyContent: 'center', marginRight: "1vw" }}>
      <Text style={{ zIndex: 2, fontSize: ".8vw", position: "absolute", marginTop: "-2.14vw", marginLeft: "0.65vw", backgroundColor: props.type == "IDP" ? color.themeShade : "white", paddingHorizontal: ".3vw", color: "#888888" }}>
        {props.placeholder}</Text>

      {
        props.disable ?
          <Text style={[Style.commonTextBoxStyles, {
            zIndex: -3,
            color: color.labelColor, paddingTop: '0.3vw', flexWrap: "nowrap"
          }]} >
            {props.value}
          </Text> :
          <View>
            <TextInput
              style={{
                borderColor: props.error ? "red" : "#CDD1D5",
                borderRadius: 4,
                borderWidth: 1,
                width: "100%",
                height: "2.27vw",
                padding: "1vw",
                fontSize:'1vw'
              }} value={props.value}
              onChangeText={(text) => {
                props.onChangeText(props.stateKey, text, props.type);
              }}

            />
            {!props.isSelected && (props.list && props.list.length > 0 && props.value.length > 0) ?
              <View style={{
                borderRadius: 10,
                width: '90%',
                justifyContent: 'center',
                alignContent: 'center',
                padding: "0.65vw",
                marginLeft: "0.65vw",
                marginTop: "1.95vw",
                position: 'absolute',
                backgroundColor: color.themeBlue
              }}>
                <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: "13.02vw" }}>
                  <FlatList
                    data={props.list}
                    renderItem={({ item }) => (
                      item ?
                        <View style={{
                          flexDirection: "row",
                          bottom: "0.65vw",
                          marginBottom: "0.65vw",
                          justifyContent: "space-evenly",
                          width: "100%",
                          alignItems: "center",
                          marginTop: "0.65vw"
                        }}
                        >
                          <View style={{ width: '100%' }}>
                            <TouchableOpacity
                              onPress={() => {

                                props.itemSelected(item, props.stateKey, objKeys)
                              }}
                            >
                              <Text style={{ color: color.white, fontSize:'1vw' }}>{objKeys ? item[objKeys['keyName']] : item}</Text>
                            </TouchableOpacity>
                          </View>
                        </View> : null
                    )}
                    enableEmptySections={true}
                  />
                </ScrollView>
              </View> : null}
          </View>
      }
    </View>
  )
}

export const CommonAutoSuggestionFull = (props) => {

  let objKeys = props.objFormat || null; 
  let showList = props.showList!=undefined?props.showList:true 

  return (
    <View style={{ width: props.width ? props.width : '9.7vw', justifyContent: 'center', marginRight: '0.97vw' }}>
      <Text style={{ zIndex: 2, fontSize: '0.7vw', position: "absolute", marginTop: '-2.2vw', marginLeft:'0.65vw', backgroundColor: props.type == "IDP" ? color.themeShade : "white", paddingHorizontal: 5, color: "#888888" }}>
        {props.placeholder}</Text>

      {
        props.disable ?
         <Tooltip placement="topLeft" title={props.value}>               
           <Text style={[Style.commonTextBoxStyles, {
            zIndex: -3,
            color: color.labelColor, padding: '0.6vw', fontSize:'0.9vw'
           }]} > {props.value && props.value.length > 20 ? props.value.slice(0,20) + ".." : props.value} </Text>
         </Tooltip> 
            :
          <View>
            <TextInput
              style={{
                borderColor: props.error ? "red" : "#CDD1D5",
                borderRadius: 4,
                borderWidth: 1,
                width: "100%",
                height: '2.27vw',
                padding: '0.9vw',
                fontSize: '0.9vw'
              }} 
              value={props.value} 
              onChangeText={(text) => {
                props.onChangeText(props.stateKey, text, props.type);
              }} 
              onFocus={()=>props.onFocusInput?props.onFocusInput(props.stateKey, props.reference?props.reference.current:null):null} 
              onBlur={()=>props.onBlurInput?props.onBlurInput(props.stateKey):null} 
              ref={props.reference || null} 
              editable= {props.stateKey == 'generic_name' || props.stateKey == 'dosage_type' || props.stateKey == 'dosage_strength' ? false : true}
            />
            {showList && !props.isSelected && (props.list && props.list.length > 0) ?
              <View style={{
                borderRadius: 10,
                width: '90%',
                justifyContent: 'center',
                alignContent: 'center',
                padding: 10,
                marginLeft: 10,
                marginTop: 30,
                position: 'absolute',
                backgroundColor: color.themeBlue
              }}>
                <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                  <FlatList
                    data={props.list}
                    renderItem={({ item }) => (
                      item ?
                        <View style={{
                          flexDirection: "row",
                          bottom: 10,
                          marginBottom: 10,
                          justifyContent: "space-evenly",
                          width: "100%",
                          alignItems: "center",
                          marginTop: 10
                        }}
                        >
                          <View style={{ width: '100%' }}>
                            <TouchableOpacity
                              onPress={() => {

                                props.itemSelected(item, props.stateKey, objKeys)
                              }}
                            >
                              <Text style={{ color: color.white, fontSize:'0.91vw' }}>{objKeys ? item[objKeys['keyName']] : props.stateKey == 'generic_name' ? item['display_name'] : item}</Text>
                            </TouchableOpacity>
                          </View>
                        </View> : null
                    )}
                    enableEmptySections={true}
                  />
                </ScrollView>
              </View> : null}
          </View>
      }
    </View>
  )
}

export const CommonLabelView = (props) => {
  return (
    <Text style={{ color: color.labelColor, fontSize: '0.8vw', width: props.minWidth ? props.minWidth : '6.5vw' }}>{props.label}</Text>
  )
}

export const CommonLabelTextView = (props) => {
  return (
    <Text style={{ fontSize: '0.9vw', fontWeight: "500" }}>{props.label}</Text>
  )
}

export const CommonLabelTextMView = (props) => {
  return (
    <Text style={{ color: color.labelColor, fontSize: '0.9vw', fontWeight: "500", 
      textAlign: props.align?props.align:"left", 
      paddingRight: props.padRight?props.padRight:null 
    }}>{props.label}</Text>
  )
}

// Start Common section Header

export const CommonReportSectionHeader = (props) => {
  let columnSizeH1 = "";
  let columnSizeH2 = "";
  let columnSizeH3 = "";
  let columnSizeH4 = "";
  let columnSizeH5 = "";
  let columnSizeH6 = "";
  let columnSizeH7 = "";
  let columnSizeH8 = "";
  let columnSizeH9 = "";
  let columnSizeH10 = "";
  let columnSizeH11 = "";
  let columnSizeH12 = "";
  let columnSizeH13 = "";
  let columnSizeH14 = "";
  let columnSizeH15 = "";
  let columnSizeH16 = "";
  let columnSizeH17 = "";
  let columnSizeH18 = "";
  let columnSizeH19 = "";

  if (props.columnSize && props.columnSize.length > 1) {
    if (props.noOfColumn == 2) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1];
    } else if (props.noOfColumn == 3) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2];
    } else if (props.noOfColumn == 4) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
    } else if (props.noOfColumn == 5) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4];
    } else if (props.noOfColumn == 6) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5];
    } else if (props.noOfColumn == 7) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6];
    } else if (props.noOfColumn == 8) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7];
    }
    else if (props.noOfColumn == 9) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8];
    }
    else if (props.noOfColumn == 10) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9];
    }
    else if (props.noOfColumn == 11) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10];
    }
    else if (props.noOfColumn == 12) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10]; columnSizeH12 = props.columnSize[11];
    }
    else if (props.noOfColumn == 13) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10]; columnSizeH12 = props.columnSize[11]; 
      columnSizeH13 = props.columnSize[12];
    }
    else if (props.noOfColumn == 14) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10]; columnSizeH12 = props.columnSize[11]; 
      columnSizeH13 = props.columnSize[12]; columnSizeH14 = props.columnSize[13];
    } 
    else if (props.noOfColumn == 15) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10]; columnSizeH12 = props.columnSize[11]; 
      columnSizeH13 = props.columnSize[12]; columnSizeH14 = props.columnSize[13]; columnSizeH15 = props.columnSize[14];
    }
    else if (props.noOfColumn == 19) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10]; columnSizeH12 = props.columnSize[11]; 
      columnSizeH13 = props.columnSize[12]; columnSizeH14 = props.columnSize[13]; columnSizeH15 = props.columnSize[14]; columnSizeH16 = props.columnSize[15]; columnSizeH17 = props.columnSize[16]; columnSizeH18 = props.columnSize[17]; columnSizeH19 = props.columnSize[18]; 
    }
    else if (props.noOfColumn == 15) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10]; columnSizeH12 = props.columnSize[11]; 
      columnSizeH13 = props.columnSize[12]; columnSizeH14 = props.columnSize[13]; columnSizeH15 = props.columnSize[14];
    }
    else if (props.noOfColumn == 16) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10]; columnSizeH12 = props.columnSize[11]; 
      columnSizeH13 = props.columnSize[12]; columnSizeH14 = props.columnSize[13]; columnSizeH15 = props.columnSize[14]; columnSizeH16 = props.columnSize[15];
    }
    else if (props.noOfColumn == 17) {
      columnSizeH1 = props.columnSize[0]; columnSizeH2 = props.columnSize[1]; columnSizeH3 = props.columnSize[2]; columnSizeH4 = props.columnSize[3];
      columnSizeH5 = props.columnSize[4]; columnSizeH6 = props.columnSize[5]; columnSizeH7 = props.columnSize[6]; columnSizeH8 = props.columnSize[7]; columnSizeH9 = props.columnSize[8]; columnSizeH10 = props.columnSize[9]; columnSizeH11 = props.columnSize[10]; columnSizeH12 = props.columnSize[11]; 
      columnSizeH13 = props.columnSize[12]; columnSizeH14 = props.columnSize[13]; columnSizeH15 = props.columnSize[14]; columnSizeH16 = props.columnSize[15]; columnSizeH17 = props.columnSize[16];
    }
  }

  let fontSize = props.fontSize ? props.fontSize : null
  return (
    <View style={{ justifyContent: 'center', height: "2.3vw", backgroundColor: "#E2E2E2", width: "100%" }}>
      {
        props.noOfColumn == 1 ?
          <Text style={[Style.historyHeaderFontStyleStartText, props.headerKey == "roomsDetails" ? { fontSize: '0.91vw' } : {}, {fontSize: fontSize ? fontSize : '1vw'}]}>{props.heading1}</Text> :
          props.noOfColumn == 2 ?
            <View style={[{ flexDirection: 'row' }, columnSizeH1 && columnSizeH2 ? {} : { justifyContent: "space-between" }]}>
              <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1, fontSize: fontSize }]}>{props.heading1}</Text>
              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, paddingRight: '1.95vw', fontSize: fontSize }]}>{props.heading2}</Text>
            </View> :
            props.noOfColumn == 3 ?
              <View style={{ flexDirection: 'row' }}>
                <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.33 }, props.headerKey == "reportHeader" ? { marginLeft: "3.25vw" } : {}]}>{props.heading1}</Text>
                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.33 }]}>{props.heading2}</Text>
                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.33 }]}>{props.heading3}</Text>
              </View> :
              props.noOfColumn == 4 ?
                <View style={{ flexDirection: 'row' }}>
                 <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.3, fontSize: '1vw', marginLeft: '1.30vw' }]}>{props.heading1}</Text>
                 <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.3, fontSize: '1vw' }]}>{props.heading2}</Text>
                 <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.3, fontSize: '1vw' }]}>{props.heading3}</Text>
                 <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.1, fontSize: '1vw' }]}>{props.heading4}</Text>
                </View> :
                props.noOfColumn == 5 ?
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.3 }]}>{props.heading1}</Text>
                    <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.3 }]}>{props.heading2}</Text>
                    <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.3 }]}>{props.heading3}</Text>
                    <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.3 }]}>{props.heading4}</Text>
                    <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 ? columnSizeH5 : 0.3 }]}>{props.heading5}</Text>
                  </View> :
                  props.noOfColumn == 6 ?
                    <View style={{ flexDirection: 'row' ,justifyContent:'center'}}>
                      <Text style={[Style.historyHeaderFontStyleStartText, { flex: columnSizeH1 ? columnSizeH1 : 0.3, fontSize:  props.fontSize ? props.fontSize : '1vw' }]}>{props.heading1}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 ? columnSizeH2 : 0.3, fontSize:  props.fontSize ? props.fontSize : '1vw'}]}>{props.heading2}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 ? columnSizeH3 : 0.3, fontSize:  props.fontSize ? props.fontSize : '1vw' }]}>{props.heading3}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 ? columnSizeH4 : 0.3, fontSize:  props.fontSize ? props.fontSize : '1vw' }]}>{props.heading4}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 ? columnSizeH5 : 0.3, fontSize:  props.fontSize ? props.fontSize : '1vw' }]}>{props.heading5}</Text>
                      <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6 ? columnSizeH6 : 0.3, fontSize:  props.fontSize ? props.fontSize : '1vw' }]}>{props.heading6}</Text>
                    </View> : props.noOfColumn == 7 ?
                      <View style={[{ flexDirection: 'row' }]}>
                        <Text style={[Style.historyHeaderFontStyle, { marginLeft: '1.30vw', flex: columnSizeH1, fontSize: '1vw' }]}>{props.heading1}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: '1vw' }]}>{props.heading2}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: '1vw' }]}>{props.heading3}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: '1vw' }]}>{props.heading4}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: '1vw' }]}>{props.heading5}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: '1vw' }]}>{props.heading6}</Text>
                        <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: '1vw' }]}>{props.heading7}</Text>
                      </View> :
                      props.noOfColumn == 8 ?
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, marginLeft: 20 }]}>{props.heading1}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2 }]}>{props.heading2}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3 }]}>{props.heading3}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4 }]}>{props.heading4}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5 }]}>{props.heading5}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6 }]}>{props.heading6}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7 }]}>{props.heading7}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8 }]}>{props.heading8}</Text>
                        </View> :
                        props.noOfColumn == 9 ?
                          <View style={{ flexDirection: 'row' }}>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, fontSize: '1vw', marginLeft: '1.30vw' }]}>{props.heading1}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: '1vw' }]}>{props.heading2}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: '1vw' }]}>{props.heading3}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: '1vw' }]}>{props.heading4}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: '1vw' }]}>{props.heading5}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: '1vw' }]}>{props.heading6}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: '1vw' }]}>{props.heading7}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8, fontSize: '1vw' }]}>{props.heading8}</Text>
                            <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9, fontSize: '1vw' }]}>{props.heading9}</Text>
                          </View> :
                          props.noOfColumn == 10 ?
                            <View style={{ flexDirection: 'row', width: "100%", flex: 1, alignItems:'center'}}>
                              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, fontSize: '1vw', marginLeft: '1.30vw' }]}>{props.heading1}</Text>
                              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: '1vw' }]}>{props.heading2}</Text>
                              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: '1vw' }]}>{props.heading3}</Text>
                              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: '1vw' }]}>{props.heading4}</Text>
                              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: '1vw' }]}>{props.heading5}</Text>
                              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: '1vw' }]}>{props.heading6}</Text>
                              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: '1vw' }]}>{props.heading7}</Text>
                              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8, fontSize: '1vw' }]}>{props.heading8}</Text>
                              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9, fontSize: '1vw' }]}>{props.heading9}</Text>
                              <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10, fontSize: '1vw' }]}>{props.heading10}</Text>
                            </View> :
                            props.noOfColumn == 11 ?
                              <View style={{ flexDirection: 'row',alignItems:'center' }}>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, fontSize: '1vw', marginLeft: '1.30vw' }]}>{props.heading1}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: '1vw' }]}>{props.heading2}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: '1vw' }]}>{props.heading3}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: '1vw' }]}>{props.heading4}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: '1vw' }]}>{props.heading5}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: '1vw' }]}>{props.heading6}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: '1vw' }]}>{props.heading7}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8, fontSize: '1vw' }]}>{props.heading8}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9, fontSize: '1vw' }]}>{props.heading9}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10, fontSize: '1vw' }]}>{props.heading10}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH11, fontSize: '1vw' }]}>{props.heading11}</Text>
                                <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH12, fontSize: '1vw' }]}>{props.heading12}</Text>
                              </View> :
                              props.noOfColumn == 12 ?
                                <View style={{ flexDirection: 'row',alignItems:'center' }}>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, fontSize: '1vw', marginLeft: '1.30vw' }]}>{props.heading1}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: '1vw' }]}>{props.heading2}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: '1vw' }]}>{props.heading3}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: '1vw' }]}>{props.heading4}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: '1vw' }]}>{props.heading5}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: '1vw' }]}>{props.heading6}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: '1vw' }]}>{props.heading7}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8, fontSize: '1vw' }]}>{props.heading8}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9, fontSize: '1vw' }]}>{props.heading9}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10, fontSize: '1vw' }]}>{props.heading10}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH11, fontSize: '1vw' }]}>{props.heading11}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH12, fontSize: '1vw' }]}>{props.heading12}</Text>
                                </View> :
                                props.noOfColumn == 13 ?
                                <View style={{ flexDirection: 'row',alignItems:'center' }}>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, fontSize: '1vw', marginLeft: '1.30vw' }]}>{props.heading1}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: '1vw' }]}>{props.heading2}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: '1vw' }]}>{props.heading3}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: '1vw' }]}>{props.heading4}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: '1vw' }]}>{props.heading5}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: '1vw' }]}>{props.heading6}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: '1vw' }]}>{props.heading7}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8, fontSize: '1vw' }]}>{props.heading8}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9, fontSize: '1vw' }]}>{props.heading9}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10, fontSize: '1vw' }]}>{props.heading10}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH11, fontSize: '1vw' }]}>{props.heading11}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH12, fontSize: '1vw' }]}>{props.heading12}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH13, fontSize: '1vw' }]}>{props.heading13}</Text>
                                </View> :
                                props.noOfColumn == 14 ?
                                <View style={{ flexDirection: 'row',alignItems:'center' }}>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, fontSize: '1vw', marginLeft: '1.3vw' }]}>{props.heading1}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: '1vw' }]}>{props.heading2}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: '1vw' }]}>{props.heading3}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: '1vw' }]}>{props.heading4}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: '1vw' }]}>{props.heading5}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: '1vw' }]}>{props.heading6}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: '1vw' }]}>{props.heading7}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8, fontSize: '1vw' }]}>{props.heading8}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9, fontSize: '1vw' }]}>{props.heading9}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10, fontSize: '1vw' }]}>{props.heading10}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH11, fontSize: '1vw' }]}>{props.heading11}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH12, fontSize: '1vw' }]}>{props.heading12}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH13, fontSize: '1vw' }]}>{props.heading13}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH14, fontSize: '1vw' }]}>{props.heading14}</Text>

                                </View> :
                                props.noOfColumn == 16 ?
                                <View style={{ flexDirection: 'row',alignItems:'center' }}>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, fontSize: '1vw', marginLeft: '1.3vw' }]}>{props.heading1}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: '1vw' }]}>{props.heading2}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: '1vw' }]}>{props.heading3}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: '1vw' }]}>{props.heading4}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: '1vw' }]}>{props.heading5}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: '1vw' }]}>{props.heading6}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: '1vw' }]}>{props.heading7}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8, fontSize: '1vw' }]}>{props.heading8}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9, fontSize: '1vw' }]}>{props.heading9}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10, fontSize: '1vw' }]}>{props.heading10}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH11, fontSize: '1vw' }]}>{props.heading11}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH12, fontSize: '1vw' }]}>{props.heading12}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH13, fontSize: '1vw' }]}>{props.heading13}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH14, fontSize: '1vw' }]}>{props.heading14}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH15, fontSize: '1vw' }]}>{props.heading15}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH16, fontSize: '1vw' }]}>{props.heading16}</Text>

                                </View> :
                                props.noOfColumn == 17 ?
                                <View style={{ flexDirection: 'row', alignItems:'center' }}>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, fontSize: '1vw', marginLeft: '1.3vw' }]}>{props.heading1}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: '1vw' }]}>{props.heading2}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: '1vw' }]}>{props.heading3}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: '1vw' }]}>{props.heading4}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: '1vw' }]}>{props.heading5}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: '1vw' }]}>{props.heading6}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: '1vw' }]}>{props.heading7}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8, fontSize: '1vw' }]}>{props.heading8}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9, fontSize: '1vw' }]}>{props.heading9}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10, fontSize: '1vw' }]}>{props.heading10}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH11, fontSize: '1vw' }]}>{props.heading11}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH12, fontSize: '1vw' }]}>{props.heading12}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH13, fontSize: '1vw' }]}>{props.heading13}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH14, fontSize: '1vw' }]}>{props.heading14}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH15, fontSize: '1vw' }]}>{props.heading15}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH16, fontSize: '1vw' }]}>{props.heading16}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH17, fontSize: '1vw' }]}>{props.heading17}</Text>

                                </View> :
                                props.noOfColumn == 15 ?
                                <View style={{ flexDirection: 'row',alignItems:'center' }}>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, fontSize: fontSize ? fontSize : '1vw' , marginLeft: '1.3vw' }]}>{props.heading1}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading2}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading3}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading4}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading5}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: fontSize ? fontSize : '1vw'}]}>{props.heading6}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading7}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading8}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading9}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading10}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH11, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading11}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH12, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading12}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH13, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading13}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH14, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading14}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH15, fontSize: fontSize ? fontSize : '1vw' }]}>{props.heading15}</Text>
                                </View> :
                                 props.noOfColumn == 19 ?
                                <View style={{ flexDirection: 'row',alignItems:'center' }}>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH1, fontSize: fontSize , marginLeft: 20 }]}>{props.heading1}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH2, fontSize: fontSize }]}>{props.heading2}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH3, fontSize: fontSize }]}>{props.heading3}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH4, fontSize: fontSize }]}>{props.heading4}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH5, fontSize: fontSize }]}>{props.heading5}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH6, fontSize: fontSize}]}>{props.heading6}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH7, fontSize: fontSize }]}>{props.heading7}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH8, fontSize: fontSize }]}>{props.heading8}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH9, fontSize: fontSize }]}>{props.heading9}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH10, fontSize: fontSize }]}>{props.heading10}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH11, fontSize: fontSize }]}>{props.heading11}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH12, fontSize: fontSize }]}>{props.heading12}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH13, fontSize: fontSize }]}>{props.heading13}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH14, fontSize: fontSize }]}>{props.heading14}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH15, fontSize: fontSize }]}>{props.heading15}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH16, fontSize: fontSize }]}>{props.heading16}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH17, fontSize: fontSize }]}>{props.heading17}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH18, fontSize: fontSize }]}>{props.heading18}</Text>
                                  <Text style={[Style.historyHeaderFontStyle, { flex: columnSizeH19, fontSize: fontSize }]}>{props.heading19}</Text>
                                </View>:
                                null
      }
    </View>
  )
}

export const CommonDateFilter = (props) => {

  const [showDD, changeDD] = useState(false)
  const [selectedDateFilter, changeSelectedDateFilter] = useState(props.defaultSelectedDateFilter || "")
  function calculateDate(item) {
    var getKey = item.key;
    var today = new Date();
    var filterDate = {};
    var dateFormet = "YYYY-MM-DD";
    if (getKey == "today") {
      var date = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      filterDate = { fromDate: date, toDate: date }
    } else if (getKey == "yesterday") {
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      var date = yesterday.getFullYear() + '-' + (yesterday.getMonth() + 1) + '-' + yesterday.getDate();
      filterDate = { fromDate: date, toDate: date }
    } else if (getKey == "last7days") {
      var todaydate = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      var lastDate = new Date();
      lastDate.setDate(lastDate.getDate() - 7);
      var finalDate = lastDate.getFullYear() + '-' + (lastDate.getMonth() + 1) + '-' + lastDate.getDate();
      filterDate = { fromDate: finalDate, toDate: todaydate }
    } else if (getKey == "last30days") {
      var todaydate = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      var getReviewDate = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));
      var reviewDate = moment(getReviewDate).format(dateFormet)
      filterDate = { fromDate: reviewDate, toDate: todaydate }
    } else if (getKey == "last90days") {
      var todaydate = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      var getReviewDate = new Date(today.getTime() - (90 * 24 * 60 * 60 * 1000));
      var reviewDate = moment(getReviewDate).format(dateFormet)
      filterDate = { fromDate: reviewDate, toDate: todaydate }
    } else if (getKey == "last12Months") {
      var todaydate = today.getFullYear() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getDate();
      var getReviewDate = new Date(today.getTime() - (365 * 24 * 60 * 60 * 1000));
      var reviewDate = moment(getReviewDate).format(dateFormet)
      filterDate = { fromDate: reviewDate, toDate: todaydate }
    } else if (getKey == "all") {
      filterDate = { fromDate: "", toDate: "" }
    }

    changeDD(showDD);
    changeSelectedDateFilter(item.label);
    if (getKey == "custom") {
      changeDD(false);
    } else {
      props.showSelectedFilteredDate(filterDate)
    }
  }

  function onChange(value, dateString) {
    var dateFormet = "YYYY-MM-DD"
    var a = dateString[0].substring(0, 10)
    var b = dateString[1].substring(0, 10)
    var a_format = moment(a).format(dateFormet);
    var b_format = moment(b).format(dateFormet);
    var selectedCustom = "(" + a_format + ")" + "-" + "(" + b_format + ")"
    changeDD(false);
    changeSelectedDateFilter(selectedCustom);
    var filterDate = { fromDate: a_format, toDate: b_format }
    props.showSelectedFilteredDate(filterDate)

  }
  const listOfFilters = [
    { label: "All", key: "all" },
    { label: "Today", key: "today" },
    { label: "Yesterday", key: "yesterday" },
    { label: "Last 7 days", key: "last7days" },
    { label: "Last 30 days", key: "last30days" },
    { label: "Last 90 days", key: "last90days" },
    { label: "Last 12 Months", key: "last12Months" },
    { label: "Custom", key: "custom" },
  ]
  if(props.startDate || props.endDate){
  }else{
    if(selectedDateFilter == ""){
    }else{
    }
  }

  useEffect(() => {
    if(props.showDateFilter != undefined) 
    {
      if( showDD != props.showDateFilter ) {
        changeDD(props.showDateFilter)
      }
    }
  });
  return (
    <View style={{}}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Add name={"calendar-range-outline"} color={"grey"} size={"1.6vw"} />
        <View style={{ marginLeft: ".65vw" }}>
          <TouchableOpacity
            onPress={() => { 
              changeDD(!showDD) 
              if( props.disableDateFilter) {
                props.disableDateFilter(!showDD)
              }
            }}
          >
            <Text style={{fontSize:'.9vw'}}>{"Select Filter"}   <Cart name={"sort-down"} color={"grey"} size={"1.3vw"} style={{ marginLeft: "3.25vw" }} /></Text>
          </TouchableOpacity>
          {
            selectedDateFilter == "Custom" ?
              <RangePicker
                open={true}
                onChange={onChange} 
                disabledDate={current => {
                  return current && current > moment().add(0, "days");
                }}
                style={{
                }}
              />
              : null
          }
          <View
            style={{
              borderBottomColor: '#CDD1D5',
              borderBottomWidth: ".1vw",
              width: '100%',
              marginTop: ".3vw",
              marginBottom: ".3vw"
            }} />
          <Text style={{fontSize:'.9vw'}}>{selectedDateFilter}</Text>
        </View>
      </View>
      {
        showDD && selectedDateFilter!="Custom"?
          <View style={{ position: 'absolute', backgroundColor: "pink", width: "9.8vw", borderRadius: ".4vw", paddingLeft: "1vw", paddingVertical: ".65vw", marginTop: "1.95vw", marginLeft: "1.95vw" }}>
            {
              listOfFilters.map((item, index) => {
                if ((props.removeObject && props.removeObject == item.key) || (props.removeObject && Array.isArray(props.removeObject) && (props.removeObject).includes(item.key) )) {
                  // do nothing 
                } else {

                return (
                  <TouchableOpacity onPress={() => { calculateDate(item) }} key={index} style={item.key == "custom" ? { marginTop: ".65vw" } : { marginTop: ".4vw" }} >
                    <Text style={[{ color: color.black, fontSize: ".8vw", fontWeight:'500' }]}>{item.label}</Text>
                  </TouchableOpacity>
                )
                }
              })
            }
          </View>
          : null
      }
    </View>
  )
}
// End Common Section Header
const styles = StyleSheet.create({
      SectionTitleText: { 
        fontSize: '1vw', 
        marginRight: '.6vw' 
      },
      CommonDatePickerView: { width: "9.8vw"},
      CommonDatePickerText: { 
        position: 'absolute', 
        marginTop: '-0.45vw', 
        marginLeft: '0.65vw', 
        backgroundColor: "white", 
        fontSize: '0.7vw', 
        paddingHorizontal: '0.32vw', 
        color: "#888888" 
      }
})