import React, { Component } from "react";
import { Text, View, TouchableOpacity, ScrollView, Dimensions } from "react-native";
import { color } from "../../styles/Color";
import StockAdjustment from "./stockAdjustment/stockAdjustment";
import NewDrugApproval from "./NewDrugApproval";
import { OTIndentRequestList } from "./OTindentRequest/OTIndentRequestList";
import { OTIndentDetails } from "./OTindentRequest/OTIndentDetails";
import Setting from "./Setting";
import { CommonDateFilter, MenuBreadCrumpList, SuccessAlert, ErrorAlert } from '../../common/BaseFunctionComponent';
import AsyncStorage from "../../AsyncStorage";


const screenHeight = Dimensions.get("window").height;

export default class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "stockUpdate",
            SelectedIndent: {},

            loginUserData : this.props.loginUserData,

            showResponse: "",
            showErrorResponse: "",
            responseMessage: "",
            pharmacyType: ""
        }
    }

    async componentDidMount() {

        let pharmacy_type = await AsyncStorage.getItem("pharmacy_type");
        this.setState({
            pharmacyType: pharmacy_type
        })
    }

    NavigateScreen(pagename, indent = {}) {
        this.setState({
            selectedTab: pagename,
            SelectedIndent: indent
        })
    }

    responseSuccessErrorMessage(message, type) {
        if (message != "") {
            if (type) {
                this.setState({
                    showResponse: true,
                    responseMessage: message,
                    showErrorResponse: false
                })
            } else {
                this.setState({
                    showResponse: false,
                    showErrorResponse: true,
                    responseMessage: message
                })
            }
        } else {
            this.setState({
                showResponse: false,
                showErrorResponse: false,
            })
        }
    }

    ApproveAlertOTIndent = (message)=>{
        this.responseSuccessErrorMessage(message, true)
        setTimeout(() => {
            this.responseSuccessErrorMessage("", true)
        }, 3000)
    }


    render() {
       const navigationHeaderList =this.state.pharmacyType === "Standalone Pharmacy" ?[
            { label: "Stock Correction", value: "stockUpdate" },
            { label: "New Drug Approval", value: "NewDrugApproval" },
            { label: "Settings", value: "Setting" }
        ] : [
            { label: "Stock Correction", value: "stockUpdate" },
            { label: "New Drug Approval", value: "NewDrugApproval" },
            { label: "OT Indent Request", value: "OTindentRequest" },
            { label: "Settings", value: "Setting" }
        ]
        return (
            <View style={{ height:'70vh'  }}>
                <View style={[{ backgroundColor: color.white, borderBottomLeftRadius: 40, borderBottomRightRadius: 40, height: 45 }, { marginBottom: 10, shadowColor: color.grayShade, shadowOffset: { width: 1, height: 1 }, shadowOpacity: 1, shadowRadius: 2 }]}>
                    <ScrollView style={{ flexDirection: 'row', marginHorizontal: 15 }} horizontal={true} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                        {
                            navigationHeaderList && navigationHeaderList.length > 0 && navigationHeaderList.map((item, index) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.setState({
                                                selectedTab: item.value
                                            })
                                        }} style={{ paddingHorizontal: 10, justifyContent: "center" }} key={index}>
                                        <Text style={[{ fontSize: "1vw", fontWeight: "500" },
                                        this.state.selectedTab === item.value ? { color: color.themeDark } :
                                            this.state.selectedTab == "OTDetails" & item.value == "OTindentRequest" ? { color: color.themeDark } : {}]}>

                                            {item.label}</Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </ScrollView>
                </View>
                <View style={{ }}>
                    {
                        this.state.selectedTab === "stockUpdate" ? <StockAdjustment  _getDrugAndStockCount = {this.props._getDrugAndStockCount}/> :
                            this.state.selectedTab === "NewDrugApproval" ? <NewDrugApproval /> :
                                this.state.selectedTab === "OTindentRequest" ? <OTIndentRequestList NavigateScreen={this.NavigateScreen.bind(this)} loginUserData = {this.state.loginUserData}/> :
                                    this.state.selectedTab === "OTDetails" ? <OTIndentDetails NavigateScreen={this.NavigateScreen.bind(this)} IndentDetails={this.state.SelectedIndent} loginUserData = {this.state.loginUserData} ApproveAlert={this.ApproveAlertOTIndent.bind(this)}/>
                                        : this.state.selectedTab === "Setting" ? <Setting reportConfigChange = {this.props.reportConfigChange}/>:  null            
                    }
                </View>
                <View style={{ position: "absolute", width: "100%", flex: 1, top: '85vh', zIndex: 12 }}>
                    {
                        this.state.showResponse ?
                            <SuccessAlert message={this.state.responseMessage} /> : null
                    }
                    {
                        this.state.showErrorResponse ?
                            <ErrorAlert message={this.state.responseMessage} /> : null
                    }
                </View>
            </View>
        )
    }
}

