import React from 'react';
import { View, Text, StyleSheet, Dimensions, ScrollView, TouchableOpacity, TouchableWithoutFeedback, FlatList } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { color } from "../../styles/Color";
import Style from "../../styles/Style";
import BaseComponent from "../../common/BaseComponent";
import Constants from "../../networks/Constants";
import Service from '../../networks/service';
import { CommonTextBox, SectionTitle, ErrorAlert, BillTableTitle, BillTableData, CommonDatePicker, ActionButton, CommonAutoSuggestion, CommonAutoSuggestionFull } from "../../common/BaseFunctionComponent";
import { TextInput } from 'react-native-paper';
import '../../styles/GlobalStyles.css'
import { CheckBox } from 'react-native-elements';
import moment from 'moment';

const screenHeight = Dimensions.get("window").height;
const DIRECT_PO = "directPO";
const DEFAULT_SET = {
    brand_name: "",
    generic_name: "",
    dosage_type: "",
    dosage_strength: "",
    batch_no: "",
    expiry_date: "",
    schedule: "",
    group: "",
    hsn_code: "",
    bar_code: "",

    pack_of_drugs: "",
    sales_unit: "",
    buying_price: "",
    free_quantity: "",
    discount_percentage: "",
    discount_amount: "",
    mrp_per_pack: "",

    cgst: 0,
    cgst_amount: 0,
    sgst: 0,
    sgst_amount: 0,

    total_buying_price: 0,
    mrp_per_quantity: 0,
    stock_in_quantity: 0,

    expiry_alert_period: "3",
    reorder_threshold: "",
    physical_box_no: "",
    instructions: "",
    manufacturer_name:"",
    recommended_by: "",
    
    // excess
    sales_discount: "",
    sales_discount_amount: "",

    gst_percentage: "",
    total_gst_amount: "0"
}

export default class StockDetailsRight extends BaseComponent {

    constructor(props) {
        super(props);
        this.refDrugName = React.createRef()
        this.refGenericName = React.createRef()
        this.refDrugStrength = React.createRef()
        this.refDrugType = React.createRef()
        let defaultDisabled = this.props.screenType === DIRECT_PO ? false : true;
        this.state = {
            menu: this.props.menu,
            selectedScreen: this.props.selectedScreen,
            screenType: this.props.screenType,
            defaultDisabled: defaultDisabled,
            po_date: null,
            delivery_date: null,
            drugSuggestionList: [],
            genericSuggestionList: [],
            genericSuggestionSortList: [],
            drugDetailsSuggestionList: [],
            drugDosageTypes: [],
            strengthSuggestionList: [],
            strengthSuggestionSortList: [],
            data: JSON.parse(JSON.stringify(DEFAULT_SET)),
            isDrugSelected: true,
            isGenericSelected: true,
            isDosageSelected: true,
            isStrengthSelected: true,
            flagShowDrugList: false,
            flagShowGenericList: false,
            flagShowStrengthList: false,
            flagShowDosageList: false,
            is_sales_amount_changed: false,
            // Tab settings
            is_free_qty_tap_selected: false,
            previousPriceDetails : null,
            isAdddisabled: true,
            EPRValue: 0,
            isDifferentBatchExist: false,
            existBatchList: [],
            selectedBatchNo: "",
        }
        this.service = new Service();
        this._getDrugSuggestion = this._getDrugSuggestion.bind(this)
        this._calculateTransaction = this._calculateTransaction.bind(this)
        this._getPreviousDrugDetails = this._getPreviousDrugDetails.bind(this)
    }

    componentDidMount() {
        this._getDosageTypes();
    }

    componentWillReceiveProps(props) {
        if (props.selectedEntry) {
            let defaultFields = this.state.data;
            if (defaultFields.serial_no != props.selectedEntry.serial_no) {
                let mergedItem = {};
                let item = props.selectedEntry;
                if (props.isNewBatch && item) {
                    mergedItem = defaultFields;
                    mergedItem['drug_id'] = item.drug_id
                    mergedItem['serial_no'] = item.serial_no
                    mergedItem['entryType'] = item.entryType
                    mergedItem['brand_name'] = item.brand_name
                    mergedItem['generic_name'] = item.generic_name
                    mergedItem['dosage_type'] = item.dosage_type
                    mergedItem['dosage_strength'] = item.dosage_strength
                } else {
                    mergedItem = { ...defaultFields, ...props.selectedEntry }
                    mergedItem['gst_percentage'] = (parseFloat(item.cgst_percentage) || 0) + (parseFloat(item.sgst_percentage) || 0)
                    mergedItem['expiry_alert_period'] = item.expiry_alert_period ? item.expiry_alert_period : '3'
                }
                this.setState({ data: mergedItem, isAdddisabled: false })
            }
        }
    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };

    _getDosageTypes = () => {
        let url = Constants.LIST_DOSAGE;
        this.service.getApiCall(
            url,
            this._getDosageTypeSuccess,
            this._getDrugFailure
        )
    }

    _getDosageTypeSuccess = response => {
        if (response.status === "success") {
            this.setState({ drugDosageTypes: JSON.parse(JSON.stringify(response.data)) || [] });
        }
    };
    _getPreviousDrugDetails = () =>{
       let { data } = this.state
        let url = Constants.GET_PREVIOUS_DRUG_DETAIL +"?brand_name=" + data.brand_name + "&generic_name=" + data.generic_name + "&dosage_type=" + data.dosage_type + "&dosage_strength=" + data.dosage_strength;

        this.service.getApiCall(
            url,
            this._getPreviousDrugSuccess,
            this._getPreviousDrugFailure
        )
    }
    _getPreviousDrugSuccess = response =>{
        if(response.status === "success"){
            this.setState({
                previousPriceDetails : response.data.buying_price
            })
        }
    }
    _getPreviousDrugFailure = error =>{

    }
    

    _updateState = (stateKey, text, type = '') => {
        let states = this.state;
        let { drugDetailsSuggestionList, genericSuggestionList, strengthSuggestionList, drugDosageTypes, data } = this.state;
        if (stateKey === "brand_name") {
            states['isDrugSelected'] = false;
            data['generic_name'] = ''
            data['dosage_type'] = ''
            data['dosage_strength'] = ''
            data['hsn_code'] = ""
            data['physical_box_no'] = ""
            data['group'] = ""
            data['expiry_alert_period'] = "3"
            data['reorder_threshold'] = ""
            data['schedule'] = ""
            data['bar_code'] = ""
            data['manufacturer_name'] = ""
            data['recommended_by'] = ""
            data['gst_percentage'] = ""
            data['cgst_percentage'] = ""
            data['sgst_percentage'] = ""

            this.setState({
                data
            })
        }
        if (stateKey === "generic_name") {

            states['isGenericSelected'] = false;
            states['genericSuggestionSortList'] = this._sortListData(genericSuggestionList, text)
        }
        if (stateKey === "dosage_type") {

            states['isDosageSelected'] = false;
            states['drugDosageTypesSortList'] = this._sortListData(drugDosageTypes, text)
        }
        if (stateKey === "dosage_strength") {

            states['isStrengthSelected'] = false;

            states['strengthSuggestionSortList'] = this._sortListData(strengthSuggestionList, text)
        }

        // number validation
        if (stateKey === "pack_of_drugs" || stateKey === "sales_unit" || stateKey === "free_quantity" || stateKey === "reorder_threshold") {

            if (!(Constants.REG_EX_NUMBERS.test(text) || text == "")) {
                // no change
                return
            }
        }

        // decimal validation
        if (stateKey == "buying_price" || stateKey == "discount_amount" || stateKey == "discount_percentage" || stateKey === "sales_discount"
            || stateKey === "sales_discount_amount" || stateKey === "gst_percentage" || stateKey === "mrp_per_pack") {

            if (!(Constants.REG_EX_DECIMALS.test(text) || text == "")) {
                // // no change
                return
            }
        }

        let total_buying_price = (parseFloat(data["sales_unit"]) || 0) * (parseFloat(data["buying_price"]) || 0);
        let discount_amount = parseFloat(data["discount_amount"]) || 0;

        let total_buying_price_after_deduct = total_buying_price - discount_amount;

        // declare and update
        let seller_disc_percent = 0;
        let seller_disc_amount = 0;

        if (stateKey === "sales_discount") {

            // set flag
            states['is_sales_amount_changed'] = false
        }

        if (stateKey === "sales_discount_amount") {
            // set flag
            states['is_sales_amount_changed'] = true
        }

        // update
        states['data'][stateKey] = text;

        this.setState({
            states
        }, () => {


            let trigger_calc_keys = ['pack_of_drugs', 'sales_unit', 'buying_price', 'free_quantity', 'discount_percentage', 'discount_amount', 'sales_discount', 'sales_discount_amount', 'mrp_per_pack', 'gst_percentage'];
            let sameInList = false;

            for (var i = 0; i < trigger_calc_keys.length; i++) {

                let value = trigger_calc_keys[i];

                if (stateKey == value) {
                    sameInList = true;
                }
            }
            if (stateKey === "brand_name") {
                this._getDrugSuggestion(text);
            }
            else if (sameInList) {
                this._calculateTransaction()
            }else  if(data.brand_name && data.generic_name && data.dosage_type && data.dosage_strength && stateKey === "batch_no"){

                this._getPreviousDrugDetails()
           }
        })
    }

    _calculateTransaction() {

        let { data, is_sales_amount_changed, is_free_qty_tap_selected } = this.state;
        let pack_of_drugs = parseInt(data.pack_of_drugs) || 0;
        let sales_unit = parseInt(data.sales_unit) || 0;
        let buying_price = parseFloat(data.buying_price) || 0;
        let free_quantity = parseInt(data.free_quantity) || 0;
        let discount_percentage = parseFloat(data.discount_percentage) || 0;
        let discount_amount = parseFloat(data.discount_amount) || 0;
        let seller_disc_percent = parseFloat(data.sales_discount) || 0;
        let seller_disc_amount = parseFloat(data.sales_discount_amount) || 0;
        let mrp_per_pack = parseFloat(data.mrp_per_pack) || 0;
        let gst_percentage = parseFloat(data.gst_percentage) || 0;

        // calc quantity
        let stock_in_quantity = is_free_qty_tap_selected ? 0 : pack_of_drugs * sales_unit;
        data.free_quantity = is_free_qty_tap_selected ? sales_unit : free_quantity;

        let total_buying_price = parseFloat((sales_unit * buying_price).toFixed(2));
        let mrp_per_quantity = pack_of_drugs ? (mrp_per_pack / pack_of_drugs).toFixed(2) : 0;

        // normal discount
        discount_amount = total_buying_price > 0 && discount_percentage > 0 ? ((total_buying_price * discount_percentage) / 100).toFixed(2) : 0;

        // seller discount
        // let seller_disc_amount = total_buying_price && seller_disc_percent>0?((total_buying_price*seller_disc_percent)/100).toFixed(2):0 

        let total_buying_price_after_discount = total_buying_price - parseFloat(discount_amount)

        if (is_sales_amount_changed) {
            // set discount percent
            data.sales_discount = total_buying_price_after_discount && seller_disc_amount > 0 ? ((100 * seller_disc_amount) / total_buying_price_after_discount).toFixed(2) : 0;

        } else {

            // set discount amount
            seller_disc_amount = buying_price && seller_disc_percent > 0 ? ((total_buying_price_after_discount * seller_disc_percent) / 100).toFixed(2) : 0;
        }

        data.sales_discount_amount = seller_disc_amount;

        // set data
        data.discount_percentage = discount_percentage;
        data.discount_amount = discount_amount;
        data.stock_in_quantity = stock_in_quantity;
        data.mrp_per_quantity = mrp_per_quantity;

        // after dicount, add GST, then calculate tot.buy price
        data.total_buying_price = (parseFloat(total_buying_price.toFixed(2)) - parseFloat(discount_amount) - parseFloat(seller_disc_amount)).toFixed(2);

        if (gst_percentage === 0) {
            data.cgst_percentage = 0;
            data.sgst_percentage = 0;
            data.cgst_amount = 0;
            data.sgst_amount = 0;
            data.total_gst_amount = 0;
        } else {

            let taxable_amount = parseFloat(total_buying_price) - parseFloat(discount_amount) - parseFloat(seller_disc_amount);
            let gst_amount = ((taxable_amount * gst_percentage) / 100).toFixed(2)

            let cgst_amount = parseFloat(gst_amount) / 2 || 0;
            let sgst_amount = parseFloat(gst_amount) / 2 || 0;

            data.cgst_percentage = (gst_percentage / 2).toFixed(2);
            data.sgst_percentage = (gst_percentage / 2).toFixed(2);
            data.cgst_amount = cgst_amount;
            data.sgst_amount = sgst_amount;
            data.total_gst_amount = gst_amount;

            // add GST to final buying price
            data.total_buying_price = (parseFloat(data.total_buying_price) + parseFloat(gst_amount)).toFixed(2)
        }

        this.setState({ data }, () => {
            console.log(this.state.data.stock_in_quantity)
        })

        // EPRValue Calculation start
        var PurchaseRate = parseInt(buying_price)
        var GST = 1 +  (parseInt(gst_percentage) / 100)
        var PurcahseQty = parseInt(sales_unit)
        var PurcahseQtyandfreeQty = PurcahseQty + (parseInt(free_quantity) ? parseInt(free_quantity): 0 )
        var Discount = (discount_percentage ? parseInt(discount_percentage) : 0) + (seller_disc_percent ? parseInt(seller_disc_percent) : 0) 
        var TotalDis = 1 - (Discount/100)
        var EPRTotalValue = (PurchaseRate * GST / mrp_per_pack) * (PurcahseQty / PurcahseQtyandfreeQty ) * (TotalDis)
        
        this.setState({
                EPRValue: (EPRTotalValue && EPRTotalValue != Infinity) ? ((EPRTotalValue * 100).toFixed(2)) : 0
            })
        // EPRValue Calculation end
    }

    _itemSelected = (item, key) => {

        let states = this.state;
        if (item) {
            if (key === "brand_name") {

                states['isDrugSelected'] = true;
                states['flagShowDrugList'] = false;
                states['data']['brand_name'] = item['drug_name'];
            }
            if (key === "generic_name") {

                // let generic_name = item ? item.split('-')[0].trim() : ""
                // let dosage_type = item ? item.split('-')[1].trim() : ""
                // let dosage_strength = item ? item.split('-')[2].trim() : ""

                states['isGenericSelected'] = true;
                states['flagShowGenericList'] = false;
                states['data']['drug_id'] = item['drug_id'];
                states['data']['generic_name'] = item['generic_name'];
                states['data']['dosage_type'] = item['dosage_type'];
                states['data']['dosage_strength'] = item['dosage_strength'];
                states['data']['hsn_code'] = item['hsn_code'];
                states['data']['physical_box_no'] = item['physical_box_no'];
                states['data']['group'] = item['group'];
                states['data']['expiry_alert_period'] = item['expiry_alert_period'] ? item['expiry_alert_period'] : '3';
                states['data']['reorder_threshold'] = item['reorder_threshold'];
                states['data']['schedule'] = item['schedule'];
                states['data']['bar_code'] = item['bar_code'];
                states['data']['gst_percentage'] = item['gst_percentage'];
                states['data']['manufacturer_name'] = item['manufacturer_name'];
                states['data']['recommended_by'] = item['recommended_by'];
                states['data']['cgst_percentage'] = item['gst_percentage'] ? ( item['gst_percentage'] / 2 ).toFixed(2) : "";
                states['data']['sgst_percentage'] = item['gst_percentage'] ? ( item['gst_percentage'] / 2 ).toFixed(2) : "";

            }
            if (key === "dosage_type") {

                states['isDosageSelected'] = true;
                states['flagShowDosageList'] = false;
                states['data']['dosage_type'] = item;
            }
            if (key === "dosage_strength") {

                states['isStrengthSelected'] = true;
                states['flagShowStrengthList'] = false;
                states['data']['dosage_strength'] = item;
            }

            this.setState({
                states
            }, () => {
                if (key === "brand_name") {
                    this._getDrugDetails(item['drug_name']);
                }
            })
        }
    }

    _getDrugSuggestion = (drugName) => {

        let { drugSuggestionList, isDrugSelected } = this.state;

        if (drugName !== "") {

            let sameInList = false;
            let isEnableDrugList = false;
            let len = drugSuggestionList.length;

            for (var i = 0; i < len; i++) {

                let value = drugSuggestionList[i];

                if (drugName == value) {
                    sameInList = true;
                    return;
                }
            }

            if (sameInList) {
                isEnableDrugList = true;
                // exist, so no need call service
                this._getDrugDetails(drugName);

            } else {
                //let url = Constants.SEARCH_DRUG + "?brand_name=" + drugName;
                let url = Constants.DIRECT_PO_DRUG_DETAILS_BY_DRUGNAME + "?brand_name=" + drugName;
                this.service.getApiCall(
                    url,
                    this._getDrugSuccess,
                    this._getDrugFailure
                )
            }
        } else {

            this.setState({
                drugSuggestionList: []
            })
        }
    }

    _getDrugSuccess = response => {
        if (response.status === "success") {
            if(response.drug_list.length === 0){
                console.log("Drug Sugg::", response.data);
                if(localStorage.getItem('user_name') === 'Lister Pharmacy' || localStorage.getItem('user_name') === 'KJ Pharmacy Admin' || localStorage.getItem('user_name') === 'Insight Pharmacy'){
                    this.setState({
                        drugSuggestionList: JSON.parse(JSON.stringify(response.data)),
                        isAdddisabled: false
                    })
                }else{
                this.setState({
                    drugSuggestionList: JSON.parse(JSON.stringify(response.data)),
                    isAdddisabled: true})
                }
            }else{
                console.log("Drug Sugg::", response.drug_list);
            this.setState({
                drugSuggestionList: JSON.parse(JSON.stringify(response.drug_list)),
                isAdddisabled: true
            })
        }
        }
    };

    _getDrugDetails = (drugName) => {

        if (drugName !== "") {

            this.setState({ isDrugSelected: true })

            let url = Constants.PO_DRUG_DETAILS_BY_DRUGNAME + "?brand_name=" + drugName;

            this.service.getApiCall(
                url,
                this._getDrugDetailsSuccess,
                this._getDrugFailure
            )

        } else {

            this.setState({
                drugDetailsSuggestionList: []
            });
        }
    }

    _getDrugDetailsSuccess = response => {

        if (response.status === "success") {
            if(response.data.length > 0){
                this.setState({isAdddisabled: false})
            }else{
                if(localStorage.getItem('user_name') === 'Lister Pharmacy' || localStorage.getItem('user_name') === 'KJ Pharmacy Admin' || localStorage.getItem('user_name') === 'Insight Pharmacy'){
                    this.setState({isAdddisabled: false})
                }
            }

            var { data } = this.state;
            let generic_unique_name = [];
            let list = JSON.parse(JSON.stringify(response.data));
            let dataLen = list.length;

            // for auto suggestion
            let isGenericSelected = true;
            let selectedIndex = -1;
            let tempForFiledSelection = [];      
            for (var i = 0; i < dataLen; i++) {

                let value = list[i].display_name;
                let genListLen = generic_unique_name.length
                let genUniqList = generic_unique_name.length > 0 ? generic_unique_name[genListLen - 1].display_name : []

                if (value && !genUniqList.includes(value)) {
                    generic_unique_name.push(list[i])
                    // set
                    selectedIndex = i
                }

                if (generic_unique_name.length > 1) {
                    isGenericSelected = false
                }

                // for temp only
                if (value) {
                    tempForFiledSelection.push(list[i])
                }
            }
            if (tempForFiledSelection.length == 1 && generic_unique_name.length == 1 && selectedIndex > -1) {
                data["drug_id"] = list[selectedIndex]['drug_id'];
                data['generic_name'] = list[selectedIndex]['generic_name'];
                data['dosage_type'] = list[selectedIndex]['dosage_type'];
                data['dosage_strength'] = list[selectedIndex]['dosage_strength'];
                data['hsn_code'] = list[selectedIndex]['hsn_code'];
                data['physical_box_no'] = list[selectedIndex]['physical_box_no'];
                data['group'] = list[selectedIndex]['group'];
                data['expiry_alert_period'] = list[selectedIndex]['expiry_alert_period'] ? list[selectedIndex]['expiry_alert_period'] : '3';
                data['reorder_threshold'] = list[selectedIndex]['reorder_threshold'];
                data['schedule'] = list[selectedIndex]['schedule'];
                data['bar_code'] = list[selectedIndex]['bar_code'];
                data['gst_percentage'] = list[selectedIndex]['gst_percentage'];
                data['manufacturer_name'] = list[selectedIndex]['manufacturer_name'];
                data['recommended_by'] = list[selectedIndex]['recommended_by'];
                data['cgst_percentage'] = list[selectedIndex]['gst_percentage'] ? (list[selectedIndex]['gst_percentage']/2).toFixed(2) : "";
                data['sgst_percentage'] = list[selectedIndex]['gst_percentage'] ? (list[selectedIndex]['gst_percentage']/2).toFixed(2) : "";

            } else if (generic_unique_name.length == 1 && selectedIndex > -1) {
                data["drug_id"] = list[0]['drug_id'];
                data['generic_name'] = list[0]['generic_name'];
                data['dosage_type'] = list[0]['dosage_type'];
                data['dosage_strength'] = list[0]['dosage_strength'];
            } else {
                data['generic_name'] = ""
                data['dosage_type'] = ""
                data['dosage_strength'] = ""
                data['hsn_code'] = ""
                data['physical_box_no'] = ""
                data['group'] = ""
                data['expiry_alert_period'] = "3"
                data['reorder_threshold'] = ""
                data['schedule'] = ""
                data['bar_code'] = ""
                data['gst_percentage'] = ""
                data['manufacturer_name'] = ""
                data['recommended_by'] = ""
                data['cgst_percentage'] = ""
                data['sgst_percentage'] = ""

            }
            // update
            this.setState({
                data,
                genericSuggestionList: generic_unique_name,
                genericSuggestionSortList: generic_unique_name,
                drugDetailsSuggestionList: list,
                isGenericSelected: isGenericSelected,
            },() => {
                if(this.state.genericSuggestionSortList.length > 1 ) {
                    this.setState({flagShowGenericList: true})
                }
            });
        }
    }

    _getDrugFailure = error => {

    };
    _onClearItem = () =>{
        this.setState({
            data: JSON.parse(JSON.stringify(DEFAULT_SET)),
            isAdddisabled: true,
            EPRValue: 0
        })
    }
    render() {
        
        let { defaultDisabled, data,
            isDrugSelected, drugSuggestionList,
            isGenericSelected, genericSuggestionSortList,
            isDosageSelected, drugDosageTypes, strengthSuggestionList, isStrengthSelected,
            showErrorResponse, responseMessage, drugDosageTypesSortList,
            is_free_qty_tap_selected, isDifferentBatchExist, existBatchList
        } = this.state;
        let drugSuggestion = data.brand_name ? drugSuggestionList : "";
        let genericSuggestionSort = data.brand_name ? genericSuggestionSortList : "";
        let drugDosageTypesList = data.brand_name ? drugDosageTypesSortList : ""

        return (
            <TouchableWithoutFeedback onPress={() => this._clearDropdowns()} >
                <View style={{
                    padding: 10, width: "100%", backgroundColor: color.themeShade,
                    shadowColor: "#090F36",
                    shadowOffset: { width: 0, height: 5 },
                    shadowOpacity: 0.6, shadowRadius: 3,
                    elevation: 5,
                    zIndex: -1
                }}>
                    <ScrollView
                        ref={scrollView => this.scrollView = scrollView}
                        onContentSizeChange={() => {
                            this._onContentSizeChange();
                        }}

                        showsVerticalScrollIndicator={false}
                        style={{ width: "100%" }}
                        contentContainerStyle={{ flex: 1 }}
                    >
                        <View>
                            {/* start */}
                            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginBottom: 15 }}>
                                <TouchableOpacity onPress={() => this._setTabSelection(false)} >
                                    <View style={{ marginRight: 25, flexDirection: "column" }}>
                                        <Text style={{ color: !is_free_qty_tap_selected ? color.themeDark : "", fontSize:'0.91vw' }}>{"Price Details"}</Text>
                                        {!is_free_qty_tap_selected ? <View style={{ width: 18, marginTop: 3, borderBottomColor: color.themeDark, borderBottomWidth: 3 }}></View>
                                            : null}
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => this._setTabSelection(true)} >
                                    <View>
                                        <Text style={{ color: is_free_qty_tap_selected ? color.themeDark : "", fontSize:'0.91vw' }}>{"Free Qty (Diff. Batch No.)"}</Text>
                                        {is_free_qty_tap_selected ? <View style={{ width: 18, marginTop: 3, borderBottomColor: color.themeDark, borderBottomWidth: 3 }}></View>
                                            : null}
                                    </View>
                                </TouchableOpacity>
                            </View>
                            {/* {
                                (localStorage.getItem('user_name') !== 'Lister Pharmacy' && localStorage.getItem('user_name') !== 'KJ Pharmacy Admin' && localStorage.getItem('user_name') !== 'Insight Pharmacy')?
                                    <Text style={{ color : color.cardOrange, fontSize : 15, fontWeight : 500}}>Note: New Drugs cannot be added</Text>: null
                            } */}
                            <SectionTitle label={"Drug Details"} flexWidth={1} />
                            {/* <View>
                                <TextInput placeholder={"Brand Name"} stateKey={"brand_name"} type={""} onChangeText={this._updateState.bind(this)}>

                                </TextInput>
                            <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                                <FlatList
                                    data={drugSuggestionList}
                                    renderItem={({ item }) => (
                                    item ?
                                        <View style={{
                                        flexDirection: "row",
                                        bottom: 10,
                                        marginBottom: 10,
                                        justifyContent: "space-evenly",
                                        width: "100%",
                                        alignItems: "center",
                                        marginTop: 10
                                        }}
                                        >
                                        <View style={{ width: '100%' }}>
                                            <TouchableOpacity
                                            onPress={() => {

                                                props.itemSelected(item, props.stateKey, objKeys)
                                            }}
                                            >
                                            <Text style={{ color: color.white }}>{objKeys ? item[objKeys['keyName']] : item}</Text>
                                            </TouchableOpacity>
                                        </View>
                                        </View> : null
                                    )}
                                    enableEmptySections={true}
                                />
                </ScrollView>
                            </View> */}
                             <View style={{ marginTop: 15, zIndex: -1 }}>
                                <CommonAutoSuggestionFull width={"100%"} disable={defaultDisabled} placeholder={"Brand Name"} type={""} align={""}
                                    stateKey={"brand_name"} value={data.brand_name} onChangeText={this._updateState.bind(this)} objFormat={{keyName: "drug_name"}}
                                    list={drugSuggestion} isSelected={isDrugSelected} itemSelected={this._itemSelected.bind(this)}
                                    reference={this.refDrugName} onFocusInput={this._onFocusInput} showList={this.state.flagShowDrugList} />
                            </View>
                            <View style={{ marginTop: 15, zIndex: -2 }}>
                                <CommonAutoSuggestionFull width={"100%"} disable={defaultDisabled} placeholder={"Generic Name"} type={""} align={""}
                                    stateKey={"generic_name"} value={data.generic_name} onChangeText={this._updateState.bind(this)}
                                    list={genericSuggestionSort} isSelected={isGenericSelected} itemSelected={this._itemSelected.bind(this)}
                                    reference={this.refGenericName} onFocusInput={this._onFocusInput} showList={this.state.flagShowGenericList} />
                            </View>

                            <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -3 }}>
                                <CommonAutoSuggestionFull width={"45%"} disable={defaultDisabled} placeholder={"Dosage Type"} type={""} align={""}
                                    stateKey={"dosage_type"} value={data.dosage_type} onChangeText={this._updateState.bind(this)}
                                    list={drugDosageTypesList} isSelected={isDosageSelected} itemSelected={this._itemSelected.bind(this)}
                                    reference={this.refDrugType} onFocusInput={this._onFocusInput} showList={this.state.flagShowDosageList} />
                                <CommonAutoSuggestionFull width={"45%"} disable={defaultDisabled} placeholder={"Dosage Strength"} type={""} align={""}
                                    stateKey={"dosage_strength"} value={data.dosage_strength} onChangeText={this._updateState.bind(this)}
                                    list={this.state.strengthSuggestionSortList} isSelected={isStrengthSelected} itemSelected={this._itemSelected.bind(this)}
                                    reference={this.refDrugStrength} onFocusInput={this._onFocusInput} showList={this.state.flagShowStrengthList} />
                            </View> 

                            <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -4 }}>
                                <CommonTextBox width={"45%"} disable={false} placeholder={"Batch Number"} type={""} align={""}
                                    stateKey={"batch_no"} value={data.batch_no} onChangeText={this._updateState.bind(this)} />
                                <CommonDatePicker width={"45%"} disable={false} placeholder={"Expiry Date"} type={"expiry-date"}
                                    stateKey={"expiry_date"} value={data.expiry_date} onChangeText={this._updateState.bind(this)}
                                />
                            </View>

                            <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -5 }}>
                                <CommonTextBox width={"45%"} disable={false} placeholder={"Schedule"} type={""} align={""}
                                    stateKey={"schedule"} value={data.schedule} onChangeText={this._updateState.bind(this)} />
                                <CommonTextBox width={"45%"} disable={false} placeholder={"Grouping"} type={""} align={""}
                                    stateKey={"group"} value={data.group} onChangeText={this._updateState.bind(this)} />
                            </View>

                            <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -6 }}>
                                <CommonTextBox width={"45%"} disable={false} placeholder={"HSN Code"} type={""} align={""}
                                    stateKey={"hsn_code"} value={data.hsn_code} onChangeText={this._updateState.bind(this)} />
                                <CommonTextBox width={"45%"} disable={false} placeholder={"UPC/EAN"} type={""} align={""}
                                    stateKey={"bar_code"} value={data.bar_code} onChangeText={this._updateState.bind(this)} />
                            </View>

                            <View style={{ zIndex: -7 }}>
                                {is_free_qty_tap_selected ?
                                    <SectionTitle label={"Free Qty"} flexWidth={1} />
                                    : <SectionTitle label={"Price Details"} flexWidth={1} />}
                            </View>

                            <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -7 }}>
                                <CommonTextBox width={"45%"} disable={false} placeholder={"Piece per Unit"} type={""} align={""}
                                    stateKey={"pack_of_drugs"} value={data.pack_of_drugs} onChangeText={this._updateState.bind(this)} />
                                <CommonTextBox width={"45%"} disable={false} placeholder={"No. of Unit(s)"} type={""} align={""}
                                    stateKey={"sales_unit"} value={data.sales_unit} onChangeText={this._updateState.bind(this)} />
                            </View>

                            {is_free_qty_tap_selected ? null :
                                <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -1 }}>
                                    <CommonTextBox width={"45%"} disable={false} placeholder={"Buying price per Unit"} type={""} align={""}
                                        stateKey={"buying_price"} value={data.buying_price} onChangeText={this._updateState.bind(this)} previousPriceDetails = {this.state.previousPriceDetails ? this.state.previousPriceDetails : null}/>
                                    <CommonTextBox width={"45%"} disable={false} placeholder={"Free Quantity(in Unit(s))"} type={""} align={""}
                                        stateKey={"free_quantity"} value={data.free_quantity} onChangeText={this._updateState.bind(this)} />
                                </View>}

                            {is_free_qty_tap_selected ? null :
                                <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -1 }}>
                                    <CommonTextBox width={"45%"} disable={false} placeholder={"Discount %"} type={""} align={""}
                                        stateKey={"discount_percentage"} value={data.discount_percentage} onChangeText={this._updateState.bind(this)} />
                                    <CommonTextBox width={"45%"} disable={false} placeholder={"Discount Amount"} type={""} align={""}
                                        stateKey={"discount_amount"} value={data.discount_amount} onChangeText={this._updateState.bind(this)} />
                                </View>}

                            {is_free_qty_tap_selected ? null :
                                <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -1 }}>
                                    <CommonTextBox width={"45%"} disable={false} placeholder={"S. Discount %"} type={""} align={""}
                                        stateKey={"sales_discount"} value={data.sales_discount} onChangeText={this._updateState.bind(this)} />
                                    <CommonTextBox width={"45%"} disable={false} placeholder={"S. Disc. Amount"} type={""} align={""}
                                        stateKey={"sales_discount_amount"} value={data.sales_discount_amount} onChangeText={this._updateState.bind(this)} />
                                </View>}

                            <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -1 }}>
                                {is_free_qty_tap_selected ? null :
                                    <CommonTextBox width={"45%"} disable={false} placeholder={"GST %"} type={""} align={""}
                                        stateKey={"gst_percentage"} value={data.gst_percentage} onChangeText={this._updateState.bind(this)} />
                                }
                                <CommonTextBox width={"45%"} disable={false} placeholder={"MRP / Unit"} type={""} align={""}
                                    stateKey={"mrp_per_pack"} value={data.mrp_per_pack} onChangeText={this._updateState.bind(this)} />
                            </View>

                            <View style={{
                                flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
                                backgroundColor: "#F9F9F9", padding: 8, borderRadius: 4, marginTop: 15,
                                borderBottomWidth: 1, borderColor: "#E2E2E2", zIndex: -1
                            }}>
                                {this.tileBlock("CGST", data.cgst_percentage, "%")}
                                {this.tileBlock("SGST", data.sgst_percentage, "%")}
                                {this.tileBlock("CGST Amount", data.cgst_amount)}
                                {this.tileBlock("SGST Amount", data.sgst_amount)}
                            </View>

                            <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginTop: 15, zIndex: -1 }}>
                                {this.tileBlock2("Total Buying Price", data.total_buying_price)}
                                {this.tileBlock2("MRP / Quantity", data.mrp_per_quantity)}
                            </View>
                            <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginTop: 15, zIndex: -1 }}>
                                {this.tileBlock2("Quantity", data.stock_in_quantity)}
                                {this.tileBlock2("Total GST Amount", data.total_gst_amount)}
                            </View>
                            
                                <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginTop: 15, zIndex: -1 }}>
                                        {this.tileBlock2("EPR", this.state.EPRValue )}
                                </View>
                                <View style={{marginTop: '1vw', marginLeft: '0.35vw'}}><Text style={{fontSize: '0.7vw', color: "#888888"}}>(Dis% and S.Dis% are cumulative)</Text></View>
                            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginTop: '1.7vw', zIndex: -1 }}>
                                <View style={{ width: "45%", marginRight: 15 }}>
                                    <Text style={[Style.floatingLabelText,{fontSize:'0.7vw'}]}>{"Expiry Date Alert Period"}</Text>
                                    <Picker
                                        selectedValue={data.expiry_alert_period}
                                        onValueChange={(itemValue, itemIndex) => {
                                            let states = this.state;
                                            states['data']['expiry_alert_period'] = itemValue;
                                            this.setState({
                                                states
                                            })
                                        }}
                                        style={[Style.pickerView, {
                                            marginTop: 5, borderColor: '#CDD1D5'
                                        }, Style.allButtonBorderRadius]}
                                    >
                                        {/* <Picker.Item label="-" value="" /> */}
                                        <Picker.Item label="1 month" value="1" />
                                        <Picker.Item label="2 months" value="2" />
                                        <Picker.Item label="3 months" value="3" />
                                        <Picker.Item label="6 months" value="6" />
                                    </Picker>
                                </View>
                                <CommonTextBox width={"45%"} disable={false} placeholder={"Reorder Threshold"} type={""} align={""}
                                    stateKey={"reorder_threshold"} value={data.reorder_threshold} onChangeText={this._updateState.bind(this)} />
                            </View>
                            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginTop: 15, zIndex: -1 }}>
                                <CommonTextBox width={"45%"} disable={false} placeholder={"Physical Box No"} type={""} align={""}
                                    stateKey={"physical_box_no"} value={data.physical_box_no} onChangeText={this._updateState.bind(this)} />
                                <CommonTextBox width={"45%"} disable={false} placeholder={"Instructions"} type={""} align={""}
                                    stateKey={"instructions"} value={data.instructions} onChangeText={this._updateState.bind(this)} />
                            </View>
                            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginTop: 15, zIndex: -1 }}>
                                <CommonTextBox width={"45%"} disable={false} placeholder={"Manufacture Name"} type={""} align={""}
                                    stateKey={"manufacturer_name"} value={data.manufacturer_name} onChangeText={this._updateState.bind(this)} />
                                <CommonTextBox width={"45%"} disable={false} placeholder={"Recommended By"} type={""} align={""}
                                    stateKey={"recommended_by"} value={data.recommended_by} onChangeText={this._updateState.bind(this)} />
                            </View>
                            <View style={{ flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: 15, zIndex: -1 }} >
                                {/* <ActionButton isDisable={!this.state.isAdddisabled && this.state.isDosageSelected && this.state.isDrugSelected && this.state.isGenericSelected && this.state.isStrengthSelected ? false : true} label={"Add"} keyIndex={"add_stock_item"} onPressAction={this._onAddStockItem.bind(this)} /> */}
                                <ActionButton label={"Add"} keyIndex={"add_stock_item"} onPressAction={this._onAddStockItem.bind(this)} />
                                <ActionButton label={"Clear"} keyIndex={"clear_stock_item"} onPressAction={this._onClearItem.bind(this)} />
                            </View>
                            <View>
                           {/* / {(this.state.isAdddisabled && localStorage.getItem('user_name') !== 'Lister Pharmacy' && localStorage.getItem('user_name') !== 'KJ Pharmacy Admin' && localStorage.getItem('user_name') !== 'Insight Pharmacy')  ?<Text style={{ color : color.red, fontSize : 10, fontWeight : 500}}> * New Drugs cannot be added</Text>: null} */}
                            </View>
                            {
                                isDifferentBatchExist? 
                                <View style={{flex: 1, zIndex: 3, backgroundColor: "rgba(0, 0, 0, 0.5)", position:'absolute', width: '100%', height: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <View style={{width: '90%', minHeight: '35vh', backgroundColor: color.white, marginBottom: '10vh', borderRadius: 4}}>
                                        <View style={{height: '5vh', alignItems: 'center', justifyContent:'center'}}>
                                            <Text style={{fontSize: '1vw', fontWeight: 'bold'}}>{"Do you want to update Batch number ?"}</Text>
                                        </View>
                                        <ScrollView style={{maxHeight: '20vh'}} showsVerticalScrollIndicator={true}>
                                        {existBatchList && existBatchList.length > 0 && existBatchList.map((item,index) => (
                                            <View>
                                                <CheckBox
                                                center
                                                title={item} 
                                                onPress={() => {
                                                    this.setState({
                                                        selectedBatchNo: item
                                                    })
                                                }}
                                                checked={this.state.selectedBatchNo === item ? true : false}
                                                />
                                            </View>
                                        ))}
                                        </ScrollView>
                                        <View style={{height:'10vh', flexDirection: 'row', justifyContent:'center', alignItems: 'center'}}>
                                            <ActionButton label={"Update"} keyIndex={"update_batch_no"}
                                            isDisable = {this.state.selectedBatchNo ? false : true} 
                                            onPressAction={() => {
                                                let { data , EPRValue, selectedBatchNo } = this.state;
                                                data['epr_value'] = EPRValue
                                                data['batch_no'] = selectedBatchNo
                                                this.props.sendItem(data)
                                                this.setState({
                                                    data: JSON.parse(JSON.stringify(DEFAULT_SET)),
                                                    EPRValue: 0,
                                                    isDifferentBatchExist: false,
                                                    existBatchList: [],
                                                    selectedBatchNo: "",
                                                })
                                            }} />
                                            <ActionButton label={"Cancel"} keyIndex={"clear_stock_item"} onPressAction={() => {
                                                this.batchApprovedDirectly()
                                            }} />
                                        </View>
                                    </View>
                                </View> : null
                            }
                            {
                                showErrorResponse ?
                                    <ErrorAlert message={responseMessage} /> : null
                            }
                            {/* end */}
                        </View>
                    </ScrollView>
                </View>
            </TouchableWithoutFeedback>
        );
    }


    tileBlock = (label, data, append = "") => {
        return (
            <View style={{ flex: 0.25, flexDirection: "column" }}>
                <Text style={{ fontSize: '0.78vw', color: "#888888" }}>{label}</Text>
                <Text style={{ fontSize: '0.78vw', marginTop: '0.32vw', color: "#888888" }}>{data}{append}</Text>
            </View>
        )
    }
    tileBlock2 = (label, data) => {
        return (
            <View style={{
                flex: 0.5, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
                backgroundColor: "#F9F9F9", padding: '0.52vw', borderRadius: "0.26vw", marginTop: '0.32vw',
                borderBottomWidth: 1, borderColor: "#E2E2E2", marginRight: '0.32vw'
            }}>
                <View style={{ flexDirection: "column" }}>

                    <Text style={{ fontSize: "1.75vw", color: "#888888" }}>{data}</Text>
                    <Text style={{ fontSize: '0.78vw', color: "#888888", marginTop:'1vw' }}>{label}</Text>
                </View>
            </View>
        )
    }

    checkBatchNumber = () => {

        var {data} = this.state

        var url = Constants.STOCK_BATCH_SUGGESTION

       var expiryDate = data.expiry_date ? moment(data.expiry_date).format("YYYY-MM-DD") : null

        var data = {
            drug_id: data.drug_id,
            expiry_date: expiryDate,
            // buying_price: data.buying_price,
            mrp_per_quantity: data.mrp_per_quantity,
            cgst_percentage: data.cgst_percentage,
            sgst_percentage: data.sgst_percentage,
        }
            
        this.service.postApiCall(
            url,
            data,
            this.checkBatchNumberSuccess,
            this.checkBatchNumbererror
        )
        
    }

    batchApprovedDirectly = () => {
        let { data , EPRValue } = this.state;
        data['epr_value'] = EPRValue
        this.props.sendItem(data)
        this.setState({
            data: JSON.parse(JSON.stringify(DEFAULT_SET)),
            EPRValue: 0,
            isDifferentBatchExist: false,
            existBatchList: [],
            selectedBatchNo: "",
        })
    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    checkBatchNumberSuccess = (response) => {
        if (response.status === "success"){
            var {data} = this.state
            var Data = this.removeDuplicate(response.data)
            if(response.data && (response.data).length === 1){
                if((response.data)[0] == data.batch_no){
                    this.batchApprovedDirectly()
                }else{
                    this.setState({
                        isDifferentBatchExist: true,
                        existBatchList: response.data
                    })
                }

            }else if(response.data.length > 1){
                this.setState({
                    isDifferentBatchExist: true,
                    existBatchList: response.data
                })
            }else{
                this.batchApprovedDirectly()
            }
        }else {

            this.batchApprovedDirectly()
        }
    }

    checkBatchNumbererror = (response) => {
        this.batchApprovedDirectly()
    }

    _onAddStockItem = () => {

        let { data , EPRValue } = this.state;
        data['epr_value'] = EPRValue
        let isValid = this._validateData(data);

        if (isValid) {
            this.checkBatchNumber() 
        }
    }

    _validateData = (data) => {

        let { is_free_qty_tap_selected } = this.state;
        let isValid = true;
        let responseMessage = [];
        if (!data.brand_name) { isValid = false; responseMessage.push("Enter drug name"); }
        if (!data.generic_name) { isValid = false; responseMessage.push("Enter generic name"); }
        if (!data.dosage_type) { isValid = false; responseMessage.push("Enter dosage type"); }
        if (!data.dosage_strength) { isValid = false; responseMessage.push("Enter dosage strength"); }
        if (!data.batch_no) { isValid = false; responseMessage.push("Enter batch number"); }
        if (!data.expiry_date) { isValid = false; responseMessage.push("Enter expiry date"); }
        if (!data.pack_of_drugs) { isValid = false; responseMessage.push("Enter Piece / Unit(s)"); }
        if (!data.sales_unit) { isValid = false; responseMessage.push("Enter number of Unit(s)s"); }
        if (!is_free_qty_tap_selected) {
            if (!data.buying_price) {
                isValid = false; responseMessage.push("Enter the buying price")
            }
        }

        if (!data.mrp_per_pack) { isValid = false; responseMessage.push("Enter MRP / Unit(s)"); }
        if (!data.expiry_alert_period) { isValid = false; responseMessage.push("Select expiry alert period") }
        if (!isValid) {
            this.props.responseSuccessErrorMessage(responseMessage.join(), false)
        }
        if(data.brand_name || data.generic_name){
            var Data = this.state.data
            Data["brand_name"] = (data.brand_name).trim()
            Data["generic_name"] = (data.generic_name).trim()
            this.setState({
                Data
            })
        }
        return isValid;
    }

    _setDataFormat = (data, desiredFormat = "string") => {

        let inString = data;

        if (desiredFormat === "int" || desiredFormat === "integer") {

            inString = parseInt(inString) || 0
        }
        if (desiredFormat === "float" || desiredFormat === "decimal") {

            let parsedVal = parseFloat(inString) || 0;
            let formattedVal = parsedVal.toFixed(2);
            // assign
            inString = parseFloat(formattedVal);
        }
        if (desiredFormat === "date") {

            let date = inString;
            if (date) {

                inString = date ? moment(date).format('YYYY-MM-DD') : null
            } else {
                inString = ""
            }
        }

        return inString;
    }

    _onFocusInput = (stateKey, refInp) => {

        let { flagShowDrugList, flagShowGenericList, flagShowDosageList, flagShowStrengthList,
            data, drugDetailsSuggestionList, drugDosageTypes, strengthSuggestionList } = this.state

        if (stateKey == "brand_name" && !flagShowDrugList) {
            this.setState({ flagShowDrugList: true })
        }
        if (stateKey == "generic_name" && !flagShowGenericList) {
            this.setState({ flagShowGenericList: true })
        }
        if (stateKey == "dosage_type" && !flagShowDosageList) {
            this.setState({ flagShowDosageList: true }, () => {

                if (data && data.dosage_type == "") {
                    let drugDosageTypesSortList = this._sortListData(drugDosageTypes, "")

                    this.setState({
                        drugDosageTypesSortList: drugDosageTypesSortList,
                        isDosageSelected: false
                    })
                }
            })
        }
        if (stateKey == "dosage_strength" && !flagShowStrengthList) {
            let isStrengthSelected = false

            this.setState({ flagShowStrengthList: true }, () => {

                /* start */

                // get list
                let dataLen = drugDetailsSuggestionList.length || 0;

                strengthSuggestionList = []

                for (var i = 0; i < dataLen; i++) {

                    let brand_name = drugDetailsSuggestionList[i].brand_name;
                    let generic_name = drugDetailsSuggestionList[i].generic_name;
                    let dosage_type = drugDetailsSuggestionList[i].dosage_type;

                    if (
                        // states['data']['brand_name'] == brand_name && 
                        data['generic_name'] == generic_name
                        && data['dosage_type'] == dosage_type) {
                        let value = drugDetailsSuggestionList[i].dosage_strength;

                        if (!strengthSuggestionList.includes(value)) {

                            strengthSuggestionList.push(value)
                        }
                    }
                }

                /* end */

                if (data && data.dosage_strength == "") {

                    let strengthSuggestionSortList = this._sortListData(strengthSuggestionList, "")

                    this.setState({
                        strengthSuggestionList: strengthSuggestionList,
                        strengthSuggestionSortList: strengthSuggestionSortList,
                        isStrengthSelected: isStrengthSelected
                    })
                }
            })
        }
    }

    _clearDropdowns = () => {

        let { isDrugSelected, isGenericSelected, isDosageSelected, isStrengthSelected,
            flagShowDrugList, flagShowGenericList, flagShowDosageList, flagShowStrengthList } = this.state
        let isChanged = false;

        if (
            flagShowDrugList && !isDrugSelected && document.activeElement !== this.refDrugName.current._node
        ) {
            flagShowDrugList = false
            isChanged = true
        }

        if (
            flagShowGenericList && !isGenericSelected && document.activeElement !== this.refGenericName.current._node
        ) {
            flagShowGenericList = false
            isChanged = true
        }
        if (
            flagShowDosageList && !isDosageSelected && document.activeElement !== this.refDrugType.current._node
        ) {
            flagShowDosageList = false
            isChanged = true
        }
        if (
            flagShowStrengthList && !isStrengthSelected && document.activeElement !== this.refDrugStrength.current._node
        ) {
            flagShowStrengthList = false
            isChanged = true
        }

        if (isChanged) {

            this.setState({
                flagShowDrugList: flagShowDrugList,
                flagShowGenericList: flagShowGenericList,
                flagShowDosageList: flagShowDosageList,
                flagShowStrengthList: flagShowStrengthList

            })
        }
    }

    _setTabSelection = (option) => {

        let { data } = this.state

        if (option) {
            // clear
            data.buying_price = 0
            data.discount_percentage = 0
            data.sales_discount = 0
            data.gst_percentage = 0
        }

        this.setState({
            is_free_qty_tap_selected: option,
            data
        }, () => {
            this._calculateTransaction()
        })
    }
}

