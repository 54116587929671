import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';
import Style from "../../styles/Style";
import { Tooltip } from 'antd';

export default class MisReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReportList: [],
      vendorName1ID: "",
      vendorName2ID: "",
      vendorName3ID: "",
      vendorName3: "",
      vendorName2: "",
      vendorName1: "",
      brandName: "",
      genericName: "",
      billingListAfterPagination: [],
      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0
    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 

  }

  componentWillReceiveProps(props) {
    if ( props.isclear) {
      // if ((props.vendorName1ID || props.vendorName2ID || props.vendorName3ID) && props.isclear) {
      this.setState({
        vendorName1ID: props.vendorName1ID,
        vendorName2ID: props.vendorName2ID,
        vendorName3ID: props.vendorName3ID,
        vendorName3: props.vendorName3,
        vendorName2: props.vendorName2,
        vendorName1: props.vendorName1,
        brandName: props.brandName,
        genericName: props.genericName,
        current_page: 1,
        records_per_page: 10
      }, () => {
        this._getMisReport()
        props.handlepropsvalueclear(false)

      })
    }
  }

  componentDidMount() {

    // this._getMisReport()

    var data = {
      "vendor1_total": 0,
      "vendor2_total": 0,
      "vendor3_total": 0
    }

    this.props.gettotalscoreValues("MIS Report", data)
  }

  // Get Api 
  _getMisReport = () => {

    let url = ""
    let { current_page, records_per_page, brandName, genericName } = this.state;
    let vendorName1ID = this.state.vendorName1 ? this.state.vendorName1ID : ""
    let vendorName2ID = this.state.vendorName2 ? this.state.vendorName2ID : ""
    let vendorName3ID = this.state.vendorName3 ? this.state.vendorName3ID : ""

    if( vendorName1ID || vendorName2ID || vendorName3ID ) {

      url = Constants.GET_MIS_REPORT + "?vendor1=" + vendorName1ID + "&vendor2=" + vendorName2ID + "&vendor3=" + vendorName3ID;

      url = url + "&page="+current_page+"&page_size="+records_per_page;

      url = url + "&brand_name="+brandName+"&generic_name="+genericName;

      this.service.getApiCall(
        url,
        this._getMisReportSuccess,
        this._getMisReportFailure
      )
    } else {

      this.props.responseSuccessErrorMessage("Select vendor", false) 
            
      setTimeout(() => {
          this.props.responseSuccessErrorMessage("", false) 
          // this.props.changeScreen("vendors")
      }, 3000)
    }
  }

  _getMisReportSuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data.result

      this.setState({
        salesReportList,
        no_of_pages: response.data?response.data.no_of_pages:0,
        total_count: response.data?response.data.total_count:0
      }, () => {
        var data = {
          "vendor1_total": response.data.vendor1_total,
          "vendor2_total": response.data.vendor2_total,
          "vendor3_total": response.data.vendor3_total,

        }
        this.props.gettotalscoreValues("MIS Report", data)
      })
    }
    else {

      this.props.gettotalscoreValues("MIS Report", "")

      // update error message
      this.props.responseSuccessErrorMessage(response.message, false)
      setTimeout(() => {
          this.props.responseSuccessErrorMessage("", false) 
      }, 3000)
      
    }
  };

  _getMisReportFailure = error => {

    this.props.gettotalscoreValues("MIS Report", "")

  };
  // Report List Views Function
  renderContent() {
    return (
      // Report List Views
      <View style={styles.renderContent}>
        {this.renderContentRow()}
        {/* End Report List View */}

      </View>
    )
  }
  // Common History Section Header function
  renderHeader() {
    return (

      <View style={styles.headerContainer}>
        <View style={styles.headerWrapper}>

          <View style={{ flexDirection: 'row'}}>

            <View style={[styles.HeadersView,{ flex: 0.075}]}>
              <Text style={[Style.historyHeaderFontStyleStartText]}>{"S.No"}</Text>
            </View>
            <View style={[styles.HeadersView,{ flex: 0.15 }]}>
              <Text style={[Style.historyHeaderFontStyleStartText,]}>{"Brand Name"}</Text>
            </View>
            <View style={[styles.HeadersView,{ flex: 0.15 }]}>
              <Text style={[Style.historyHeaderFontStyleStartText, { flex: .125 }]}>{"Generic Name"}</Text>
            </View>
            <View style={[styles.HeadersView,{ flex: 0.125 }]}>
              <Text style={[Style.historyHeaderFontStyleStartText,]}>{"Dosage Type"}</Text>
            </View>
            <View style={[styles.HeadersView,{ flex: 0.125 }]}>
              <Text style={[Style.historyHeaderFontStyleStartText,]}>{"Dosage Strength"}</Text>
            </View>
            <Tooltip placement="topLeft" title={this.state.vendorName1}>
            <View style={[styles.HeadersView,{ flex: 0.125 }]}>
              <Text style={[Style.historyHeaderFontStyleStartText,]}>{this.state.vendorName1?this.state.vendorName1.length > 15 ? this.state.vendorName1.slice(0,15) + "..": this.state.vendorName1:"Vendor 1"}</Text>
              <Text style={styles.subHeaderContainer}>{"Price/Pack"}</Text>
            </View>
            </Tooltip>
            <Tooltip placement="topLeft" title={this.state.vendorName2}>
            <View style={[styles.HeadersView,{ flex: 0.125 }]}>
              <Text style={[Style.historyHeaderFontStyleStartText,]}>{this.state.vendorName2?this.state.vendorName2.length > 15 ? this.state.vendorName2.slice(0,15) + "..": this.state.vendorName2:"Vendor 2"}</Text>
              <Text style={styles.subHeaderContainer}>{"Price/Pack"}</Text>
            </View>
            </Tooltip>
            <Tooltip placement="topLeft" title={this.state.vendorName3}>
            <View style={[styles.HeadersView,{ flex: 0.125 }]}>
              <Text style={[Style.historyHeaderFontStyleStartText,]}>{this.state.vendorName3?this.state.vendorName3.length > 15 ? this.state.vendorName3.slice(0,15) + "..": this.state.vendorName3:"Vendor 3"}</Text>
              <Text style={styles.subHeaderContainer}>{"Price/Pack"}</Text>
            </View>
            </Tooltip>

          </View>
        </View>
        {/* <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Brand Name"}
          heading3={"Generic Name"}
          heading4={"DT"}
          heading5={"DS"}
          heading6={"Vendor 1"}
          columnSize={[0.16, 0.16, 0.16, 0.16, 0.16, 0.16]}  //total value is == of 1
          noOfColumn={6}
        /> */}
      </View>
    )
  }

  // Render Content Row function Key and
  renderContentRow() {
    return (
      <View>
        {
          this.state.salesReportList && this.state.salesReportList.map((item, index) => {
            return (
              <View style={styles.tableBodyContainer}>
                <View style={{ flex: 0.075 }}>
                  <Text style={[styles.BodyLabel,{ marginLeft: "1.30vw" }]}>{index + 1}</Text>
                </View>
                <View style={{ flex: 0.15 }}>
                  <Text style={styles.BodyLabel}>{item.brand_name}</Text>
                </View>
                <View style={{flex: 0.15}}>
                  <Text style={styles.BodyLabel}>{item.generic_name}</Text>
                  </View>
                  <View style={{flex: 0.125}}>
                  <Text style={styles.BodyLabel}>{item.dosage_type}</Text>
                  </View>
                  <View style={{flex: 0.125}}>
                  <Text style={styles.BodyLabel}>{item.dosage_strength}</Text>
                  </View>
                  <View style={{flex: 0.125}}>
                  <Text style={[styles.BodyLabel,{marginLeft:".85vw"}]}>{item.vendor1}</Text>
                  </View>
                  <View style={{flex: 0.125}}>
                  <Text style={[styles.BodyLabel,{marginLeft:".85vw"}]}>{item.vendor2}</Text>
                  </View>
                  <View style={{flex: 0.125}}>
                  <Text style={[styles.BodyLabel,{marginLeft:"1vw"}]}>{item.vendor3}</Text>
                  </View>

              </View>
            )
          })}



      </View>
    )
  }
  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page } = this.state

    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        
        this.setState({
            records_per_page: records_per_page_change,
            current_page: next_page
        }, ()=>{
            this._getMisReport()
        })
    }
  }
  render() {
    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0

    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}

        {
          total_count > 0 ?
          <View style={{ marginLeft: "1.30vw" }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
              type={Constants.PAGINATION_TYPE_SERVER} 
              totalServerRecordsCount={total_count} 
              totalServerPagesCount={no_of_pages}/></View>
            : null
        }
      </View>
    );
  }
  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={styles.paginateTableContent}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              let sno = (current_page-1)*records_per_page + index + 1;
              return (
                <View key={sno} style={styles.tableBodyContainer}>
                  
                <View style={{ flex: 0.075 }}>
                  <Text style={[styles.BodyLabel,{marginLeft:"1.30vw"}]}>{sno}</Text>
                </View>
                <View style={{ flex: 0.15 }}>
                  <Text style={styles.BodyLabel}>{item.brand_name}</Text>
                </View>
                <View style={{flex: 0.15}}>
                  <Text style={styles.BodyLabel}>{item.generic_name}</Text>
                  </View>
                  <View style={{flex: 0.125}}>
                  <Text style={styles.BodyLabel}>{item.dosage_type}</Text>
                  </View>
                  <View style={{flex: 0.125}}>
                  <Text style={styles.BodyLabel}>{item.dosage_strength}</Text>
                  </View>
                  <View style={{flex: 0.125}}>
                  <Text style={[styles.BodyLabel,{marginLeft:".85vw"}]}>{item.vendor1}</Text>
                  </View>
                  <View style={{flex: 0.125}}>
                  <Text style={[styles.BodyLabel,{marginLeft:".85vw"}]}>{item.vendor2}</Text>
                  </View>
                  <View style={{flex: 0.125}}>
                  <Text style={[styles.BodyLabel,{marginLeft:"1vw"}]}>{item.vendor3}</Text>
                  </View>
                </View>
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={styles.NoRecordContainer}>
          <Text style={styles.NoRecordText}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }

}

const styles = StyleSheet.create({
  BodyLabel:{
    fontSize:'1vw'
  },
  renderContent:{ 
    marginHorizontal: "1.30vw", 
    backgroundColor: "#F9F9F9", 
    marginTop: "1vw" 
  },
  headerContainer:{ 
    marginHorizontal: "1.30vw", 
    marginTop: ".65vw" 
  },
  headerWrapper:{ 
    justifyContent: 'center', 
    height: "2.6vw", 
    backgroundColor: "#E2E2E2"
  },
  subHeaderContainer:{
    fontSize:".71vw", 
    marginHorizontal: "1.30vw" 
  },
  tableBodyContainer:{ 
    flexDirection: 'row', 
    borderBottomColor: "#888888", 
    borderBottomWidth: ".1vw", 
    paddingVertical: "1.30vw", 
    marginHorizontal: 0 ,
    justifyContent:'center'
  },
  paginateTableContent:{
    marginHorizontal:'1.30vw',
    backgroundColor:"#F9F9F9",
    marginTop:'1vw'
  },
  NoRecordContainer:{ 
    flex: 1, 
    flexDirection: "row", 
    justifyContent: "center", 
    alignContent: "center", 
    marginTop: "9.76vw" 
  },
  NoRecordText:{ 
    color: "#8888888", 
    fontSize:'1vw' 
  },
  HeadersView:{
    width:'5vw',
    justifyContent:'center'
  }
});