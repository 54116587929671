import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, StyleSheet, Picker } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';

export default class GSTSplitUps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReportList: [],
      startTime: "",
      endTime: "",
      billingListAfterPagination: [],


      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0,
      selectedMonth: props.selectedMonth,
      selectedGST: "",
      startIndex: 0,
      endIndex:9
    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this)
  }

  componentDidMount() {
    this._getGSTSplitupsDetailes()
  }

  componentWillReceiveProps(props) {

    if (props.isclear && (props.startTime != this.state.startTime || props.endTime != this.state.endTime)) {
      this.setState({
        startTime: props.startTime,
        endTime: props.endTime,
        current_page: 1,
        records_per_page: 10
      }, () => {
        this._getGSTSplitupsDetailes()
        props.handlepropsvalueclear(false)

      })
    }
  }

  // Get Api 
  _getGSTSplitupsDetailes = () => {

    var url = ""
    let { current_page, records_per_page } = this.state;
    var selectedGST = this.state.selectedGST;

    if (this.state.startTime && this.state.endTime) {

      url = Constants.GET_SALES_GST_SPLITUPS + "?from_date=" + this.state.startTime + "&to_date=" + this.state.endTime + "&page=" + current_page + "&page_size=" + records_per_page + "&start_index="+ this.state.startIndex +"&end_index="+ this.state.endIndex;
    } else {
      if (selectedGST != "") {
        url = Constants.GET_SALES_GST_SPLITUPS + "?invoice_date=" + this.state.selectedMonth + "&search_key=" + parseFloat(selectedGST)+ "&page=" + current_page + "&page_size=" + records_per_page + "&start_index="+ this.state.startIndex +"&end_index="+ this.state.endIndex;
      } else {
        url = Constants.GET_SALES_GST_SPLITUPS + "?invoice_date=" + this.state.selectedMonth+ "&page=" + current_page + "&page_size=" + records_per_page + "&start_index="+ this.state.startIndex +"&end_index="+ this.state.endIndex;
      }
    }

    this.service.getApiCall(
      url,
      this._getGSTSplitupsDetailesSuccess,
      this._getGSTSplitupsDetailesFailure
    )
  }

  _getGSTSplitupsDetailesSuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data
      this.setState({
        salesReportList,
        no_of_pages: response.data ? response.no_of_pages : 0,
        total_count: response.data ? response.total_count : 0,
        startIndex: response.data ? this.state.startIndex : 0,
        endIndex : response.data ? this.state.endIndex : 0
      }, () => {
        var data = {
          "total_amount": response.total_amount,
          "total_cgst_amount": response.total_taxable_amount,

        }
        this.props.gettotalscoreValues("Sales GST Splitups", "")
      })
    }
    else {
      this.props.gettotalscoreValues("Sales GST Splitups", "")
    }
  };

  _getGSTSplitupsDetailesFailure = error => {
    this.props.gettotalscoreValues("Sales GST Splitups", "")

    console.log(error);

  };

  // Common History Section Header function
  renderHeader() {
    return (

      <View style={ReportStyle.HeaderView}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          // heading2={"Month"}
          // heading3={"Invoice No"}
          // heading4={"Drug"}
          heading2={"GST(CGST+SGST)%"}
          heading3={"CGST Amount"}
          heading4={"SGST Amount"}
          heading5={"GST Amount"}
          // heading9={"Net Amount"}
          // heading6={"Sales Amount"}

          // columnSize={[0.15, 0.15,0.15, 0.15,0.15, 0.15]}  //total value is == of 1 
          columnSize={[0.1, 0.2, 0.2, 0.2, 0.2]}
          noOfColumn={5}

        />
      </View>
    )
  }

  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }

  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page, startIndex, endIndex } = this.state

    if (current_page != next_page || records_per_page != records_per_page_change) {
      if(current_page<next_page){
        startIndex = this.state.startIndex+10,
        endIndex = this.state.endIndex+10
      }else if(current_page>next_page){
        startIndex = this.state.startIndex-10,
        endIndex = this.state.endIndex-10
      } else{
        startIndex = this.state.startIndex,
        endIndex = this.state.endIndex
      }

      this.setState({
        records_per_page: records_per_page_change,
        current_page: next_page,
        startIndex: startIndex,
        endIndex: endIndex
      }, () => {
        this._getGSTSplitupsDetailes()
      })
    }
  }
  setSelectedGSTValue(val) {
    this.setState({ selectedGST: val }, () => this._getGSTSplitupsDetailes());
  }

  render() {

    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0

    return (
      <View>
        {/* <Picker
          selectedValue={this.state.selectedGST}
          style={{ height: 50, width: 150, marginTop: -20, marginLeft: 350 }}
          onValueChange={(itemValue, itemIndex) => this.setSelectedGSTValue(itemValue)}
        >
          <Picker.Item label="All" value="" />
          <Picker.Item label="5%" value="5.0" />
          <Picker.Item label="12%" value="12.0" />
          <Picker.Item label="18%" value="18.0" />
        </Picker> */}
        {this.renderHeader()}
        {this.renderPaginateContent()}
        {
          no_of_pages > 1 ?
            <View style={{ marginLeft: 20 }}>
              <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}
                type={Constants.PAGINATION_TYPE_SERVER}
                totalServerRecordsCount={total_count}
                totalServerPagesCount={no_of_pages} />
            </View>
            : null
        }
      </View>
    );
  }

  renderPaginateContent() {

    let { current_page, records_per_page } = this.state

    if (this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={ReportStyle.TableDataContainer}>
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {

            let sno = (current_page - 1) * records_per_page + index + 1;
            let gst_amount = (parseFloat(item.cgst_amount) || 0) + (parseFloat(item.sgst_amount) || 0)
            return (
              <View key={sno} style={ReportStyle.TableDataWrapper}>
                <View style={[{ flex: 0.1 }]}><Text style={[ReportStyle.TableDataText, { marginLeft: "1.7vw" }]}>{sno}</Text></View>
                {/* <View style={[ReportStyle.ReportCell, { flex: 0.1 }]}>
                  <Text >{item.month}</Text></View>
                <View style={[ReportStyle.ReportCell, { flex: 0.1 }]}>
                  <Text >{item.invoice_number}</Text></View>
                <View style={[ReportStyle.ReportCell, { flex: 0.2 }]}>
                  <Text >{item.drug_name}</Text></View> */}
                <View style={[ReportStyle.ReportCell, { flex: 0.2 }]}>
                  <Text style={[ReportStyle.TableDataText, { marginLeft: "2.5vw" }]}>{item.gst_percentage != 0?item.gst_percentage + "(" + item.gst_percentage / 2 + "+" + item.gst_percentage / 2 + ")" : "Others"}</Text></View>
                <View style={[ReportStyle.ReportCell, { flex: 0.2 }]}>
                  <Text style={[ReportStyle.TableDataText, { marginLeft: "2vw" }]}>{item.cgst_amount}</Text></View>
                <View style={[ReportStyle.ReportCell, { flex: 0.2 }]}>
                  <Text style={[ReportStyle.TableDataText, { marginLeft: "1.7vw" }]}>{item.sgst_amount}</Text></View>
                <View style={[ReportStyle.ReportCell, { flex: 0.2 }]}>
                  <Text style={[ReportStyle.TableDataText, { marginLeft: "1vw" }]}>{item.taxable_amount}</Text></View>
                {/* <View style={[ReportStyle.ReportCell, { flex: 0.1, paddingHorizontal: 15 }]}>
                  <Text style={[ReportStyle.ReportCellTxt]}>{item.net_total_amount}</Text></View> */}
              </View>
            )

          })
          }
        </View>
      )
    } else {
      return (
        <View style={ReportStyle.NoRecordsView}>
          <Text style={ReportStyle.NoRecordsText}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }

}

const ReportStyle = StyleSheet.create({

  ReportCell: {
    width: '13vw',
    alignItems: "flex-start"
  },
  ReportCellTxt: {
    width: '9.76vw',
    paddingHorizontal: '1.62vw',
    textAlign: "right"
  },
  HeaderView:{
    marginHorizontal: '1.30vw', marginTop: '0.65vw' 
  },
  TableDataContainer:{
    marginHorizontal: '1.30vw', backgroundColor: "#F9F9F9", marginTop: '0.13vw'
  },
  TableDataWrapper:{
    flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: '0.97vw'
  },
  TableDataText:{
    fontSize:'1vw'
  },
  NoRecordsView:{
    flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: '9.76vw'
  },
  NoRecordsText:{
    color: "#8888888", fontSize:'1vw'
  }
})
