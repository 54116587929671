import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import Pagination from '../../common/Pagination';
import moment from 'moment';
import { Tooltip } from 'antd';

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

export default class SalesOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReportList: [],
      startTime: converted_date,
      endTime: converted_date,
      billingListAfterPagination: [],
      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0
    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 
  }

  componentDidMount() {
    this._getSalesOverView()
  }

  componentWillReceiveProps(props) {
    if (props.isclear && (props.startTime!=this.state.startTime || props.endTime!=this.state.endTime)) {
      this.setState({
        startTime: props.startTime,
        endTime: props.endTime,
        current_page: 1,
        records_per_page: 10
      }, () => {
        this._getSalesOverView()
        props.handlepropsvalueclear(false)

      })
    }
  }

  // Get Api 
  _getSalesOverView = () => {

    let url = ""
    let { current_page, records_per_page } = this.state;

    if (this.state.startTime && this.state.endTime) {

        url = Constants.GET_SALES_OVERVIEW + "?from_date=" + this.state.startTime + "&to_date=" + this.state.endTime + "&page="+current_page+"&page_size="+records_per_page;
    } else {
        url = Constants.GET_SALES_OVERVIEW + "?page="+current_page+"&page_size="+records_per_page;

    }

    this.service.getApiCall(
      url,
      this._getSalesOverViewSuccess,
      this._getSalesOverViewFailure
    )



  }

  _getSalesOverViewSuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data.result;

      this.setState({
        salesReportList,
        no_of_pages: response.data?response.data.no_of_pages:0,
        total_count: response.data?response.data.total_count:0
      },()=>{
        var data ={
          "total_amount":response.data.total_amount ,
          "total_cgst_amount": response.data.total_cgst_amount,
          "total_sgst_amount": response.data.total_sgst_amount,
          "total_discount_amount": response.data.total_discount_amount,
          "total_net_total": response.data.total_net_amount,
          }
      this.props.gettotalscoreValues("Sales Overview", data)
      })
    }
    else{
      this.props.gettotalscoreValues("Sales Overview", "")

    }
  };

  _getSalesOverViewFailure = error => {
    this.props.gettotalscoreValues("Sales Overview", "")

    console.log(error);

  };
  
  // // Report List Views Function
  // renderContent() { 
  //   return (
  //     // Report List Views
  //     <View style={{ marginHorizontal: 20, backgroundColor: "#F9F9F9", marginTop: 15 }}>
  //       {this.renderContentRow()}
  //     </View>
  //   )
  // }

  // Common History Section Header function
  renderHeader() {
    return (

      <View style={Styles.TableHeaderView}>
        <CommonReportSectionHeader
          heading1={"Bill No"}
          heading2={"Bill Date"}
          heading3={"Patient Name"}
          heading4={"Doctor Name"}
          
          heading5={"Amount"}
          heading6={"CGST"}

          heading7={"SGST"}

          heading8={"DISC AMT"}
          heading9={"Round Off"}
          heading10={"Net AMT"}

          columnSize={[0.07, 0.10, 0.11, 0.15, 0.10, 0.09, 0.09, 0.08, 0.1, 0.1 ]}  //total value is == of 1
          noOfColumn={10}

        />
      </View>
    )
  }



  // // Render Content Row function Key and 
  // renderContentRow() {
  //   if (this.state.salesReportList && this.state.salesReportList.length < 11) {

  //     return (
  //       <View style={{}}>
  //         {
  //           this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
  //             return (
  //               <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0 }}>
  //                 <View style={[{ flex: 0.1 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.invoice_date}</Text></View>
  //                 <View style={[[{ flex: 0.1 }]]}><Text>{item.invoice_number}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.billing_customer_name}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.taxable_amount}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.cgst_amount}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.sgst_amount}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.discount_amount}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.round_off}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.grand_total}</Text></View>
  //               </View>
  //             )

  //           })
  //         }
  //       </View>
  //     )
  //   }

  //   else if (this.state.salesReportList && this.state.salesReportList.length > 0) {

  //     return (
  //       <View style={{}}>
  //         {
  //           this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
  //             return (
  //               <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0 }}>
  //                 <View style={[{ flex: 0.1 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.invoice_date}</Text></View>
  //                 <View style={[[{ flex: 0.1 }]]}><Text>{item.invoice_number}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.billing_customer_name}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.taxable_amount}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.cgst_amount}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.sgst_amount}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.discount_amount}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.round_off}</Text></View>
  //                 <View style={[{ flex: 0.1 }]}><Text>{item.grand_total}</Text></View>
  //               </View>
  //             )

  //           })
  //         }
  //       </View>
  //     )
  //   }
  //   else {
  //     return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100 }}>
  //       <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
  //     </View>
  //   }
  // }

  // paginationChangedValues(data) {
  //   this.setState({
  //     billingListAfterPagination: data
  //   })
  // }

  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page } = this.state

    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        
        this.setState({
            records_per_page: records_per_page_change,
            current_page: next_page
        }, ()=>{
            this._getSalesOverView()
        })
    }
  }

  render() {
    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0

    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}

        {
          total_count > 0 ?
          <View style={{ marginLeft: 20 }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
              type={Constants.PAGINATION_TYPE_SERVER} 
              totalServerRecordsCount={total_count} 
              totalServerPagesCount={no_of_pages}/></View>
            : null
        }
      </View>
    );
  }

  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views0.07, 0.10, 0.11, 0.15, 0.10, 0.09, 0.09, 0.08, 0.1, 0.1 
        <View style={Styles.TableBodyContainer}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              let sno = (current_page-1)*records_per_page + index + 1;
              return (
                <View key={sno} style={Styles.TableBodyMainView}>
                  <View style={{ flex: 0.07, marginLeft: '1.30vw' }}><Text style={Styles.TableDataText}>{item.invoice_number}</Text></View>
                  <View style={{ flex: 0.10 }}><Text style={Styles.TableDataText}>{item.invoice_date?moment(item.invoice_date).format("DD-MM-YYYY"):""}</Text></View>
                  <View style={{ flex: 0.11 }}>
                    <Tooltip placement="topLeft" title={item.billing_customer_name}>               
                     <Text style={Styles.TableDataText}> {item.billing_customer_name && item.billing_customer_name.length > 12 ? item.billing_customer_name.slice(0,12) + ".." : item.billing_customer_name} </Text> 
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.15 }]}>
                    <Tooltip placement="topLeft" title={item.doctor_name}>               
                     <Text style={Styles.TableDataText}> { item.doctor_name && item.doctor_name.length > 12 ? item.doctor_name.slice(0,12) + ".." : item.doctor_name} </Text> 
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.10 }]}><Text style={Styles.TableDataText}>{item.taxable_amount}</Text></View>
                  <View style={[{ flex: 0.09 }]}><Text style={Styles.TableDataText}>{item.cgst_amount}</Text></View>
                  <View style={[{ flex: 0.09 }]}><Text style={Styles.TableDataText}>{item.sgst_amount}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text style={Styles.TableDataText}>{item.general_discount.toFixed(2)}</Text></View>
                  <View style={[{ flex: 0.1 }]}><Text style={Styles.TableDataText}>{item.round_off}</Text></View>
                  <View style={[{ flex: 0.1 }]}><Text style={Styles.TableDataText}>{Math.round(item.grand_total)}</Text></View>
                </View>
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={Styles.NoRecordsView}>
          <Text style={Styles.NoRecordsText}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }
}

const Styles = StyleSheet.create({
  TableHeaderView:{
    marginHorizontal: '1.30vw', marginTop: '0.65vw'
  },
  TableBodyContainer:{
    marginHorizontal: '1.30vw', backgroundColor: "#F9F9F9", marginTop: '0.97vw'
  },
  TableBodyMainView:{
    flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: '1.30vw', marginHorizontal: 0 
  },
  TableDataText:{
    fontSize:'0.91vw'
  },
  NoRecordsView:{
    flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: '9.76vw' 
  },
  NoRecordsText:{
    color: "#8888888", fontSize:'1vw' 
  }
})