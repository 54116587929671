//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Dimensions, ScrollView, TouchableWithoutFeedback, PanResponder } from 'react-native';
import { color } from "./styles/Color";

import AsyncStorage from "./AsyncStorage";
// import Arrow from "react-native-vector-icons/SimpleLineIcons";
import { SimpleLineIcons } from '@expo/vector-icons'
import moment from "moment"

// import { connect } from "react-redux";
// import { updateProfileDropdown } from "./Redux/ProfileDropdown"

import { CommonHeader, TransmittedPrescription, SavedBill, AppointmentList, CommonHeaderSearch ,PatientList} from './common/BaseFunctionComponent'

import Constants from './networks/Constants';
import Service from './networks/service';
import ResetPassword from './common/ResetPassword';
import Success from "react-native-vector-icons/FontAwesome5";
// import Service from "./networks/service";
// import { Constants } from "./networks/Constants";
import NewBill from './contents/NewBill';
import ModifyBill from './contents/modifyBill';
import Invoices from './contents/Invoices';
import Inventory from './contents/inventory';
import {Reports} from './contents/reports';
import Vendor from './contents/ventor';
import VendorHome from './contents/ventor/homeVendor';
import Admin from './contents/admin';
import VendorMonthwise from './contents/ventor/vendorMonthwise';
import CreditHome from './CreditBill/CreditHome';
import CreditBilling from './CreditBill/CreditBilling';
import { ReferredDoctors } from './contents/referredDoctors';
import Icon from "react-native-vector-icons/FontAwesome";
const screenHeight = Dimensions.get("window").height;

const tempPrescription = {
    "prescription_id": "",
    "patient_name": "",
    "patient_mobile": "",
    "doctor_name": "",
    "patient_acc_no": "",
    "bill_date": "",
    "bill_time": ""
}
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
// create a component
class RootComponent extends Component {
    constructor(props) {
        super(props);

        this.service = new Service();

        // from navigation
        const { params } = this.props.navigation.state;
        const menu = params ? params.menu : "billing";
        const page = params ? params.page : null;

        this.state = {
            user_name: "",
            service_type: "",

            selectedScreen: menu,
            subMenu: page,
            // popup search
            popupSeachText: "",
            // saved bill based
            savedBillFlag: false,
            savedBillList: [], 
            // transmitted based
            transmittedPrescriptionFlag: false,
            transmittedPrescriptionList: [],
            transmittedPrescriptionData: null,
            prescriptionDate: converted_date,
            // appointment based
            appointmentListFlag:false,
            appointmentList:[],
            appointmentSearchList:[],
            appointmentData: {},
            pageNumber: 1,
            pageSize: 10,
            patientAppointment:null,
            appointmentDate: converted_date,
            appointmentSearchText: "",

            // common search
            searchCommonValue: "",
            searchCommonTempValue: "",
            suggestionList: [],
            isSelected: false,
            searchWithoutDateCheck:false,

            // search invoice value for future use
            searchedValueForInvoice: "",
            login_user_data: "",
            login_user_is_admin: false,
            vendor_id : "",
            get_patientlist:false,
            patientID : "",
            selectedPatientDetails : [],
            selectedPatientDetail : {},
            get_searchpatientlist: false,
            patientSearchData: null,
 
            // discard
            billRows: "",
            is_complete_bill: false,
            isDiscardChanges: false,
            isYesClick: false,
            selectedSideMenuScreen: "",
            isSideMenuClick: false,
            selectedTopMenu: "",
            get_searchpatientlist: false,
            patientSearchData: null,
            reportCurrentPage: "",
            isBackButtonAction: false,
            selectedSavedBill: {},
            show:false,
            //pharmacy type
            pharmacyType: "",

            //Expiry drug  
            fromDate: converted_date,
            toDate: converted_date,
            isExpiredrug: false,
            expiredCount:"",

            //lowStock 
            isLowStock: false,
            stockListCount:"",

            //Resetpassword
            resetResponseType: "",
            resetResponseMeaasge: "",
            isResetPassword: false,

            //Admin config setting 
            configData: {}
        }

        this.changeScreen = this.changeScreen.bind(this);
        this.renderComponent = this.renderComponent.bind(this);
    }
    _panResponder = {};
    timer = 0;
   async componentWillMount(){
    let pharmacy_type = await AsyncStorage.getItem("pharmacy_type");
    this.setState({
        pharmacyType: pharmacy_type
    })
    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => {
        this.resetTimer()
        return true
      },
      onMoveShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => {  this.resetTimer();  return false},
      onMoveShouldSetPanResponderCapture: () => false,
      onPanResponderTerminationRequest: () => true,
      onShouldBlockNativeResponder: () => false,
    });
    
    this.timer = setTimeout(()=>{this.setState({show:true})}, 30 * 60 * 1000)
  }
  
  resetTimer(){
    clearTimeout(this.timer)
    this.setState({show:false })
    this.timer = setTimeout(()=>this.setState({show:true}), 30 * 60 * 1000)
    }

    async componentDidMount(){
        let user_name = await AsyncStorage.getItem("user_name");
        let service_type = await AsyncStorage.getItem("service_type");
        let login_user = await AsyncStorage.getItem("login_result");

        this._getSavedBills() // for saved bill
        // this._getExpiryDrug();
        // this._getLowStockList();
        var data = JSON.parse(login_user).data
        this._getDrugAndStockCount();
        this.configStatusGet();

        this.setState({
            user_name: user_name,
            service_type: service_type,
            login_user_data: JSON.parse(login_user),
            login_user_is_admin: data.is_admin_access ? data.is_admin_access : false,
            isResetPassword: data.is_password_reset ? data.is_password_reset : null 
        }, ()=>{
            this._geAppointmentList()
            if(service_type != "undefined") {
                if(service_type != Constants.ROLE_PHARMACIST) {
                    this._logoutFunction()
                }
            }
        })
    }
    renderPatientDetails = (data) => {
        this.setState({
           selectedPatientDetails : data
        })
    }
    discardChanges = (billRows, is_complete_bill) => {
        this.setState({
            billRows: billRows,
            is_complete_bill: is_complete_bill
        })

    }
    discardDataReset = (flag) => {       
        if(flag) {
        this.setState({
            transmittedPrescriptionFlag: false,
            transmittedPrescriptionData: null,
            transmittedPrescriptionList: [],
            appointmentListFlag: false,
            appointmentList: [],
            savedBillFlag: false,
            appointmentData: {},
            // savedBillList: [],
            selectedSavedBill: {},
            isDiscardChanges: false,
            selectedTopMenu: "",
            billRows: "",

        })
       }
    }


    //Get Api of expiry drug and lowStock count 
    _getDrugAndStockCount = () =>{
        let url = Constants.EXPIRY_REORDER_COUNT + "?from_date=" + this.state.fromDate + "&to_date=" + this.state.toDate
        this.service.getApiCall(
            url,
            this.getExpiryReorderSuccess,
            this.getExpiryReorderError
        )
    }

    getExpiryReorderSuccess = (response) => {
        let states = this.state
        let data = response.data
        if(response.status === "success"){
            states.expiredCount = data.expired_drugs
            states.stockListCount = data.reorder_threshold
            states.isExpiredrug = states.expiredCount > 0 ? true :false
            states.isLowStock = states.stockListCount > 0 ? true : false
         this.setState({
           states
        })
        }
    }

    getExpiryReorderError = (error) => {}

    //Get Api of configSetting
    configStatusGet = () => {
        var url = Constants.CONFIG_UPDATE
        this.service.getApiCall(
            url,
            this.getConfigSuccess,
            this.getConfigError
        )
    }
    getConfigSuccess = (response) => {
        if (response.status === "succes") {
            this.setState({
                configData: response.data ? response.data : {}
            })
        }
    }

    getConfigError = (response) => {
        if (response.status === "fail") {
            this.setState({
                configData: {}
            })
        }
    }


    reportCurrentPage = (currentPageName) => {
        this.setState({
            reportCurrentPage: currentPageName
        })
    }
    renderComponent(selectedComponent) {

        // var selectedComponent = this.state.selectedScreen;
        // alert(selectedComponent);

        var componentLookup = {
            billing: (< NewBill selectedSavedBill={this.state.selectedSavedBill} selectedBillToView={this.state.selectedBillToView} 
                initiateNewBill={this._initiateNewBill.bind(this)} patientAppointment={this.state.patientAppointment} 
                transmittedPrescription={this.state.transmittedPrescriptionData} 
                discardChanges = {this.discardChanges}
                patientSearchData = {this.state.patientSearchData}
                isSideMenuClick = { this.state.isSideMenuClick }
                isDiscardChanges = {this.state.isDiscardChanges}
                transmittedPrescriptionFlag = {this.state.transmittedPrescriptionFlag}
                appointmentListFlag = {this.state.appointmentListFlag}
                savedBillFlag = {this.state.savedBillFlag}
                selectedTopMenu = {this.state.selectedTopMenu}
                updatePopupStatus = {this.updatePopupStatus.bind(this)}
                discardDataReset = {this.discardDataReset}
                _getDrugAndStockCount = {this._getDrugAndStockCount}
                changeScreen={this.changeScreen.bind(this)} logout={this._logoutFunction} />),
            modifyBill: (<ModifyBill logout={this._logoutFunction} selectedBillToView={this.state.selectedModifyBillToView} changeScreen={this.changeScreen.bind(this)} discardChanges = {this.discardChanges} />),
            invoice: (<Invoices viewBill={this._viewBillDetails.bind(this)} modifyBill={this._modifyBillDetails.bind(this)} logout={this._logoutFunction} searchValue={this.state.searchCommonValue} />),
            inventory: (<Inventory subMenu={this.state.subMenu} logout={this._logoutFunction} searchValue={this.state.searchCommonValue} subSelectedScreen={this._changeSubScreen} loginUserData = {this.state.login_user_data} _getDrugAndStockCount = {this._getDrugAndStockCount} />),
            report: (<Reports subMenu={this.state.subMenu} logout={this._logoutFunction} reportCurrentPage={this.reportCurrentPage} changeScreen={this.changeScreen.bind(this)} isExpiredrug = {this.state.isExpiredrug} expiredCount = {this.state.expiredCount} isLowStock = {this.state.isLowStock} stockListCount = {this.state.stockListCount} pharmacyType = {this.state.pharmacyType} />),
            admin: (<Admin  changeScreen={this.changeScreen.bind(this)}  loginUserData = {this.state.login_user_data} _getDrugAndStockCount = {this._getDrugAndStockCount} reportConfigChange = {this.configStatusGet}/>),
            vendor: (<VendorHome  changeScreen={this.changeScreen.bind(this)} subMenu={this.state.subMenu} logout={this._logoutFunction} searchValue={this.state.searchCommonValue} subSelectedScreen={this._changeSubScreen} _getDrugAndStockCount = {this._getDrugAndStockCount} loginUserData = {this.state.login_user_data} login_user_is_admin = {this.state.login_user_is_admin} configData = {this.state.configData}/>),
            vendorMonthwise:  (<VendorMonthwise  changeScreen={this.changeScreen.bind(this)} subMenu={this.state.subMenu} vendor_id = {this.state.vendor_id} logout={this._logoutFunction}/>),
            creditHome: (<CreditHome  changeScreen={this.changeScreen.bind(this)} subMenu={this.state.subMenu} logout={this._logoutFunction}/>),
            creditBilling : (<CreditBilling  viewBill={this._viewBillDetails.bind(this)} changeScreen={this.changeScreen.bind(this)} patient_id ={ this.state.patientID } subMenu={this.state.subMenu} logout={this._logoutFunction} renderPatientDetails = {this.renderPatientDetails} selectedPatientDetails = {this.state.selectedPatientDetails} />),
            referredDoctors : (<ReferredDoctors  changeScreen={this.changeScreen.bind(this)}  loginUserData = {this.state.login_user_data}/>),
        }
        return (<View>
            {componentLookup[selectedComponent]?componentLookup[selectedComponent]:null}
        </View>);
    }

    showResetResposeValue(type, message) {
        this.setState({
            resetResponseType: type,
            resetResponseMeaasge: message
        }, () => {
            setTimeout(() => {
                this.setState({ resetResponseType: "",
                resetResponseMeaasge: "" });
            }, 3000);
        })
    }

    resetSuccessAlert() {
        return (
            <View style={[{  width: "40%", backgroundColor: "#DEF2D6",  borderColor: "#B8C7B2", height: '4.55vw' }, styles.AlertWrapper]}>
                <View style={styles.AlertContentView}>
                    <Success size={'1.62vw'} name={"check"} color={"green"} />
                    <Text style={styles.SuccessTitle}>{"SUCCESS: "}
                        <Text style={styles.SuccessText}>{this.state.resetResponseMeaasge}</Text>
                    </Text>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ resetResponseType: '' }) }} style={styles.AlertIcon}>
                    <Icon size={'1.62vw'} name={"close"} color={"green"} />
                </TouchableOpacity>
            </View>
        );
    }

    resetErrorAlert() {
 
        let errorList = (this.state.resetResponseMeaasge) ? (this.state.resetResponseMeaasge).split(",") : []
        return (
            <View style={[{ width: "35%", backgroundColor: "#CD3A34", borderColor: "#A58B8A",  minHeight: '4.55vw'}, styles.AlertWrapper]}>
                <View style={[styles.AlertContentView, {marginTop: '0.97vw' }]}>
                    <View style={styles.ErrorView1}>
                        <Icon size={'1.62vw'} name={"ban"} color={"#FFF"} /></View>
                    <View style={styles.ErrorView2} >
                        <View>
                            <Text style={styles.ErrorTitle}>{"ERROR: "}
                                {errorList && errorList.map((item, index) => {
                                    return <View key={index}><Text style={styles.ErrorText}>{item}</Text></View>
                                })}
                            </Text>
                        </View>

                    </View>
                </View>
                <TouchableOpacity onPress={() => { this.setState({ resetResponseType: '' }) }} style={styles.AlertIcon}>
                    <Icon size={'1.62vw'} name={"close"} color={"#fff"} />
                </TouchableOpacity>
            </View>
        );
    }

    _modifyBillDetails(bill) {
        this.setState({
            selectedScreen: "modifyBill", 
            selectedModifyBillToView: bill,
            
            selectedSavedBill: null,
            patientAppointment: null,
            transmittedPrescriptionData: null,
            patientSearchData: null
        })
    }

    _viewBillDetails (bill) {

        this.setState({ 
            selectedScreen: "billing", 
            selectedBillToView: bill,
            
            selectedSavedBill: null,
            patientAppointment: null,
            transmittedPrescriptionData: null,
            patientSearchData: null
        })
    }

    changeScreen(screen,id, data){
        // this._getExpiryDrug();
        // this._getLowStockList();
        let states = this.state;
        let { searchedValueForInvoice } = this.state

        states['selectedScreen'] = screen;

        // reset
        states['reportCurrentPage'] = "";
        states['showProfileDropDownFlag'] = false;
        states['searchCommonValue'] = "";
        states['searchCommonTempValue'] = "";
        states['suggestionList'] = [];
        states["vendor_id"] = id
        states['get_patientlist'] = false
        states['patientID'] = id
        states['selectedPatientDetail'] = data
        states["selectedModifyBillToView"] = null;
        if(screen != "billing") {

            states['selectedSavedBill'] = null;
            states['selectedBillToView'] = null;
        }
        if( screen != "inventory" )
        {
            states["subSelectedScreen"] = ""
        }else{
            states["subSelectedScreen"] = "stock_list"
        }
        if(screen == "invoice") {
            states['searchCommonTempValue'] = searchedValueForInvoice
            states['searchCommonValue'] = searchedValueForInvoice
        }
        states["billRows"] = []
        states["patientSearchData"] = null
        this.setState({ states })
    }

    _changeSubScreen=(subScreen)=>{

        // let { subSelectedScreen } = this.state

        // subSelectedScreen = subScreen
        
        this.setState({
            subSelectedScreen: subScreen,
            suggestionList: [],
            isSelected: false,
            searchCommonTempValue: "",
            searchCommonValue: ""
        })
    }

    getCurrentPageNumberAndCount = (pageNumber, ItemCount) => {
        // console.log("Page-number:",pageNumber);
        // console.log("ItemCount:",ItemCount);
        this.setState({
            pageNumber: pageNumber,
            pageSize: ItemCount
        },() => {
            this._geAppointmentList()
        })
    }

    renderImgBtn = (img, title, menuItem, imageFlag = false) => {
        return (
            <TouchableOpacity onPress={() => { 
                this.setState({
                    isSideMenuClick: true,
                    reportCurrentPage: ""
                }, () => {
                   if(this.state.selectedTopMenu == "")  { 
                    if(((this.state.billRows.length > 0 && !this.state.is_complete_bill ) || ( this.state.transmittedPrescriptionData != null && !this.state.is_complete_bill))&& !this.state.appointmentListFlag && !this.state.transmittedPrescriptionFlag && !this.state.savedBillFlag ) {
                        this.setState({
                          isDiscardChanges: true,
                          selectedSideMenuScreen: menuItem
                        })
                     } else {
                      this.setState({
                          billRows: ""
                      },()=>{
                          this.changeScreen(menuItem) 
                      })
                     }
                    } else {
                        
                    }
                })
            }}>
                <View style={{
                    // backgroundColor: imageFlag ? color.white : "",
                    height: "4.4vw",
                    width: "3.5vw", alignItems: "center", justifyContent: "center", alignSelf: 'center', alignContent: 'center',
                    marginTop: "1.15vw" ,
                    borderRadius: "3.25vw"
                }}>
                    <View style={{paddingHorizontal:"0.6vw",paddingVertical:"0.45vw",backgroundColor: imageFlag ? color.white : "",borderRadius:"0.25vw"}}>
                    <Image
                        source={img}
                        style={{
                            height: "1.85vw",
                            width: "1.85vw",
                            tintColor: !imageFlag ? title === "Report"? "" : color.white : color.themeDark,//color.lightGray
                        }}
                        resizeMode="contain"
                    />
                    </View>
                    <Text style={{ fontSize: "0.65vw", fontWeight: "500", color: imageFlag ? color.white : color.white, paddingLeft: 0, textAlign: "center" }} numberOfLines={2}>{title}</Text>
                </View>
            </TouchableOpacity>
        );
    };
    onChangePrescriptionDate = (key, Date) => {
     if(key == "prescriptionDate") {    
      this.setState({
        prescriptionDate: moment(Date).format('YYYY-MM-DD')
      },()=>{
        this._getTansmittedPrescriptionList()
      })
     }
    }
   
    render() {
        var { selectedScreen, subSelectedScreen, user_name, service_type, pharmacyType } = this.state;
        return (
            <TouchableWithoutFeedback onPress={()=>{
                // this.props.updateProfileDropdown(false)
            }} {...this._panResponder.panHandlers}>
            <View style={styles.container}>
                {
                    this.state.show ?  this.IdleLoggingOut() : null
                }
                <CommonHeader
                    currentPage = {selectedScreen} 
                    subScreen = {subSelectedScreen} 
                    user_name = {user_name} 
                    service_type = {service_type} 
                    pharmacyType = {pharmacyType}

                    // common search
                    searchValue={this.state.searchCommonTempValue}
                    onChangeSearchText={this._onChangeSearchText}
                    searchPatient={this._searchCommon} 
                    suggestionList={this.state.suggestionList} 
                    isSelected={this.state.isSelected}

                    updatePopupStatus={this.updatePopupStatus.bind(this)} 

                    // dropDown
                    showProfileDropDownFlag={this.state.showProfileDropDownFlag}
                    profileDropDownFlagChange={this._profileDropDownFlagChange.bind(this)}
                    logoutFunction={this._logoutFunction.bind(this)}                 
                    //Home page patient search
                    getAllPatientList={this.getAllPatientList}
                    getPatientListWithSearchText={this.getPatientListWithSearchText}
                    selectedScreen={this.state.selectedScreen}
                    closePatienSearchModal={this.closePatienSearchModal}
                    transmittedPrescriptionList={this.state.savedBillList}  
                    selectedPatientDetail = {this.state.selectedPatientDetail}   
                    changeScreen={this.changeScreen.bind(this)} 
                    reportCurrentPage = {this.state.reportCurrentPage}  
                />

                <View style={[styles.bodyContainer, { height: "100%" }]} >
                <View style={{width: "6%", backgroundColor: color.themeBackgroud,  justifyContent: 'space-between', paddingBottom: "1.30vw", flex :1 }}>
                {this.state.login_user_data != "" ?<View>
                                {this.state.login_user_data.data.is_prescription_access ?
                                    <View>
                                        {this.renderImgBtn(
                                            require("./../assets/Icon/Billing.png"),
                                            "Billing", 'billing', selectedScreen == "billing" ? true : false)}
                                    </View> : null
                                }
                                 {/* <View>
                                        {this.renderImgBtn(
                                            require("../assets/Icon/Billing.png"),
                                            "Credit Bill", 'creditHome', selectedScreen == "creditHome" ? true : false)}
                                </View>  */}
                                {this.state.login_user_data.data.is_invoices_access ?
                                    <View>
                                        {this.renderImgBtn(
                                            require("./../assets/Icon/Invoice.png"),
                                            "Invoice", 'invoice', selectedScreen == "invoice" ? true : false)}
                                    </View> : null
                                }
                                {this.state.login_user_data.data.is_inventory_access ?
                                    <View>
                                        {this.renderImgBtn(
                                            require("./../assets/Icon/Inventory.png"),
                                            "Inventory", 'inventory', selectedScreen == "inventory" ? true : false)}
                                    </View> : null
                                }
                                <View>
                                        {this.renderImgBtn(
                                            require("../assets/images/HomeScreenImages/vendor.png"),
                                            "Purchase", 'vendor', selectedScreen == "vendor" ? true : false)}
                                </View> 
                                {this.state.login_user_data.data.is_reports_access ?
                                    <View>
                                        {this.renderImgBtn(
                                           this.state.isExpiredrug || this.state.isLowStock ? require("./../assets/Icon/ReportWhite.png"): require("./../assets/Icon/Report.png"),
                                            "Report", 'report', selectedScreen == "report" ? true : false)}
                                    </View> : null
                                }

                                {this.state.login_user_data.data.is_admin_access ?
                                    <View>
                                        {this.renderImgBtn(
                                            require("./../assets/Icon/AdminIconWhite.png"),
                                            "Admin", 'admin', selectedScreen == "admin" ? true : false)}
                                    </View> : null
                                }
                               
                            </View> : null}

                    {/* <TouchableOpacity onPress={() => this._logoutFunction.bind(this)}>
                        <View style={{ alignSelf: "center" }}>
                            <SimpleLineIcons
                                name="logout"
                                size={25}
                                color={color.white}
                            />
                            <Text style={{ fontSize: 10, fontWeight: "500", color: color.white, paddingLeft: 0, textAlign: "center" }} numberOfLines={2}>Logout</Text>
                        </View>
                    </TouchableOpacity> */}
                </View>

                <View style={{ width: "94%", height: screenHeight-65 }}>
                    <TouchableWithoutFeedback onPress={() => this._clearDropdowns() } >
                        <ScrollView
                            ref={scrollView => this.scrollView = scrollView}
                            onContentSizeChange={() => {
                                // this._onContentSizeChange();
                            }}
                            style={{ height: screenHeight-65 }}
                            >
                            {this.renderComponent(selectedScreen)}
                        </ScrollView>
                    </TouchableWithoutFeedback>
                    {this.state.isDiscardChanges ?
                        <View style={{ justifyContent: "center", alignItems: "center", height: '100%', width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute' }}>
                           <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: '0.25vw', padding: '1.30vw' }}>
                              <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: "10%" }}>{"Are you sure to discard ?"}</Text>
                                <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "50%", height:"20%", flexWrap: "wrap-reverse" }}>
                                    <TouchableOpacity
                                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw' }}
                                        onPress={() => { 
                                                this.changeScreen(this.state.selectedSideMenuScreen) 
                                                this.setState({
                                                    billRows: "",
                                                    transmittedPrescriptionData: null,
                                                    isDiscardChanges: false,
                                                    selectedTopMenu: ""
                                                })
                                         }}>
                                        <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"Yes"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw' }}
                                        onPress={() => { 
                                            this.setState({ 
                                                isDiscardChanges : false 
                                            })
                                        }}
                                    >
                                        <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"No"}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                        : null
                    }
                    

                </View>

            </View>

            {
                this.state.savedBillFlag ?
                    <SavedBill userType={'pharmacy'} 

                        selectedSavedBill={this._selectedSavedBill.bind(this)} 
                        cancelRequest={this.cancelRequest.bind(this)} 
                        closeModal={this.updatePopupStatus.bind(this, 'savedBillFlag', false)} 
                        transmittedPrescriptionList={this.state.savedBillList} 
                        deleteSavedBill={this._deleteSavedBill} 
                        
                        // searchText={this.state.popupSeachText}
                        // changeSearchText={this._updatePopupSearchText} 
                        searchPatient={this._getSavedBills} 
                    /> : null
            }
            {
                this.state.transmittedPrescriptionFlag ?
                    <TransmittedPrescription userType={'pharmacy'} 
                        AcceptRequest={this.setModalVisible.bind(this)} 
                        cancelRequest={this.cancelRequest.bind(this)} 
                        closeModal={this.updatePopupStatus.bind(this, 'transmittedPrescriptionFlag', false)} 
                        transmittedPrescriptionList={this.state.transmittedPrescriptionList} 
                        selectedTransmittedPresc={this.selectedTransmittedPresc.bind(this)} 
                        // searchText={this.state.popupSeachText}
                        // changeSearchText={this._updatePopupSearchText} 
                        searchPatient={this._getTansmittedPrescriptionList} 
                        onChangePrescriptionDate = { this.onChangePrescriptionDate }
                        prescriptionDate = { this.state.prescriptionDate }
                    /> : null
            }

            {this.state.get_patientlist ?   
                <PatientList
                        appointmentSearchList={this.state.appointmentSearchList} 
                        appointmentData={this.state.appointmentData}
                        closeModal={this.updatePopupStatus.bind(this, 'appointmentListFlag', false)} 
                        selectedSearchAppointmentList={this.selectedSearchAppointmentList.bind(this)}
                        currentPage={this.getCurrentPageNumberAndCount}
                        closePatienSearchModal={this.closeOverAllSearch}
                        showPatientSearchModal={this.showPatientSearchModal}
                        getPatientListWithSearchText={this.getPatientListWithSearchText}
                /> : null
            }
{
                this.state.appointmentListFlag ?
                    <AppointmentList userType={'pharmacy'} 
                        closeModal={this.updatePopupStatus.bind(this, 'appointmentListFlag', false)} 
                        selectedappointmentList={this.selectedappointmentList.bind(this)}
                        appointmentList={this.state.appointmentList} 
                        appointmentDate={this.state.appointmentDate} 
                        appointmentData={this.state.appointmentData}
                        currentPage={this.getCurrentPageNumberAndCount}
                        // search actions 
                        searchAppointment={this._searchAppointment} 
                        onChangeAppointmentDate={this._onChangeAppointmentDate}
                    /> : null
            }
        {
            this.state.isResetPassword ? 
            <View style={{position: 'absolute'}}>
                <View style={{
                    position: "absolute",
                    zIndex: -1,
                    height: "100vh",
                    width: "100vw",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}>
                    <ResetPassword 
                    logoutFunction={this._logoutFunction.bind()} 
                    showResetResposeValue={this.showResetResposeValue.bind(this)}/>
                    {
                        this.state.resetResponseType == "success" ?
                        this.resetSuccessAlert() :
                        this.state.resetResponseType == "error" ?
                        this.resetErrorAlert() : null
                    }
                </View>
            </View>
            
            : null
        }


            </View>
            </TouchableWithoutFeedback>
        );
    }

    closeTransmittedPrescriptionModal(key){
        this.setState({
            savedBillFlag: false
        })
    }
    prescriptionPrint = (id) => {
        var url = Constants.GET_TRANSMITTED_PRESCRIPTION +  id + "/" + "?export_type=pdf";
        this.service.getInstance().documentUploadGet(
            url,
            this.PrintReportSuccess,
            this.PrintReportFailure,
            "pdf"
        );
    }
    PrintReportSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    };

    PrintReportFailure = error => {
        console.log(error);
    };
    selectedTransmittedPresc(item) {
        
        if(item) {
            if(item.id) {
                item['is_transmitted']=true;
                item['transmitted_id']=item['id']
            }
        }

        this.setState({ 
            transmittedPrescriptionFlag: false,
            selectedScreen: "billing",
            patientAppointment: null,
            patientSearchData: null,
            selectedSavedBill: null,
            selectedBillToView: null,
            transmittedPrescriptionData: null

        },()=>{
            this.prescriptionPrint(item.transmitted_id)
            if(item.is_transmitted && item.transmitted_id)
            {
                this._getPrescriptionDetails(item.transmitted_id)
            }
        })
    }
    selectedSearchAppointmentList(item) {
        this.setState({ 
            appointmentListFlag: false,
            selectedScreen: "billing",
            patientAppointment: null, 

            selectedSavedBill: null,
            selectedBillToView: null,
            prescription_lines: [], 
            transmittedPrescriptionData: null,
            get_patientlist:false,
            patientSearchData: item
        },()=>{

            if(item.is_transmitted && item.transmitted_id)
            {
                this._getPrescriptionDetails(item.transmitted_id)
            }else{
                //this._getpatientList(item.id)
            }
        })
    }
    selectedappointmentList(item) {
     
        this.setState({ 
            appointmentListFlag: false,
            selectedScreen: "billing",
            patientAppointment: item, 

            selectedSavedBill: null,
            selectedBillToView: null,
            prescription_lines: [], 
            transmittedPrescriptionData: null,
            get_patientlist:false,
            patientSearchData: null
        },()=>{

            if(item.is_transmitted && item.transmitted_id)
            {
                this._getPrescriptionDetails(item.transmitted_id)
            }else{
                //this._getpatientList(item.id)
            }
        })
    }

    updatePopupStatus(key, flag, data=[]) {
        let states = this.state;

        states[key] = flag;
        states['selectedSavedBill'] = null;
        states['popupSeachText'] = "" 

        // clear appointment search details
        states['appointmentDate'] = new Date() 
        states['appointmentSearchText'] = "" 
        states['appointmentList'] = []
        states["get_patientlist"]=false
        states['prescriptionDate'] = converted_date
        if(flag) {
            states['selectedTopMenu'] = key
        } else {
            states['selectedTopMenu'] = ""
        }

        this.setState({
            // savedBillFlag: flag,
            // transmittedPrescriptionList: data
            states
        }, ()=>{

            if(flag){
                if(key==='savedBillFlag')
                    this._getSavedBills()
                
                if(key==='transmittedPrescriptionFlag')
                    this._getTansmittedPrescriptionList()

                    if(key==='appointmentListFlag')
                    this._geAppointmentList(key)

            }
        })
    }

    setModalVisible(data, appointmentModalClose) {
        
        this.setState({
            savedBillFlag: appointmentModalClose
        })
    }

    cancelRequest(item) {}

    _logoutFunction = async () => {
        
        await AsyncStorage.removeItem("loggedIn");
        await AsyncStorage.removeItem("token");
        await AsyncStorage.removeItem("service_type");
        await AsyncStorage.removeItem("gstin_number")
 
        await AsyncStorage.clear();
        
        this.props.navigation.navigate("auth");
    };

    IdleLoggingOut(){
        this._logoutFunction();
    }

    _getSavedBills=(searchText)=>{
        var search = searchText ? searchText : ""
        let url = Constants.BILL_PARTIAL_SAVED_LIST + "?search=" + search;
    
        this.service.getApiCall(
            url,
            this._getSavedBillsSuccess,
            this._getFailure
        )
    }

    _getSavedBillsSuccess = response => {
    
        if (response.status === "success") {
     
            let list = JSON.parse(JSON.stringify(response.data));
    
            // update
            this.setState({ 
                savedBillList: list,

            });
        }
    };

    _getFailure = error => {
        this.setState({
            selectedTopMenu: ""
        })
        console.log("Error response");
    
    };

    _selectedSavedBill(item) {

        this.setState({ 
            savedBillFlag: false,
            selectedScreen: "billing",
            selectedSavedBill: item,

            selectedBillToView: null,
            patientAppointment: null,
            transmittedPrescriptionData: null
        })
    }

    _deleteSavedBill=(bill_summary_id)=>{

        let { selectedSavedBill } = this.state

        if(bill_summary_id)
        {
            let url = `${Constants.PARTIALLY_SAVED_BILL}${bill_summary_id}/delete/` 

            this.service.postApiCall(
                url,
                null,
                this._successDeleteCallBack,
                this._errorDeleteCallBack
            )
            /*
            // clear deleted saved bills
            if( selectedSavedBill )
            {
                if( selectedSavedBill.bill_summary_id == bill_summary_id ) 
                {
                    this.setState({
                        selectedSavedBill: null
                    })
                }
            }
            */
        }
    }
    closeOverAllSearch=()=>{
        this.setState({
            get_searchpatientlist:false
        })
    }
    showPatientSearchModal=()=>{
        this.setState({
            get_searchpatientlist:true
        })
    }
    getAllPatientList=(flag) => {
        this.setState({ 
            get_patientlist:flag,
            searchWithoutDateCheck: flag
        }, ()=>{
            this._geAppointmentList()
        })
    }
    closePatientPatientModel=()=>{
        this.setState({
            get_patientlist:false
        })
    }
    getPatientListWithSearchText=(searchText)=>{
        this.setState({ 
            appointmentSearchText: searchText ,
            searchWithoutDateCheck: true
        }, ()=>{
            this._geAppointmentList()
        })
    }

    _successDeleteCallBack=response=>{

        if(response.status==="success") {
        
            this._getSavedBills()

        } else {

        }
    }

    _errorDeleteCallBack=response=>{
    
    }

    _getTansmittedPrescriptionList = (popupSeachText='') => {
        // let { popupSeachText } = this.state

        // let dt = new Date();
        // let dtFormatted = moment(dt).format('YYYY-MM-DD');

        let url = Constants.TRANSMITTED_PRESCRIPTION_LIST //+ "?date=" + dtFormatted;

        if( popupSeachText || this.state.prescriptionDate)
        {
            url = `${url}?search_key=${popupSeachText}&prescription_date=${this.state.prescriptionDate}`
        }
    
        this.service.getApiCall(
            url,
            this._getPrescriptionsSuccess,
            this._getFailure
        )
    }

    _getPrescriptionsSuccess = response => {
    
        if (response.status === "success") {
     
            let list = JSON.parse(JSON.stringify(response.data));
    
            // update
            this.setState({ 
                transmittedPrescriptionList: list
            });
        }
    };

    _onChangeAppointmentDate=(key, date)=>{

        this.setState({ appointmentDate: date }, ()=>{
            this._geAppointmentList("appointmentListFlag")
        })
    }
  

    _searchAppointment = (searchText) => {

        this.setState({ appointmentSearchText: searchText }, ()=>{

            this._geAppointmentList()
        })
    }

    _geAppointmentList = async (key="") => {
        
        let today = new Date();
        // let dtFormatted = moment(dt).format('YYYY-MM-DD');
        var url = ""
        let { appointmentDate, appointmentSearchText,pageNumber, pageSize, pharmacyType } = this.state
        if(this.state.get_patientlist && pharmacyType === "Standalone Pharmacy"){

            url = Constants.GET_PATIENT_LIST + "?search_key=" + appointmentSearchText ;
           
        }else if(this.state.get_patientlist && pharmacyType != "Standalone Pharmacy"){

            url = `${Constants.OVER_ALL_SEARCH}?search_key=${appointmentSearchText}`

        } else { 
            let dtFormatted = (key == "appointmentListFlag") ? moment(this.state.appointmentDate).format('YYYY-MM-DD') : converted_date

            url = `${Constants.GET_APPOINTMENT_LIST}?page=${pageNumber}&page_size=${pageSize}&from_date=${dtFormatted}&to_date=${dtFormatted}` 
            let dateValue = appointmentDate ? appointmentDate : today
    
            // url = `${url}?from_date=${dtFormatted}&to_date=${dtFormatted}` 
    
            if( appointmentSearchText) {
                
                url = `${url}&search_key=${appointmentSearchText}`
            }
       }
        this.service.getApiCall(
            url,
            this._geAppointmentListSuccess,
            this._getFailure
        )
    }

    _geAppointmentListSuccess = response => {
    
        if (response.status === "success") {
            var searchList = JSON.parse(JSON.stringify(response.data)); 
            let list = JSON.parse(JSON.stringify(response.data));
            let data = response;
            // update'
            this.setState({ 
                appointmentSearchList: this.state.pharmacyType === "Standalone Pharmacy" ? response.data : searchList.patient,
                appointmentData: data,
                appointmentList: list,
            });
        }
    };


    _getpatientList(id) {

        let url = Constants.GET_APPOINTMENT_PATIENT_DETAILS +id //+ "?date=" + dtFormatted;
    
        this.service.getApiCall(
            url,
            this._getpatientListSuccess,
            this._getFailure
        )
    }

    _getpatientListSuccess = response => {
    
        if (response.status === "success") {
     
            let list = JSON.parse(JSON.stringify(response.data));
          
            this.setState({ 
                appointmentDetails: list
            });
        }
    };

    _initiateNewBill () {
        /*
        selectedSavedBill={this.state.selectedSavedBill} 
        selectedBillToView={this.state.selectedBillToView}
        initiateNewBill={this._initiateNewBill.bind(this)} 
        patientAppointment={this.state.patientAppointment} 
        transmittedPrescription={this.state.transmittedPrescriptionData}
        */
        this.setState({ 
            savedBillFlag: false,
            selectedScreen: "billing",
            selectedSavedBill: null,
            selectedBillToView: null,
            patientAppointment: null,
            transmittedPrescriptionData: null,
            patientSearchData: null
        })        
    }

    _getPrescriptionDetails (id) {
        

        let url = Constants.GET_TRANSMITTED_PRESCRIPTION + id + "/" //+ "?date=" + dtFormatted;
    
        this.service.getApiCall(
            url,
            this._getPrescriptionSuccess,
            this._getFailure
        )
    }

    _getPrescriptionSuccess = response => {
    
        if (response.status === "success") {
     
            let list = JSON.parse(JSON.stringify(response.data));
          
            this.setState({ 
                transmittedPrescriptionData: list?list:null
            });
        }
    };

    _clearDropdowns=()=>{
        let { showProfileDropDownFlag } = this.state

        if( showProfileDropDownFlag ) {
            this.setState({ 
                showProfileDropDownFlag: false
            })
        }
    }

    _profileDropDownFlagChange(flag) {
        this.setState({
            showProfileDropDownFlag: flag
        })
    }

    _onChangeSearchText=(searchText, flag)=>{

        let { selectedScreen, subSelectedScreen, searchCommonValue, suggestionList } = this.state;
        if(selectedScreen == "inventory" && !subSelectedScreen){
            subSelectedScreen = "stock_list";
        }

        if( (selectedScreen == "inventory" && subSelectedScreen=="stock_list") || selectedScreen == "inventory" && subSelectedScreen=="new_drug_request"  || selectedScreen == "inventory" && subSelectedScreen=="wanted_list")
        {
            this.setState({
                searchCommonTempValue: searchText, 
                searchCommonValue: flag || !searchText? searchText: searchCommonValue,
                isSelected: flag, 
                suggestionList: searchText?suggestionList:[]
            },()=>{
                if(!flag)
                {
                    if( searchText )
                    {
                        this._getDrugSuggestion(searchText)
                    }
                }
            })
        } else if( selectedScreen == "invoice" ) {

            this.setState({
                searchCommonTempValue: searchText, 
                searchCommonValue: searchText,
                
                searchedValueForInvoice: searchText
            })            
        }else if(selectedScreen == "inventory" && subSelectedScreen=="vendors" || subSelectedScreen=="purchase_orders"){
            this.setState({
                searchCommonTempValue: searchText, 
                searchCommonValue: flag || !searchText? searchText: searchCommonValue,
                isSelected: flag, 
                suggestionList: searchText?suggestionList:[]
            },()=>{
                if(!flag)
                {
                    if( searchText)
                    {
                        this.getVendorlist(searchText)
                    }
                }
            })
        }
    }

    _getDrugSuggestion = (drugName) => {
        
        let { suggestionList } = this.state;
        
            if (drugName !== "") {

            let sameInList = false;
            let isEnableDrugList = false;
            let len = suggestionList.length;
            
            for (var i = 0; i < len; i++) {

                let value = suggestionList[i];

                if (drugName == value) {
                    sameInList = true;
                    return;
                }
            }
            
            if (sameInList) {

                isEnableDrugList = true;

                this.setState({
                    isSelected: true
                })

            } else {

                if(drugName.length > 2) 
                {
                    let url = Constants.SEARCH_DRUG + "?brand_name=" + drugName;

                    this.service.getApiCall(
                        url,
                        this._getDrugSuccess,
                        this._getDrugFailure
                    )
                }
            }

        } else {

            this.setState({
                suggestionList: [],
                isSelected: false
            })
        }
    }
    
    _getDrugSuccess = response => {
    
        let { suggestionList } = this.state;

        if (response.status === "success") {

            let data = JSON.parse(JSON.stringify(response.data));

            this.setState({ suggestionList: data, isSelected: false })
        }
    };

    _getDrugFailure = error => {

        console.log("GET error response");
        console.log(error);
    
    };
    getVendorlist = (vendorname) =>{
        let { suggestionList } = this.state;
        
        if (vendorname !== "") {

        let sameInList = false;
        let isEnableDrugList = false;
        let len = suggestionList.length;
        
        for (var i = 0; i < len; i++) {

            let value = suggestionList[i];

            if (vendorname == value) {
                sameInList = true;
                return;
            }
        }
        
        if (sameInList) {

            isEnableDrugList = true;

            this.setState({
                isSelected: true
            })

        } else {

            if(vendorname.length > 1) 
            {
                let url = Constants.LIST_VENDOR  + "?search_term="+vendorname+"&from=purchase_order"
                this.service.getApiCall(
                    url,
                    this.getvendorlistsuccess,
                    this.getvendorlistFailure
                ) 
            }
        }

    } else {

        this.setState({
            suggestionList: [],
            isSelected: false
        })
    }
          
    }    
        
    getvendorlistsuccess = (response) => {
        let { suggestionList } = this.state;
        if (response.status === "success") {
            let data = []
            response.data.result.forEach(element => {
                data.push(element.name)
            });
            //let data = JSON.parse(JSON.stringify(response.data));

            this.setState({ suggestionList: data, isSelected: false })
        }      
      }
      
    getvendorlistFailure = error => {
        console.log("Get Tag list error response");
      };

    _updatePopupSearchText=(searchValue)=>{

        this.setState({ popupSeachText: searchValue })
    }

}

// define your styles
const styles = StyleSheet.create({
    container: {
        width: "100%",
        flex: 1,
        // flexDirection: 'row',
        backgroundColor: color.white
    },
    bodyContainer:{
        width: "100%",
        flex: 1,
        flexDirection: 'row',
        backgroundColor: color.white,
        zIndex:-1
    },
    AlertWrapper:{
        flex: 1, 
        zIndex: 3,
        position: 'absolute', 
        left: '0.65vw', 
        bottom: '0.32vw', 
        right: 0, 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        flexDirection: "row",
        borderRadius: '0.32vw', 
        borderWidth: 1,  
        marginRight: '0.65vw', 
        marginTop: '0.65vw'
    },
    AlertContentView:{
        marginLeft: '0.65vw', 
        flex: 0.95, 
        flexDirection: 'row', 
        alignItems: 'center',
    },
    SuccessTitle:{
        fontWeight: "700", 
        color: "green", 
        fontSize: '0.8vw', 
        marginLeft: '0.97vw'
    },
    SuccessText:{
        fontWeight: "400", 
        color: "green", 
        fontSize: '0.8vw'
    },
    ErrorView1:{
        flex: 0.1, 
        alignSelf: "flex-start", 
        marginBottom: '0.65vw'
    },
    ErrorView2:{
        flex: 0.9, 
        flexDirection: "column", 
        justifyContent: "flex-start"
    },
    ErrorTitle:{
        fontWeight: "700", 
        color: "#FFF", 
        fontSize: '0.8vw'
    },
    ErrorText:{
        fontWeight: "400", 
        color: "#FFF", 
        fontSize: '0.8vw', 
        marginBottom: '0.52vw'
    },
    AlertIcon:{
        flex: 0.05, 
        marginRight: '0.97vw', 
        alignItems: 'center', 
        alignContent: 'center', 
        alignSelf: 'center', 
        justifyContent: "center"
    }
});

//make this component available to the app
export default RootComponent;

/*
const mapDispatchToProps = (dispatch) => {
    return {   
        updateProfileDropdown : (data) => dispatch(updateProfileDropdown(data)) 
    }
}
export default connect (null, mapDispatchToProps)(RootComponent)
*/