import React, { Component } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, Image, Dimensions } from 'react-native';
import { CommonReportSectionHeader, CommonAutoSuggestion } from '../../common/BaseFunctionComponent'
import { color } from '../../styles/Color';

import Constants from "../../networks/Constants";
import Service from '../../networks/service';
import BaseComponent from "../../common/BaseComponent";
import VendorMonthwise from './vendorMonthwise';
import Pagination from "../../common/Pagination"
import { ScrollView } from 'react-native-gesture-handler';

class Vendor extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      vendor_name: "",
      vendor_list_suggest: [],
      vendor_list: [],
      isSelected: true,
      vendor_id: null,
      selectedScreen: "",
      vendorDataListAfterPagination : [],
      current_page: 0,
      records_per_page: 10

    };
    this.service = new Service()
    this._paginationChangedValues = this.paginationChangedValues.bind(this)
  }
  componentDidMount() {
    this._getVendorList()
    this._getVendorPayment()
  }
  _getVendorList() {
    let url = Constants.LIST_VENDOR + "?from=purchase_order";
    this.service.getApiCall(
      url,
      this._getVendorListSuccess,
      this._getDosageTypeFailure
    )
  }

  _getVendorListSuccess = response => {

    if (response.status === "success") {

      this.setState({
        vendor_list_suggest: response.data || [],
        vendor_list: response.data || [],

      });
    }
  };

  _getDosageTypeFailure = response => {
  }
  _getVendorPayment(vendor_id) {
    var vendorId = vendor_id == null ? "" : vendor_id
    let url = Constants.GET_VENDOR_PAYMENT + "?vendor_id=" + vendorId;
    this.service.getApiCall(
      url,
      this._getVendorPaymenttSuccess,
      this._getVendorPaymentFailure
    )
  }

  _getVendorPaymenttSuccess = response => {

    if (response.status === "success") {

      this.setState({
        vendorDataList: response.data
      });
    }
  };

  _getVendorPaymentFailure = response => {
  }

  renderHeader() {
    return (
      <View style={VendorStyle.TableHeader}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Vendor Name"}
          heading3={"GST Number"}
          heading4={"Total PO"}
          heading5={"Bill Amount"}
          heading6={"Paid Amount"}
          heading7={"Outstanding Payment"}
          columnSize={[0.14, 0.14, 0.14, 0.14, 0.14, 0.14, 0.14]}
          noOfColumn={7}

        />
      </View>
    )
  }
  renderHeaderData() {
    return (
      <ScrollView style={[VendorStyle.TableContainer,{maxHeight:"55vh"}]} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true}>
        {
          this.state.vendorDataListAfterPagination && this.state.vendorDataListAfterPagination.map((item, index) => {
            let sno = (parseInt(this.state.current_page)-1)*parseInt(this.state.records_per_page)
            return (
              <TouchableOpacity style={VendorStyle.dataContainer} onPress={() => {
                this.props.NavigateScreen("vendorMonthwise", item.vendor_id)
              }}>
                <View style={VendorStyle.commonFlex}><Text style={[VendorStyle.TableDataText,{ marginLeft: '1.3vw' }]}>{sno + (index+1)}</Text></View>
                <View style={VendorStyle.commonFlex}><Text style={VendorStyle.TableDataText}>{item.vendor_name}</Text></View>
                <View style={VendorStyle.commonFlex}><Text style={VendorStyle.TableDataText}>{item.vendor_gstin_number}</Text></View>
                <View style={VendorStyle.commonFlex}><Text style={VendorStyle.TableDataText}>{item.total_purchase_order}</Text></View>
                <View style={VendorStyle.commonFlex}><Text style={VendorStyle.TableDataText}>{item.total_bill_amount}</Text></View>
                <View style={VendorStyle.commonFlex}><Text style={VendorStyle.TableDataText}>{item.total_paid_amount}</Text></View>
                <View style={VendorStyle.commonFlex}><Text style={[VendorStyle.TableDataText,{ color: color.red }]}>{item.total_balance_amount}</Text></View>
              </TouchableOpacity>
            )
          })}
      </ScrollView>
    )
  }
  _itemSelected = (item, key, objFormat = {}) => {

    let states = this.state;
    if (item) {
      if (key === "vendor_name" && objFormat) {

        let name = item[objFormat["keyName"]];
        let vendor_id = item[objFormat["keyId"]];

        states['isSelected'] = true;
        states['vendor_name'] = name;
        states['vendor_id'] = vendor_id;
      }
      this.setState({
        states
      }, () => {
        this._getVendorPayment(this.state.vendor_id)
      })
    }
  }
  _updateState = (stateKey, value, type = '') => {

    let states = this.state;
    let { vendor_list } = this.state;

    states[stateKey] = value;
    states['isSelected'] = false;
    states['vendor_id'] = null;
    states['vendor_list_suggest'] = this._sortListData(vendor_list, value)

    this.setState({
      states
    }, () => {
      this._getVendorPayment(this.state.vendor_id)
    })
  }

  printVendorDetails = (type) => {
    var vendorId = this.state.vendor_id == null ? "" : this.state.vendor_id
    var url = Constants.GET_VENDOR_PAYMENT + "?vendor_id=" + vendorId + "&export_type=" + type;

    if (url) {
      if (type == 'pdf') {
        this.service.getInstance().documentUploadGet(
          url,
          this.printVendorDetailsSuccess,
          this.printVendorDetailsFailure,
          "pdf"
        );
      } else {
        this.service.getInstance().documentUploadGet(
          url,
          this.exportCSVSuccessCallBack,
          this.exportCSVSuccessCallBack,
          "excel"
        );
      }
    }
  }

  printVendorDetailsSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  };

  printVendorDetailsFailure = error => {
    console.log(error);
  };
  
  exportCSVSuccessCallBack = response => {
    if (response) {

        var pom = document.createElement('a');
        var csvContent = response.data; //here we load our csv data 

        let filename = response.headers["content-disposition"].split("filename=")[1]

        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
        var url = URL.createObjectURL(blob);
        pom.href = url;
        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
        pom.click();
    }
  }

exportCSVErrorCallBack = error => { } 
  renderTopHeader() {
    var { vendor_list_suggest, isSelected } = this.state
    return (
      <View style={VendorStyle.topHeaderStyle}>
        <View style={VendorStyle.searchContainer}>

          <CommonAutoSuggestion width={"20%"} placeholder={"Vendor Search"} type={""} align={""}
            stateKey={"vendor_name"} value={this.state.vendor_name} onChangeText={this._updateState.bind(this)}
            list={vendor_list_suggest} isSelected={isSelected} itemSelected={this._itemSelected.bind(this)} objFormat={{ keyId: "vendor_id", keyName: "name" }} />
          <TouchableOpacity
            onPress={() => {
              this.printVendorDetails("pdf")
            }}
            style={[ VendorStyle.printContainer, {marginRight: 10} ]}>
            <Image style={VendorStyle.Image}
              source={require('../../../assets/images/HomeScreenImages/Print.png')} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.printVendorDetails("excel")
            }}
            style={VendorStyle.printContainer}>
            <Image style={VendorStyle.Image}
              source={require('../../../assets/images/HomeScreenImages/Export.png')} />
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  renderComponent(selectedComponent) {
    var componentLookup = {
      vendorMonthwise: (<VendorMonthwise />),

    }
    return (<View>
      {componentLookup[selectedComponent] ? componentLookup[selectedComponent] : null}
    </View>);
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
     current_page: current_page,
     records_per_page: records_per_page
    })
   }
  renderPaginationView() {
    return (
      <View style={{ marginHorizontal: 20 }}>
        {
          this.state.vendorDataList && this.state.vendorDataList.length > 0 ?
            <Pagination
              paginationChangedValues={this.paginationChangedValues.bind(this)}
              totalClientItems={this.state.vendorDataList}
              paginationCurrentPage = { this.paginationCurrentPage.bind(this) }
            /> : null
        }
      </View>
    )
  }

  paginationChangedValues(data) {
    this.setState({
      vendorDataListAfterPagination: data
    })
  }


  render() {
    return (
      <View>
        <View>{this.renderTopHeader()}</View>
        <View style={{ zIndex: -10 }}>{this.renderHeader()}</View>
        <View style={{ zIndex: -10 }}>{this.renderHeaderData()}</View>
        <View>{this.renderComponent(this.state.selectedScreen)}</View>
        {this.renderPaginationView()}
      </View>
    );
  }
} export default Vendor;
const VendorStyle = StyleSheet.create({

  dataContainer: {
    flexDirection: 'row',
    borderBottomColor: "#888888",
    borderBottomWidth: 1,
    paddingVertical: '1.30vw',
    justifyContent: 'center'
  },
  commonFlex: {
    flex: 0.14
  },
  topHeaderStyle: {
    padding: '1.30vw',
    flex: 1,
    justifyContent: "flex-end",
  },
  Image: {
    height: '1.95vw',
    width: '1.95vw'
  },
  vendorText: {
    fontWeight: "500", 
    fontSize: '1.30vw'
  },
  searchContainer: {
    flexDirection: "row", 
    flex: 1, 
    justifyContent: "flex-end"
  },
  printContainer : { 
    width: '2.60vw', 
    height: '2.27vw', 
    borderRadius: '0.26vw', 
    backgroundColor: color.themeBackgroud, 
    paddingHorizontal: '0.32vw', 
    paddingVertical: '0.16vw',},
  TableHeader:{
    padding:'1.30vw'
  },
  TableContainer:{
    paddingHorizontal: '1.30vw'
  },
  TableDataText:{
    fontSize:'1vw'
  }

})