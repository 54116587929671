import React, { Component } from 'react';
import { Text, View, TextInput, ScrollView, Dimensions, TouchableOpacity, Picker, StyleSheet } from 'react-native';
import { MenuBreadCrumpList, CommonReportSectionHeader, ActionButton, SuccessAlert, ErrorAlert, CommonAutoSuggestion, } from '../../common/BaseFunctionComponent';
import { color } from '../../styles/Color';
import Icon from "react-native-vector-icons/FontAwesome";
import Constants from "../../networks/Constants";
import Service from '../../networks/service';
import BaseComponent from '../../common/BaseComponent';
import Style from '../../styles/Style';
const screenHeight = Dimensions.get("window").height;

const tempRows = {
    "drug_name": "",
    "generic_name": "",
    "dosage_strength": "",
    "dosage_type": "",
    "schedule": "",
    "grouping": "",
    "hsn_code": "",
    "physical_box_no": "",
    "vendor_id": null,
    "vendorName": "",
    "manufacturer_name": "",
    "gst_percentage": 0,
    "reorder_threshold": 0,
    "expiry_alert_period": 3,
    "recommended_by": "",
    "bar_code": ""
}

const Expiry_period = [
    {label: "1 month", value: '1'},
    {label: "2 months", value: '2'},
    {label: "3 months", value: '3'},
    {label: "6 months", value: '6'},
]
export default class NewDrugEntry extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            menu: this.props.menu,
            selectedScreen: this.props.selectedScreen,
            drugRows: tempRows,
            isDosageSelected: false,
            flagShowDosageList: false,
            dosageTypeList: [],
            dosageTypeListOriginal: [],
            vendor_list: [],
            vendor_list_suggest: [],
            isVendorSuggestion: false,
            loginUserData: this.props.loginUserData,
            isClearClick: false
        }
        this.service = new Service();

    }
    componentDidMount() {
        this._getDosageTypes()
        this._getVendorList()
    }
    _getDosageTypes = () => {
        let url = Constants.LIST_DOSAGE;
        this.service.getApiCall(
            url,
            this._getDosageTypeSuccess,
            this._getDrugFailure
        )
    }

    _getDosageTypeSuccess = response => {
        if (response.status === "success") {
            this.setState({
                dosageTypeList: response.data || []
            }, () => {
                var list = this.removeDuplicate(this.state.dosageTypeList)
                this.setState({
                    dosageTypeListOriginal: list
                })
            });
        }
    };

    _getVendorList() {
        let url = Constants.LIST_VENDOR + "?from=purchase_order";
        this.service.getApiCall(
            url,
            this._getVendorListSuccess,
            this._getDosageTypeFailure
        )
    }

    _getVendorListSuccess = response => {

        if (response.status === "success") {

            this.setState({
                vendor_list: response.data,
                vendor_list_suggest: response.data
            });
        }
    };

    _getDosageTypeFailure = response => {
    }
    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }
    // renderTextInput = (stateKey, value, flex, index) => {
    //     var { drugRows } = this.state
        
    //     return (
    //         <View style={{ flex: flex, height: "20%" }}>
    //             {stateKey === "dosage_type" ?
    //                 <View>
    //                     <Picker
    //                         selectedValue={value}
    //                         style={[Style.pickersView, {
    //                             width: '12.64vw', fontSize: '0.8vw', borderColor: '#CDD1D5', marginTop: 10
    //                         }]}
    //                         itemStyle={{}}
    //                         onValueChange={(itemvalue) => {
    //                             var states = this.state;
    //                             drugRows[index][stateKey] = itemvalue
    //                             this.setState({ states });
    //                         }}
    //                     >
    //                         <Picker.Item label='' value='' />
                            
    //                         {this.state.dosageTypeListOriginal.length > 0 ?
    //                             this.state.dosageTypeListOriginal.map((list, index) => (
    //                                 <Picker.Item key={index} label={list} value={list} />
    //                             ))
    //                             : null
    //                         }

    //                     </Picker>
    //                 </View>
    //                 :
    //                 <TextInput
    //                     style={{
    //                         padding: 6,
    //                         margin: 10,
    //                         backgroundColor: color.white,
    //                         borderColor: "#CDD1D5",
    //                         borderRadius: 4,
    //                         borderWidth: 1,
    //                     }}
    //                     value={value}
    //                     onChangeText={(text) => {
    //                         drugRows[index][stateKey] = text
    //                         this.setState({
    //                             drugRows
    //                         })
    //                     }}
    //                 />
    //             }

    //         </View>
    //     )
    // }
    // _addNextRows = () => {

    //     let { drugRows } = this.state

    //     let current_row = drugRows.length;
    //     let bill_line_items = [];
    //     let drugRows_no_manitory_fields = [];

    //     let add_flag = false;
       
    //     for (let i = 0; i < current_row; i++) {

    //         if (drugRows[i].drug_name && drugRows[i].generic_name && drugRows[i].dosage_strength && drugRows[i].dosage_type) {
    //                 bill_line_items.push(drugRows[i]);
    //         }
    //         else if (!drugRows[i].drug_name || !drugRows[i].generic_name || !drugRows[i].dosage_strength || !drugRows[i].dosage_type) {
    //             drugRows_no_manitory_fields.push(drugRows[i])
    //         }
    //     }

    //     if ((current_row - bill_line_items.length) === 0) {
    //         add_flag = true;
    //     }

    //     if (add_flag) {

    //         let new_row = drugRows.length + 5

    //         for (let i = current_row; i < new_row; i++) {
    //             drugRows.push(JSON.parse(JSON.stringify(tempRows)));
    //         }

    //         this.setState({
    //             drugRows,
    //             drugLineItems: bill_line_items
    //         })
    //     }
    //     else if (drugRows_no_manitory_fields.length > 0) {
    //         this.setState({
    //             responseMessage: "Please enter manitory fields",
    //             showErrorResponse: true,
    //             is_disable_action: false
    //         },() => {
    //             this.responseSuccessErrorMessage( this.state.responseMessage, false)
    //             setTimeout(() => {
    //               this.responseSuccessErrorMessage("", false)
    //             }, 3000);
    //         })
    //     }
    // }
    
    _handleItemRemove = (i = null) => {
        let { drugRows } = this.state;

        let drugRow = drugRows.slice(0, i).concat(drugRows.slice(i + 1, drugRows.length))
        this.setState({
            drugRows: drugRow
        })
    }
    _saveNewDrugEntries = () => {
        var { drugRows } = this.state

        var url = Constants.DRUG_DETAILS_POST

        let bill_line_items = [];
        var isManitory = "";

        if (drugRows.drug_name) {
            bill_line_items.push(drugRows);
        } 
        var status = this.state.loginUserData.data.is_admin_access  ? "Accepted" : "Pending";
        bill_line_items.forEach(data => { data.status =   status });

        // for status

       

        // repeated drug restriction

        var isDuplicate = false
        var drug_name = bill_line_items && bill_line_items.length > 0 && bill_line_items.map(item => { return item.drug_name })
            isDuplicate = drug_name && drug_name.length > 0 && drug_name.some((item, index)=> (
            drug_name.indexOf(item) != index
        ))
        for ( let i=0; i < bill_line_items.length; i++ ) {
            if(!bill_line_items[i].drug_name) {
                isManitory = "Please enter the drug name";
            } else if(!bill_line_items[i].generic_name) {
                isManitory = "Please enter the generic name"
            } else if(!bill_line_items[i].dosage_type) {
                isManitory = "Please enter the dosage type"
            } else if(!bill_line_items[i].dosage_strength) {
                isManitory = "Please enter the dosage strength"
            }
        }
       
        if (isDuplicate) {
            this.responseSuccessErrorMessage( "Same drug repeated multiple times", false)
            setTimeout(() => {
              this.responseSuccessErrorMessage("", false)
            }, 3000);
        } else if(isManitory) {
            this.responseSuccessErrorMessage( isManitory, false)
            setTimeout(() => {
                this.responseSuccessErrorMessage("", false)
              }, 3000);
        } else if(bill_line_items && bill_line_items.length == 0 ) {
            this.responseSuccessErrorMessage( "Please enter the details to proceed", false)
            setTimeout(() => {
              this.responseSuccessErrorMessage("", false)
            }, 3000);
        }
        else {
         for ( let i=0; i < bill_line_items.length; i++ ) {

           var drug_name = (bill_line_items[i].drug_name).trim();
           bill_line_items[i]["drug_name"] = drug_name;

           var generic_name = (bill_line_items[i].generic_name).trim()
           bill_line_items[i]["generic_name"] = generic_name;

           var dosage_strength = (bill_line_items[i].dosage_strength).trim()
           bill_line_items[i]["dosage_strength"] = dosage_strength;

           var dosage_type = (bill_line_items[i].dosage_type).trim()
           bill_line_items[i]["dosage_type"] = dosage_type;

           var grouping = (bill_line_items[i].grouping).trim()
           bill_line_items[i]["grouping"] = grouping;

           var schedule = (bill_line_items[i].schedule).trim()
           bill_line_items[i]["schedule"] = schedule;

         }
         var data = {
          "drugs": bill_line_items
         }
        this.service.postApiCall(
             url,
             data,
             this._saveNewDrugSuccess,
             this._saveNewDrugFailure
         )
        }
    }
     _saveNewDrugSuccess = (response) => {
         if (response.status == 'Success') {
            this._clearDrugEntries()
             this.responseSuccessErrorMessage(response.message, true)

             setTimeout(() => {
               this.responseSuccessErrorMessage("", true)
             }, 3000)
         } else {
             this.responseSuccessErrorMessage(response.message, false)

             setTimeout(() => {
               this.responseSuccessErrorMessage("", false)
             }, 3000)
         }
     }
     _saveNewDrugFailure = (error) => {
         this.responseSuccessErrorMessage(error.message, false)
         setTimeout(() => {
           this.responseSuccessErrorMessage("", false)
         }, 3000)
     }
    _clearDrugEntries = () => {
        var {drugRows} = this.state
        var states = this.state
        drugRows['drug_name'] = "",
        drugRows['generic_name'] = "",
        drugRows['dosage_strength'] = "",
        drugRows['dosage_type'] = "",
        drugRows['schedule'] = "",
        drugRows['grouping'] = "",
        drugRows['hsn_code'] = "",
        drugRows['physical_box_no'] = "",
        drugRows['vendor_id'] = null,
        drugRows['vendorName'] = "",
        drugRows['manufacturer_name'] = "",
        drugRows['gst_percentage'] = 0,
        drugRows['reorder_threshold'] = 0,
        drugRows['expiry_alert_period'] = 3,
        drugRows['recommended_by'] = "",
        drugRows['bar_code'] = "",

        this.setState({
            drugRows, 
            isClearClick: false,
            isVendorSuggestion: false,
        })
    }
    // renderTableView = () => {
    //     let { drugRows } = this.state
    //     if (drugRows.length < 10) {
    //         for (let i = drugRows.length; i < 10; i++) {
    //             drugRows.push(JSON.parse(JSON.stringify(tempRows)))
    //         }
    //     }
    //     return (
    //         <View>
    //             <CommonReportSectionHeader
    //                 heading1={'SNO'}
    //                 heading2={"Drug Name * "}
    //                 heading3={"Generic Name * "}
    //                 heading4={"Dosage Strength * "}
    //                 heading5={"Dosage Type * "}
    //                 heading6={"Schedule"}
    //                 heading7={"Grouping"}
    //                 columnSize={[0.05, 0.17, 0.17, 0.15, 0.15, 0.15, 0.15]}  //total value is == of 1
    //                 noOfColumn={7}
    //             />
    //             <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ height: '515px' }}>
    //                 <View>
    //                     {drugRows && drugRows.map((item, index) => {
    //                         var sno = index + 1
    //                         return (
    //                             <View onLayout={(event) => {
    //                                 const layout = event.nativeEvent.layout
    //                             }}>
    //                                 <View style={{ flexDirection: "row", flex: 1 }} >
    //                                     <View  style={{ flex: 0.05, marginTop: 10, alignItems: "center" }}><Text>{sno}</Text></View>
    //                                     {this.renderTextInput('drug_name', item.drug_name, 0.17, index)}
    //                                     {this.renderTextInput('generic_name', item.generic_name, 0.17, index)}
    //                                     {this.renderTextInput('dosage_strength', item.dosage_strength, 0.15, index)}
    //                                     {this.renderTextInput('dosage_type', item.dosage_type, 0.15, index)}
    //                                     {this.renderTextInput('schedule', item.schedule, 0.15, index)}
    //                                     {this.renderTextInput('grouping', item.grouping, 0.15, index)}
    //                                     <TouchableOpacity onPress={() => { drugRows[index]['drug_name'] ? this._handleItemRemove(index) : null }} style={{ flex: 0.03, justifyContent: "center", alignItems: "flex-end" }}>
    //                                         <Icon name="close" size={18} color={"#000000"} />
    //                                     </TouchableOpacity>
    //                                 </View>

    //                             </View>
    //                         )
    //                     })

    //                     }
    //                 </View>
    //             </ScrollView>
    //             <View style={{ flexDirection: "row", justifyContent: "flex-end", marginTop: 15 }}>
    //                 <ActionButton label={"Add Item"} keyIndex={"addNextRos"}
    //                     onPressAction={this._addNextRows.bind(this, false)}
    //                 />
    //             </View>
    //             <View style={{ flexDirection: "row", justifyContent: "center", alignSelf: "center" }}>
    //                 <ActionButton label={"Save"} keyIndex={"savedDrugEntry"}
    //                     imgPath={require("../../../assets/Icon/SavedBills.png")}
    //                     // isDisable={is_disable_action}
    //                     onPressAction={this._saveNewDrugEntries.bind(this)}
    //                 />
    //                 <ActionButton label={"Clear"} keyIndex={"clearDrugEntry"}
    //                     // imgPath={require("../../../assets/images/HomeScreenImages/Cancel.svg")}
    //                     // isDisable={is_disable_action}
    //                     onPressAction={() => { 
    //                         this.setState({
    //                             isClearClick: true
    //                         })
    //                     }}
    //                 />
    //             </View>
    //         </View>
    //     )
    // }

    vendorSuggestionList = () => {
        let {vendor_list, drugRows} = this.state
        let tempDescList = this.state.vendor_list_suggest
        if (tempDescList.length > 0) {
            var searchListTemp = [];
            for (let i = 0; i < tempDescList.length; i++) {
      
              if (tempDescList[i].name.toLowerCase().indexOf(drugRows['vendorName'].toLowerCase()) > -1) {
                searchListTemp.push(tempDescList[i]);
              }
            }
            vendor_list = searchListTemp;
          } else {
            vendor_list = this.state.vendor_list_suggest;
          }
          this.setState({
            vendor_list
          })
    }

    renderTexBoxNew = (label, key) => {
        var {drugRows} = this.state
        var states = this.state
        return(
            <View style={{width: '18%', marginTop: '6vh', zIndex: key === "grouping" ? -1 : 1}}>
                <Text style={{ zIndex: 2, fontSize: '.78vw', position: "absolute", marginLeft: '0.65vw', backgroundColor: "white", paddingLeft: '0.33vw', paddingRight: '0.33vw', color: "#888888" }}>{label}</Text>
                {(key === "dosage_type" || key === "expiry_alert_period")?
                    <View>
                        <Picker
                            selectedValue={drugRows[key]}
                            style={{
                                borderColor: "#CDD1D5",
                                borderRadius: '.26vw',
                                borderWidth: 1,
                                width: "100%",
                                height: '5vh',
                                fontSize: '1vw',
                                paddingLeft: '0.65vw',
                                marginTop: '.52vw'
                            }} 
                            itemStyle={{}}
                            onValueChange={(itemvalue) => {
                                var states = this.state;
                                drugRows[key] = itemvalue
                                this.setState({ states });
                            }}
                        >
                            {key === "dosage_type" ?
                            <Picker.Item label='-' value='' />
                            : null }
                            
                            {(this.state.dosageTypeListOriginal.length > 0 && key === "dosage_type") ?
                                this.state.dosageTypeListOriginal.map((list, index) => (
                                    <Picker.Item key={index} label={list } value={list} />
                                ))
                                : null
                            }
                            {key === "expiry_alert_period" ?
                            Expiry_period.map((list, index) => (
                                <Picker.Item key={index} label={list.label} value={list.value} />
                            ))
                            : null}

                        </Picker>
                    </View>
                    :
                <View>
                <TextInput  
                style={{
                    borderColor: "#CDD1D5",
                    borderRadius: '.26vw',
                    borderWidth: 1,
                    width: "100%",
                    height: '5vh',
                    paddingLeft: '0.65vw',
                    marginTop: '.52vw'
                }}
                value={drugRows[key] ? drugRows[key] : ''}
                onChangeText = {(text) => {
                    if (key === "gst_percentage" || key === "reorder_threshold") {
                        if (!(Constants.REG_EX_NUMBERS.test(text) || text == "")) {
                            // no change
                            return
                        }
                    }
                    if (key === "vendorName" && text != "") {

                        states['isVendorSuggestion'] = true;
                        drugRows['vendor_id'] = null;            
                    }else if (key === "vendorName" && text === ""){
                        states['isVendorSuggestion'] = false;
                        drugRows['vendor_id'] = null;
                    }
                   drugRows[key] = text
                   this.setState({
                    drugRows,
                    states
                   }, () => {
                    if(key === "vendorName" && (text).trim() != ""){
                        this.vendorSuggestionList()
                    }
                   }) 
                }}
                />
                {states.isVendorSuggestion && states.vendor_list.length > 0 &&  key === "vendorName"?
                <View style={{ zIndex: 1, position: 'absolute', marginTop: 40, width: "90%"}}>
                    <ScrollView
                    style={{
                        maxHeight: '25vh', marginBottom: 10, flex: 1,
                        backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                    }}
                    >
                    {this.state.vendor_list.map((value, index) =>
                        <View style={{ height: 30 }}>
                        <TouchableOpacity key={index}
                            style={{ marginLeft: 5, marginTop: 5 }}
                            onPress={
                            () => {
                                drugRows['vendorName'] = value.name
                                drugRows['vendor_id'] = value.vendor_id
                                this.setState({
                                    drugRows,
                                    isVendorSuggestion: false
                                })
                            }
                            }
                        >
                            <Text>{value.name}</Text>

                        </TouchableOpacity>
                        </View>
                        )} 
                        </ScrollView>
                    </View>
                    : null
                    }
                </View>
                }
            </View>    
        )
    }
    
    renderNewRegisterView = () => {
        return(
            <View style={{height: '100%'}}>
            <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                {this.renderTexBoxNew("Brand Name *", "drug_name" )}
                {this.renderTexBoxNew("Generic Name *", "generic_name")}
                {this.renderTexBoxNew("Dosage Type *", "dosage_type")}
                {this.renderTexBoxNew("Dosage Strength *", "dosage_strength")}
                {this.renderTexBoxNew("GST %", "gst_percentage")}
                {/* {this.renderTexBoxNew("Vendor Name", "vendorName")} */}
                {this.renderTexBoxNew("Manufacture Name", "manufacturer_name")}
                {this.renderTexBoxNew("HSN Code", "hsn_code")}
                {this.renderTexBoxNew("Phy Box No", "physical_box_no")}
                {this.renderTexBoxNew("Reorder Threshold", "reorder_threshold")}
                {this.renderTexBoxNew("Group", "grouping")}
                {this.renderTexBoxNew("Schedule", "schedule")}
                {this.renderTexBoxNew("Expiry alert period", "expiry_alert_period")}
                {this.renderTexBoxNew("Recommended by", "recommended_by")}
                {this.renderTexBoxNew("UPC/EAN", "bar_code")}

            </View>
            <View style={{ flexDirection: "row", justifyContent: "center", alignSelf: "center", marginTop: '10vh' }}>
            <ActionButton label={"Save"} keyIndex={"savedDrugEntry"}
                imgPath={require("../../../assets/Icon/SavedBills.png")}
                // isDisable={is_disable_action}
                onPressAction={this._saveNewDrugEntries.bind(this)}
            />
            <ActionButton label={"Clear"} keyIndex={"clearDrugEntry"}
                // imgPath={require("../../../assets/images/HomeScreenImages/Cancel.svg")}
                // isDisable={is_disable_action}
                onPressAction={() => { 
                    this.setState({
                        isClearClick: true
                    })
                }}
            />
            </View>
            </View>
        )
    }
    render() {
        let { showResponse, showErrorResponse, responseMessage } = this.state
        return (
            <View style={{ padding: 20, height: '90vh' }}>
                {/* <MenuBreadCrumpList menu={this.state.menu} selectedScreen={this.state.selectedScreen} changeScreen={this.props.changeScreen} /> */}
                <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginBottom: "0.625vw", paddingHorizontal: "1.25vw" }}>
                        <TouchableOpacity
                            style={[styles.ButtonStyle, { marginRight: " 0.625vw", width:"4vw" }]}
                            onPress={() => this.props.changeScreen("stock_list","")}
                        >
                            <Text style={styles.ButtonText}>Back</Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={{ marginRight: " 0.625vw" }}
                            onPress={() => this.props.changeScreen("new_drug_entry","")}
                        >
                            <Text style={[styles.TitleText, { color: this.state.selectedScreen === "new_drug_entry" ? color.themeDark : color.black }]}>Add New Drug</Text>
                        </TouchableOpacity>
                        <Text style={styles.TitleText} >/</Text>
                        <TouchableOpacity
                            style={{ marginRight: " 0.625vw" }}
                            onPress={
                                () => this.props.changeScreen("new_drug_request","")
                            }
                        >
                            <Text style={styles.TitleText}>Request</Text>
                        </TouchableOpacity>
                    </View>
                <View>
                    {/* {this.renderTableView()} */}
                    {this.renderNewRegisterView()}
                </View>
                <View style={{ position: "absolute", width: "100%", flex: 1, top: screenHeight - 65, zIndex: 4 }}>
                    {
                        showResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }
                </View>
                {this.state.isClearClick ?
                <View style={{ justifyContent: "center", alignItems: "center", height: screenHeight, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute' }}>
                <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: 4, padding: 20 }}>
                   <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: "10%" }}>{"Are you sure to clear ?"}</Text>
                     <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "50%", height:"20%", flexWrap: "wrap-reverse" }}>
                         <TouchableOpacity
                             style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                             onPress={() => { 
                               this._clearDrugEntries()
                              }}>
                             <Text style={{ color: 'white', fontSize: 12, }}>{"Yes"}</Text>
                         </TouchableOpacity>
                         <TouchableOpacity
                             style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                             onPress={() => { 
                                 this.setState({ 
                                    isClearClick : false 
                                 },()=>{
                                     // this.props.discardDataReset(true)
                                 }) 
                             }}
                         >
                             <Text style={{ color: 'white', fontSize: 12, }}>{"No"}</Text>
                         </TouchableOpacity>
                     </View>
                 </View>
             </View>
             : null
         }
            </View>
        );
    }
    responseSuccessErrorMessage(message, type) {
        if (message != "") {
          if (type) {
            this.setState({
              showResponse: true,
              responseMessage: message,
              showErrorResponse: false
            })
          } else {
            this.setState({
              showResponse: false,
              showErrorResponse: true,
              responseMessage: message
            })
          }
        } else {
          this.setState({
            showResponse: false,
            showErrorResponse: false,
          })
        }
      }
}

const styles = StyleSheet.create({
    ButtonStyle: {
        width: '5vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.5vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize:"1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    },
    TitleText:{
        padding: "0.4vw", 
        fontSize: "1vw", 
        fontWeight: "500"
    }
})