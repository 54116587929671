import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';
import { ScrollView } from 'react-native-web';
import { Tooltip } from 'antd';
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

export default class SalesDetailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReportList: [],
      startTime: converted_date,
      endTime: converted_date,
      billingListAfterPagination: [],

      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0,
      patientName: this.props.patientName,
      drugName: this.props.drugName,
      ScheduleFilter:this.props.ScheduleFilter,
      isSchedule:this.props.isSchedule
    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this)
  }

  componentDidMount() {
    this._getSalesDetailed()
  }

  componentWillReceiveProps(props) {
    if (props.isclear && (props.startTime!=this.state.startTime || props.endTime!=this.state.endTime)) {
      this.setState({
        startTime: props.startTime,
        endTime: props.endTime,
        current_page: 1,
        records_per_page: 10
      }, () => {
        this._getSalesDetailed()
        props.handlepropsvalueclear(false)

      })
    } else if( (props.patientName != this.state.patientName) || (props.drugName != this.state.drugName) || (props.ScheduleFilter != this.state.ScheduleFilter) || (props.isSchedule != this.state.isSchedule)) {
      this.setState({
        patientName: props.patientName,
        drugName: props.drugName,
        ScheduleFilter:props.ScheduleFilter,
        isSchedule:props.isSchedule,
        current_page: 1,
        records_per_page: 10
      }, () => {
        if( (props.selectedSalesSearchOption == "Patient Name" )) {
          if( (this.state.patientName && this.state.patientName.length >= 3) || ( this.state.patientName.length === 0 )) {
            this._getSalesDetailed()
          }
        } else if(props.selectedSalesSearchOption == "Drug Name"  ) {
          if (( this.state.drugName && this.state.drugName.length >= 3) || ( this.state.drugName.length === 0 )) {
            this._getSalesDetailed()
          }
        } else if(props.selectedSalesSearchOption == "Schedule"){
          if(this.state.ScheduleFilter != "" || this.state.isSchedule){
            this._getSalesDetailed()
          }
        }
      })
    } 
  }

  // Get Api 
  _getSalesDetailed = () => {

    var url = ""
    let { current_page, records_per_page } = this.state;

    let drug_name = this.state.drugName ? this.state.drugName : "";
    
    let patient_name = this.state.patientName ? this.state.patientName : "";

    let Schedule_Filter = this.state.ScheduleFilter ? this.state.ScheduleFilter: "";

    if ((this.state.startTime && this.state.endTime) || this.state.patientName || this.state.drugName) {

      url = Constants.GET_SALES_DETAILED + "?from_date=" + this.state.startTime + "&to_date=" + this.state.endTime +  "&patient_name=" + patient_name + "&drug_name=" + drug_name + "&is_scheduled=" + this.state.isSchedule +"&schedule=" + Schedule_Filter + "&page=" + current_page + "&page_size=" + records_per_page;
    } else {
      url = Constants.GET_SALES_DETAILED+ "?page="+current_page+"&page_size="+records_per_page;

    }
    this.service.getApiCall(
      url,
      this._getSalesDetailedSuccess,
      this._getSalesDetailedFailure
    )
  }

  _getSalesDetailedSuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data.result

      this.setState({
        salesReportList,
        no_of_pages: response.data?response.data.no_of_pages:0,
        total_count: response.data?response.data.total_count:0
      }, () => {
        var data = {
          "total_amount": response.data.total_amount,
          "total_cgst_amount": response.data.total_cgst_amount,
          "total_sgst_amount": response.data.total_sgst_amount,
          "total_discount_amount": response.data.total_discount_amount,
          "total_net_total": response.data.total_net_amount,
        }
        this.props.gettotalscoreValues("Sales Detailed", data)
      })
    }
    else {
      this.props.gettotalscoreValues("Purchase Detailed", "")
    }
  };

  _getSalesDetailedFailure = error => {
    this.props.gettotalscoreValues("Purchase Detailed", "")
    console.log(error);

  };
  // Report List Views Function
  renderContent() {
    return (
      // Report List Views
      <View style={{ marginHorizontal: 20, backgroundColor: "#F9F9F9", marginTop: 15 }}>
        {this.renderContentRow()}



        {/* End Report List View */}

      </View>
    )
  }
  // Common History Section Header function
  renderHeader() {
    return (

      <View style={{ width: "40vw" }}>
        <CommonReportSectionHeader
          heading1={"Bill No"}
          heading2={"Bill Date"}
          heading3={"Patient Name"}
          heading4={"Doctor Name"}
          columnSize={[0.1, 0.25, 0.3, 0.3]}  //total value is == of 1
          noOfColumn={4}

        />
      </View>
    )
  }
  renderScrollHeader() {
    return(
      <View style={{width: "70vw"}}>
        <CommonReportSectionHeader
          heading1={"Brand Name"}
          heading2={"Dosage Strength"}
          heading3={"Dosage Type"}
          heading4={"Schedule"}
          heading5={"HSN Code"}
          heading6={"Batch Number"}
          heading7={"Expiry Date"}
          heading8={"Quantity"}
          heading9={"DISC AMT"}
          heading10={"Net AMT"}         
          columnSize={[0.50, 0.50, 0.50, 0.4, 0.45, 0.50, 0.4, 0.40, 0.40, 0.40]}  //total value is == of 1
          noOfColumn={10}
        />

      </View>
    )
  }
  renderScrollData() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={{ flex: 1}}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              
              let sno = (current_page-1)*records_per_page + index + 1;
              let invoice_date = item.invoice_date?moment(item.invoice_date).format("DD-MM-YYYY"):"" 
              return (
                <View key={sno} style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, padding: '1.30vw',  width: "40vw" }}>
                  <View style={{ flex: 0.1 }}><Text style={styles.TableDataText}>{item.invoice_number}</Text></View>
                  <View style={{ flex: 0.25 }}><Text style={styles.TableDataText}>{invoice_date}</Text></View>
                  <View style={{ flex: 0.3 }}>
                    <Tooltip placement="topLeft" title={item.patient_name}>               
                     <Text style={styles.TableDataText}> {item.patient_name && item.patient_name.length > 20 ? item.patient_name.slice(0,20) + ".." : item.patient_name} </Text> 
                    </Tooltip> 
                  </View>
                  <View style={{ flex: 0.3 }}>
                   <Tooltip placement="topLeft" title={item.doctor_name}>               
                     <Text style={styles.TableDataText}> {item.doctor_name && item.doctor_name.length > 12 ? item.doctor_name.slice(0,12) + ".." : item.doctor_name} </Text> 
                    </Tooltip> 
                  </View>
                     
                </View>
              )
            })
          }
        </View>
      )
    }
  }

  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }

  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page } = this.state

    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        
        this.setState({
            records_per_page: records_per_page_change,
            current_page: next_page
        }, ()=>{
            this._getSalesDetailed()
        })
    }
  }

  render() {

    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0

    return (
      <View>
       <View style ={{ flexDirection: "row", width: "100%" }}> 
       <ScrollView showsHorizontalScrollIndicator = {false}  showsVerticalScrollIndicator={false}> 
        <View style = {{ flexDirection: "column", width: "40%"}}>
         {this.renderHeader()}
         {this.renderScrollData()}
        </View>
       </ScrollView>   
         <ScrollView showsHorizontalScrollIndicator = {this.state.salesReportList.length > 0 ? true : false}  showsVerticalScrollIndicator={ this.state.salesReportList.length > 0 ? true : false}  horizontal={true}>
         <View style={{ flexDirection:"column", width: "70%", marginBottom: 10}}>
          {this.renderScrollHeader()}
          {this.renderPaginateContent()}
        </View>   
         </ScrollView>  
        </View>  
        {
          total_count > 0 ?
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
            type={Constants.PAGINATION_TYPE_SERVER} 
            totalServerRecordsCount={total_count} 
            totalServerPagesCount={no_of_pages} />
            : null
        }
      </View>
    );
  }

  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={{ width: "70vw"}}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              
              let sno = (current_page-1)*records_per_page + index + 1;
              let expiry_date = item.expiry_date?moment(item.expiry_date).format("MM-YYYY"):""

              return (          

                <View key={sno} style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: '1.30vw'}}>
                  <View style={[{ flex: 0.5, flexDirection: "row", marginLeft: 20 }]}>
                    <Tooltip placement="topLeft" title={item.brand_name}>               
                     <Text style={styles.TableDataText}> {item.brand_name && item.brand_name.length > 10 ? item.brand_name.slice(0,10) + ".." : item.brand_name} </Text> 
                    </Tooltip> 
                  </View>
                  <View style={[{ flex: 0.50, marginLeft: 20}]}>
                    <Tooltip placement="topLeft" title={item.dosage_strength}>
                      <Text style={styles.TableDataText}>{item.dosage_strength && item.dosage_strength.length > 10 ? item.dosage_strength.slice(0, 8) + ".." : item.dosage_strength}</Text>
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.50, flexDirection: "row" }]}>
                    <Tooltip placement="topLeft" title={item.dosage_type}>
                      <Text style={styles.TableDataText}>{item.dosage_type && item.dosage_type.length > 10 ? item.dosage_type.slice(0, 8) + ".." : item.dosage_type}</Text>
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.40, flexDirection: "row" }]}><Text style={styles.TableDataText}>{item.drug_schedule}</Text></View>
                  <View style={[{ flex: 0.45, flexDirection: "row" }]}><Text style={styles.TableDataText}>{item.hsn_code}</Text></View>
                  <View style={[{ flex: 0.50 }]}>
                    <Tooltip placement="topLeft" title={item.batch_no}>               
                     <Text style={styles.TableDataText}> {item.batch_no && item.batch_no.length > 12 ? item.batch_no.slice(0,4) + ".." : item.batch_no} </Text> 
                    </Tooltip> 
                  </View>
                  <View style={[{ flex: 0.40, flexDirection: "row" }]}><Text style={styles.TableDataText}>{expiry_date}</Text></View>
                  <View style={[{ flex: 0.40, flexDirection: "row" }]}><Text style={styles.TableDataText}>{item.quantity}</Text></View>
                  <View style={[{ flex: 0.40, flexDirection: "row" }]}><Text style={styles.TableDataText}>{parseFloat(item.discount_amount).toFixed(2)}</Text></View>
                  <View style={[{ flex: 0.40, flexDirection: "row" }]}><Text style={styles.TableDataText}>{Math.round(item.net_total_amount)}</Text></View>
                </View>
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={styles.NoRecordsView}>
          <Text style={styles.NorecordsText}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }

}

const styles = StyleSheet.create({

  TableDataText: {
     fontSize:'1vw'
  },
  NoRecordsView:{
    flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: '9.76vw'
  },
  NorecordsText:{
    color:"#8888888",
    fontSize:'1vw'
  }
});