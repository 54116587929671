import React, { Component } from "react";
import { View } from "react-native";
import AsyncStorage from "../AsyncStorage";

export class Authentication extends Component {

    constructor(props) {

        super(props);

        this._isAuthenticationDone();
    }

    _isAuthenticationDone = async () => {

        const isLoggedIn = await AsyncStorage.getItem("loggedIn");

        this.props.navigation.navigate(
            isLoggedIn === "true" ? "homeNavigator":"loginNavigator"
        );
    }

    render () {

        return <View />
    }
}