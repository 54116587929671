import React, { Component } from "react";
import { View, Text, TextInput, Dimensions, StyleSheet } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { color } from "../../styles/Color";
import Style from "../../styles/Style";
import { MenuBreadCrumpList, SuccessAlert, ErrorAlert } from "../../common/BaseFunctionComponent";

import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import ValCon from '../../networks/ValidationConstants';
const screenHeight = Dimensions.get("window").height;
import { Formik } from 'formik'
import * as yup from 'yup'

let initialValues = {
    "vendor_id": "",
    "name": "",
    "gstin_number": "",
    "dl_no": "",
    "dl_no_2": "",
    "dl_no_3": "",
    "dl_no_4": "",
    "dl_no_5": "",
    "dl_no_6": "",
    "address_line_1": "",
    "address_line_2": "",
    "area_name": "",
    "pincode": "",
    "city": "",
    "state": "",
    "mobile_number": "",
    "phone_number": "",
    "email": ""
}
const vendorValidationSchema = yup.object().shape({
    name: yup.string().required(ValCon.MSG_VENDOR_NAME),
    gstin_number: yup.string().required(ValCon.MSG_GSTIN_NO),
    dl_no: yup.string().required(ValCon.MSG_DL_NO).matches(ValCon.RG_DL_NO, ValCon.MSG_INVALID_DL_NO),
    pincode: yup.string(),
    mobile_number: yup.string().max(10).required()

})

export default class VendorDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: this.props.menu,
            selectedScreen: this.props.selectedScreen,
            vendor_id: this.props.edit_id ? this.props.edit_id : null,
            data: {
                "name": "",
                "gstin_number": "",
                "dl_no": "",
                "dl_no_2": "",
                "dl_no_3": "",
                "dl_no_4": "",
                "dl_no_5": "",
                "dl_no_6": "",
                "address_line_1": "",
                "address_line_2": "",
                "area_name": "",
                "pincode": "",
                "city": "",
                "state": "",
                "mobile_number": "",
                "phone_number": "",
                "email": ""
            },
            name: "",
            statelist: [],
            isSaveDisable: false
        }
        this.service = new Service();
        this.successCallBack = this.successCallBack.bind(this)
    }

    componentDidMount(props) {

        if (this.state.vendor_id) {

            this._doLoadVendor()
        }

        this._doLoadStates()
    }

    _doLoadVendor = () => {

        let { vendor_id } = this.state;
        let url = null;

        if (vendor_id) {
            url = Constants.GET_VENDOR + "?vendor_id=" + vendor_id;

            this.service.getApiCall(
                url,
                this.successOnGet,
                this.errorCallBack
            )
        }
    }

    successOnGet = async (response) => {

        if (response.status === "success") {

            this.setState({
                "data": response.data
            })

        } else if (response.status === "fail") {

            this.setState({
                responseMessage: response.message
            }, () => {

            })
        }
    }

    errorCallBack = (error) => {
    }
    handleChange = (key) =>{
         console.log(key)
    }

    renderTextBox(placeholder, width, center, type, stateKey, value, error = false, disable = false, handleChange, errors) {

        return (
            <View style={{ 
                width: width, 
                justifyContent: 'center', 
                marginRight: '1vw', 
                marginTop: '1vw'
            }}>
                <Text style={[styles.TextBoxPlaceholder, {backgroundColor: type == "IDP" ? color.themeShade : "white", }]}>{placeholder}</Text>
                {
                    disable ?
                    <View>
                        <Text style={[Style.commonTextBoxStyles, { zIndex: -3, marginTop: '.7vw', color: color.labelColor, paddingTop: '.4vw', fontSize: '1vw' }]} >
                            {value}
                        </Text>
                        <View style={{ height: '1vw' }}></View>
                    </View>
                       
                        :
                        <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                            {stateKey == "state" ?
                                <Picker selectedValue={value}
                                    onValueChange={handleChange(stateKey)}
                                    style={[Style.pickerView, {
                                        marginTop: '.7vw',width:'26vw', borderColor: '#CDD1D5'
                                    }, Style.allButtonBorderRadius]}
                                >
                                    <Picker.Item label="-" value="" />
                                    {this.state.statelist && (this.state.statelist).length > 0 && this.state.statelist.map((item, index) => {
                                        return (
                                            <Picker.Item key={index} label={item} value={item} />
                                        )
                                    })}

                                </Picker> :
                                <TextInput
                                    style={[styles.TextBoxTextInput, {borderColor: error ? "red" : "#CDD1D5"}]}
                                    keyboardType = { stateKey == "mobile_number" ? "numeric" : null }
                                    value={value}
                                    onChangeText={handleChange(stateKey)}


                                />}
                            <View style={{ height: '1vw' }}>	
                                {errors[stateKey] &&	
                                    <Text style={styles.TextBoxerror}>{errors[stateKey]}</Text>	
                                }	
                            </View>
                        </View>
                }
            </View>
        )
    }

    _updateState = (stateKey, text, type) => {

        let { data } = this.state;
        data[stateKey] = text;

        this.setState({
            data
        })
    }

    _doSaveVendor = (data) => {



        let { vendor_id } = this.state;
        let url = Constants.SAVE_VENDOR;

        // add save function      

        let flag = true

        if (vendor_id && flag) {

            data['vendor_id'] = parseInt(vendor_id);
            this.setState({isSaveDisable : true})
            this.service.putApiCall(
                url,
                data,
                this.successCallBack,
                this.errorCallBack
            )

        } else if (flag) {
           this.setState({isSaveDisable : true})
            this.service.postApiCall(
                url,
                data,
                this.successCallBack,
                this.errorCallBack
            )
        }
    }

    successCallBack = async (response) => {
        if (response.status == "success") {
           this.setState({ isSaveDisable : true })
           this.props.responseSuccessErrorMessage(response.message, true)
      
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", true)
                this.props.changeScreen("vendors")
            }, 3000)


        } else if (response.status == "fail") {
            this.setState({ isSaveDisable : false })
            this.props.responseSuccessErrorMessage(response.message, false)

            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", false)
            }, 3000)

        }
    }

    errorCallBack = (error) => {
        if(error){
            this.setState({ isSaveDisable : false })
        }
    }

    render() {

        let { data, menu, selectedScreen, showResponse, showErrorResponse, responseMessage } = this.state;
        let isDisable = selectedScreen === "update_vendor" ? true : false

        return (
            <View style={{ maxHeight: screenHeight - 65, margin: 15 }}>

                <Formik
                    enableReinitialize
                    validationSchema={vendorValidationSchema}
                    initialValues={data}
                    onSubmit={values => {
                        this._doSaveVendor(values)
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, values, errors, isValid }) => (
                        <>
                            <MenuBreadCrumpList menu={menu} selectedScreen={selectedScreen}
                                changeScreen={this.props.changeScreen}
                                onSaveVendor={handleSubmit}
                                disabled = {this.state.isSaveDisable}
                                
                            />

                            <View style={styles.TextBoxItemView}>

                                {this.renderTextBox("Vendor Name", "26vw", false, "MI", "name", values.name, "", isDisable, handleChange, errors)}
                                {this.renderTextBox("GSTIN", "26vw", false, "MI", "gstin_number", values.gstin_number, "", isDisable, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no", values.dl_no, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_2", values.dl_no_2, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_3", values.dl_no_3, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_4", values.dl_no_4, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_5", values.dl_no_5, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_6", values.dl_no_6, "", false, handleChange, errors)}
                            </View>
                            <View style={styles.SubTitleTextView}><Text style={styles.SubTitleText}>{"Address"}</Text></View>
                            <View style={styles.TextBoxItemView}>
                                {this.renderTextBox("Address Line 1", "26vw", false, "MI", "address_line_1", values.address_line_1, "", false, handleChange, errors)}
                                {this.renderTextBox("Address Line 2", "26vw", false, "MI", "address_line_2", values.address_line_2, "", false, handleChange, errors)}
                                {this.renderTextBox("Area Name", "26vw", false, "MI", "area_name", values.area_name, "", false, handleChange, errors)}
                                {this.renderTextBox("City", "26vw", false, "MI", "city", values.city, "", false, handleChange, errors)}
                                {this.renderTextBox("State", "26vw", false, "MI", "state", values.state, "", false, handleChange, errors)}
                                {this.renderTextBox("Country", "26vw", false, "MI", "country", values.country, "", false, handleChange, errors)}
                                {this.renderTextBox("Pincode", "26vw", false, "MI", "pincode", values.pincode, "", false, handleChange, errors)}
                            </View>
                            <View style={styles.SubTitleTextView}><Text style={styles.SubTitleText}>{"Contact Details"}</Text></View>
                            <View style={styles.TextBoxItemView}>
                                {this.renderTextBox("Mobile Number", "26vw", false, "MI", "mobile_number", values.mobile_number, "", false, handleChange, errors)}
                                {this.renderTextBox("Landline", "26vw", false, "MI", "phone_number", values.phone_number, "", false, handleChange, errors)}
                                {this.renderTextBox("Email Id", "26vw", false, "MI", "email", values.email, "", false, handleChange, errors)}
                            </View>

                        </>
                    )}
                </Formik>

            </View>
        )
    }
    onPressButton() { }

    _doLoadStates = () => {

        let url = null;

        url = Constants.LIST_STATE_NAMES;

        this.service.getApiCall(
            url,
            this.successOnStatesGet,
            this.errorCallBack
        )
    }

    successOnStatesGet = async (response) => {

        if (response.status === "success") {

            this.setState({
                "statelist": response.data
            })

        } else if (response.status === "fail") {

            this.setState({
                responseMessage: response.message
            })
        }
    }
}
const styles = StyleSheet.create({
        TextBoxPlaceholder: { 
            zIndex: 2, 
            fontSize: '0.78vw', 
            position: "absolute", 
            marginTop: '-2.5vw', 
            marginLeft: '.6vw', 
            paddingHorizontal: '.2vw', 
            color: "#888888" 
        },
        TextBoxTextInput: {
            borderRadius: 4,
            borderWidth: 1,
            width: "26vw",
            height: '2.3vw',
            padding: '1vw',
            marginTop: '.7vw',
            fontSize: '1vw'
        },
        TextBoxerror: { 
            fontSize: '1vw', 
            color: 'red', 
            marginTop: '.3vw' 
        },
        TextBoxItemView: { 
            flexWrap: "wrap", 
            flexDirection: "row", 
            justifyContent: "flex-start", 
            alignContent: "center", 
            marginLeft: '1vw' 
        },
        SubTitleTextView: { marginLeft: '1vw' },
        SubTitleText: { fontSize:'1vw'}

})