// Form validation - Regex
// Validation messages

// ValCon - Validation Constants

export default class ValCon {

        // vendor
        static MSG_VENDOR_NAME = 'Vendor name is required'
        static MSG_FOR_SPECIAL_CHAR = "Kindly remove special characters"
        static ALPHA_NUMERIC = /^[a-z A-Z 0-9]+$/
    
        static MSG_AREA_NAME = "Area name is required"
    
        static MSG_DL_NO= 'DL number is required'
        static RG_DL_NO = /([a-zA-Z0-9]{2,3})+(\/)+([a-zA-Z0-9]{2,4})+(\/)+([a-zA-Z0-9]{2,3})?$/ 
        static MSG_INVALID_DL_NO = 'Invalid format, ex. ABC/1234/12A'
    
        static GSTIN_NO = /^[a-z A-Z 0-9]+$/
        static MSG_GSTIN_NO = 'GSTIN number is required'
    
        static ADDRESS = /^[a-z A-Z 0-9,/]+$/
        static MSG_ADDRESS = "Address Line 1 is required"
    
        static MSG_PINCODE = "Pincode is required"
        static MSG_CITY = "City is required"
        static MSG_MOBILE_NUMBER = "Mobile Number is required"

        // stock
        static MSG_DRUG_NAME_REQ='Drug name is required' 
        static MSG_GENERIC_NAME_REQ='Generic name is required' 
        static MSG_DOSAGE_TYPE_REQ='Dosage type is required' 
        static MSG_DOSAGE_STRENGTH_REQ='Dosage strength is required' 

}