import React, { Component } from "react";
import { View, Text, TextInput, TouchableOpacity, ScrollView, StyleSheet, CheckBox, Dimensions } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { BillTableTitle } from "../../common/BaseFunctionComponent";
import Pagination from "../../common/Pagination";
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import { color } from "../../styles/Color";
import { Tooltip } from "antd";

const screenHeight = Dimensions.get("window").height;

export default class DrugList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedScreen: this.props.selectedScreen ,
            searchValue: "",
            data: {
                page_count: 0,
                no_of_page: 0,
                result: []
            },
            selectedID: [],
            individualId: null,
            current_page: 1,
            records_per_page: 10,
            isActive: this.props.selectedScreen == "drug_list" ? true : false,
            isBtnClicked: false,
            isCommonBtnClicked: false,
            isAllChecked: false,
            loginUser_is_admin: this.props.loginUserData.data.is_admin_access ? this.props.loginUserData.data.is_admin_access : false
        }
        this.service = new Service();
    }
    componentDidMount() {
        this._getDrugList()
    }
    componentWillReceiveProps(props) {

        if (this.state.selectedScreen !== props.selectedScreen) {
            this.setState({
                selectedScreen: props.selectedScreen,
                isActive: props.selectedScreen == "drug_list" ? true : false,
                current_page: 1,
                records_per_page: 10,
                searchValue: ""
            }, () => {
                this._getDrugList()
            })
        } 
    }
    _getDrugList = () => {
        this.setState({
            isBtnClicked: false,
            isCommonBtnClicked: false,
            isAllChecked:false,
            selectedID:[],
            individualId: null
        })
        let { current_page, records_per_page, searchValue, isActive } = this.state;
        let url = `${Constants.ACTIVE_DRUG_LIST}?page_number=${current_page}&page_size=${records_per_page}&active_status=${isActive}&search_key=${searchValue}`;
        this.service.getApiCall(
            url,
            this._getDrugListSuccess,
            this._getDrugListError
        )
    }

    _getDrugListSuccess = (response) => {
        if (response.status === "Success") {
            let {data} = this.state
            data['result'] = response.result ? response.result : [];
            data['page_count'] = response.page_count ? response.page_count : 0;
            data['no_of_page'] = response.no_of_page ? response.no_of_page : 0;
            
            this.setState({
                data
            },() => {
                this.state.data.result.forEach(data => { data.isSelected = false})
            }
            )
        }
    }

    _getDrugListError = (error) => {}

    //Bulk Actions

    _InActivateAllDrugs(){
     let url = Constants.INACTIVATE_ALL_DRUGS
     let data = {
        id: this.state.selectedID,
        active_status: !this.state.isActive
     }
     this.service.postApiCall(
        url,
        data,
        this._InActivateAllDrugssuccess,
        this._InActivateAllDrugserror
    )
    }

    _InActivateAllDrugssuccess = (response) => {
        if (response.status === "success") {
            this.props.responseSuccessErrorMessage(response.message, true)
            this.setState({
                isBtnClicked: false,
                isCommonBtnClicked: false,
                isAllChecked:false,
                selectedID:[],
                individualId: null
            }, () => {
                this._getDrugList()
                setTimeout(() => {
                    this.props.responseSuccessErrorMessage("",true )
                }, 2000)
            })
        }
    }

    _InActivateAllDrugserror = (error)=>{
        if (error.status === "fail") {
            this.props.responseSuccessErrorMessage(error.message, false)
            this.setState({
                isBtnClicked: false,
                isCommonBtnClicked: false,
                isAllChecked: false,
                selectedID:[],
                individualId: null
            }, () => {
                setTimeout(() => {
                    this.props.responseSuccessErrorMessage("", false)
                }, 2000)
            })
        }
    }

    //Individual Actions
    _InActivateDrug(){
    this.setState({
        isBtnClicked:false
    })
    let url = Constants.ACTIVE_DRUG_LIST
     let data = {
        id: this.state.individualId,
        active_status: !this.state.isActive
     }
     this.service.postApiCall(
        url,
        data,
        this._InActivateDrugsuccess,
        this._InActivateDrugerror
    )
    }

    _InActivateDrugsuccess = (response) =>{
        if (response.status === "success") {
            this.props.responseSuccessErrorMessage(response.message, true)
            
            this.setState({
                isBtnClicked: false,
                isCommonBtnClicked: false,
                isAllChecked:false,
                individualId:null,
                selectedID:[],
            }, () => {
                this._getDrugList()
                setTimeout(() => {
                    this.props.responseSuccessErrorMessage("",true )
                }, 2000)
            })
        }
    }

    _InActivateDrugerror = (error) =>{
        if (error.status === "fail") {
            this.props.responseSuccessErrorMessage(error.message, false)
            this.setState({
                isBtnClicked: false,
                isCommonBtnClicked: false,
                isAllChecked:false,
                individualId:null,
                selectedID:[],
            }, () => {
                setTimeout(() => {
                    this.props.responseSuccessErrorMessage("", false)
                }, 2000)
            })
        }
    }
    _paginationChangedValues(next_page, records_per_page_change) {

        let { current_page, records_per_page } = this.state

        if (current_page != next_page || records_per_page != records_per_page_change) {

            this.setState({
                records_per_page: records_per_page_change,
                current_page: next_page
            }, () => {
                this._getDrugList()
            })
        }
    }

    onCheckBoxAll = () => {
        let selectedIDs = [];
        let {data} = this.state
        let temp = data.result && data.result.length > 0 && data.result.map((item) => {
            if (this.state.isAllChecked ) {
                return { ...item, isSelected: true }
            } else {
                return { ...item, isSelected: false }
            }
        })
        data["result"] = temp
        this.setState({
            data
        }, () => {
            if (data.result && data.result.length > 0 && this.state.isAllChecked) {
                data.result && data.result.length > 0 && data.result.forEach((item) => {
                        selectedIDs.push(item.id)
            })
                this.setState({
                    selectedID : selectedIDs
                })
            } else {
                this.setState({
                    selectedID: []
                })
            }
        })
    }

    onMultiCheck = (id, index) => {

        let { data, selectedID } = this.state
        let selectedIDs = [];
        if (id === data.result[index].id && !this.state.isAllChecked) {
            data.result[index]["isSelected"] = !data.result[index]["isSelected"]
            this.setState({
                data
            }, () => {
                if (data.result && data.result.length > 0 && !this.state.isAllChecked) {
                    data.result.forEach((item) => {
                        if (item.isSelected == true) {
                            selectedIDs.push(item.id)
                        }
                    })
                    this.setState({
                        selectedID: selectedIDs
                    }, () => {
                        if (this.state.selectedID.length == this.state.records_per_page) {
                            this.setState({
                                isAllChecked: true
                            })
                        }
                    })
                }
            })
        } else if (id === data.result[index].id && this.state.isAllChecked) {
            data.result[index]["isSelected"] = !data.result[index]["isSelected"]
            selectedID.splice(index, 1)
            this.setState({
                data,
                selectedID
            }, () => {
                if (this.state.selectedID.length != this.state.records_per_page) {
                    this.setState({
                        isAllChecked: false
                    })
                }
            })
        }
        }

    renderTopHeader() {
        let {isActive, loginUser_is_admin} = this.state
        return(
            <View style={{ flexDirection: "row", justifyContent: "flex-end", marginHorizontal: "1.25vw", marginBottom: "0.625vw" }}>
                <View style={{ flexDirection: "row", marginRight: " 0.625vw" }}>
                    <TextInput
                        value={this.state.searchValue}
                        onChangeText={(text) => {
                            this.setState({
                                searchValue: text
                            })
                        }}
                        style={{ borderWidth: 1, height: "2.2vw", width: "10.62vw", paddingHorizontal: "0.19vw", borderColor: color.lightGray, fontSize: "1vw" }}
                        placeholder="Search Drug..."
                    />
                    <TouchableOpacity
                        style={{ height: "2.2vw", width: "2.2vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                        onPress={() => {
                            this._getDrugList()
                        }}>
                        <Icon name='search' size={"0.93vw"} color={color.white}></Icon>
                    </TouchableOpacity>
                </View>
                {loginUser_is_admin ?
                    <TouchableOpacity
                        style={[styles.ButtonStyle, { marginRight: " 0.625vw", width: '12vw', backgroundColor: this.state.selectedID && this.state.selectedID.length > 0 ? color.themeBackgroud : color.lightGray }]}
                        disabled={this.state.selectedID && this.state.selectedID.length > 0 ? false : true}
                        onPress={() => {
                            this.setState({
                                isCommonBtnClicked: true
                            })
                        }}
                    >
                        <Text style={styles.ButtonText}>{isActive ? "InActivate Selected" : "Activate Selected"}</Text>
                    </TouchableOpacity>
                    : null
                }
            </View>
        )
    }

    renderAdminTable() {
        let { data ,isActive, current_page, records_per_page } = this.state
        let prev_record_count = (parseInt(current_page) - 1) * parseInt(records_per_page)
        return(
        <View style={{ maxHeight: screenHeight - 150 , marginHorizontal: "1.25vw"}}>
            <View style={{ flexDirection: "column", justifyContent: "flex-start", alignContent: "center", margin: "0.5vw" }}>
                <View style={{
                    flex: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: "center",
                    backgroundColor: "#E2E2E2", padding: "0.85vw", borderRadius: "0.3vw"
                }}>
                    <View style={{ flex: 0.07, marginRight: "0.65vw" }}>
                        <CheckBox style={{ alignSelf: "center", width: '1.2vw', height: '1.2vw' }} value={this.state.isAllChecked}
                            onChange={() => {
                                this.setState({ isAllChecked: !this.state.isAllChecked }, () => {
                                    this.onCheckBoxAll()
                                })
                            }}
                        /></View>
                    <BillTableTitle label={'S.No'} flexWidth={0.08} />
                    <BillTableTitle label={'Drug.Id'} flexWidth={0.1} />
                    <BillTableTitle label={'Brand Name'} flexWidth={0.14} />
                    <BillTableTitle label={'Generic Name'} flexWidth={0.14} />
                    <BillTableTitle label={'Dosage Type'} flexWidth={0.13} />
                    <BillTableTitle label={'Dosage Strength'} flexWidth={0.13} />
                    <BillTableTitle label={'Avail Qty'} flexWidth={0.1} />
                    <BillTableTitle label={'Action'} flexWidth={0.1} />
                </View>
                <ScrollView showsVerticalScrollIndicator={(data.result && data.result.length > 0) ? true : false} style={{ maxHeight: "60vh" }}>
                    {data.result && data.result.length > 0 ? data.result.map((item, index) => {
                        let sno = prev_record_count + (index + 1);
                        return (
                            <View key={index} style={{
                                flex: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: "center",
                                backgroundColor: "#F9F9F9", padding: "0.65vw", borderRadius: "0.3vw", marginTop: "0.4vw",
                                borderBottomWidth: 1, borderColor: "#E2E2E2"
                            }}>
                                <View style={{ flex: 0.08, marginRight: "0.65vw" }}>
                                    <CheckBox style={{ alignSelf: "center", width: '1.2vw', height: '1.2vw' }} value={item.isSelected}
                                        onChange={() => {
                                            this.onMultiCheck(item.id, index)
                                        }}
                                    /></View>
                                <View style={{ flex: 0.08, marginRight: "0.65vw" }}><Text style={{ fontSize: "1vw" }}>{sno}</Text></View>
                                <View style={{ flex: 0.10, marginRight: "0.65vw" }}><Text style={{ fontSize: "1vw" }}>{item.id}</Text></View>
                                <View style={{ flex: 0.14, marginRight: "0.65vw" }}>
                                    <Tooltip title={item.drug_name && (item.drug_name).length > 15 ? item.drug_name : null}>
                                        <Text style={{ fontSize: "1vw" }}>{item.drug_name && (item.drug_name).length > 15 ? (item.drug_name).slice(0, 13) + ".." : item.drug_name}</Text>
                                    </Tooltip>
                                </View>
                                <View style={{ flex: 0.14, marginRight: "0.65vw" }}>
                                    <Tooltip title={item.generic_name && (item.generic_name).length > 15 ? item.generic_name : null}>
                                        <Text style={{ fontSize: "1vw" }}>{item.generic_name && (item.generic_name).length > 15 ? (item.generic_name).slice(0, 13) + ".." : item.generic_name}</Text>
                                    </Tooltip>
                                </View>
                                <View style={{ flex: 0.13, marginRight: "0.65vw" }}><Text style={{ fontSize: "1vw" }}>{item.dosage_type}</Text></View>
                                <View style={{ flex: 0.13, marginRight: "0.65vw" }}><Text style={{ fontSize: "1vw" }}>{item.drug_strength}</Text></View>
                                <View style={{ flex: 0.10, marginRight: "0.65vw" }}><Text style={{ fontSize: "1vw" }}>{item.stock_in_quantity}</Text></View>
                                <View style={{ flex: 0.10, marginRight: "0.65vw", alignSelf: "center" }}>
                                    <TouchableOpacity style={[styles.ButtonStyle, { width: "8vw", backgroundColor: this.state.selectedID && this.state.selectedID.length > 0 ? color.lightGray : color.themeBackgroud }]}
                                        onPress={() => {
                                            this.setState({
                                                isBtnClicked: true,
                                                individualId: item.id
                                            })
                                        }}
                                        disabled = {this.state.selectedID && this.state.selectedID.length > 0  ? true : false }>
                                        <Text style={styles.ButtonText}>{ isActive ? "InActivate" : "Activate" }</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        )
                    })
                        :
                        <View style={{
                            flexDirection: "row", justifyContent: "center", alignItems: "center",
                            backgroundColor: "#F9F9F9", padding: "0.6vw", marginTop: "0.5vw", height: "65vh"
                        }}>
                            <Text style={{ fontSize: "1vw" }}>No Records Found</Text>

                        </View>
                    }
                </ScrollView>
            </View>
        </View >
        )
    }

    renderUserTable(){
        let { data, current_page, records_per_page } = this.state
        let prev_record_count = (parseInt(current_page) - 1) * parseInt(records_per_page)
        return (
            <View style={{ maxHeight: screenHeight - 150, marginHorizontal: "1.25vw" }}>
                <View style={{ flexDirection: "column", justifyContent: "flex-start", alignContent: "center", margin: "0.5vw" }}>
                    <View style={{
                        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: "center",
                        backgroundColor: "#E2E2E2", padding: "0.85vw", borderRadius: "0.3vw"
                    }}>
                        <BillTableTitle label={'S.No'} flexWidth={0.10} />
                        <BillTableTitle label={'Drug.Id'} flexWidth={0.10} />
                        <BillTableTitle label={'Brand Name'} flexWidth={0.20} />
                        <BillTableTitle label={'Generic Name'} flexWidth={0.20} />
                        <BillTableTitle label={'Dosage Type'} flexWidth={0.15} />
                        <BillTableTitle label={'Dosage Strength'} flexWidth={0.15} />
                        <BillTableTitle label={'Avail Qty'} flexWidth={0.10} />
                    </View>
                    <ScrollView showsVerticalScrollIndicator={(data.result && data.result.length > 0) ? true : false} style={{ maxHeight: "60vh" }}>
                        {data.result && data.result.length > 0 ? data.result.map((item, index) => {
                            let sno = prev_record_count + (index + 1);
                            return (
                                <View key={index} style={{
                                    flex: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: "center",
                                    backgroundColor: "#F9F9F9", padding: "0.65vw", borderRadius: "0.3vw", marginTop: "0.4vw",
                                    borderBottomWidth: 1, borderColor: "#E2E2E2"
                                }}>
                                    <View style={{ flex: 0.10, marginRight: "0.65vw" }}><Text style={{ fontSize: "1vw" }}>{sno}</Text></View>
                                    <View style={{ flex: 0.10, marginRight: "0.65vw" }}><Text style={{ fontSize: "1vw" }}>{item.id}</Text></View>
                                    <View style={{ flex: 0.20, marginRight: "0.65vw" }}>
                                        <Tooltip title={item.drug_name && (item.drug_name).length > 15 ? item.drug_name : null}>
                                            <Text style={{ fontSize: "1vw" }}>{item.drug_name && (item.drug_name).length > 15 ? (item.drug_name).slice(0, 13) + ".." : item.drug_name}</Text>
                                        </Tooltip>
                                    </View>
                                    <View style={{ flex: 0.20, marginRight: "0.65vw" }}>
                                        <Tooltip title={item.generic_name && (item.generic_name).length > 15 ? item.generic_name : null}>
                                            <Text style={{ fontSize: "1vw" }}>{item.generic_name && (item.generic_name).length > 15 ? (item.generic_name).slice(0, 13) + ".." : item.generic_name}</Text>
                                        </Tooltip>
                                    </View>
                                    <View style={{ flex: 0.15, marginRight: "0.65vw" }}><Text style={{ fontSize: "1vw" }}>{item.dosage_type}</Text></View>
                                    <View style={{ flex: 0.15, marginRight: "0.65vw" }}><Text style={{ fontSize: "1vw" }}>{item.drug_strength}</Text></View>
                                    <View style={{ flex: 0.10, marginRight: "0.65vw" }}><Text style={{ fontSize: "1vw" }}>{item.stock_in_quantity}</Text></View>
                                </View>
                            )
                        })
                            :
                            <View style={{
                                flexDirection: "row", justifyContent: "center", alignItems: "center",
                                backgroundColor: "#F9F9F9", padding: "0.6vw", marginTop: "0.5vw", height: "65vh"
                            }}>
                                <Text style={{ fontSize: "1vw" }}>No Records Found</Text>

                            </View>
                        }
                    </ScrollView>
                </View>
            </View >
        )
    }


    render() {
        let { data ,isActive, loginUser_is_admin } = this.state
        return (
            <View style={{ height: screenHeight - 120}}>
                {this.renderTopHeader()}
                {loginUser_is_admin ? this.renderAdminTable() : this.renderUserTable()}
                <View style = {{ marginHorizontal: "1.25vw" }}>
                {data.page_count && parseInt(data.page_count) > 0 ?
                    <Pagination
                        paginationChangedValues={this._paginationChangedValues.bind(this)}
                        totalClientItems={""}
                        type={Constants.PAGINATION_TYPE_SERVER}
                        totalServerRecordsCount={data.page_count}
                        totalServerPagesCount={data.no_of_page}
                    /> : null}
                </View>
                {this.state.isBtnClicked ? 
                <View style={{height: "100%", zIndex:1, position:'absolute', width:'100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center"}}>
                    <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: "0.25vw", padding: "1.25vw"}}>
                    <Text style={{ fontSize: '1.1vw', fontWeight: "bold", marginBottom: "10%" , }}>{isActive ? "Kindly Confirm before Inactivating the drug ?" : "Kindly Confirm before Activating the drug ?"}</Text>
                    <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "center", width: "100%", flexWrap: "wrap-reverse" }}>

                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: "0.25vw", width: "20%",  paddingVertical: "0.5vw" ,marginRight:"0.625vw" }}
                        onPress={() => { this._InActivateDrug()}}
                    >
                        <Text style={{ color: 'white', fontSize: '0.9vw', }}>{"Yes"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: "0.25vw", width: "20%",  paddingVertical: "0.5vw" }}
                        onPress={() => { 
                            this.setState({
                                isBtnClicked: false,
                                individualId: null
                            })
                         }}>
                        <Text style={{ color: 'white', fontSize: '0.9vw', }}>{"No"}</Text>
                    </TouchableOpacity>
                    </View>
                    </View>
                </View>
                : null
                }

                {this.state.isCommonBtnClicked ?
                <View style={{height: "100%", zIndex:1, position:'absolute', width:'100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center"}}>
                <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: "0.25vw", padding: "1.25vw"}}>
                <Text style={{ fontSize: '1.1vw', fontWeight: "bold", marginBottom: "10%" }}>{ isActive ? "Kindly Confirm before Inactivating drugs ?" : "Kindly Confirm before Activating drugs ?" }</Text>
                <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "center", width: "100%", flexWrap: "wrap-reverse" }}>

                <TouchableOpacity
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: "0.25vw", width: "20%",  paddingVertical: "0.5vw" ,marginRight:"0.625vw"}}
                    onPress={() => {this._InActivateAllDrugs()}}
                >
                    <Text style={{ color: 'white', fontSize: '0.9vw', }}>{"Yes"}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: "0.25vw", width: "20%",  paddingVertical: "0.5vw"  }}
                    onPress={() => { 
                        this.setState({
                            isCommonBtnClicked: false,
                            selectedID: []
                        })
                     }}>
                    <Text style={{ color: 'white', fontSize: '0.9vw', }}>{"No"}</Text>
                </TouchableOpacity>
                </View>
                </View>
            </View>
                :null}
            </View >
        )
    }
}

const styles = StyleSheet.create({
    ButtonStyle: {
        width: '6vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        paddingHorizontal: '0.4vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize: "1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    }
})