import React, { Component } from 'react';
import { View, TouchableOpacity, TextInput, Image, Dimensions, Picker, Text, TouchableWithoutFeedback, ScrollView, StyleSheet } from 'react-native'
import { MenuBreadCrumpList, CommonReportSectionHeader, SuccessAlert, ErrorAlert, CommonDateFilter } from '../../common/BaseFunctionComponent'
import Icon from "react-native-vector-icons/FontAwesome";
import Edit from "react-native-vector-icons/Entypo";
import { color } from '../../styles/Color';
import Constants from "../../networks/Constants";
import Service from '../../networks/service';
import Pagination from '../../common/Pagination';
import Style from '../../styles/Style';
import moment from "moment";
import  { Tooltip } from 'antd';

const screenHeight = Dimensions.get("window").height;
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

const Expiry_period = [
    {label: "1 month", value: '1'},
    {label: "2 months", value: '2'},
    {label: "3 months", value: '3'},
    {label: "6 months", value: '6'},
]
export default class NewDrugRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: this.props.menu,
            selectedScreen: this.props.selectedScreen,
            getNewDrugRequest: [],
            
            isEditClick: false,
            loginUserData: this.props.loginUserData,
            // for pagination
            current_page: 1,
            records_per_page: 10,
            paginationNewDrugRequestList: [],
            originalDrugList: [],
            isSelectedIndex: "",
            filterDate: "",
            startDate: converted_date,
            endDate: converted_date,
            showDateFilter: false,
            selectedStatus: "Pending",
            isEditCancel: false,
            editOriginalData: {},
            showResponse: "",
            showErrorResponse: "", 
            responseMessage: "", 

            searchDrug: "",
            isSelected: false,
            selectedItem: [],
            drugSuggession: [],
            editLineItem: {},
            
         }
        this.service = new Service();
    }
    componentDidMount() {
        this.getNewDrugRequest()
        this._getDosageTypes()
    }

    _getDosageTypes = () => {
        let url = Constants.LIST_DOSAGE;
        this.service.getApiCall(
            url,
            this._getDosageTypeSuccess,
            this._getDrugFailure
        )
    }

    _getDosageTypeSuccess = response => {
        if (response.status === "success") {
            this.setState({
                dosageTypeList: response.data || []
            }, () => {
                var list = this.removeDuplicate(this.state.dosageTypeList)
                this.setState({
                    dosageTypeListOriginal: list
                })
            });
        }
    };

    _getDrugFailure = (response) => {

    }

    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    getNewDrugRequest = (drug_name) => {
        var searchText = drug_name ? drug_name : ""
        var selectedStatus = this.state.selectedStatus === "Approved" ? 'Accepted' : this.state.selectedStatus
        let url = Constants.DRUG_DETAILS_POST + "?status=" + selectedStatus + "&from_date=" + this.state.startDate + "&to_date=" + this.state.endDate + "&drug_name=" + searchText;
        this.setState({
            getNewDrugRequest: [],
            paginationNewDrugRequestList: [],
            current_page: 1,
            records_per_page: 10,
        })
        this.service.getApiCall(
            url,
            this.getNewDrugRequestSuccess,
            this.getNewDrugRequestFailure
        )
    }

    getNewDrugRequestSuccess = response => {
        if (response.status === "Success") {
            var data = response.data ? response.data : []
            this.setState({
                getNewDrugRequest: data,
                drugSuggession: data
            })
        }
    };
    getNewDrugRequestFailure = (error) => {

    }
    renderRequestHeader = () => {
        return (
            <View style={{ zIndex: 0 }}>
              { this.state.selectedStatus === "Pending" ?
                <CommonReportSectionHeader
                    heading1={"S.No"}
                    heading2={"Date"}
                    heading3={"Drug Name"}
                    heading4={"Generic Name"}
                    heading5={"Dosage Strength"}
                    heading6={"Dosage Type"}
                    heading7={"Schedule"}
                    heading8={"Grouping"}
                    heading9={"Action"}
                    columnSize={[0.04, 0.11, 0.15, 0.15, 0.1, 0.15, 0.1, 0.1, 0.1]}  //total value is == of 1
                    noOfColumn={9}
                /> : this.state.selectedStatus === "Declined" ?
                 <CommonReportSectionHeader
                    heading1={"S.No"}
                    heading2={"Date"}
                    heading3={"Drug Name"}
                    heading4={"Generic Name"}
                    heading5={"Dosage Strength"}
                    heading6={"Dosage Type"}
                    heading7={"Schedule"}
                    heading8={"Grouping"}
                    heading9={"Comments"}
                    heading10={"Status"}
                    columnSize={[0.05, 0.1, 0.15, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}  //total value is == of 1
                    noOfColumn={10}
                /> : 
                <CommonReportSectionHeader
                    heading1={"S.No"}
                    heading2={"Date"}
                    heading3={"Drug Name"}
                    heading4={"Generic Name"}
                    heading5={"Dosage Strength"}
                    heading6={"Dosage Type"}
                    heading7={"Schedule"}
                    heading8={"Grouping"}
                    heading9={"Status"}
                    columnSize={[0.05, 0.1, 0.15, 0.15, 0.1, 0.15, 0.1, 0.1, 0.1 ]}  //total value is == of 1
                    noOfColumn={9}
                /> }
            </View>
        )
    }
       renderTextInput = (value, flex, stateKey, index, item) => {
            var tempData ={
                "index": index,
                "created_date__date": item.created_date,
                "drug_name" : item.drug_name,
                "generic_name" : item.generic_name,
                "dosage_strength" : item.dosage_strength,
                "dosage_type" : item.dosage_type,
                "schedule" : item.schedule,
                "grouping" : item.grouping,
                "gst_percentage" : item.gst_percentage,
                "vendor_id": item.vendor_id,
                "manufacturer_name" : item.manufacturer_name,
                "hsn_code" : item.hsn_code,
                "physical_box_no" : item.physical_box_no,
                "reorder_threshold" : item.reorder_threshold,
                "expiry_alert_period" : item.expiry_alert_period,
                "recommended_by" : item.recommended_by,
                "bar_code" : item.bar_code,
            }
    
        return (
            <View style={{ flex: flex, alignSelf: 'center'  }}>
                {(stateKey === "dosage_type" || stateKey === "expiry_alert_period")?
                <Picker
                selectedValue={value}
                style={{
                    borderColor: "#CDD1D5",
                    borderRadius: '0.26vw',
                    borderWidth: 1,
                    width: "90%",
                    // padding: 6,
                    margin: '0.33vw',
                    height: '4vh',
                    fontSize: '1vw'
                    // paddingLeft: '0.65vw',
                    // marginTop: '.52vw'
                }} 
                itemStyle={{fontSize: '1vw'}}
                enabled= {this.state.isEditClick && index === this.state.isSelectedIndex ? true : false}
                onValueChange={(itemvalue) => {
                    tempData[stateKey] = itemvalue
                    this.setState({ 
                        editLineItem: tempData
                     },()=>{
                        if(index == tempData.index){
                          this.state.paginationNewDrugRequestList[index]["dosage_type"] = this.state.editLineItem.dosage_type
                          this.state.paginationNewDrugRequestList[index]["expiry_alert_period"] = this.state.editLineItem.expiry_alert_period
                        }

                        this.setState({
                            paginationNewDrugRequestList: this.state.paginationNewDrugRequestList
                        })
                    });
                }}
                >
                    {stateKey === "dosage_type" ?
                    <Picker.Item label='-' value='' />
                    : null }
                    
                    {(this.state.dosageTypeListOriginal && this.state.dosageTypeListOriginal.length > 0 && stateKey === "dosage_type") ?
                        this.state.dosageTypeListOriginal.map((list, index) => (
                            <Picker.Item key={index} label={list } value={list} />
                        ))
                        : null
                    }
                    {stateKey === "expiry_alert_period" ?
                    Expiry_period.map((list, index) => (
                        <Picker.Item key={index} label={list.label} value={list.value} />
                    ))
                    : null}

                </Picker>
                :
                <TextInput
                    style={{
                        padding: '0.39vw',
                        margin: '0.33vw',
                        backgroundColor: color.white,
                        borderColor: "#CDD1D5",
                        borderRadius: '0.26vw',
                        borderWidth: 1,
                        height: '4vh',
                        fontSize: '1vw'
                    }}
                    editable={this.state.isEditClick && index === this.state.isSelectedIndex ? true : false}
                    value={value}
                    onChangeText={(text) => {
                            tempData[stateKey] = text
                            this.setState({
                                editLineItem: tempData
                            },()=>{
                                if(index == tempData.index){
                                  this.state.paginationNewDrugRequestList[index]["drug_name"] = this.state.editLineItem.drug_name
                                  this.state.paginationNewDrugRequestList[index]["generic_name"] = this.state.editLineItem.generic_name
                                  this.state.paginationNewDrugRequestList[index]["dosage_strength"] = this.state.editLineItem.dosage_strength
                                //   this.state.paginationNewDrugRequestList[index]["dosage_type"] = this.state.editLineItem.dosage_type
                                  this.state.paginationNewDrugRequestList[index]["schedule"] = this.state.editLineItem.schedule
                                  this.state.paginationNewDrugRequestList[index]["grouping"] = this.state.editLineItem.grouping
                                  this.state.paginationNewDrugRequestList[index]["gst_percentage"] = this.state.editLineItem.gst_percentage
                                  this.state.paginationNewDrugRequestList[index]["vendor_id"] = this.state.editLineItem.vendor_id
                                  this.state.paginationNewDrugRequestList[index]["manufacturer_name"] = this.state.editLineItem.manufacturer_name
                                  this.state.paginationNewDrugRequestList[index]["hsn_code"] = this.state.editLineItem.hsn_code
                                  this.state.paginationNewDrugRequestList[index]["physical_box_no"] = this.state.editLineItem.physical_box_no
                                  this.state.paginationNewDrugRequestList[index]["reorder_threshold"] = this.state.editLineItem.reorder_threshold
                                //   this.state.paginationNewDrugRequestList[index]["expiry_alert_period"] = this.state.editLineItem.expiry_alert_period
                                  this.state.paginationNewDrugRequestList[index]["recommended_by"] = this.state.editLineItem.recommended_by
                                  this.state.paginationNewDrugRequestList[index]["bar_code"] = this.state.editLineItem.bar_code

                                  this.setState({
                                    paginationNewDrugRequestList: this.state.paginationNewDrugRequestList
                                  })
                                }
                               
                            })
                            
                    }}

                />
                }
            </View>
        )
    } //
    drugRequestEdit = (item) => {
        console.log(item,"check===>")
       var url = Constants.DRUG_DETAILS_POST;
        var data =  {
            "created_date__date": item.created_date,
            "drug_name" : (item.drug_name + "").trim(),
            "generic_name" : (item.generic_name + "").trim(),
            "dosage_strength" : (item.dosage_strength + "").trim(),
            "dosage_type" : (item.dosage_type + "").trim(),
            "schedule" : (item.schedule + "").trim(),
            "grouping" : (item.grouping + "").trim(),
            "hsn_code" : (item.hsn_code + "").trim(),
            "physical_box_no" : (item.physical_box_no + "").trim(),
            "reorder_threshold" : (item.reorder_threshold + "").trim(),
            "vendor_id" : item.vendor_id,
            "manufacturer_name" : (item.manufacturer_name + "").trim(),
            "gst_percentage" : (item.gst_percentage + "").trim(),
            "expiry_alert_period" : (item.expiry_alert_period + "").trim(),
            "recommended_by": (item.recommended_by + "").trim(),
            "bar_code": (item.bar_code + "").trim(),
            "comments": item.comments,
            "id": item.id,
            "pharmacy_id": item.pharmacy_id   
        }
        this.service.putApiCall(
            url,
            data,
            this._saveNewDrugSuccess,
            this._saveNewDrugFailure
        )

    }
    _saveNewDrugSuccess = (response) => {
        if (response.status == 'Success') {
            this.setState({
                drugRows: [],
                isEditClick: false
            })
            this.responseSuccessErrorMessage(response.message, true)

            setTimeout(() => {
                this.responseSuccessErrorMessage("", true)
            }, 3000)
        } else {
            this.responseSuccessErrorMessage(response.message, false)

            setTimeout(() => {
                this.responseSuccessErrorMessage("", false)
            }, 3000)
        }
    }
    _saveNewDrugFailure = (error) => {
        this.responseSuccessErrorMessage(error.message, false)
        setTimeout(() => {
            this.responseSuccessErrorMessage("", false)
        }, 3000)
    }
    pendingDrugDelete = (id) => {
        var url = Constants.DRUG_DETAILS_POST;
        var data = {
            "id": id
        }
        this.service.deleteApiCall(
            url,
            data,
            this._deleteDrugSuccess,
            this._deleteDrugFailure
        )
    }
    _deleteDrugSuccess = (response) => {
        if (response.status == 'Success') {
            this.responseSuccessErrorMessage(response.message, true)
            this.getNewDrugRequest()
            setTimeout(() => {
                this.responseSuccessErrorMessage("", true)
            }, 3000)
        } else {
            this.responseSuccessErrorMessage(response.message, false)
            setTimeout(() => {
                this.responseSuccessErrorMessage("", false)
            }, 3000)
        }
    }
    _deleteDrugFailure = (error) => {
        this.responseSuccessErrorMessage(error.message, false)
        setTimeout(() => {
            this.responseSuccessErrorMessage("", false)
        }, 3000)
    }
    renderDataView = () => {
        var { current_page, records_per_page } = this.state
        return (
          <View style={{ zIndex: 0 }}>
            {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 ?
             <View style={{ zIndex: 0 }}>
                {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                    let sno = (parseInt(current_page)-1)*parseInt(records_per_page) + (index + 1)
                    return (
                        <View>
                            {item && item.status == "Pending" ?
                                <View style={{ flexDirection: "row", flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0 }}>
                                    <Text style={{ flex: 0.04, alignSelf: "center", marginLeft: 14 }}>{sno}</Text>
                                    {this.renderTextInput(item.created_date, 0.11, 'created_date__date', index, item)}
                                    {this.renderTextInput(item.drug_name, 0.15, 'drug_name', index, item)}
                                    {this.renderTextInput(item.generic_name, 0.15, 'generic_name', index, item)}
                                    {this.renderTextInput(item.dosage_strength, 0.1, 'dosage_strength', index, item)}
                                    {this.renderTextInput(item.dosage_type, 0.15, 'dosage_type', index, item)}
                                    {this.renderTextInput(item.schedule, 0.1, 'schedule', index, item)}
                                    {this.renderTextInput(item.grouping, 0.1, 'grouping', index, item)}
                                    {this.state.isEditClick && (index === this.state.isSelectedIndex) ?
                                        <View key={index} style={{ flex: 0.1, flexDirection: "row", margin: 16 }}>
                                            <TouchableOpacity onPress={() => {
                                                this.setState({
                                                    selectedIndex: index
                                                })
                                                this.drugRequestEdit(item)
                                            }}>
                                                <Image style={{ height: 25, width: 25, tintColor: color.themeDark }} source={require('../../../assets/Icon/complete.png')} />
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => {
                                                var { selectedIndex, paginationNewDrugRequestList, originalDrugList } = this.state
                                                for( let i=0; i< originalDrugList.length; i++){
                                                    if( selectedIndex === i) {
                                                        paginationNewDrugRequestList.push(originalDrugList[i])
                                                    }
                                                } 
                                                this.setState({
                                                    paginationNewDrugRequestList,
                                                    isEditClick: false,
                                                    isEditCancel: true
                                                })
                                                this.getNewDrugRequest()
                                            }} style={{ marginLeft: 20 }}>
                                                <Image style={{ height: 25, width: 25, tintColor: color.themeDark }} source={require('../../../assets/Icon/cancel.png')} />
                                            </TouchableOpacity>
                                        </View> :
                                        <View style={{ flex: 0.1, flexDirection: "row", margin: 16 }}>
                                            <TouchableOpacity onPress={() => {
                                                this.setState({
                                                    isEditClick: true,
                                                    isSelectedIndex: index,
                                                    editLineItem: item,
                                                    selectedItem: item
                                                    
                                                })
                                            }}>
                                               <Edit name='edit' size={20} color={color.themeDark}></Edit>
                                            </TouchableOpacity>
                                            <TouchableOpacity onPress={() => {
                                                this.pendingDrugDelete(item.id)
                                            }} style={{ marginLeft: 20 }}>
                                               <Icon name='trash' size={20} color={color.red}></Icon>
                                            </TouchableOpacity>
                                        </View>
                                    }
                                </View> : this.state.selectedStatus === "Declined" ? 
                                <View style={{ flexDirection: "row", flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, paddingVertical: 20 }}>
                                    <Text style={{ flex: 0.05, marginLeft: 20 }}>{sno}</Text>
                                    <Text style={{ flex: 0.1 }}>{item.created_date}</Text>
                                    <Text style={{ flex: 0.15}}>{item.drug_name}</Text>
                                    <Text style={{ flex: 0.1 }}>{item.generic_name}</Text>
                                    <Text style={{ flex: 0.1 }}>{item.dosage_strength}</Text>
                                    <Text style={{ flex: 0.1 }}>{item.dosage_type}</Text>
                                    <Text style={{ flex: 0.1 }}>{item.schedule}</Text>
                                    <Text style={{ flex: 0.1 }}>{item.grouping}</Text>
                                     <Tooltip placement="topLeft" title={item.comments}>               
                                      <Text style={{fontSize: 15, flex: 0.1 }}> {item.comments && item.comments.length > 12 ? item.comments.slice(0,12) + ".." :( item.comments != null && item.comments != "") ? item.comments : "-"} </Text> 
                                     </Tooltip>  
                                    
                                    <Text style={{ flex: 0.1 }}>{item.status}</Text>
                                </View>: 
                                  <View style={{ flexDirection: "row", flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, paddingVertical: 20 }}>
                                  <Text style={{ flex: 0.05, marginLeft: 20}}>{sno}</Text>
                                  <Text style={{ flex: 0.1 }}>{item.created_date}</Text>
                                  <Text style={{ flex: 0.15 }}>{item.drug_name}</Text>
                                  <Text style={{ flex: 0.15 }}>{item.generic_name}</Text>
                                  <Text style={{ flex: 0.1 }}>{item.dosage_strength}</Text>
                                  <Text style={{ flex: 0.15 }}>{item.dosage_type}</Text>
                                  <Text style={{ flex: 0.1 }}>{item.schedule}</Text>
                                  <Text style={{ flex: 0.1 }}>{item.grouping}</Text>
                                  <Text style={{ flex: 0.1 }}>{item.status}</Text>

                              </View>

                            }
                        </View>

                    )
                })}


            </View> : 
           <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: '7.8vw', zIndex: 0 }}>
             <Text style={{ marginTop: '3.91vw' }}>{"No records to be shown"}</Text>
           </View>
        }
        </View>
        )
    }
    paginationChangedValues(data) {
        this.setState({
            paginationNewDrugRequestList: data
        })
    }
    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }
    showSelectedFilteredDate(date) {
        this.setState({
            startDate: date.fromDate,
            endDate: date.toDate,
            showDateFilter: false
        },() => {
            this.getNewDrugRequest()
        })
    }
    _disableDateFilter = (flag) => {

        this.setState({
            showDateFilter: flag
        })
    }
    render() {
        let { showResponse, showErrorResponse, responseMessage, current_page, records_per_page } = this.state
        return (
          <TouchableWithoutFeedback onPress={() => {
                this.setState({
                    showDateFilter: false
                })
          }}>
            <View style={{ padding: '1.3vw', zIndex: 10, height: screenHeight - 60 }}>
                <View style={{ flexDirection: "row", justifyContent: "space-between", zIndex: 10, marginBottom: "0.625vw", paddingHorizontal: "1.25vw"  }}>
                    {/* <MenuBreadCrumpList menu={this.state.menu} selectedScreen={this.state.selectedScreen} changeScreen={this.props.changeScreen} /> */}
                    <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center"}}>
                        <TouchableOpacity
                            style={[styles.ButtonStyle, { marginRight: " 0.625vw", width:"4vw" }]}
                            onPress={() => this.props.changeScreen("stock_list","")}
                        >
                            <Text style={styles.ButtonText}>Back</Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={{ marginRight: " 0.625vw" }}
                            onPress={() => this.props.changeScreen("new_drug_entry","")}
                        >
                            <Text style={[styles.TitleText, { color: this.state.selectedScreen === "new_drug_entry" ? color.themeDark : color.black }]}>Add New Drug</Text>
                        </TouchableOpacity>
                        <Text style={styles.TitleText} >/</Text>
                        <TouchableOpacity
                            style={{ marginRight: " 0.625vw" }}
                            onPress={
                                () => this.props.changeScreen("new_drug_request","")
                            }
                        >
                            <Text style={[styles.TitleText, { color: this.state.selectedScreen === "new_drug_request" ? color.themeDark : color.black }]}>Request</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                    <View style={{flexDirection: "row", zIndex: 0, margin: '0.65vw'}}>
                        <TextInput
                        value = {this.state.searchDrug}
                        onChangeText={(text) => {
                            this.setState({
                                searchDrug: text
                            })
                        }}
                        style={{borderWidth: 1, fontSize: '1vw', height: '2.28vw', width: '11.06vw', paddingHorizontal: '0.2vw', borderColor: color.lightGray }}
                        placeholder="Search drug..."
                        />
                        <TouchableOpacity 
                        style={{height: '2.28vw', width: '2.28vw', backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                        onPress={() => {
                            this.getNewDrugRequest(this.state.searchDrug)
                        }}>
                            <Icon name='search' size={'0.96vw'} color={color.white}></Icon>
                        </TouchableOpacity>
                     </View>
                        <CommonDateFilter
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            filterDate={this.state.filterDate}
                            showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                            defaultSelectedDateFilter={"Today"}
                            // on outside focus, close popup
                            showDateFilter={this.state.showDateFilter}
                            disableDateFilter={this._disableDateFilter}
                        />
                        <Picker
                            selectedValue={this.state.selectedStatus}
                            style={[Style.pickerView, {
                                marginTop: '0.33vw', width: '10vw', fontSize: '1vw', marginLeft: '0.65vw' }]}
                            itemStyle={{}}
                            onValueChange={(itemvalue) => {
                                var states = this.state;
                                states["selectedStatus"] = itemvalue;
                                this.setState({ states },() =>{
                                    this.getNewDrugRequest()
                                });
                            }}
                        >
                            <Picker.Item label='Status Filter' value='' />
                            <Picker.Item label={"Pending"} value={"Pending"} />
                            <Picker.Item label={"Approved"} value={"Approved"} />
                            <Picker.Item label={"Declined"} value={"Declined"} />
                        </Picker>
                    </View>

                </View>
                <View style={{ zIndex: 0 }}>
                <ScrollView style={{maxHeight: '65vh'}}>

                    {/* {this.renderRequestHeader()} */}
                    {/* {this.renderDataView()} */}
                    
                    {this.state.selectedStatus === "Pending" ?
                    <View style={{ flexDirection: "row", width: "100vw"}}>
                    <View style={{width: "5vw"}}>
                    <CommonReportSectionHeader 
                    heading1={"S.No"}
                    columnSize={[1]}
                    noOfColumn={1}  
                    />
                    {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                    let sno = (current_page-1)*records_per_page + index + 1;
                    return(
                        <View style={{flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0,  paddingVertical: '1.3vw',  width: "100%", maxHeight: '3.91vw', minHeight: '3.91vw' }}>
                            <Text style={{ flex: 1, marginHorizontal: "1.30vw", fontSize: '1vw' }}>{sno}</Text>
                        </View>
                    )})}
                    </View>
                    <ScrollView horizontal={true} showsHorizontalScrollIndicator={(this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0) ? true : false}  style={{maxWidth: "75vw", flex: 1}}>
                    <View style={{width: "150vw"}}>
                    <CommonReportSectionHeader
                    heading1={"Date"}
                    heading2={"Drug Name"}
                    heading3={"Generic Name"}
                    heading4={"Dosage Strength"}
                    heading5={"Dosage Type"}
                    heading6={"GST %"}
                    heading7={"Manufacture Name"}
                    heading8={"HSN code"}
                    heading9={"Phy box No"}
                    heading10={"Reorder Threshold"}
                    heading11={"Expiry Alert Period"}
                    heading12={"Recommended by"}
                    heading13={"UPC/EAN"}
                    heading14={"Grouping"}
                    heading15={"Schedule"}
                    columnSize={[0.11, 0.15, 0.15, 0.14, 0.15, 0.1, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15]}  
                    noOfColumn={15}
                    />
                    {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                    return(
                        <View style={{ justifyContent: 'center', maxHeight: '3.91vw', minHeight: '3.91vw' }}>
                        <View style={{flexDirection: 'row', flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0 }}>
                            {this.renderTextInput(item.created_date, 0.11, 'created_date__date', index, item)}
                            {this.renderTextInput(item.drug_name, 0.15, 'drug_name', index, item)}
                            {this.renderTextInput(item.generic_name, 0.15, 'generic_name', index, item)}
                            {this.renderTextInput(item.dosage_strength, 0.14, 'dosage_strength', index, item)}
                            {this.renderTextInput(item.dosage_type, 0.15, 'dosage_type', index, item)}
                            {this.renderTextInput(item.gst_percentage, 0.1, 'gst_percentage', index, item)}
                            {this.renderTextInput(item.manufacturer_name, 0.15, 'manufacturer_name', index, item)}
                            {this.renderTextInput(item.hsn_code, 0.15, 'hsn_code', index, item)}
                            {this.renderTextInput(item.physical_box_no, 0.15, 'physical_box_no', index, item)}
                            {this.renderTextInput(item.reorder_threshold, 0.15, 'reorder_threshold', index, item)}
                            {this.renderTextInput(item.expiry_alert_period, 0.15, 'expiry_alert_period', index, item)}
                            {this.renderTextInput(item.recommended_by, 0.15, 'recommended_by', index, item)}
                            {this.renderTextInput(item.bar_code, 0.15, 'bar_code', index, item)}
                            {this.renderTextInput(item.grouping, 0.15, 'grouping', index, item)}
                            {this.renderTextInput(item.schedule, 0.15, 'schedule', index, item)}
                        </View>
                        </View>
                    )})}
                    </View>
                    </ScrollView>
                    <View style={{width: "10vw"}}>
                    <CommonReportSectionHeader 
                    heading1={"Action"}
                    columnSize={[1]}
                    noOfColumn={1}  
                    />
                    {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => (
                    <View style={{flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0,  paddingVertical: '1.3vw',  width: "100%", maxHeight: '3.91vw', minHeight: '3.91vw' }}>
                    {this.state.isEditClick && (index === this.state.isSelectedIndex) ?
                    <View key={index} style={{ flex: 0.1, flexDirection: "row", marginHorizontal: "1.30vw"}}>
                        <TouchableOpacity onPress={() => {
                            this.setState({
                                selectedIndex: index
                            })
                            this.drugRequestEdit(item)
                        }}>
                            <Image style={{ height: '1.63vw', width: '1.63vw', tintColor: color.themeDark }} source={require('../../../assets/Icon/complete.png')} />
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            var { selectedIndex, paginationNewDrugRequestList, originalDrugList } = this.state
                            for( let i=0; i< originalDrugList.length; i++){
                                if( selectedIndex === i) {
                                    paginationNewDrugRequestList.push(originalDrugList[i])
                                }
                            } 
                            this.setState({
                                paginationNewDrugRequestList,
                                isEditClick: false,
                                isEditCancel: true
                            })
                            this.getNewDrugRequest()
                        }} style={{ marginLeft: '1.3vw' }}>
                            <Image style={{ height: '1.63vw', width: '1.63vw', tintColor: color.themeDark }} source={require('../../../assets/Icon/cancel.png')} />
                        </TouchableOpacity>
                    </View> :
                    <View style={{ flex: 1, flexDirection: "row", marginHorizontal: "1.30vw"}}>
                        <TouchableOpacity onPress={() => {
                            this.setState({
                                isEditClick: true,
                                isSelectedIndex: index,
                                editLineItem: item,
                                selectedItem: item
                                
                            })
                        }}>
                            <Edit name='edit' size={'1.3vw'} color={color.themeDark}></Edit>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            this.pendingDrugDelete(item.id)
                        }} style={{ marginLeft: '1.3vw' }}>
                            <Icon name='trash' size={'1.3vw'} color={color.red}></Icon>
                        </TouchableOpacity>
                    </View>
                    }
                    </View>
                    ))}
                    </View>
                    </View>
                    : this.state.selectedStatus === "Declined" ?
                    <View style={{ flexDirection: "row", width: "100vw"}}>
                    <View style={{width: "5vw"}}>
                    <CommonReportSectionHeader 
                    heading1={"S.No"}
                    columnSize={[1]}
                    noOfColumn={1}  
                    />
                    {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                    let sno = (current_page-1)*records_per_page + index + 1;
                    return(
                        <View style={{flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0,  paddingVertical: '1.3vw',  width: "100%", maxHeight: '3.91vw', minHeight: '3.91vw' }}>
                            <Text style={{ flex: 1, marginHorizontal: "1.30vw", fontSize: '1vw' }}>{sno}</Text>
                        </View>
                    )})}
                    </View>
                    <ScrollView horizontal={true} showsHorizontalScrollIndicator={(this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0) ? true : false}  style={{maxWidth: "85vw", flex: 1}}>
                    <View style={{width: "150vw"}}>
                    <CommonReportSectionHeader
                    heading1={"Date"}
                    heading2={"Drug Name"}
                    heading3={"Generic Name"}
                    heading4={"Dosage Strength"}
                    heading5={"Dosage Type"}
                    heading6={"GST %"}
                    heading7={"Manufacture Name"}
                    heading8={"HSN code"}
                    heading9={"Phy box No"}
                    heading10={"Reorder Threshold"}
                    heading11={"Expiry Alert Period"}
                    heading12={"Recommended by"}
                    heading13={"UPC/EAN"}
                    heading14={"Grouping"}
                    heading15={"Schedule"}
                    heading16={"Comments"}
                    columnSize={[0.11, 0.15, 0.15, 0.14, 0.15, 0.1, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.20]}  
                    noOfColumn={16}
                    />
                    {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                    return(
                        <View style={{ justifyContent: 'center', maxHeight: '3.91vw', minHeight: '3.91vw' }}>
                        <View style={{flexDirection: 'row', flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, alignItems: 'center' }}>
                        <Text style={{ flex: 0.11, marginLeft: '1.3vw', fontSize: '1vw' }}>{item.created_date}</Text>
                        <Tooltip placement="topLeft" title={item.drug_name}>               
                        <Text style={{flex: 0.15, fontSize: '1vw' }}> {item.drug_name && item.drug_name.length > 12 ? item.drug_name.slice(0,12) + ".." :( item.drug_name != null && item.drug_name != "") ? item.drug_name : "-"} </Text> 
                        </Tooltip>
                        <Tooltip placement="topLeft" title={item.generic_name}>               
                        <Text style={{flex: 0.15, fontSize: '1vw' }}> {item.generic_name && item.generic_name.length > 12 ? item.generic_name.slice(0,12) + ".." :( item.generic_name != null && item.generic_name != "") ? item.generic_name : "-"} </Text> 
                        </Tooltip>
                        <Text style={{ flex: 0.14, fontSize: '1vw' }}>{item.dosage_strength}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.dosage_type}</Text>
                        <Text style={{ flex: 0.1, fontSize: '1vw' }}>{item.gst_percentage}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.manufacturer_name}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw'}}>{item.hsn_code}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.physical_box_no}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.reorder_threshold}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.expiry_alert_period ? item.expiry_alert_period + " month(s)" : ""}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.recommended_by}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.bar_code}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.grouping}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw'}}>{item.schedule}</Text>                        
                        <Tooltip placement="topLeft" title={item.comments}>               
                        <Text style={{flex: 0.20, fontSize: '1vw' }}> {item.comments && item.comments.length > 20 ? item.comments.slice(0,20) + ".." :( item.comments != null && item.comments != "") ? item.comments : "-"} </Text> 
                        </Tooltip>  
                        </View>
                        </View>
                    )})}
                    </View>
                    </ScrollView>
                    <View style={{width: "5vw"}}></View>
                    </View>
                    :
                    <View style={{ flexDirection: "row", width: "100vw"}}>
                    <View style={{width: "5vw"}}>
                    <CommonReportSectionHeader 
                    heading1={"S.No"}
                    columnSize={[1]}
                    noOfColumn={1}  
                    />
                    {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                    let sno = (current_page-1)*records_per_page + index + 1;
                    return(
                        <View style={{flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0,  paddingVertical: '1.3vw',  width: "100%", maxHeight: '3.91vw', minHeight: '3.91vw' }}>
                            <Text style={{ flex: 1, marginHorizontal: "1.30vw", fontSize: '1vw' }}>{sno}</Text>
                        </View>
                    )})}
                    </View>
                    <ScrollView horizontal={true} showsHorizontalScrollIndicator={(this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0) ? true : false}  style={{maxWidth: "85vw", flex: 1}}>
                    <View style={{width: "150vw"}}>
                    <CommonReportSectionHeader
                    heading1={"Date"}
                    heading2={"Drug Name"}
                    heading3={"Generic Name"}
                    heading4={"Dosage Strength"}
                    heading5={"Dosage Type"}
                    heading6={"GST %"}
                    heading7={"Manufacture Name"}
                    heading8={"HSN code"}
                    heading9={"Phy box No"}
                    heading10={"Reorder Threshold"}
                    heading11={"Expiry Alert Period"}
                    heading12={"Recommended by"}
                    heading13={"UPC/EAN"}
                    heading14={"Grouping"}
                    heading15={"Schedule"}
                    columnSize={[0.11, 0.15, 0.15, 0.14, 0.15, 0.1, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15]}  
                    noOfColumn={15}
                    />
                    {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                    return(
                        <View style={{ justifyContent: 'center', maxHeight: '3.91vw', minHeight: '3.91vw' }}>
                        <View style={{flexDirection: 'row', flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, alignItems: 'center' }}>
                        <Text style={{ flex: 0.11, marginLeft: '1.3vw', fontSize: '1vw' }}>{item.created_date}</Text>
                        <Tooltip placement="topLeft" title={item.drug_name}>               
                        <Text style={{flex: 0.15, fontSize: '1vw' }}> {item.drug_name && item.drug_name.length > 12 ? item.drug_name.slice(0,12) + ".." :( item.drug_name != null && item.drug_name != "") ? item.drug_name : "-"} </Text> 
                        </Tooltip>
                        <Tooltip placement="topLeft" title={item.generic_name}>               
                        <Text style={{flex: 0.15, fontSize: '1vw' }}> {item.generic_name && item.generic_name.length > 12 ? item.generic_name.slice(0,12) + ".." :( item.generic_name != null && item.generic_name != "") ? item.generic_name : "-"} </Text> 
                        </Tooltip>
                        <Text style={{ flex: 0.14, fontSize: '1vw' }}>{item.dosage_strength}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.dosage_type}</Text>
                        <Text style={{ flex: 0.1, fontSize: '1vw' }}>{item.gst_percentage}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.manufacturer_name}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw'}}>{item.hsn_code}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.physical_box_no}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.reorder_threshold}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.expiry_alert_period ? item.expiry_alert_period + " month(s)" : ""}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.recommended_by}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.bar_code}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw' }}>{item.grouping}</Text>
                        <Text style={{ flex: 0.15, fontSize: '1vw'}}>{item.schedule}</Text>                         
                        </View>
                        </View>
                    )})}
                    </View>
                    </ScrollView>
                    <View style={{width: "5vw"}}></View>
                    </View>
                    }

                </ScrollView>
                    
                    <View style={{ zIndex: 0 }}>
                    {
                        this.state.getNewDrugRequest && this.state.getNewDrugRequest.length > 0 ?
                            <View style={{ marginLeft: '1.3vw', zIndex: 0 }}>
                                <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)}
                                    totalClientItems={this.state.getNewDrugRequest}
                                    paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                                />
                            </View> : null
                    }
                </View>
                </View>
                <View style={{ position: "absolute", width: "100%", flex: 1, top: screenHeight-65 , zIndex: 20 }}>
                    {
                        showResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }
                </View>
            </View>
         </TouchableWithoutFeedback>  
        )
    }
    responseSuccessErrorMessage(message, type) {
        if (message != "") {
            if (type) {
                this.setState({
                    showResponse: true,
                    responseMessage: message,
                    showErrorResponse: false
                })
            } else {
                this.setState({
                    showResponse: false,
                    showErrorResponse: true,
                    responseMessage: message
                })
            }
        } else {
            this.setState({
                showResponse: false,
                showErrorResponse: false,
            })
        }
    }
} 
const styles = StyleSheet.create({
    ButtonStyle: {
        width: '5vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.5vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize: "1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    },
    TitleText: {
        padding: "0.4vw",
        fontSize: "1vw",
        fontWeight: "500"
    }
})