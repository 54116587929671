import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';


export default class SalesMonthly extends Component {
  constructor(props) {
    super(props);

    let month = moment().format("MM")
    let year = moment().format("YYYY")

    this.state = {
      salesReportList: [],
      startTime: month,
      endTime: year,
      billingListAfterPagination: [],

      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0

    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 
  }

  componentDidMount() {
    this._getSalesDetailed()
  }

  componentWillReceiveProps(props) {
    if (props.monthvalue && props.yearValue  && props.isclear) {
      this.setState({
        startTime: props.monthvalue,
        endTime: props.yearValue,
        current_page: 1,
        records_per_page: 10
      }, () => {
        this._getSalesDetailed()
        props.handlepropsvalueclear(false)

      })
    }
  }

  // Get Api 
  _getSalesDetailed = () => {
    
    var url = ""
    let { current_page, records_per_page } = this.state;

    if (this.state.startTime && this.state.endTime) {
      url = Constants.GET_SALES_MONTH + "?month=" + this.state.startTime + "&year=" + this.state.endTime + "&page="+current_page+"&page_size="+records_per_page;
    }
    else {
      url = Constants.GET_SALES_MONTH+ "?page="+current_page+"&page_size="+records_per_page;
    }
    this.service.getApiCall(
      url,
      this._getSalesDetailedSuccess,
      this._getSalesDetailedFailure
    )



  }

  _getSalesDetailedSuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data.bill_details

      this.setState({
        salesReportList, 
        no_of_pages: response.data?response.data.no_of_pages:0,
        total_count: response.data?response.data.total_count:0
      },()=>{
        this.props.gettotalscoreValues("Sales Monthly",response.data.total_amount)
      })
    }else{
      this.props.gettotalscoreValues("Sales Monthly","")

    }
  };

  _getSalesDetailedFailure = error => {
    this.props.gettotalscoreValues("Sales Monthly","")

    console.log(error);

  };
  // Report List Views Function
  renderContent() {
    return (
      // Report List Views
      <View style={{ marginHorizontal: 20, backgroundColor: "#F9F9F9", marginTop: 15 }}>
        {this.renderContentRow()}



        {/* End Report List View */}

      </View>
    )
  }

  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }


  // Common History Section Header function
  renderHeader() {
    return (

      <View style={styles.HeaderView}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Invoice Date"}
          heading3={"Total"}
        
          columnSize={[0.3,0.3,0.3]}  //total value is == of 1
          noOfColumn={3}

        />
      </View>
    )
  }


  // Render Content Row function Key and 
  renderContentRow() {
    if (this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.length < 11) {

      return (
        <View style={{}}>
          {
            this.state.salesReportList && this.state.salesReportList.map((item, index) => {
              return (
                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, }}>
                  <View style={[{ flex: 0.31,marginLeft:20 }]}><Text >{index + 1}</Text></View>
                  <View style={[{ flex: 0.3 }]}><Text>{item.invoice_date ? moment(item.invoice_date).format("DD-MM-YYYY") : null}</Text></View>
                  <View style={[[{ flex: 0.3,marginRight:8 }]]}><Text>{item.amount}</Text></View>
                  
                </View>
              )
            })}



        </View>
      )

    }
    else if (this.state.salesReportList && this.state.salesReportList.length > 0) {

      return (
        <View style={{}}>
          {
            this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
              return (
                  <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 20, justifyContent: 'center', alignItems: 'center' }}>
                <View style={[{ flex: 0.3 }]}><Text >{index + 1}</Text></View>
                <View style={[{ flex: 0.3 }]}><Text>{item.invoice_date ? moment(item.invoice_date).format("DD-MM-YYYY") : null}</Text></View>
                <View style={[[{ flex: 0.3,marginRight:8 }]]}><Text>{item.amount}</Text></View>
                
              </View>
              )
            })}



        </View>
      )

    }
    else {
      return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100,alignItems:'center' }}>
        <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
      </View>
    }
  }

  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page } = this.state

    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        
        this.setState({
            records_per_page: records_per_page_change,
            current_page: next_page
        }, ()=>{
            this._getSalesDetailed()
        })
    }
  }

  render() {

    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0

    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}
        {
          total_count > 0 ?
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
            type={Constants.PAGINATION_TYPE_SERVER} 
            totalServerRecordsCount={total_count} 
            totalServerPagesCount={no_of_pages} />
            : null
        }
      </View>
    );
  }

  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={styles.TableDataContainer}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              let sno = (current_page-1)*records_per_page + index + 1;
              return (
                <View key={sno} style={styles.TableDataWrapper}>
                  <View style={[{ flex: 0.3 }]}><Text style={styles.TableDataText} >{sno}</Text></View>
                  <View style={[{ flex: 0.3 }]}><Text style={styles.TableDataText}>{item.invoice_date ? moment(item.invoice_date,'DD/MM/YYYY').format("DD-MM-YYYY") : null}</Text></View>
                  <View style={[[{ flex: 0.3 ,marginRight:'0.52vw' }]]}><Text style={styles.TableDataText}>{item.amount}</Text></View>
                </View>
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={styles.NoRecordsView}>
          <Text style={styles.NorecordsText}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }
}

const styles = StyleSheet.create({
  HeaderView:{
     marginHorizontal: '1.30vw', marginTop: '0.65vw' 
  },
  TableDataText: {
     fontSize:'1vw', marginLeft:'2vw'
  },
  TableDataContainer:{
     marginHorizontal: '1.30vw', backgroundColor: "#F9F9F9", marginTop: '0.97vw' 
  },
  TableDataWrapper:{
    flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: '1.30vw'
  },
  NoRecordsView:{
    flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: '9.76vw'
  },
  NorecordsText:{
    color:"#8888888",fontSize:'1vw'
  },
});
