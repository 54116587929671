import { Tooltip } from "antd";
import React, { Component } from "react";
import { ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View, Dimensions } from "react-native";
import { CommonButton, CommonReportSectionHeader, MenuBreadCrumpList } from "../../common/BaseFunctionComponent";
import Pagination from "../../common/Pagination";
import Constants from "../../networks/Constants";
import Service from "../../networks/service";
import { color } from "../../styles/Color";

const screenHeight = Dimensions.get("window").height;

export default class OrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: this.props.menu,
            selectedScreen: this.props.selectedScreen,
            vendor_list: [],
            vendor_list_suggest: [],
            orderRow: [],
            paginationNewDrugRequestList: [],
            current_page: 1,
            records_per_page: 10,
            selectedIndex: null,
            selectedScreen: this.props.selectedScreen ? this.props.selectedScreen : "orderList"
        }
        this.service = new Service();
    }

    componentDidMount() {
        this._getOrderList();
        this._getVendorList()
    }

    _getOrderList = () => {
        let url = Constants.MOVE_ORDER_LIST
        this.setState({
            orderRow: [],
            paginationNewDrugRequestList: [],
            current_page: 1,
            records_per_page: 10,
        })
        this.service.getApiCall(
            url,
            this.successCallBack,
            this.errorCallBack
        )
    }

    successCallBack = (response) => {
        if(response.status === "success"){
            var {orderRow} = this.state
            orderRow = response.data
            for(let i = 0; i < orderRow.length; i++){
                var buyingPrice = parseInt(orderRow[i]['order_quantity']) * parseFloat(orderRow[i]['mrp_per_quantity'])
                orderRow[i]['buying_price'] = Math.round(buyingPrice)
            }
            this.setState({
                orderRow: response.data
            })
        }
    }

    errorCallBack = (response) => {

    }

    _getVendorList() {
        let url = Constants.LIST_VENDOR + "?from=purchase_order";

        this.service.getApiCall(
            url,
            this._getVendorListSuccess,
            this._getVendorListFailure
        )
    }

    _getVendorListSuccess = response => {

        if (response.status === "success") {

            this.setState({
                vendor_list: response.data,
                vendor_list_suggest: response.data
            });
        }
    };

    _getVendorListFailure = response => {

    }

    paginationChangedValues(data) {
        this.setState({
            paginationNewDrugRequestList: data
        })
    }
    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }

    vendorSuggestionList = () => {
        let {vendor_list, orderRow, selectedIndex} = this.state
        let tempDescList = this.state.vendor_list_suggest
        if (tempDescList.length > 0) {
            var searchListTemp = [];
            for (let i = 0; i < tempDescList.length; i++) {
      
              if (tempDescList[i].name.toLowerCase().indexOf(orderRow[selectedIndex]['vendor_name'].toLowerCase()) > -1) {
                searchListTemp.push(tempDescList[i]);
              }
            }
            vendor_list = searchListTemp;
          } else {
            vendor_list = this.state.vendor_list_suggest;
          }
          this.setState({
            vendor_list
          })
    }

    onClickBackToWanted = (item, index) => {
        let url = Constants.MOVE_ORDER_LIST + "?id=" + item.id
        this.service.deleteApiCall(
            url,
            {},
            this.onClickBackToWantedSuccess,
            this.errorCallBack
        )
    }

    onClickBackToWantedSuccess = (response) => {
        if(response.status === "Success"){
            this.props.responseSuccessErrorMessage(response.message, true)
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", true)
            }, 2000)
            this._getOrderList()
        }else if (response.status == "fail") {
            this.props.responseSuccessErrorMessage(response.message, false)
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", false)
            }, 2000)
        }
    }

    checkAllDetailsAreAvailable(newEntry) {
        var defaultReturn = true
        for (const data of newEntry) {
            if (data.order_quantity && data.vendor_id) {
                // Do Nothing
            } else {
                defaultReturn = false
                return defaultReturn
            }
        }
        return defaultReturn
    }

    onClickMoveToConfirm = () => {
        var {orderRow} = this.state
        var url = Constants.ORDER_LIST

        var checkTestField = this.checkAllDetailsAreAvailable(orderRow)
        
        if(!checkTestField){
            this.props.responseSuccessErrorMessage("Kindly add Qty & Vendor", false)
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", false)
            }, 2000)
        }else{
            var data = {
                order_list: orderRow
            }
            this.service.postApiCall(
                url,
                data,
                this.successToConfirm,
                this.errorCallBack
            )
        }
    }

    successToConfirm = (response) => {
        if(response.status === "Success"){
            this.props.responseSuccessErrorMessage(response.message, true)
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", true)
            }, 2000)
            this._getOrderList()
        }else if (response.status == "fail") {
            this.props.responseSuccessErrorMessage(response.message, false)
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", false)
            }, 2000)
        }
    }

    renderOrderListView = () => {
        var {orderRow, paginationNewDrugRequestList} = this.state
        var states = this.state
        var suggestionHeight = ((states.selectedIndex + 1) * 9) + 30
        return(
            <View style={styles.TableContainer}>
                <ScrollView style={{maxHeight: '65vh'}}>
                <View style={{ flexDirection: "row", width: "100vw"}}>
                <View style={{width: "15vw"}}>
                    <CommonReportSectionHeader
                    heading1={"S.No"} 
                    heading2={"Brand Name"}
                    columnSize={[0.15,0.85]}
                    fontSize={"1vw"}
                    noOfColumn={2}  
                    />
                    {paginationNewDrugRequestList && paginationNewDrugRequestList.length > 0 && paginationNewDrugRequestList.map((item, index) => {
                        let sno = (index + 1);
                    return(
                    <View style={{ flex: 1, flexDirection: "row", justifyContent: 'center', maxHeight: 60, minHeight: 60 }}>
                    <View style={{flex: 0.15, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, paddingVertical: 20,}}>
                    <Text style={{  marginLeft: '1.3vw',fontSize: "1vw" }}>{sno}</Text>
                    </View>
                    <View style={{flex: 0.85, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, paddingVertical: 20, }}>
                    <Tooltip placement="topLeft" title={item.drug_name}>               
                    <Text style={{ marginLeft: '2.0vw', fontSize: "1vw" }}> {item.drug_name && item.drug_name.length > 20 ? item.drug_name.slice(0,20) + ".." :( item.drug_name != null && item.drug_name != "") ? item.drug_name : "-"} </Text> 
                    </Tooltip>
                    </View>
                    </View>
                    )})}
                </View>
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={(orderRow && orderRow.length > 0) ? true : false}  style={{maxWidth: "65vw", flex: 1, minHeight: suggestionHeight+'vh'}}>
                <View style={{width: "100vw"}}>
                <CommonReportSectionHeader
                    heading1={"Generic Name"}
                    heading2={"Dosage Type/Strength"}
                    heading3={"Recent Exp. On"}
                    heading4={"Avl. Qty"}
                    heading5={"Latest MRP/Qty"}
                    heading6={"Place order Vendor"}
                    heading7={"Place order Qty"}
                    heading8={"Free Qty"}
                    heading9={"Discount %"}
                    heading10={"PU Rate"}
                    columnSize={[0.15, 0.20, 0.12, 0.12, 0.15, 0.20, 0.15, 0.15, 0.15, 0.15 ]} 
                    fontSize={"1vw"} 
                    noOfColumn={10}
                    />
                    {paginationNewDrugRequestList && paginationNewDrugRequestList.length > 0 && paginationNewDrugRequestList.map((item, index) => {
                        return(
                        <View style={{ justifyContent: 'center', maxHeight: 60, minHeight: 60, zIndex: 1 - index }}>
                        <View style={{flexDirection: 'row', flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 1, alignItems: 'center' }}>
                        <Tooltip placement="topLeft" title={item.generic_name}>               
                        <Text style={{flex: 0.15, marginLeft: '1.3vw' , fontSize: "1vw"}}> {item.generic_name && item.generic_name.length > 18 ? item.generic_name.slice(0,20) + ".." :( item.generic_name != null && item.generic_name != "") ? item.generic_name : "-"} </Text> 
                        </Tooltip>
                        <Tooltip placement="topLeft" title={item.dosagetype + "/" + item.dosagetype}>               
                        <Text style={{flex: 0.20 , fontSize: "1vw"}}> {item.dosagetype && item.dosagetype.length > 15 ? item.dosagetype.slice(0,15) + ".." :( item.dosagetype != null && item.dosagetype != "") ? item.dosagetype : "-"}{"/"}{item.drug_strength ? item.drug_strength : "-"} </Text> 
                        </Tooltip>
                        <Text style={{ flex: 0.12 , fontSize: "1vw"}}>{item.expiry_date}</Text>
                        <Text style={{ flex: 0.12 , fontSize: "1vw"}}>{item.available_quantity}</Text>
                        <Text style={{ flex: 0.15 , fontSize: "1vw"}}>{item.mrp_per_quantity}</Text>
                        <View style={{ flex: 0.20, zIndex: 0 }}>
                        <TextInput 
                        style={{
                            borderColor: "#CDD1D5",
                            borderRadius: '.26vw',
                            borderWidth: 1,
                            width: "80%",
                            height: '2.8vw',
                            paddingLeft: '0.65vw',
                            marginTop: '.52vw',
                            zIndex: -1,
                            fontSize: "1vw"
                        }} 
                        value={item.vendor_name} 
                        onChangeText = {(text) => {
                            if(text != ""){
                                states['isVendorSuggestion'] = true;
                                orderRow[index]['vendor_id'] = null;
                                states["selectedIndex"] = index
                            }else{
                                states['isVendorSuggestion'] = false;
                                orderRow[index]['vendor_id'] = null;
                                states["selectedIndex"] = null
                            }
                            orderRow[index]['vendor_name'] = text

                            this.setState({
                            orderRow,
                            states
                            }, () => {
                            if((text).trim() != ""){
                                this.vendorSuggestionList()
                            }
                            })
                        }}
                        />
                        {states.isVendorSuggestion && states.vendor_list.length > 0 && index === states.selectedIndex ?
                        <View style={{ zIndex: 2, position: 'absolute', marginTop: 40, width: "90%"}}>
                            <ScrollView
                            style={{
                                maxHeight: '25vh', marginBottom: 10, flex: 1,
                                backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                            }}
                            >
                            {this.state.vendor_list.map((value, index) =>
                                <View style={{ height: 30 }}>
                                <TouchableOpacity key={index}
                                    style={{ marginLeft: 5, marginTop: 5 }}
                                    onPress={
                                    () => {
                                        orderRow[states.selectedIndex]['vendor_name'] = value.name
                                        orderRow[states.selectedIndex]['vendor_id'] = value.vendor_id,
                                        states["selectedIndex"] = null,
                                        states["isVendorSuggestion"] = false
                                        this.setState({
                                            orderRow,
                                            states
                                        })
                                    }
                                    }
                                >
                                    <Text style={{fontSize: "1vw"}}>{value.name}</Text>

                                </TouchableOpacity>
                                </View>
                            )} 
                            </ScrollView>
                        </View>
                        : null
                        }
                        </View>
                        <View style={{ flex: 0.15 }}>
                        <TextInput 
                        style={{
                            borderColor: "#CDD1D5",
                            borderRadius: '.26vw',
                            borderWidth: 1,
                            width: "60%",
                            height: '2.8vw',
                            paddingLeft: '0.65vw',
                            marginTop: '.52vw',
                            fontSize: "1vw"
                        }} 
                        value={item.order_quantity ? item.order_quantity : ""} 
                        onChangeText = {(text) => {
                            if ((Constants.REG_EX_NUMBERS.test(text))) {
                                orderRow[index]['order_quantity'] = text
                                var buyingPrice = parseInt(text) * parseFloat(item.mrp_per_quantity)
                                orderRow[index]['buying_price'] = Math.round(buyingPrice)
                            }
                            this.setState({
                                orderRow
                            })
                        }}
                        />
                        </View>
                        <View style={{ flex: 0.15 }}>
                        <TextInput 
                        style={{
                            borderColor: "#CDD1D5",
                            borderRadius: '.26vw',
                            borderWidth: 1,
                            width: "60%",
                            height: '2.8vw',
                            paddingLeft: '0.65vw',
                            marginTop: '.52vw',
                            fontSize: "1vw"
                        }} 
                        value={item.free_quantity ? item.free_quantity : "" } 
                        onChangeText = {(text) => {
                            if ((Constants.REG_EX_NUMBERS.test(text))) {
                                orderRow[index]['free_quantity'] = text
                            }
                            this.setState({
                                orderRow
                            })
                        }}
                        />
                        </View>
                        <View style={{ flex: 0.15 }}>
                        <TextInput 
                        style={{
                            borderColor: "#CDD1D5",
                            borderRadius: '.26vw',
                            borderWidth: 1,
                            width: "60%",
                            height: '2.8vw',
                            paddingLeft: '0.65vw',
                            marginTop: '.52vw',
                            fontSize: "1vw"
                        }} 
                        value={item.discount_percentage ? item.discount_percentage : ""}  
                        onChangeText = {(text) => {
                            if ((Constants.REG_EX_NUMBERS.test(text))) {
                                orderRow[index]['discount_percentage'] = text
                            }
                            this.setState({
                                orderRow
                            })
                        }}
                        />
                        </View>
                        <View style={{ flex: 0.15 }}>
                        <TextInput 
                        style={{
                            borderColor: "#CDD1D5",
                            borderRadius: '.26vw',
                            borderWidth: 1,
                            width: "60%",
                            height: '2.8vw',
                            paddingLeft: '0.65vw',
                            marginTop: '.52vw',
                            fontSize: "1vw"
                        }} 
                        editable={false}
                        value={item.buying_price ? item.buying_price : ""} 
                        />
                        </View>
                        </View>
                        </View>
                    )})}
                </View>
                </ScrollView>
                <View style={{width: "10vw"}}>
                    <CommonReportSectionHeader 
                    heading1={"Action"}
                    columnSize={[1]}
                    fontSize={"1vw"}
                    noOfColumn={1}  
                    />
                    {paginationNewDrugRequestList && paginationNewDrugRequestList.length > 0 && paginationNewDrugRequestList.map((item, index) => (
                    <View style={{flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, width: "100%", maxHeight: 60, minHeight: 60 }}>
                    <View key={index} style={{ flex: 1, marginHorizontal: "1.30vw", justifyContent: 'center', alignItems: 'center', width: 'fit-content'}}>
                    <CommonButton
                    item={{}}
                    selectedvalue={{}}
                    butttonText={"Remove"}
                    buttonType={"theme"}
                    buttonIcon={""}
                    rightIcon={true}
                    buttonAction={() => {
                        this.onClickBackToWanted(item, index)
                    }}
                    buttonKey={"backToWanted"} />
                    </View>
                    </View>
                    ))}
                </View>
                </View>
                </ScrollView>
                {orderRow && orderRow.length > 0 ?
                <View style={{marginTop: '1.25vw'}}>
                <CommonButton
                    item={{}}
                    selectedvalue={{}}
                    butttonText={"Confirm Order"}
                    buttonType={"theme"}
                    buttonIcon={""}
                    rightIcon={true}
                    buttonAction={() => {
                        this.onClickMoveToConfirm()
                    }}
                    buttonKey={"confirmOrder"} />
                </View>
                : null }
                {
                    orderRow && orderRow.length > 0 ?
                        <View style={{ marginLeft: 20, zIndex: 0 }}>
                        <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)}
                            totalClientItems={orderRow}
                            paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                        />
                        </View> 
                    : null 
                }
            </View>
        )
    }

    render(){
        return(
            <View style={{height:screenHeight - 120}}>
                {/* <MenuBreadCrumpList menu={this.state.menu}
                    selectedScreen={this.state.selectedScreen}
                    changeScreen={this.props.changeScreen}
                    // generateReport={this._generateReport}
                    // isDisable={!resportActions}
                /> */}
                <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginBottom: "0.625vw", paddingHorizontal: "1.25vw" }}>
                        <TouchableOpacity
                            style={[styles.ButtonStyle, { marginRight: " 0.625vw" }]}
                            onPress={() => this.props.NavigateScreen("wantedList")}
                        >
                            <Text style={styles.ButtonText}>Back</Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={{ marginRight: " 0.625vw" }}
                            onPress={() => this.props.NavigateScreen("orderList")}
                        >
                            <Text style={[styles.TitleText,{ color: this.state.selectedScreen === "orderList"? color.themeDark : color.black}]}>Order List</Text>
                        </TouchableOpacity>
                        <Text style={styles.TitleText} >/</Text>
                        <TouchableOpacity
                            style={{ marginRight: " 0.625vw" }}
                            onPress={
                                () => this.props.NavigateScreen("confirmOrderList")
                            }
                        >
                            <Text style={styles.TitleText}>Confirm OrderList</Text>
                        </TouchableOpacity>
                </View>
                {this.renderOrderListView()}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    TableContainer:{ 
        flex: 1, 
        flexDirection: "column", 
        justifyContent: "flex-start", 
        alignContent: "center" ,
        paddingLeft:20
    },
    TableWrapper:{
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#E2E2E2", padding: ".52vw", borderRadius: ".25vw"
    },
    TableBody:{
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#F9F9F9", padding: ".52vw", borderRadius: ".25vw", marginTop: ".32vw",
        borderBottomWidth: 1, borderColor: "#E2E2E2"
    },
    ButtonStyle: {
        width: '6vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '1.25vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize:"1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    },
    TitleText:{
        padding: "0.4vw", 
        fontSize: "1vw", 
        fontWeight: "500"
    }
});