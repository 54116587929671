import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';
import Style from '../../styles/Style';
import { color } from '../../styles/Color';

export default class SalesGST extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReportList: [],
      startTime: "",
      endTime: "",
      billingListAfterPagination: [],


      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0,
      selectedMonth: "",
      pageLoading: true,
      selectedSalesFilterYear: props.selectedSalesFilterYear

    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 
  }

  componentDidMount() {
    this._getSalesDetailed()
  }

  componentWillReceiveProps(props) {

    if (props.isclear && (props.startTime!=this.state.startTime || props.endTime!=this.state.endTime || props.selectedSalesFilterYear != this.state.selectedSalesFilterYear)) 
    {
      this.setState({
        startTime: props.startTime,
        endTime: props.endTime,
        current_page: 1,
        records_per_page: 10,
        selectedSalesFilterYear: props.selectedSalesFilterYear
      }, () => {
        this._getSalesDetailed()
        props.handlepropsvalueclear(false)

      })
    }
  }

  // Get Api 
  _getSalesDetailed = () => {

    var url = ""
    let { current_page, records_per_page } = this.state;

    if ( (this.state.startTime && this.state.endTime) || this.state.selectedSalesFilterYear ) {
      var startDate = this.state.startTime ? this.state.startTime : "";
      var endDate = this.state.endTime ? this.state.endTime : ""
      url = Constants.GET_SALES_GST + "?from_date=" + startDate + "&to_date=" + endDate + "&year=" + this.state.selectedSalesFilterYear + "&page="+current_page+"&page_size="+records_per_page;
    } else {
      url = Constants.GET_SALES_GST+ "?page="+current_page+"&page_size="+records_per_page;

    }

    this.service.getApiCall(
      url,
      this._getSalesDetailedSuccess,
      this._getSalesDetailedFailure
    )
  }

  _getSalesDetailedSuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data.result
      this.setState({
        salesReportList,
        no_of_pages: response.data?response.data.no_of_pages:0,
        total_count: response.data?response.data.total_count:0
      }, () => {
        var data = {
          "total_amount": response.data.total_gst_amount,
          "total_cgst_amount": response.data.total_cgst_amount,
          "total_sgst_amount": response.data.total_sgst_amount,

        }
        this.props.gettotalscoreValues("Sales GST", data)
        this.CalculateAmount()
      })
    }
    else {
      this.props.gettotalscoreValues("Sales GST", "")
    }
  };

  _getSalesDetailedFailure = error => {
    this.props.gettotalscoreValues("Sales GST", "")

    console.log(error);

  };

  CalculateAmount(){
    let dataArray = [];
    let FinalarrRes = [];
    // let data = this.groupBy(this.state.salesReportList, "month")
    // dataArray.push(data)
    //   console.log("Dataset,==", dataArray);
      var result = this.finalResult(this.state.salesReportList);
    console.log("FINAL RESULT....", result);
    let keys = Object.keys(result);
    keys.forEach(ele=>{
      FinalarrRes.push(result[ele])
    })
    
    this.setState({salesReportList: FinalarrRes, pageLoading: false}, ()=>{console.log(this.state.salesReportList)})
  }
  finalResult(data){
    var result = [];
    return data.reduce((res, value)=> {
      if (!res[value.month]) {
        res[value.month] = { month: value.month, cgst_amount: 0, sgst_amount:0, total_amount:0 };
        result.push(res[value.month])
      }
      res[value.month].cgst_amount += value.cgst_amount;
      res[value.month].sgst_amount += value.sgst_amount;
      res[value.month].total_amount += value.total_amount;
      return res;
    }, {});
  }
  groupBy(objectArray, property){
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
         acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
   }, {});
  }
  _SelectedItem(item){
    this.setState({isGSTSplitSelected: true,
    selectedMonth: item.month})
    this.props.GstSplitupSelected(true, item.month)
  }
  // Report List Views Function
  renderContent() {
    return (
      // Report List Views
      <View style={{ marginHorizontal: 20, backgroundColor: "#F9F9F9", marginTop: 15 }}>
        {this.renderContentRow()}
        {/* End Report List View */}

      </View>
    )
  }
  // Common History Section Header function
  renderHeader() {
    return (

      <View style={ReportStyle.TableHeaderView}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Month"}
          heading3={"CGST"}
          heading4={"SGST"}
          heading5={"GST Amount"}
          // heading6={"Sales Amount"}

          // columnSize={[0.15, 0.15,0.15, 0.15,0.15, 0.15]}  //total value is == of 1 
          columnSize={[0.1, 0.225, 0.225, 0.225, 0.225]}
          noOfColumn={5}

        />
      </View>
    )
  }

  // Render Content Row function Key and 
  renderContentRow() {
    if (this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.length < 11) {
      return (
        <View style={{}}>
          {
            this.state.salesReportList && this.state.salesReportList.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => {this.props.GstSplitupSelected(true, item.month)}}>
                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, justifyContent: 'center' }} >
                  <View style={[{ flex: 0.35 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
                  <View style={[{ flex: 0.3 }]}><Text>{item.month}</Text></View>
                  <View style={[{ flex: 0.3 }]}><Text>{item.cgst_amount}</Text></View>
                  <View style={[{ flex: 0.3 }]}><Text>{item.sgst_amount}</Text></View>
                  <View style={[{ flex: 0.3 }]}><Text>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.3 }]}><Text>{item.expiry_date}</Text></View>
                  
                </View>
                </TouchableOpacity>
              )
            })}



        </View>
      )
    }
    else if (this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        <View style={{}}>
          {
            this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => {this._SelectedItem(item)}}>
                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0, justifyContent: 'center' }}>
                  <View style={[{ flex: 0.166 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
                  <View style={[{ flex: 0.166 }]}><Text>{item.date}</Text></View>
                  <View style={[[{ flex: 0.166 }]]}><Text>{item.vendor_gstin_number}</Text></View>
                  <View style={[{ flex: 0.166, }]}><Text>{item.cgst_amount}</Text></View>
                  <View style={[{ flex: 0.166, }]}><Text>{item.sgst_amount}</Text></View>
                  <View style={[{ flex: 0.166, }]}><Text>{item.gst_amount}</Text></View>
                  
                </View>
                </TouchableOpacity>
              )
            })}



        </View>
      )
    }
    else {
      return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100, alignItems: 'center' }}>
        <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
      </View>
    }
  }

  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }

  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page } = this.state

    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        
        this.setState({
            records_per_page: records_per_page_change,
            current_page: next_page
        }, ()=>{
            this._getSalesDetailed()
        })
    }
  }

  render() {
    console.log(this.state.selectedSalesFilterYear,"selectedSalesFilterYear")
    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0

    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}
        {
          total_count > 0 ?
          <View style={{ marginLeft: '1.30vw' }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
            type={Constants.PAGINATION_TYPE_SERVER} 
            totalServerRecordsCount={total_count} 
            totalServerPagesCount={no_of_pages} />
            </View>
            : null
        }
      </View>
    );
  }

  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={ReportStyle.TableDataContainer}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              
              let sno = (current_page-1)*records_per_page + index + 1; 
              let gst_amount = (parseFloat(item.cgst_amount) || 0) + (parseFloat(item.sgst_amount) || 0)
              return (
                <TouchableOpacity onPress={() => {this._SelectedItem(item)}}>
                <View key={sno} style={ReportStyle.TableDataWrapper}>
                  <View style={[{ flex: 0.1 }]}>
                    <Text style={ReportStyle.SNoData}>{sno}</Text></View>
                  <View style={[ReportStyle.ReportCell, { flex: 0.225 }]}>
                    <Text style={[ReportStyle.ReportCellTxt]}>{item.month}</Text></View>
                  <View style={[ReportStyle.ReportCell, { flex: 0.225 }]}>
                    <Text style={[ReportStyle.ReportCellTxt]}>{parseFloat(item.cgst_amount).toFixed(2)}</Text></View>
                  <View style={[ReportStyle.ReportCell, { flex: 0.225 }]}>
                    <Text style={[ReportStyle.ReportCellTxt]}>{parseFloat(item.sgst_amount).toFixed(2)}</Text></View>
                  <View style={[ReportStyle.ReportCell, { flex: 0.225, paddingHorizontal: 15 }]}>
                    <Text style={[ReportStyle.ReportCellTxt]}>{gst_amount.toFixed(2)}</Text></View>
                  {/* <View style={[{ flex: 0.3 }]}><Text>{item.expiry_date}</Text></View> */}
                </View>
                </TouchableOpacity>
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={[Style.loaderStyle,ReportStyle.NorecordsView]}>
          { this.state.pageLoading ?
          <ActivityIndicator size="large" color={color.themeDark} /> : <Text style={ReportStyle.NoRecordsText}>{"No records to be shown"}</Text>
          }
        </View>
      )
    }
  }

}

const ReportStyle = StyleSheet.create({

  ReportCell: {
    width: '13vw',
    alignItems: "flex-start"
  },
  ReportCellTxt: {
    width: '9.76vw',
    paddingHorizontal: '1.63vw',
    textAlign: "center",
    fontSize:'1vw'
  },
  TableHeaderView:{
    marginHorizontal: '1.30vw', marginTop: '0.65vw' 
  },
  TableDataContainer:{
    marginHorizontal: '1.30vw', backgroundColor: "#F9F9F9", marginTop: '0.13vw'
  },
  TableDataWrapper:{
    flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: '0.97vw', justifyContent: 'center'
  },
  SNoData:{
    marginLeft:'1.30vw', fontSize:'1vw'
  },
  NorecordsView:{
    flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: '9.76vw'
  },
  NoRecordsText:{
   color: "#8888888", fontSize:'1vw'
  }
})
