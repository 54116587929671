import React, { Component } from "react";
import { Text, View, TouchableOpacity, ScrollView, Dimensions } from "react-native";
import { SuccessAlert, ErrorAlert } from "../../common/BaseFunctionComponent";
import { color } from "../../styles/Color";
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import Ventors from "./Vendors";   //vendor list
import Ventor from "./index";     //vendor payment
import VendorMonthwise from './vendorMonthwise';
import VendorDetails from "./VendorDetails";
import WantedList from "./WantedList";
import PurchaseOrder from "./PurchaseOrder";
import StockDetails from "../inventory/StockDetails";
import OrderList from "../inventory/orderlist";
import ConfirmOrderList from "../inventory/confirmOrderList";

const screenHeight = Dimensions.get("window").height;
const navigationHeaderList = [
    { label:"Wanted List", value:"wantedList" },
    { label:"Purchase Order", value:"purchaseOrder" },
    { label: "Vendor List", value: "vendors" },
    { label: "Vendor Payment", value: "vendorPayment" }
]

export default class VendorHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: this.props.searchValue ? this.props.searchValue : "",
            login_user_is_admin: this.props.login_user_is_admin ? this.props.login_user_is_admin : false,
            configData: this.props.configData ? this.props.configData : {},
            selectedTab: "wantedList",
            selectedSubPage: "", //vendor_id
            vendor_id: null,
            statusDelivery: "",
            resportActions: true,
            showResponse: false,
            showErrorResponse: false,
            responseMessage: ""
        }
        this.service = new Service();
    }

    NavigateScreen(pagename, subScreen = "", pageType = "", item_id = null, statusDelivery) {
        let states = this.state;
        let letUpdate = true;

        states["statusDelivery"] = statusDelivery;
        states["purchase_id"] = null;
        states["vendor_id"] = null;
        if (pagename === "add_stock") {
            if (item_id) {
                states["purchase_id"] = item_id;
            }
        }
        states["selectedTab"] = pagename;
        states["screenType"] = pageType;
        this.setState({
            states,
            selectedSubPage: subScreen    // vendor_id
        })
    }

    openVendorDetails(vendor_id) {
        if (vendor_id) {
            this.setState({
                vendor_id: vendor_id,
                selectedTab: "update_vendor"
            })
        }
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }


    _generateReport = (type = '') => {

        var url = ""
        if (this.state.selectedTab == "vendors") {
            url = Constants.LIST_VENDOR + "?export=" + type+"&search_term="+ this.state.searchValue;
        }
        else if (this.state.selectedTab == "purchaseOrder") {
            url = Constants.LIST_PO + "?export=" + type+"&search_term="+ this.state.searchValue;

        } 

        if (type) {

            this.setState({ resportActions: false }, () => {
                if (type == 'pdf') {
                    this.service.getInstance().documentUploadGet(
                        url,
                        this.PrintReportSuccess,
                        this.PrintReportFailure,
                        "pdf"
                    );
                } else if (type == 'excel') {
                    this.service.getInstance().documentUploadGet(
                        url,
                        this.exportCSVSuccessCallBack,
                        this.exportCSVSuccessCallBack,
                        "excel"
                    );
                }
            })
        }
    };

    PrintReportSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            // enable actions
            this._enablePrintActions()

        } else {
            // enable actions
            this._enablePrintActions()
        }
    };

    PrintReportFailure = error => {
        // enable actions
        this._enablePrintActions()
    };

    exportCSVSuccessCallBack = response => {
        if (response) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            // enable actions
            this._enablePrintActions()
        } else {
            // enable actions
            this._enablePrintActions()
        }
    }

    exportCSVErrorCallBack = error => {
        // enable actions
        this._enablePrintActions()
    }

    _enablePrintActions = () => {
        this.setState({ resportActions: true })
    }
    render() {
        var { showResponse, showErrorResponse , responseMessage, resportActions} = this.state;
        return (
            <View style={{ height: screenHeight - 110 }}>
                <View style={[{ backgroundColor: color.white, borderBottomLeftRadius: 40, borderBottomRightRadius: 40, height: 45 }, { marginBottom: 10, shadowColor: color.grayShade, shadowOffset: { width: 1, height: 1 }, shadowOpacity: 1, shadowRadius: 2 }]}>
                    <ScrollView style={{ flexDirection: 'row', marginHorizontal: 15 }} horizontal={true} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                        {
                            navigationHeaderList && navigationHeaderList.length > 0 && navigationHeaderList.map((item, index) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.setState({
                                                selectedTab: item.value
                                            })
                                        }} style={{ paddingHorizontal: 10, justifyContent: "center" }} key={index}>
                                        <Text style={[{ fontSize: "1vw", fontWeight: "500" },
                                        this.state.selectedTab === item.value ? { color: color.themeDark } :
                                            this.state.selectedTab === "orderList" && item.value === "wantedList" ? { color: color.themeDark } :
                                            this.state.selectedTab === "confirmOrderList" && item.value === "wantedList" ? { color: color.themeDark } :
                                            this.state.selectedTab === "add_stock" && item.value === "purchaseOrder" ? { color: color.themeDark } :
                                            this.state.selectedTab === "vendorMonthwise" && item.value == "vendorPayment" ? { color: color.themeDark } :
                                            this.state.selectedTab === "update_vendor" && item.value === "vendors" ? {color: color.themeDark} : 
                                            this.state.selectedTab === "add_vendor" && item.value === "vendors" ? {color: color.themeDark} : {}
                                        ]}>

                                            {item.label}</Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </ScrollView>
                </View>

                <View style={{}}>
                    {
                        this.state.selectedTab === "wantedList" ? <WantedList NavigateScreen={this.NavigateScreen.bind(this)} searchValue = {this.state.searchValue} responseSuccessErrorMessage = {this.responseSuccessErrorMessage.bind(this)}/> : 
                        this.state.selectedTab === "orderList" ? <OrderList NavigateScreen={this.NavigateScreen.bind(this)} selectedScreen = {this.state.selectedTab} responseSuccessErrorMessage = {this.responseSuccessErrorMessage.bind(this)}/>:
                        this.state.selectedTab === "confirmOrderList" ? <ConfirmOrderList NavigateScreen={this.NavigateScreen.bind(this)} selectedScreen = {this.state.selectedTab}/>:
                        this.state.selectedTab === "purchaseOrder" ? <PurchaseOrder NavigateScreen={this.NavigateScreen.bind(this)} searchValue = {this.state.searchValue} generateReport={this._generateReport} isDisable={!resportActions} login_user_is_admin = {this.state.login_user_is_admin} configData = {this.state.configData}/>:
                        this.state.selectedTab === "add_stock" ? <StockDetails  selectedScreen={this.state.selectedTab} screenType={this.state.screenType} purchase_id={this.state.purchase_id} NavigateScreen={this.NavigateScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} 
                        statusDelivery = {this.state.statusDelivery} _getDrugAndStockCount = {this.props._getDrugAndStockCount}/>:
                        this.state.selectedTab === "vendors" ? <Ventors openVendorDetails = {this.openVendorDetails.bind(this)} NavigateScreen={this.NavigateScreen.bind(this)} generateReport={this._generateReport} isDisable={!resportActions}/> :
                        this.state.selectedTab === "update_vendor"? <VendorDetails selectedScreen = {this.state.selectedTab} NavigateScreen={this.NavigateScreen.bind(this)} edit_id={this.state.vendor_id} login_user_is_admin = {this.state.login_user_is_admin} />:
                        this.state.selectedTab === "add_vendor" ?<VendorDetails selectedScreen = {this.state.selectedTab} NavigateScreen={this.NavigateScreen.bind(this)} />:
                            this.state.selectedTab === "vendorPayment" ? <Ventor NavigateScreen={this.NavigateScreen.bind(this)} /> :
                                this.state.selectedTab === "vendorMonthwise" ? <VendorMonthwise NavigateScreen={this.NavigateScreen.bind(this)} vendor_id={this.state.selectedSubPage} /> :
                                    null
                    }
                </View>
                {
                    showResponse ?
                        <SuccessAlert message={responseMessage} /> : null
                }
                {
                    showErrorResponse ?
                        <ErrorAlert message={responseMessage} /> : null
                }
            </View>
        )
    }

}
