import AsyncStorage from '../AsyncStorage'
import axios from "axios";

export default class Service {
  static instance;

  getInstance() {
    if (this.instance == undefined) {
      this.instance = new Service();
    }
    return this.instance;
  }

  postApiCall(url, data, successResponse, errorResponse, api = "") {
    this.callApi(url, "POST", data, successResponse, errorResponse, api);
  }
  patchApiCall(url, data, successResponse, errorResponse, api = "") {
    this.callApi(url, "PATCH", data, successResponse, errorResponse, api);
  }
  getApiCall(url, successResponse, errorResponse, type, passData) {
    this.callApi(url, "GET", undefined, successResponse, errorResponse, "", type, passData);
  }

  putApiCall(url, data, successResponse, errorResponse, api = "") {
    this.callApi(url, "PUT", data, successResponse, errorResponse, api);
  }
  deleteApiCall(url, data, successResponse, errorResponse, api = "") {
    this.callApi(url, "DELETE", data, successResponse, errorResponse, api);
  }
  documentUploadGet(url, successCallback, errorCallback, type = "") {
    this.documentUploadGetAPI(
      url,
      successCallback,
      errorCallback,
      type
    );
  }

  documentUploadPostAPI = async (url, data, successCallback, errorCallback, contentType = 'application/json', docType, doctorId) => {

    axios
      .post(url, data,
        {
          headers: {
            "Content-Type": contentType,
            Authorization:
              "Token " + (await AsyncStorage.getItem("token")).toString(),
          }
        }
      )
      .then((response) => {
        successCallback(response, docType, doctorId);
        return response;
      })
      .catch((error) => {
        if (error.response) { errorCallback(error.response); }
        return error;
      });

    // }else{
    //   successCallback("", docType,flag);
    // }
  }


  documentUploadGetAPI = async (
    url,
    successCallback,
    errorCallback,
    type
  ) => {

    let contentType = type == "pdf" ? "application/pdf"
      : type == "excel" ? "application/x-www-form-urlencoded; charset=UTF-8"
        : type == "csv" ? "text/csv" : "application/json"

    axios.get(
      url,
      {
        headers: {
          "Content-Type": contentType,
          Authorization: "Token " + (await AsyncStorage.getItem("token")).toString()
        },
        responseType: 'blob', //important
      },
    )
      .then((response) => {
        successCallback(response);
        // return response;
      })
      .catch((error) => {
        errorCallback(error.response);
        // return error;
      });
  }
  
  callApi = async (
    url,
    methodType,
    data,
    successCallback,
    errorCallback,
    api,
    type,
    passData
  ) => {
    fetch(url, {
      method: methodType,
      headers: {
        "Content-Type": "application/json",
        Authorization: api == "login" ? null : "Token " + (await AsyncStorage.getItem("token")).toString()
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(responseData => {
        console.log('success - ' + responseData)
        // if(responseData.detail && responseData.detail==="Token is expired"){

        //   this.props.navigation.navigate( "loginNavigator" );
        // }
        successCallback(responseData, type, passData);
        return responseData;
      })
      .catch(error => {
        console.log('catch - ' + url + JSON.stringify(error))
        errorCallback(error);
        return error;
      });

  };

  AxiosGETAPI = async (url, successCallback, errorCallback, contentType = 'application/json', docType = '', doctorId = '') => {

    axios
      .get(url, {
        headers: {
          "Content-Type": contentType,
          Authorization:
            "Token " + (await AsyncStorage.getItem("token")).toString(),
        }
      }
      )
      .then((response) => {
        console.log(" axios success ");
        successCallback(response, docType, doctorId);
        return response;
      })
      .catch((error) => {
        console.log(" axios error ");
        if (error.response) { errorCallback(error.response); }
        return error;
      });

  }

}
