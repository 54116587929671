import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';

export default class PurchaseMonthly extends Component {
  constructor(props) {
    super(props);

    let month = moment().format("MM")
    let year = moment().format("YYYY")

    this.state = {
      salesReportList: [],
      startTime: month,
      endTime: year,
      billingListAfterPagination: [],
      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0

    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 
  }

  componentDidMount() {
    this._getPurchaseMonthly()
  }

  componentWillReceiveProps(props) {
    if (props.monthvalue && props.yearValue && props.isclear) {
      this.setState({
        startTime: props.monthvalue,
        endTime: props.yearValue,
        current_page: 1,
        records_per_page: 10
      }, () => {
        this._getPurchaseMonthly()
        props.handlepropsvalueclear(false)

      })
    }
  }

  // Get Api 
  _getPurchaseMonthly = () => {
    
    var url = "" 
    let { current_page, records_per_page } = this.state;

    if (this.state.startTime && this.state.endTime && !this.props.VenderselectedID) {
      url = Constants.GET_PURCHASE_MONTHLY + "?month=" + this.state.startTime + "&year=" + this.state.endTime + "&page="+current_page+"&page_size="+records_per_page;
    }else if (this.props.VenderselectedID) {
      url = Constants.GET_PURCHASE_MONTHLY + "?month=" + this.state.startTime + "&year=" + this.state.endTime + "&page="+current_page+"&page_size="+records_per_page+"&vendor_id="+ this.props.VenderselectedID;
    } else {
      url = Constants.GET_PURCHASE_MONTHLY + "?page="+current_page+"&page_size="+records_per_page;
    }
    this.service.getApiCall(
      url,
      this._getPurchaseMonthlySuccess,
      this._getPurchaseMonthlyFailure
    )



  }

  _getPurchaseMonthlySuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data.bill_details
      this.setState({
        salesReportList, 
        no_of_pages: response.data?response.data.no_of_pages:0,
        total_count: response.data?response.data.total_count:0
      }, () => {
        let data = {
          total_amount : response.data.total_amount,
          return_amount : response.data.return_amount
        }
        this.props.gettotalscoreValues("Purchase Monthly", data)

      })
    }
    else {
      this.props.gettotalscoreValues("Purchase Monthly", "")

    }
  };

  _getPurchaseMonthlyFailure = error => {
    this.props.gettotalscoreValues("Purchase Monthly", "")

    console.log(error);

  };
  // Report List Views Function
  renderContent() {
    return (
      // Report List Views
      <View style={{ marginHorizontal: 20, backgroundColor: "#F9F9F9", marginTop: 15 }}>
        {this.renderContentRow()}



        {/* End Report List View */}

      </View>
    )
  }
  // Common History Section Header function
  renderHeader() {
    return (

      <View style={styles.TableHead}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Date"}
          heading3={"Vendor Name"}
          heading4={"Bill Number"}
          heading5={"Purchase Order No"}
          heading6={"Amount"}
          heading7={"EPR Value"}



          columnSize={[0.07, 0.2, 0.2, 0.2, 0.2, 0.2, 0.08]}  //total value is == of 1
          noOfColumn={7}

        />
      </View>
    )
  }


  // Render Content Row function Key and 
  renderContentRow() {
    if (this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.length < 11) {
      return (
        <View style={{}}>
          {
            this.state.salesReportList && this.state.salesReportList.map((item, index) => {
              return (
                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0, justifyContent: 'center' }}>
                  <View style={[{ flex: 0.19 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.date}</Text></View>
                  <View style={[[{ flex: 0.2 }]]}><Text>{item.vendor_name}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.bill_no}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.purchase_order_number}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.amount}</Text></View>

                </View>
              )
            })}
          {/* "date": "01-06-2020",
                "vendor_name": "Test Vendor",
                "bill_no": "ABC123",
                "purchase_order_number": 1,
                "amount": 1000.0 */}


        </View>
      )

    }
    else if (this.state.salesReportList && this.state.salesReportList.length > 0) {

      return (
        <View style={{}}>
          {
            this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
              return (
                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0, justifyContent: 'center' }}>
                  <View style={[{ flex: 0.19 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.date}</Text></View>
                  <View style={[[{ flex: 0.2 }]]}><Text>{item.vendor_name}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.bill_no}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.purchase_order_number}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.amount}</Text></View>
                </View>
              )
            })
          }
        </View>
      )

    }
    else {
      return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100, alignItems: 'center' }}>
        <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
      </View>
    }
  }

  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }
  
  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page } = this.state

    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        
        this.setState({
            records_per_page: records_per_page_change,
            current_page: next_page
        }, ()=>{
            this._getPurchaseMonthly()
        })
    }
  }

  render() {

    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0

    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}
        {
          total_count > 0 ?
          <View style={{ marginLeft: 20 }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
              type={Constants.PAGINATION_TYPE_SERVER} 
              totalServerRecordsCount={total_count} 
              totalServerPagesCount={no_of_pages}/></View>
            : null
        }
      </View>
    );
  }
  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={styles.TableContent}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              
              let sno = (current_page-1)*records_per_page + index + 1;
              let amount = (parseFloat(item.amount) || 0).toFixed(2)

              return (
                <View key={sno} style={styles.TableContentView}>
                  <View style={[{ flex: 0.07 }]}><Text style={[styles.TableContentText,{ marginLeft: 20 }]}>{sno}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text style={styles.TableContentText}>{item.date ? moment(item.date, 'DD/MM/YYYY').format("DD-MM-YYYY") : null}</Text></View>
                  <View style={[[{ flex: 0.2 }]]}><Text style={styles.TableContentText}>{item.vendor_name}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text style={styles.TableContentText}>{item.bill_no}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text style={styles.TableContentText}>{item.purchase_order_number}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text style={styles.TableContentText}>{amount}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text style={styles.TableContentText}>{item.epr_value}</Text></View>
                </View>
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={styles.NoRecordsView}>
          <Text style={styles.NoRecordsText}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }
}
const styles = StyleSheet.create({
      TableHead: { 
        marginHorizontal: '1.2vw', 
        marginTop: '.5vw' 
      },
      TableContent: { 
        marginHorizontal: '1.2vw', 
        backgroundColor: "#F9F9F9", 
        marginTop: '1vw' 
      },
      TableContentView: { 
        flexDirection: 'row', 
        borderBottomColor: "#888888", 
        borderBottomWidth: 1, 
        paddingVertical: '1.2vw', 
        marginHorizontal: 0 
      },
      TableContentText: { fontSize: '1vw' },
      NoRecordsView: { 
        flex: 1, 
        flexDirection: "row", 
        justifyContent: "center", 
        alignContent: "center", 
        marginTop: '9.5vw' 
      },
      NoRecordsText:{ color: "#8888888", fontSize: '1vw' }
})