import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';
import { Tooltip } from "antd";

const PAGE_LOWSTOCK = "Low Stock Drugs"

export default class StockOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lowStockList: [],
      startTime: "",
      endTime: "",
      lowStockListAfterPagination: [],
      // for pagination
      current_page: 1,
      records_per_page: 10,
      drugNameSearch: ""
    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 

  }

  componentDidMount() {
    this._getLowStockList()
  }

  componentWillReceiveProps(props) {
    if ((props.startTime && props.endTime && props.isclear) || ( props.isclear )) {
      this.setState({
        startTime: props.startTime,
        endTime: props.endTime,
        current_page: 1,
        records_per_page: 10,
        drugNameSearch: props.drugNameSearch
      }, () => {
        this._getLowStockList()
        props.handlepropsvalueclear(false)

      })
    }
  }

  // Get Api 
  _getLowStockList = () => {

    if(this.state.drugNameSearch != "") {
      this.setState({
        lowStockList: [],
        lowStockListAfterPagination:[],
        current_page: 1,
        records_per_page: 10
      })
    }

   let url = ""
   if(this.state.drugNameSearch.length > 0 ) {  
    url = Constants.GET_REORDER_THRESHOLD_REPORT + "?brand_name=" + this.state.drugNameSearch
   } else {
    url = Constants.GET_REORDER_THRESHOLD_REPORT
   }
    this.service.getApiCall(
      url,
      this._getLowStockListSuccess,
      this._getLowStockListFailure
    )

  }

  _getLowStockListSuccess = response => {

    let { lowStockList } = this.state;

    if (response.status === "success") {

      lowStockList = response.data

      this.setState({
        lowStockList,
        current_page: 1,
      }, () => {
        this.props.gettotalscoreValues(PAGE_LOWSTOCK, "")

      })
    }else{
      this.props.gettotalscoreValues(PAGE_LOWSTOCK, "")

    }
  };

  _getLowStockListFailure = error => {
    this.props.gettotalscoreValues(PAGE_LOWSTOCK, "")

    console.log(error);

  };
  renderHeader() {
    return (

      <View style={styles.renderHeader}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Brand Name"}
          heading3={"Dosage Type"}
          heading4={"Dosage Strength"}
          heading5={"Batch No"}
          heading6={"Reorder Threshold"}
          heading7={"Available Quanitity"}
          columnSize={[0.08, 0.18, 0.16, 0.16, 0.14, 0.14, 0.14]}  //total value is == of 1
          noOfColumn={7}

        />
      </View>
    )
  }


  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }

  _paginationChangedValues(data) {
    this.setState({
      lowStockListAfterPagination: data
    })
  }
  paginationCurrentPage = (current_page, records_per_page) => {
   this.setState({
    current_page: current_page,
    records_per_page: records_per_page
   })
  }
  render() {

    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}
        {
          this.state.lowStockList && this.state.lowStockList.length > 0 ?
          <View style={{ marginLeft: "1.30vw" }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
              totalClientItems={this.state.lowStockList}
              paginationCurrentPage = { this.paginationCurrentPage.bind(this) }
            />
          </View> : null
        }
      </View>
    );
  }
  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.lowStockListAfterPagination && this.state.lowStockListAfterPagination.length > 0) {
      return (
        // Report List Views
        <View style={styles.bodyContainer}>
          {this.state.lowStockListAfterPagination && this.state.lowStockListAfterPagination.length > 0 && this.state.lowStockListAfterPagination.map((item, index) => {
              
              let sno = (current_page-1)*records_per_page + index + 1;

              return (
                <View key={index} style={styles.bodyWrapper}>
                  <View style={[{ flex: 0.08, marginLeft: "1.35vw"  }]}><Text style={[styles.bodylabel]}>{sno}</Text></View>
                  <View style={[{ flex: 0.18 }]}>
                     <Tooltip title={item.drug_name && (item.drug_name).length > 20 ? item.drug_name : null}>
                         <Text style={styles.bodylabel}>{item.drug_name && (item.drug_name).length > 20 ? (item.drug_name).slice(0,18) + "..." : item.drug_name}</Text>
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.16 }]}><Text style={styles.bodylabel}>{item.dosage_type}</Text></View>
                  <View style={[{ flex: 0.16 }]}><Text style={styles.bodylabel}>{item.dosage_strength}</Text></View>
                  <View style={[{ flex: 0.14 }]}><Text style={styles.bodylabel}>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.14 }]}><Text style={[styles.bodylabel,{ flex: 1, paddingRight: ".65vw" }]}>{item.reorder_threshold}</Text></View>
                  <View style={[{ flex: 0.14 }]}><Text style={[styles.bodylabel,{ flex: 1, paddingRight: ".65vw" }]}>{item.available_quantity}</Text></View>
                </View>
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={styles.NorecordDiv}>
          <Text style={styles.NorecordText}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }

}

const styles = StyleSheet.create({
renderHeader:{ 
  marginHorizontal: "1.30vw", 
  marginTop: ".65vw" 
},
bodyContainer:{ 
  marginHorizontal: "1.30vw", 
  backgroundColor: "#F9F9F9", 
  marginTop: "1vw" 
},
bodyWrapper:{ 
  flexDirection: 'row', 
  borderBottomColor: "#888888", 
  borderBottomWidth: ".1vw", 
  paddingVertical: "1.30vw", 
  marginHorizontal: 0 
},
bodylabel:{
  fontSize:"1vw"
},
NorecordDiv:{ 
  flex: 1, 
  flexDirection: "row", 
  justifyContent: "center", 
  alignContent: "center", 
  marginTop: "9.8vw" 
},
NorecordText:{ 
  color: "#8888888", 
  fontSize:"1vw" 
}
});
