import React, { Component } from 'react';
import { View, Text, Dimensions, ScrollView, StyleSheet, CheckBox, TextInput, TouchableOpacity, Image } from 'react-native';
import { color } from '../styles/Color';
import Icon from "react-native-vector-icons/FontAwesome";
import Constants from "../networks/Constants";
import Service from '../networks/service'; 
import Icons from "react-native-vector-icons/Entypo";
import Pagination from '../common/Pagination'
import moment from 'moment';
import { SuccessAlert, ErrorAlert, CommonReportSectionHeader, CommonDateFilter, ActionButton } from '../common/BaseFunctionComponent'

const screenHeight = Dimensions.get("window").height;
var billType = [
    { label: "Corporate", value: "corporate" },
    { label: "Credit", value: "credit" },
    { label: "Insurance", value: "insurance" }
  
  ]
  let fulldate = new Date();
  let converted_date = moment(fulldate).format("YYYY-MM-DD");

export default class CreditBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedPaymentType : null,
        isAllChecked : false,
        roundOff : 0.00,

        discountPersentage : 0,
        discountAmount : 0.00,
        isOnFocus : false,
        patientID : this.props.patient_id,
        originalCreditBillList : [],
        creditBillList : [],
        creditBillListAfterPagination : [],

        isCloseBill : false,
        cancelReason : "",
        selectedID : [],
        paymentTotalAmount : 0.00,
        receviedAmount : 0.00,
        balanceAmount : 0.00,
        cardNumber : "",
        cashAmount : 0,
        cardAmount : 0,
        upiTransactionID : "",
        amountPayable : 0,
        showResponse: false,
        responseMessage: "",
        showErrorResponse: false,
        outStandingAmount : 0,
        totalPaidAmount : 0,
        totalBillAmount : 0,

        policyNumber: "",
        companyName: "",
        insuranceTransactionID: "",

        //Splitup 
        isPendingBills: true,
        billNumSearch: "",
        startTime: "",
        endTime: "",
        filterDate: "",
        showDateFilter: false,
        paymentTypeList: [],
        completedCreditBill: [],
        completeBillAfterPagination: [],
        currentPage: 1,
        recordsPerPage: 10, 
        totalCompleteBillAmount: 0,
        totalCompleteBillPaidAmount: 0,
        totalPayableAmount: 0,
        isClear: false,
        isclearInt: 0

      };
    this.service = new Service()

  }
  componentDidMount() {
    {this._getCreditBilltList()}
  }
  _getCreditBilltList() {

    this.setState({
      currentPage: 1,
      recordsPerPage: 10,
      creditBillListAfterPagination: [],
      completedCreditBill: [],
      completeBillAfterPagination: [],
      isClear: false,
      isClearInt: 0
    })
    let status = this.state.isPendingBills ? 'Partially Saved' : 'Payment Received'
    let url = Constants.GET_CREDIT_BILL_LIST + "?patient_id=" + this.state.patientID ;

    if(this.state.isPendingBills) {
      url = Constants.GET_CREDIT_BILL_LIST + "?patient_id=" + this.state.patientID + "&bill_status=" + status ;
    } else {
      url = url+ "&bill_status=" + status + "&invoice_number=" + this.state.billNumSearch + "&from_date=" +this.state.startTime + "&to_date=" + this.state.endTime + "&payment_mode=" + this.state.paymentTypeList 
    }
    this.service.getApiCall(
      url,
      this._getCreditBilltListSuccess,
      this._getCreditBilltListFailure
    )
  }

  _getCreditBilltListSuccess = response => {

    if (response.status === "success") {

      this.setState({
        originalCreditBillList: response.data,
        outStandingAmount : response.total_outstanding_amount,
        totalPaidAmount : response.total_paid_amount,
        totalBillAmount : response.total_bill_amount,
        creditBillList: response.data,
        completedCreditBill: response.data,
        totalCompleteBillAmount: response.total_bill_amount,
        totalCompleteBillPaidAmount: response.total_paid_amount,
        totalPayableAmount: response.total_payable_amount,
        selectedID: [],
        isAllChecked: false

      },()=>{
        this.props.renderPatientDetails(this.state.originalCreditBillList)
        this.state.originalCreditBillList.forEach(data => { data.isSelected = false; });
        // if(this.state.originalCreditBillList.length == 0){
        //   this.setState({
        //     isPendingBills: false
        //   })
          // this.props.changeScreen("creditHome")
        // } 
      });
    }
  };

  _getCreditBilltListFailure = response => {
  }
  renderCard(color, value, placehold) {
    return (
      <View style={{ backgroundColor: color, borderRadius: 4, paddingVertical: 4, maxHeight: '3.2vw', justifyContent: "center"} }>
        <View style={ CreditStyle.cardMain }>
          <Text style={CreditStyle.cardText}>{value ? parseFloat(value).toFixed(2): 0}</Text>
          <Text style={CreditStyle.cardText}>{"₹"}</Text>
        </View>
        <Text style={CreditStyle.cardPlacehold}>{placehold}</Text>
      </View>
    )
  }
  renderBillTypeDetails(){
      return(
          <View>
               {this.state.selectedBillType == "corporate" ?
                    <View style={{ marginTop: 10, paddingHorizontal: 30, flexDirection : "row" }}>
                        {this.renderBillTypeTextBoxRow("Company Name", "billTypeCompanyName", "")}
                        {this.renderBillTypeTextBoxRow("Employee ID", "billTypeEmployeeID", "")}
                    </View> : null
                }
                {this.state.selectedBillType == "insurance" ?
                    <View style={{ marginTop: 10, paddingHorizontal: 30, flexDirection : "row" }}>
                        {this.renderBillTypeTextBoxRow("Company Name", "companyName", "")}
                        {this.renderBillTypeTextBoxRow("Policy Number", "policyNumber", "")}
                    </View> : null
                }
          </View>
      )
  }
  renderPaymentIcons(image, key, text) {
    return (
      <View style={{}}>
        <TouchableOpacity onPress={() => {
          var states = this.state
          if (key == 1) {
            states["selectedPaymentType"] = 1
          } else if (key == 2) {
            states["selectedPaymentType"] = 2
          } else if (key == 3) {
            states["selectedPaymentType"] = 3
          } else if (key == 4) {
            states["selectedPaymentType"] = 4
          } else if (key == 5) {
            states["selectedPaymentType"] = 5
          } else if (key == 6) {
            states["selectedPaymentType"] = 6
          }
          states['cashAmount'] = 0;
          states['cardNumber'] = null;
          states['receviedAmount'] = 0;
          states['balance'] = 0;
          states['upiTransactionID'] = ""
          this.setState({ states })

        }} style={{ alignItems: "center" }} 
        // disabled = {this.state.payableAmount > 0 ? false : true}
        >
          <Image source={image} style={{ height: '2.3vw', width: '2.3vw', marginBottom: 5, tintColor: this.state.selectedPaymentType == key ? color.themeDark : "#888888" }} />
          <Text style={{ fontSize: '1vw' }}>{text}</Text>
        </TouchableOpacity>
      </View>
    )
  }

  renderPaymentType() {
    return (
      <View style={{ zIndex: -1 }}>
        <View style={{ flexDirection: 'row', justifyContent: "space-evenly" }}>
          {this.renderPaymentIcons(
            require('../../assets/images/Cash.png'),
            1, "Cash"
          )}
          {this.renderPaymentIcons(
            require('../../assets/images/Card.png'),
            2, "Card"
          )}
          {this.renderPaymentIcons(
            require('../../assets/images/Card.png'),
            3, "Cash & Card"
          )}
          {this.renderPaymentIcons(
            require("../../assets/images/upi.png"),
            4, "UPI"
          )}
          {this.renderPaymentIcons(
            require("../../assets/images/bank-transfer.png"),
            5, "Bank Transfer"
          )}
          {this.renderPaymentIcons(
            require("../../assets/images/Insurance.png"),
            6, "Insurance"
          )}
        </View>
        {
          this.state.selectedPaymentType == 1 ?
            <View style={CreditStyle.paymentView}>
              {this.renderPaymentTypeTextBoxRow("Total", "PaymentTotalAmount", this.state.amountPayable)}
              {this.renderPaymentTypeTextBoxRow("Received Amount", "receviedAmount", this.state.receviedAmount)}
              {this.renderPaymentTypeTextBoxRow("Balance Amount", "balanceAmount", this.state.balanceAmount)}
            </View> : null}
        {this.state.selectedPaymentType == 2 ?
          <View style={CreditStyle.paymentView}>
            {this.renderPaymentTypeTextBoxRow("Total", "PaymentTotalAmount", parseFloat(this.state.amountPayable))}
            {this.renderPaymentTypeTextBoxRow("Card Number", "cardNumber", this.state.cardNumber)}
          </View> : null}
        {this.state.selectedPaymentType == 3 ?
          <View style={CreditStyle.paymentView}>
            {this.renderPaymentTypeTextBoxRow("Total", "PaymentTotalAmount", this.state.amountPayable)}
            {this.renderPaymentTypeTextBoxRow("Cash", "cashAmount", this.state.cashAmount)}
            {this.renderPaymentTypeTextBoxRow("Card", "cardAmount", this.state.cardAmount)}
            {this.renderPaymentTypeTextBoxRow("Card Number", "cardNumber", this.state.cardNumber)}
          </View> : null}
        {this.state.selectedPaymentType == 4 || this.state.selectedPaymentType == 5 ?
          <View style={CreditStyle.paymentView}>
            {this.renderPaymentTypeTextBoxRow("Transaction ID", "upiTransactionID", this.state.upiTransactionID)}
          </View> : null}
        {this.state.selectedPaymentType == 6 ?
          <View style={CreditStyle.paymentView}>
             {this.renderBillTypeTextBoxRow("Company Name", "companyName", this.state.companyName)}
             {this.renderBillTypeTextBoxRow("Policy Number", "policyNumber", this.state.policyNumber)} 
             {this.renderBillTypeTextBoxRow("Transaction ID", "insuranceTransactionID", this.state.insuranceTransactionID)} 

       </View> : null}


      </View>
    )
  }
  renderBillingView = (placehold, value, key) => {
    return ( 
      <View style={{ flexDirection: 'row', padding: 5, borderTopWidth : key == "amountPayable" ? 1 : null, borderTopColor : key == "amountPayable" ? color.black : null}}>
        <View style={{ flex: 0.5 }}><Text style={{fontSize: '1vw'}}>{placehold}</Text></View>
        <View style={{ flex: 0.3, flexDirection: "row", justifyContent: "flex-end" }}>
          <Text style={{ fontSize: '1.2vw' }}>{"₹"}</Text>
            <Text style={{ fontSize: '1.2vw', textAlign: 'right', marginLeft: 5, justifyContent: "flex-end" }}>{value ? value : 0}</Text>  
        </View>
      </View>
    )

  }
  renderBilling() {
    return(
      <View style={{ width : "85%" }}>
        <Text style={{ fontSize: '1.2vw', fontWeight: 500 }}>{"Billing"}</Text>
        {this.renderBillingView("Bill Amount", this.state.paymentTotalAmount ? parseFloat(this.state.paymentTotalAmount).toFixed(2) : 0, "paymentTotalAmount")}
        {this.renderDiscountView()}
        {this.renderBillingView("Round Off", parseFloat(this.state.roundOff).toFixed(2), "roundOff")}
        {this.renderBillingView("Amount Payable", this.state.amountPayable, "amountPayable")}


      </View>
    )
  }
  onFocus = () =>{
    this.setState({
        isOnFocus : true
    })
  }  
  onBlur = () =>{
    this.setState({
        isOnFocus : false
    })
  }
  checkFloat = (num) => {
    return Number(num) === num && num % 1 != 0
  }  
  calculateDiscountAmount() {

    var states = this.state;
    let { is_disc_percent_changed,discountAmount, discountPersentage } = this.state;

    if (((states.paymentTotalAmount ) != 0 && discountPersentage) || (parseFloat(states.paymentTotalAmount) != 0 && discountAmount)) {

      var amount = parseFloat(discountAmount);

      if (is_disc_percent_changed) {
        let tem_discountPersentage = parseFloat(discountPersentage) || 0;
        amount = ((parseFloat(states.paymentTotalAmount) / 100) * tem_discountPersentage);
      } else {
        discountPersentage = ((amount / this.state.paymentTotalAmount) * 100).toFixed(2);
      }

      this.setState({
        discountAmount: amount > 0 ? parseFloat(amount).toFixed(2) : 0,
        discountPersentage: discountPersentage ? parseFloat(discountPersentage).toFixed(2) : 0
      }, () => {
        states,
        this.roundOffCalculation()
        this.payableAmountCalculation()
      })
    } else {
      this.setState({
        discountAmount: 0,
        discountPersentage:  0,
        roundOff: 0
      },()=>{
        this.payableAmountCalculation()
      })
    }
  }
 
  roundOffCalculation = () => {
    var states = this.state;

    var roundOffAmount = states.discountAmount % 1;
    var totalPaymentAmount = Math.round(states.discountAmount)
    states["discountRoundOff"] = totalPaymentAmount; 

    if (parseFloat(roundOffAmount) >= 0.5) {
      states["roundOff"] = (1 - parseFloat(roundOffAmount))
    } else if (parseFloat(roundOffAmount) < 0.5) {
      states["roundOff"] = "-" + (roundOffAmount)
    }
    this.setState({
      states
    })
  }
  renderTextBoxAlone(flexWidth = 1, center, key, value, border = '') {

    return (
      <View style={{ flex: flexWidth }} >

        <TextInput
          underlineColorAndroid="transparent"
          style={{
            zIndex: -1, color: "grey", borderRadius: border ? border : 10, borderColor: "grey", borderWidth: 1, width: '5vw',
            height: '1.8vw',
            fontSize: '1vw',
            paddingLeft: 10, alignSelf: center ? 'center' : null, paddingHorizontal : 3,
            backgroundColor: color.white,
            textAlign: "right", marginLeft: '0.5vw', borderRadius : 4,
            color: key === "discountAmount" && this.state.discountAmount != 0 ? this.state.discountAmount > this.state.paymentTotalAmount ? color.red : null : null,
          }}
          placeholderTextColor={"grey"}
          autoCapitalize="none"
          value={ value ? value : 0 }
          keyboardType = {"numeric"}
          onFocus={ ()=>{ this.onFocus()}}
          onBlur = {() => {this.onBlur()}}
          onChangeText={amount => {
            if (key === "discountPersentage" && amount <= 100 ) {
              var states = this.state
              states[key] = amount
              this.setState({
                states,
                is_disc_percent_changed: key === "discountPersentage" ? true : false
              }, () => {
                  key == "discountPersentage" || key == "discountAmount" ?
                  this.calculateDiscountAmount() : null
              })
            } else if (key == "discountAmount") {
                var states = this.state
                if(amount != this.state.paymentTotalAmount && amount > this.state.paymentTotalAmount) {
                  this.responseSuccessErrorMessage("The discount amount should less than payable amount", false)
                  setTimeout(() => {
                    this.responseSuccessErrorMessage("", false)
                  }, 3000)
                } else {
                  states[key] = amount
                  this.setState({
                    states,
                    is_disc_percent_changed: key === "discountPersentage" ? true : false
                  }, () => {
                     key == "discountPersentage" || key == "discountAmount" ?
                     this.calculateDiscountAmount() : null
                })
                } 
           }
          }}
        />
      </View>
    );
  }
  renderDiscountView = () => {
    let discountPercentage =(this.checkFloat(parseFloat(this.state.discountPersentage)) && ! this.state.isOnFocus ) ? parseFloat(this.state.discountPersentage).toFixed(2) : parseFloat(this.state.discountPersentage)
    let discountAmount =(this.checkFloat(parseFloat(this.state.discountAmount)) && ! this.state.isOnFocus ) ? parseFloat(this.state.discountAmount).toFixed(2) : parseFloat(this.state.discountAmount)
 
     return (
       <View style={{ flexDirection: 'row', padding: 5 }}>
         <View style={{ flexDirection: "row", flex: 0.5}}>
           <Text style={{fontSize: '1vw'}}>{"Discount %"}</Text>
           {this.renderTextBoxAlone("", false, "discountPersentage", discountPercentage, '')}
         </View>
 
         <View style={{ flex: 0.3, flexDirection: "row", justifyContent: 'center' }}>
           <Text style={{ fontSize: '1.2vw', paddingLeft: '1vw', }}>{"₹"}</Text>
           {this.renderTextBoxAlone("", false, "discountAmount", discountAmount, '')}
           
         </View>
       </View>
     )
   }
   _cancelBill = () => {
     var states = this.state
     states['paymentTotalAmount'] = 0;
     states['discountAmount'] = 0.00;
     states['discountPersentage'] = 0.00;
     states['roundOff'] = 0.00;
     states['amountPayable'] = 0;
     states['receviedAmount'] = 0;
     states['balanceAmount'] = 0;
     states['cardAmount'] = 0;
     states['cashAmount'] = 0;
     states['upiTransactionID'] = "";
     states['cardNumber'] = "";
     states["selectedPaymentType"] = null;
     this.setState({ states })
   }
   _complateBill = () => {
    let url = Constants.GET_CREDIT_BILL_LIST;
    if(this.state.discountPersentage>100){
      this.responseSuccessErrorMessage("Discount Percent or Discount amount cannot be greater than actual", false)
      setTimeout(() => {
        this.responseSuccessErrorMessage("", false)
      }, 3000)
      return;
    }
    var data = {
      "payment_mode": this.state.selectedPaymentType,
      "bill_type": "Credit",
      "bill_summary_id_list": this.state.selectedID,
      "recevied_amount": this.state.amountPayable,
      "card_amount": this.state.cardAmount,
      "cash_amount" : this.state.cashAmount,
      "card_number" : this.state.cardNumber,
      "round_off": parseFloat(this.state.roundOff).toFixed(2),
      "general_discount_percentage": this.state.discountPersentage,
      "general_discount": this.state.discountAmount,
      "total_bill_amount" : this.state.amountPayable,
      "insurance_company_name" : this.state.companyName ? this.state.companyName : "",
      "policy_number" : this.state.policyNumber, 
      "transaction_number": this.state.insuranceTransactionID

      
    }
    let cashCardAmount = (parseInt(this.state.cashAmount) + parseInt(this.state.cardAmount))

    if(this.state.paymentTotalAmount === 0){
      this.responseSuccessErrorMessage("Please select bills to proceed payment", false)
      setTimeout(() => {
        this.responseSuccessErrorMessage("", false)
      }, 3000)

    } else if(this.state.selectedPaymentType === null){
      this.responseSuccessErrorMessage("Please select the payment mode")
      setTimeout(() => {
        this.responseSuccessErrorMessage("", false)
      }, 3000)

    } else if( this.state.selectedPaymentType == 1 && this.state.receviedAmount != this.state.amountPayable){
      this.responseSuccessErrorMessage("Please enter correct received amount", false)
      setTimeout(() => {
        this.responseSuccessErrorMessage("", false)
      }, 3000)

    } else if(  this.state.selectedPaymentType == 3 && this.state.amountPayable != cashCardAmount){
      this.responseSuccessErrorMessage("Please enter correct received amount", false)
      setTimeout(() => {
        this.responseSuccessErrorMessage("", false)
      }, 3000)
    }else if(this.state.selectedPaymentType == 3 && (this.state.cardAmount < 0 || this.state.cashAmount < 0 )){
        this.responseSuccessErrorMessage("Please enter correct amount", false)
        setTimeout(() => {
          this.responseSuccessErrorMessage("", false)
        }, 3000)
    } else if( this.state.selectedPaymentType === 6 && this.state.companyName === "" ) {
      this.responseSuccessErrorMessage("Please enter insurance company name", false)
      setTimeout(() => {
        this.responseSuccessErrorMessage("", false)
      }, 3000)
    } else if( this.state.selectedPaymentType === 6 && this.state.policyNumber === "") {
      this.responseSuccessErrorMessage("Please enter insurance policy number", false)
      setTimeout(() => {
        this.responseSuccessErrorMessage("", false)
      }, 3000)
    }
     else {
      this.service.patchApiCall(
        url,
        data,
        this.successCallBack,
        this.errorCallBack
      )
    }
   }
   successCallBack = (response) => {

    if (response.status == "success") {
     
      this._getCreditBilltList()
      this.responseSuccessErrorMessage(response.message, true)

      setTimeout(() => {
        this.responseSuccessErrorMessage("", true)
      }, 3000)

      this._cancelBill()

    } else if (response.status == "fail") {

      this.responseSuccessErrorMessage(response.message, false)
      setTimeout(() => {
        this.responseSuccessErrorMessage("", false)
      }, 3000)

    }
  }
  errorCallBack = (response) => {

    this.responseSuccessErrorMessage(response.message, false)
    setTimeout(() => {
      this.responseSuccessErrorMessage("", false)
    }, 3000)

  }
   renderButtonView() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "center" }}>
        <TouchableOpacity
          onPress={()=>{
            this._complateBill(this)
          }}
          style={{ backgroundColor: color.themeDark, padding: '.7vw', marginLeft: '.7vw', borderRadius : 4 }} >
          <Text style={{ fontSize: '1vw', color: color.white, fontWeight: "700" }}>{"Payment Received"}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>{this.printCreditBillDetails()}}
          style={{ backgroundColor : color.themeDark,  padding: '.7vw', marginLeft: '.7vw', borderRadius : 4  }} >
          <Text style={{  fontSize: '1vw', color: color.white, fontWeight: "700",  borderRadius : 4  }}>{"Print"}</Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={()=>{this._cancelBill()}}
          style={{ backgroundColor : color.themeDark,  padding: '.7vw', marginLeft: '.7vw', borderRadius : 4  }} >
          <Text style={{  fontSize: '1vw', color: color.white, fontWeight: "700",  borderRadius : 4  }}>{"Cancel"}</Text>
        </TouchableOpacity>
      </View>
    )
  }
    renderRightSideView() {   
        return (
            <View style={ CreditStyle.rightSideContainer }>
                <Text style={CreditStyle.mainHeader}>{"Overall Bill Status"}</Text>
                <View style={CreditStyle.cardContainer}>
                    {this.renderCard("#9D358C", this.state.totalCompleteBillAmount, "Total Bill")}
                    {this.renderCard("#58B727", this.state.totalCompleteBillPaidAmount, "Total Paid")}
                    {this.renderCard("#EA3434", this.state.outStandingAmount ? this.state.outStandingAmount : 0, "Total Payable")}
                </View>
                {/* <View style={{ flex : 0.15}}>
                    <Text style={CreditStyle.mainHeader}>{"Bill Type"}</Text>
                    {this.renderCheckBoxView()}
                    {this.renderBillTypeDetails()}
                </View> */}
                <View style= {{ marginTop: '1.5vw', marginLeft: '.5vw' }}>
                  {this.renderBilling()}
                </View>
                <View style={{ marginTop: '1.5vw', marginLeft: '.5vw' }}>
                    <Text style={CreditStyle.mainHeader}>{"Payment Type"}</Text>
                    {this.renderPaymentType()}
                </View>
                <View style={{ marginTop: '1.5vw', marginLeft: '.5vw' }}>
                  {this.renderButtonView()}
                </View>
            </View>
        )
    }
    renderBillTypeTextBoxRow(label, key, value) {
        return (
          <View style={{ marginVertical: 8, marginRight: '.5vw' }}>
            <View style={{ flex: 0.4, justifyContent: "center" }}>
              <Text style={{ fontSize: '1vw', fontWeight: "500", marginBottom: 10 }}>{label}</Text>
            </View>
            <View>
              {key ?
                <TextInput
                  style={[CreditStyle.billTypeTextBox,{width: key == "companyName" ? '10vw' : '6vw'}]}
                  value={value}
                  onChangeText={(text) => {
                    if (key != "") {
                      var state = this.state;
                      state[key] = text;
                      this.setState({
                        state
                      })
                    }
                  }}
                /> : null
              }
            </View>
          </View>
        )
      }
    renderCheckBoxView() {
        return (
            <View style={{ flexDirection: "row", marginTop: 10 }}>
                {billType && billType.map((item) => {
                    return <View style={{ paddingHorizontal: 30, flexDirection: "row" }}>
                        <CheckBox value={item.value == this.state.selectedBillType} style={{ alignSelf: "center" }} onChange={() => {
                            if (item.value) {
                                this.setState({
                                    selectedBillType: item.value
                                })
                            }
                        }} />
                        <Text style={CreditStyle.billTypeLabel}>{item.label}</Text>
                    </View>
                })}
            </View>
        )
    }
    payableAmountCalculation() {
      var states = this.state
      let payableAmounts = 0
      payableAmounts = this.state.paymentTotalAmount
      if(this.state.discountAmount){
        payableAmounts = this.state.paymentTotalAmount - parseFloat(this.state.discountAmount)
      } 
      var roundOffAmount = payableAmounts % 1;
  
      if (parseFloat(roundOffAmount) >= 0.5) {
        states["roundOff"] = (1 - parseFloat(roundOffAmount))
      } else if (parseFloat(roundOffAmount) < 0.5) {
        states["roundOff"] = "-" + parseFloat(roundOffAmount)
      }
      this.setState({
        states,
        amountPayable: payableAmounts ? Math.round(payableAmounts) : 0

      })
    }
    onCheckBoxAll = () => {
      var selectedIDs = []
      let temp = this.state.creditBillList && this.state.creditBillList.length > 0 && this.state.creditBillList.map((item) => {
        if (this.state.isAllChecked ) {
          return { ...item, isSelected: true }
        } else {
          return { ...item, isSelected: false }
  
        }
  
      });
      this.setState({ creditBillList: temp },()=>{
        const totalBillAmount = this.state.creditBillList && this.state.creditBillList.length > 0 && this.state.creditBillList.map(item => this.state.isAllChecked ? parseFloat(item.grand_total) : null).reduce((prev, curr) => (prev + curr));
        this.payableAmountCalculation()
        this.calculateDiscountAmount()
        for( let i=0; i < this.state.creditBillList.length ; i++ ){
          if(this.state.creditBillList[i].isSelected){
           selectedIDs.push(this.state.creditBillList[i].id)
          }  
        }
        this.setState({
          paymentTotalAmount: totalBillAmount,
          selectedID : selectedIDs
        },()=>{
          this.payableAmountCalculation()
          this.calculateDiscountAmount()
        })
      
      });
    }
    printCreditBillDetails = () => {
      let status = this.state.isPendingBills ? 'Partially Saved' : 'Payment Received'

      let url = ""

      if(this.state.isPendingBills) {

        url = Constants.GET_CREDIT_BILL_LIST + "?patient_id=" + this.state.patientID + "&bill_status=" + status + "&export_type=pdf" ;

      } else {

        url = Constants.GET_CREDIT_BILL_LIST + "?bill_status=" + status + "&patient_id=" + this.state.patientID + "&invoice_number=" + this.state.billNumSearch + "&from_date=" +this.state.startTime + "&to_date=" + this.state.endTime + "&payment_mode=" + this.state.paymentTypeList + "&export_type=pdf"
        
      }
      this.service.getInstance().documentUploadGet(
        url,
        this.printCreditBillSuccess,
        this.printCreditBillFailure,
        "pdf"
      )
    }
    printCreditBillSuccess = (response) => {
      if (response) {
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    }
    printCreditBillFailure = (error) => {
      console.log(error);
    }
  renderHeaderView() {
    return (
      <View>
        <View style={{ margin: 10 }}>
          { this.state.selectedID.length > 0 ?<Text style={{ fontSize: '1vw', color: color.black }}>{"Selected Bills - "} {this.state.selectedID.length}</Text> : null }
        </View>
        <View style={{ backgroundColor: "#E2E2E2", flexDirection: 'row', flex: 1, padding: '0.8vw' }} >
          <View style={{ flex: 0.1 }}>
            <CheckBox style={CreditStyle.CheckBoxContainer} value={this.state.isAllChecked}
              onChange={() => {
                this.setState({ isAllChecked: !this.state.isAllChecked }, () => {
                  this.onCheckBoxAll()
                })
              }}
            /></View>
          <Text style={{ fontSize: '1vw', flex: 0.1 }}>{"S.No"}</Text>
          <Text style={{ fontSize: '1vw', flex: 0.2 }}>{"Credit Bill No"}</Text>
          <Text style={{ fontSize: '1vw', flex: 0.15 }}>{"Total Items"}</Text>
          <Text style={{ fontSize: '1vw', flex: 0.15 }}>{"Bill Date"}</Text>
          <Text style={{ fontSize: '1vw', flex: 0.2 }}>{"Bill Amount ( ₹ )"}</Text>
          <Text style={{ fontSize: '1vw', flex: 0.1 }}>{"Action"}</Text>
        </View>
      </View>
    )
  }
  calculateTotalBillAmount = () =>{
    var selectedIDs = []
    for( let i=0; i < this.state.creditBillList.length ; i++ ){ 
      if( this.state.creditBillList[i].isSelected ){
        selectedIDs.push(this.state.creditBillList[i].id)
      }
    }
    const totalBillAmount = this.state.creditBillList && this.state.creditBillList.length > 0 && this.state.creditBillList.map(item => item.isSelected ? parseFloat(item.grand_total) : null).reduce((prev, curr) => (prev + curr));
    this.setState({
       paymentTotalAmount: totalBillAmount,
       selectedID : selectedIDs

    },()=>{
      this.calculateDiscountAmount()
      this.payableAmountCalculation()
    }) 
  }
  onMultiCheck = (id, index) => {

    var { creditBillList } = this.state
    if (id === creditBillList[index].invoice_number && !this.state.isAllChecked) {
      creditBillList[index]["isSelected"] = !creditBillList[index]["isSelected"]
      this.setState({
        creditBillList
      },()=>{
       let checkTrue = creditBillList.map((item)=>item.isSelected)
       if(checkTrue.every((item)=>item)) {
          this.setState({
            isAllChecked: true
          })
        }
         this.calculateTotalBillAmount()
         this.payableAmountCalculation()
       })
      
    } else if (id === creditBillList[index].invoice_number && this.state.isAllChecked) {
      creditBillList[index]["isSelected"] = !creditBillList[index]["isSelected"]
      this.setState({
        creditBillList
      },()=>{
        this.payableAmountCalculation()
        this.calculateTotalBillAmount()

        if(creditBillList && creditBillList.length > 0 && this.state.isAllChecked) {
          let checkTrue = creditBillList.map((item)=>item.isSelected)
          if(checkTrue.includes(false)) {
            this.setState({
              isAllChecked: false
            })
          } 
        }
      })
    } 

  }
  _viewBill = (item) => {

    // let data = JSON.parse(item)

    this.props.viewBill(item)
}

onClickIndividualPrint = (id) => {
  let status = this.state.isPendingBills ? 'Partially Saved' : 'Payment Received'
  var url = Constants.GET_CREDIT_BILL_LIST + "?patient_id=" + this.state.patientID + "&id=" + id + "&bill_status=" + status +"&export_type=pdf" ;

  this.service.getInstance().documentUploadGet(
    url,
    this.onClickIndividualPrintSuccess,
    this.onClickIndividualPrintFailure,
    "pdf"
  )
}
onClickIndividualPrintSuccess = (response) => {
  if (response) {
    const file = new Blob(
      [response.data],
      { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}
onClickIndividualPrintFailure = (error) => {
  console.log(error);
}

  renderDataView() {
    return(
     <View>
      { this.state.creditBillList && this.state.creditBillList.length > 0 ? 
      <View>
        {this.state.creditBillList && this.state.creditBillList.length > 0 && this.state.creditBillList.map((item, index) => {
          var date = moment(item.invoice_date).format("DD-MM-YYYY")
          // let sno = (this.state.currentPage-1)*this.state.recordsPerPage + index + 1;

          return (

            <View
              style={{ flex: 1, flexDirection: "row", borderBottomWidth: 1, borderBottomColor: color.grayShade, paddingVertical: '1vw', marginLeft: 10 }}>
              <View style={{ flex: 0.1, fontSize: '1vw' }}>
                <CheckBox style={{ alignSelf: "center", width: '1.2vw', height:'1.2vw' }} value={item.isSelected}
                  onChange={() => {
                    this.onMultiCheck(item.invoice_number, index)
                    this.payableAmountCalculation()
                  }}
                /></View>
              <Text style={{ flex: 0.1, fontSize: '1vw' }}>{index+1}</Text>
              <Text style={{ flex: 0.2, fontSize: '1vw' }}>{item.invoice_number}</Text>
              <Text style={{ flex : 0.15, fontSize: '1vw' }}>{item.total_item}</Text>
              <Text style={{ flex: 0.15, fontSize: '1vw' }}>{date}</Text>
              <Text style={{ flex: 0.2, fontSize: '1vw' }}>{item.grand_total}</Text>
              <View style={{ flexDirection : "row", flex: 0.1, alignItems: "center" }}>
              <TouchableOpacity
                onPress={() => this.onClickIndividualPrint(item.id)}
                style={{ borderWidth: 1, borderColor: color.themeDark, borderRadius: 4, backgroundColor: color.themeDark, justifyContent: "center", alignContent: "center", padding: 3, marginRight: 10 }} >
                  <Icon name={"print"} size={'1.2vw'} color={color.white} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                this.setState({
                  isCloseBill: true,
                  summaryId: item.id

                })
              }} >
                <Icons name={"circle-with-cross"} size={'1.6vw'} color={color.black} />
              </TouchableOpacity>
              </View>
            </View>
           )
        })} 
      </View> : 
       <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: 150 }}>
       <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
     </View>}
      </View>
    )
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
       currentPage: current_page,
       recordsPerPage: records_per_page
    })
}
  renderPaginationView() {
    return (
      <View style={{ marginHorizontal: 20 }}>
        {
          this.state.completedCreditBill && this.state.completedCreditBill.length > 0 ?
            <Pagination
              paginationChangedValues={this.paginationChangedValues.bind(this)}
              totalClientItems={this.state.completedCreditBill}
              paginationCurrentPage = {this.paginationCurrentPage.bind(this)}
            /> : null
        }
      </View>
    )
  }
  paginationChangedValues(data) {
    this.setState({
      completeBillAfterPagination: data
    })
  }
  renderLeftSideView(){
      return(
        <View>
          {this.renderHeaderView()}
          {this.renderDataView()}
 
        </View>
      )
    }
    DeleteDoctorDetails = (id) => {
      var url = Constants.GET_CREDIT_BILL_LIST + "?bill_summary_id=" + id;
      var data = {
        "cancel_reason" : this.state.cancelReason,
        "bill_summary_id": id
      }
      if(this.state.cancelReason === "") {
        this.responseSuccessErrorMessage( "Please enter the reason for cancelling", false)
        setTimeout(() => {
          this.responseSuccessErrorMessage("", false)
        }, 3000)
      } else if(this.state.cancelReason.length < 10) {
        this.responseSuccessErrorMessage( "Please enter valid reason with 10 charactors", false)
        setTimeout(() => {
          this.responseSuccessErrorMessage("", false)
        }, 3000)
      } else { 
      this.service.putApiCall(
        url,
        data,
        this.deleteDetailsListSuccess,
        this.deleteDetailsListFailure
      )
      }
    }
    deleteDetailsListSuccess = response => {
      if (response.status == "success") {
        this.responseSuccessErrorMessage(response.message, true)
        setTimeout(() => {
          this.responseSuccessErrorMessage("", true)
        }, 3000)
        this.setState({ isCloseBill: false })
        this._getCreditBilltList()
      } else {
        this.responseSuccessErrorMessage(response.message, false)
        setTimeout(() => {
          this.responseSuccessErrorMessage("", false)
        }, 3000)
      }
    }
    deleteDetailsListFailure = response => {
       this.responseSuccessErrorMessage(response.message, false)
       setTimeout(() => {
        this.responseSuccessErrorMessage("", false)
      }, 3000)
    }
    renderCompletedBillHeader = () => {
      return(
        <View>
         <CommonReportSectionHeader
            heading1={"S.No"}
            heading2={"Invoice Number"}
            heading3={"Date"}
            heading4={"Amount"}
            heading5={"Payment Type"}
            heading6={"Action"}

            columnSize={[16.6, 16.6, 16.6, 16.6, 16.6, 16.6]}  //total value is == of 1
            noOfColumn={6}

          />
        </View>
      )
    }
    renderCompltedBillData = () => {

    }
    _showSelectedFilteredDate(date) {
      this.setState({
          startTime: date.fromDate,
          endTime: date.toDate,
          showDateFilter: false,
          // reset pagination
          current_page: 1,
          records_per_page: 10,

      },()=>{
          // this._doLoadInvoiceList()
      })
  }
  _disableDateFilter=(flag)=>{
        
    this.setState({
        showDateFilter: flag
    })
}
renderPaymentBtn = (img, title, payment_mode) => {
  return (
    <TouchableOpacity
      onPress={() => {
        
        var fields = this.state;
        var tempList = this.state.paymentTypeList;
        if( tempList.indexOf(payment_mode) > -1 ){
          tempList.splice(tempList.indexOf(payment_mode) , 1 ) 
        }else{
          if( tempList.length > 2 )
            tempList.splice(0 , 1 )  
          tempList.push(payment_mode)      
        }

        fields["paymentTypeList"] = tempList
        this.setState({ fields }) 

        //    this.updateState("payment_type2", title);    

      }}
    >
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <Image
          source={img}
          style={{ width: '2.3vw', height: '2.3vw', tintColor:  (this.state.paymentTypeList.indexOf(payment_mode) > -1) ? color.themeDark : "black" }}
          resizeMode="contain"
        />
        <Text style={{ color: (this.state.paymentTypeList.indexOf(payment_mode) > -1) ? color.themeDark : "black", marginTop: 5, fontSize: '1vw' }}>
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
_searchFilter = () => {

}
renderPaymentFilter =() => {
 
  return(
    <View
    style={{
      flexDirection: "row",
      marginLeft: 10,
      marginBottom: 20,
      marginTop: 20,
      justifyContent: "space-between",
      alignItems: "center",
      width: "90%",
    }}
  >
    {this.renderPaymentBtn(
      require("../../assets/images/Cash.png"),
      "Cash", 1
    )}  
    {this.renderPaymentBtn(
      require("../../assets/images/Card.png"),
      "Card", 2
    )}
   
    {this.renderPaymentBtn(
      require("../../assets/images/Card.png") ,
      // "Card & Cash"
      "Cash and Card", 3
    )}
    {this.renderPaymentBtn(
      require("../../assets/images/upi.png"),
      // "Card & Cash"
      "UPI", 4
    )}
    {this.renderPaymentBtn(
      require("../../assets/images/bank-transfer.png"),
      // "Card & Cash"
      "Bank Transfer", 5
    )}
   {this.renderPaymentBtn(
     require("../../assets/images/Insurance.png"),
      "Insurance", 6
    )}
  </View>
  )
 }
 renderBottomView = () => {
  return(
    <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "1.5vw" }}>
        <ActionButton label={"Search"} keyIndex={"searchFilter"} onPressAction={this._getCreditBilltList.bind(this)}/>
        <ActionButton label={"Print"} keyIndex={"searchFilter"} onPressAction={this.printCreditBillDetails.bind(this)}/>
        <ActionButton label={"Clear"} keyIndex={"searchFilter"} onPressAction={this.renderFilterClear.bind(this)}/>

    </View>
  )
 }
 renderFilterOptions = () => {
      return(
      <View style={{ flexDirection: "column"}}>  
       <View style={{ flexDirection: "row" }}>
        <View style={[CreditStyle.billNumSearch]}>
        <TextInput
            placeholder={"Search Invoice Number"}
            underlineColorAndroid="transparent"
            style={CreditStyle.billTextInput}
            placeholderTextColor={color.lightGray}
            autoCapitalize="none"
            value={this.state.billNumSearch}
            onChangeText={text => {
              this.setState({
                billNumSearch: text
              })
            }}
        /> 
       </View> 
       <View style={{ zIndex: 1, marginTop: '1.5vw'}}>
     { this.state.isClear && this.state.isClearInt === 0 ? null :
      this.state.isClear && this.state.isClearInt === 1 ?
        <CommonDateFilter
            startDate={this.state.startTime}
            endDate={this.state.endTime}
            filterDate={this.state.filterDate}
            showSelectedFilteredDate={this._showSelectedFilteredDate.bind(this)}
            defaultSelectedDateFilter={"All"}
            // on outside focus, close popup
            showDateFilter={this.state.showDateFilter}
            disableDateFilter={this._disableDateFilter}
         /> : 
         <CommonDateFilter
         startDate={this.state.startTime}
         endDate={this.state.endTime}
         filterDate={this.state.filterDate}
         showSelectedFilteredDate={this._showSelectedFilteredDate.bind(this)}
         defaultSelectedDateFilter={"All"}
         // on outside focus, close popup
         showDateFilter={this.state.showDateFilter}
         disableDateFilter={this._disableDateFilter}
      />}
       </View> 
        </View>
        <View style={{ marginTop: '1.5vw', zIndex: -1 }}>
        <Text style={CreditStyle.mainHeader}>{"Payment type"}</Text>
           {this.renderPaymentFilter()}
         </View>
         <View style={{ marginTop: '1.5vw'}}>
           { this.renderBottomView() }
         </View>

       </View> 
       
      )
    }
    renderCompletedBillRightView = () => {
      return(
        <View style={ [CreditStyle.rightSideContainer, {flex: 1} ]}>
        <Text style={[CreditStyle.mainHeader]}>{"Overall Bill Status"}</Text>
        <View style={[CreditStyle.cardContainer]}>
            {this.renderCard("#9D358C", this.state.totalBillAmount ? this.state.totalBillAmount : 0, "Total Bill")}
            {this.renderCard("#58B727", this.state.totalPaidAmount ? this.state.totalPaidAmount : 0, "Total Paid")}
            {this.renderCard("#EA3434", this.state.totalPayableAmount ? this.state.totalPayableAmount : 0, "Total Payable")}
        </View>
        <View style={{ marginTop: '1.5vw' }}>
        {this.renderFilterOptions()}
       </View>
       </View> 
      
      )
    }
    renderCompletedBillList = () => {
      return(
     <View> 
      { this.state.completeBillAfterPagination && this.state.completeBillAfterPagination.length > 0 ?
      <View>  
        {this.state.completeBillAfterPagination && this.state.completeBillAfterPagination.length > 0 && this.state.completeBillAfterPagination.map((item, index) => {
          let sno = (this.state.currentPage-1)*this.state.recordsPerPage + index + 1;
        return(
        <View style={{ flex: 1, flexDirection: "row", borderBottomWidth: 1, borderBottomColor: color.grayShade, paddingVertical: '1vw', marginLeft: 10 }}>
              <Text style={{ flex: 16.6, fontSize: '1vw', marginLeft: 20 }}>{sno}</Text>
              <Text style={{ flex: 16.6, fontSize: '1vw' }}>{item.invoice_number}</Text>
              <Text style={{ flex : 16.6, fontSize: '1vw' }}>{item.invoice_date}</Text>
              <Text style={{ flex: 16.6, fontSize: '1vw' }}>{ item.received_amount ? parseFloat(item.received_amount).toFixed(2) : 0}</Text>
              <Text style={{ flex: 16.6, fontSize: '1vw'}}>{item.payment_mode}</Text>
              <View style={{ flexDirection : "row", flex: 16.6, alignItems: "center" }}>
              <TouchableOpacity
                onPress={() => this.onClickIndividualPrint(item.id)}
                style={{ borderWidth: 1, borderColor: color.themeDark, borderRadius: 4, backgroundColor: color.themeDark, justifyContent: "center", alignContent: "center", padding: 3, marginRight: 10 }} >
                  <Icon name={"print"} size={'1.2vw'} color={color.white} />
              </TouchableOpacity>
              </View>
        </View>)} )} 
      </View> : 
      <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: 150 }}>
       <Text style={{ color: "#8888888", fontSize: '1vw' }}>{"No records to be shown"}</Text>
      </View>}
      </View>  
      )
    }
    renderFilterClear = () => {
      var states = this.state
      states["paymentTypeList"] = [];
      states["billNumSearch"] = ""
      states["filterDate"] = "";
      states["startTime"] = "";
      states["endTime"] = "";
      this.setState({
        states,
        isClear: true
      },() => {
        this.setState({
          isClearInt: 1,
        },()=>{
          this.renderFilterOptions()
          this._getCreditBilltList()
        })
      })
      
    }
  render() {
    let { showResponse, showErrorResponse, responseMessage } = this.state
    return (
     <View>
        <View style={{ flexDirection: "row", marginTop: 10, width: "65%", flex: 1, margin: 5 }}>
          <TouchableOpacity onPress={() => {
            this.props.changeScreen("creditHome")
            this.setState({
              paymentTypeList: [],
              billNumSearch: "",
              
            })
          }} style={{ backgroundColor: color.themeBackgroud, width: '5.1vw', height: '2vw', borderRadius: 5, justifyContent: "center", alignItems: "center", margin: 8, flex: 0.1, }}>
            <View style={{ flexDirection: "row", justifyContent: "center" }}>
              <Image
                style={CreditStyle.BackIcon}
                source={require('../../assets/images/Return.png')}
              />
              <Text style={{ color: "#ffffff", fontSize: '1vw' }}>{"Back"}</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
           onPress={() => {
            if (this.state.originalCreditBillList && this.state.originalCreditBillList.length > 0) { }
            this.setState({
              isPendingBills: true
            }, () => {
              this._getCreditBilltList()
            })
          }} style={{ paddingHorizontal: 5, justifyContent: "center", flex: 0.15 }}>
            <Text style={[CreditStyle.CreditBillHead, { color: this.state.isPendingBills ? color.themeDark : color.black }]}>{"Pending Bills"}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => {
            this.setState({
              isPendingBills: false
            }, () => {
              this._getCreditBilltList()
            })
          }} style={{ paddingHorizontal: 5, justifyContent: "center", flex: 0.8}}>
            <Text style={[CreditStyle.CreditBillHead, { color: !this.state.isPendingBills ? color.themeDark : color.black }]}>{"Completed Bills"}</Text>
          </TouchableOpacity>
      </View>
      { this.state.isPendingBills ?
      <View style = {{ flexDirection : "row" }}>  
       <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={CreditStyle.leftSideView} bounces={false} bouncesZoom={false} >
          {this.renderLeftSideView()}
       </ScrollView>
       <View style={{  position :"absolute", width : "100%", flex : 1, top:screenHeight-65, zIndex:4 }}>
              {
                showResponse ?
                  <SuccessAlert message={responseMessage} /> : null
              }
              {
                showErrorResponse ?
                  <ErrorAlert message={responseMessage} /> : null
              }
       </View>
       <ScrollView showsVerticalScrollIndicator={false}  showsHorizontalScrollIndicator={false} style={CreditStyle.rightSideView} >
          {this.renderRightSideView()}
       </ScrollView>
       {this.state.isCloseBill ?
          <View style={{ justifyContent: "center", alignItems: "center", height: '100vh', width: "100vw", backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute' }}>
            <View style={{  backgroundColor: color.white, borderRadius: 4, padding: '1vw', width: "30vw"}}>
            <Text style={{ color: "#00b4ae", fontSize: '1.3vw', fontWeight: 'bold' }}>Reason</Text>
                <View style={{ paddingTop: 10 }}>
                  <TextInput
                    style={{ border: '1px solid #e3e5e6', width: '25vw', height: '8vh', paddingTop: 10, padding: 5, fontSize: '1vw' }}
                    // maxLength={20}
                    multiline = {true}
                    onChangeText={(text) => { this.setState({ cancelReason : text })}}
                  />

                </View>
                <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "center", height: "5vh", marginTop : 10 }}>
                <TouchableOpacity
                  style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, padding: 8, width: "5vw" }}
                  onPress={() => {
                    this.DeleteDoctorDetails(this.state.summaryId), () => {
                    }
                  }}>
                  <Text style={{ color: 'white', fontSize: '1vw', }}>{"Ok"}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, padding: 8, marginLeft: 10 }}
                  onPress={() => { this.setState({ isCloseBill: false }) }}>
                  <Text style={{ color: 'white', fontSize: '1vw', }}>{"Cancel"}</Text>
                </TouchableOpacity>
              </View> 
            </View>
          </View> : null}
      </View> :

      <View style= {{ flexDirection: "row" }}>
        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={CreditStyle.leftSideView} bounces={false} bouncesZoom={false} >
          {this.renderCompletedBillHeader()}
          {this.renderCompletedBillList()}
          {this.renderPaginationView()}
        </ScrollView>
        <ScrollView showsVerticalScrollIndicator={false}  showsHorizontalScrollIndicator={false} style={CreditStyle.rightSideView} >
          {this.renderCompletedBillRightView()}
        </ScrollView>
      </View> }

    </View> 
    );
  }
  responseSuccessErrorMessage(message, type) {
    if (message != "") {
      if (type) {
        this.setState({
          showResponse: true,
          responseMessage: message,
          showErrorResponse: false
        })
      } else {
        this.setState({
          showResponse: false,
          showErrorResponse: true,
          responseMessage: message
        })
      }
    } else {
      this.setState({
        showResponse: false,
        showErrorResponse: false,
        responseMessage : ""
      })
    }
  }
  numberValidation(num) {
    return !/[^.[0-9]]*/.test(num);
  }
  renderPaymentTypeTextBoxRow(label, key, value) {
    return (
      <View style={CreditStyle.paymentTextBox}>
        <View style={CreditStyle.paymentTextView}>
          <Text style={CreditStyle.paymentText}>{label}</Text>
        </View>
        <View>
          {
            key ?
              <TextInput
                style={[CreditStyle.paymentTextInput, { width: key === "companyName" ? '13vw' : '6vw' }]}
                value={value}
                maxLength={key == "cardNumber" ? 4 : null}
                onChangeText={(text) => {
                  if (key == "upiTransactionID" && this.state.selectedPaymentType != 6) {
                    var state = this.state;
                    state[key] = text;
                    this.setState({
                      state
                    })
                  } else if (this.numberValidation(text)) {
                    if (text === "") {
                      text = 0
                      if (key === "cashAmount") {
                        this.setState({
                          paymentTypeCardAmount: this.state.paymentTotalAmount
                        })
                      }
                      else if (key === "cardAmount") {
                        this.setState({
                          paymentTypeCashAmount: this.state.paymentTotalAmount
                        })
                      }
                    }
                    var states = this.state;
                    states[key] = text;
                    this.setState({ states }, () => {
                      if (this.state.selectedPaymentType == 1 && this.state.receviedAmount && this.state.selectedPaymentType != 3) {
                        var states = this.state.amountPayable - this.state.receviedAmount
                        this.setState({
                          cashAmount: parseFloat(this.state.receviedAmount),
                          balanceAmount: states
                        })
                      } else if (this.state.selectedPaymentType == 2 && this.state.selectedPaymentType != 3) {

                        this.setState({
                          cardAmount: parseFloat(this.state.amountPayable)
                        })
                      } else if (this.state.selectedPaymentType == 3) {
                        if (key === "cashAmount") {
                          var cardAmount = parseFloat(this.state.amountPayable) - parseFloat(this.state.cashAmount)
                          this.setState({
                            cardAmount: cardAmount
                          })
                        }
                        else if (key === "cardAmount") {
                          var cashAmount = parseFloat(this.state.amountPayable) - parseFloat(this.state.cashAmount)
                          this.setState({
                            cashAmount: cashAmount,
                          })
                        } 
                      }
                    })
                  } else if(this.state.selectedPaymentType === 6){
                    var state=this.state
                    state[key] = text ? text : "";
                    this.setState({
                      state
                    })
                  }
                }}
              />
              : null
          }

        </View>

      </View>
    )
  }
}
const CreditStyle = StyleSheet.create({
   BackIcon: {
    width: '1.5vw',
    height: '1vw',
    marginTop: '.2vw'
   },
    leftSideView : {
        width: "65%", height: screenHeight - 65, backgroundColor: color.white, zIndex: -1 ,paddingHorizontal:15,paddingVertical:10
    },
    rightSideView : {
        width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade, paddingVertical : 10
    },
    rightSideContainer : {
        flexDirection: "column", flex: 1, height: screenHeight, position: "absolute", width: "90%"
    },
    mainHeader : { fontSize: '1.2vw', fontWeight: '500', marginTop: '1.5vw', marginLeft: '.5vw' },
    creditHeader : { fontSize: 18, fontWeight: '500', color: color.themeDark, marginLeft: 20 },

    cardContainer : { flexDirection: "row", marginTop: '1.5vw', flexWrap: "wrap", justifyContent: "space-between", marginLeft: '.5vw' },
    cardMain : { marginRight: 5, paddingLeft: 5, flexDirection: "row", justifyContent: "space-between", minWidth: '8.5vw' },
    cardPlacehold : { color: "white", fontSize: '0.8vw', marginLeft: 10 },
    billTypeLabel : { fontSize: 12, marginLeft: 10 },
    billTypeTextBox: { textAlign: 'center', height: '2vw', width: '6vw', borderRadius: 4, borderWidth: 1, borderColor: "#91939F", fontSize: '1vw' },
    paymentView: { flexDirection: "row", paddingHorizontal: 30, marginTop: 20 },
    paymentTextBox: { marginVertical: 8, marginRight: '.5vw' },
    paymentTextView: { flex: 0.4, justifyContent: "center" },
    paymentText: { fontSize: '1vw', fontWeight: "500", marginBottom: 10 },
      paymentTextInput: { textAlign: 'center', height: '2vw', borderRadius: 4, borderWidth: 1, borderColor: "#91939F", fontSize: '1vw' },
    // billTypeTextBox: { textAlign: 'center', height: 28, width: 90, borderRadius: 4, borderWidth: 1, borderColor: "#91939F" },
    billNumSearch :{
      borderColor: color.lightGray, borderWidth: 1, width: "14vw",  height: '2.5vw', borderWidth: 1, borderRadius: '.7vw', alignItems: "center", padding: '.5vw', zIndex: -1, marginTop: '1.5vw' },
    billTextInput:{
      color: '#000', fontSize: '1vw', marginLeft: '1.8vw'
  },
  CreditBillHead: {fontSize: '1.2vw', fontWeight: "500"},
  CheckBoxContainer: { alignSelf: "center", width: '1.2vw', height: '1.2vw' },
  cardText: {fontSize: '1vw', fontWeight:"bold", color: "white"}
})
