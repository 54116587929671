import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

export default class PurchaseOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salesReportList: [],
            startTime: converted_date,
            endTime: converted_date,
            billingListAfterPagination: [],
            // for pagination
            current_page: 1,
            records_per_page: 10,
            // for pagination, from get data
            total_count: 0,
            no_of_pages: 0
        };

        this.service = new Service();
        this._paginationChangedValues = this._paginationChangedValues.bind(this) 
    }

    componentDidMount() {
        this._getPurchaseOverView()
    }

    componentWillReceiveProps(props) {
        
        if (props.isclear && (props.startTime!=this.state.startTime || props.endTime!=this.state.endTime)) {
            this.setState({
                startTime: props.startTime,
                endTime: props.endTime,
                current_page: 1,
                records_per_page: 10
            }, () => {
                this._getPurchaseOverView()
                props.handlepropsvalueclear(false)

            })
        }
    }

    // Get Api 
    _getPurchaseOverView = () => {

        var url = ""
        let { current_page, records_per_page } = this.state;

        if (this.state.startTime && this.state.endTime) {

            url = Constants.GET_PURCHASE_OVERVIEW + "?from_date=" + this.state.startTime + "&to_date=" + this.state.endTime+ "&page="+current_page+"&page_size="+records_per_page;
        } else {
            url = Constants.GET_PURCHASE_OVERVIEW + "?page="+current_page+"&page_size="+records_per_page;

        }

        this.service.getApiCall(
            url,
            this._getPurchaseOverViewSuccess,
            this._getPurchaseOverViewFailure
        )
    }

    _getPurchaseOverViewSuccess = response => {

        let { salesReportList } = this.state;

        if (response.status === "success") {

            salesReportList = response.data.result
            this.setState({
                salesReportList,
                no_of_pages: response.data?response.data.no_of_pages:0,
                total_count: response.data?response.data.total_count:0
            },()=>{
                var data ={
                    "total_amount":response.data.total_amount ,
                    "total_gst_amount": response.data.total_gst_amount,
                    "total_discount_amount": response.data.total_discount_amount,
                    "total_net_total": response.data.total_net_total,
                    }
                this.props.gettotalscoreValues("Purchase Overview", data)

            })
        } else{
            this.props.gettotalscoreValues("Purchase Overview", "") 
        }
    };

    _getPurchaseOverViewFailure = error => {
        this.props.gettotalscoreValues("Purchase Overview", "")

        console.log(error);

    };
    // Report List Views Function
    renderContent() {
        return (
            // Report List Views
            <View style={{ marginHorizontal: 20, backgroundColor: "#F9F9F9", marginTop: 15 }}>
                {this.renderContentRow()}



                {/* End Report List View */}

            </View>
        )
    }
    // Common History Section Header function
    renderHeader() {
        return (

            <View style={styles.TableHead}>
                <CommonReportSectionHeader
                    heading1={"S.No"}
                    heading2={"Date"}
                    heading3={"Invoice No"}
                    heading4={"Vendor Name"}
                    heading5={"GST No"}
                    heading6={"Amount"}
                    heading7={"GST Amount"}
                    heading8={"DIS AMT"}
                    heading9={"Net AMT"}
                    heading10={"EPR Value"}

                    columnSize={[0.08, 0.10, 0.10, 0.15, 0.15, 0.08, 0.08, 0.08, 0.10, 0.08]}  //total value is == of 1
                    noOfColumn={10}

                />
            </View>
        )
    }


    // Render Content Row function Key and 
    renderContentRow() {
        if (this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.length < 11) {

            return (
                <View style={{}}>
                    {
                        this.state.salesReportList && this.state.salesReportList.map((item, index) => {
                            let stock_entered_date = item.stock_entered_date?moment(item.stock_entered_date).format("DD-MM-YYYY"):""
                            return (
                                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0 }}>
                                    <View style={[{ flex: 0.11, marginLeft: 20 }]}><Text style={{}}>{index + 1}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{stock_entered_date}</Text></View>
                                    <View style={[[{ flex: 0.11 }]]}><Text>{item.invoice_number}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.vendor_name}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.vendor_gstin_number}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.amount}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.gst_amount}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.discount_amount}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.total_buying_price}</Text></View>
                                </View>
                            )
                        })}



                </View>
            )
        }
        else if (this.state.salesReportList && this.state.salesReportList.length > 0) {

            return (
                <View style={{}}>
                    {
                        this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
                            let stock_entered_date = item.stock_entered_date?moment(item.stock_entered_date).format("DD-MM-YYYY"):""
                            return (
                                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0 }}>
                                    <View style={[{ flex: 0.11 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{stock_entered_date}</Text></View>
                                    <View style={[[{ flex: 0.11 }]]}><Text>{item.invoice_number}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.vendor_name}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.vendor_gstin_number}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.amount}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.gst_amount}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.discount_amount}</Text></View>
                                    <View style={[{ flex: 0.11 }]}><Text>{item.total_buying_price}</Text></View>
                                </View>
                            )
                        })}



                </View>
            )
        }
        else{
            return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100, alignItems: 'center' }}>
            <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
          </View>
        }
    }

    paginationChangedValues(data) {
        this.setState({
            billingListAfterPagination: data
        })
    }

    _paginationChangedValues(next_page, records_per_page_change) {

        let { current_page, records_per_page } = this.state
    
        if( current_page != next_page  || records_per_page!=records_per_page_change) {
            
            this.setState({
                records_per_page: records_per_page_change,
                current_page: next_page
            }, ()=>{
                this._getPurchaseOverView()
            })
        }
      }

    render() {
        let { total_count, no_of_pages } = this.state
        total_count = parseInt(total_count) || 0
        return (
            <View>
                {this.renderHeader()}
                {this.renderPaginateContent()}
                {
                total_count > 0 ?
                <View style={{ marginLeft: 20 }}>
                    <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
                    type={Constants.PAGINATION_TYPE_SERVER} 
                    totalServerRecordsCount={total_count} 
                    totalServerPagesCount={no_of_pages}/></View>
                    : null
                }
            </View>
        );
    }

    renderPaginateContent() { 

        let { current_page, records_per_page } = this.state
    
        if(this.state.salesReportList && this.state.salesReportList.length > 0) {
          return (
            // Report List Views
            <View style={styles.TableContent}>   
              {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
                  let sno = (current_page-1)*records_per_page + index + 1;
                  let bill_date = item.bill_date ? moment(item.bill_date).format("DD-MM-YYYY"):""
                  return (
                    <View key={sno} style={styles.TableContentView}>
                        <View style={{ flex: 0.08 ,marginLeft: '1.30vw' }}><Text style={styles.TableContentText}>{sno}</Text></View>
                        <View style={{ flex: 0.10 }}><Text style={styles.TableContentText}>{bill_date}</Text></View>
                        <View style={{ flex: 0.10 }}><Text style={styles.TableContentText}>{item.invoice_number}</Text></View>
                        <View style={{ flex: 0.15 }}><Text style={styles.TableContentText}>{item.vendor_name}</Text></View>
                        <View style={{ flex: 0.15 }}><Text style={styles.TableContentText}>{item.vendor_gstin_number}</Text></View>
                        <View style={{ flex: 0.08 }}><Text style={styles.TableContentText}>{item.amount}</Text></View>
                        <View style={{ flex: 0.08 }}><Text style={styles.TableContentText}>{item.gst_amount}</Text></View>
                        <View style={{ flex: 0.08 }}><Text style={styles.TableContentText}>{item.discount_amount}</Text></View>
                        <View style={{ flex: 0.10 }}><Text style={styles.TableContentText}>{item.total_buying_price}</Text></View>
                        <View style={{ flex: 0.08 }}><Text>{item.epr_value}</Text></View>
                    </View>
                  )
    
                })
              }
            </View>
          )
        }else{
          return(
            <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: 150 }}>
              <Text style={styles.NoRecord}>{"No records to be shown"}</Text>
            </View>
          )
        }
      }
}
const styles = StyleSheet.create({
    TableContent: { 
        marginHorizontal: '1vw', 
        backgroundColor: "#F9F9F9", 
        marginTop: '1vw' 
    },
    TableContentView: { 
        flexDirection: 'row', 
        borderBottomColor: "#888888", 
        borderBottomWidth: 1, 
        paddingVertical: '1.2vw', 
        marginHorizontal: 0 
    },
    TableContentLabel: { flex: 0.11 },
    TableContentText: {fontSize: '1vw'},
    TableHead:{ marginHorizontal: '1.4vw', marginTop: 10 },
    NoRecord: { color: "#8888888", fontSize: '1vw' }
})
