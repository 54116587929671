// #E2E2E2    content BG
// #F6F6F6      create Button Color
// #EEF1F7      create btn border
// #A1ABC4      txt color
// #C3C5D1
// green color  #04c6bf

import { Dimensions, Platform, StyleSheet } from 'react-native';
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
const platform = Platform.OS;

var themePrimaryColor = "#04c6bf";
var themeBorderColor = "#CDD1D5"

export default StyleSheet.create({
    loginPage: {
        flexDirection: 'row',
        flex: 1,
        zIndex: -1
    },
    loginPageLeftView: {
        flex: 0.55,
        // backgroundColor: themeBorderColor //border color
    },
    loginPageRightView: {
        flex: 0.37,
        backgroundColor: "white", //border color,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    loginHeader: {
        fontSize: '2vw',
        fontWeight: '700',
        marginBottom: 30
    },
    loginContent: {
        width: "85%",
        fontSize: 12,
        marginBottom: 50
    },
    loginPageTextBox: {
        // borderColor: themeBorderColor,
        // borderWidth:1,
        // borderRadius:10,
        // width: "70%",
        height: '8vh',
        // marginBottom:20,

        borderTopColor: themeBorderColor,
        borderBottomColor: themeBorderColor,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRightColor: themeBorderColor,
        borderRightWidth: themeBorderColor,
        borderRightWidth: 1,
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
        paddingLeft: 20
    },



    loginPageTextBoxRightIcon: {
        // borderColor: themeBorderColor,
        // borderWidth:1,
        // borderRadius:10,
        // width: "61%",
        height: '8vh',
        // marginBottom:20,

        borderTopColor: themeBorderColor,
        borderBottomColor: themeBorderColor,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        // borderRightColor: themeBorderColor,
        // borderRightWidth: themeBorderColor,
        // borderRightWidth: 1,
        // borderBottomRightRadius: 10,
        // borderTopRightRadius: 10,
        paddingLeft: 20
    },




    inputPutFieldIcons: {
        borderLeftColor: themeBorderColor,
        borderTopColor: themeBorderColor,
        borderBottomColor: themeBorderColor,
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
        height: '8vh',
        justifyContent: 'center'

    },

    inputPutFieldRightIcons: {
        borderRightColor: themeBorderColor,
        borderTopColor: themeBorderColor,
        borderBottomColor: themeBorderColor,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
        height: '8vh',

        paddingRight:15

    },

    loginPageBackgroundImage: {
        width: '50vw',
        height: '100vh',
        resizeMode: 'cover',
    },

    rememberMe_User_Password_Icon: {
        marginLeft: 10,
        color: "grey",
    },
    rememberMeText: {
        color: "grey",
        marginLeft: 15,
        fontSize: '1vw'
    },
    rememberAndForgotPasswordView: {
        width: "28vw",
        // marginTop:1,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems : 'center'
    },
    forgotPasswordText: {
        color: "grey",
        fontSize: '1vw',
    },
    signinView: {
        width: "100%",
        marginTop: 40, 
    },
    singinTouchableView: {
        alignSelf: 'center',
        backgroundColor: themePrimaryColor,
        borderRadius: 30,
        paddingTop: "0.7vw",
        paddingBottom: '0.7vw',
        paddingLeft: '3.2vw',
        paddingRight: '3.2vw'
    },
    signinText: {
        color: "white",
        fontSize: '1vw'
    },
    errorMessageView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5
    },
    errorMessageText: {
        color: "red",
        fontSize: '1vw',
        marginLeft: 15
    },
    exclamationIcon: {
        color: 'red',
        marginLeft: 10,
        fontSize: '1.5vw'
    },
    LoginPageProductName: {
        fontSize: '2vw', 
        color: '#04c6bf', 
        fontWeight: 'bold', 
        marginLeft: 70, 
        position: 'absolute', 
        marginTop: 30
    },
    LoginPageTextBoxContainer: { 
        marginBottom: 20, 
        width: '30vw'
     }
})