import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';
import { ScrollView } from 'react-native-web';
import { Tooltip } from 'antd';

export default class StockOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReportList: [],
      startTime: "",
      endTime: "",
      billingListAfterPagination: [],
      delivery_date: moment(props.delivery_date).format("YYYY-MM-DD") ,
      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0
    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 

  }

  componentDidMount() {
    this._getPurchaseDetailed()
  }

  componentWillReceiveProps(props) {
    if(this.state.delivery_date!=moment(props.delivery_date).format("YYYY-MM-DD")){
      this.setState({delivery_date: moment(props.delivery_date).format("YYYY-MM-DD")},
       () => this._getPurchaseDetailed(),
      props.handlepropsvalueclear(false))
      
    }
  }

  // Get Api 
  _getPurchaseDetailed = () => {

    let url = ""
    let { current_page, records_per_page, delivery_date } = this.state;

    let filteredDate = "";
    if(delivery_date === "" || delivery_date=== "Invalid date"){
      filteredDate = ""
    }else{
      filteredDate = delivery_date;
    }

    url = Constants.GET_STOCKS_REPORT + "?page="+current_page+"&page_size="+records_per_page + "&delivery_date="+ filteredDate;


    this.service.getApiCall(
      url,
      this._getPurchaseDetailedSuccess,
      this._getPurchaseDetailedFailure
    )



  }

  _getPurchaseDetailedSuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data.result

      this.setState({
        salesReportList,
        no_of_pages: response.data?response.data.no_of_pages:0,
        total_count: response.data?response.data.total_count:0
      }, () => {
        this.props.gettotalscoreValues("Stock Report", "")

      })
    }else{
      this.props.gettotalscoreValues("Stock Report", "")

    }
  };

  _getPurchaseDetailedFailure = error => {
    this.props.gettotalscoreValues("Stock Report", "")

    console.log(error);

  };
  // Report List Views Function
  renderContent() {
    return (
      // Report List Views
      <View style={styles.renderContentRow}>
        {this.renderContentRow()}



        {/* End Report List View */}

      </View>
    )
  }
  // Common History Section Header function
  renderHeader() {
    return (

      <View style={{width: '20vw'}}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Brand Name"}
          columnSize={[0.3, 0.7]}  //total value is == of 1
          noOfColumn={2}
          fontSize={"1vw"}
        />
      </View>
    )
  }
  renderScrollHeader() {
    return (

      <View style={{width: '80vw'}}>
        <CommonReportSectionHeader
          heading1={"Generic Name"}
          heading2={"Dosage Type"}
          heading3={"Dosage Strength"}
          heading4={"Batch No"}
          heading5={"Expiry Date"}
          heading6={"Schedule"}
          heading7={"Group"}
          heading8={"Purchase Qty"}
          heading9={"MRP / Qty"}
          heading10={"Price"}
          heading11={"Box No"}
          heading12={"EPR Value"}
          columnSize={[1.5, 1, 1, 0.633, 0.833, 0.833, 0.633, 0.9, 0.9, 0.9, 0.566, 0.833]}  //total value is == of 1
          noOfColumn={12}

        />
      </View>
    )
  }


  // Render Content Row function Key and 
  renderContentRow() {
    if (this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.length < 11) {

      return (
        <View>
          {
            this.state.salesReportList && this.state.salesReportList.map((item, index) => {

              let mrp_per_quantity = item.mrp_per_quantity?(parseFloat(item.mrp_per_quantity)).toFixed(2):"0.00"

              return (
                <View style={styles.bodyContainer}>
                  <View style={[{ flex: 0.2 }]}><Text style={[styles.bodyLabel,{ marginLeft: "1.30vw" }]}>{index + 1}</Text></View>
                  <View style={[{ flex: 0.9 }]}><Text style={styles.bodyLabel}>{item.brand_name}</Text></View>
                  <View style={[{ flex: 0.95 }]}><Text style={styles.bodyLabel}>{item.generic_name}</Text></View>
                  <View style={[{ flex: 0.9 }]}><Text style={styles.bodyLabel}>{item.dosage_type}</Text></View>
                  <View style={[{ flex: 0.833 }]}><Text style={styles.bodyLabel}>{item.dosage_strength}</Text></View>
                  <View style={[{ flex: 0.833 }]}><Text style={styles.bodyLabel}>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.9 }]}><Text style={styles.bodyLabel}>{item.expiry_date}</Text></View>
                  <View style={[{ flex: 0.833 }]}><Text style={styles.bodyLabel}>{item.schedule}</Text></View>
                  <View style={[{ flex: 0.533 }]}><Text style={styles.bodyLabel}>{item.group}</Text></View>
                  <View style={[{ flex: 0.8 }]}><Text style={styles.bodyLabel}>{item.available_stock}</Text></View>
                  <View style={[{ flex: 0.5 }]}><Text style={styles.bodyLabel}>{mrp_per_quantity}</Text></View>
                  <View style={[{ flex: 0.7 }]}><Text style={styles.bodyLabel}>{item.price}</Text></View>
                  <View style={[{ flex: 0.533 }]}><Text style={styles.bodyLabel}>{item.physical_box_no}</Text></View>
                  </View>
              ) 
            })}
        </View>
      )
    }

    else if (this.state.salesReportList && this.state.salesReportList.length > 0) {

      return (
        <View>
          {
            this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
              return (
                <View style={styles.bodyContainer}>
                  <View style={[{ flex: 0.7 }]}><Text style={[styles.bodyLabel,{ marginLeft: "1.30vw" }]}>{index + 1}</Text></View>
                  <View style={[{ flex: 0.9 }]}><Text style={styles.bodyLabel}>{item.brand_name}</Text></View>
                  <View style={[[{ flex: 0.9 }]]}><Text style={styles.bodyLabel}>{item.generic_name}</Text></View>
                  <View style={[{ flex: 0.9 }]}><Text style={styles.bodyLabel}>{item.dosage_type}</Text></View>
                  <View style={[{ flex: 0.833 }]}><Text style={styles.bodyLabel}>{item.dosage_strength}</Text></View>
                  <View style={[{ flex: 0.833 }]}><Text style={styles.bodyLabel}>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.9 }]}><Text style={styles.bodyLabel}>{item.expiry_date}</Text></View>
                  <View style={[{ flex: 0.833 }]}><Text style={styles.bodyLabel}>{item.schedule}</Text></View>
                  <View style={[{ flex: 0.833 }]}><Text style={styles.bodyLabel}>{item.group}</Text></View>
                  <View style={[{ flex: 0.5 }]}><Text style={styles.bodyLabel}>{item.available_stock}</Text></View>
                  <View style={[{ flex: 0.9 }]}><Text style={styles.bodyLabel}>{item.price}</Text></View>
                  <View style={[{ flex: 0.833 }]}><Text style={styles.bodyLabel}>{item.physical_box_no}</Text></View>
                </View>
              )
            })}
        </View>
      )
    }
    else {
      return <View style={styles.PaginationContainer}>
        <Text style={styles.PaginationLabel}>{"No records to be shown"}</Text>
      </View>
    }

  }

  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }

  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page } = this.state

    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        
        this.setState({
            records_per_page: records_per_page_change,
            current_page: next_page
        }, ()=>{
            this._getPurchaseDetailed()
        })
    }
  }

  render() {
    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0
    
    return (
      <View>
        <View style ={{ flexDirection: "row", width: "100%" }}>
        <ScrollView showsHorizontalScrollIndicator = {false}  showsVerticalScrollIndicator={false}> 
        <View style = {{ flexDirection: "column", width: "20vw"}}>
        {this.renderHeader()}
        {this.renderScrollData()}
        </View>
       </ScrollView>
       <ScrollView showsHorizontalScrollIndicator = {this.state.salesReportList.length > 0 ? true : false}  showsVerticalScrollIndicator={ this.state.salesReportList.length > 0 ? true : false}  horizontal={true}>
         <View style={{ flexDirection:"column", width: "80vw", marginBottom: '.6vw'}}>
          {this.renderScrollHeader()}
          {this.renderPaginateContent()}
        </View>   
         </ScrollView>   
         </View>
        {
          total_count > 0 ?
          <View style={{ marginLeft: "1.30vw" }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
              type={Constants.PAGINATION_TYPE_SERVER} 
              totalServerRecordsCount={total_count} 
              totalServerPagesCount={no_of_pages}/></View>
            : null
        }
      </View>
    );
  }
  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={styles.renderPaginateContent}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              
              let sno = (current_page-1)*records_per_page + index + 1;
              let expiry_date = item.expiry_date?moment(item.expiry_date).format("MM-YYYY"):"" 
              let mrp_per_quantity = item.mrp_per_quantity?(parseFloat(item.mrp_per_quantity)).toFixed(2):"0.00"

              return (
                <View key={sno} style={styles.bodyContainer}>
                  <View style={[{ flex: 1.6, marginLeft: '1.3vw' }]}>
                  <Tooltip placement="topLeft" title={item.generic_name}>
                    <Text style={styles.bodyLabel}>{item.generic_name && item.generic_name.length > 15 ? item.generic_name.slice(0,14) + ".." : item.generic_name}</Text>
                    </Tooltip>
                    </View>
                  <View style={[{ flex: 1 }]}>
                    <Tooltip placement="topLeft" title={item.dosage_type && item.dosage_type.length > 10 ? item.dosage_type : null}>
                      <Text style={styles.bodyLabel}>{item.dosage_type && item.dosage_type.length > 10 ? item.dosage_type.slice(0, 8) + ".." : item.dosage_type}</Text>
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 1 }]}><Text style={styles.bodyLabel}>{item.dosage_strength}</Text></View>
                  <View style={[{ flex: 0.633 }]}><Text style={[styles.bodyLabel,{ flex: 1 }]}>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.833 }]}><Text style={[styles.bodyLabel,{ flex: 1 }]}>{expiry_date}</Text></View>
                  <View style={[{ flex: 0.833 }]}>
                    <Tooltip placement="topLeft" title={item.schedule && item.schedule.length > 6 ? item.schedule : null}>
                      <Text style={[styles.bodyLabel]}>{item.schedule && item.schedule.length > 6 ? item.schedule.slice(0, 5) + ".." : item.schedule}</Text>
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.633 }]}>
                    <Tooltip placement="topLeft" title={item.group && item.group.length > 6 ? item.group : null}>
                      <Text style={[styles.bodyLabel, { flex: 1 }]}>{item.group && item.group.length > 6 ? item.group.slice(0, 5) + ".." : item.group}</Text>
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.9 }]}><Text style={[styles.bodyLabel,{ flex: 1 }]}>{item.available_stock}</Text></View>
                  <View style={[{ flex: 0.9 }]}><Text style={[styles.bodyLabel,{ flex: 1 }]}>{mrp_per_quantity}</Text></View>
                  <View style={[{ flex: 0.9 }]}><Text style={[styles.bodyLabel,{ flex: 1 }]}>{item.price}</Text></View>
                  <View style={[{ flex: 0.566 }]}>
                    <Tooltip placement="topLeft" title={item.physical_box_no && item.physical_box_no.length > 7 ? item.physical_box_no : null}>
                      <Text style={[styles.bodyLabel]}>{item.physical_box_no && item.physical_box_no.length > 7 ? item.physical_box_no.slice(0,7) + ".."  : item.physical_box_no}</Text>
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.833 }]}><Text style={[styles.bodyLabel]}>{item.epr_value}</Text></View>
                </View>
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={styles.PaginationContainer}>
          <Text style={styles.PaginationLabel}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }
  renderScrollData() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={{width: '20vw'}}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              
              let sno = (current_page-1)*records_per_page + index + 1;

              return (
                <View key={sno} style={styles.bodyContainer}>
                  <View style={[{ flex: 0.3 }]}><Text style={[styles.bodyLabel,{ marginLeft: "1.30vw" }]}>{sno}</Text></View>
                  <View style={[{ flex: 0.7 }]}>
                  <Tooltip placement="topLeft" title={item.brand_name}> 
                    <Text style={[styles.bodyLabel,{ marginLeft: "1.30vw" }]}>{item.brand_name && item.brand_name.length > 20 ? item.brand_name.slice(0,20) + ".." : item.brand_name}</Text>
                    </Tooltip>
                    </View>
                  
                </View>
              )

            })
          }
        </View>
      )
    }
  }

}

const styles = StyleSheet.create({
  renderContentRow:{ 
    marginHorizontal: "1.30vw", 
    backgroundColor: "#F9F9F9", 
    marginTop: "1vw" 
  },
  headerContainer:{ 
    marginHorizontal: "1.30vw", 
    marginTop: ".7vw" 
  },
  bodyContainer:{ 
    flexDirection: 'row', 
    borderBottomColor: "#888888", 
    borderBottomWidth: ".1vw", 
    paddingVertical: "1.30vw", 
    marginHorizontal: 0,
    justifyContent:'center',
    alignItems:'center'
  },
  renderPaginateContent:{ 
    // marginHorizontal: "1.30vw", 
    // backgroundColor: "#F9F9F9", 
    // marginTop: "1vw" 
    width: '80vw'
  },
  PaginationContainer:{ 
    flex: 1, 
    flexDirection: "row", 
    justifyContent: "center", 
    alignContent: "center", 
    minHeight: "6.51vw", 
    alignItems: 'center' 
  },
  PaginationLabel:{ 
    color: "#8888888", 
    fontSize:"1vw" 
  },
  bodyLabel:{
    fontSize:'.92vw'
  }
});
