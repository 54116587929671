import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';
import { ScrollView } from 'react-native-gesture-handler';

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

export default class PatientReport extends Component {
    constructor(props) {
      super(props);
      this.state = {
        patientReportList: [],
        startTime: converted_date,
        endTime: converted_date,
        patientReportListAfterPagination: [],
  
        // for pagination
        current_page: 1,
        records_per_page: 10,
        // for pagination, from get data
        total_count: 0,
        no_of_pages: 0,
        patientReportSearch: ''
  
      };
  
      this.service = new Service();
    }

    componentDidMount() {
        this._getReferralDocReport()
    }

    componentWillReceiveProps(props) {
    
        if ((props.isclear && (props.startTime!=this.state.startTime || props.endTime!=this.state.endTime)) || (props.isclear && (props.patientReportSearch != this.state.patientReportSearch))) 
        {
          this.setState({
            startTime: props.startTime,
            endTime: props.endTime,
            patientReportSearch: props.patientReportSearch,
            current_page: 1,
            records_per_page: 10
          }, () => {
            this._getReferralDocReport()
            props.handlepropsvalueclear(false)
    
          })
        }
    }

    _getReferralDocReport = () => {
        var url = Constants.PATIENT_REPORT + "?from_date=" + this.state.startTime + "&to_date=" + this.state.endTime + "&search_key=" + this.state.patientReportSearch;
        this.service.getApiCall(
            url,
            this._getReferralDocReportSuccess,
            this._getReferralDocReportFailure
        )
    }

    _getReferralDocReportSuccess = (response) => {
        if (response.status === "success") {
            this.setState({
                patientReportList: response.data.patient_detail
            },()=>{
                this.props.gettotalscoreValues("Patient Report",response.data.total_amount)
            })
        }else{
            this.props.gettotalscoreValues("Patient Report","")
        }
    }

    _getReferralDocReportFailure = (response) => {
        this.props.gettotalscoreValues("Patient Report","")
    }

    renderHeader() {
        return (
    
          <View style={styles.HeaderView}>
            <CommonReportSectionHeader
              heading1={"S.No"}
              heading2={"Patient Name"}
              heading3={"Mobile No"}
              heading4={"No of Bills"}
              heading5={"Total Amount"}
              columnSize={[0.1, 0.25, 0.22, 0.22, 0.21]}  //total value is == of 1
              noOfColumn={5}
    
            />
          </View>
        )
    }

    renderPaginateContent() { 

        let { current_page, records_per_page } = this.state
    
        if(this.state.patientReportList && this.state.patientReportList.length > 0) {
          return (
            // Report List Views          columnSize={[0.05, 0.08, 0.08, 0.08, 0.08, 0.11, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08]}  //total value is == of 1
    
            <View style={styles.TableDataContainer}>   
              {this.state.patientReportListAfterPagination && this.state.patientReportListAfterPagination.length > 0 && this.state.patientReportListAfterPagination.map((item, index) => {
                  let sno = (current_page-1)*records_per_page + index + 1;
                  return (
                    <View key={sno} style={styles.TableDataWrapper}>
                      <View style={[{ flex: 0.1, marginHorizontal: '1.30vw' }]}><Text style={styles.TableDataText}  >{sno}</Text></View>
                      <View style={[[{ flex: 0.25 }]]}><Text style={styles.TableDataText}>{item.patient_name}</Text></View>
                      <View style={[[{ flex: 0.22 }]]}><Text style={styles.TableDataText}>{item.mobile_number}</Text></View>
                      <View style={[{ flex: 0.22 }]}><Text style={styles.TableDataText}>{item.count}</Text></View>
                      <View style={[{ flex: 0.21 }]}><Text style={styles.TableDataText}>{item.total}</Text></View>
                    </View>
                  )
    
                })
              }
            </View>
          )
        }else{
          return(
            <View style={styles.NoRecordsView}>
              <Text style={styles.NorecordsText}>{"No records to be shown"}</Text>
            </View>
          )
        }
    }

    paginationChangedValues(data) {
        this.setState({
            patientReportListAfterPagination: data
        })
    }
    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }

    render() {
        return(
            <View>
                {this.renderHeader()}
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{maxHeight: '65vh'}}>
                {this.renderPaginateContent()}
                </ScrollView>
                <View>
                    {
                        this.state.patientReportList && this.state.patientReportList.length > 0 ?
                            <View style={{ marginLeft: 20 }}>
                                <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)}
                                    totalClientItems={this.state.patientReportList}
                                    paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                                />
                            </View> : null
                    }
                </View>
               
            </View>
        )
    }
}

const styles = StyleSheet.create({
    HeaderView:{
       marginHorizontal: '1.30vw', marginTop: '0.65vw' 
    },
    TableDataText: {
       fontSize:'1vw'
    },
    TableDataContainer:{
       marginHorizontal: '1.30vw', backgroundColor: "#F9F9F9", marginTop: '0.97vw' 
    },
    TableDataWrapper:{
      flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: '1.30vw', justifyContent: 'center', alignItems: 'center'
    },
    NoRecordsView:{
      flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: '9.76vw'
    },
    NorecordsText:{
      color:"#8888888",
      fontSize:'1vw'
    },
  
});