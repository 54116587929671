import moment from "moment";
import React, { Component } from  "react";
import { View, Text, TouchableOpacity, TouchableWithoutFeedback, StyleSheet, Image} from "react-native";
import Pagination from "../common/Pagination"
import { color } from "../styles/Color";
// import Icon from "react-native-vector-icons/FontAwesome";

import { CommonDateFilter } from "../common/BaseFunctionComponent"

import Constants from '../networks/Constants';
import Service from '../networks/service';
import AsyncStorage from "../AsyncStorage";

export default class Invoices extends Component {
    constructor(props){
        super(props);
        this.state={
            showPickerDropDownFlag: false,
            selectedBatch: [],
            batchData: [],
            billRows: [],
            // for pagination
            current_page: 1,
            records_per_page: 10,

            searchValue: props.searchValue,

            // datefilter
            fromDate: new Date().getFullYear() + "-" + parseInt(new Date().getMonth() + 1) + "-" + new Date().getDate(),
            toDate: new Date().getFullYear() + "-" + parseInt(new Date().getMonth() + 1) + "-" + new Date().getDate(),
            filterDate: "",
            showDateFilter: false,
            defaultSelectedDateFilter: "Today",
            totalAmt: 0,
            cashAmt: 0,
            cardAmt: 0,
            upiAmt: 0,
            banktransferAmt: 0,
            returnAmt: 0,
            insuranceAmt:0,
            selectedStatus: "",
            loginData: {},
            isAdminAccess: false

        }

        this.service = new Service();
        this._viewBill = this._viewBill.bind(this) 
        this._modifyBill = this._modifyBill.bind(this)
        this._doLoadInvoiceList = this._doLoadInvoiceList.bind(this) 
        this._paginationChangedValues = this._paginationChangedValues.bind(this) 
    }

    async componentDidMount() {

        let login_user = await AsyncStorage.getItem("login_result");
        login_user = JSON.parse(login_user)

        this.setState({
            loginData: login_user.data,
            isAdminAccess: login_user.data.is_admin_access

        })
        this._doLoadInvoiceList();
    }

    componentWillReceiveProps(props){
        
        if(props.searchValue !== this.state.searchValue) 
        {
            this.setState({
                searchValue: props.searchValue,
                current_page: 1,
                records_per_page: 10
            }, ()=>{
                this._doLoadInvoiceList()
            })
        }
    }

    _viewBill(item) {

        // let data = JSON.parse(item)
        this.props.viewBill(item)
    }

    _modifyBill(item) {
        this.props.modifyBill(item)
    }

    _doLoadInvoiceList() {

        let { current_page, records_per_page, searchValue, fromDate, toDate } = this.state;
         
        let search_key1 = ""
        let search_key2 = "" 
        let search_key4 = ""
        let search_key5 = ""
        let billStatus = this.state.selectedStatus == "Return" ? "Returned" : ""
        let payment_mode = this.state.selectedStatus == "Cash" ? 1 : this.state.selectedStatus == "Card" ? 2 : this.state.selectedStatus == "UPI" ? 4 : this.state.selectedStatus == "Bank Transfer" ? 5 : this.state.selectedStatus == "Insurance" ? 6 : this.state.selectedStatus == "Total" ? "" : ""
        let url = `${Constants.BILL_LISTING}?page=${current_page}&page_size=${records_per_page}&sort_by=2&payment_mode=${payment_mode}&bill_status=${billStatus}` ;
        let searchAccountNo1 = searchValue.split(/(\d+)/)
        let accountNumber = parseInt(searchAccountNo1[1])
   
         if(this.state.selectedStatus != "") {
            this.setState({
                current_page: 1,
                records_per_page: 10
            })
         }

        if(Constants.REG_EX_MOBILENO.test(searchValue)) {
            search_key4 = searchValue
        } else if(Constants.REG_EX_NUMBERS.test(searchValue)) {
            search_key1 = searchValue
        } else if( searchAccountNo1[0].length > 0 &&  searchAccountNo1[1] && searchAccountNo1[1].length > 0){
           if( typeof(searchAccountNo1[0] === "string") && typeof(accountNumber) === "number" ){
            search_key5 = searchValue
           } 
        } else {
            search_key2 = searchValue
        }

        url = `${url}&search_key1=${search_key1}&search_key2=${search_key2}&search_key4=${search_key4}&search_key5=${search_key5}`

        if( fromDate && toDate )
        {
            url = `${url}&from_date=${fromDate}&to_date=${toDate}`
        }
        
        if(url)
        {
            this.service.getApiCall(
                url,
                this.successCallBack,
                this.errorCallBack
            )
        }
    }

    successCallBack = async (response) => {

        let { billRows } = this.state;
        
        if(response.detail && response.detail === "Token is expired" )
        {
            this.props.logout();
        }
        else if (response.status == "success") {
            
            billRows['result'] = response.data?response.data.result:[];
            billRows['total_count'] = response.data?response.data.total_count:0;
            billRows['no_of_pages'] = response.data?response.data.no_of_pages:0;

            this.setState({
                billRows,
                totalAmt: response.data? response.data.total_amount : 0,
                cashAmt: response.data? response.data.cash_amount : 0,
                cardAmt: response.data? response.data.card_amount : 0,
                upiAmt: response.data? response.data.upi_amount : 0,
                banktransferAmt: response.data? response.data.bank_transfer_amount : 0,
                insuranceAmt: response.data? response.data.insurance_amount : 0,
                returnAmt: response.data? response.data.return_amount : 0
            }, ()=>{
                // setTimeout(() => {
                //     this.setState({ showResponse: false, responseMeaasge: "" });
                //     this.props.navigation.navigate("dashBoard", { menu: "inventory", page: "vendors" })
                // }, 3000)                
            })
            

        }else if(response.status == "fail") {
            
            this.setState({
                responseMessage: response.message
                // ,
                // showErrorResponse: true
            }, ()=>{
                // setTimeout(() => {
                //     this.setState({ showErrorResponse: false, responseMeaasge: "" });
                // }, 3000)                
            })
        }
    }

    errorCallBack = (error) =>{
        console.log("error ---------------------> "+JSON.stringify(error))
    }

    printBill =(id) => {
        let url = "";

        if (id) {

            url = Constants.PHARMACY_BILLING + "?id=" + id + "&export=pdf";

            this.service.getInstance().documentUploadGet(
                url,
                this._printSuccessCallBack,
                this.errorCallBack,
                "pdf"
            );
        } else {
            console.log(" summary id not available")
        }

    }

    _printSuccessCallBack = (response) => {

        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }

    getBillTable(){

        let { billRows, current_page, records_per_page } = this.state;

        let prev_record_count = (parseInt(current_page)-1)*parseInt(records_per_page)
        return(
            <View style={{ flex:1, flexDirection:"column", justifyContent: "flex-start", alignContent: "center", margin: "1vw", zIndex: -1 }}>
                <View style={{ flex:1, flexDirection:"row", justifyContent: "flex-start", alignContent: "center", 
                    backgroundColor: "#E2E2E2", padding: ".5vw", borderRadius: ".25vw" }}>
                        {this.getBillTableTitle('S.No', 0.05)}
                        {this.getBillTableTitle('Invoice No', 0.1)}
                        {this.getBillTableTitle('Invoice Date', 0.085)} 
                        {this.getBillTableTitle('Invoice Time', 0.1)}
                        {this.getBillTableTitle('Patient Account Number', 0.115)}
                        {this.getBillTableTitle('Patient Name', 0.1)}
                        {this.getBillTableTitle('Patient Mobile No', 0.1)}
                        {this.getBillTableTitle('Amount', 0.1)}
                        {this.getBillTableTitle('Payment Type', 0.1)}
                        {this.getBillTableTitle('Status', 0.05)}
                </View>
                {billRows.result && billRows.result.map((item,index)=>{
                    let sno=prev_record_count + (index+1);
                    let Amount = parseFloat((Math.round(item.amount)).toFixed(2)) || 0 // 18.55 - 18.85

                    return(
                        <TouchableOpacity disabled={item.billing_status==="Cancelled"} key={index} onPress={()=>{ 
                            this._viewBill(item) 
                            }}>
                            <View key={index} style={{ flex:1, flexDirection:"row", justifyContent: "flex-start", alignContent: "center", 
                                backgroundColor: "#F9F9F9", padding: ".5vw", borderRadius: ".25vw", marginTop: ".3vw", 
                                borderBottomWidth: 1, borderColor: "#E2E2E2" }}>
                                    {this.getBillTableData(sno, 0.05, 'label' )}
                                    {this.getBillTableData(item.invoice_number, 0.1 )}
                                    {this.getBillTableData(item.invoice_date, 0.085, 'date' )} 
                                    {this.getBillTableData(item.invoice_time || "", 0.1 )}
                                    {this.getBillTableData(item.patient_account_number != "null" ? item.patient_account_number : "NA", 0.115 )}
                                    {this.getBillTableData(item.patient_name, 0.1 )}
                                    {this.getBillTableData(item.customer_mobile_no, 0.1, 'select')}
                                    {this.getBillTableData(Amount, 0.1, "amount" )}
                                    {this.getBillTableData(item.payment_type, 0.1, "payment_mode" )}
                                    {this.getBillTableData(item.billing_status, 0.085 )}
                                    {<View style={{ flex:0.065, flexDirection: "row", justifyContent: "space-between"}}>
                                    <TouchableOpacity
                                onPress={()=>this.printBill(item.id)}
                                style={styles.printBill}>
                                <Image style={{width: "1.30vw", height: "1.30vw"}}
                                    source={require('../../assets/images/HomeScreenImages/Print.png')} />
                            </TouchableOpacity>
                            {this.state.loginData.is_admin_access ? 
                                    <TouchableOpacity
                                        onPress={()=>  { this._modifyBill(item) }}
                                        disabled={item.billing_status === "Returned"}
                                        style={[styles.printBill, {backgroundColor: item.billing_status != "Returned" ? color.themeBackgroud : color.lightGray }]}>
                                        <Image style={{width: "1.30vw", height: "1.30vw"}} 
                                            source={require('../../assets/images/HomeScreenImages/ModifyBill.png')} />
                                    </TouchableOpacity>
                            : null }
                                    </View>}  
                            </View>
                        </TouchableOpacity>
                    )
                })}

                { billRows && billRows.no_of_pages>1?
                <Pagination 
                    paginationChangedValues={this._paginationChangedValues.bind(this)} 
                    totalClientItems={""} 
                    type={Constants.PAGINATION_TYPE_SERVER}
                    totalServerRecordsCount={billRows.total_count} 
                    totalServerPagesCount={billRows.no_of_pages}
                    />:null}
            </View>
        )
    }

    getBillTableTitle(title, flexWidth=0.05){
        return(
        <View style={{ flex: flexWidth }}>
            <Text style={{ fontSize: ".75vw", marginRight:".65vw" }}>{title}</Text>
        </View>)
    }

    PrintReport = (type) => {

        let { searchValue, fromDate, toDate } = this.state;
        
        let search_key1 = ""
        let search_key2 = "" 
        let search_key3 = ""

        if(Constants.REG_EX_MOBILENO.test(searchValue)) {
            search_key3 = searchValue
        } else if(Constants.REG_EX_NUMBERS.test(searchValue)) {
            search_key1 = searchValue
        } else {
            search_key2 = searchValue
        }
        let billStatus = this.state.selectedStatus == "Return" ? "Returned" : ""

        let payment_mode = this.state.selectedStatus == "Cash" ? 1 : this.state.selectedStatus == "Card" ? 2 : this.state.selectedStatus == "UPI" ? 4 : this.state.selectedStatus == "Bank Transfer" ? 5 : this.state.selectedStatus == "Insurance" ? 6 : this.state.selectedStatus == "Total" ? "" : ""

        let url = Constants.GET_INVOICE_REPORT + "?page=" + this.state.current_page + "&page_size=" + this.state.records_per_page + "&sort_by=2&search_key=&search_key1="+search_key1+"&search_key2="+search_key2+"&search_key4="+search_key3+"&export="+type;
        
        if( (fromDate && toDate) || payment_mode || billStatus )
        {
            url = `${url}&from_date=${fromDate}&to_date=${toDate}&payment_mode=${payment_mode}&bill_status=${billStatus}`
        }
      
        if (type == 'PDF'){
        this.service.getInstance().documentUploadGet(
            url,
            this.PrintReportSuccess,
            this.PrintReportFailure,
            "pdf"
        );
        }else{
        this.service.getInstance().documentUploadGet(
            url,
            this.exportCSVSuccessCallBack,
            this.exportCSVErrorCallBack,
            "excel"
        );
        }
    }

    PrintReportSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            // console.log(file)
            window.open(fileURL);
        }
    };

    PrintReportFailure = error => {
        // console.log("Get clinic list error response");
        console.log(error);
    };
    exportCSVSuccessCallBack = response => {
        if (response) {

            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
        }
    }

    exportCSVErrorCallBack = error => { }


    getBillTableData(title, flexWidth=0.05, type='textBox'){

        // if(type=='date') {
        //     title = title?moment(title).format('DD-MM-YYYY'):title
        // }

        return(
        <View style={{ flex: flexWidth,  }}>
            {type==='label'?
            <Text style={{ fontSize: ".85vw"}}>{title}</Text>
            :(type==='amount'?<Text style={{ fontSize: ".85vw"
                // , textAlign: "center"
             }}>{title?title.toFixed(2):title}</Text>
            :<Text style={{ fontSize: ".85vw" }}>{title}</Text>)
            }
        </View>)
    }

    renderCard(colors, icon, value, placehold) {
        return (
            <TouchableOpacity 
               onPress = {()=>{
                  this.setState({
                    selectedStatus: placehold
                  },()=>{
                    this._doLoadInvoiceList()
                  })
               }}
               style={{ backgroundColor: colors, marginRight: ".65vw", borderRadius: ".65vw", paddingVertical: ".25vw", borderWidth: ".25vw", borderColor: this.state.selectedStatus == placehold ? color.themeDark : color.white }}
            >
                <View style={{ flexDirection: "row", justifyContent: "flex-end", minWidth: "7.2vw" }}>
                    <View style={{ marginRight: ".3vw", paddingLeft: ".3vw" }} >
                        <Text style={{ color: "white", fontSize: this.state.heading == "Purchase Detailed" || this.state.heading == 'Purchase Overview' || this.state.heading == 'Sales Overview' || this.state.heading == "Sales Detailed" ? "1.05vw" : "1.05vw", fontWeight: "bold" }}>{value}</Text>
                        {/* 16:20 */}
                    </View>
                    <View style={{ marginRight: ".3vw" }}>
                        <Image
                            style={[styles.Image, { width: this.state.heading == "Purchase Detailed" || this.state.heading == 'Purchase Overview' || this.state.heading == 'Sales Overview' || this.state.heading == "Sales Detailed" ? "1.30vw" : "1.30vw", height: this.state.heading == "Purchase Detailed" || this.state.heading == 'Purchase Overview' || this.state.heading == 'Sales Overview' || this.state.heading == "Sales Detailed" ? "1.30vw" : "1.30vw" }]}
                            source={icon}
                        />
                        {/* 20:30 20:30 */}
                    </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "flex-end", marginRight: ".3vw" }}>
                    <Text style={{ color: "white", fontSize: ".6vw" }}>{placehold}</Text>
                </View>
            </TouchableOpacity>
        )
    }

    render(){
        let total= parseFloat((Math.round(this.state.totalAmt)).toFixed(2)) || 0 
        let cashAmount = parseFloat((Math.round(this.state.cashAmt)).toFixed(2)) || 0 
        let cardAmount = parseFloat((Math.round(this.state.cardAmt)).toFixed(2)) || 0 
        let upiAmount = parseFloat((Math.round(this.state.upiAmt)).toFixed(2)) || 0
        let bankTransAmount = parseFloat((Math.round(this.state.banktransferAmt)).toFixed(2)) || 0 
        let insuranceAmount = parseFloat((Math.round(this.state.insuranceAmt)).toFixed(2)) || 0 
        let returnAmount = parseFloat((Math.round(this.state.returnAmt)).toFixed(2)) || 0 

        return(
            <TouchableWithoutFeedback onPress={()=>this._disableDateFilter(false)}>
                <View>
                    <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginTop: "1vw", marginRight: "1vw" }}>
                        <View style={{ flex: 0.5 }}>
                            <Text style={{ margin: "1vw", marginBottom: ".3vw", fontSize: "1.1vw" }}>{"Invoice"}</Text>
                        </View>
                        <View style={{ flexDirection: "row" }}>
                                {this.renderCard("#DE1B1B", require('../../assets/images/Rupee.png'), total, "Total")}
                                {this.renderCard("#9D358C", require('../../assets/images/Rupee.png'), cashAmount, "Cash")}
                                {this.renderCard("#E54900", require('../../assets/images/Rupee.png'), cardAmount, "Card")}
                                {this.renderCard("#1890ff", require('../../assets/images/Rupee.png'), upiAmount, "UPI")}
                                {this.renderCard("#00b257", require('../../assets/images/Rupee.png'), bankTransAmount, "Bank Transfer")}
                                {this.renderCard("#CD7F32", require('../../assets/images/Rupee.png'), insuranceAmount, "Insurance")}
                                {this.renderCard("#687a8b", require('../../assets/images/Rupee.png'), returnAmount, "Return")}
                        </View>
                        <View style={{ flex: 0.5, flexDirection: "row", justifyContent: "flex-end" }}>
                            <CommonDateFilter
                                removeObject = {!this.state.isAdminAccess ? Constants.remove_Obj_user : ""}
                                startDate={this.state.fromDate}
                                endDate={this.state.toDate}
                                filterDate={this.state.filterDate}
                                showSelectedFilteredDate={this._showSelectedFilteredDate.bind(this)}
                                defaultSelectedDateFilter={this.state.defaultSelectedDateFilter} 
                                // on outside focus, close popup
                                showDateFilter={this.state.showDateFilter} 
                                disableDateFilter={this._disableDateFilter}
                            />
                        </View>
                        <View style={{flexDirection:'row'}}>
                        <View style={{ marginLeft: ".65vw", alignSelf: "center", alignItems: "center" }} >
                            <TouchableOpacity
                                onPress={() => {
                                    this.PrintReport("PDF")
                                }}
                                style={{ width: "2.6vw", height: "2.3vw", borderRadius: ".25vw", backgroundColor: color.themeBackgroud, paddingHorizontal: ".3vw", paddingVertical: ".25vw" }}>
                                <Image style={styles.Image}
                                    source={require('../../assets/images/HomeScreenImages/Print.png')} />
                            </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: ".65vw", alignSelf: "center", alignItems: "center" }} >
                            <TouchableOpacity
                                onPress={() => {
                                    this.PrintReport("excel")
                                }}
                                style={{ width: "2.60vw", height: "2.3vw", borderRadius: ".25vw", backgroundColor: color.themeBackgroud, paddingHorizontal: ".3vw", paddingVertical: ".25vw" }}>
                                <Image style={styles.Image}
                                    source={require('../../assets/images/HomeScreenImages/Export.png')} />
                            </TouchableOpacity>
                        </View>
                        </View>
                    </View>
                    {this.getBillTable()}
                </View>
            </TouchableWithoutFeedback>
        )
    }

    _showSelectedFilteredDate(date) {
        this.setState({
            isclear: true,
            fromDate: date.fromDate,
            toDate: date.toDate,
            showDateFilter: false,
            // reset pagination
            current_page: 1,
            records_per_page: 10,

        },()=>{
            this._doLoadInvoiceList()
        })
    }
    _disableDateFilter=(flag)=>{
        
        this.setState({
            showDateFilter: flag
        })
    }

    _paginationChangedValues(next_page, records_per_page_change) {

        // current_page: 1,
        // records_per_page: 10

        let { current_page, records_per_page } = this.state

        if( current_page != next_page  || records_per_page!=records_per_page_change) {
            
            this.setState({
                records_per_page: records_per_page_change,
                current_page: next_page
            }, ()=>{
                this._doLoadInvoiceList()
            })
        }
    }
}

const styles = StyleSheet.create({
    
    BackIcon: {
        width: "1.30vw",
        height: "1.30vw",
    },
    Image: {
        width: "1.95vw",
        height: "1.95vw",
    },
    printBill: {     
        justifyContent: 'center',
        alignItems: 'center',
        width: "2.6vw",
        height: "2.3vw",
        borderRadius: ".25vw",
        backgroundColor: color.themeBackgroud  
    }
});
