import React, { Component } from 'react';
import { View, Text, TextInput, StyleSheet, Image, TouchableOpacity, ScrollView, Dimensions } from 'react-native';
import { CommonButton, ErrorAlert, SuccessAlert } from '../common/BaseFunctionComponent';
import Constants from '../networks/Constants';
import Service from '../networks/service';
import { color } from '../styles/Color';
import EditIcon from '../../assets/Icon/Edit.png';
import DeleteIcon from '../../assets/Icon/delete.png';

const screenHeight = Dimensions.get("window").height;

export class ReferredDoctors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referredDocList: [],
            doc_name: "",
            doc_reg_no: "",
            doc_qualification: "",
            doc_specialization: "",
            doc_hospital_name: "",
            edit_doc_id: null,
            isDeleteClick: false,
            deletedId: null,
            addClicked: false,
        }
        this.service = new Service();
    }

    componentDidMount(){
        this.getReferredDocList()
    }

    getReferredDocList = () => {

        var url = Constants.REFERRED_DOCTOR

        this.service.getApiCall(
            url,
            this.getReferredDocListSuccess,
            this.errorCallBack
        )
    }

    getReferredDocListSuccess = (response) => {
        if(response.status === "success"){
            this.setState({
                referredDocList: response.data,
            })
        }
    }

    errorCallBack = (response) =>{
        this.setState({
            showErrorResponse: true,
            showSuccesResponse: false,
            responseMessage: response.message
        }, () => {
            this._clearNotification()
        })
    }

    editReferredDoc = (item) => {
        this.setState({
            edit_doc_id: item.id,
            doc_name: item.doctor_name,
            doc_reg_no: item.reg_no,
            doc_qualification: item.qualification,
            doc_specialization: item.specialization,
            doc_hospital_name: item.hospital_name,
        })
    }

    deleteReferredDoc = (id) => {
        this.setState({
            isDeleteClick: true,
            deletedId: id
        })
    }

    renderTableHeader = () => {
        return(
            <View style={{ zIndex: -1, marginTop: 5, backgroundColor: color.appointmentTableHead, height: 50, flexDirection: 'row', alignItems: 'center', borderRadius: 4 }}>
                <View style={{ flex: 0.20, paddingLeft: 15 }}><Text style={styles.headerFontStyle}>{"Doctor Name"}</Text></View>
                <View style={{ flex: 0.17 }}><Text style={styles.headerFontStyle}>{"Registration No."}</Text></View>
                <View style={{ flex: 0.15 }}><Text style={styles.headerFontStyle}>{"Qualification"}</Text></View>
                <View style={{ flex: 0.18 }}><Text style={styles.headerFontStyle}>{"Specialization"}</Text></View>
                <View style={{ flex: 0.20 }}><Text style={styles.headerFontStyle}>{"Hospital Name"}</Text></View>
                <View style={{ flex: 0.10}}><Text style={styles.headerFontStyle}>{"Action"}</Text></View>
            </View>
        )
    }

    renderTableData = () => {
        return(
            <View style={{height: "100%"}}>
            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={true}  style={{height: "100%"}}>
                {this.state.referredDocList.length > 0 && this.state.referredDocList.map((item, index) => (
                   <View style={{height: 60, flexDirection: 'row', flex: 1, alignItems: "center", minHeight: 60, backgroundColor: index % 2 === 0 ? color.white : color.appointmentTableHead,}}>
                    <View style={{flex: 0.20, paddingLeft: 15}}>
                    <Text style={styles.docListFontStyle}>{item.doctor_name}</Text>
                    </View>
                    <View style={{flex: 0.17}}>
                    <Text style={styles.docListFontStyle}>{item.reg_no}</Text>
                    </View>
                    <View style={{flex: 0.15}}>
                    <Text style={styles.docListFontStyle}>{item.qualification}</Text>
                    </View>
                    <View style={{flex: 0.18}}>
                    <Text style={styles.docListFontStyle}>{item.specialization}</Text>
                    </View>
                    <View style={{flex: 0.20}}>
                    <Text style={styles.docListFontStyle}>{item.hospital_name}</Text>
                    </View>
                    <View style={{flex: 0.10, flexDirection: "row"}}>
                    <TouchableOpacity
                    onPress={() => {
                        this.editReferredDoc(item)
                    }}
                    >
                        <Image
                            source={EditIcon}
                            style={{ height: 25, width: 25 }}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                    style={{marginLeft: '1.5vw', width: "fit-content"}}
                    onPress={() => {
                        this.deleteReferredDoc(item.id)
                    }}
                    >
                        <Image
                            source={DeleteIcon}
                            style={{ height: 30, width: 30 }}
                            resizeMode="contain"
                        />
                    </TouchableOpacity>
                    </View>
                   </View> 
                ))}
            </ScrollView>
            {
            this.state.referredDocList.length == 0 ? 
            <View style={{height: "100%", alignItems: "center", justifyContent: "center"}}>
                <Text style={{fontSize:"1.6vw"}}>No Data Found</Text>
            </View>  
            : null
            }
            </View>

        )
    }

    alphabetvalidationWithMajorSplChar(num) {
        return /^[a-z A-Z.,_-]+$/.test(num);
    }

    alphabetvalidation(num) {
        return /^[a-z A-Z]*$/.test(num);
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    renderTextBox = (stateKey, label) => {
        var states = this.state
        return(
        <View style={{marginTop: "4vh"}}>
            <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 10, backgroundColor: "white", paddingLeft: 5, paddingRight: 5, color: color.black }}>{label}</Text>
            <TextInput
            value={states[stateKey]}
            style={{paddingHorizontal: '0.5vw', borderColor: (states.addClicked && ((states[stateKey]).trim() == "")) ? color.brightRed : color.boxColor, borderWidth: 1, borderRadius: 4, height: '5vh', marginTop: 8,}}
            onChangeText = {(text) => {
                if(stateKey === "doc_name" || stateKey === "doc_specialization"){
                    var isNum = this.alphabetvalidation(text)
                }else if(stateKey === "doc_qualification"){
                    var isNum = this.alphabetvalidationWithMajorSplChar(text)
                }else if(stateKey === "doc_reg_no"){
                    var isNum = this.numberValidation(text)
                }else{
                    var isNum = true
                }

                if(isNum){
                    states[stateKey] = text
                    this.setState({
                        states
                    })
                }else if(text == ""){
                    states[stateKey] = text
                    this.setState({
                        states
                    }) 
                }
                
            }}
            />
        </View>
        )
    }

    postReferredDoc = () => {
        var states = this.state
        this.setState({
            addClicked: false
        })
        if((states.doc_name).trim() && (states.doc_reg_no).trim() && (states.doc_qualification).trim() && (states.doc_specialization).trim() && (states.doc_hospital_name).trim()){
            var data = {
                doctor_name: (states.doc_name).trim(),
                reg_no: (states.doc_reg_no).trim(),
                qualification: (states.doc_qualification).trim(),
                specialization: (states.doc_specialization).trim(),
                hospital_name: (states.doc_hospital_name).trim()
            }
    
            if(states.edit_doc_id){
                data["id"] = states.edit_doc_id
            }

            var url = Constants.REFERRED_DOCTOR
            
            this.service.postApiCall(
                url,
                data,
                this.postReferredDocSuccess,
                this.errorCallBack
            )

        }else{
            this.setState({
                showErrorResponse: true,
                showSuccesResponse: false,
                responseMessage: 'All Fields are Mandatory',
                addClicked: true
            }, () => {
                this._clearNotification()
            })
        }
        
    }

    postReferredDocSuccess = (response) => {
        if(response.status === "success"){
            this.setState({
                showErrorResponse: false,
                showSuccesResponse: true,
                responseMessage: response.message
            }, () => {
                this._clearNotification()
            })
            this.onPressClear()
            this.getReferredDocList()
        }else {
            this.setState({
                showErrorResponse: true,
                showSuccesResponse: false,
                responseMessage: response.message
            }, () => {
                this._clearNotification()
            })
        }
    }

    onPressClear = () => {
        this.setState({
            doc_name: "",
            doc_reg_no: "",
            doc_qualification: "",
            doc_specialization: "",
            doc_hospital_name: "",
            edit_doc_id: null,
            addClicked: false,
        })
    }

    deleteReferredDocPost = () => {
        
        var url = Constants.REFERRED_DOCTOR + "?id=" + this.state.deletedId

        this.setState({
            deletedId: null,
            isDeleteClick: false
        })

        this.service.deleteApiCall(
            url,
            {},
            this.postReferredDocSuccess,
            this.errorCallBack
        )
        
    }

    _clearNotification = () => {
        // clear after 2 seconds
        setTimeout(() => {
            this.setState({
                showSuccesResponse: false,
                showErrorResponse: false,
                responseMessage: ""
            })
        }, 2000)
    }

    render() {
        let { showSuccesResponse, showErrorResponse, responseMessage } = this.state;
        return(
            <View style={{ flex: 1, flexDirection: "row", backgroundColor: color.applicationBackgroundColor }}>
                <View style={{ flex: 0.65, height: screenHeight - 65, paddingHorizontal: 15, paddingVertical: 30 }}>
                <View style={{ paddingHorizontal: 15, paddingBottom: 10, flex: 1, height: "100%", backgroundColor: color.white, zIndex: -1 }}>
                {this.renderTableHeader()}
                {this.renderTableData()}
                </View>
                </View>
                <View style={{ flex: 0.35, height: screenHeight - 65, backgroundColor: color.themeShade, }} >
                <View style={{paddingHorizontal: 10, paddingVertical: 30, height: "100%"}}>
                <View style={{ zIndex: -1, marginTop: 5, backgroundColor: color.appointmentTableHead, height: 50, flexDirection: 'row', alignItems: 'center', borderRadius: 4 }}>
                <View style={{ flex: 0.20, paddingLeft: 15 }}><Text style={styles.headerFontStyle}>{"Doctor Details"}</Text></View>
                </View>
                {this.renderTextBox("doc_name", "Doctor Name")}
                {this.renderTextBox("doc_reg_no", "Registration Number")}
                {this.renderTextBox("doc_qualification", "Qualification")}
                {this.renderTextBox("doc_specialization", "Specialization")}
                {this.renderTextBox("doc_hospital_name", "Hospital Name")}
                <View style={{ flexDirection: 'row', justifyContent: "center", marginTop: "6vh" }}>
                <View style={{marginRight: '1vw'}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Add"}
                buttonType={"theme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {
                    this.postReferredDoc()
                }}
                buttonKey={"add"} />
                </View>
                <View style={{marginLeft: "1vw"}}>
                <CommonButton
                item={{}}
                selectedvalue={{}}
                butttonText={"Clear"}
                buttonType={"outlineTheme"}
                buttonIcon={""}
                rightIcon={true}
                buttonAction={() => {
                    this.onPressClear()
                }}
                buttonKey={"clear"} />
                </View>
                </View>
                </View>
                </View>
                {this.state.isDeleteClick ? 
                <View style={{height: "100%", zIndex:1, position:'absolute', width:'100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center"}}>
                    <View style={{ paddingHorizontal: '2vw', paddingVertical: '3vh', backgroundColor: color.white,  width: '22vw', height: '22vh', borderRadius: 8, alignItems: "center", borderWidth: 1, }}>
                    <Text style={{ fontSize: '1.1vw', fontWeight: "bold", marginBottom: '3vh', }}>{"Are you sure ?"}</Text>
                    <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "100%", flexWrap: "wrap-reverse" }}>

                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", height: '5vh' }}
                        onPress={() => {
                            this.deleteReferredDocPost()
                        }}
                    >
                        <Text style={{ color: 'white', fontSize: '0.9vw', }}>{"Yes"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", height: '5vh' }}
                        onPress={() => { 
                            this.setState({
                                deletedId: null,
                                isDeleteClick: false
                            })
                         }}>
                        <Text style={{ color: 'white', fontSize: '0.9vw', }}>{"No"}</Text>
                    </TouchableOpacity>
                    </View>
                    </View>
                </View>
                : null
                }
                {
                        showSuccesResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    headerFontStyle: {
        fontSize: 14,
        color: "black",
        fontWeight: '500'
    },
    docListFontStyle: {
        fontSize: 13,
        color: "black"
    },
    textBoxLabel: {
        zIndex: 2, fontSize: 12, position: "absolute", marginLeft: 5, backgroundColor: color.white, paddingLeft: 5, paddingRight: 5, color: "#888888"
    }
});