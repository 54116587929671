import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

export default class PurchaseDetailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReportList: [],
      startTime: converted_date,
      endTime: converted_date,
      billingListAfterPagination: [],
      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0,
      purchaseDetailedSearchData : this.props.purchaseDetailedSearchData,
      vendorName: this.props.vendorName,
      drugsName: this.props.drugsName
    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 
  }

  componentDidMount() {
    this._getpurchaseDetailed()
  }

  componentWillReceiveProps(props) {
    
    if (props.isclear && (props.startTime!=this.state.startTime || props.endTime!=this.state.endTime)) 
    {
      this.setState({
        startTime: props.startTime,
        endTime: props.endTime,
        current_page: 1,
        records_per_page: 10
      }, () => {
        this._getpurchaseDetailed()
        props.handlepropsvalueclear(false)

      })
    } else if( (props.vendorName != this.state.vendorName) || (props.drugsName != this.state.drugsName) ) {
      this.setState({
         vendorName: props.vendorName,
         drugsName: props.drugsName,
         current_page: 1,
         records_per_page: 10
      }, () => {
        if( (props.selectedPurchaseSearchOption == "Vendor Name" )) {
          if( (this.state.vendorName && this.state.vendorName.length >= 3) || ( this.state.vendorName.length === 0 )) {
            this._getpurchaseDetailed()
          }
        } else if(props.selectedPurchaseSearchOption == "Drug Name"  ) {
          if (( this.state.drugsName && this.state.drugsName.length >= 3) || ( this.state.drugsName.length === 0 )) {
            this._getpurchaseDetailed()
          }
        }
      })
    } 
  }

  // Get Api 
  _getpurchaseDetailed = () => {

    var url = "" 

    let drug_name = this.state.drugsName ? this.state.drugsName : "";
    let vendor_name = this.state.vendorName ? this.state.vendorName : "";
    let { current_page, records_per_page } = this.state;

    if ((this.state.startTime && this.state.endTime) || (this.state.vendorName || this.state.drugsName)) {

      url = Constants.GET_PURCHASE_DETAILED + "?from_date=" + this.state.startTime + "&to_date=" + this.state.endTime  + "&drug_name=" + drug_name + "&vendor_name=" + vendor_name + "&page=" + current_page + "&page_size=" + records_per_page;
    } else {
      url = Constants.GET_PURCHASE_DETAILED + "?page="+current_page+"&page_size="+records_per_page;

    }
    this.service.getApiCall(
      url,
      this._getpurchaseDetailedSuccess,
      this._getpurchaseDetailedFailure
    )
  }

  _getpurchaseDetailedSuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data.result
      this.setState({
        salesReportList,
        no_of_pages: response.data?response.data.no_of_pages:0,
        total_count: response.data?response.data.total_count:0
      }, () => {
        var data ={
          "total_amount":response.data.total_amount ,
          "total_cgst_amount": response.data.total_cgst_amount,
          "total_sgst_amount": response.data.total_sgst_amount,
          "total_discount_amount": response.data.total_discount_amount,
          "total_net_total": response.data.total_net_total,
          }
        this.props.gettotalscoreValues("Purchase Detailed", data)

      })
    }else{
      this.props.gettotalscoreValues("Purchase Detailed", "")

    }
  };

  _getpurchaseDetailedFailure = error => {
    this.props.gettotalscoreValues("Purchase Detailed", "")

    console.log(error);

  };
  // Report List Views Function
  renderContent() {
    return (
      // Report List Views
      <View style={{ marginHorizontal: 20, backgroundColor: "#F9F9F9", marginTop: 15 }}>
        {this.renderContentRow()}



        {/* End Report List View */}

      </View>
    )
  }
  // Common History Section Header function
  renderHeader() {
    return (

      <View style={styles.TableHead}>
        <CommonReportSectionHeader
          heading1={"Bill No"}
          heading2={"Date"}
          heading3={"Vendor Name"}
          heading4={"Batch No"}
          heading5={"Brand Name"}
          heading6={"GST(%)"}
          heading7={"GST Amt(₹)"}
          heading8={"Type/Strength"}
          heading9={"Qty"}
          heading10={"Free Qty"}
          heading11={"Taxable Amt(₹)"}
          heading12={"Buying Price"}
          heading13={"Price Unit"}
          heading14={"Mrp/Qty"}
          heading15={"Epr value"}
          columnSize={[0.06, 0.06, 0.11, 0.06, 0.11, 0.0533, 0.0833, 0.1, 0.0633, 0.0733, 0.1, 0.08, 0.06, 0.06,0.05]}  //total value is == of 1
          // columnSize={[0.083, 0.083, 0.83, 0.09, 0.09, 0.09, 0.09, 0.12, 0.09, 0.09, 0.03]}
          noOfColumn={15}

        />
      </View>
    )
  }


  // // Render Content Row function Key and 
  // renderContentRow() {
  //   if (this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.length < 11) {

  //     return (
  //       <View style={{}}>
  //         {
  //           this.state.salesReportList && this.state.salesReportList.map((item, index) => {

  //             let stock_entered_date = item.stock_entered_date?moment(item.stock_entered_date).format("DD-MM-YYYY"):""

  //             return (
  //               <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0,justifyContent:'center' ,alignItems:'center'}}>
  //                 <View style={[{ flex: 0.7 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
  //                 <View style={[{ flex: 0.7 }]}><Text>{stock_entered_date}</Text></View>
  //                 <View style={[[{ flex: 0.35 }]]}><Text>{item.purchase_order_number}</Text></View>
  //                 <View style={[{ flex: 0.9 }]}><Text>{item.vendor_name}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.batch_no}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.brand_name}</Text></View>
  //                 <View style={[{ flex: 0.9 }]}><Text>{item.generic_name}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.dosage_type}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.dosage_strength}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.stock_in_quantity}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.free_quantity}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.total_price}</Text></View>

  //               </View>
  //             )
  //           })}
  //       </View>
  //     )
  //   }

  //   else if (this.state.salesReportList && this.state.salesReportList.length > 0) {

  //     return (
  //       <View style={{}}>
  //         {
  //           this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
  //             return (
  //               <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0 }}>
  //                 <View style={[{ flex: 0.7 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.stock_entered_date}</Text></View>
  //                 <View style={[[{ flex: 0.833 }]]}><Text>{item.purchase_order_number}</Text></View>
  //                 <View style={[{ flex: 0.9 }]}><Text>{item.vendor_name}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.batch_no}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.brand_name}</Text></View>
  //                 <View style={[{ flex: 0.9 }]}><Text>{item.generic_name}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.dosage_type}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.dosage_strength}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.stock_in_quantity}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.free_quantity}</Text></View>
  //                 <View style={[{ flex: 0.833 }]}><Text>{item.round_off}</Text></View>

  //               </View>
  //             )
  //           })}
  //       </View>
  //     )
  //   }
  //   else {
  //     return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100, alignItems: 'center' }}>
  //       <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
  //     </View>
  //   }

  // }

  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }

  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page } = this.state

    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        
        this.setState({
            records_per_page: records_per_page_change,
            current_page: next_page
        }, ()=>{
            this._getpurchaseDetailed()
        })
    }
  }

  render() {
    
    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0

    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}
        {
          total_count > 0 ?
          <View style={{ marginLeft: 20 }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
              type={Constants.PAGINATION_TYPE_SERVER} 
              totalServerRecordsCount={total_count} 
              totalServerPagesCount={no_of_pages}/></View>
            : null
        }
      </View>
    );
  }
  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        //           columnSize={[0.07, 0.09, 0.1, 0.07, 0.09, 0.0533, 0.0833, 0.12, 0.0633, 0.0633, 0.1333, 0.08, 0.1]}  //total value is == of 1

        <View style={styles.TableContent}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              let sno = (current_page-1)*records_per_page + index + 1;
              let stock_entered_date = item.bill_date?moment(item.bill_date).format("DD-MM-YYYY"):"";
              return (
                <View key={sno} style={styles.TableContentView}>
                  <View style={[{ flex: 0.06  , marginLeft: '1.2vw'}]}><Text style={styles.TableContentText}>{item.invoice_number}</Text></View>
                  <View style={[{ flex: 0.06 }]}><Text style={[styles.TableContentText,{ textAlign: "center" }]}>{stock_entered_date}</Text></View>
                  <View style={[{ flex: 0.11 }]}><Text style={styles.TableContentText}>{item.vendor_name}</Text></View>
                  <View style={[{ flex: 0.06 }]}><Text style={styles.TableContentText}>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.11 }]}><Text style={styles.TableContentText}>{item.brand_name}</Text></View>
                  <View style={[{ flex: 0.0533  }]}><Text style={styles.TableContentText}>{item.cgst_percentage*2}</Text></View>
                  <View style={[{ flex: 0.0833 }]}><Text style={styles.TableContentText}>{item.cgst_amount *2}</Text></View>
                  <View style={[{ flex: 0.1 }]}><Text style={styles.TableContentText}>{item.dosage_type}/{item.dosage_strength}</Text></View>
                  <View style={[{ flex: 0.0633 }]}><Text style={styles.TableContentText}>{item.stock_in_quantity}</Text></View>
                  <View style={[{ flex: 0.0733 }]}><Text style={styles.TableContentText}>{item.free_quantity}</Text></View>
                  <View style={[{ flex: 0.1 }]}><Text style={styles.TableContentText}>{parseFloat(item.amount).toFixed(2)}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text style={styles.TableContentText}>{parseFloat(item.total_buying_price).toFixed(2)}</Text></View>
                  <View style={[{ flex: 0.06 }]}><Text style={styles.TableContentText}>{item.price_per_quantity}</Text></View>
                  <View style={[{ flex: 0.06 }]}><Text style={styles.TableContentText}>{parseFloat(item.mrp_per_quantity).toFixed(2)}</Text></View>
                  <View style={[{ flex: 0.05 }]}><Text style={styles.TableContentText}>{item.epr_value}</Text></View>
                </View>
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: 150 }}>
          <Text style={styles.NoRecord}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }
}
const styles = StyleSheet.create({
    TableHead: { marginHorizontal: '1.2vw', marginTop: '.5vw' },
    TableContent: { 
      marginHorizontal: '1.2vw', 
      backgroundColor: "#F9F9F9", 
      marginTop: '1vw' 
    },
    TableContentView: {
      flex:1, 
      flexDirection: 'row', 
      borderBottomColor: "#888888", 
      borderBottomWidth: 1, 
      paddingVertical: '1.2vw', 
      marginHorizontal: 0
    },
    TableContentText: {fontSize: '1vw'},
    NoRecord: {color: "#8888888", fontSize:'1vw'}
})