import React, { Component } from "react";
import { View, Text, TextInput, TouchableOpacity, StyleSheet, ScrollView, Image } from "react-native";
import moment from "moment"
import { BillTableTitle, BillTableData, CommonAutoSuggestion } from "../../common/BaseFunctionComponent";
import Pagination from "../../common/Pagination";
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import { color } from "../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import { Tooltip } from "antd";

export default class PurchaseOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                total_count: 0,
                no_of_pages: 0,
                result: []
            },
            current_page: 1,
            records_per_page: 10,
            searchValue: this.props.searchValue ? this.props.searchValue : "",
            configData: this.props.configData ? this.props.configData : {},
            loginUserData: !(this.props.login_user_is_admin) ? false : true
        }
        this.service = new Service();
    }

    componentDidMount() {
        this._getPurchaseList()
        // this.configStatusGet();
    }

    _getPurchaseList = () => {
        let { current_page, records_per_page, searchValue } = this.state;
        let url = `${Constants.LIST_PO}?search_term=${searchValue}&page=${current_page}&page_size=${records_per_page}&sort_by=2`;

        this.service.getApiCall(
            url,
            this.successCallBack,
            this.errorCallBack
        )
    }

    successCallBack = async (response) => {

        let { data } = this.state;

        if (response.detail && response.detail === "Token is expired") {
            this.props.logout();
        }
        else if (response.status == "success") {

            data['result'] = response.data ? response.data.result : [];
            data['total_count'] = response.data ? response.data.total_count : 0;
            data['no_of_pages'] = response.data ? response.data.no_of_pages : 0;

            data = JSON.parse(JSON.stringify(data))

            this.setState({
                data
            })
        } else if (response.status == "fail") {

            this.setState({
                responseMessage: response.message
            }, () => {
            })
        }
    }

    errorCallBack = (error) => { }

    configStatusGet = () => {
        var url = Constants.CONFIG_UPDATE
        this.service.getApiCall(
            url,
            this.getConfigSuccess,
            this.getConfigError
        )
    }
    getConfigSuccess = (response) => {
        if (response.status === "succes") {
            this.setState({
                direct_po_available: response.data.direct_po_available
            })
        }
    }

    getConfigError = (response) => { }

    renderTopHeader() {
        let isDisable = this.props.isDisable ? this.props.isDisable : false;
        return (
            <View style={{ flexDirection: "row", justifyContent: "flex-end", marginHorizontal: "1.25vw", marginBottom: "0.625vw" }}>
                <View style={{ flexDirection: "row", marginRight: " 0.625vw" }}>
                    <TextInput
                        value={this.state.searchValue}
                        onChangeText={(text) => {
                            this.setState({
                                searchValue: text
                            })
                        }}
                        style={{ borderWidth: 1, height: "2.2vw", width: "10.62vw", paddingHorizontal: "0.19vw", borderColor: color.lightGray, fontSize: "1vw" }}
                        placeholder="Search Vendor..."
                    />
                    <TouchableOpacity
                        style={{ height: "2.2vw", width: "2.2vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                        onPress={() => {
                            this._getPurchaseList()
                        }}>
                        <Icon name='search' size={"0.93vw"} color={color.white}></Icon>
                    </TouchableOpacity>
                </View>

                <View style={{ flexDirection: "row" }}>
                    {
                        this.state.loginUserData || this.state.configData.direct_po_available? <TouchableOpacity
                        style={[styles.ButtonStyle, { marginRight: " 0.625vw" }]}
                        onPress={() => this.props.NavigateScreen("add_stock", "", "directPO")}
                    >
                        <Text style={styles.ButtonText}>+Direct Po</Text>
                    </TouchableOpacity>
                    :  null       
                    }

                    <TouchableOpacity
                        onPress={() => {
                            !isDisable ? this.props.generateReport('excel') : null
                        }}
                        style={{ width: "2.60vw", height: "2vw", borderRadius: "0.26vw", backgroundColor: isDisable ? color.lightGray : color.themeBackgroud, paddingHorizontal: ".32vw", paddingVertical: "0.4vw" }}>
                        <Image style={{ width: "1.95vw", height: "1.62vw" }}
                            source={require('../../../assets/images/HomeScreenImages/Export.png')} />
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => {
                            !isDisable ? this.props.generateReport('pdf') : null
                        }}
                        style={{ width: "2.60vw", height: "2vw", borderRadius: ".26vw", backgroundColor: isDisable ? color.lightGray : color.themeBackgroud, paddingHorizontal: ".3vw", paddingVertical: "0.4vw", marginLeft: "1vw" }}>
                        <Image style={{ width: "1.95vw", height: "1.62vw" }}
                            source={require('../../../assets/images/HomeScreenImages/Print.png')} />
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    getPurchaseTable() {
        let { data } = this.state;
        return (
            <View style={styles.TableContainer}>
                <View style={styles.TableWrapper}>
                    <BillTableTitle label={'GRN'} flexWidth={0.1} />
                    <BillTableTitle label={'Purchase Order Date'} flexWidth={0.15} />
                    <BillTableTitle label={'Vendor Name'} flexWidth={0.3} />
                    <BillTableTitle label={'No of Items'} flexWidth={0.1} />
                    <BillTableTitle label={'Delivery Date'} flexWidth={0.2} />
                    <BillTableTitle label={'Status'} flexWidth={0.2} />
                </View>
                {data.result && data.result.map((item, index) => {
                    // let sno=index+1;
                    let purchase_order_date = item.purchase_order_date ? moment(item.purchase_order_date).format("DD-MM-YYYY") : ""
                    let delivery_date = item.delivery_date ? moment(item.delivery_date).format("DD-MM-YYYY") : ""
                    return (
                        <TouchableOpacity key={index} onPress={() => this.props.NavigateScreen('add_stock', '', 'add_stock', item.id, item.status)}>
                            <View key={index} style={styles.TableBody}>
                                <BillTableData label={item.purchase_order_number} labelType={'label'} flexWidth={0.1} />
                                <BillTableData label={purchase_order_date} labelType={''} flexWidth={0.15} />
                                <BillTableData label={item.vendor_name} labelType={''} flexWidth={0.3} />
                                <BillTableData label={item.number_of_items} labelType={''} flexWidth={0.1} />
                                <BillTableData label={delivery_date} labelType={''} flexWidth={0.2} />
                                <BillTableData label={item.status} labelType={''} flexWidth={0.2} />
                            </View>
                        </TouchableOpacity>
                    )
                })}
            </View>
        )
    }

    _paginationChangedValues(next_page, records_per_page_change) {

        let { current_page, records_per_page } = this.state

        if (current_page != next_page || records_per_page != records_per_page_change) {

            this.setState({
                records_per_page: records_per_page_change,
                current_page: next_page
            }, () => {
                this._getPurchaseList()
            })
        }
    }

    render() {
        let { data } = this.state;
        return (
            <View style={{ paddingHorizontal:"1.25vw" }}>
                {this.renderTopHeader()}
                {this.getPurchaseTable()}
                {data.total_count && parseInt(data.total_count) > 0 ?
                    <Pagination
                        paginationChangedValues={this._paginationChangedValues.bind(this)}
                        totalClientItems={""}
                        type={Constants.PAGINATION_TYPE_SERVER}
                        totalServerRecordsCount={data.total_count}
                        totalServerPagesCount={data.no_of_pages}
                    /> : null}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    TableContainer: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center"
    },
    TableWrapper: {
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#E2E2E2", padding: ".52vw", borderRadius: ".25vw"
    },
    TableBody: {
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#F9F9F9", padding: ".52vw", borderRadius: ".25vw", marginTop: ".32vw",
        borderBottomWidth: 1, borderColor: "#E2E2E2"
    },
    ButtonStyle: {
        width: '8vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.4vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize: "1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    },
    printContainer: {
        width: '2.60vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.32vw',
        paddingVertical: '0.16vw'
    },
});