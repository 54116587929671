import React from "react"
import { View, Text, TextInput, TouchableOpacity, ScrollView, FlatList, StyleSheet } from "react-native"
import Icon from "react-native-vector-icons/FontAwesome";
import moment from "moment";
import { color } from "../../styles/Color";
import { MenuBreadCrumpList, SuccessAlert, ErrorAlert } from "../../common/BaseFunctionComponent"
import BaseComponent from "../../common/BaseComponent"
import Constants from '../../networks/Constants';
import Service from '../../networks/service';

const drugDetails = {
    "brand_name": "",
    "generic_name": "",
    "dosage_type": "",
    "dosage_strength": "",
    "quantity": "",
    "isDrugSelected": false,
    "isGenericSelected": false,
    "isDosageSelected": false,
    "isStrengthSelected": false
}
const tempSuggestion = {
    "drugSuggestionList": [],
    "genericSuggestionList": [],
    "strengthSuggestionList": [],
    "drugDetailsSuggestionList": []
}

export default class PurchaseDetails extends BaseComponent {

    constructor(props) {
        super(props)
        this.brandRefs = [];
        let suggestions = [];
        for (let i = 0; i < 10; i++) {
            suggestions.push(tempSuggestion);
        }

        this.state = {
            menu: this.props.menu,
            selectedScreen: this.props.selectedScreen,
            vendor_id: null,
            vendor_name: "",
            vendor_list: [],
            vendor_list_suggest: [],
            isSelected: false,
            po_number: null,
            po_date: new Date(),
            billRows: [],
            drugSuggestionList: [],
            genericSuggestionList: [],
            drugDetailsSuggestionList: [],
            drugDosageTypes: [],
            suggestions: JSON.parse(JSON.stringify(suggestions))
        }
        this.service = new Service();
    }

    componentDidMount() {
        //this._getDosageTypes();
        this._getPOnumber();
        this._getVendorList();
    }

    _getDosageTypes() {
        let url = Constants.LIST_DOSAGE;
        this.service.getApiCall(
            url,
            this._getDosageTypeSuccess,
            this._getDosageTypeFailure
        )
    }

    _getDosageTypeSuccess = response => {
        if (response.status === "success") {
            this.setState({ drugDosageTypes: JSON.parse(JSON.stringify(response.data)) });
        }
    };

    _getDosageTypeFailure = error => {
    };

    _getPOnumber() {
        let url = Constants.PURCHASE_ORDER_NO_GET;
        this.service.getApiCall(
            url,
            this._getPOnoSuccess,
            this._getDosageTypeFailure
        )
    }

    _getPOnoSuccess = response => {
        if (response.status === "success") {
            let data = JSON.parse(JSON.stringify(response.data));
            this.setState({ po_number: data ? data.po_number : null });
        }
    };

    _getVendorList() {
        let url = Constants.LIST_VENDOR + "?from=purchase_order";
        this.service.getApiCall(
            url,
            this._getVendorListSuccess,
            this._getDosageTypeFailure
        )
    }

    _getVendorListSuccess = response => {
        if (response.status === "success") {
            let data = response.data || [];
            this.setState({
                vendor_list: data,
                vendor_list_suggest: data
            });
        }
    };

    getBillTable() {
        let { billRows } = this.state;
        for (let i = billRows.length; i < 10; i++) {
            billRows.push(JSON.parse(JSON.stringify(drugDetails)))
        }
        return (
            <View style={styles.BillTableContainer}>
                <View style={styles.BillTableWrapper}>
                    {this.getBillTableTitle('S.No', 0.05)}
                    {this.getBillTableTitle('Brand Name', 0.3)}
                    {this.getBillTableTitle('Generic Name', 0.3)}
                    {this.getBillTableTitle('Dosage Type', 0.15)}
                    {this.getBillTableTitle('Dosage Strength', 0.1)}
                    {this.getBillTableTitle('Quantity', 0.1)}
                </View>
                {billRows.map((item, index) => {
                    let sno = index + 1;
                    let zindexValue = (index + 2) * -1
                    return (
                        <View key={index} style={[styles.BillTableBody,{ zIndex: zindexValue}]}>
                            {this.getBillTableData(sno, 0.05, 'label')}
                            {this.getBillTableData(item.brand_name, 0.3, 'select', index, "drug_name")}
                            {this.getBillTableData(item.generic_name, 0.3, 'select_generic', index, "generic_name")}
                            {this.getBillTableData(item.dosage_type, 0.15, 'select_dosage', index, "dosage_type")}
                            {this.getBillTableData(item.dosage_strength, 0.1, 'select_strength', index, "dosage_strength")}
                            {this.getBillTableData(item.quantity, 0.1, 'textBox', index, "quantity")}
                        </View>
                    )
                })}
            </View>
        )
    }

    getBillTableTitle(title, flexWidth = 0.05) {
        return (
            <View style={{ flex: flexWidth, justifyContent:'center' }}>
                <Text style={{ fontSize: "1vw", marginRight: "0.65vw" }}>{title}</Text>
            </View>)
    }
    getBillTableData(title, flexWidth = 0.05, type = 'textBox', index = null, key = null) {
        let { billRows } = this.state;
        return (
            <View style={{ flex: flexWidth }}>
                {type === 'label' ? <Text style={{ fontSize: "1.4vw", color: "#505050" }}>{title}</Text>
                    : type === 'icon' ? <Icon name="close" size={"1.17vw"} color={"#000000"} />
                        : type === 'select' ? (this._handleDrugSuggestion(type, index, key))
                            : type === 'select_generic' || type === 'select_dosage' || type === 'select_strength' ?
                                (this._handleDrugDetailSuggestion(type, index, key))
                                : <View style={{ flexDirection: "column" }}>
                                    <TextInput
                                        style={[styles.BillTableDataInput,{backgroundColor: color.white}]}
                                        value={title}
                                        onChangeText={(text) => {
                                            billRows[index][key] = text;
                                            this.setState({
                                                billRows
                                            })
                                        }} ></TextInput>
                                </View>
                }
            </View>)
    }

    _handleDrugSuggestion = (type, index, key) => {
        let { typedIndex, billRows, suggestions } = this.state;
        let value = billRows[index]['brand_name'];
        let drugSuggestionList = suggestions[typedIndex ? typedIndex : 0]['drugSuggestionList'];
        let isSelected = billRows[index]['isDrugSelected'];

        return (
            <View style={{ flexDirection: "column" }}>
                <TextInput
                    style={[styles.drugSuggestionInput,{backgroundColor: color.white}]}
                    value={value}
                    onChangeText={(text) => {
                        billRows[index]['brand_name'] = text;
                        billRows[index]['isDrugSelected'] = false;
                        this.setState({
                            typedIndex: index,
                            billRows
                        }, () => {
                            this._getDrugSuggestion(text)
                        })
                    }}
                ></TextInput>
                {typedIndex === index && value && !isSelected && (drugSuggestionList && drugSuggestionList.length > 0) ?
                    <View style={[styles.typedIndex,{backgroundColor: color.themeBlue}]}>
                        <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: "13.02vw" }}>
                            <FlatList
                                data={drugSuggestionList}
                                renderItem={({ item }) => (
                                    <View style={styles.FlatList}
                                    >
                                        <View style={{ width: '100%' }}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    billRows[index]['brand_name'] = item;
                                                    billRows[index]['isDrugSelected'] = true;
                                                    this.setState({
                                                        billRows,
                                                    }, () => {
                                                        this._getDrugDetails(item)
                                                    })
                                                }}
                                            >
                                                <Text style={{ color: color.white, fontSize:'1vw' }}>{item}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )}
                                enableEmptySections={true}
                            />
                        </ScrollView>
                    </View> : null}
            </View>
        )
    }

    _handleDrugDetailSuggestion = (type, index, key) => {
        let { typedIndex, typedKey, suggestions, billRows, drugDosageTypes } = this.state;
        let value = null;
        let suggestData = [];
        let isSelected = true;
        let genericSuggestionList = suggestions[typedIndex ? typedIndex : 0]['genericSuggestionList'];
        let dosageTypeSuggestionList = suggestions[typedIndex ? typedIndex : 0]['dosageTypeSuggestionList'];
        let strengthSuggestionList = suggestions[typedIndex ? typedIndex : 0]['strengthSuggestionList'];
        if (key === 'generic_name') {
            value = billRows[index]['generic_name'];
            isSelected = billRows[index]['isGenericSelected'];
            suggestData = genericSuggestionList;
        }
        if (key === 'dosage_type') {
            value = billRows[index]['dosage_type'];
            isSelected = billRows[index]['isDosageSelected'];
            suggestData = dosageTypeSuggestionList;
        }
        if (key === 'dosage_strength') {
            value = billRows[index]['dosage_strength'];
            isSelected = billRows[index]['isStrengthSelected'];
            suggestData = strengthSuggestionList;
        }

        return (
            <View style={{ flexDirection: "column" }}>
                <TextInput
                    style={[styles.drugSuggestionInput,{backgroundColor: color.white}]}
                    value={value}
                    editable={false}
                    onFocus = {()=>{
                        if (key === 'generic_name') {
                            //billRows[index]['generic_name'] = text;
                            billRows[index]['isGenericSelected'] = false;
                        }
                        if (key === 'dosage_type') {
                            //billRows[index]['dosage_type'] = text;
                            billRows[index]['isDosageSelected'] = false;
                        }
                        if (key === 'dosage_strength') {
                            //billRows[index]['dosage_strength'] = text;
                            billRows[index]['isStrengthSelected'] = false;
                        }
                        this.setState({
                            typedKey: key,
                            typedIndex: index,
                            billRows
                        }, () => {
                        })
                    }}
                    // onChangeText={(text) => {
                    //     if (key === 'generic_name') {
                    //         billRows[index]['generic_name'] = text;
                    //         billRows[index]['isGenericSelected'] = false;
                    //     }
                    //     if (key === 'dosage_type') {
                    //         billRows[index]['dosage_type'] = text;
                    //         billRows[index]['isDosageSelected'] = false;
                    //     }
                    //     if (key === 'dosage_strength') {
                    //         billRows[index]['dosage_strength'] = text;
                    //         billRows[index]['isStrengthSelected'] = false;
                    //     }
                    //     this.setState({
                    //         typedKey: key,
                    //         typedIndex: index,
                    //         billRows
                    //     }, () => {
                    //     })

                    // }}
                ></TextInput>
                {typedIndex === index && typedKey === key && !isSelected && (suggestData && suggestData.length > 0) ?
                    <View style={[styles.typedIndex,{backgroundColor: color.themeBlue}]}>
                        <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: "13.02vw" }}>
                            <FlatList
                                data={suggestData}
                                renderItem={({ item }) => (
                                    <View style={styles.FlatList}
                                    >
                                        <View style={{ width: '100%' }}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (key === 'generic_name') {
                                                        billRows[index]['generic_name'] = item; //.generic_name;
                                                        billRows[index]['isGenericSelected'] = true;
                                                    }
                                                    if (key === 'dosage_type') {
                                                        billRows[index]['dosage_type'] = item;
                                                        billRows[index]['isDosageSelected'] = true;
                                                    }
                                                    if (key === 'dosage_strength') {
                                                        billRows[index]['dosage_strength'] = item;
                                                        billRows[index]['isStrengthSelected'] = true;
                                                    }
                                                    this.setState({
                                                        billRows
                                                    }, () => {
                                                        this._onChangeDosageType(typedIndex, item)
                                                    })
                                                }}
                                            >
                                                <Text style={{ color: color.white, fontSize:'1vw' }}>
                                                    {item}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )}
                                enableEmptySections={true}
                            />
                        </ScrollView>
                    </View> : null}
            </View>
        )
    }

    _onChangeDosageType = (index, value) => {

        let { suggestions, billRows } = this.state;
        let drugList = suggestions[index]['drugDetailsSuggestionList'];
        let strengthData = [];  //suggestions[index]['strengthSuggestionList'];
        let currentRowData = billRows[index];
        if (currentRowData) {
            let cDrug = billRows[index].brand_name;
            let cGeneric = billRows[index].generic_name;
            let cDosage = billRows[index].dosage_type;
            for (let i = 0; i < drugList.length; i++) {
                if (drugList[i].generic_name === cGeneric && drugList[i].dosage_type === cDosage) {
                    strengthData.push(drugList[i].dosage_strength)
                }
            }
            suggestions[index]['strengthSuggestionList'] = strengthData;
            this.setState({
                suggestions
            });
        }
    }

    render() {
        let { menu, selectedScreen, vendor_list_suggest, isSelected, vendor_name, po_number, po_date, showSuccessResponse, showErrorResponse, responseMessage } = this.state;
        let isDisable = selectedScreen === "update_purchase_order" ? true : false
        return (
            <View style={{ margin: "1vw" }}>
                <MenuBreadCrumpList menu={menu} selectedScreen={selectedScreen}
                    changeScreen={this.props.changeScreen}
                    onSave={this._doSave.bind(this)}
                    list={vendor_list_suggest}
                    isSelected={isSelected}
                    vendor_name={vendor_name}
                    po_number={po_number}
                    po_date={po_date}
                    onChangeText={this._updateState.bind(this)}
                    itemSelected={this._updateItemSelected.bind(this)}
                    objFormat={{ keyId: "vendor_id", keyName: "name" }}
                />
                {this.getBillTable()}
                {
                    showSuccessResponse ?
                        <SuccessAlert message={responseMessage} /> : null
                }
                {
                    showErrorResponse ?
                        <ErrorAlert message={responseMessage} /> : null
                }
            </View>
        )
    }

    _getDrugSuggestion = (searchKey) => {
        let self = this;
        let { suggestions, typedIndex, isDrugSelected, newItem } = this.state;
        let drugSuggestionList = suggestions[typedIndex]['drugSuggestionList'];
        let drugName = searchKey;
        if (drugName !== "") {
            let sameInList = false;
            let isEnableDrugList = false;
            let len = drugSuggestionList.length;
            for (var i = 0; i < len; i++) {
                let value = drugSuggestionList[i];
                if (drugName == value) {
                    sameInList = true;
                    return;
                }
            }
            if (sameInList) {
                isEnableDrugList = true;
                // exist, so no need call service
                this._getDrugDetails(drugName);
            } else {
                let url = Constants.SEARCH_DRUG + "?brand_name=" + drugName;
                this.service.getApiCall(
                    url,
                    this._getDrugSuccess,
                    this._getDrugFailure
                )
            }

        } else {
            this.setState({
                drugSuggestionList: []
            })
        }
    }
    _getDrugSuccess = response => {
        let { typedIndex, suggestions } = this.state;
        if (response.status === "success") {
            suggestions[typedIndex]['drugSuggestionList'] = JSON.parse(JSON.stringify(response.data));
            this.setState({ suggestions })
        }
    };
    _getDrugFailure = error => {
    };
    _getDrugDetails = (drugName) => {
        if (drugName !== "") {
            this.setState({ isDrugSelected: true })
            let url = Constants.PO_DRUG_DETAILS_BY_DRUGNAME + "?brand_name=" + drugName;
            this.service.getApiCall(
                url,
                this._getDrugDetailsSuccess,
                this._getDrugFailure
            )
        } else {
            this.setState({
                drugDetailsSuggestionList: []
            });
        }
    }

    _getDrugDetailsSuccess = response => {
        if (response.status === "success") {
            var { billRows, typedIndex, suggestions } = this.state;
            let generic_unique_name = [];
            let dosage_type_unique = [];
            let dosage_strength_unique = [];
            let list = JSON.parse(JSON.stringify(response.data));
            let dataLen = list.length;
            // if have only one, this will be default
            if (dataLen === 1) {
                billRows[typedIndex]['drug_id'] = list[0]['drug_id'];
                billRows[typedIndex]['generic_name'] = list[0]['generic_name'];
                billRows[typedIndex]['selectedDosage'] = list[0]['dosage_type'];
                billRows[typedIndex]['dosage_strength'] = list[0]['dosage_strength'];
            }
            for (var i = 0; i < dataLen; i++) {
                let value = list[i].generic_name;
                let strength = list[i].dosage_strength;
                let type = list[i].dosage_type;
                if (value && !generic_unique_name.includes(value)) {
                    generic_unique_name.push(value)
                }
                if (strength && !dosage_strength_unique.includes(strength)) {
                    dosage_strength_unique.push(strength)
                }
                if (type && !dosage_type_unique.includes(type)) {
                    dosage_type_unique.push(type)
                }
            }
            suggestions[typedIndex]['genericSuggestionList'] = generic_unique_name;
            suggestions[typedIndex]['strengthSuggestionList'] = dosage_strength_unique;
            suggestions[typedIndex]['dosageTypeSuggestionList']= dosage_type_unique;
            suggestions[typedIndex]['drugDetailsSuggestionList'] = list;
            // update
            this.setState({
                suggestions,
                billRows
            });
        }
    };

    _updateState = (stateKey, text, type = '') => {
        let states = this.state;
        let { vendor_list, vendor_list_suggest } = this.state
        states[stateKey] = text;
        if (stateKey === "vendor_name") {
            states['isSelected'] = false;
            states['vendor_id'] = null;
            vendor_list_suggest = this._sortListData(vendor_list, text)
        }
        this.setState({
            states,
            vendor_list_suggest: vendor_list_suggest
        })
    }

    _updateItemSelected = (item) => {
        let states = this.state;
        if (item) {
            states['vendor_id'] = item.vendor_id;
            states['vendor_name'] = item.name;
            states['isSelected'] = true;
            this.setState({
                states
            })
        }
    }

    _doSave() {
        let { billRows, po_number, po_date, vendor_id } = this.state;
        let url = Constants.CREATE_PURCHASE_ORDER;
        let data = {
            vendor_id: vendor_id,
            purchase_order_number: po_number,
            purchase_order_date: moment(po_date).format('YYYY-MM-DD'),
            line_items: []
        };
        for (let i = 0; i < billRows.length; i++) {
            if (billRows[i].brand_name && billRows[i].generic_name && billRows[i].dosage_type && billRows[i].dosage_strength) {
                // if((localStorage.getItem('user_name') !== 'Lister Pharmacy' && localStorage.getItem('user_name') !== 'KJ Pharmacy Admin' && localStorage.getItem('user_name') !== 'Insight Pharmacy')){
                    // if(!billRows[i].drug_id){
                    //     this.props.responseSuccessErrorMessage(billRows[i].brand_name+" is new drug, New drug cannot be added!", false)
                    //     setTimeout(() => {
                    //     this.props.responseSuccessErrorMessage("", false)
                    // }, 2000)
                    // return;
                    // }else if(billRows[i].drug_id){
                    //     let tempDrug = {
                    //         "serial_no": i + 1,
                    //         "brand_name": billRows[i].brand_name,
                    //         "generic_name": billRows[i].generic_name,
                    //         "dosage_type": billRows[i].dosage_type,
                    //         "dosage_strength": billRows[i].dosage_strength,
                    //         "quantity": billRows[i].quantity
                    //     }
                    //     // push data
                    //     data['line_items'].push(tempDrug)
                    // }
                // }
                // else{
                    let tempDrug = {
                        "serial_no": i + 1,
                        "brand_name": billRows[i].brand_name,
                        "generic_name": billRows[i].generic_name,
                        "dosage_type": billRows[i].dosage_type,
                        "dosage_strength": billRows[i].dosage_strength,
                        "quantity": billRows[i].quantity
                    }
                    // push data
                    data['line_items'].push(tempDrug)
                // }
            }
        }

        if (data['line_items'].length > 0) {
            this.service.postApiCall(
                url,
                data,
                this.successCallBack,
                this.errorCallBack
            )
        } else {
            this.props.responseSuccessErrorMessage("Purchase items should not be empty", false)
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", false)
            }, 2000)
        }
    }

    successCallBack = async (response) => {
        if (response.status == "success") {
            this.props.responseSuccessErrorMessage(response.message, true)
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", true)
                this.props.changeScreen("purchase_orders")
            }, 2000)
        } else if (response.status == "fail") {
            this.props.responseSuccessErrorMessage(response.message, false)
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", false)
            }, 2000)
        }
    }
    errorCallBack = (error) => {
    }
}

const styles = StyleSheet.create({
BillTableContainer:{ 
    flex: 1, 
    flexDirection: "column", justifyContent: "flex-start", 
    alignContent: "center", marginVertical: "1vw", zIndex: -1
 },
 BillTableWrapper:{
    flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
    backgroundColor: "#E2E2E2", padding: "0.52vw", borderRadius: "0.26vw", zIndex: -1, maxHeight:'2.5vw'
},
BillTableDataInput:{
    flex: 1,
    height: "2.27vw",
    padding: "0.39vw",
    marginRight: "1vw",
    borderColor: "#CDD1D5",
    borderRadius: "0.26vw",
    borderWidth: 1,
    fontSize: "1vw"
},
BillTableBody:{
    flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
    backgroundColor: "#F9F9F9", padding: "0.52vw", borderRadius: "0.26vw", marginTop: "0.32vw",
    borderBottomWidth: 1, borderColor: "#E2E2E2"
},
drugSuggestionInput:{
    flex: 1,
    height: "2.27vw",
    padding: "0.39vw",
    marginRight: "1vw",
    borderColor: "#CDD1D5",
    borderRadius: "0.26vw",
    borderWidth: 1,
    fontSize: "0.8vw",
    zIndex: -10
},
typedIndex:{
    borderRadius: "0.65vw",
    width: '90%',
    justifyContent: 'center',
    alignContent: 'center',
    padding: "0.65vw",
    marginLeft: "0.65vw",
    marginTop: "1.95vw",
    position: 'absolute'
},
FlatList:{
    flexDirection: "row",
    bottom: "0.65vw",
    marginBottom: "0.65vw",
    justifyContent: "space-evenly",
    width: "100%",
    alignItems: "center",
    marginTop: "0.65vw"
},
});