import React, { Component } from 'react';
import { Text, View, ScrollView, TouchableOpacity, StyleSheet, TextInput, Picker, Image } from 'react-native';
import { CommonDateFilter, MenuBreadCrumpList, SuccessAlert, ErrorAlert } from '../../../common/BaseFunctionComponent';
import Pagination from "../../../common/Pagination";
import moment from "moment";
import Icon from "react-native-vector-icons/FontAwesome";
import { color } from "../../../styles/Color";
import Service from "../../../networks/service";
import Constants from "../../../networks/Constants";
import { Tooltip } from 'antd';


const StatusType = [
    { label: 'All', value: "" },
    { label: 'Indent Placed', value: "Indent Placed" },
    { label: 'Approved', value: "Approved" },
    { label: 'Declined', value: "Declined" },
]
export class OTIndentRequestList extends Component {
    constructor(props) {
        super(props)

        let fulldate = new Date();
        let converted_date = moment(fulldate).format("YYYY-MM-DD");


        this.state = {
            OTindentRequestListData: [],
            PaginationData: [],
            current_page: 1,
            records_per_page: 10,

            startDate: converted_date,
            endDate: converted_date,
            filterDate: "",
            showDateFilter: false,

            SelectedStatus: "",
            SearchIndentNumber: "",

            menu: this.props.menu ? this.props.menu : "",
            selectedScreen: this.props.selectedScreen ? this.props.selectedScreen : "",

            loginUserData: this.props.loginUserData ? this.props.loginUserData : {},

            statusUpdateData: {},

            declinedComment: '',
            isDeclineComment: false,

            showResponse: "",
            showErrorResponse: "",
            responseMessage: "",
        }
        this.service = new Service()
    }

    componentDidMount() {
        this.getIndentRequestList();
    }

    getIndentRequestList() {
        this.setState({
            PaginationData: [],
            current_page: 1,
            OTindentRequestListData: []
        })
        let url = Constants.OT_INDENT_REQUEST_LIST +
            "?from_date=" + this.state.startDate +
            "&to_date=" + this.state.endDate +
            "&status=" + this.state.SelectedStatus +
            "&indent_number=" + this.state.SearchIndentNumber
        this.service.getApiCall(
            url,
            this.getIndentRequestListSuccess,
            this.getIndentRequestListFailure
        )
    }

    getIndentRequestListSuccess = (response) => {
        if (response.status === "Success") {
            this.setState({
                OTindentRequestListData: response.data,
            });
        }
    }

    getIndentRequestListFailure = (response) => {
        console.log(response)
    }

    paginationChangedValues(data) {
        this.setState({
            PaginationData: data
        })
    }

    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }

    showSelectedFilteredDate(date) {
        this.setState({
            startDate: date.fromDate,
            endDate: date.toDate,
            showDateFilter: false
        }, () => { this.getIndentRequestList() })
    }
    _disableDateFilter = (flag) => {

        this.setState({
            showDateFilter: flag
        })
    }

    onPressActions = (item, status) => {
        var url = Constants.INDENT_STATUS_POST;
        var updateItem = {
            "indent_number": item.indent_number,
        }
        updateItem['status'] = status === "Approved" ? "Approved" : "Declined";
        if (this.state.declinedComment) {
            updateItem['comments'] = this.state.declinedComment;
        }
        if (status === 'Declined' && this.state.declinedComment == "") {
            this.responseSuccessErrorMessage("Please enter the decline comment", false)

            setTimeout(() => {
                this.responseSuccessErrorMessage("", false)
            }, 3000)
        } else {
            var data = updateItem
            this.service.postApiCall(
                url,
                data,
                this._onPressActionSuccess,
                this._onPressActionFailure
            )
        }
    }

    _onPressActionSuccess = (response) => {
        if (response.status == 'Success') {
            this.setState({
                statusUpdateData: {},
                isDeclineComment: false,
                declinedComment:""
            }, () => { this.getIndentRequestList() })
            this.responseSuccessErrorMessage(response.message, true)

            setTimeout(() => {
                this.responseSuccessErrorMessage("", true)
            }, 3000)
        } else {
            this.responseSuccessErrorMessage(response.message, false)

            setTimeout(() => {
                this.responseSuccessErrorMessage("", false)
            }, 3000)
        }
    }
    _onPressActionFailure = (error) => {
        this.responseSuccessErrorMessage(error.message, false)
        setTimeout(() => {
            this.responseSuccessErrorMessage("", false)
        }, 3000)
    }

    responseSuccessErrorMessage(message, type) {
        if (message != "") {
            if (type) {
                this.setState({
                    showResponse: true,
                    responseMessage: message,
                    showErrorResponse: false
                })
            } else {
                this.setState({
                    showResponse: false,
                    showErrorResponse: true,
                    responseMessage: message
                })
            }
        } else {
            this.setState({
                showResponse: false,
                showErrorResponse: false,
            })
        }
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    render() {
        return (
            <View>
                <View style={{justifyContent: 'space-between', padding: 20, alignContent: 'center', flexDirection: 'row-reverse', zIndex: 1}}>
                <View style={styles.Datepicker}>
                    <CommonDateFilter
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        filterDate={this.state.filterDate}
                        showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                        defaultSelectedDateFilter={"Today"}
                        showDateFilter={this.state.showDateFilter}
                        disableDateFilter={this._disableDateFilter.bind(this)}
                    />
                    <View style={styles.SearchBoxView}>
                        <TextInput
                            value={this.state.SearchIndentNumber}
                            onChangeText={(text) => {
                                var isValid = this.numberValidation(text)
                                if (isValid || text === "") {
                                    this.setState({ SearchIndentNumber: text })
                                }
                            }}
                            style={styles.SearchBoxTxtInput}
                            placeholder="Search Indent Number..."
                        />
                        <TouchableOpacity
                            style={styles.SearchBtn}
                            onPress={() => {
                                this.getIndentRequestList();
                            }}>
                            <Icon name='search' size={'0.97vw'} color={color.white}></Icon>
                        </TouchableOpacity>
                    </View>

                    <View>
                        <Text style={{ zIndex: 2, fontSize: '0.7vw', position: "absolute", marginTop: '-0.5vw', marginLeft: '0.65vw', backgroundColor: "white", paddingHorizontal: '0.32vw', color: "#888888" }}>Filter by Status</Text>
                        <Picker
                            selectedValue={this.state.SelectedStatus}
                            style={styles.PickerStyle}
                            itemStyle={{}}
                            onValueChange={(itemvalue) => {
                                this.setState({ SelectedStatus: itemvalue }, () => { this.getIndentRequestList() });
                            }}
                        >
                            {
                                StatusType.map((list, index) => (
                                    <Picker.Item key={index} label={list.label} value={list.value} />
                                ))
                            }
                        </Picker>
                    </View>
                </View>
                <View>
                    {
                        this.props.FromInventory ?

                            <MenuBreadCrumpList menu={this.state.menu} selectedScreen={this.state.selectedScreen} changeScreen={this.props.changeScreen} />
                        : null
                    }
                </View>
                </View>
                <View style={styles.TableContainer}>
                    <View>
                        <View style={{ width: "100%" }}>
                            <View style={[styles.TableTitleContainer]}>
                                <View style={{ alignItems: "center", width: '17%' }} >
                                    <Text style={styles.TableHeadText}>S No</Text>
                                </View>
                                <View style={{ alignItems: "center", width: '17%' }} >
                                    <Text style={styles.TableHeadText}>Date</Text>
                                </View>
                                <View style={{ alignItems: "center", width: '17%' }} >
                                    <Text style={styles.TableHeadText}>Indent Number</Text>
                                </View>
                                <View style={{ alignItems: "center", width: '17%' }} >
                                    <Text style={styles.TableHeadText}>No of Items</Text>
                                </View>
                                <View style={{ alignItems: "center", width: '17%' }} >
                                    <Text style={styles.TableHeadText}>Status</Text>
                                </View>
                                <View style={{ alignItems: "center", width: '17%' }} >
                                    <Text style={styles.TableHeadText}>Action</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <ScrollView style={styles.TableDataScroll}>
                        {this.state.PaginationData && this.state.PaginationData.length > 0 && this.state.PaginationData.map((item, index) => {
                            let sno = (this.state.current_page - 1) * this.state.records_per_page + index + 1;
                            return (
                                <TouchableOpacity onPress={() => { this.props.NavigateScreen("OTDetails", item) }}>
                                    <View key={index} style={styles.TableDataWrapper} >
                                        <View style={{ width: '17%', alignItems: 'center' }}><Text style={styles.TableDataText}>{sno}</Text></View>
                                        <View style={{ width: '17%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.indent_date__date}</Text></View>
                                        <View style={{ width: '17%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.indent_number}</Text></View>
                                        <View style={{ width: '17%', alignItems: 'center' }}><Text style={styles.TableDataText}>{item.no_of_line_items}</Text></View>
                                        <View style={{ width: '17%', alignItems: 'center' }}>

                                            {
                                                item.status == "Declined" ?
                                                    <Tooltip placement="topLeft" title={`Declined due to ${item.comments}`}>
                                                        <Text style={[styles.TableDataText, { color: 'red' }]}>Declined</Text>
                                                    </Tooltip>
                                                    :
                                                    <Text style={[styles.TableDataText, { color: item.status == "Approved" ? 'green' : 'black' }]}>
                                                        {item.status}
                                                    </Text>

                                            }
                                        </View>
                                        <View style={{ width: '17%', }}>
                                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: '4vw' }}>
                                                <View>
                                                    <TouchableOpacity onPress={() => {
                                                        this.props.NavigateScreen("OTDetails", item)
                                                    }} style={{ marginLeft: '1.3vw' }}>
                                                        <Tooltip placement="top" title={'View List'}>
                                                            <Icon name='eye' size={'1.5vw'} color={color.themeDark}></Icon>
                                                        </Tooltip>
                                                    </TouchableOpacity>

                                                </View>
                                                {
                                                    this.state.loginUserData.data.is_admin_access && item.status == "Indent Placed" ?
                                                        <View>
                                                            <TouchableOpacity onPress={() => {
                                                                this.setState({
                                                                    selectedIndex: index,
                                                                    statusUpdateData: item,
                                                                    isDeclineComment: true
                                                                })
                                                            }} style={{ marginLeft: '1.3vw' }}>
                                                                <Tooltip placement="top" title={'Decline'}>
                                                                    <Image style={styles.DeclineIcon} source={require('../../../../assets/Icon/cancel.png')} />
                                                                </Tooltip>
                                                            </TouchableOpacity>
                                                        </View> :
                                                        null
                                                }
                                            </View>

                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )
                        })}
                        {this.state.OTindentRequestListData.length === 0 ?
                            <View style={styles.NoRecordsView}>
                                <Text style={styles.NorecordText}>No Records Found</Text>
                            </View>
                            : null}
                    </ScrollView>
                </View>
                <View style={styles.PaginationView}>
                    {
                        this.state.OTindentRequestListData && this.state.OTindentRequestListData.length > 0 ?
                            <Pagination
                                paginationChangedValues={this.paginationChangedValues.bind(this)}
                                totalClientItems={this.state.OTindentRequestListData}
                                recordsPerPage={this.state.records_per_page}
                                paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                            /> : null
                    }
                </View>
                <View style={{ position: "absolute", width: "100%", flex: 1, top: '80vh', zIndex: 12 }}>
                    {
                        this.state.showResponse ?
                            <SuccessAlert message={this.state.responseMessage} /> : null
                    }
                    {
                        this.state.showErrorResponse ?
                            <ErrorAlert message={this.state.responseMessage} /> : null
                    }
                </View>
                {this.state.isDeclineComment ?
                    <View style={styles.CommentPopUpView}>
                        <View style={styles.CommentPopWrapper}>
                            <View style={styles.CommentPopHeading}>
                                <Text style={styles.CommentHeadingTxt}>Enter your Reason For Decline Indent : </Text>
                                <Text style={styles.CommentHeadingRedTxt}>{this.state.statusUpdateData.indent_number}</Text>
                            </View>
                            <TextInput
                                value={this.state.declinedComment}
                                multiline={true}
                                onChangeText={(text) => {
                                    this.setState({
                                        declinedComment: text
                                    })
                                }}
                                style={styles.CommentTxtInput}
                            />
                            <View style={styles.CommentPopBtnView}>
                                <TouchableOpacity onPress={() => {
                                    this.onPressActions(this.state.statusUpdateData, "Declined")
                                }}
                                    style={styles.CommentPopButton}
                                ><Text style={styles.CommentPopBtnText}>{"Submit"}</Text></TouchableOpacity>
                                <TouchableOpacity onPress={() => {
                                    this.setState({
                                        isDeclineComment: false,
                                        statusUpdateData: {},
                                        declinedComment: '',
                                    })
                                }}
                                    style={styles.CommentPopButton}
                                ><Text style={styles.CommentPopBtnText}>{"Cancel"}</Text></TouchableOpacity>
                            </View>
                        </View>

                    </View>
                    : null
                }
            </View>
        )
    }
}

export default OTIndentRequestList

const styles = StyleSheet.create({
    TableContainer: {
        marginLeft: '1vw',
        marginRight: '1vw',
        marginTop: '0.5vw'
    },
    TableDataScroll: {
        height: '60vh'
    },
    Datepicker: {
        display: 'flex',
        flexDirection: 'row-reverse',
        // marginRight: "1.3vw",
        // alignItems: 'center'
    },
    TableHeadText: {
        fontSize: '0.91vw'
    },
    TableDataText: {
        fontSize: '0.91vw'
    },
    TableDataView: {
        justifyContent: 'center',
        maxHeight: '2.9vw',
        minHeight: '2.9vw',
        width: "100%",
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2vw",
        paddingHorizontal: ".5vw"
    },
    TableDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "2.9vw",
        alignItems: "center",
        paddingHorizontal: ".5vw"
    },
    NoRecordsView: {
        alignItems: "center",
        width: "100%",
        marginTop: "15%"
    },
    NorecordText: {
        fontSize: '0.97vw'
    },
    PaginationView: {
        marginHorizontal: '1.3vw'
    },
    SearchBoxView: {
        flexDirection: "row",
        marginRight: '1.2vw',

    },
    SearchBoxTxtInput: {
        borderWidth: '0.06vw',
        height: '2.28vw',
        width: '11.06vw',
        paddingHorizontal: '0.19vw',
        borderColor: color.lightGray,
        fontSize: '0.91vw',
        borderBottomStartRadius: '0.26vw',
        borderTopLeftRadius: '0.26vw',
    },
    SearchBtn: {
        height: '2.28vw',
        width: '2.28vw',
        backgroundColor: color.themeDark,
        justifyContent: "center",
        alignItems: "center",
        borderTopEndRadius: '0.26vw',
        borderBottomRightRadius: '0.26vw',
    },
    PickerStyle: {
        height: "5vw",
        marginRight: '2vw',
        backgroundColor: "transparent",
        borderRadius: '0.26vw',
        flex: 0.5,
        borderColor: '#CDD1D5',
        width: '13vw',
        fontSize: '0.91vw',
        padding: '0.4vw',
        cursor: 'pointer'
    },
    BreadCrump: {
        marginLeft: '2vw',
        marginRight: '5vw',
        marginTop: '1vw'
    },
    Icon: {
        height: '1.62vw',
        width: '1.62vw',
        tintColor: color.themeDark
    },
    DeclineIcon: {
        height: '1.62vw',
        width: '1.62vw',
        tintColor: color.red
    },
    CommentPopUpView: {
        alignItems: "center", height: "83vh", width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', zIndex: 10
    },
    CommentPopWrapper: {
        justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: '0.26vw', marginVertical: '0.65vw', height: "40%", width: "32%"
    },
    CommentPopHeading: {
        display: 'flex', flexDirection: 'row'
    },
    CommentTxtInput: {
        width: "80%", height: "50%", padding: '0.65vw', borderWidth: 1, borderRadius: '0.26vw', margin: '0.65vw', borderColor: color.lightGray, fontSize: '0.91vw'
    },
    CommentPopBtnView: {
        flexDirection: "row", height: '1.95vw', flex: 1, width: "40%", justifyContent: "space-around", flexWrap: "wrap-reverse", marginBottom: '0.5vw'
    },
    CommentPopButton: {
        backgroundColor: color.themeDark, height: '1.95vw', justifyContent: "center", alignSelf: "center", paddingHorizontal: '0.65vw', borderRadius: '0.26vw'
    },
    CommentPopBtnText: {
        fontSize: '0.8vw', color: color.white
    },
    CommentHeadingRedTxt: {
        color: 'red', fontWeight: 500, fontSize: '0.97vw', marginTop: '0.65vw',
    },
    CommentHeadingTxt: {
        fontWeight: 500, fontSize: '0.97vw', marginTop: '0.65vw',
    }
})