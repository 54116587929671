import React, { Component } from "react";
import { View, Text, TextInput, TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import { CommonReportSectionHeader, CommonButton,SuccessAlert, ErrorAlert } from "../../common/BaseFunctionComponent";
import Icon from "react-native-vector-icons/FontAwesome";
import Pagination from "../../common/Pagination";
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import { color } from "../../styles/Color";
import { Tooltip } from "antd";

export default class WantedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                total_count: 0,
                no_of_pages: 0,
                result: []
            },
            drugRow: [],
            paginationNewDrugRequestList: [],
            vendor_list: [],
            vendor_list_suggest: [],
            isVendorSuggestion: false,
            current_page: 1,
            records_per_page: 10,
            searchValue: this.props.searchValue ? this.props.searchValue : "",
            isClicked: false,
        }
        this.service = new Service();
    }

    componentDidMount() {
        this._getWantedList();
        this._getVendorList();
    }

    onClickMoveToOrder = (item) => {
        var {drugRow} = this.state
        var url = Constants.MOVE_ORDER_LIST
        var data = {
            vendor_id: item.vendor_id ? item.vendor_id : '',
            drug_id: item.drug_id,
            stock_id: item.stock_id,
            order_quantity: item.suggested_quantity ? item.suggested_quantity : 0
        }

        this.service.postApiCall(
            url,
            data,
            this.successToOrder,
            this.errorToOrder
        )
    }

    successToOrder = (response) => {
    if(response.status === "success"){
        this.props.responseSuccessErrorMessage(response.message, true)
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", true)
            }, 2000)
        this._getWantedList()
    }else if (response.status == "fail") {
        this.props.responseSuccessErrorMessage(response.message, false)
        setTimeout(() => {
            this.props.responseSuccessErrorMessage("", false)
        }, 2000)
    }
    }

    errorToOrder = (response) => {
        this.props.responseSuccessErrorMessage(response.message, false)
        setTimeout(() => {
            this.props.responseSuccessErrorMessage("", false)
        }, 2000)
    }

    _getWantedList = () => {
        let { searchValue, current_page, records_per_page } = this.state;
        let url = "";
        this.setState({
            // current_page: 1,
            // records_per_page: 10,
            drugRow: [],
            paginationNewDrugRequestList: []
        })
        if (searchValue) {
            url = `${Constants.WANTED_LIST}?search_key=${searchValue ? searchValue : ""}&page=${current_page}&page_size=${records_per_page}`;
        }else {
            url = `${Constants.WANTED_LIST}?page=${current_page}&page_size=${records_per_page}`
        }


        if (url) {
            this.service.getApiCall(
                url,
                this.successCallBack,
                this.errorCallBack
            )
        }
    }

    successCallBack = async (response) => {

        let { data,drugRow } = this.state;

        if (response.detail && response.detail === "Token is expired") {
            this.props.logout();
        }
        else if (response.status == "success") {

            drugRow = response.data ? response.data : []
            data['result'] = response.data ? response.data : [];
            data['total_count'] = response.data ? response.total_count : 0;
            data['no_of_pages'] = response.data ? response.no_of_pages : 0;
           
            this.setState({
                data,
                drugRow
            })

        } else if (response.status == "fail") {

            this.setState({
                responseMessage: response.message
            }, () => {
            })
        }
    }

    errorCallBack = (error) => { }

    _getVendorList() {
        let url = Constants.LIST_VENDOR + "?from=purchase_order";
        this.service.getApiCall(
            url,
            this._getVendorListSuccess,
            this._getDosageTypeFailure
        )
    }

    _getVendorListSuccess = response => {

        if (response.status === "success") {

            this.setState({
                vendor_list: response.data,
                vendor_list_suggest: response.data
            });
        }
    };

    _getDosageTypeFailure = response => {}

    renderTopHeader() {
        
        return (
            <View style={{ flexDirection: "row", justifyContent: "flex-end", marginHorizontal: "1.25vw" , marginBottom: "0.625vw"}}>
                <View style={{ flexDirection: "row", marginRight: " 0.625vw" }}>
                    <TextInput
                        value={this.state.searchValue}
                        onChangeText={(text) => {
                            this.setState({
                                searchValue: text
                            })
                        }}
                        style={{ borderWidth: 1, height: "2.2vw", width: "10.62vw", paddingHorizontal: "0.19vw", borderColor: color.lightGray, fontSize: "1vw" }}
                        placeholder="Search Wanted List..."
                    />
                    <TouchableOpacity
                        style={{ height: "2.2vw", width: "2.2vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                        onPress={() => {
                            this._getWantedList()
                        }}>
                        <Icon name='search' size={"0.93vw"} color={color.white}></Icon>
                    </TouchableOpacity>
                </View>
                <TouchableOpacity
                    style={[styles.ButtonStyle, { marginRight: " 0.625vw"}]}
                    onPress={() => this.props.NavigateScreen("orderList")}
                >
                    <Text style={styles.ButtonText}>Order List</Text>
                </TouchableOpacity>
            </View>
        )
    }

    vendorSuggestionList = () => {
        let {vendor_list, drugRow, data, selectedIndex, current_page, records_per_page} = this.state
        let tempDescList = this.state.vendor_list_suggest
        let prev_record_count = (parseInt(current_page)-1)*parseInt(records_per_page)
        if (tempDescList.length > 0) {
            var searchListTemp = [];
            for (let i = 0; i < tempDescList.length; i++) {
      
              if (tempDescList[i].name.toLowerCase().indexOf(data.result[prev_record_count + selectedIndex]['vendor_name'].toLowerCase()) > -1) {
                searchListTemp.push(tempDescList[i]);
              }
            }
            vendor_list = searchListTemp;
          } else {
            vendor_list = this.state.vendor_list_suggest;
          }
          this.setState({
            vendor_list
          })
    }

    getWantedTable = () => {
        let { data, current_page, records_per_page, drugRow, paginationNewDrugRequestList } = this.state;
        var states = this.state
        var suggestionHeight = ((states.selectedIndex + 1) * 9) + 30
        let prev_record_count = (parseInt(current_page) - 1) * parseInt(records_per_page)
        return (
            <View style={styles.TableContainer}>
                <ScrollView style={{ maxHeight: '65vh' }}>
                    <View style={{ flexDirection: "row", width: "100vw" }}>
                        <View style={{ width: "15vw" }}>
                            <CommonReportSectionHeader
                                heading1={"S.No"}
                                heading2={"Brand Name"}
                                columnSize={[0.20, 0.80]}
                                noOfColumn={2}
                                fontSize={'1vw'}
                            />
                            {data.result && data.result.map((item, index) => {
                                let sno = prev_record_count + (index + 1);
                                return (
                                    <View style={{ justifyContent: 'center', maxHeight: 60, minHeight: 60 }} key={index}>
                                        <View style={{ flex: 1, flexDirection: "row", borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, alignItems: 'center' }}>
                                            <Text style={{ marginLeft: '1.3vw', fontSize: "1vw", flex: 0.20 }}>{sno}</Text>

                                            <Tooltip placement="topLeft" title={item.drug_name}>
                                                <Text style={{ fontSize: "1vw", flex: 0.80 }}> {item.drug_name && item.drug_name.length > 20 ? item.drug_name.slice(0, 20) + ".." : (item.drug_name != null && item.drug_name != "") ? item.drug_name : "-"} </Text>
                                            </Tooltip>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                        <ScrollView horizontal={true} showsHorizontalScrollIndicator={(data.result && data.result.length > 0) ? true : false} style={{ maxWidth: "65vw", flex: 1, minHeight: suggestionHeight + 'vh' }}>
                            <View style={{ width: "90vw" }}>
                                <CommonReportSectionHeader
                                    heading1={"Generic Name"}
                                    heading2={"Dosage Type/Strength"}
                                    heading3={"Recent Exp. On"}
                                    heading4={"Avl. Qty"}
                                    heading5={"Latest MRP/Qty"}
                                    heading6={"Place order Vendor"}
                                    heading7={"Place order Qty"}
                                    columnSize={[0.15, 0.20, 0.12, 0.12, 0.15, 0.20, 0.15]}
                                    noOfColumn={7}
                                />
                                {data.result && data.result.map((item, index) => (
                                    <View style={{ justifyContent: 'center', maxHeight: 60, minHeight: 60, zIndex: 1 - index }} key={index}>
                                        <View style={{ flexDirection: 'row', flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 1, alignItems: 'center' }}>
                                            <Tooltip placement="topLeft" title={item.generic_name}>
                                                <Text style={{ flex: 0.15, marginLeft: '1.3vw', fontSize: "1vw" }}> {item.generic_name && item.generic_name.length > 18 ? item.generic_name.slice(0, 20) + ".." : (item.generic_name != null && item.generic_name != "") ? item.generic_name : "-"} </Text>
                                            </Tooltip>
                                            <Tooltip placement="topLeft" title={item.dosage_type + "/" + item.drug_strength}>
                                                <Text style={{ flex: 0.20, fontSize: "1vw" }}> {item.dosage_type && item.dosage_type.length > 15 ? item.dosage_type.slice(0, 15) + ".." : (item.dosage_type != null && item.dosage_type != "") ? item.dosage_type : "-"}{"/"}{item.drug_strength ? item.drug_strength : "-"} </Text>
                                            </Tooltip>
                                            <Text style={{ flex: 0.12, fontSize: "1vw" }}>{item.expiry_date}</Text>
                                            <Text style={{ flex: 0.12, fontSize: "1vw" }}>{item.available_quantity}</Text>
                                            <Text style={{ flex: 0.15, fontSize: "1vw" }}>{item.mrp_per_quantity}</Text>
                                            <View style={{ flex: 0.20, zIndex: 0 }}>
                                                <TextInput
                                                    style={{
                                                        borderColor: "#CDD1D5",
                                                        borderRadius: '.26vw',
                                                        borderWidth: 1,
                                                        width: "80%",
                                                        height: '5vh',
                                                        paddingLeft: '0.65vw',
                                                        marginTop: '.52vw',
                                                        zIndex: -1,
                                                        fontSize: "1vw"
                                                    }}
                                                    value={item.vendor_name ? item.vendor_name : ''}
                                                    onChangeText={(text) => {
                                                        if (text != "") {
                                                            states['isVendorSuggestion'] = true;
                                                            data.result[prev_record_count + index]['vendor_id'] = null;
                                                            states["selectedIndex"] = index
                                                        } else {
                                                            states['isVendorSuggestion'] = false;
                                                            data.result[prev_record_count + index]['vendor_id'] = null;
                                                            states["selectedIndex"] = null
                                                        }
                                                        data.result[prev_record_count + index]['vendor_name'] = text

                                                        this.setState({
                                                            data,
                                                            states
                                                        }, () => {
                                                            if ((text).trim() != "") {
                                                                this.vendorSuggestionList()
                                                            }
                                                        })
                                                    }}
                                                />
                                                {states.isVendorSuggestion && states.vendor_list.length > 0 && index === states.selectedIndex ?
                                                    <View style={{ zIndex: 2, position: 'absolute', marginTop: 40, width: "90%" }}>
                                                        <ScrollView
                                                            style={{
                                                                maxHeight: '25vh', marginBottom: 10, flex: 1,
                                                                backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                                                            }}
                                                        >
                                                            {this.state.vendor_list.map((value, index) =>
                                                                <View style={{ height: 30 }}>
                                                                    <TouchableOpacity key={index}
                                                                        style={{ marginLeft: 5, marginTop: 5 }}
                                                                        onPress={
                                                                            () => {
                                                                                data.result[prev_record_count + states.selectedIndex]['vendor_name'] = value.name
                                                                                data.result[prev_record_count + states.selectedIndex]['vendor_id'] = value.vendor_id,
                                                                                    states["selectedIndex"] = null,
                                                                                    states["isVendorSuggestion"] = false
                                                                                this.setState({
                                                                                    data,
                                                                                    states
                                                                                })
                                                                            }
                                                                        }
                                                                    >
                                                                        <Text style={{ fontSize: "1vw" }}>{value.name}</Text>

                                                                    </TouchableOpacity>
                                                                </View>
                                                            )}
                                                        </ScrollView>
                                                    </View>
                                                    : null
                                                }
                                            </View>
                                            <View style={{ flex: 0.15 }}>
                                                <TextInput
                                                    style={{
                                                        borderColor: "#CDD1D5",
                                                        borderRadius: '.26vw',
                                                        borderWidth: 1,
                                                        width: "60%",
                                                        height: '5vh',
                                                        paddingLeft: '0.65vw',
                                                        marginTop: '.52vw',
                                                        fontSize: "1vw"
                                                    }}
                                                    value={item.suggested_quantity ? item.suggested_quantity : ''}
                                                    onChangeText={(text) => {
                                                        if ((Constants.REG_EX_NUMBERS.test(text))) {
                                                            data.result[prev_record_count + index]['suggested_quantity'] = text
                                                        }
                                                        this.setState({
                                                            data
                                                        })
                                                    }}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        </ScrollView>
                        <View style={{ width: "10vw" }}>
                            <CommonReportSectionHeader
                                heading1={"Action"}
                                columnSize={[1]}
                                noOfColumn={1}
                                fontSize={'1vw'}
                            />
                            {data.result && data.result.map((item, index) => (
                                <View key={index} style={{ flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, width: "100%", maxHeight: 60, minHeight: 60 }}>
                                    <View key={index} style={{ flex: 1, marginHorizontal: "1.30vw", justifyContent: 'center', alignItems: 'center' }}>
                                        <CommonButton
                                            item={{}}
                                            selectedvalue={{}}
                                            butttonText={"Move to Order"}
                                            buttonType={"theme"}
                                            buttonIcon={""}
                                            rightIcon={true}
                                            buttonAction={() => {
                                                this.onClickMoveToOrder(item, index)
                                            }}
                                            buttonKey={"moveToOrder"} />
                                    </View>
                                </View>
                            ))}
                        </View>
                    </View>
                </ScrollView>
            </View>
        )
    }

    _paginationChangedValues(next_page, records_per_page_change) {

        let { current_page, records_per_page } = this.state

        if (current_page != next_page || records_per_page != records_per_page_change) {

            this.setState({
                records_per_page: records_per_page_change,
                current_page: next_page
            }, () => {
                this._getWantedList()
            })
        }
    }

    render() {
        let { data } = this.state;
        return (
            <View style={{ paddingHorizontal:"1.25vw" }}>
                {this.renderTopHeader()}
                {this.getWantedTable()} 
                {data.total_count && parseInt(data.total_count) > 0 ?
                    <Pagination
                        paginationChangedValues={this._paginationChangedValues.bind(this)}
                        totalClientItems={""}
                        type={Constants.PAGINATION_TYPE_SERVER}
                        totalServerRecordsCount={data.total_count}
                        totalServerPagesCount={data.no_of_pages}
                    /> : null}              
            </View>
        )
    }
}

const styles = StyleSheet.create({
    TableContainer: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center"
    },
    TableWrapper: {
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#E2E2E2", padding: ".52vw", borderRadius: ".25vw"
    },
    TableBody: {
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#F9F9F9", padding: ".52vw", borderRadius: ".25vw", marginTop: ".32vw",
        borderBottomWidth: 1, borderColor: "#E2E2E2"
    },
    ButtonStyle: {
        width: '6vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.4vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize:"1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    }
});