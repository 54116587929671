import React, { Component } from "react";
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Image } from "react-native";
import moment from "moment";
import { BillTableTitle, BillTableData, CommonAutoSuggestion } from "../../common/BaseFunctionComponent";
import Pagination from "../../common/Pagination";
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import { color } from "../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";

export default class Vendors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                total_count: 0,
                no_of_pages: 0,
                result: []
            },
            vendor_list_suggest: [],
            isSelected: true,
            current_page: 1,
            records_per_page: 10,
            resportActions: true,
            searchValue: "",
            responseMessage: "",
        }
        this.service = new Service();
    }
    componentDidMount() {
        this._getVendorList();
    }
    componentWillReceiveProps(props) {
        this._getVendorList();
    }

    _getVendorList() {
        let { current_page, records_per_page, searchValue } = this.state;
        let url = "";

        if (!searchValue) {
            url = `${Constants.LIST_VENDOR}?&page=${current_page}&page_size=${records_per_page}`;
         } else {
            url = `${Constants.LIST_VENDOR}?search_term=${searchValue}&page=${current_page}&page_size=${records_per_page}`;
        }
        if (url) {
            this.service.getApiCall(
                url,
                this.successCallBack,
                this.errorCallBack
            )
        }
    }
    successCallBack = async (response) => {

        let { data } = this.state;

        if (response.detail && response.detail === "Token is expired") {
            this.props.logout();
        }else if (response.status == "success") {

            data['result'] = response.data ? response.data.result : [];
            data['total_count'] = response.data ? response.data.total_count : 0;
            data['no_of_pages'] = response.data ? response.data.no_of_pages : 0;

            data = JSON.parse(JSON.stringify(data))

            this.setState({
                data
            }, () => {
            })


        } else if (response.status == "fail") {

            this.setState({
                responseMessage: response.message
            }, () => {
            })
        }
    }

    errorCallBack = (error) => {}

    renderTopHeader() {
        let isDisable = this.props.isDisable ? this.props.isDisable : false;
        return (
            <View style={{ flexDirection: "row", justifyContent: "flex-end", marginHorizontal:"1.25vw" }}>
                 <View style={{ flexDirection: "row", marginRight: " 0.625vw" }}>
                    <TextInput
                        value={this.state.searchValue}
                        onChangeText={(text) => {
                            this.setState({
                                searchValue: text
                            })
                        }}
                        style={{ borderWidth: 1, height: "2.2vw", width: "10.62vw", paddingHorizontal: "0.19vw", borderColor: color.lightGray, fontSize: "1vw" }}
                        placeholder="Search Vendor List..."
                    />
                    <TouchableOpacity
                        style={{ height: "2.2vw", width: "2.2vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                        onPress={() => {
                            this._getVendorList()
                        }}>
                        <Icon name='search' size={"0.93vw"} color={color.white}></Icon>
                    </TouchableOpacity>
                </View>
                <TouchableOpacity
                    style={[styles.ButtonStyle, { marginRight: 10 }]}
                    onPress={() => this.props.NavigateScreen("add_vendor")}
                >
                    
                    <Text style={styles.ButtonText}>+Vendor</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => {
                        !isDisable?this.props.generateReport('pdf'):null
                    }}
                    style={[styles.printContainer, { marginRight: 10 }]}>
                    <Image style={styles.Image}
                        source={require('../../../assets/images/HomeScreenImages/Print.png')} />
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => {
                        !isDisable?this.props.generateReport('excel'):null
                    }}
                    style={styles.printContainer}>
                    <Image style={styles.Image}
                        source={require('../../../assets/images/HomeScreenImages/Export.png')} />
                </TouchableOpacity>
            </View>
        )
    }



    render() {
        let { data } = this.state;
        return (
            <View>
                {this.renderTopHeader()}
                <View style={styles.TableContainer}>
                    <View style={styles.TableWrapper}>
                        <BillTableTitle label={'S.No'} flexWidth={0.08} />
                        <BillTableTitle label={'Registration Date'} flexWidth={0.12} />
                        <BillTableTitle label={'Vendor Name'} flexWidth={0.2} />
                        <BillTableTitle label={'Registration Number'} flexWidth={0.2} />
                        <BillTableTitle label={'Phone Number'} flexWidth={0.2} />
                        <BillTableTitle label={'Email Id'} flexWidth={0.2} />
                    </View>
                    {data.result && data.result.map((item, index) => {
                        let sno = index + 1;
                        let registration_date = item.registration_date ? moment(item.registration_date).format("DD-MM-YYYY") : ""
                        return (
                            <TouchableOpacity key={index} onPress={() => this.props.openVendorDetails(item.vendor_id)}>
                                <View style={styles.TableBody}>
                                    <BillTableData label={sno} labelType={'label'} flexWidth={0.08} />
                                    <BillTableData label={registration_date} labelType={''} flexWidth={0.12} />
                                    <BillTableData label={item.name} labelType={''} flexWidth={0.2} />
                                    <BillTableData label={item.dl_no} labelType={''} flexWidth={0.2} />
                                    <BillTableData label={item.mobile_number} labelType={''} flexWidth={0.2} />
                                    <BillTableData label={item.email} labelType={''} flexWidth={0.2} />
                                </View>
                            </TouchableOpacity>
                        )
                    })}
                </View>
                <View style={{ marginHorizontal: 20 }}>
                    {data.total_count && parseInt(data.total_count) > 0 ?
                        <Pagination
                            paginationChangedValues={this._paginationChangedValues.bind(this)}
                            totalClientItems={""}
                            type={Constants.PAGINATION_TYPE_SERVER}
                            totalServerRecordsCount={data.total_count}
                            totalServerPagesCount={data.no_of_pages}
                        /> : null}
                </View>

            </View>
        )
    }

    _paginationChangedValues(next_page, records_per_page_change) {

        let { current_page, records_per_page } = this.state

        if (current_page != next_page || records_per_page != records_per_page_change) {

            this.setState({
                records_per_page: records_per_page_change,
                current_page: next_page
            }, () => {
                this._getVendorList();
            })
        }
    }
}


const styles = StyleSheet.create({
    printContainer: {
        width: '2.60vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.32vw',
        paddingVertical: '0.16vw'
    },
    Image: {
        height: '1.95vw',
        width: '1.95vw'
    },
    TableContainer: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center",
        padding: '1.30vw'
    },
    TableWrapper: {
        flex: 1, flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "center",
        backgroundColor: "#E2E2E2",
        padding: "0.52vw",
        borderRadius: ".25vw"
    },
    TableBody: {
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#F9F9F9", padding: ".52vw", borderRadius: ".25vw", marginTop: ".32vw",
        borderBottomWidth: 1, borderColor: "#E2E2E2"
    },
    ButtonStyle: {
        width: '6vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.4vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize:"1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    }
})

