import React, { Component } from 'react';
import { View, Text, Dimensions, ScrollView, TouchableOpacity, StyleSheet } from 'react-native';
import { color } from "../../styles/Color";
import Style from "../../styles/Style";
import Constants from "../../networks/Constants";
import Service from '../../networks/service';
import moment from "moment"

import { CommonTextBox, CommonAutoSuggestion, MenuBreadCrumpList, BillTableTitle, BillTableData, CommonDatePicker, SuccessAlert, ErrorAlert, ActionButton } from "../../common/BaseFunctionComponent";
import StockDetailsRight from "./StockDetailsRight";
import ReturnDetailsRight from "./ReturnDetailsRight";
import BaseComponent from "../../common/BaseComponent";

const screenHeight = Dimensions.get("window").height;
const DIRECT_PO = "directPO";
const VIEW_STOCK = "view_stock"
const ADD_STOCK = "add_stock";

export default class StockDetails extends BaseComponent {

    constructor(props) {
        super(props);
        let defaultDisabled = this.props.screenType === DIRECT_PO ? false : true;
        this.state = {
            menu: this.props.menu,
            selectedScreen: this.props.selectedScreen,
            screenType: this.props.screenType,
            defaultDisabled: defaultDisabled,
            // get from list
            purchase_id: this.props.purchase_id,
            // for vendor
            vendor_id: null,
            vendor_name: "",
            vendor_list: [],
            vendor_list_suggest: [],
            isSelected: true,
            // for PO
            po_number: "",
            po_date: null,
            delivery_date: this.props.screenType === DIRECT_PO ? new Date() : "",
            po_line_items: [],
            cgst_amount: 0,
            sgst_amount: 0,
            "bill_amount": "",
            // for stock
            stock_items: [],
            from_api_call: false,
            stocklist_id: this.props.stocklist_id ? this.props.stocklist_id : null,
            infoHeight: 100,
            // for bill
            bill_id: "",
            isActionDisabled: false,
            conformationPopupFlag: false,
            isYesButton:false,
            totalGSTAmount: 0,
            statusDelivery: this.props.statusDelivery
        }
        this.service = new Service()
    }

    _onContentSizeChange() {
        let initialYScroll = this.state.scrollPosition;
        this.scrollView.scrollTo({ x: 0, y: initialYScroll, animated: true });
    };

    componentDidMount() {

        if (this.state.screenType === DIRECT_PO) {
            this._getVendorList()
        } else if (this.state.selectedScreen === VIEW_STOCK) {
            this._viewStockDetails()
        } else {
            if (this.state.purchase_id) {

                this._getPODetails()
            }
        }
    }

    _getPODetails() {
        let url = Constants.STOCK_ENTRY_ITEMS + "?purchase_order_id=" + this.state.purchase_id
        this.service.getApiCall(
            url,
            this._getStockDetails,
            this._getDosageTypeFailure
        )
    }

    _getStockDetails = response => {
        response = JSON.parse(JSON.stringify(response));
        if (response.status === "success") {
            let data = JSON.parse(JSON.stringify(response.data));
            let last_vendor_bill = data.latest_vendor_bill ? data.latest_vendor_bill : {};
            let stock_items = data.stock_items ? data.stock_items : {};

            // set delivery date 
            let delivery_date = ""
            if (last_vendor_bill && last_vendor_bill.delivery_date) {
                delivery_date = last_vendor_bill.delivery_date;
            } else {
                if (data.stock_items) {
                    let stockItems = [];
                    stockItems = data.stock_items
                    stockItems.forEach(element => {
                        if (element.delivery_date) {
                            delivery_date = element.delivery_date;
                        }
                    });
                }
            }
            if (data) {
                this.setState({
                    purchase_id: data.purchase_order_id,
                    po_number: data.purchase_order_number,
                    po_date: data.purchase_order_date ? moment(data.purchase_order_date) : null,
                    vendor_name: data.vendor_name,
                    vendor_id: data.vendor_id,
                    previous_vendor_bills: data.previous_vendor_bills,
                    last_vendor_bill: last_vendor_bill,
                    bill_id: last_vendor_bill ? last_vendor_bill.bill_id : "",
                    bill_no: last_vendor_bill ? last_vendor_bill.bill_no : "",
                    bill_date: last_vendor_bill ? this._setDataFormat(last_vendor_bill.bill_date, 'date') : "",
                    cgst_amount: last_vendor_bill.cgst_amount ? last_vendor_bill.cgst_amount : 0,
                    sgst_amount: last_vendor_bill.sgst_amount ? last_vendor_bill.sgst_amount : 0,
                    bill_amount: last_vendor_bill ? last_vendor_bill.total_amount : "",
                    delivery_date: delivery_date,
                    stock_items: data.stock_items,
                    po_line_items: data.po_line_items,
                    from_api_call: true,

                }, () => {
                });
            }
        }
    }

    _getPOnumber() {
        let url = Constants.PURCHASE_ORDER_NO_GET;
        this.service.getApiCall(
            url,
            this._getPOnoSuccess,
            this._getDosageTypeFailure
        )
    }

    _getPOnoSuccess = response => {
        if (response.status === "success") {
            let data = JSON.parse(JSON.stringify(response.data));
            this.setState({
                po_number: data ? data.po_number : null,
                po_date: new Date()
            }, () => {
                if (this.state.screenType == DIRECT_PO) {
                    this._doSave()
                }
            });
        }
    };

    _getVendorList() {
        let url = Constants.LIST_VENDOR + "?from=purchase_order";
        this.service.getApiCall(
            url,
            this._getVendorListSuccess,
            this._getDosageTypeFailure
        )
    }

    _getVendorListSuccess = response => {

        if (response.status === "success") {

            this.setState({
                vendor_list: response.data || [],
                vendor_list_suggest: response.data || []
            });
        }
    };

    _getDosageTypeFailure = response => {
    }

    _updateState = (stateKey, value, type = '') => {

        let states = this.state;
        let { last_vendor_bill, vendor_list } = this.state;
        if (stateKey == "cgst_amount" || stateKey == "sgst_amount" || stateKey == "bill_amount") {

            if (Constants.REG_EX_DECIMALS.test(value) || value != "") {

                states[stateKey] = value;
            }


        } else {
            states[stateKey] = value;
        }

        if (stateKey === "vendor_name") {

            states['isSelected'] = false;
            states['vendor_id'] = null;
            states['vendor_list_suggest'] = this._sortListData(vendor_list, value)

        } else if (stateKey === "bill_no") {

            if (last_vendor_bill && last_vendor_bill.bill_no == value) {

                states['bill_id'] = last_vendor_bill.bill_id;
            } else {
                states['bill_id'] = "";
            }
        }

        this.setState({
            states
        })
    }

    _itemSelected = (item, key, objFormat = {}) => {

        let states = this.state;
        if (item) {
            if (key === "vendor_name" && objFormat) {

                let name = item[objFormat["keyName"]];
                let vendor_id = item[objFormat["keyId"]];

                states['isSelected'] = true;
                states['vendor_name'] = name;
                states['vendor_id'] = vendor_id;
            }
            this.setState({
                states
            })
        }
    }

    _addItem = (itemIn) => {

        let { stock_items, po_line_items } = this.state;
        let item = JSON.parse(JSON.stringify(itemIn)) || null;
        let temp_po_items = JSON.parse(JSON.stringify(po_line_items));

        if (item) {
            if (this.state.screenType === DIRECT_PO) {
                let len = stock_items.length;
                let last_serial_no = len === 0 ? 0 : stock_items[len - 1]['serial_no'];
                let new_serial_no = parseInt(last_serial_no) + 1;
                let drug_repeat = false
                // new item
                if (!item['serial_no']) {
                    item['serial_no'] = new_serial_no;
                    if(new_serial_no != 1){
                        for(let i=0; i < stock_items.length; i++){
                            if((stock_items[i]["brand_name"]).toLowerCase() == (item.brand_name).toLowerCase() && 
                            (stock_items[i]["generic_name"]).toLowerCase() == (item.generic_name).toLowerCase() &&
                            (stock_items[i]["dosage_type"]).toLowerCase() == (item.dosage_type).toLowerCase() && 
                            (stock_items[i]["dosage_strength"]).toLowerCase() == (item.dosage_strength).toLowerCase() && 
                            (stock_items[i]["batch_no"]).toLowerCase() == (item.batch_no).toLowerCase()
                            ){
                                drug_repeat = true
                            }
                        }
                    }
                }else{
                    for(let i=0; i < stock_items.length; i++){
                        if((stock_items[i]["brand_name"]).toLowerCase() == (item.brand_name).toLowerCase() && 
                        (stock_items[i]["generic_name"]).toLowerCase() == (item.generic_name).toLowerCase() &&
                        (stock_items[i]["dosage_type"]).toLowerCase() == (item.dosage_type).toLowerCase() && 
                        (stock_items[i]["dosage_strength"]).toLowerCase() == (item.dosage_strength).toLowerCase() && 
                        (stock_items[i]["batch_no"]).toLowerCase() == (item.batch_no).toLowerCase() && 
                        stock_items[i]["serial_no"] != item.serial_no
                        ){
                            drug_repeat = true
                        }
                    }
                }
        

                // update item detail to specific index
                if (item['temp_id'] != undefined && !drug_repeat) {
                    let temp_id = item['temp_id']
                    stock_items[temp_id] = item
                } else{
                    // no specific index, add it as new one
                    if(!drug_repeat){
                        stock_items.push(item)
                    }else if(drug_repeat){
                        this.props.responseSuccessErrorMessage("Drug already in list", false)

                        setTimeout(() => {
                            this.props.responseSuccessErrorMessage("", false)
                        }, 3000)
                    }
                    
                }
            }
            else {
                let updated_serial_no = item.serial_no;
                let updated_batch_no = item.batch_no;
                if (updated_serial_no) {
                    // update item status
                    item["is_edited"] = true;
                    let entryType = item['entryType'];
                    if (entryType === "SE") {
                        // update
                        let getIndex = null;
                        for (let i = 0; i < stock_items.length; i++) {
                            // find serial no. index
                            if (stock_items[i]['serial_no'] == updated_serial_no
                                && stock_items[i]['batch_no'] == updated_batch_no) {
                                getIndex = i;
                            }
                        }

                        if (getIndex != null) {
                            // update
                            stock_items[getIndex] = item;
                        } else {
                            // For new batch, so new entry
                            item = (delete item['stock_id'], item);

                            stock_items.push(item);
                        }

                        this.setState({
                            stock_items: stock_items
                        })

                    } else if (entryType === "PO") {
                        // remove from po items
                        let removeIndex = -1;
                        for (let i = 0; i < temp_po_items.length; i++) {
                            // find serial no. index
                            if (temp_po_items[i]['serial_no'] === updated_serial_no) {

                                removeIndex = i;
                            }
                        }

                        if (removeIndex >= 0) {
                            let i = removeIndex;
                            // temp_po_items = temp_po_items.slice(removeIndex)
                            temp_po_items = temp_po_items.slice(0, i).concat(temp_po_items.slice(i + 1, temp_po_items.length))
                            // add to stock item
                            stock_items.push(item)
                        }
                    }
                }
            }

            this.setState({
                stock_items: JSON.parse(JSON.stringify(stock_items)),
                po_line_items: JSON.parse(JSON.stringify(temp_po_items)),
                selectedEntry: null
            }, () => {
            const totalGSTAmount = stock_items && stock_items.length > 0 && stock_items.map(item => item.total_gst_amount ? parseFloat(item.total_gst_amount) : 0 ) .reduce((prev,curr) => (prev + curr));
            // GST Amount Calculation
            const cgstAndSgstAmount = parseFloat( totalGSTAmount / 2 ).toFixed(2)
            // Total Bill Amount Calculation
            const totalBuying = stock_items && stock_items.length > 0 && stock_items.map(item => parseFloat(item.total_buying_price)).reduce((prev,curr) => (prev + curr));

            this.setState({
                sgst_amount: cgstAndSgstAmount ? cgstAndSgstAmount : 0,
                cgst_amount: cgstAndSgstAmount ? cgstAndSgstAmount : 0,
                bill_amount: totalBuying ? Math.round(totalBuying) : 0
            })
            })
        }
    }

    renderLineItems = (list, entryType = 'PO') => {

        let { isActionDisabled } = this.state
        return (
            <View>
                {list.map((item, index) => {
                    console.log()
                    let sno = index + 1;
                    let zindexValue = (index + 1) * -1
                    let gst_amount = (parseFloat(item.cgst_amount) || 0) + (parseFloat(item.sgst_amount) || 0);

                    let expiry_alert_period = item.expiry_alert_period ? (item.expiry_alert_period + " " + (item.expiry_alert_period == "1" ? " month" : " months")) : "";
                     
                    // temp ID for Direct PO
                    item['temp_id'] = index
                    // for sales alone
                    let sales_discount = (item.sales_discount || item.sales_discount_amount) ? ((item.sales_discount || 0) + "% / " + (item.sales_discount_amount || 0)) : ""
                    // color
                    let statusTxtColor = ""
                    let item_status = item.status ? (item.status).toUpperCase() : ""
                    if (item_status === Constants.STOCK_FULLY_DELIVERED) {
                        statusTxtColor = color.themeDark
                    }
                    else if (item_status === Constants.STOCK_PARTIAL_DELIVERED) {
                        statusTxtColor = "#FF9900"
                    }
                    // free quantity
                    let free_quantity_count = item.free_quantity ? item.free_quantity * item.pack_of_drugs : 0
                    // stock quantity
                    let stock_quantity = (item.stock_in_quantity ? item.status == "Delivered" ? item.stock_in_quantity :(item.stock_in_quantity + free_quantity_count ): 0) + (item.quantity ? (" / " + item.quantity) : (item.purchase_quantity ? (" / " + item.purchase_quantity) : null))
                    if (this.state.selectedScreen === VIEW_STOCK) {
                        stock_quantity = item.current_quantity + " / " + item.stock_in_quantity
                    }
                    
                    return (
                        <TouchableOpacity key={index} onPress={() => item.status != "Delivered" ? this._selectEntry(item, entryType) : null} >
                            <View key={index} style={[Style.stockDetailSection]}>
                                <View style={[Style.stockLightRow, { marginTop: '1vw' }]}>
                                    <BillTableTitle label={'Brand Name'} flexWidth={0.25} />
                                    <BillTableTitle label={'Generic Name'} flexWidth={0.3} />
                                    <BillTableTitle label={'Dosage Type'} flexWidth={0.15} />
                                    <BillTableTitle label={'Dosage Strength'} flexWidth={0.15} />
                                    <BillTableTitle label={'Batch Number'} flexWidth={0.15} />
                                </View>
                                <View style={[Style.stockRow]}>
                                    <BillTableTitle label={item.brand_name} flexWidth={0.25} />
                                    <BillTableTitle label={item.generic_name} flexWidth={0.3} />
                                    <BillTableTitle label={item.dosage_type} flexWidth={0.15} />
                                    <BillTableTitle label={item.dosage_strength} flexWidth={0.15} />
                                    <BillTableTitle label={item.batch_no} flexWidth={0.15} />
                                </View>
                                <View style={[Style.stockLightRow]}>
                                    <BillTableTitle label={'Expiry Date'} flexWidth={0.25} />
                                    <BillTableTitle label={'Group'} flexWidth={0.15} />
                                    <BillTableTitle label={'Schedule'} flexWidth={0.15} />
                                    <BillTableTitle label={'HSN Code'} flexWidth={0.15} />
                                    <BillTableTitle label={'Pieces Per Unit'} flexWidth={0.15} />
                                    <BillTableTitle label={'No of Unit(s)s'} flexWidth={0.15} />
                                </View>
                                <View style={[Style.stockRow]}>
                                    <BillTableTitle label={item.expiry_date ? moment(item.expiry_date).format("MM-YYYY") : ""} flexWidth={0.25} />
                                    <BillTableTitle label={item.group} flexWidth={0.15} />
                                    <BillTableTitle label={item.schedule} flexWidth={0.15} />
                                    <BillTableTitle label={item.hsn_code} flexWidth={0.15} />
                                    <BillTableTitle label={item.pack_of_drugs} flexWidth={0.15} />
                                    <BillTableTitle label={item.sales_unit} flexWidth={0.15} />
                                </View>
                                <View style={[Style.stockLightRow]}>
                                    <BillTableTitle label={'Quantity'} flexWidth={0.25} />
                                    <BillTableTitle label={'Free Qty (Unit(s))'} flexWidth={0.15} />
                                    <BillTableTitle label={'Buying Price per Unit'} flexWidth={0.15} />
                                    <BillTableTitle label={'Discount %'} flexWidth={0.15} />
                                    <BillTableTitle label={'Discount Amount'} flexWidth={0.15} />
                                    <BillTableTitle label={'S. Discount'} flexWidth={0.15} />
                                </View>
                                <View style={[Style.stockRow]}>
                                    <BillTableTitle label={stock_quantity} flexWidth={0.25} />
                                    <BillTableTitle label={item.free_quantity} flexWidth={0.15} />
                                    <BillTableTitle label={item.buying_price ? item.buying_price : ""} flexWidth={0.15} />
                                    <BillTableTitle label={item.discount_percentage} flexWidth={0.15} />
                                    <BillTableTitle label={item.discount_amount} flexWidth={0.15} />
                                    <BillTableTitle label={sales_discount} flexWidth={0.15} />
                                </View>
                                <View style={[Style.stockLightRow]}>
                                    <BillTableTitle label={'Total Buying Price'} flexWidth={0.25} />
                                    <BillTableTitle label={'MRP / Unit'} flexWidth={0.15} />
                                    <BillTableTitle label={'MRP / Qty'} flexWidth={0.15} />
                                    <BillTableTitle label={'CGST %'} flexWidth={0.15} />
                                    <BillTableTitle label={'SGST %'} flexWidth={0.15} />
                                    <BillTableTitle label={'Total GST Amount'} flexWidth={0.15} />
                                </View>
                                <View style={[Style.stockRow]}>
                                    <BillTableTitle label={item.total_buying_price} flexWidth={0.25} />
                                    <BillTableTitle label={item.mrp_per_pack} flexWidth={0.15} />
                                    <BillTableTitle label={item.mrp_per_quantity} flexWidth={0.15} />
                                    <BillTableTitle label={item.cgst_percentage} flexWidth={0.15} />
                                    <BillTableTitle label={item.sgst_percentage} flexWidth={0.15} />
                                    <BillTableTitle label={gst_amount} flexWidth={0.15} />
                                </View>
                                <View style={[Style.stockLightRow]}>
                                    <BillTableTitle label={'Expiry Date Alert Period'} flexWidth={0.25} />
                                    <BillTableTitle label={'Reorder Threshold'} flexWidth={0.15} />
                                    <BillTableTitle label={'Physical Box No'} flexWidth={0.15} />
                                    <BillTableTitle label={'Instructions'} flexWidth={0.15} />
                                    <BillTableTitle label={'EPR'} flexWidth={0.15}/>
                                    {this.state.selectedScreen !== VIEW_STOCK ?
                                        <BillTableTitle label={'Status'} flexWidth={0.15} /> : null}
                                </View>
                                <View style={[Style.stockRow]}>
                                    <BillTableTitle label={expiry_alert_period} flexWidth={0.25} />
                                    <BillTableTitle label={item.reorder_threshold} flexWidth={0.15} />
                                    <BillTableTitle label={item.physical_box_no} flexWidth={0.15} />
                                    <BillTableTitle label={item.instructions} flexWidth={0.15} />
                                    <BillTableTitle label={item.epr_value} flexWidth={0.15}/>
                                    {this.state.selectedScreen !== VIEW_STOCK ?
                                        <BillTableData label={item.status} flexWidth={0.15}
                                            txtColor={statusTxtColor} labelType={"status"}
                                        /> : null}
                                    {
                                        // item.status=="Partially Delivered"?
                                        this.state.selectedScreen !== VIEW_STOCK && item_status == Constants.STOCK_PARTIAL_DELIVERED ?
                                            <View style={{ flex: 0.15 }}>
                                                <ActionButton label={"New Batch"} keyIndex={"addNewBatch"}
                                                    onPressAction={() => this._selectEntry(item, entryType, true)} isDisable={isActionDisabled} />
                                            </View>
                                            : null}
                                </View>
                                <View style={[Style.stockLightRow]}>
                                    <BillTableTitle label={'UPC/EAN'} flexWidth={0.25} />
                                    <BillTableTitle label={'Recommended By'} flexWidth={0.30} />
                                    <BillTableTitle label={'Manufacture Name'} flexWidth={0.30} />                                  
                                </View>
                                <View style={[Style.stockRow]}>
                                    <BillTableTitle label={item.bar_code} flexWidth={0.25} />
                                    <BillTableTitle label={item.recommended_by} flexWidth={0.30} />
                                    <BillTableTitle label={item.manufacturer_name} flexWidth={0.30} />
                                </View>
                            </View>
                        </TouchableOpacity>
                    )
                })}
            </View>
        )
    }

    _selectEntry = (item, entryType, isNewBatch = false) => {
        item['entryType'] = entryType;
        this.setState({ selectedEntry: item, isNewBatch: isNewBatch })
    }

    render() {
        let { defaultDisabled, screenType, isNewBatch, vendor_list_suggest, isSelected, stock_items, po_line_items, previous_vendor_bills,
            showErrorResponse, showSuccesResponse, responseMessage, selectedEntry, isActionDisabled } = this.state;
            // Total buying price calculation
            const totalBuying = stock_items && stock_items.length > 0 && stock_items.map(item => parseFloat(item.total_buying_price)).reduce((prev,curr) => (prev + curr));
           // is neccesary to show save button ?
            let isSaveDisable = this.state.statusDelivery == "Completely Delivered" ? false : true
        return (
            <View style={{ height: screenHeight - 65, flexDirection: "row", justifyContent: "flex-start" }}>
                {this.state.conformationPopupFlag ?
                    <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: "100%", alignItems: "center", flex: 1, justifyContent: "center", paddingLeft: 10, zIndex: 10 }}>
                        <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius:4, padding: 20}}>
                            <Text style={{fontSize: 18, fontWeight: "bold", marginBottom: "10%"}}>{"Kindly Confirm Before Saving"}</Text>
                           
                                    <View style={{ backgroundColor: color.white, flexDirection:"row", flex:1, justifyContent: "space-around", width:"50%", flexWrap: "wrap-reverse"}}>
                                       
                                         <TouchableOpacity 
                                           style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: this.state.isYesButton ? color.lightGray : color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                                           onPress={() => {this._doGetPOBeforeSave()}}
                                           disabled = {this.state.isYesButton}
                                           > 
                                           <Text style={{ color: 'white', fontSize: 12, }}>{"Yes"}</Text>
                                         </TouchableOpacity>
                                         <TouchableOpacity 
                                           style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 4, width: "35%", paddingVertical: 8 }}
                                           onPress={() => this.setState({ conformationPopupFlag : false })}>
                                           <Text style={{ color: 'white', fontSize: 12, }}>{"No"}</Text>
                                         </TouchableOpacity>

                                    </View>

                        </View>
                    </View> : null
                }
                <View style={[Style.leftView, {
                    width: "65%", paddingHorizontal: 10, backgroundColor: color.applicationBackgroundColor,
                    flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start",
                    height: screenHeight - 65
                }]}>
                    <View onLayout={(event) => {
                        var { x, y, width, height } = event.nativeEvent.layout;
                        this.setState({ infoHeight: height })
                    }}>
                        {VIEW_STOCK === this.state.selectedScreen && screenType === "" ?
                        // <MenuBreadCrumpList menu={this.state.menu} selectedScreen={this.state.selectedScreen} changeScreen={this.props.changeScreen}
                        //     onSave={this.saveConformationPopup} PrintPO={this._doPrintPOBeforeSave} purchase_id={this.state.purchase_id} isDisable={isActionDisabled}  isSaveDisable = {this.state.statusDelivery == "Completely Delivered" ? false : true}/> 
                        <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginBottom: "0.625vw", paddingHorizontal: "1.25vw" }}>
                        <TouchableOpacity
                            style={[styles.ButtonStyle, { marginRight: " 0.625vw", width:"4vw" }]}
                            onPress={() => this.props.changeScreen("stock_list","")}
                        >
                            <Text style={styles.ButtonText}>Back</Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={{ marginRight: " 0.625vw" }}
                            onPress={() => this.props.changeScreen("stock_list","")}
                        >
                            <Text style={[styles.TitleText, { color: this.state.selectedScreen === "stock_list" ? color.themeDark : color.black }]}>Stock or Drug List</Text>
                        </TouchableOpacity>
                        <Text style={styles.TitleText} >- </Text>
                        <TouchableOpacity
                            style={{ marginRight: " 0.625vw" }}
                            onPress={
                                () => this.props.changeScreen("view_stock","")
                            }
                        >
                            <Text style={styles.TitleText}>Stock Details</Text>
                        </TouchableOpacity>
                    </View>: 
                        null}
                            {/* {this.saveConformationPopup()} */}

                        { ADD_STOCK === screenType || DIRECT_PO === screenType ?
                            <View style={{ flexDirection: "row", justifyContent: "space-between", alignContent: "center", marginBottom: "0.625vw", paddingHorizontal: "1.25vw" }}>
                                <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
                                    <TouchableOpacity
                                        style={[styles.ButtonStyle, { marginRight: " 0.625vw" , width:"4vw"}]}
                                        onPress={() => this.props.NavigateScreen("purchaseOrder")}
                                    >
                                        <Text style={styles.ButtonText}>Back</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity
                                        style={{ marginRight: " 0.625vw" }}
                                        onPress={() => this.props.NavigateScreen("purchaseOrder")}
                                    >
                                        <Text style={[styles.TitleText, { color: this.state.selectedScreen === "orderList" ? color.themeDark : color.black }]}>Purchase order</Text>
                                    </TouchableOpacity>
                                    <Text style={styles.TitleText} >- </Text>
                                    <TouchableOpacity
                                        style={{ marginRight: " 0.625vw" }}
                                        onPress={
                                            () => this.props.NavigateScreen("add_stock")
                                        }
                                    >
                                        <Text style={styles.TitleText}>Add to Stock</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }} >
                                    {
                                        this.state.selectedScreen === "add_stock" && isSaveDisable ?
                                            <TouchableOpacity
                                                style={[styles.ButtonStyle, { marginRight: " 0.625vw" }]}
                                                onPress={() => this.saveConformationPopup()}
                                                disabled={isActionDisabled}
                                            >
                                                <Text style={styles.ButtonText}>Save</Text>
                                            </TouchableOpacity> : null
                                    }

                                    <TouchableOpacity
                                        style={[styles.ButtonStyle, { marginRight: " 0.625vw" }]}
                                        onPress={() => this._doPrintPOBeforeSave()}
                                        disabled={isActionDisabled}
                                    >
                                        <Text style={styles.ButtonText}>Print</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            : null}

                        <View style={styles.VendorNameTextBoxView}>
                                <CommonAutoSuggestion width={"68%"} disable={defaultDisabled} placeholder={"Vendor Name"} type={""} align={""}
                                    stateKey={"vendor_name"} value={this.state.vendor_name} onChangeText={this._updateState.bind(this)}
                                    list={vendor_list_suggest} isSelected={isSelected} itemSelected={this._itemSelected.bind(this)} objFormat={{ keyId: "vendor_id", keyName: "name" }} />
                            {DIRECT_PO !== screenType ?
                                <View style={styles.PoNumberTextBoxView}>
                                    <CommonTextBox disable={defaultDisabled} placeholder={"Purchase Order No"} type={""} align={""}
                                        stateKey={"po_number"} value={this.state.po_number} onChangeText={this._updateState.bind(this)} /></View>
                                : null}
                            {/* <View style={{ flexBasis: 150, flexGrow: 0, flexShrink: 0, marginLeft: 15 }}>
                                <CommonDatePicker disable={defaultDisabled} placeholder={"Date"} type={""}
                                    stateKey={"po_date"} value={this.state.po_date} onChangeText={this._updateState.bind(this)} />
                            </View> */}
                            <View style={styles.DeliverydateDatePicker}>
                                <CommonDatePicker disable={false} placeholder={"Delivery date"} type={""}
                                    stateKey={"delivery_date"} value={this.state.delivery_date} onChangeText={this._updateState.bind(this)} /></View>
                        </View>

                        <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center", maxHeight: 40, marginTop: 15, zIndex: -1 }}>
                            <View style={{ flex: 0.3, flexDirection: "row", justifyContent: "flex-start" }}>
                                <Text style={styles.PaymentDetailText}>{"Payment Details"}</Text></View>
                            {/* <View style={{ flex: 0.7, flexDirection: "row", justifyContent: "flex-end", alignContent: "center" }}>
                                <Text style={styles.PreviousBillText}>{"Previous Bill"}</Text>
                                {previous_vendor_bills && previous_vendor_bills.length > 0 && previous_vendor_bills.map((item, index) => {
                                    return (
                                        <View key={index}>
                                            <ActionButton label={item.bill_date ? moment(item.bill_date).format('DD-MM-YYYY') : ""} keyIndex={"previous_bill_date"} onPressAction={this._selectPreviousBillDate.bind(this, item)} />
                                        </View>
                                    )
                                })}
                                {!previous_vendor_bills || previous_vendor_bills.length == 0 ?
                                    <View><Text style={{ color: color.labelColor , fontSize: '1vw' }}>{"No bills"}</Text></View> : null}
                            </View> */}
                        </View>

                        <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center", maxHeight: 40, marginTop: 15, zIndex: -1,marginLeft : 20 }}>
                            <CommonTextBox width={"18%"} disable={false} placeholder={"Bill No"} type={""} align={""}
                                stateKey={"bill_no"} value={this.state.bill_no} onChangeText={this._updateState.bind(this)} />
                                <View style={styles.BilldateDatePicker}>
                            <CommonDatePicker width={"18%"} disable={false} placeholder={"Bill date"} type={""}
                                stateKey={"bill_date"} value={this.state.bill_date} onChangeText={this._updateState.bind(this)} />
                                </View>
                            <CommonTextBox width={"18%"} disable={this.state.po_number != "" ? true : false} placeholder={"CGST Amount"} type={0} align={""}
                                stateKey={"cgst_amount"} value={this.state.cgst_amount ? this.state.cgst_amount : 0} onChangeText={this._updateState.bind(this)} />
                            <CommonTextBox width={"18%"} disable={this.state.po_number != "" ? true : false} placeholder={"SGST Amount"} type={""}
                                stateKey={"sgst_amount"} value={this.state.sgst_amount ? this.state.sgst_amount : 0} onChangeText={this._updateState.bind(this)} />
                            <CommonTextBox width={"18%"} disable={true} placeholder={"Bill Amount"} type={""}
                                stateKey={"bill_amount"} value={this.state.bill_amount} onChangeText={this._updateState.bind(this)} />

                        </View>
                    </View>

                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        style={{ width: "100%", marginTop: 15, marginBottom: 15, height: screenHeight - (65 + this.state.infoHeight), zIndex: -1 }}
                        contentContainerStyle={{ flex: 1 }}
                        bounces={false} bouncesZoom={false}
                    >
                        {this.renderLineItems(stock_items, "SE")}
                        {this.renderLineItems(po_line_items, "PO")}
                    </ScrollView>
                    {
                        showSuccesResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }
                </View>

                <View style={{
                    padding: 20, width: "35%", height: screenHeight - 65, backgroundColor: color.themeShade,
                    shadowColor: "#090F36",
                    shadowOffset: { width: 0, height: 5 },
                    shadowOpacity: 0.6, shadowRadius: 3,
                    elevation: 5
                }}>
                    <ScrollView
                        ref={scrollView => this.scrollView = scrollView}
                        onContentSizeChange={() => {
                            this._onContentSizeChange();
                        }}
                        showsVerticalScrollIndicator={false}
                        style={{ width: "100%" }}
                        contentContainerStyle={{ flex: 1 }}
                    >
                        {this.state.selectedScreen === VIEW_STOCK ?
                            (selectedEntry ?
                                <ReturnDetailsRight
                                    stock_in_quantity={selectedEntry.current_quantity}
                                    pack_of_drugs={selectedEntry.pack_of_drugs}
                                    sales_unit={selectedEntry.sales_unit}
                                    stock_id={selectedEntry.stock_id}
                                    errorClientCallBack={this.errorClientCallBack}
                                    successClientCallBack={this.successClientCallBack}
                                /> : null)
                            : <StockDetailsRight screenType={screenType} sendItem={this._addItem.bind(this)}
                                selectedEntry={this.state.selectedEntry} isNewBatch={isNewBatch}
                                responseSuccessErrorMessage={this.errorClientCallBack} />
                        }

                    </ScrollView>
                </View>
            </View>
        );
    }

    _selectPreviousBillDate(item) {

    }

    tileBlock = (label, data) => {
        return (
            <View style={{ flex: 0.25, flexDirection: "column" }}>
                <Text>{label}</Text>
                <Text>{data}</Text>
            </View>
        )
    }
    tileBlock2 = (label, data) => {
        return (
            <View style={{
                flex: 0.5, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
                backgroundColor: "#F9F9F9", padding: 8, borderRadius: 4, marginTop: 5,
                borderBottomWidth: 1, borderColor: "#E2E2E2"
            }}>
                <View style={{ flexDirection: "column" }}>

                    <Text>{data}</Text>
                    <Text>{label}</Text>
                </View>
            </View>
        )
    }

    _setDataFormat = (data, desiredFormat = "string") => {

        let inString = data;

        if (desiredFormat === "int" || desiredFormat === "integer") {

            inString = parseInt(inString) || 0

        } else if (desiredFormat === "float" || desiredFormat === "decimal") {

            let parsedVal = parseFloat(inString) || 0;
            let formattedVal = parsedVal.toFixed(2);
            // assign
            inString = parseFloat(formattedVal);

        } else if (desiredFormat === "date") {

            let date = inString;
            if (date) {

                inString = date ? moment(date).format('YYYY-MM-DD') : null
            } else {
                inString = ""
            }
        } else if (desiredFormat === "string") {

            inString = inString ? (inString.toString()).trim() : ""
        }

        return inString;
    }
    saveConformationPopup = () => {
        this.setState({
            conformationPopupFlag : true
        })
    }
    _doGetPOBeforeSave = () => {

        this.setState({
            isActionDisabled: true,
            isYesButton : true,
        }, () => {
            if (this.state.screenType === DIRECT_PO) {
                this._getPOnumber()
            } else { this._doSave() }
        })
    }

    _doSave = () => {

        let { screenType, stock_items, po_date, po_number,
            last_vendor_bill, bill_no, bill_date, delivery_date, vendor_id,
            cgst_amount, sgst_amount } = this.state;

        // declaration
        let url = null;
        let data = {}

        let isValid = true
        let errorMsg = ""

        // set default value if value not available
        cgst_amount = cgst_amount != "" ? cgst_amount : 0
        sgst_amount = sgst_amount != "" ? sgst_amount : 0

        po_date = po_date ? moment(po_date).format('YYYY-MM-DD') : null
        delivery_date = delivery_date ? moment(delivery_date).format('YYYY-MM-DD') : null
        bill_date = bill_date ? moment(bill_date).format('YYYY-MM-DD') : null

        // validation
        if (!vendor_id && screenType === DIRECT_PO) {

            this.errorClientCallBack("Select vendor", false)
            return
        } else if (!po_date) {

            this.errorClientCallBack("Enter purchase order date", false)
            return
        } else if (!delivery_date) {

            this.errorClientCallBack("Enter delivery date", false)
            return
        } else if (!bill_no) {

            this.errorClientCallBack("Enter purchase bill number", false)
            return
        } else if (!bill_date) {

            this.errorClientCallBack("Enter purchase bill date", false)
            return
        } else if ( !this.state.bill_amount && this.state.bill_amount != 0 ) {

            this.errorClientCallBack("Enter purchase bill amount", false)
            return
        }

        if (screenType === DIRECT_PO) {

            url = Constants.DIRECT_PO;
            data = {
                vendor_id: this._setDataFormat(vendor_id, 'int'),
                purchase_order_number: this._setDataFormat(po_number, 'string'),
                purchase_order_date: po_date,
                delivery_date: delivery_date,
                "bill_no": this._setDataFormat(bill_no, 'string'),
                "bill_date": bill_date,
                "cgst_amount": cgst_amount,
                "sgst_amount": sgst_amount,
                "total_amount": this.state.bill_amount ?  this.state.bill_amount : "0",
                stock_items: []
            };
        } else {

            url = Constants.ADD_TO_STOCK;
            data = {
                purchase_order_number: this._setDataFormat(po_number, 'string'),
                purchase_order_date: po_date,
                vendor_bill_details: {
                    vendor_id: this._setDataFormat(vendor_id, 'int'),
                    "bill_no": this._setDataFormat(bill_no, 'string'),
                    "bill_date": bill_date,
                    "cgst_amount": cgst_amount,
                    "sgst_amount": sgst_amount,
                    "total_amount": this.state.bill_amount ? this.state.bill_amount : "0",
                    "delivery_date": delivery_date
                },
                stock_items: []
            };
            // if bill id exist,
            if (this.state.bill_id && (last_vendor_bill && last_vendor_bill.bill_no == bill_no)) {

                data["vendor_bill_details"]["bill_id"] = this.state.bill_id;
            }
        }

        for (let i = 0; i < stock_items.length; i++) {

            let is_edited = stock_items[i].is_edited ? stock_items[i].is_edited : false;

            if (is_edited || (screenType === DIRECT_PO)) {

                let expiry_date = stock_items[i].expiry_date ? moment(stock_items[i].expiry_date).format("YYYY-MM-DD") : null;
                let free_quantity_count = stock_items[i].free_quantity ? stock_items[i].free_quantity * stock_items[i].pack_of_drugs : 0

                let tempDrug = {
                    "serial_no": this._setDataFormat(stock_items[i].serial_no, 'int'),
                    "batch_no": stock_items[i].batch_no,
                    "expiry_alert_period": this._setDataFormat(stock_items[i].expiry_alert_period, 'int'),
                    "pack_of_drugs": this._setDataFormat(stock_items[i].pack_of_drugs, 'int'),
                    "sales_unit": this._setDataFormat(stock_items[i].sales_unit, 'int'),
                    "buying_price": stock_items[i].buying_price,
                    "stock_in_quantity": this._setDataFormat(stock_items[i].stock_in_quantity, 'int'),
                    "free_quantity": this._setDataFormat(free_quantity_count, 'int'),
                    "total_buying_price": stock_items[i].total_buying_price,
                    "cgst_percentage": stock_items[i].cgst_percentage,
                    "cgst_amount": stock_items[i].cgst_amount,
                    "sgst_percentage": stock_items[i].sgst_percentage,
                    "sgst_amount": stock_items[i].sgst_amount,
                    "mrp_per_pack": stock_items[i].mrp_per_pack,
                    "mrp_per_quantity": stock_items[i].mrp_per_quantity,
                    "discount_percentage": stock_items[i].discount_percentage,
                    "discount_amount": stock_items[i].discount_amount,
                    "reorder_threshold": this._setDataFormat(stock_items[i].reorder_threshold, 'int'),
                    "expiry_date": expiry_date,
                    "physical_box_no": stock_items[i].physical_box_no,
                    "treatment_for": stock_items[i].treatment_for,
                    "instructions": stock_items[i].instructions,
                    "hsn_code": stock_items[i].hsn_code,
                    "group": stock_items[i].group,
                    "schedule": stock_items[i].schedule,
                    "sales_discount": this._setDataFormat(stock_items[i].sales_discount, 'decimal'),
                    "sales_discount_amount": this._setDataFormat(stock_items[i].sales_discount_amount, 'decimal'),
                    "epr_value": stock_items[i].epr_value,
                    "recommended_by": stock_items[i].recommended_by,
                    "manufacturer_name": stock_items[i].manufacturer_name,
                    "bar_code": stock_items[i].bar_code,
                }

                if (screenType === DIRECT_PO) {
                    tempDrug["brand_name"] = stock_items[i].brand_name;
                    tempDrug["generic_name"] = stock_items[i].generic_name;
                    tempDrug["dosage_type"] = stock_items[i].dosage_type;
                    tempDrug["dosage_strength"] = stock_items[i].dosage_strength;
                } else {
                    tempDrug["drug_id"] = this._setDataFormat(stock_items[i].drug_id, 'int');
                    // stock update
                    if (stock_items[i].stock_id) {
                        tempDrug["stock_id"] = this._setDataFormat(stock_items[i].stock_id, 'int');
                    }
                }
                // push data
                data['stock_items'].push(tempDrug)
            }
        }

        // added items length
        let added_items_length = data['stock_items'].length
        const totalBuying = stock_items.length > 0 && stock_items.map(item => parseFloat(item.total_buying_price)).reduce((prev,curr) => (prev + curr));
        if(stock_items.length == 0){
            this.errorClientCallBack("Please enter the drug details", false)
        }else if (Math.round(totalBuying) != this.state.bill_amount) {
            this.errorClientCallBack("Total buying price and payment details - Amount dosen't match", false)
        }else if (added_items_length > 0 && isValid) {
          this.setState({          
            isYesButton:true,
        })
            this.service.postApiCall(
                url,
                data,
                this.successCallBack,
                this.errorCallBack
            )
        } else {

            if (added_items_length == 0) {
                errorMsg = "No edited items found"
            }

            if (errorMsg) {
                this.setState({
                    isActionDisabled: false,
                    isYesButton : false
                }, () => {
                    this.errorClientCallBack(errorMsg, false)
                })
            }
        }
    }
    _doPrintPOBeforeSave = () =>{
        let url = Constants.DIRECT_PO + "?purchase_order_number=" + this.state.po_number + "&export_type=pdf"
        this.service.getInstance().documentUploadGet(
            url,
            this.PrintPOSuccess,
            this.PrintPOSuccessFailure,
            "pdf"
        )
    }
    PrintPOSuccess= response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            // console.log(file)
            window.open(fileURL);
        }
    };
    PrintPOSuccessFailure(){
        
    }

    _viewStockDetails() {

        let url = Constants.VIEW_STOCK + "?list_id=" + this.state.stocklist_id

        this.service.getApiCall(
            url,
            this._getViewStockDetails,
            this._getDosageTypeFailure
        )
    }

    _getViewStockDetails = response => {

        let states = this.state;

        response = JSON.parse(JSON.stringify(response));

        if (response.status === "success") {

            let stockItems = [];

            let data = response.data
            stockItems.push(data)

            states["stock_items"] = stockItems
            states["vendor_name"] = data["vendor_name"]
            states["po_number"] = data["purchase_order_number"]
            states["po_date"] = data["purchase_order_date"]
            states["delivery_date"] = data["delivery_date"]
            states["from_api_call"] = true

            this.setState({
                states
            })
        }
    }

    successCallBack = response => {

        this.setState({
            isActionDisabled: false
        }, () => {

            if (response.status == "success") {
                this.setState({          
                    isYesButton:true,
                    conformationPopupFlag : false
                })
                this.props._getDrugAndStockCount();
                this.props.responseSuccessErrorMessage(response.message, true)
                setTimeout(() => {
                    this.props.responseSuccessErrorMessage("", true)
                    // this.props.changeScreen("purchase_orders")
                    this.props.NavigateScreen("purchaseOrder")
                }, 3000)

            } else if (response.status == "fail") {
           
                this.props.responseSuccessErrorMessage(response.message, false)
                this.setState({ conformationPopupFlag : false, isYesButton:false, })

                setTimeout(() => {
                    this.props.responseSuccessErrorMessage("", false)
                }, 3000)
            }

        })
    }

    errorCallBack = response => {

        this.setState({
            isActionDisabled: false
        }, () => {

            this.props.responseSuccessErrorMessage(response.message, false)
            this.setState({ conformationPopupFlag : false, isYesButton:false, })
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", false)
            }, 3000)
        })
    }

    errorClientCallBack = (message, flag) => {

        let { isActionDisabled } = this.state;

        if (isActionDisabled) {
            this.setState({
                isActionDisabled: false, 
                isYesButton:false,
            })
        }

        this.props.responseSuccessErrorMessage(message, flag)
        this.setState({ conformationPopupFlag : false })

        setTimeout(() => {
            this.props.responseSuccessErrorMessage("", flag)
        }, 3000)
    }

    successClientCallBack = (message, flag) => {
        this.props.responseSuccessErrorMessage(message, true)
        setTimeout(() => {
            this.props.responseSuccessErrorMessage("", true)
            this.props.changeScreen("stock_list")
        }, 3000)
    }
}
const styles = StyleSheet.create({
    VendorNameTextBoxView: { 
        flex: 1, 
        flexDirection: "row", 
        justifyContent: "flex-start", 
        alignContent: "center", 
        maxHeight: '2.7vw', 
        marginTop: '1vw', 
        width: "80%", 
        marginLeft : '1.3vw' 
    },
    PoNumberTextBoxView: { 
        flexBasis: '10.5vw', 
        flexGrow: 0, 
        flexShrink: 0, 
        marginLeft: '1vw' 
    },
    PaymentDetailText: { 
        alignSelf: "center", 
        fontSize: '1vw' 
    },
    PreviousBillText: { 
        marginRight: '1vw', 
        alignSelf: "center", 
        fontSize: '1vw' 
    },
    BilldateDatePicker: {
        marginRight: '.79vw',  
        marginTop: '-.15vw'
    },
    DeliverydateDatePicker: { 
        width:"20%", 
        marginLeft: '.6w' 
    },
    ButtonStyle: {
        width: '5vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.5vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize:"1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    },
    TitleText:{
        padding: "0.4vw", 
        fontSize: "1vw", 
        fontWeight: "500"
    }
})