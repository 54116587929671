import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

export default class PurchaseGst extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReportList: [],
      startTime: converted_date,
      endTime: converted_date,
      billingListAfterPagination: [],
      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0
    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 
  }

  componentDidMount() {
    this._getPurchaseGst()
  }

  componentWillReceiveProps(props) {
    
    if (props.isclear && (props.startTime!=this.state.startTime || props.endTime!=this.state.endTime)) 
    {
      this.setState({
        startTime: props.startTime,
        endTime: props.endTime,
        current_page: 1,
        records_per_page: 10
      }, () => {
        this._getPurchaseGst()
        props.handlepropsvalueclear(false)

      })
    }
  }

  // Get Api 
  _getPurchaseGst = () => {

    var url = "" 
    let { current_page, records_per_page } = this.state;

    if (this.state.startTime && this.state.endTime) {

      url = Constants.GET_PURCHASE_GST + "?from_date=" + this.state.startTime + "&to_date=" + this.state.endTime + "&page="+current_page+"&page_size="+records_per_page;
    } else {
      url = Constants.GET_PURCHASE_GST + "?page="+current_page+"&page_size="+records_per_page;

    }

    this.service.getApiCall(
      url,
      this._getPurchaseGstSuccess,
      this._getPurchaseGstFailure
    )
  }

  _getPurchaseGstSuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data.result
      this.setState({
        salesReportList,
        no_of_pages: response.data?response.data.no_of_pages:0,
        total_count: response.data?response.data.total_count:0
      }, () => {
        var data = {
          "total_amount": response.data.total_gst_amount,
          "total_cgst_amount": response.data.total_cgst_amount,
          "total_sgst_amount": response.data.total_sgst_amount,

        }
        this.props.gettotalscoreValues("Purchase GST", data)

      })
    } else {
      this.props.gettotalscoreValues("Purchase GST", "")

    }
  };

  _getPurchaseGstFailure = error => {
    this.props.gettotalscoreValues("Purchase GST", "")

    console.log(error);

  };
  // Report List Views Function
  renderContent() {
    return (
      // Report List Views
      <View style={{ marginHorizontal: 20, backgroundColor: "#F9F9F9", marginTop: 15 }}>
        {this.renderContentRow()}



        {/* End Report List View */}

      </View>
    )
  }
  // Common History Section Header function
  renderHeader() {
    return (

      <View style={ReportStyle.TableHead}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Date"}
          heading3={"Vendor Name"}
          heading4={"GSTIN"}
          heading5={"CGST"}
          heading6={"SGST"}
          heading7={"GST Amount"}
          heading8={"EPR Value"}



          columnSize={[0.05, 0.126, 0.2, 0.2, 0.12, 0.12, 0.12, 0.07]}  //total value is == of 1
          noOfColumn={8}

        />
      </View>
    )
  }


  // Render Content Row function Key and 
  renderContentRow() {
    if (this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.length < 11) {

      return (
        <View style={{}}>
          {
            this.state.salesReportList && this.state.salesReportList.map((item, index) => { 
              let date = item.date?moment(item.date).format("DD-MM-YYYY"):""
              return (
                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0, justifyContent: 'center' }}>
                  <View style={[{ flex: 0.12 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
                  <View style={[{ flex: 0.126 }]}><Text>{date}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.vendor_name}</Text></View>
                  <View style={[[{ flex: 0.2 }]]}><Text>{item.vendor_gstin_number}</Text></View>
                  <View style={[{ flex: 0.12, }]}><Text>{item.cgst_amount}</Text></View>
                  <View style={[{ flex: 0.12, }]}><Text>{item.sgst_amount}</Text></View>
                  <View style={[{ flex: 0.12, }]}><Text>{item.gst_amount}</Text></View>

                </View>
              )
            })}



        </View>
      )
    }
    else if (this.state.salesReportList && this.state.salesReportList.length > 0) {

      return (
        <View style={{}}>
          {
            this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
              return (
                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 0, justifyContent: 'center' }}>
                  <View style={[{ flex: 0.12 }]}><Text style={{ marginLeft: 20 }}>{index + 1}</Text></View>
                  <View style={[{ flex: 0.126 }]}><Text>{item.date}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.vendor_name}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text>{item.vendor_gstin_number}</Text></View>
                  <View style={[{ flex: 0.12, }]}><Text>{item.cgst_amount}</Text></View>
                  <View style={[{ flex: 0.12, }]}><Text>{item.sgst_amount}</Text></View>
                  <View style={[{ flex: 0.12, }]}><Text>{item.gst_amount}</Text></View>

                </View>
              )
            })}



        </View>
      )
    }
    else {
      return <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: 100, alignItems: 'center' }}>
        <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
      </View>
    }

  }
  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }
  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page } = this.state

    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        
        this.setState({
            records_per_page: records_per_page_change,
            current_page: next_page
        }, ()=>{
            this._getPurchaseGst()
        })
    }
  }
  render() {

    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0

    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}
        {
          total_count > 0 ?
          <View style={{ marginLeft: 20 }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
              type={Constants.PAGINATION_TYPE_SERVER} 
              totalServerRecordsCount={total_count} 
              totalServerPagesCount={no_of_pages}/></View>
            : null
        }
      </View>
    );
  }
  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={ReportStyle.TableContent}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              let sno = (current_page-1)*records_per_page + index + 1;
              let date = item.date?moment(item.date).format("DD-MM-YYYY"):"";
              return (
                <View key={sno} style={ReportStyle.TableContentView}>
                  <View style={[{ flex: 0.05, marginLeft: 20  }]}><Text style={[ReportStyle.TableContentText]}>{sno}</Text></View>
                  <View style={[{ flex: 0.126 }]}><Text style={ReportStyle.TableContentText}>{date}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text style={ReportStyle.TableContentText}>{item.vendor_name}</Text></View>
                  <View style={[{ flex: 0.2 }]}><Text style={ReportStyle.TableContentText}>{item.vendor_gstin_number}</Text></View>
                  <View style={[ReportStyle.ReportCell, { flex: 0.12, }]}>
                    <Text style={[ReportStyle.ReportCellTxt]}>{item.cgst_amount}</Text></View>
                  <View style={[ReportStyle.ReportCell, { flex: 0.12, }]}>
                    <Text  style={[ReportStyle.ReportCellTxt]}>{item.sgst_amount}</Text></View>
                  <View style={[ReportStyle.ReportCell, { flex: 0.12, }]}>
                    <Text style={[ReportStyle.ReportCellTxt]}>{item.gst_amount}</Text></View>
                    <View style={[{ flex: 0.07 }]}><Text style={ReportStyle.TableContentText}>{item.epr_value}</Text></View>
                </View>      
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: 150 }}>
          <Text style={ReportStyle.NoRecord}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }
}

const ReportStyle = StyleSheet.create({

  ReportCell: {
    width: 150,
    alignItems: "flex-start"
  },
  ReportCellTxt: {
    width: '7vw',
    // paddingHorizontal: '1.5vw',
    textAlign: "left",
    fontSize: '1vw'
  },
  TableContent: { 
    marginHorizontal: '1.2vw', 
    backgroundColor: "#F9F9F9", 
    marginTop: '1vw' 
  },
  TableContentView: { 
    flexDirection: 'row', 
    borderBottomColor: "#888888", 
    borderBottomWidth: 1, 
    paddingVertical: '1.2vw', 
    marginHorizontal: 0 
  },
  TableContentText: {fontSize: '1vw'},
  NoRecord: { color: "#8888888", fontSize: '1vw' },
  TableHead: { marginHorizontal: '1.2vw', marginTop: '.5vw' }
  
})