import { StyleSheet, Dimensions, Platform } from "react-native";
import { color } from "./Color";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
const platform = Platform.OS;

const contentColumnBGColor = color.white;

export default StyleSheet.create({

    allButtonBorderRadius:{
        borderRadius:4
    },

    fontSizeMedium:{
        fontSize:"0.92vw"
    },

    componentBtnDDPadding:{
        borderWidth: 1,
        // borderRadius: 30,
        paddingTop: 8,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 8,
        // alignItems: "center",
        borderRadius:4,
        alignSelf: "flex-start",
    },
    errorMessageView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5
    },
    errorMessageText: {
        color: "red",
        fontSize: 12,
        marginLeft: 15
    },
    exclamationIcon: {
        color: 'red',
        marginLeft: 10
    },
    commonTextBoxStyles: {
        borderColor: "#CDD1D5",
        borderRadius: 4,
        borderWidth: 1,
        width: "100%",
        height: '2.27vw',
        padding: '1vw',
        fontSize: "1vw"
    },
    commonLineTextBoxStyles: {
        flex: 1,
        borderColor: "#CDD1D5",
        borderRadius: 4,
        borderWidth: 1,
        // width: "100%",
        height: 35,
        padding: 6, 
        marginRight: 15,
        fontSize: 11
    },
    rootView: {
        width: screenWidth,
        height: screenHeight,
        flex: 1,
        flexDirection: "row"
    },
    leftBarView: {
        width: platform === "web" ? "6%" : "10%",
        height: screenHeight,
        backgroundColor: color.themeBackgroud,
        flexDirection: "row",
        // alignItems: "center"
    },
    leftView: {
        width: platform === "web" ? "62.5%" : "58.5%",
        height: screenHeight,
        backgroundColor: contentColumnBGColor,
        // flexDirection: "row"
    },
    leftReportView: {
        width: platform === "web" ? "93%" : "65%",
        height: screenHeight,
        backgroundColor: contentColumnBGColor,
        flexDirection: "row"
    },
    rightView: {
        padding: 10,
        width: "31.5%",
        height: screenHeight,
        backgroundColor: color.themeShade,
        alignItems: "center"
    },
    /*
    appointmentTableHead: {
        marginLeft: 25,
        marginRight: 25,
        height: 50,
        backgroundColor: color.appointmentTableHead,
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
    },
    appointmentTableHeadText: {
        fontSize: 12, 
        fontWeight:"700",
        color: color.appointmentTableHeadText,
        textAlign: "center"
    },
    appointmentTableRow: {
        height: 50,
        borderRadius: 20,
        marginLeft: 25,
        marginRight: 25,
        marginBottom: 10,
        paddingRight: 20
    },
    appointmentTableStatusView: {
        height:45,
        width:45,
        
    },
   
    */
    pickerForScanReport: {
        backgroundColor: "#45B7B1",
        borderRadius: 20, 
        height: 32, 
        paddingLeft: 20, 
        paddingRight: 20,
        fontSize: 11
    },
    pickersView: {
        backgroundColor: color.white,
        borderRadius: "4px",
        height: "2vw",
        fontSize: ".85vw",
        paddingLeft: ".3vw",
        marginRight: "1vw"
    },
    appointmentTableRowImg: {
        height: platform === "web" ? 40 : 55,
        width: platform === "web" ? 40 : 55,
        borderRadius: 35
    },

    pickerRoundCornerView: {
        backgroundColor: color.white,
        borderRadius: '1.30vw', 
        height: '2.08vw', 
        paddingLeft: '0.65vw', 
        fontSize: '0.72vw'
    },
    pickerView: {
        backgroundColor: color.white,
        borderRadius: '0.65vw', 
        height: '2.27vw',  
        fontSize: '0.78vw',
        paddingLeft: '0.65vw',
        marginRight: '0.65vw'
    },
    pickerFont: {
        backgroundColor: color.white,
        paddingLeft: ".65vw",
        paddingRight:".65vw"
        //fontSize: 12
    },
    /*
    formTextBox: {
        color: "grey", 
        borderRadius: 10, 
        borderColor: "grey", 
        borderWidth: 1,
        height: 35, 
        paddingLeft: 10,
        fontSize: 14  
    },
    contentPageTitle: {
        fontSize: 23, 
        color: "black", 
        fontWeight: "500", 
        marginTop: 40, 
        marginLeft: 40
    },
    doctorNotesFooterButton:{

        borderRadius:4,
        // borderWidth: 1,
        paddingTop: 8,
        paddingLeft: 18,
        paddingRight: 18,
        paddingBottom: 8,
        borderRadius:4,
        backgroundColor: color.themeDark, 
    },
    contentPageLine: {
        marginTop: 5,
        marginBottom: 5,
        width: "100%",
        height: 0.5,
        backgroundColor: "#E4E8F1"  // "#707070"
    },
    contentSectionTitle: {
        fontSize: 20,
        fontWeight: "600"
    },
    contentSectionSubTitle: {
        fontSize: 16, 
        color: color.notesTableTitle
    },*/
    LoaderStyle:{
        justifyContent: 'center',
        alignItems: 'center'
    },
    contentTableTitle: {
        // table headings
        fontSize: ".9vw", 
        color: color.notesTableTitle
    },
    contentModalTitle: {
        // table headings
        fontSize: 16, 
        color: color.black
    },
    contentTableDataCell: {
        padding: 15
    },
    contentTableDataLead: {
        // lead also data - like Right Eye (OD)
        fontSize: 14, 
        color: color.notesTableData
    },
    contentTableData: {
        fontSize: 12, 
        color: color.notesTableData,
        marginTop: 5
    },
    contentActive: {
        fontSize: 12, 
        color: '#E86261',
        marginTop: 5
    },
    contentInActive: {
        fontSize: 12, 
        color: '#EB982B',
        marginTop: 5
    },
    sectionItemLine: {
        height: 0.5,
        backgroundColor: color.sectionItemLine
    },
    SearchBarContainer: {
        width: "100%", 
        height: 60, 
        borderTopColor: "transparent", 
        borderBottomColor: "transparent", 
        backgroundColor: 'none'
    },
    SearchBarInnerContainer: {
        paddingHorizontal: 5, 
        backgroundColor: "#e8e9eb", // color.lightGray
    },
    SearchBarInput: {
        fontSize: 14, 
        color: color.black
    },
    historyHeaderFontStyle: { 
        fontWeight: '300', fontSize: '1vw' 
    },
    historyHeaderFontStyleStartText: { 
        fontWeight: '300', fontSize: '1vw', marginHorizontal: "1.30vw" 
    },
    // home screen 
    homeScreenStatusView :{
        marginBottom:4,
        width: "15%", 
        color: "white",
        paddingLeft: 25
    },
    homeScreenSelectedStates:  {
        marginTop:4,
        padding: 8, 
        backgroundColor: color.themeDark, 
        borderRadius: 50, 
        borderColor: "white", 
        borderWidth: 1 
    },
    /* popup */
    popupTableDataFirst: {
        // table headings
        fontSize: "0.8vw", 
        color: color.notesTableData
    },
    popupTableDataSecond: {
        fontSize: ".65vw", 
        color: color.notesTableData,
        marginTop: ".3vw"
    },
    /* stock  */
    stockDetailSection: {

        // flex: 1, 
        flexDirection: "column",
        backgroundColor: "#E2E2E2", 
        minHeight: '19vw', 
        marginTop: '1vw', 
        borderRadius: 4
    },
    stockRow: {
        flexDirection: "row", 
        justifyContent: "flex-start", 
        alignItems: "center",
        // flex: 1, 
        // backgroundColor: "#E2E2E2",
        minHeight: 30,
        paddingVertical: 10,
        paddingHorizontal: 15,
        fontSize: 13,
        fontWeight: "bold" 
    },
    stockLightRow: { 
        flexDirection: "row", 
        justifyContent: "flex-start", 
        // flex: 1, 
        backgroundColor: color.white, 
        maxHeight: '2vw',
        paddingVertical: '.3vw',
        paddingHorizontal: '.6vw',
        // fontSize: 9
        marginHorizontal: '.3vw'
    },
    floatingLabelText: {
        position: 'absolute', 
        marginLeft: '0.65vw', 
        backgroundColor: "white", 
        fontSize: '0.78vw', 
        paddingHorizontal: '0.32vw', 
        color: "#888888"
    }
});
