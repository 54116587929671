import React, { Component } from "react";
import { View, Text, TextInput, TouchableOpacity,StyleSheet, ScrollView } from "react-native";
import moment from "moment"
import { MenuBreadCrumpList, BillTableTitle, BillTableData, CommonReportSectionHeader, CommonButton } from "../../common/BaseFunctionComponent";
import Pagination from "../../common/Pagination";
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import { color } from "../../styles/Color";
import { Tooltip } from "antd";

export default class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: this.props.menu,
            selectedScreen: this.props.selectedScreen,
            showPickerDropDownFlag: false,
            selectedBatch: [],
            batchData: [],
            data: {
                total_count: 0,
                no_of_pages: 0,
                result: []
            },
            paginationNewDrugRequestList: [],
            resportActions: true,
            // for pagination
            current_page: 1,
            records_per_page: 10,
            searchValue: this.props.selectedScreen == "stock_list" || "wanted_list"? this.props.searchValue : "",
            vendor_list: [],
            vendor_list_suggest: [],
            isVendorSuggestion: false,
            drugRow: [],
            selectedIndex: null,
        }
        this.service = new Service();
    }

    componentDidMount() {
        this._doLoadInventoryList();
        this._getVendorList()
    }

    componentWillReceiveProps(props) {

        let { searchValue } = this.state

        if (this.state.selectedScreen !== props.selectedScreen) {
            this.setState({
                selectedScreen: props.selectedScreen,
                current_page: 1,
                records_per_page: 10,
                searchValue: ""
            }, () => {
                this._doLoadInventoryList()
            })

        } else if (searchValue != props.searchValue) {

            if (props.selectedScreen == "stock_list" || props.selectedScreen == "wanted_list" || props.selectedScreen == "purchase_orders") {

                this.setState({
                    selectedScreen: props.selectedScreen,
                    current_page: 1,
                    records_per_page: 10,
                    searchValue: props.searchValue
                }, () => {
                    this._doLoadInventoryList()
                })
            }

        }
    }

    _getVendorList() {
        let url = Constants.LIST_VENDOR + "?from=purchase_order";
        this.service.getApiCall(
            url,
            this._getVendorListSuccess,
            this._getDosageTypeFailure
        )
    }

    _getVendorListSuccess = response => {

        if (response.status === "success") {

            this.setState({
                vendor_list: response.data,
                vendor_list_suggest: response.data
            });
        }
    };

    _getDosageTypeFailure = response => {
    }

    _doLoadInventoryList() {

        let { selectedScreen, current_page, records_per_page, searchValue } = this.state;
        let url = null;
        
        // if (selectedScreen === "vendors") {
        //     if(!searchValue){
        //         url = `${Constants.LIST_VENDOR}?&page=${current_page}&page_size=${records_per_page}`;
        //     }else{
        //         url = `${Constants.LIST_VENDOR}?search_term=${searchValue}&page=${current_page}&page_size=${records_per_page}`;
        //     }
        // }
        // else 
        if (selectedScreen === "purchase_orders") {
            url = `${Constants.LIST_PO}?search_term=${searchValue}&page=${current_page}&page_size=${records_per_page}&sort_by=2`;
        }
        else if (selectedScreen === "stock_list") {
            url = `${Constants.LIST_STOCK}?page=${current_page}&page_size=${records_per_page}`;

            if (searchValue) {
                url = `${url}&search=${searchValue}`
            }
        }else if (selectedScreen === "wanted_list") {
            this.setState({
                current_page: 1,
                records_per_page: 10,
                drugRow: [],
                paginationNewDrugRequestList: []
            })
            url = `${Constants.WANTED_LIST}?search_key=${searchValue}`;
        }

        if (url) {
            this.service.getApiCall(
                url,
                this.successCallBack,
                this.errorCallBack
            )
        }
    }

    successCallBack = async (response) => {

        let { data, selectedScreen } = this.state;

        if (response.detail && response.detail === "Token is expired") {
            this.props.logout();
        }
        else if (response.status == "success") {
            var drugRow = []
            if(selectedScreen === "wanted_list"){
                data['result'] = response.data ? response.data : [];
                var responseData = response.data
                drugRow = responseData
            }else{
                data['result'] = response.data ? response.data.result : [];
                data['total_count'] = response.data ? response.data.total_count : 0;
                data['no_of_pages'] = response.data ? response.data.no_of_pages : 0;
            }

            data = JSON.parse(JSON.stringify(data))

            this.setState({
                data,
                drugRow: drugRow,
            })


        } else if (response.status == "fail") {

            this.setState({
                responseMessage: response.message
            }, () => {
            })
        }
    }

    errorCallBack = (error) => {
    }

    getVendorTable() {
        let { data } = this.state;
        return (
            <View style={styles.TableContainer}>
                <View style={styles.TableWrapper}>
                    <BillTableTitle label={'S.No'} flexWidth={0.1} />
                    <BillTableTitle label={'Registration Date'} flexWidth={0.1} />
                    <BillTableTitle label={'Vendor Name'} flexWidth={0.2} />
                    <BillTableTitle label={'Registration Number'} flexWidth={0.3} />
                    <BillTableTitle label={'Phone Number'} flexWidth={0.1} />
                    <BillTableTitle label={'Email Id'} flexWidth={0.2} />
                </View>
                {data.result && data.result.map((item, index) => {
                    let sno = index + 1;
                    let registration_date = item.registration_date ? moment(item.registration_date).format("DD-MM-YYYY") : ""
                    return (
                        <TouchableOpacity key={index} onPress={() => this.props.openVendorDetails(item.vendor_id)}>
                            <View style={styles.TableBody}>
                                <BillTableData label={sno} labelType={'label'} flexWidth={0.1} />
                                <BillTableData label={registration_date} labelType={''} flexWidth={0.1} />
                                <BillTableData label={item.name} labelType={''} flexWidth={0.2} />
                                <BillTableData label={item.dl_no} labelType={''} flexWidth={0.3} />
                                <BillTableData label={item.phone_number} labelType={''} flexWidth={0.1} />
                                <BillTableData label={item.email} labelType={''} flexWidth={0.2} />
                            </View>
                        </TouchableOpacity>
                    )
                })}
            </View>
        )
    }

    getPurchaseTable() {
        let { data } = this.state;
        return (
            <View style={styles.TableContainer}>
                <View style={styles.TableWrapper}>
                    <BillTableTitle label={'GRN'} flexWidth={0.1} />
                    <BillTableTitle label={'Purchase Order Date'} flexWidth={0.15} />
                    <BillTableTitle label={'Vendor Name'} flexWidth={0.3} />
                    <BillTableTitle label={'No of Items'} flexWidth={0.1} />
                    <BillTableTitle label={'Delivery Date'} flexWidth={0.2} />
                    <BillTableTitle label={'Status'} flexWidth={0.2} />
                </View>
                {data.result && data.result.map((item, index) => {
                    // let sno=index+1;
                    let purchase_order_date = item.purchase_order_date ? moment(item.purchase_order_date).format("DD-MM-YYYY") : ""
                    let delivery_date = item.delivery_date ? moment(item.delivery_date).format("DD-MM-YYYY") : ""
                    return (
                        <TouchableOpacity key={index} onPress={() => this.props.changeScreen('add_stock', '', item.id, item.status)}>
                            <View key={index} style={styles.TableBody}>
                                <BillTableData label={item.purchase_order_number} labelType={'label'} flexWidth={0.1} />
                                <BillTableData label={purchase_order_date} labelType={''} flexWidth={0.15} />
                                <BillTableData label={item.vendor_name} labelType={''} flexWidth={0.3} />
                                <BillTableData label={item.number_of_items} labelType={''} flexWidth={0.1} />
                                <BillTableData label={delivery_date} labelType={''} flexWidth={0.2} />
                                <BillTableData label={item.status} labelType={''} flexWidth={0.2} />
                            </View>
                        </TouchableOpacity>
                    )
                })}
            </View>
        )
    }

    getStockTable() {

        let { data, current_page, records_per_page } = this.state;
        let prev_record_count = (parseInt(current_page) - 1) * parseInt(records_per_page)

        return (
            <View style={styles.TableContainer}>
                <View style={styles.TableWrapper}>
                    <BillTableTitle label={'S.No'} flexWidth={0.05} />
                    <BillTableTitle label={'Brand Name'} flexWidth={0.15} />
                    <BillTableTitle label={'Generic Name'} flexWidth={0.15} />
                    <BillTableTitle label={'Dosage Type'} flexWidth={0.1} />
                    <BillTableTitle label={'Dosage Strength'} flexWidth={0.1} />
                    <BillTableTitle label={'Batch No'} flexWidth={0.1} />
                    <BillTableTitle label={'Quantity'} flexWidth={0.1} />
                    <BillTableTitle label={'Expiry Date'} flexWidth={0.1} />
                    <BillTableTitle label={'MRP/Quantity'} flexWidth={0.1} />
                    <BillTableTitle label={'Box No'} flexWidth={0.05} />
                </View>
                {data.result && data.result.map((item, index) => {

                    let sno = prev_record_count + (index + 1);
                    // MRP
                    let mrp_per_quantity = (parseFloat(item.mrp_per_quantity) || 0).toFixed(2)

                    return (
                        <TouchableOpacity key={index} onPress={() => this.props.changeScreen('view_stock', '', item.id)}>
                            <View style={styles.TableBody}>
                                <BillTableData label={sno} labelType={'label'} flexWidth={0.05} />
                                <BillTableData label={item.brand_name} labelType={''} flexWidth={0.15} />
                                <BillTableData label={item.generic_name} labelType={''} flexWidth={0.15} />
                                <BillTableData label={item.dosage_type} labelType={''} flexWidth={0.1} />
                                <BillTableData label={item.dosage_strength} labelType={''} flexWidth={0.1} />
                                <BillTableData label={item.batch_no} labelType={''} flexWidth={0.1} />
                                <BillTableData label={item.stock_in_quantity} labelType={''} flexWidth={0.1} />
                                <BillTableData label={item.expiry_date} labelType={''} flexWidth={0.1} />
                                <BillTableData label={mrp_per_quantity} labelType={''} flexWidth={0.1} />
                                <BillTableData label={item.physical_box_no} labelType={''} flexWidth={0.05} />
                            </View>
                        </TouchableOpacity>
                    )
                })}
            </View>
        )
    }

    paginationChangedValues(data) {
        this.setState({
            paginationNewDrugRequestList: data
        })
    }
    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }

    vendorSuggestionList = () => {
        let {vendor_list, drugRow, selectedIndex, current_page, records_per_page} = this.state
        let tempDescList = this.state.vendor_list_suggest
        let prev_record_count = (parseInt(current_page)-1)*parseInt(records_per_page)
        if (tempDescList.length > 0) {
            var searchListTemp = [];
            for (let i = 0; i < tempDescList.length; i++) {
      
              if (tempDescList[i].name.toLowerCase().indexOf(drugRow[prev_record_count + selectedIndex]['vendor_name'].toLowerCase()) > -1) {
                searchListTemp.push(tempDescList[i]);
              }
            }
            vendor_list = searchListTemp;
          } else {
            vendor_list = this.state.vendor_list_suggest;
          }
          this.setState({
            vendor_list
          })
    }

    onClickMoveToOrder = (item) => {
        var {drugRow} = this.state
        var url = Constants.MOVE_ORDER_LIST
        var data = {
            vendor_id: item.vendor_id ? item.vendor_id : '',
            drug_id: item.drug_id,
            stock_id: item.stock_id,
            order_quantity: item.suggested_quantity ? item.suggested_quantity : 0
        }

        this.service.postApiCall(
            url,
            data,
            this.successToOrder,
            this.errorToOrder
        )
    }

    successToOrder = (response) => {
    if(response.status === "success"){
        this.props.responseSuccessErrorMessage(response.message, true)
            setTimeout(() => {
                this.props.responseSuccessErrorMessage("", true)
            }, 2000)
        this._doLoadInventoryList()
    }else if (response.status == "fail") {
        this.props.responseSuccessErrorMessage(response.message, false)
        setTimeout(() => {
            this.props.responseSuccessErrorMessage("", false)
        }, 2000)
    }
    }

    errorToOrder = (response) => {
        this.props.responseSuccessErrorMessage(response.message, false)
        setTimeout(() => {
            this.props.responseSuccessErrorMessage("", false)
        }, 2000)
    }

    getWantedTable = () => {
        let { data, current_page, records_per_page, drugRow, paginationNewDrugRequestList } = this.state;
        var states = this.state
        var suggestionHeight = ((states.selectedIndex + 1) * 9) + 30
        let prev_record_count = (parseInt(current_page)-1)*parseInt(records_per_page)
        return (
            <View style={styles.TableContainer}>
                <ScrollView style={{maxHeight: '65vh'}}>
                <View style={{ flexDirection: "row", width: "100vw"}}>
                <View style={{width: "15vw"}}>
                    <CommonReportSectionHeader 
                    heading1={"S.No"}
                    heading2={"Brand Name"}
                    columnSize={[0.20,0.80]}
                    noOfColumn={2}
                    fontSize={'1vw'}  
                    />
                    {paginationNewDrugRequestList && paginationNewDrugRequestList.map((item, index) => {
                       let sno=prev_record_count + (index+1);
                   return ( 
                   <View style={{ justifyContent: 'center', maxHeight: 60, minHeight: 60 }} key = {index}>
                    <View style={{ flex:1, flexDirection:"row", borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, alignItems: 'center'  }}>
                    <Text style={{marginLeft: '1.3vw', fontSize:"1vw", flex:0.20 }}>{sno}</Text>
                    
                    <Tooltip placement="topLeft" title={item.drug_name}>               
                    <Text style={{fontSize:"1vw", flex: 0.80}}> {item.drug_name && item.drug_name.length > 20 ? item.drug_name.slice(0,20) + ".." :( item.drug_name != null && item.drug_name != "") ? item.drug_name : "-"} </Text> 
                    </Tooltip>
                    </View>
                    </View>
                    )})}
                </View> 
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={(paginationNewDrugRequestList && paginationNewDrugRequestList.length > 0) ? true : false}  style={{maxWidth: "65vw", flex: 1, minHeight: suggestionHeight+'vh'}}>
                <View style={{width: "90vw"}}>
                <CommonReportSectionHeader
                    heading1={"Generic Name"}
                    heading2={"Dosage Type/Strength"}
                    heading3={"Recent Exp. On"}
                    heading4={"Avl. Qty"}
                    heading5={"Latest MRP/Qty"}
                    heading6={"Place order Vendor"}
                    heading7={"Place order Qty"}
                    columnSize={[0.15, 0.20, 0.12, 0.12, 0.15, 0.20, 0.15 ]}  
                    noOfColumn={7}
                    />
                    {paginationNewDrugRequestList && paginationNewDrugRequestList.map((item, index) => (
                        <View style={{ justifyContent: 'center', maxHeight: 60, minHeight: 60, zIndex: 1 - index }} key = {index}>
                        <View style={{flexDirection: 'row', flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 1, alignItems: 'center' }}>
                        <Tooltip placement="topLeft" title={item.generic_name}>               
                        <Text style={{flex: 0.15, marginLeft: '1.3vw',fontSize:"1vw" }}> {item.generic_name && item.generic_name.length > 18 ? item.generic_name.slice(0,20) + ".." :( item.generic_name != null && item.generic_name != "") ? item.generic_name : "-"} </Text> 
                        </Tooltip>
                        <Tooltip placement="topLeft" title={item.dosage_type + "/" + item.drug_strength}>               
                        <Text style={{flex: 0.20 , fontSize: "1vw" }}> {item.dosage_type && item.dosage_type.length > 15 ? item.dosage_type.slice(0,15) + ".." :( item.dosage_type != null && item.dosage_type != "") ? item.dosage_type : "-"}{"/"}{item.drug_strength ? item.drug_strength : "-"} </Text> 
                        </Tooltip>
                        <Text style={{ flex: 0.12, fontSize: "1vw" }}>{item.expiry_date}</Text>
                        <Text style={{ flex: 0.12, fontSize: "1vw" }}>{item.available_quantity}</Text>
                        <Text style={{ flex: 0.15, fontSize: "1vw" }}>{item.mrp_per_quantity}</Text>
                        <View style={{ flex: 0.20, zIndex: 0 }}>
                        <TextInput 
                        style={{
                            borderColor: "#CDD1D5",
                            borderRadius: '.26vw',
                            borderWidth: 1,
                            width: "80%",
                            height: '5vh',
                            paddingLeft: '0.65vw',
                            marginTop: '.52vw',
                            zIndex: -1,
                            fontSize: "1vw"
                        }} 
                        value={item.vendor_name ? item.vendor_name : ''} 
                        onChangeText = {(text) => {
                            if(text != ""){
                                states['isVendorSuggestion'] = true;
                                drugRow[prev_record_count + index]['vendor_id'] = null;
                                states["selectedIndex"] = index
                            }else{
                                states['isVendorSuggestion'] = false;
                                drugRow[prev_record_count + index]['vendor_id'] = null;
                                states["selectedIndex"] = null
                            }
                            drugRow[prev_record_count + index]['vendor_name'] = text

                            this.setState({
                            drugRow,
                            states
                            }, () => {
                            if((text).trim() != ""){
                                this.vendorSuggestionList()
                            }
                            })
                        }}
                        />
                        {states.isVendorSuggestion && states.vendor_list.length > 0 && index === states.selectedIndex ?
                        <View style={{ zIndex: 2, position: 'absolute', marginTop: 40, width: "90%"}}>
                            <ScrollView
                            style={{
                                maxHeight: '25vh', marginBottom: 10, flex: 1,
                                backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
                            }}
                            >
                            {this.state.vendor_list.map((value, index) =>
                                <View style={{ height: 30 }}>
                                <TouchableOpacity key={index}
                                    style={{ marginLeft: 5, marginTop: 5 }}
                                    onPress={
                                    () => {
                                        drugRow[prev_record_count + states.selectedIndex]['vendor_name'] = value.name
                                        drugRow[prev_record_count + states.selectedIndex]['vendor_id'] = value.vendor_id,
                                        states["selectedIndex"] = null,
                                        states["isVendorSuggestion"] = false
                                        this.setState({
                                            drugRow,
                                            states
                                        })
                                    }
                                    }
                                >
                                    <Text style={{ fontSize: "1vw" }}>{value.name}</Text>

                                </TouchableOpacity>
                                </View>
                            )} 
                            </ScrollView>
                        </View>
                        : null
                        }
                        </View>
                        <View style={{ flex: 0.15 }}>
                        <TextInput 
                        style={{
                            borderColor: "#CDD1D5",
                            borderRadius: '.26vw',
                            borderWidth: 1,
                            width: "60%",
                            height: '5vh',
                            paddingLeft: '0.65vw',
                            marginTop: '.52vw',
                            fontSize: "1vw"
                        }} 
                        value={item.suggested_quantity ? item.suggested_quantity : ''} 
                        onChangeText = {(text) => {
                            if ((Constants.REG_EX_NUMBERS.test(text))) {
                                drugRow[prev_record_count + index]['suggested_quantity'] = text
                            }
                            this.setState({
                            drugRow
                            })
                        }}
                        />
                        </View>
                        </View>
                        </View>
                    ))}
                </View>
                </ScrollView>
                <View style={{width: "10vw"}}>
                    <CommonReportSectionHeader 
                    heading1={"Action"}
                    columnSize={[1]}
                    noOfColumn={1} 
                    fontSize={'1vw'}
                    />
                    {paginationNewDrugRequestList && paginationNewDrugRequestList.map((item, index) => (
                    <View style={{flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, width: "100%", maxHeight: 60, minHeight: 60 }}>
                    <View key={index} style={{ flex: 1, marginHorizontal: "1.30vw", justifyContent: 'center', alignItems: 'center'}}>
                    <CommonButton
                    item={{}}
                    selectedvalue={{}}
                    butttonText={"Move to Order"}
                    buttonType={"theme"}
                    buttonIcon={""}
                    rightIcon={true}
                    buttonAction={() => {
                        this.onClickMoveToOrder(item, index)
                    }}
                    buttonKey={"moveToOrder"} />
                    </View>
                    </View>
                    ))}
                </View>
                </View>
                </ScrollView>
                {
                    drugRow && drugRow.length > 0 ?
                        <View style={{ marginLeft: 20, zIndex: 0 }}>
                        <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)}
                            totalClientItems={drugRow}
                            paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                        />
                        </View> 
                    : null
                    
                }
            </View>
        )
    }


    render() {

        let { data, current_page, records_per_page, resportActions } = this.state;
        return (
            <View style={{ margin: "1vw" }}>
                <MenuBreadCrumpList menu={this.state.menu}
                    selectedScreen={this.state.selectedScreen}
                    changeScreen={this.props.changeScreen}
                    generateReport={this._generateReport}
                    isDisable={!resportActions}
                />
                {this.state.selectedScreen === 'purchase_orders' ? this.getPurchaseTable() :
                    this.state.selectedScreen === 'stock_list' ? this.getStockTable() :
                        this.state.selectedScreen === 'wanted_list' ? this.getWantedTable() : null
                }

                {data.total_count && parseInt(data.total_count) > 0 && this.state.selectedScreen != 'wanted_list' ?
                    <Pagination
                        paginationChangedValues={this._paginationChangedValues.bind(this)}
                        totalClientItems={""}
                        type={Constants.PAGINATION_TYPE_SERVER}
                        totalServerRecordsCount={data.total_count}
                        totalServerPagesCount={data.no_of_pages}
                    /> : null}
            </View>
        )
    }

    onPressButton() { }

    _paginationChangedValues(next_page, records_per_page_change) {

        let { current_page, records_per_page } = this.state

        if (current_page != next_page || records_per_page != records_per_page_change) {

            this.setState({
                records_per_page: records_per_page_change,
                current_page: next_page
            }, () => {
                this._doLoadInventoryList()
            })
        }
    }

    _generateReport = (type = '') => {

        var url = ""
        if (this.state.selectedScreen == "vendors") {
            url = Constants.LIST_VENDOR + "?export=" + type+"&search_term="+ this.state.searchValue;
        }
        else if (this.state.selectedScreen == "purchase_orders") {
            url = Constants.LIST_PO + "?export=" + type+"&search_term="+ this.state.searchValue;

        } else if (this.state.selectedScreen == "stock_list") {
            url = Constants.LIST_STOCK + "?export=" + type+"&search="+ this.state.searchValue;
        }

        if (type) {

            this.setState({ resportActions: false }, () => {
                if (type == 'pdf') {
                    this.service.getInstance().documentUploadGet(
                        url,
                        this.PrintReportSuccess,
                        this.PrintReportFailure,
                        "pdf"
                    );
                } else if (type == 'excel') {
                    this.service.getInstance().documentUploadGet(
                        url,
                        this.exportCSVSuccessCallBack,
                        this.exportCSVSuccessCallBack,
                        "excel"
                    );
                }
            })
        }
    };

    PrintReportSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            // enable actions
            this._enablePrintActions()

        } else {
            // enable actions
            this._enablePrintActions()
        }
    };

    PrintReportFailure = error => {
        // enable actions
        this._enablePrintActions()
    };

    exportCSVSuccessCallBack = response => {
        if (response) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            // enable actions
            this._enablePrintActions()
        } else {
            // enable actions
            this._enablePrintActions()
        }
    }

    exportCSVErrorCallBack = error => {
        // enable actions
        this._enablePrintActions()
    }

    _enablePrintActions = () => {
        this.setState({ resportActions: true })
    }
}

const styles = StyleSheet.create({
    TableContainer:{ 
        flex: 1, 
        flexDirection: "column", 
        justifyContent: "flex-start", 
        alignContent: "center" 
    },
    TableWrapper:{
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#E2E2E2", padding: ".52vw", borderRadius: ".25vw"
    },
    TableBody:{
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#F9F9F9", padding: ".52vw", borderRadius: ".25vw", marginTop: ".32vw",
        borderBottomWidth: 1, borderColor: "#E2E2E2"
    }
});