// import Pagination from './Pagination'
{/* <Pagination/> */ }
// https://stackoverflow.com/questions/25434813/simple-pagination-in-javascript

//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import Constants from '../networks/Constants';

// create a component
export default class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paginationType: this.props.type ? this.props.type : Constants.PAGINATION_TYPE_CLIENT,
            isCustome: this.props.isCustome ? true : false,

            current_page: this.props.current_page ? this.props.current_page : 1,
            records_per_page: this.props.recordsPerPage ? this.props.recordsPerPage : 10,

            listing_table: [],
            fromList: "",
            toList: "",

            objJson: [], // Can be obtained from another source, such as your objJson variable
            totalClientItems: this.props.totalClientItems ? this.props.totalClientItems : 0,

            // server side pagination
            totalServerRecordsCount: this.props.totalServerRecordsCount ? this.props.totalServerRecordsCount : 0,
            totalServerPagesCount: this.props.totalServerPagesCount ? this.props.totalServerPagesCount : 0

        }

        this._changePageServercall = this._changePageServercall.bind(this)
        this.prevPage = this.prevPage.bind(this)
        this.nextPage = this.nextPage.bind(this)
    }

    componentDidMount() {

        let totalClientItems = this.props.totalClientItems

        if (this.state.paginationType == Constants.PAGINATION_TYPE_CLIENT) {
            // for client side validation
            this.initiateClientSidePagination(totalClientItems)

        } else if (this.state.totalServerRecordsCount) {
            // for server side validation
            this.initiateServerSidePagination()
        }
    }

    componentWillReceiveProps(props) {

        let { paginationType, totalServerRecordsCount, totalServerPagesCount } = this.state;
        var getData = props.totalClientItems;

        if (paginationType == Constants.PAGINATION_TYPE_CLIENT) {
            if (getData && getData.length !== this.state.objJson.length) {
                // for client side validation
                this.initiateClientSidePagination(getData)

                var {current_page, records_per_page} = this.state

                this.setState({
                    current_page: props.current_page ? props.current_page : current_page,
                    records_per_page: props.recordsPerPage ? props.recordsPerPage : records_per_page,
                    objJson: getData
                })
            }
        } else {
            // server side request process 
            if (totalServerRecordsCount != props.totalServerRecordsCount || totalServerPagesCount != props.totalServerPagesCount) {

                this.setState({
                    current_page: 1,
                    totalServerRecordsCount: props.totalServerRecordsCount,
                    totalServerPagesCount: props.totalServerPagesCount,
                    records_per_page: totalServerRecordsCount != props.totalServerRecordsCount ? 10 : this.state.records_per_page

                }, () => {
                    this.initiateServerSidePagination()
                })
            }
        }
    }

    initiateClientSidePagination(data) {
        var getData = []
        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                var prepareData = data[i];
                prepareData["tempPaginationId"] = i + 1
                getData.push(prepareData);
            }
            if (data.length > 0) {
                this.setState({
                    objJson: getData
                }, () => {
                    this.changePage(this.state.current_page);
                })
            }
        }
    }

    prevPage() {
        let { current_page, paginationType } = this.state;
        if (current_page > 1) {
            this.setState({
                current_page: parseInt(current_page) - 1
            }, () => {
                if (paginationType == Constants.PAGINATION_TYPE_CLIENT) {
                    this.changePage(current_page-1)
                } else {
                    this._changePageServercall(this.state.current_page)
                }
            })
        }
    }

    nextPage() {
        let { current_page, totalServerPagesCount, paginationType } = this.state;
        if (paginationType == Constants.PAGINATION_TYPE_CLIENT) {
            if (current_page < this.numPages()) {
                this.setState({
                    current_page: parseInt(current_page) + 1
                }, () => {
                    this.changePage(this.state.current_page);
                })
            }
        } else {
            totalServerPagesCount = parseInt(totalServerPagesCount);
            if (current_page < totalServerPagesCount) {
                this.setState({
                    current_page: parseInt(current_page) + 1
                }, () => {
                    this._changePageServercall(this.state.current_page);
                })
            }
        }
    }

    changePage(page) {
        if (page < 1) page = 1;
        if (page > this.numPages()) page = this.numPages();
        var getlisting_table = [];
        for (var i = (page - 1) * this.state.records_per_page; i < (page * this.state.records_per_page) && i < this.state.objJson.length; i++) {
            getlisting_table.push(this.state.objJson[i]);
        }
        this.setState({
            fromList: getlisting_table[0].tempPaginationId,
            toList: getlisting_table[getlisting_table.length - 1].tempPaginationId,
            listing_table: getlisting_table
        })
        this.props.paginationChangedValues(getlisting_table)
        this.props.paginationCurrentPage ? this.props.paginationCurrentPage(this.state.current_page,this.state.records_per_page) : () => {}
    }

    numPages() {
        return Math.ceil(this.state.objJson.length / this.state.records_per_page);
    }

    render() {

        let { paginationType, current_page, totalServerRecordsCount } = this.state;

        let paginationDetails = `of ${paginationType == Constants.PAGINATION_TYPE_CLIENT ? (this.state.objJson).length : totalServerRecordsCount}`

        return (
            <View style={{ flexDirection: "row", marginVertical: 20 }}>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity style={[inStyle.paginationBlockIcon, {
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: "#CCF0EF",
                        shadowColor: "#090F36",
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.6,
                        shadowRadius: 3,
                        elevation: 5,
                    }]} onPress={() => {
                        this.prevPage()
                    }}>
                        <Text style={{fontSize: '1vw'}}>{"<"}</Text>
                    </TouchableOpacity>

                    <View style={[inStyle.paginationBlockIcon, {
                        minWidth: '8.5vw', backgroundColor: "#EDFAFA", flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }]}>
                        <Text style={[inStyle.textStyle, { flex: 1, textAlign: "center", fontSize: '1vw' }]}>
                            {this.state.fromList + " - " + this.state.toList + " " + paginationDetails}</Text>
                    </View>

                    <TouchableOpacity style={[inStyle.paginationBlockIcon, {
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: "#CCF0EF",
                        shadowColor: "#090F36",
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.6,
                        shadowRadius: 3,
                        elevation: 5
                    }]} onPress={() => {
                        this.nextPage()
                    }}>
                        <Text style={{fontSize: '1vw'}}>{">"}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ flexDirection: "row" }}>
                    <Picker
                        selectedValue={this.state.records_per_page}
                        style={[inStyle.paginationBlockIcon, {
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: "#CCF0EF",
                            shadowColor: "#090F36",
                            shadowOffset: { width: 0, height: 2 },
                            shadowOpacity: 0.6,
                            shadowRadius: 3,
                            borderWidth: 0, borderColor: "transparent",
                            elevation: 5,
                            width: '4.2vw',
                            height: '2vw',
                            fontSize: '1vw',
                            marginLeft: '2.3vw',
                        }]}
                        onValueChange={(itemvalue) => {
                            this.setState({ records_per_page: itemvalue, current_page: 1 }, () => {
                                if (paginationType == Constants.PAGINATION_TYPE_CLIENT) {
                                    this.changePage(this.state.current_page)
                                } else {
                                    this._changePageServercall(this.state.current_page)
                                }
                            });
                        }}
                    >
                        <Picker.Item label="10" value={10} />
                        <Picker.Item label="15" value={15} />
                        <Picker.Item label="20" value={20} />
                        <Picker.Item label="25" value={25} />
                        <Picker.Item label="30" value={30} />
                    </Picker>
                    <Text style={[inStyle.textStyle, { marginLeft: "1vw", fontSize: '1vw' }]}>{"Items Per Page"}</Text>
                </View>
            </View>
        );
    }

    initiateServerSidePagination() {
        let { current_page } = this.state;
        if (current_page) {
            this.setState({
                current_page: 1
            }, () => {
                this._changePageServercall(this.state.current_page, false)
            })
        }
    }

    _changePageServercall(page, triggerServerCall = true) {
        let { totalServerRecordsCount, totalServerPagesCount, records_per_page } = this.state;
        // format data
        page = parseInt(page);
        records_per_page = parseInt(records_per_page);
        totalServerRecordsCount = parseInt(totalServerRecordsCount);
        if (page < 1) page = 1;
        if (page > totalServerPagesCount) {
            page = totalServerPagesCount;
        }

        let record_start_from = (page - 1) * records_per_page + 1;
        let record_end_whole_no = page * records_per_page;
        let record_end_to = record_end_whole_no < totalServerRecordsCount ? record_end_whole_no : totalServerRecordsCount;
        this.setState({
            fromList: record_start_from,
            toList: record_end_to
        }, () => {
            if (triggerServerCall) {
                this.props.paginationChangedValues(page, records_per_page)
            }
        })
    }
}

// define your styles
const inStyle = StyleSheet.create({
    container: {
        flex: 1,
    },
    textStyle: {
        color: "black", fontWeight: '500', alignSelf: "center"
    },
    paginationBlockIcon: {
        width: '2vw',
        height: '2vw'
    }
});