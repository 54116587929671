import React, { Component } from 'react';
import { Text, View, ScrollView, StyleSheet, TextInput, Image, TouchableOpacity } from 'react-native';
import { CommonTextBox, SuccessAlert, ErrorAlert } from '../../../common/BaseFunctionComponent';
import moment from "moment";
import { color } from "../../../styles/Color";
import Service from "../../../networks/service";
import Constants from "../../../networks/Constants";
import { Checkbox } from 'antd';
import Icon from "react-native-vector-icons/FontAwesome";
import { Tooltip } from 'antd';


let SelectedQuantity = [] // Array of Selected Quantity of list
let RequestedQuantity = []  // Array of Requested Quantity of list
export class OTIndentDetails extends Component {

    constructor(props) {
        super(props)

        let fulldate = new Date();
        let converted_date = moment(fulldate).format("YYYY-MM-DD");

        this.state = {
            OTIndentDrugListData: [],

            startDate: converted_date,
            endDate: converted_date,
            filterDate: "",
            showDateFilter: false,

            menu: this.props.menu ? this.props.menu : "",
            selectedScreen: this.props.selectedScreen ? this.props.selectedScreen : "",

            loginUserData: this.props.loginUserData,

            BatchPopup: false,
            SelectedDrug: {},
            DrugRows: [],
            SelectedIndex: "",

            showResponse: "",
            showErrorResponse: "",
            responseMessage: "",
        }
        this.service = new Service()
    }


    componentDidMount() {
        this.getIndentRequestList();
    }

    pushDrugRows(data) {
        let { DrugRows } = this.state;
        RequestedQuantity = []
        SelectedQuantity = []
        for (let i = 0; i < this.state.OTIndentDrugListData.length; i++) {
            DrugRows.push(JSON.parse(JSON.stringify(data[i])))
            RequestedQuantity[i] = DrugRows[i].quantity
        }
    }

    getIndentRequestList() {
        RequestedQuantity = []
        SelectedQuantity = []
        let url = Constants.OT_INDENT_DETAILED_LIST +
            "?indent_number=" + this.props.IndentDetails.indent_number
        this.service.getApiCall(
            url,
            this.getIndentDrugListSuccess,
            this.getIndentDrugListFailure
        )
    }

    getIndentDrugListSuccess = (response) => {
        if (response.status === "Success") {
            this.setState({
                OTIndentDrugListData: response.data,

            }, () => {
                this.pushDrugRows(response.data)
            });
        }
    }

    getIndentDrugListFailure = (response) => {
        console.log(response.message)
    }


    onPressActions = (indent_number) => {
        let { DrugRows } = this.state
        let FinalList = []  // stores All Selected List of Items
        for (let i = 0; i < DrugRows.length; i++) {

            if (DrugRows[i].SelectedBatches) {
                FinalList[i] =
                {
                    "id": DrugRows[i].id,
                    "batch_details": []
                }
                for (let j = 0; j < DrugRows[i].SelectedBatches.length; j++) { // stores drug's individual List of Items 
                    if (DrugRows[i].SelectedBatches[j].quantity != "") {
                        FinalList[i].batch_details[j] = DrugRows[i].SelectedBatches[j]
                        FinalList[i].batch_details[j].quantity = parseInt(DrugRows[i].SelectedBatches[j].quantity)
                    }
                }
            }
            for (let i = 0; i < FinalList.length; i++) {
                FinalList[i].batch_details = FinalList[i].batch_details.filter(x => x != null);
            }

        }
        try {

            if ((JSON.stringify(RequestedQuantity) == JSON.stringify(SelectedQuantity))) {   //  Check Every Durg has Selected Batch with Quantity
                var url = Constants.INDENT_STATUS_POST;
                var updateItem = {}
                updateItem['indent_number'] = indent_number;
                updateItem['po_details'] = FinalList;
                updateItem['status'] = "Approved";
                var data = updateItem
                if (FinalList.length > 0) {
                    this.service.postApiCall(
                        url,
                        data,
                        this._onPressActionSuccess,
                        this._onPressActionFailure
                    )
                }
                else {
                    this.responseSuccessErrorMessage("Select Batches and quantity For All Drugs", false)
                    setTimeout(() => {
                        this.responseSuccessErrorMessage("", false)
                    }, 3000)
                }
            }
            else {
                this.responseSuccessErrorMessage("Select Batches and quantity For All Drugs", false)
                setTimeout(() => {
                    this.responseSuccessErrorMessage("", false)
                }, 3000)
            }


        }
        catch {
            this.responseSuccessErrorMessage("Select Batches and quantity For All Drugs", false)
            setTimeout(() => {
                this.responseSuccessErrorMessage("", false)
            }, 3000)
        }
    }

    _onPressActionSuccess = (response) => {
        if (response.status == 'Success') {
            this.responseSuccessErrorMessage(response.message, true)
            this.props.ApproveAlert(response.message)
            this.props.NavigateScreen("OTindentRequest")
            setTimeout(() => {
                this.responseSuccessErrorMessage("", true)
            }, 3000)
        } else {
            this.responseSuccessErrorMessage(response.message, false)

            setTimeout(() => {
                this.responseSuccessErrorMessage("", false)
            }, 3000)
        }
    }
    _onPressActionFailure = (error) => {
        this.responseSuccessErrorMessage(error.message, false)
        setTimeout(() => {
            this.responseSuccessErrorMessage("", false)
        }, 3000)
    }

    responseSuccessErrorMessage(message, type) {
        if (message != "") {
            if (type) {
                this.setState({
                    showResponse: true,
                    responseMessage: message,
                    showErrorResponse: false
                })
            } else {
                this.setState({
                    showResponse: false,
                    showErrorResponse: true,
                    responseMessage: message
                })
            }
        } else {
            this.setState({
                showResponse: false,
                showErrorResponse: false,
            })
        }
    }


    onCheckClick = (list) => {
        let BatchAdd = [
            {
                'id': list.id,
                'batch_no': list.batch_no,
                "quantity": '',
            }
        ]
        let { DrugRows, SelectedIndex } = this.state;

        const indexOfObject = DrugRows[SelectedIndex]['SelectedBatches'] ? DrugRows[SelectedIndex]['SelectedBatches'].findIndex(object => {
            return object.id === list.id;
        }) : console.log(indexOfObject, "$$$");;

        if (indexOfObject >= 0) {
            DrugRows[SelectedIndex]['SelectedBatches'] ? DrugRows[SelectedIndex]['SelectedBatches'].splice(indexOfObject, 1) : null;
        } else {
            DrugRows[SelectedIndex]['SelectedBatches'] = this.state.DrugRows[this.state.SelectedIndex].SelectedBatches ? this.state.DrugRows[this.state.SelectedIndex].SelectedBatches.concat(BatchAdd) : BatchAdd;
        }
        this.setState({ DrugRows })

    }
    returnInputValue(list) {
        let { DrugRows, SelectedIndex } = this.state;

        const indexOfObject = DrugRows[SelectedIndex]['SelectedBatches'] ? DrugRows[SelectedIndex]['SelectedBatches'].findIndex(object => {
            return object.id === list.id;
        }) : console.log(indexOfObject, "$$$");;

        return DrugRows[this.state.SelectedIndex].SelectedBatches[indexOfObject].quantity


    }

    returnCheckValue(list) {
        let { DrugRows, SelectedIndex } = this.state;

        const indexOfObject = DrugRows[SelectedIndex]['SelectedBatches'] ? DrugRows[SelectedIndex]['SelectedBatches'].findIndex(object => {
            return object.id === list.id;
        }) : console.log(indexOfObject, "$$$");;

        if (indexOfObject >= 0) {
            return true
        }
    }

    numberValidation(num) {
        return /^\d+$/.test(num);
    }

    renderTextInput(list, drug, index) {
        let { DrugRows, SelectedIndex } = this.state;

        const indexOfObject = DrugRows[SelectedIndex]['SelectedBatches'] ? DrugRows[SelectedIndex]['SelectedBatches'].findIndex(object => {
            return object.id === list.id;
        }) : console.log(indexOfObject, "$$$");;

        if (indexOfObject >= 0) {
            return (
                <TextInput
                    value={this.returnInputValue(list)}
                    style={{ fontSize: '0.8vw', backgroundColor: 'EFEFEF', width: '30%', height: '1.5vw', margin: '0.3vw', borderColor: color.themeDark, borderWidth: 1, borderRadius: '0.3vw', paddingLeft: "0.5vw", color: 'black' }}
                    onChangeText={(text) => {
                        var isValid = this.numberValidation(text)


                        if (isValid || text === "") {
                            let { DrugRows, SelectedIndex } = this.state;

                            const indexOfObject = DrugRows[SelectedIndex]['SelectedBatches'] ? DrugRows[SelectedIndex]['SelectedBatches'].findIndex(object => {
                                return object.id === list.id;
                            }) : console.log(indexOfObject, "$$$");
                            var BalanceQuantity = drug.quantity;
                            for (let i = 0; i < DrugRows[this.state.SelectedIndex].SelectedBatches.length; i++) {
                                if (DrugRows[this.state.SelectedIndex].SelectedBatches[i].quantity != "") {
                                    BalanceQuantity = BalanceQuantity - parseInt(DrugRows[this.state.SelectedIndex].SelectedBatches[i].quantity)
                                }
                            }
                            // if (text <= list.stock_in_quantity && text <= drug.quantity && text <= BalanceQuantity) {
                            if (text <= list.quantity && text <= drug.quantity) {
                                DrugRows[this.state.SelectedIndex].SelectedBatches[indexOfObject].quantity = text
                            }
                            this.setState({ DrugRows })
                        }
                        this.CalculateTotal(index)
                    }}
                />
            )
        }
    }
    BatchSelectionPop(drug, index) {
        return (
            <View style={[styles.BatchSelectPopUP, { zIndex: (index + 1) * (-10) }]}>
                <View style={styles.BatchSelectPopUPWrapper}>
                    <Text style={{ fontSize: '0.7vw', width: '5%', textAlign: 'center' }}></Text>
                    <Text style={{ fontSize: '0.7vw', width: '30%', textAlign: 'center' }}>Batch Name</Text>
                    <Text style={{ fontSize: '0.7vw', width: '30%', textAlign: 'center', marginLeft: '0.2vw' }}>Avail.Quantity</Text>
                    <TouchableOpacity style={{ width: '30%', display: 'flex', flexDirection: 'row-reverse' }} onPress={() => { this.setState({ BatchPopup: false }) }}>
                        <Icon name="window-close-o" size={'0.97vw'} color={color.themeDark} />
                    </TouchableOpacity>
                </View>
                <ScrollView showsVerticalScrollIndicator={false}>
                    {(this.state.DrugRows[this.state.SelectedIndex].batch_details) ?

                        this.state.DrugRows[this.state.SelectedIndex].batch_details.length > 0 ?
                            (this.state.DrugRows[this.state.SelectedIndex].batch_details).map((list, index1) => (
                                list.quantity > 0 ?
                                    <View style={styles.BatchScrollView}>
                                        <Checkbox
                                            checked={this.returnCheckValue(list)}
                                            onChange={() => { this.onCheckClick(list) }} />
                                        <Text style={{ fontSize: '0.8vw', width: '30%', textAlign: 'center' }}>{list.batch_no}</Text>
                                        <Text style={{ width: '30%', fontSize: '0.8vw', textAlign: 'center', marginLeft: '0.5vw' }}>{list.quantity}</Text>
                                        {this.renderTextInput(list, drug, index)}

                                    </View> : null

                            ))
                            : <Text style={styles.NoListBatch}>No Stock Available</Text>
                        : null
                    }
                </ScrollView>

            </View>
        )
    }

    CalculateTotal(index) {
        let { DrugRows, } = this.state;
        let total = 0

        try {
            if (DrugRows[index].SelectedBatches) {
                for (let j = 0; j < DrugRows[index].SelectedBatches.length; j++) {
                    if (DrugRows[index].SelectedBatches[j]) {
                        if (DrugRows[index].SelectedBatches[j].quantity != "") {
                            total = parseInt(total) + parseInt(DrugRows[index].SelectedBatches[j].quantity)
                        }

                    }

                }
            }
            SelectedQuantity[index] = total
            return total
        }
        catch {
            SelectedQuantity[index] = 0
            return 0
        }

    }


    render() {
        return (
            <View style={{ marginTop: this.props.FromInventory ? "2vw" : 0 }}>

                <View style={styles.TopView}>
                    <View>
                        <TouchableOpacity
                            onPress={() => {
                                this.props.NavigateScreen("OTindentRequest")
                            }}
                            style={styles.BackBtn} >
                            <View style={styles.BackBtnImg}>
                                <Image
                                    // style={style.BackIcon}
                                    style={{ width: '1.3vw', height: '1.3vw' }}
                                    source={require('../../../../assets/Icon/Back.png')}
                                />
                                <Text style={styles.BackText}>{"Back"}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.IndentDetails}>
                        <CommonTextBox disable={true} width={'9.7vw'} placeholder={"Date"} value={this.props.IndentDetails.indent_date__date} />
                        <CommonTextBox disable={true} width={'9.7vw'} placeholder={"Indent Number"} value={this.props.IndentDetails.indent_number} />
                        <CommonTextBox disable={true} width={'9.7vw'} placeholder={"No of Items"} value={this.props.IndentDetails.no_of_line_items} />
                        <CommonTextBox disable={true} width={'13vw'} placeholder={"Status"} value={this.props.IndentDetails.status} />
                    </View>
                </View>
                <View style={styles.TableContainer}>
                    <View style={{ width: "100%" }}>
                        <View style={[styles.TableTitleContainer]}>
                            <View style={{ alignItems: "center", width: '4%' }} >
                                <Text style={styles.TableDataText}>S No</Text>
                            </View>
                            <View style={{ alignItems: "center", width: '16%' }} >
                                <Text style={styles.TableDataText}>Brand Name</Text>
                            </View>
                            <View style={{ alignItems: "center", width: '16%' }} >
                                <Text style={styles.TableDataText}>Generic Name</Text>
                            </View>
                            <View style={{ alignItems: "center", width: '12%' }} >
                                <Text style={styles.TableDataText}>Dosage Type</Text>
                            </View>
                            <View style={{ alignItems: "center", width: '24%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
                                <Text style={[styles.TableDataText, { marginLeft: '5vw' }]}>Batch Selection</Text><Text style={styles.TableDataText}>Selected Quantity</Text>

                            </View>
                            <View style={{ alignItems: "center", width: '12%' }} >
                                <Text style={styles.TableDataText}>Dosage Strength</Text>
                            </View>
                            <View style={{ alignItems: "center", width: '12%' }} >
                                <Text style={styles.TableDataText}>Quantity</Text>
                            </View>
                        </View>
                    </View>

                    <ScrollView style={styles.TableDataScroll}>

                        {this.state.OTIndentDrugListData && this.state.OTIndentDrugListData.length > 0 && this.state.OTIndentDrugListData.map((item, index) => {
                            this.state.DrugRows.length > 0 ? this.state.DrugRows[index].brand_name = item.brand_name : null
                            return (
                                <View key={index} style={[styles.TableDataWrapper, { zIndex: (index + 1) * (-100) }]} >
                                    <View style={{ alignItems: "center", width: '4%' }}><Text style={styles.TableDataText}>{index + 1}</Text></View>
                                    <View style={{ alignItems: "center", width: '16%' }}>
                                        <Tooltip title={item.brand_name && (item.brand_name).length > 20 ? item.brand_name : null}>
                                            <Text style={styles.TableDataText}>{item.brand_name && (item.brand_name).length > 20 ? (item.brand_name).slice(0, 20) + ".." : item.brand_name}</Text>
                                        </Tooltip>
                                    </View>

                                    <View style={{ alignItems: "center", width: '16%' }}>
                                        <Tooltip title={item.generic_name && (item.generic_name).length > 20 ? item.generic_name : null}>
                                            <Text style={styles.TableDataText}>{item.generic_name && (item.generic_name).length > 20 ? (item.generic_name).slice(0, 20) + ".." : item.generic_name}</Text>
                                        </Tooltip>
                                    </View>
                                    <View style={{ alignItems: "center", width: '12%' }}><Text style={styles.TableDataText}>{item.dosage_type}</Text></View>
                                    <View style={{ width: '24%' }}>
                                        {
                                            this.state.loginUserData.data.is_admin_access && this.props.IndentDetails.status == "Indent Placed" ?
                                                <View>
                                                    <TouchableOpacity style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} disabled={!(this.state.loginUserData.data.is_admin_access) || !(this.props.IndentDetails.status == "Indent Placed")} onPress={() => {
                                                        this.setState({ SelectedDrug: item, BatchPopup: true, SelectedIndex: index })
                                                    }}>
                                                        <TextInput
                                                            disabled={true}
                                                            style={[styles.BatchTextInput, {
                                                                cursor: (this.state.loginUserData.data.is_admin_access && this.props.IndentDetails.status == "Indent Placed") ? 'text' : 'pointer',
                                                            }]}
                                                            value={
                                                                this.state.DrugRows[index] ? this.state.DrugRows[index].SelectedBatches ? this.state.DrugRows[index].SelectedBatches.map((item, index) => (item.quantity > 0 ? `${item.batch_no} (${item.quantity})` : "")) : "-" : "-"
                                                            }
                                                        ></TextInput>
                                                        <TextInput
                                                            disabled={true}
                                                            style={{ paddingLeft: '0.3vw', borderColor: (SelectedQuantity[index] == undefined || SelectedQuantity[index] == 0) ? "#CDD1D5" : SelectedQuantity[index] == RequestedQuantity[index] ? 'green' : 'red', color: SelectedQuantity[index] == undefined ? "black" : SelectedQuantity[index] == RequestedQuantity[index] ? 'green' : 'red', fontSize: '0.91vw', width: '25%', borderWidth: 1, borderRadius: '0.26vw', cursor: 'pointer' }}
                                                            value={this.CalculateTotal(index)}
                                                        ></TextInput>
                                                    </TouchableOpacity>
                                                    <View>
                                                        {this.state.BatchPopup && this.state.SelectedIndex == index ?
                                                            this.BatchSelectionPop(this.state.SelectedDrug, index)
                                                            : null}
                                                    </View>
                                                </View>
                                                :

                                                this.props.IndentDetails.status == "Approved" ?
                                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <TextInput
                                                            disabled={true}
                                                            style={[styles.BatchTextInput, { cursor: 'pointer' }]}
                                                            value=
                                                            {item.batch_details != null ? JSON.parse(item.batch_details.replaceAll(`'`, `"`)).map((list) => ` ${list.batch_no} (${list.quantity})`) : "-"}
                                                        />
                                                        <TextInput
                                                            disabled={true}
                                                            style={styles.ApproveTextBox}
                                                            value={item.quantity}
                                                        ></TextInput>
                                                    </View> :
                                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <TextInput
                                                            disabled={true}
                                                            style={[styles.BatchTextInput, { cursor: 'pointer' }]}
                                                            value="-"
                                                        />
                                                        <TextInput
                                                            disabled={true}
                                                            style={styles.ApproveTextBox}
                                                            value="-"
                                                        ></TextInput>
                                                    </View>
                                        }

                                    </View>
                                    <View style={{ alignItems: "center", width: '12%' }}><Text style={styles.TableDataText}>{item.dosage_strength}</Text></View>
                                    <View style={{ alignItems: "center", width: '12%' }}><Text style={styles.TableDataText}>{item.quantity}</Text></View>
                                </View>
                            )
                        })}
                        {this.state.OTIndentDrugListData.length === 0 ?
                            <View style={styles.NoRecordsView}>
                                <Text style={styles.NorecordText}>No Records Found</Text>
                            </View>
                            : null}
                    </ScrollView>
                </View>
                <View>
                    {
                        this.state.loginUserData.data.is_admin_access && this.props.IndentDetails.status == "Indent Placed" ?
                            <TouchableOpacity
                                onPress={() => {
                                    this.onPressActions(this.props.IndentDetails.indent_number, "Approved")
                                }}
                                style={styles.ApproveButton}>
                                <Text style={styles.ApproveButtonText}>Approve</Text>
                            </TouchableOpacity> : null
                    }
                </View>
                <View style={{ position: "absolute", width: "100%", flex: 1, top: '75vh' }}>
                    {
                        this.state.showResponse ?
                            <SuccessAlert message={this.state.responseMessage} /> : null
                    }
                    {
                        this.state.showErrorResponse ?
                            <ErrorAlert message={this.state.responseMessage} /> : null
                    }
                </View>
            </View>
        )
    }
}

export default OTIndentDetails

const styles = StyleSheet.create({
    TableContainer: {
        marginLeft: '1vw',
        marginRight: '1vw',
        marginTop: '0.5vw'
    },
    TableDataScroll: {
        height: '60vh'
    },
    TopView: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: "1.3vw",
        alignItems: 'center'
    },
    IndentText: {
        fontSize: '1vw',
    },
    IndentData: {
        fontSize: '1.2vw',
        color: 'rgb(4, 183, 177)'
    },
    TableDataText: {
        fontSize: '0.91vw'
    },
    TableTitleContainer: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#E2E2E2",
        height: "2vw",
        paddingHorizontal: ".5vw"
    },
    TableDataView: {
        justifyContent: 'center',
        maxHeight: '3.9vw',
        minHeight: '3.9vw',
        width: "100%"
    },
    TableDataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottomColor: "#888888",
        borderBottomWidth: "1px",
        borderRadius: 4,
        height: "2.9vw",
        alignItems: "center",
        paddingHorizontal: ".5vw",
    },
    NoRecordsView: {
        alignItems: "center",
        width: "100%",
        marginTop: "15%"
    },
    NorecordText: {
        fontSize: '0.97vw'
    },
    PaginationView: {
        marginHorizontal: '1.3vw'
    },
    SearchBoxView: {
        flexDirection: "row",
        marginRight: '1.2vw'
    },
    SearchBoxTxtInput: {
        borderWidth: '0.06vw',
        height: '2.28vw',
        width: '11.06vw',
        paddingHorizontal: '0.19vw',
        borderColor: color.lightGray,
        fontSize: '0.91vw',
    },
    SearchBtn: {
        height: '2.28vw',
        width: '2.28vw',
        backgroundColor: color.themeDark,
        justifyContent: "center",
        alignItems: "center"
    },
    BackBtn: {
        backgroundColor: color.themeBackgroud,
        borderRadius: '0.32vw',
        alignSelf: "center",
        paddingVertical: '0.32vw',
        paddingHorizontal: '0.52vw',
        marginRight: '4vw'
    },
    BackBtnImg: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    BackText: {
        color: "#ffffff",
        fontSize: '0.9vw'
    },
    BreadCrump: {
        marginLeft: '2vw'
    },
    IndentDetails: {
        display: 'flex',
        flexDirection: 'row',
    },
    ApproveButton: {
        backgroundColor: color.themeDark,
        height: '1.95vw',
        justifyContent: "center",
        alignSelf: "center",
        paddingHorizontal: '0.65vw',
        borderRadius: '0.26vw',
        marginTop: '2vw'
    },
    ApproveButtonText: {
        color: 'white',
        fontSize: '0.91vw'
    },
    PickerStyle: {
        height: "5vw",
        marginRight: '2vw',
        backgroundColor: "transparent",
        borderRadius: '0.26vw',
        flex: 0.5,
        borderColor: '#CDD1D5',
        width: '13vw',
        fontSize: '0.91vw',
        padding: '0.4vw',
        cursor: 'pointer'
    },
    BatchSelectPopUP: {
        borderRadius: 10,
        width: '100%',
        justifyContent: 'center',
        alignContent: 'center',
        padding: "0.65vw",
        position: 'absolute',
        backgroundColor: "white",
        maxHeight: '15vw',
        borderColor: 'black',
        borderWidth: 1,
    },
    BatchSelectPopUPWrapper: {
        display: 'flex', flexDirection: 'row', backgroundColor: '#E1F7F4', height: '1.5vw', borderRadius: '0.3vw', alignItems: 'center'
    },
    BatchScrollView: {
        width: '100%', display: 'flex', flexDirection: 'row', height: '2vw', borderBottomWidth: 1, borderBottomColor: '#E2E2E2', borderRadius: '0.3vw', alignItems: 'center',
    },
    NoListBatch: {
        fontSize: '0.7vw', alignSelf: 'center', justifyContent: 'center', margin: '1vw'
    },
    TableTitleView: {
        alignItems: "center", width: '12%'
    },
    BatchTextInput: {
        height: '2vw',
        padding: 6,
        marginRight: 15,
        borderColor: "#CDD1D5",
        borderRadius: 4,
        borderWidth: 1,
        fontSize: '0.91vw',
        color: 'black',
        width: '75%'
    },
    ApproveTextBox: {
        paddingLeft: '0.3vw', borderColor: "#CDD1D5", fontSize: '0.91vw', width: '25%', borderWidth: 1, borderRadius: '0.26vw', cursor: 'pointer'
    },
    EmptyText: {
        display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '0.9vw'
    }
})