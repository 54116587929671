import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent';
import Pagination from "../../common/Pagination"
import { color } from '../../styles/Color';

export default class consolidateSplitUps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consolidateData: [],
      selectedMonth: props.selectedMonth,

    };
    this.service = new Service();
    this._paginationChangedValues = this.paginationChangedValues.bind(this)

  }

  componentDidMount() {
    this._getConsolidateReport()
  }
  _getConsolidateReport() {
    var url = Constants.GET_CONSOLIDATED_REPORT + "?invoice_date=" + this.state.selectedMonth
    this.service.getApiCall(
      url,
      this._getConsolidateSuccess,
      this._getConsolidateFailure
    )

}
_getConsolidateSuccess = response => {
    if (response.status === "success") {
      this.setState({
        consolidateData: response.data.result

      })
      var data = {
        "profit_total": response.data.profit_total,
        "purchase_total": response.data.purchase_total,
        "sales_total": response.data.sales_total,
      }
      this.props.gettotalscoreValues("Consolidated Sales Splitups", data)

    }
  };
  renderPaginationView() {
    return (
      <View style={{ marginHorizontal: 20 }}>
        {
          this.state.consolidateData && this.state.consolidateData.length > 0 ?
            <Pagination
              paginationChangedValues={this.paginationChangedValues.bind(this)}
              totalClientItems={this.state.consolidateData}
            /> : null
        }
      </View>
    )
  }
  renderHeaderContent() {


    if (this.state.consolidatedDataAfterPagination && this.state.consolidatedDataAfterPagination.length > 0) {
      return (
        // Report List Views
        <View style={styles.TableContent}>
          {this.state.consolidatedDataAfterPagination && this.state.consolidatedDataAfterPagination.length > 0 && this.state.consolidatedDataAfterPagination.map((item, index) => {
            return (
              <View style={styles.TableContentView}>
                <View style={[{ flex: 0.2, marginLeft: 20 }]}><Text style={styles.TableContentText}>{index + 1}</Text></View>
                <View style={[{ flex: 0.2 }]}><Text style={styles.TableContentText}>{item.date}</Text></View>
                <View style={[{ flex: 0.07 }]}><Text style={[styles.TableContentText, { textAlign : "right" }]}>{parseFloat(item.purchase_amount).toFixed(2)}</Text></View>
                <View style={[{ flex: 0.175 }]}><Text style={[styles.TableContentText, { textAlign : "right" }]}>{parseFloat(item.sales_amount).toFixed(2)}</Text></View>
                <View style={[{ flex: 0.195 }]}><Text style={[{ textAlign : "right", color: item.profit_amount > 0 ? color.cardGreen : color.red }, styles.TableContentText]}>{parseFloat(item.profit_amount).toFixed(2)}</Text></View>

              </View>
            )

          })
          }
        </View>
      )
    } else {
      return (
        <View style={styles.NoRecordView}>
          <Text style={styles.NoRecordText}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }
  renderHeader() {
    return (

      <View style={styles.TableHead}>
        <CommonReportSectionHeader
          heading1={"S.NO"}
          heading2={"Date"}
          heading3={"Purchase Amount ( ₹ )"}
          heading4={"Sales Amount ( ₹ )"}
          heading5={"Profit Amount ( ₹ )"}
          columnSize={[0.2, 0.2, 0.2, 0.2, 0.2]}  //total value is == of 1
          noOfColumn={5}

        />
      </View>
    )
  }

  render() {
    return (
      <View>
        {this.renderHeader()}
        {this.renderHeaderContent()}
        {this.renderPaginationView()}
      </View>
    );
  }
  paginationChangedValues(data) {
    this.setState({
      consolidatedDataAfterPagination: data
    })
  }

}

const styles = StyleSheet.create({
      TableHead: { 
        marginHorizontal: '1.2vw', 
        marginTop: '.5vw' 
      },
      TableContent: { 
        marginHorizontal: '1.2vw', 
        backgroundColor: "#F9F9F9", 
        marginTop: '1vw' 
      },
      TableContentView: { 
        flexDirection: 'row', 
        borderBottomColor: "#888888", 
        borderBottomWidth: 1, 
        paddingVertical: '1.2vw', 
        marginHorizontal: 0 
      }, 
      TableContentText: {
        fontSize: '1vw'
      },
      NoRecordView: { 
        flex: 1, 
        flexDirection: "row", 
        justifyContent: "center", 
        alignContent: "center", 
        marginTop: '9.5vw' 
      },
      NoRecordText: { 
        color: "#8888888", 
        fontSize: '1vw' 
      }
})