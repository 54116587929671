import { API_V1_URL, API_V2_URL } from "@env"

export default class Constants{

    // static BASE_DOMAIN = API_V1_URL;
    // static DOMAIN_V2 = API_V2_URL;

    // static BASE_DOMAIN = 'https://beta.api.emedhub.in/pharmacy/'
    // static DOMAIN_V2='https://beta.api.emedhub.in/v2/pharmacy/'
    // static BASE_DOMAIN_SEARCH = 'https://beta.api.emedhub.in/'

    static BASE_DOMAIN = 'https://uat.v3.api.emedhub.in/pharmacy/'
    static DOMAIN_V2='https://uat.v3.api.emedhub.in/v2/pharmacy/'
    static BASE_DOMAIN_SEARCH = 'https://uat.v3.api.emedhub.in/'

    static ROLE_PHARMACIST = "pharmacy";

    static OVER_ALL_SEARCH = this.BASE_DOMAIN_SEARCH + "api/search/"
    
    // constants used for condtion checking
    static PAGINATION_TYPE_SERVER = "ServerSide"
    static PAGINATION_TYPE_CLIENT = "ClientSide" 
    static STOCK_PARTIAL_DELIVERED = "PARTIALLY DELIVERED" 
    static STOCK_FULLY_DELIVERED = "DELIVERED" 
    static STOCK_NOT_DELIVERED = "NOT DELIVERED" 

    // login api call (post)
    static LOG_IN = this.BASE_DOMAIN + "login/";

    // doctors
    static LIST_DOCTORS = this.BASE_DOMAIN + "billing/doctor/list/";

    // vendor
    static LIST_VENDOR = this.BASE_DOMAIN + "vendors/list/";
    static GET_VENDOR = this.BASE_DOMAIN + "vendor/";
    static SAVE_VENDOR = this.BASE_DOMAIN + "vendor/";
    static LIST_STATE_NAMES = this.BASE_DOMAIN + "states/";
    static VENDOR_LIST = this.BASE_DOMAIN + "vendor/search/"

    // PO
    static LIST_PO = this.BASE_DOMAIN + "purchase-orders/list/";
    static PURCHASE_ORDER_NO_GET = this.BASE_DOMAIN + "po-number/get/";
    static CREATE_PURCHASE_ORDER = this.BASE_DOMAIN + "purchase-order/";
    static WANTED_LIST = this.BASE_DOMAIN + "wanted/list/";
    static ORDER_LIST = this.BASE_DOMAIN + "order/list/";
    static ORDER_LIST_POPUP = this.BASE_DOMAIN + "product/list/order/";  // Confirm popup api
    static MOVE_ORDER_LIST = this.BASE_DOMAIN + "wanted/list/cart/";

    // Stocks
    static LIST_STOCK = this.BASE_DOMAIN + "stock-list/";
    static VIEW_STOCK = this.BASE_DOMAIN + "stock-details/"
    static STOCK_ENTRY_ITEMS = this.DOMAIN_V2 + "stock/entry/items/";
    static ADD_TO_STOCK = this.DOMAIN_V2 + "add/to/stock/"; 
    static MARK_RETURN = this.BASE_DOMAIN + "mark-return/"
    static GET_PREVIOUS_DRUG_DETAIL = this.BASE_DOMAIN + "get/previous-drug-details/"
    static STOCK_ADJUSTMENT = this.BASE_DOMAIN + "inventory/stock_adjustment/"
    static STOCK_ADJUSTMENT_HISTORY = this.BASE_DOMAIN + "inventory/stock_adjustment/history/"
    static STOCK_ADJUSTMENT_DELETE = this.BASE_DOMAIN + "inventory/stock_adjustment/"
    static STOCK_BATCH_SUGGESTION = this.BASE_DOMAIN + "batch/list/"

    //Expiry and lowStock count
    static EXPIRY_REORDER_COUNT = this.BASE_DOMAIN + "reorder/threshold/count/"

    // Direct 
    static DIRECT_PO = this.DOMAIN_V2 + "direct/po/"

    // Drugs
    static LIST_DOSAGE = this.BASE_DOMAIN + "dosage-types/";
    static SEARCH_DRUG = this.BASE_DOMAIN + "drugs/auto-complete/";
    static PO_DRUG_DETAILS_BY_DRUGNAME = this.BASE_DOMAIN + "drug-details/";
    static DIRECT_PO_DRUG_DETAILS_BY_DRUGNAME = this.BASE_DOMAIN + "po/drugs/search/";
    static ACTIVE_DRUG_LIST = this.BASE_DOMAIN + "new/druglist/";
    static INACTIVATE_ALL_DRUGS = this.BASE_DOMAIN + "new/druglist/status/";
    //Referred Doctor
    static REFERRED_DOCTOR = this.BASE_DOMAIN + "referred/doctor/"
    
    // Billing
    static BILL_DRUG_SEARCH = this.BASE_DOMAIN + "drugs/search/"
    static PHARMACY_BILLING = this.BASE_DOMAIN + "billing/"
    static BILL_LISTING = this.DOMAIN_V2 + "invoice/list/"
    static BILL_VIEW = this.BASE_DOMAIN + "billing/"
    static BILL_NEW_INVOICE_NO =  this.BASE_DOMAIN + "billing/invoice-no/"
    static BILL_DRUG_DETAILS_BY_DRUGNAME = this.DOMAIN_V2 + "dosage/detail/"
    static BILL_DRUG_DETAILS_BY_DRUGID = this.DOMAIN_V2 + "billing/drug/details/"
    static BILL_COMPLETE_BILLING = this.DOMAIN_V2 + "complete/billing/"
    static BILL_PARTIAL_BILLING = this.DOMAIN_V2 + "partial/billing/" 
    static BILL_RETURN_BILLING = this.BASE_DOMAIN + "billing/return/" 
    static PARTIALLY_SAVED_BILL = this.DOMAIN_V2 + "partially/saved/bill/"
    static CREDIT_BILL_SAVE = this.DOMAIN_V2 + "credit/billing/";

    // menu data list
    static BILL_PARTIAL_SAVED_LIST = this.DOMAIN_V2 + "partially/saved/bills/list/"
    static TRANSMITTED_PRESCRIPTION_LIST = this.DOMAIN_V2 + "transmitted/prescription/list/" 

    // invoice print 
    static GET_INVOICE_REPORT = this.DOMAIN_V2 + "invoice/list/"

    // Reports API
    static GET_SALES_OVERVIEW = this.BASE_DOMAIN +"reports/sales/overview/"
    static GET_PURCHASE_OVERVIEW = this.BASE_DOMAIN+"reports/purchase/overview/"
    static GET_DAYEND_REPORT = this.BASE_DOMAIN+"reports/sales/day-end/"
    static GET_SALES_DETAILED = this.BASE_DOMAIN+"reports/sales/detailed/"
    static GET_SALES_GST = this.BASE_DOMAIN+"reports/sales/gst/"
    static GET_SALES_RETURN = this.BASE_DOMAIN+"reports/sales-return/"
    static GET_SALES_MONTH = this.BASE_DOMAIN+"reports/sales/monthly/"
    static GET_SALES_GST_SPLITUPS = this.BASE_DOMAIN+"sales-gst/splitup/"
    static REFERRED_DOCTOR_REPORT = this.BASE_DOMAIN + "referred/doctor/bill/"
    static PATIENT_REPORT = this.BASE_DOMAIN + "patient/report/"

    static GET_STOCK_OVERVIEW = this.BASE_DOMAIN+"reports/stock/"
    static GET_PURCHASE_DETAILED = this.BASE_DOMAIN+"reports/purchase/detailed"
    static GET_MIS_REPORT = this.BASE_DOMAIN+"reports/mis-report/"
    static GET_PURCHASE_GST = this.BASE_DOMAIN+"reports/purchase-gst/"
    static GET_PURCHASE_MONTHLY = this.BASE_DOMAIN+"reports/purchase/monthly/"
    static GET_PURCHASE_RETURN = this.BASE_DOMAIN+"reports/purchase-return/"
    static GET_STOCKS_REPORT = this.BASE_DOMAIN+"reports/stock/"
    static GET_MIS_VENDOR_REPORT = this.BASE_DOMAIN+"reports/mis-report/vendors/"

    static GET_REORDER_THRESHOLD_REPORT = this.BASE_DOMAIN+ "reorder/threshold/"
    static GET_EXPIRY_DRUG_REPORT = this.BASE_DOMAIN+ "reports/drug-expiry/"

    static GET_CONSOLIDATED_REPORT = this.BASE_DOMAIN + "consolidate-report/"
    static CONSOLIDATED_REPORT_PRINT = this.BASE_DOMAIN + "consolidate-report/"

    static GET_SALES_DETAILED_SEARCH = this.BASE_DOMAIN + "reports/sales/detailed/"
    static GET_PURCHASE_DETAILED_SEARCH = this.BASE_DOMAIN + "reports/purchase/detailed/"

    //stock movement
    static GET_SLOWMOVEMENT_DRUG_REPORT = this.BASE_DOMAIN+ "slow-movement/"
    static GET_NOMOVEMENT_DRUG_REPORT = this.BASE_DOMAIN+ "no-movements/"

    static CONFIG_UPDATE = this.BASE_DOMAIN + "config/"
    
    // Appointment based
    static GET_APPOINTMENT_LIST = this.BASE_DOMAIN+"appointments/list/"
    static GET_APPOINTMENT_PATIENT_DETAILS = this.BASE_DOMAIN+"appointments/details/"

    // StandAlone patient search
    static GET_PATIENT_LIST = this.BASE_DOMAIN+"search/patient/"
    // Prescription
    static GET_TRANSMITTED_PRESCRIPTION = this.BASE_DOMAIN+"prescription/"

    // RegExpressions  
    // /\d{0,2}(\.\d{1,2})?$/ ======= /^[\d ]+(.\d{0,2})?$/
    static REG_EX_DECIMALS = /^[\d]+(\.\d{0,2})?$/ 
    static REG_EX_NUMBERS = /^\d*$/ 
    static REG_EX_MOBILENO = /^\d{10}$/

    // Vendor payment
    static GET_VENDOR_PAYMENT = this.BASE_DOMAIN + "vendor/payment/transaction/"
    static GET_VENDOR_MONTHWISE = this.BASE_DOMAIN + "vendor/payment/transaction/month/"
    static VENDOR_PAYMENT_SAVE = this.BASE_DOMAIN + "vendor/payment/"
    static GET_VENDOR_HISTORY = this.BASE_DOMAIN + "vendor/payment/"

    // Credit Bill
    static GET_CREDIT_PATIENT_LIST = this.DOMAIN_V2 + "credit/billing/transaction/"
    static GET_CREDIT_BILL_LIST = this.DOMAIN_V2 + "credit/billing/"

    // Drug Registry
    static DRUG_DETAILS_POST = this.BASE_DOMAIN + "drug/registry/"

    // Date Restriction
    static remove_Obj_user = ["all","last30days", "last90days", "last12Months", "custom"];
    // OT Indent Request 
    static OT_INDENT_REQUEST_LIST = this.BASE_DOMAIN + "indent/listing/"
    static OT_INDENT_DETAILED_LIST = this.BASE_DOMAIN + "purchase/indent/"
    static INDENT_STATUS_POST = this.BASE_DOMAIN + "purchase/indent/"
    // Date Restriction
    static remove_Obj_user = ["all","last30days", "last90days", "last12Months", "custom"];
    
    static RESET_PASSWORD = this.BASE_DOMAIN_SEARCH + "auth/password/reset/"
}