import React, { Component } from 'react';
import { View } from 'react-native';

export default class BaseComponent extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <View />
    }

    _sortListData (list, searchText) {

        let tempList = [];
        let filterText = searchText.toUpperCase();

        if(list.length>0 && filterText && filterText.length > 0)
        {
            // validate & return
            for (let i = 0; i < list.length; i++) {
                
                if(list[i] && list[i]['name'])
                {
                    if (list[i]['name'].toUpperCase().indexOf(filterText) > -1) {
                    
                        tempList.push(list[i])
                    }
                } else if (list[i]) 
                {
                    if (list[i].toUpperCase().indexOf(filterText) > -1) {
                    
                        tempList.push(list[i])
                    }
                }
            }
        } else {
            // return the same data
            tempList = list
        }

        return tempList; 
    } 

    _setDataFormat=(data, desiredFormat="string")=>{

        let inString = data;

        if(desiredFormat==="int" || desiredFormat==="integer"){

            inString = parseInt(inString) || 0
        }
        if(desiredFormat==="float" || desiredFormat==="decimal"){

            let parsedVal = parseFloat(inString) || 0;
            let formattedVal = parsedVal.toFixed(2);
            // assign
            inString = parseFloat(formattedVal);
        }
        if(desiredFormat==="date"){

            let date = inString;
            if(date){

                inString = date?moment(date).format('YYYY-MM-DD'):null
            }else{
                inString=""
            }
        }

        return inString;
    }

    responseSuccessErrorMessage(message, type) {

        this.setState({
            showResponse: message?type:false,
            showErrorResponse: message?!type:false,
            responseMessage: message,
            isclear: false
        })
    }
}