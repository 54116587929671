import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity,StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';
import { Tooltip } from "antd";

var PAGE_STOCKMOVEMENT = "";
let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export default class StockMovements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockMovementList: [],
      startTime: "",
      endTime: "",
      stockMovementListAfterPagination: [],
      // for pagination
      current_page: 1,
      records_per_page: 10,
      drugNameSearch: "",
      fillterDate: []
    };
    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 

  }

  componentDidMount() {
    if(this.props.typeofReport === "slowmovementdrugs"){
        PAGE_STOCKMOVEMENT = "Slow Movement Drugs"
      } else if(this.props.typeofReport === "nomovementdrugs"){
        PAGE_STOCKMOVEMENT = "No Movement Drugs"
      }
    this._getStockMovementList()
  }

  componentWillReceiveProps(props) {

    if ((props.startTime && props.endTime && props.isclear) || (props.isclear && props.drugNameSearch) || (props.fillterDate && props.isclear))  {
      this.setState({
        startTime: props.startTime,
        endTime: props.endTime,
        current_page: 1,
        records_per_page: 10,
        drugNameSearch: props.drugNameSearch,
        fillterDate: props.fillterDate
      }, () => {
        this._getStockMovementList()
        props.handlepropsvalueclear(false)
      })
    } 
  }

  // Get Api 
  _getStockMovementList = () => {

    let url = ""
    let fromDate =this.state.fillterDate[0] ?  moment(this.state.fillterDate[0]).format('YYYY-MM-DD') : converted_date
    let toDate = this.state.fillterDate[1] ?  moment(this.state.fillterDate[1]).format('YYYY-MM-DD') : converted_date

    if(this.state.drugNameSearch != "") {
      this.setState({
        stockMovementList: [],
        stockMovementListAfterPagination:[],
        current_page: 1,
        records_per_page: 10
      })
    }

    if(this.props.typeofReport === "slowmovementdrugs"){

        url = Constants.GET_SLOWMOVEMENT_DRUG_REPORT + "?brand_name=" + this.state.drugNameSearch + "&from_date=" + fromDate + "&to_date=" + toDate

    } else if(this.props.typeofReport === "nomovementdrugs"){

        url = Constants.GET_NOMOVEMENT_DRUG_REPORT + "?brand_name=" + this.state.drugNameSearch + "&from_date=" + fromDate + "&to_date=" + toDate

      }
    this.service.getApiCall(
      url,
      this.__getStockMovementListSuccess,
      this.__getStockMovementListFailure
    )

  }

  __getStockMovementListSuccess = response => {

    let { stockMovementList } = this.state;

    if (response.status === "success") {
      stockMovementList = response.data

      this.setState({
        stockMovementList,
        current_page: 1,
      }, () => {

        this.props.gettotalscoreValues(PAGE_STOCKMOVEMENT, "")

      })
    }else{
      this.props.gettotalscoreValues(PAGE_STOCKMOVEMENT, "")

    }
  };

  __getStockMovementListFailure = error => {
    this.props.gettotalscoreValues(PAGE_STOCKMOVEMENT, "")

    console.log(error);

  };

  renderHeader() {
    return (

      <View style={styles.renderHeader}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Brand Name"}
          heading3={"Dosage Type"}
          heading4={"Dosage Strength"}
          heading5={"Batch No"}
          heading6={"Last Billed Date"}
          heading7={"Age (Days)"}
          columnSize={[0.08, 0.18, 0.16, 0.16, 0.14, 0.14, 0.14]}  //total value is == of 1
          noOfColumn={7}

        />
      </View>
    )
  }
  _paginationChangedValues(data) {
    this.setState({
      stockMovementListAfterPagination: data
    })
  }
  paginationCurrentPage = (current_page, records_per_page) => {
   this.setState({
    current_page: current_page,
    records_per_page: records_per_page
   })
  }
  render() {

    
    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}
        {
          this.state.stockMovementList && this.state.stockMovementList.length > 0 ?
          <View style={{ marginLeft: "1.30vw" }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
              totalClientItems={this.state.stockMovementList}
              paginationCurrentPage = { this.paginationCurrentPage.bind(this) }
            />
          </View> : null
        }
      </View>
    );
  }
  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state
    if(this.state.stockMovementList && this.state.stockMovementList.length > 0) {
      return (
        // Report List Views
        <View style={styles.bodyContainer}>   
          {this.state.stockMovementListAfterPagination && this.state.stockMovementListAfterPagination.length > 0 && this.state.stockMovementListAfterPagination.map((item, index) => {
              let last_bill_date = item.last_bill_date ? item.last_bill_date : "Not yet billed" 
              let sno = (parseInt(current_page)-1)*parseInt(records_per_page)
              let Age = item.age ? item.age == "" ? "Nill": item.age : "Nill"
              return (
                <View key={index} style={styles.bodyWrapper}>
                  <View style={[{ flex: 0.08, marginLeft: "1.30vw" }]}><Text style={styles.BodyLabel}>{sno + (index+1)}</Text></View>
                  <View style={[{ flex: 0.18 }]}>
                     <Tooltip title={item.drug_name && (item.drug_name).length > 20 ? item.drug_name : null}>
                         <Text style={styles.BodyLabel}>{item.drug_name && (item.drug_name).length > 20 ? (item.drug_name).slice(0,18) + "..." : item.drug_name}</Text>
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.16 }]}><Text style={styles.BodyLabel}>{item.drug_type}</Text></View>
                  <View style={[{ flex: 0.16 }]}><Text style={styles.BodyLabel}>{item.drug_strength}</Text></View>
                  <View style={[{ flex: 0.14 }]}><Text style={styles.BodyLabel}>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.14 }]}><Text style={styles.BodyLabel}>{last_bill_date}</Text></View>
                  <View style={[{ flex: 0.14 }]}><Text style={styles.BodyLabel}>{Age}</Text></View>
                </View>
              )

            })
          }
        </View>
      )
    }else{
      return(
        <View style={styles.NorecordDiv}>
          <Text style={styles.Norecordtext}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }

}
const styles = StyleSheet.create({
  BodyLabel:{
    fontSize:'1vw'
  },
  renderHeader:{ 
    marginHorizontal: "1.30vw", 
    marginTop: ".65vw" 
  },
  bodyContainer:{ 
    marginHorizontal: "1.30vw", 
    backgroundColor: "#F9F9F9", 
    marginTop: "1vw" 
  },
  bodyWrapper:{ 
    flexDirection: 'row', 
    borderBottomColor: "#888888", 
    borderBottomWidth: ".1vw", 
    paddingVertical: "1.30vw", 
    marginHorizontal: 0 
  },
  NorecordDiv:{ 
    flex: 1, 
    flexDirection: "row", 
    justifyContent: "center", 
    alignContent: "center", 
    marginTop: "9.8vw" 
  },
  Norecordtext:{ 
    color: "#8888888", 
    fontSize:'1vw' 
  }
});
