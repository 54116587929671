import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet } from 'react-native';
import { color } from '../styles/Color';
import AsyncStorage from '../AsyncStorage';
import Styles from '../styles/Style';
import EyeIcon from "react-native-vector-icons/MaterialCommunityIcons";
import Constants from '../networks/Constants';
import Service from '../networks/service';

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      newPassword: '',
      cnfpassword: '',
      showConfirmPassword: false,
      showNewPassword: false,
      isValid: true,
      isValidConform: true
    };
    this.service = new Service();
  }

  async componentDidMount() {
    var user_name = await AsyncStorage.getItem("user_name");
    this.setState({
      userName: user_name
    })
  }

  // Post New Password API start
  postNewpassword = () => {
    var serviceurl = Constants.RESET_PASSWORD
    var data = {
      "username": this.state.userName,
      "password": this.state.newPassword,
      "confirm_password": this.state.cnfpassword
    }
    this.service.postApiCall(
      serviceurl,
      data,
      this.postNewpasswordSuccess,
      this.postNewpasswordFailure

    )
  }
  postNewpasswordSuccess = (response) => {
    if (response && response.status == "success") {
      this.props.showResetResposeValue("success", response.message)
      setTimeout(() => {
        this.props.logoutFunction()
      }, 2000);
    } else {
      this.props.showResetResposeValue("error", response.message)
    }
  }
  postNewpasswordFailure = (error) => {
    this.props.showResetResposeValue("error", error.message)
  }
  // Post New Password API end

  // Textbox function start
  renderTextbox = (label, key, value, showEyeFlagKey, showEyeFlag) => {
    return (
      <View style={{
        flexDirection: 'row', borderWidth: 0.1,
        borderColor: (this.state.isValid && key === "newPassword") || (this.state.isValidConform && key === "cnfpassword") ? "#888888" : color.red,
        borderRadius: 5,
        width: '20vw'
      }}>
        <TextInput
          secureTextEntry={(showEyeFlag && showEyeFlagKey == "showConfirmPassword") ||
            (showEyeFlag && showEyeFlagKey == "showNewPassword") ? false : true}
          onChangeText={(text) => {
            var states = this.state
            var isValid = false
            states[key] = (text).trim()
            this.setState({
              states
            }, () => {
              if (key == "newPassword" && text && text.length > 0) {
                isValid = this.validateTextbox(text)
                this.setState({ isValid: isValid })
              } else {
                if (this.state.newPassword === this.state.cnfpassword) {
                  this.setState({
                    isValidConform: true
                  })
                } else {
                  this.setState({
                    isValidConform: false
                  })
                }
              }
            })
          }}
          value={value}
          placeholder={label}
          style={[styles.TextInputView]}
        />
        <View style={{ justifyContent: 'center', width: "3vw", height: '3vw' }}>
          <TouchableOpacity onPress={() => {
            var states = this.state;
            states[showEyeFlagKey] = !showEyeFlag;


            this.setState({
              states
            })
          }}>
            <EyeIcon name={
              (showEyeFlag && showEyeFlagKey == "showConfirmPassword") ||
                (showEyeFlag && showEyeFlagKey == "showNewPassword")


                ? "eye-outline" : "eye-off-outline"} size={'1.5vw'} style={Styles.rememberMe_User_Password_Icon} />
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  // Textbox function end

  // Textbox Validation function start
  validateTextbox = (text) => {
    return /^(?=.*?[0-9])(?=.*?[a-zA-Z])(?=.*?[!@#$%^&*()]).{3,30}$/.test(text);
  }
  // Textbox Validation function end

  // Clear function start
  clear = () => {
    this.setState({
      newPassword: '',
      cnfpassword: '',
      isValid: false,
      isValidConform: false
    })
  }
  // Clear function end

  render() {
    console.log(this.state.userName, "New username")
    return (
      <View style={styles.ResetPasswordView}>
        <View style={styles.ContentView}>
          <View>
            <Text style={styles.ResetPasswordHeadText}>Reset Your Password</Text>
          </View>
          <View style={{ marginTop: '0.5vw' }}>
            <Text style={styles.UserNameText}>User Name: {this.state.userName}</Text>
          </View>
          <View style={{ marginTop: '1vw' }}>
            {this.renderTextbox("New Password", "newPassword", this.state.newPassword, "showNewPassword", this.state.showNewPassword)}
          </View>
          <View style={{ marginTop: '1vw' }}>
            {this.renderTextbox("ReEnter New Password", "cnfpassword", this.state.cnfpassword, "showConfirmPassword", this.state.showConfirmPassword)}
          </View>
          <View style={{ marginTop: '1vw', width: '20vw' }}>
            <Text style={styles.HelperText3}>{"Uses more than 6 characters with Commbination of Alphabets, Numbers, and Special Characters."}</Text>
          </View>
          <View style={styles.BtnView}>
            <View style={{ marginTop: '1vw' }}>
              <TouchableOpacity style={styles.SaveBtnView} onPress={() => { this.postNewpassword() }}>
                <Text style={styles.SaveBtnText}>Save</Text>
              </TouchableOpacity>
            </View>
            <View style={{ marginTop: '1vw', marginLeft: '1vw' }}>
              <TouchableOpacity style={styles.ClearBtnView} onPress={this.clear}>
                <Text style={styles.ClearBtnText}>Clear</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  TextInputView: {
    width: "20vw",
    height: '3vw',
    paddingLeft: "0.5vw",
    fontSize: "1vw",
    color: 'black'
  },
  ResetPasswordView: {
    backgroundColor: color.white,
    width: '25vw',
    height: '50vh',
    borderRadius: '1vw'
  },
  ContentView: {
    margin: '2vw',
  },
  HelperText1: {
    color: color.white,
    fontSize: '1vw',
    marginLeft: '0.5vw'
  },
  ResetPasswordHeadText: {
    fontSize: '2vw',
    fontWeight: '700'
  },
  HelperText2: {
    color: '#888888',
    fontSize: '0.8vw'
  },
  UserNameText: {
    fontSize: '1vw',
    fontWeight: '600'
  },
  HelperText3: {
    fontSize: '0.8vw',
    color: '#888888'
  },
  BtnView: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '20vw'
  },
  SaveBtnView: {
    backgroundColor: color.themeDark,
    width: '6vw',
    height: '2.2vw',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center'
  },
  SaveBtnText: {
    color: color.white,
    fontSize: '1vw',
    alignItems: 'center'
  },
  ClearBtnView: {
    width: '6vw',
    height: '2.2vw',
    borderRadius: 5,
    borderColor: color.themeDark,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  ClearBtnText: {
    color: color.themeDark,
    fontSize: '1vw'
  }
})