import React, { Component } from "react";
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, ScrollView, TextInput } from "react-native";
import { MenuBreadCrumpList, BillTableTitle, BillTableData, CommonLabelView, CommonLabelTextView, SuccessAlert, ErrorAlert } from "../../common/BaseFunctionComponent";
import Icon from "react-native-vector-icons/FontAwesome";
import { color } from '../../styles/Color';
import Constants from "../../networks/Constants";
import Service from '../../networks/service';
import Pagination from "../../common/Pagination";
import moment from "moment";


const screenHeight = Dimensions.get("window").height;

export default class ConfirmOrderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: this.props.menu,
            selectedScreen: this.props.selectedScreen ? this.props.selectedScreen : "confirmOrderList",
            orderList: [],
            orderListAfterPagination: [],
            isClicked: false,
            isTrashClicked: false,
            Vendor_id: null,
            Vendor_name: "",
            po_number: null,
            po_date: new Date(),
            vendorList: [],
            current_page: 0,
            records_per_page: 10,
            showResponse: false,
            showErrorResponse: false,
            responseMessage: "",
        }
        this.service = new Service()
        this._paginationChangedValues = this.paginationChangedValues.bind(this)
    }
    componentDidMount() {
        this._getOrderList();
        // this._getPOnumber();
    }

    _getOrderList = () => {
        this.setState({
            orderList: [],
            orderListAfterPagination: [],
            current_page: 0,
            records_per_page: 10
        })
        let url = Constants.ORDER_LIST;
        this.service.getApiCall(
            url,
            this.successOnGet,
            this.errorOnGet
        )
    }
    successOnGet = (response) => {
        if (response.status === "Success") {
            this.setState({
                orderList: response.data
            })
        }
    }

    errorOnGet = (error) => {
        if (response.status === "fail") {
            this.setState({
                showErrorResponse: true,
                responseMessage: error.message,
                orderList: []
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            }
            )
        }
    }

    // vendor vice get api
    _confirmPopupGet = (vendor_id) => {
        if (vendor_id) {
            let url = Constants.ORDER_LIST_POPUP + "?vendor_id=" + vendor_id
            this.service.getApiCall(
                url,
                this.successOnGetpopup,
                this.errorOnGetpopup
            )
        }
    }

    successOnGetpopup = (response) => {
        if (response.status === "sucess") {
            this.setState({
                vendorList: response.data
            })
        }
    }

    errorOnGetpopup = (error) => {
        if (error.status === "fail") {
            this.setState({
                showErrorResponse: true,
                responseMessage: error.message,
                vendorList: []
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            }
            )
        }
    }

    //popup delete api 
    _confirmPopupDelete = (order_id) => {
        if (order_id) {
            let url = Constants.ORDER_LIST_POPUP + "?order_id=" + order_id
            var data = {
                "order_id": order_id
            }
            this.service.deleteApiCall(
                url,
                data,
                this.successOnpopupDelete,
                this.errorOnpopupDelete
            )
        }
    }

    successOnpopupDelete = (response) => {
        if (response.status == 'sucess') {
            this.setState({
                isTrashClicked: false,
                showResponse: true,
                responseMessage: response.message
            }, () => {
                this._confirmPopupGet(this.state.Vendor_id)
                this._getOrderList();
                setTimeout(() => {
                    this.setState({
                        showResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            })
        } else {
            this.setState({
                isTrashClicked: false,
                showErrorResponse: true,
                responseMessage: response.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            })
        }
    }
    errorOnpopupDelete = (error) => {
        if (error.status == 'fail') {
            this.setState({
                isTrashClicked: false,
                showErrorResponse: true,
                responseMessage: error.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            })
        }
    }
    //po number get api
    _getPOnumber() {
        let url = Constants.PURCHASE_ORDER_NO_GET;
        this.service.getApiCall(
            url,
            this._getPOnoSuccess,
            this._getPOnoError
        )
    }

    _getPOnoSuccess = (response) => {
        if (response.status === "success") {
            let data = JSON.parse(JSON.stringify(response.data));
            this.setState({ po_number: data ? data.po_number : null }, () => {
                this._confirmPopupPost()
            });
        }
    };

    _getPOnoError = (error) => { }


    //popup post api

    _confirmPopupPost = () => {
        let { vendorList, po_number, po_date, Vendor_id } = this.state;
        let url = Constants.CREATE_PURCHASE_ORDER;
        let data = {
            vendor_id: Vendor_id,
            purchase_order_number: po_number,
            purchase_order_date: moment(po_date).format('YYYY-MM-DD'),
            line_items: []
        };
        vendorList.forEach((item, index) => {
            if (item.drug_name && item.generic_name && item.dosagetype && item.drug_strength) {
                let tempDrug = {
                    "serial_no": index + 1,
                    "brand_name": item.drug_name,
                    "generic_name": item.generic_name,
                    "dosage_type": item.dosagetype,
                    "dosage_strength": item.drug_strength,
                    "order_quantity": item.order_quantity,
                    "free_quantity": item.free_quantity ? item.free_quantity : 0,
                    "discount_percentage": item.discount_percentage ? item.discount_percentage : 0,
                    "buying_price": item.buying_price ? item.buying_price :0 ,
                    "estimated_epr": item.estimated_epr ? item.estimated_epr : 0,
                    "order_id": item.order_id
                }
                // push data
                data['line_items'].push(tempDrug)
            }
        })

        if (data['line_items'].length > 0) {
            this.service.postApiCall(
                url,
                data,
                this.successOnPostPopup,
                this.errorOnPostPopup
            )
        }
    }

    successOnPostPopup = (response) => {
        if (response.status === "success") {
            this.setState({
                isClicked: false,
                Vendor_name: "",
                Vendor_id: null,
                vendorList: [],
                showResponse: true,
                responseMessage: response.message,
            }, () => {
                this._getOrderList();
                setTimeout(() => {
                    this.setState({
                        showResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            }
            )
        }
    }

    errorOnPostPopup = (error) => {
        if (error.status === "fail") {
            this.setState({
                showErrorResponse: true,
                responseMessage: error.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showSuccessResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            })
        }
    }

    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }
    renderPaginationView() {
        return (
            <View style={{ marginHorizontal: "1.25vw", zIndex: 0 }}>
                {
                    this.state.orderList && this.state.orderList.length > 0 ?
                        <Pagination
                            paginationChangedValues={this.paginationChangedValues.bind(this)}
                            totalClientItems={this.state.orderList}
                            paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                        /> : null
                }
            </View>
        )
    }

    paginationChangedValues(data) {
        this.setState({
            orderListAfterPagination: data
        })
    }

    render() {
        let { orderListAfterPagination, vendorList, isClicked, showResponse, showErrorResponse, responseMessage } = this.state
        return (
            <View style={{ height:screenHeight - 120 }}>

                {/* <MenuBreadCrumpList menu={this.state.menu}
                    selectedScreen={this.state.selectedScreen}
                    changeScreen={this.props.changeScreen}
                    generateReport={this._generateReport}
                // isDisable={!resportActions}
                /> */}
                <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginBottom: "0.3vw", paddingHorizontal: "1.25vw" }}>
                    <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
                        <TouchableOpacity
                            style={[styles.ButtonStyle, { marginRight: " 0.625vw" }]}
                            onPress={() => this.props.NavigateScreen("wantedList")}
                        >
                            <Text style={styles.ButtonText}>Back</Text>
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={{ marginRight: " 0.625vw" }}
                            onPress={() => this.props.NavigateScreen("orderList")}
                        >
                            <Text style={styles.TitleText}>Order List</Text>
                        </TouchableOpacity>
                        <Text style={styles.TitleText} >/</Text>
                        <TouchableOpacity
                            style={{ marginRight: " 0.625vw" }}
                            onPress={
                                () => this.props.NavigateScreen("confirmOrderList")
                            }
                        >
                            <Text style={[styles.TitleText,{color: this.state.selectedScreen === "confirmOrderList" ? color.themeDark : color.black}]}>Confirm OrderList</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                {/* <View style={{ flexDirection: "row", margin: 15, height: "1vw", padding: "3px", justifyContent: "flex-end" }}>
                    <TouchableOpacity
                        style={styles.ButtonStyle}
                        >
                        <Text style={styles.ButtonText}> Back to Product List </Text>
                    </TouchableOpacity>
                </View> */}
                <View style={{ maxHeight: screenHeight - 65, zIndex: 0 }}>
                    <View style={{ flexDirection: "column", justifyContent: "flex-start", alignContent: "center", margin: "0.5vw" }}>
                        <View style={{
                            flex: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: "center",
                            backgroundColor: "#E2E2E2", padding: "0.85vw", borderRadius: "0.3vw"
                        }}>
                            <BillTableTitle label={'S.No'} flexWidth={0.1} />
                            <BillTableTitle label={'Vendor Name'} flexWidth={0.25} />
                            <BillTableTitle label={'No.Of.Product'} flexWidth={0.25} />
                            <BillTableTitle label={'Est.Value'} flexWidth={0.25} />
                            <BillTableTitle label={'Action'} flexWidth={0.15} />
                        </View>
                        <ScrollView style={{ maxHeight: "60vh" }}>
                            {orderListAfterPagination && orderListAfterPagination.length > 0 ? orderListAfterPagination.map((item, index) => {
                                let sno = index + 1;
                                return (
                                    <View key={index} style={{
                                        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignitems: "center",
                                        backgroundColor: "#F9F9F9", padding: "0.35vw", borderRadius: "0.3vw", marginTop: "0.4vw",
                                        borderBottomWidth: 1, borderColor: "#E2E2E2"
                                    }}>
                                        <BillTableData label={sno} labelType={'label'} flexWidth={0.1} />
                                        <BillTableData label={item.vendor_name} labelType={''} flexWidth={0.25} />
                                        <BillTableData label={item.no_of_product} labelType={''} flexWidth={0.25} />
                                        <BillTableData label={item.estimated_prize} labelType={''} flexWidth={0.25} />
                                        <View style={{ flex: 0.15, alignSelf: "center" }}>
                                            <TouchableOpacity style={[styles.ButtonStyle,{ width:"8vw" }]}
                                                onPress={() => {
                                                    let states = this.state;
                                                    states["isClicked"] = true;
                                                    states["Vendor_id"] = item.vendor_id;
                                                    states["Vendor_name"] = item.vendor_name;
                                                    this.setState({
                                                        states
                                                    }, () => {
                                                        this._confirmPopupGet(this.state.Vendor_id)
                                                    }
                                                    )
                                                }}>
                                                <Text style={styles.ButtonText}>Place Order</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )
                            }) :  
                                <View style={{
                                 flexDirection: "row", justifyContent: "center", alignItems: "center",
                                    backgroundColor: "#F9F9F9", padding: "0.6vw", marginTop: "0.5vw", height: "65vh"
                                }}>
                                    <Text style={{ fontSize: "1vw",fontWeight: 500 }}>No Records Found</Text>

                                </View>
                            }
                        </ScrollView>
                    </View>
                </View>
                {this.renderPaginationView()}
                <View style={{zIndex: 2}}>
                    {
                        showResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }
                </View>

                {/* popup window*/}
                {
                    isClicked && vendorList && vendorList.length > 0 ?
                        <View style={{ zIndex:1, height: '100%', position: 'absolute', width: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center" }}>
                            <View style={{ paddingHorizontal: 30, paddingVertical: 20, backgroundColor: color.white, width: "90%", height: "90%", borderRadius: "0.6vw", borderWidth: 1, }}>
                                <View style={{ flexDirection: "column", justifyContent: "flex-start", alignContent: "center", margin: "0.9vw" }}>
                                    <View style={{ flexDirection: "row", justifyContent: "flex-start", marginBottom: "0.7vw" }}>
                                        <CommonLabelView label={"Vendor Name :"} />
                                        <CommonLabelTextView label={this.state.Vendor_name} />
                                    </View>
                                    <View style={{
                                        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: "center",
                                        backgroundColor: "#E2E2E2", padding: "0.8vw", borderRadius: "0.3vw"
                                    }}>
                                        <BillTableTitle label={'S.No'} flexWidth={0.1} />
                                        <BillTableTitle label={'Brand Name'} flexWidth={0.22} />
                                        <BillTableTitle label={'Generic / Combo'} flexWidth={0.20} />
                                        <BillTableTitle label={'Dosage Type / Strength'} flexWidth={0.24} />
                                        <BillTableTitle label={'Purchase Quantity'} flexWidth={0.14} />
                                        <BillTableTitle label={'Action'} flexWidth={0.10} />
                                    </View>
                                    <ScrollView style={{ maxHeight: "25vw" }}>
                                        {
                                            this.state.vendorList && this.state.vendorList.length > 0 ? this.state.vendorList.map((item, index) => {
                                                let sno = index + 1;
                                                return (

                                                    <View key={index} style={{
                                                        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
                                                        backgroundColor: "#F9F9F9", padding: "0.5vw", borderRadius: "0.3vw", marginTop: "0.5vw",
                                                        borderBottomWidth: 1, borderColor: "#E2E2E2"
                                                    }}>
                                                        <BillTableData label={sno} labelType={'label'} flexWidth={0.1} />
                                                        <BillTableData label={item.drug_name} labelType={''} flexWidth={0.22} />
                                                        <BillTableData label={item.generic_name} labelType={''} flexWidth={0.20} />
                                                        <View style={{ flex: 0.24 }}>
                                                            <Text style={{fontSize: '1vw'}}>{item.dosagetype ? item.dosagetype : ""}{item.drug_strength ? "/" : ""}{item.drug_strength ? item.drug_strength : ""}</Text>
                                                        </View>
                                                        <View style={{ flex: 0.14 }}>
                                                            <TextInput
                                                                value={item.order_quantity ? item.order_quantity : ""}
                                                                style={{
                                                                    borderColor: "#CDD1D5", height: '2vw', width: " 8vw", backgroundColor: color.white, padding: "0.5vw",
                                                                    borderRadius: "0.3vw", borderWidth: 1, fontSize: '1vw',
                                                                }}
                                                                keyboardType="numeric"
                                                                onChangeText={(text) => {
                                                                    let states = this.state;
                                                                    states.vendorList[index]['order_quantity'] = text
                                                                    this.setState({
                                                                        states
                                                                    })
                                                                }}

                                                            />
                                                        </View>
                                                        <View style={{ flex: 0.10 }}>
                                                            <TouchableOpacity
                                                                onPress={() => {
                                                                    let states = this.state
                                                                    states['isTrashClicked'] = true
                                                                    this.setState({
                                                                        states
                                                                    }, () => {
                                                                        this._confirmPopupDelete(item.order_id)
                                                                    }
                                                                    )
                                                                }}>
                                                                <Icon name='trash' size={20} color={color.red} ></Icon>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>

                                                )
                                            })
                                                : null
                                        }
                                    </ScrollView>
                                </View>
                                <View style={{ maxHeight: "15%", width: "100%", justifyContent: "flex-end", flexDirection: "row", marginTop: "auto" }}>
                                    <TouchableOpacity style={[styles.ButtonStyle, { marginRight: "0.63vw", width: "5vw" }]}
                                        onPress={() => {
                                            this.setState({
                                                isClicked: false,
                                                vendorList:[],
                                                Vendor_id: null,
                                                Vendor_name: ""
                                            })
                                        }}>
                                        <Text style={styles.ButtonText}>Back</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity style={[styles.ButtonStyle, { marginRight: "10px", width: "15vw" }]}
                                        onPress={() => { this._getPOnumber() }}
                                    >
                                        <Text style={styles.ButtonText}> Confirm Place Order</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                        :
                        null
                }
            </View>

        )
    }
}
const styles = StyleSheet.create({
    ButtonStyle: {
        width: '6vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.4vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize: "1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    },
    TitleText:{
        padding: "0.4vw", 
        fontSize: "1vw", 
        fontWeight: "500"
    }

})