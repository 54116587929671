import React, { Component } from "react";
import { View, Text, Switch, TextInput, Image, TouchableOpacity, ScrollView, FlatList, TouchableWithoutFeedback, Dimensions,StyleSheet } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { RadioButton } from 'react-native-paper';
import moment from "moment";

import {
    CommonLabelView, CommonLabelTextMView, CommonLabelTextView, DrugBatchDetails, CommonTextBox, CommonDatePicker,
    LineItemTextBox, ActionButton, SuccessAlert, ErrorAlert, CommonAutoSuggestionFull
} from "../common/BaseFunctionComponent";

import { color } from "../styles/Color";
import Style from "../styles/Style";
import Constants from '../networks/Constants';
import Service from '../networks/service';
import AsyncStorage from "../AsyncStorage";
import '../styles/GlobalStyles.css';
import BaseComponent from '../common/BaseComponent';

let screenHeight = Dimensions.get("window").height;
const tempRows = {
    "brand_name": "",
    "dosage_type": "",
    "dosage_strength": "",
    // flags
    "isDrugSelected": false,
    "isDosageSelected": false,
    "isStrengthSelected": false,
    // needed
    "line_item_id": "",
    "drug_id": "",
    "hsn_code": "",
    "schedule": "",
    "batch_no": "",
    "quantity": "",
    "expiry_date": "",
    "physical_box_no": "",
    "cost_per_quantity": "",
    "total_cost": "",
    "discount_percentage": "",
    "gst_percentage": "",
    "net_total_amount": "",
    // extra
    "batch_details": "select",
    "stock_in_quantity": "0"
};

const tempSuggestion = {
    "drugSuggestionList": [],
    "dosageSuggestionList": [],
    "strengthSuggestionList": [],
    "drugDetailsSuggestionList": []
}

const tempData = {
    "bill_summary_id": "",
    "invoice_number": "",
    "invoice_date": new Date(),
    "billing_customer_name": "",
    "billing_customer_mobile_no": "",
    "doctor_name": "",
    "patient_id": "",
    "doctor_id": "",
    "clinic_id": "",
    "patient_account_number": "",
    "ip_number":0,
    "transmitted_id": "",
    "card_number": "",
    "payment_mode": "1",
    "discount_amount": "0",
    "taxable_amount": "0",
    "general_discount": "0",
    "cgst_amount": "0",
    "sgst_amount": "0",
    "igst_amount": "0",
    "tax_amount": "0",
    "round_off": "0",
    "delivery_charge": "0",
    "gross_amount": "0",
    "grand_total": "0",
    "net_amount" : "0",
    "received_amount": "0",
    "balance_amount": "0",
    "bill_line_items": [],
    // excess
    "general_discount": "0",
    "transaction_id": "",
    "employee_id":'',
    "company_name":"",
    "transaction_number":"",
    "policy_number":"",
    "insurance_company_name":'',
    "validity":'',
    "bill_type":"Bill",
    "general_discount_percentage" : "0",
    "doctor_reg_no": "",
};

export default class NewBill extends BaseComponent {
    constructor(props) {
        super(props);

        this.refDoctorName = React.createRef()

        // Suggestion list
        let suggestions = [];
        let is_saved_bill = false;
        let data = JSON.parse(JSON.stringify(tempData))

        for (let i = 0; i < 10; i++) {
            suggestions.push(tempSuggestion);
        }

        if (this.props.selectedSavedBill) {

            data['bill_summary_id'] = this.props.selectedSavedBill.bill_summary_id
            is_saved_bill = true;
        }

        this.state = {
            showPickerDropDownFlag: false,
            selectedBatch: [],
            batchData: [],
            billRows: [],
            gstin_number: "",
            data: data,

            //setting config
            is_discount_edittable: false,
            pharmacyType: "",

            typedIndex: 0,
            drugSuggestionList: [],
            drugDetailsSuggestionList: [],
            drugDosageTypes: [],
            suggestions: JSON.parse(JSON.stringify(suggestions)),
            // flags
            flagShowDoctorList: false,
            dropDownViewFlag : false,
            // is_partial_bill: false,
            is_complete_bill: false,
            is_saved_bill: is_saved_bill,
            is_editable: true,
            is_action_for_complete_bill: false,
            is_disable_action: false,
            is_initiate_return: false,
            is_complete_disable_action: false,
            is_bill_type_disable : false,
            // from props, view bill
            bill_type: "",
            viewBill: this.props.selectedBillToView ? this.props.selectedBillToView : null,

            // from patient appointment
            patientAppointment: this.props.patientAppointment ? this.props.patientAppointment : null,
            is_patient_details_editable: true,

            // from transmitted list 
            transmittedPrescription: this.props.transmittedPrescription ? this.props.transmittedPrescription : null,
            searchDoctorsList: [],
            doctorsList: [],
            conformationFlag : false,
            isConformationYes : false,
            subAmount : 0.00,
            isOnFocus : false,
            isOnFocusPer: false,
            searchPatientData: [],
            toggleSwitchFlag: false,
            totalAvailableQuantity: null,
            isSideMenuClick: false,
            isDiscardChanges: false,
            transmittedPrescriptionFlag: false,
            appointmentListFlag : false,
            savedBillFlag: false,
            selectedTopMenu: "",

            patientSearchData: this.props.patientSearchData ? this.props.patientSearchData : null,
            totalAvailableQuantity: null,
            is_return_editable: false,
            isOnChange: false,
            isDoctorSelected: false

        }

        // initiate service
        this.service = new Service();
        this._calculateReturnBill = this._calculateReturnBill.bind(this);
        // this._updateBillState = this._updateBillState.bind(this);
        this._updateState = this._updateState.bind(this);
        this._clearNotification = this._clearNotification.bind(this);
        this._clearBill = this._clearBill.bind(this)
    }

    async componentDidMount() {

        let pharmacy_type = await AsyncStorage.getItem("pharmacy_type");
        this.configStatusGet();
        this.setState({
            pharmacyType: pharmacy_type,
        })

        let { viewBill, data, transmittedPrescription, patientAppointment, billRows, patientSearchData } = this.state;

        this._checkGSTnumber()
        this.configStatusGet();

        if (data.bill_summary_id) {

            this.setState({
                is_editable: true,
                is_saved_bill: true,
                is_complete_bill: false,
                is_patient_details_editable: true,
            }, () => {
                this._loadSavedBill(data.bill_summary_id)
            })

        } else if (viewBill) {
            let suggestions = []
            for (let i = 0; i < 10; i++) {
                suggestions.push(tempSuggestion);
            }
            this.setState({
                is_editable: false,
                is_saved_bill: false,
                is_complete_bill: true,
                is_patient_details_editable: false,
                suggestions : suggestions
            }, () => {
                this._viewBillDetails()
            })

        } else if (transmittedPrescription) {

            // for clear previous data
            data = JSON.parse(JSON.stringify(tempData))

            if (transmittedPrescription.doctor){ 
                data['doctor_name'] = transmittedPrescription.doctor['name']
                data["doctor_reg_no"] = props.transmittedPrescription.doctor["doctor_reg_no"] ? props.transmittedPrescription.doctor["doctor_reg_no"] : ""
            }    
            if (transmittedPrescription.patient) {
                console.log(transmittedPrescription.patient)
                data['billing_customer_name'] = transmittedPrescription.patient['name']
                data['billing_customer_mobile_no'] = transmittedPrescription.patient['mobile_number']
                data['patient_account_number'] = transmittedPrescription['patient_account_number']
                data['ip_number'] = transmittedPrescription.patient['ip_number'] ? transmittedPrescription.patient['ip_number'] : 0
            }

            if (transmittedPrescription.prescription_lines && transmittedPrescription.prescription_lines.length > 0) {
                billRows = []
                var brand_name = billRows && billRows.length > 0 && billRows.map((item)=>item.brand_name )
                this.props.discardChanges(brand_name[0], this.state.is_complete_bill)

                let temp = transmittedPrescription.prescription_lines;
                let len = temp.length;

                for (let i = 0; i < len; i++) {
                  if(!patientAppointment){ 
                    let dummyRow = JSON.parse(JSON.stringify(tempRows))
                    dummyRow['drug_id'] = temp[i].drug_id
                    dummyRow['brand_name'] = temp[i].brand_name
                    dummyRow['dosage_strength'] = temp[i].dosage_strength
                    dummyRow['dosage_type'] = temp[i].dosage_type
                    // for transmitted details alone
                    dummyRow['is_transmitted_drug'] = true

                    billRows.push(dummyRow)
                }
                }

            } else {
                billRows = []
            }

            // get PO number
            this.setState({

                is_editable: true,
                is_saved_bill: false,
                is_complete_bill: false,
                is_patient_details_editable: false,
                data,
                billRows,
                selectedTopMenu: ""
            }, () => {
                this._clearBill()
            })

        }  else if(patientSearchData) {
            
            data['doctor_name'] = patientSearchData['doctor_name'] ? patientSearchData['doctor_name'] : ""
            data['billing_customer_name'] = this.state.pharmacyType === "Standalone Pharmacy" ? patientSearchData['patient_name']:patientSearchData['first_name']
            data['billing_customer_mobile_no'] = patientSearchData['mobile_number']
            data['patient_account_number'] = patientSearchData['patient_account_number']
            data["ip_number"] = patientSearchData['ip_number'] ? patientSearchData['ip_number'] : 0
            // data['patient_id'] = patientSearchData["id"]
            data["doctor_reg_no"] = props.patientAppointment["doctor_reg_no"] ? props.patientAppointment["doctor_reg_no"] : ""
            this.setState({

                is_editable: true,
                is_saved_bill: false,
                is_complete_bill: false,
                is_patient_details_editable: true,
                data,
                billRows,
                selectedTopMenu: ""
            }, () => {
                this.props.discardDataReset(true)
                this._clearBill()
            })
        } else {

            let is_patient_details_editable = true

            if (patientAppointment) {

                is_patient_details_editable = false

                data['doctor_name'] = patientAppointment['doctor_name'] ? patientAppointment['doctor_name'] : ""
                data['billing_customer_name'] = patientAppointment['patient_name']
                data['billing_customer_mobile_no'] = patientAppointment['patient_mobile_number']
                data["doctor_reg_no"] = patientAppointment['doctor_reg_no'] ? patientAppointment['doctor_reg_no'] : ""
                data["ip_number"] = patientAppointment['ip_number'] ? patientAppointment['ip_number'] : 0
            }

            billRows = []

            // get PO number
            this.setState({

                is_editable: true,
                is_saved_bill: false,
                is_complete_bill: false,
                is_patient_details_editable: is_patient_details_editable,
                data,
                billRows,
                selectedTopMenu: ""
            }, () => {
                this.props.discardDataReset(true)
                this.props.discardChanges("", false)
                this._loadBillNumber()
                this._clearBill()
            })
        } 

        this._loadDoctorsList()
    }

    configStatusGet = () => {
        var url = Constants.CONFIG_UPDATE
        this.service.getApiCall(
            url,
            this.getConfigSuccess,
            this.getConfigError
        )
    }
    getConfigSuccess = (response) => {
        if (response.status === "succes") {
            this.setState({
                is_discount_edittable: response.data.drug_individual_discount,
            })
        } 
    }

    getConfigError = (response) => {

    }

    componentWillReceiveProps(props) {
        // componentDidUpdate(props, prevStats){
        let states = this.state;
        let { data, viewBill, transmittedPrescription, patientAppointment, billRows,
            is_patient_details_editable, is_saved_bill, is_complete_bill, is_action_for_complete_bill, patientSearchData, searchPatientData  } = this.state;
        // if(props.isDiscardChanges && props.isSideMenuClick) {
        //        this.setState({
        //         conformationFlag: false
        //        })
        // }
        if(data.billing_customer_mobile_no === "" && searchPatientData.length > 0){
            this.setState({
                searchPatientData: []
            })
        }
        if(this.state.selectedTopMenu != props.selectedTopMenu) {
            this.setState({
                selectedTopMenu: props.selectedTopMenu
            })
        } else if(this.state.isDiscardChanges != props.isDiscardChanges) {
            this.setState({
                isDiscardChanges: props.isDiscardChanges
            })
        }
        if (props.selectedSavedBill && props.selectedSavedBill.bill_summary_id !== data.bill_summary_id && this.state.selectedTopMenu === "savedBillFlag" && !this.state.isDiscardChanges) {

            var drugId = []
            var i = 0

            for(i=0; i<billRows.length; i++){

                if(billRows[i].drug_id != ""){
                   let drug_id = billRows[i].drug_id
                   drugId.push(drug_id)
                }   
            }        
            if (drugId.length > 0 && this.state.selectedTopMenu === "savedBillFlag" && !this.state.isDiscardChanges) {
                      this.setState({
                          conformationFlag : true
                      })
            } else {      

            data['bill_summary_id'] = props.selectedSavedBill.bill_summary_id
            // states['is_saved_bill'] = true;
            data['invoice_number'] = ""
            data['received_amount'] = ""
            // data["doctor_reg_no"] = null

            // clear suggestion
            let suggestions = []
            for (let i = 0; i < 10; i++) {
                suggestions.push(tempSuggestion);
            }

            this.setState({

                // states,
                is_saved_bill: true,
                is_complete_bill: false,
                is_editable: true,
                is_patient_details_editable: true,
                data: data,
                selectedTopMenu: ""
            }, () => {
                this.props.discardDataReset(true)
                this._loadSavedBill(props.selectedSavedBill.bill_summary_id)
            })
            }
            /*
            } else if(props.viewBill && props.viewBill.id != viewBill.id) {
    
                this.setState({
                    viewBill: props.viewBill 
                }, ()=>{
                    this._viewBillDetails()
                })
            */

            /*        } else if(props.selectedBillToView && props.selectedBillToView.id !== data.bill_summary_id) {
            
                        this.setState({
                            is_editable: true
                        }, ()=>{
                            this._loadSavedBill(props.selectedBillToView.id) 
                        })
            */
        } else if( props.patientSearchData && (props.patientSearchData != this.state.patientSearchData) ) {

            is_patient_details_editable = false
            // data['doctor_name'] = props.patientSearchData['doctor_name']
            data['billing_customer_name'] = this.state.pharmacyType === "Standalone Pharmacy" ? props.patientSearchData['patient_name']:props.patientSearchData['first_name']
            data['billing_customer_mobile_no'] = props.patientSearchData['mobile_number']
            data['patient_account_number'] = props.patientSearchData['patient_account_number']
            data['ip_number'] = props.patientSearchData['ip_number'] ? props.patientSearchData['ip_number'] : 0
            data['patient_id'] = props.patientSearchData["id"] 

            this.setState({
                patientSearchData: props.patientSearchData,
                is_patient_details_editable: true,
                data,
                is_editable: true
            })
        }
        else if (this.state.selectedTopMenu === "transmittedPrescriptionFlag" && !this.state.isDiscardChanges && props.transmittedPrescription && (!transmittedPrescription || (transmittedPrescription && props.transmittedPrescription.prescription_id != transmittedPrescription.prescription_id))) {

            var drugId = []
            var i = 0

            for(i=0; i<billRows.length; i++){

                if(billRows[i].drug_id != ""){
                   let drug_id = billRows[i].drug_id
                   drugId.push(drug_id)
                }   
            }        
            if ( drugId.length > 0 && this.state.selectedTopMenu === "transmittedPrescriptionFlag" && !this.state.isDiscardChanges && Object.keys(props.transmittedPrescription).length > 0) {
                      this.setState({
                          conformationFlag : true
                      })
            } else {            

            // for clear previous data
            data = JSON.parse(JSON.stringify(tempData))

            if (props.transmittedPrescription.doctor) { 
                data['doctor_name'] = props.transmittedPrescription.doctor['name'] ? props.transmittedPrescription.doctor['name'] : ""
                data["doctor_reg_no"] = props.transmittedPrescription.doctor["doctor_reg_no"] ? props.transmittedPrescription.doctor["doctor_reg_no"] : ""
            }   
            if (props.transmittedPrescription.patient) {
                data['billing_customer_name'] = props.transmittedPrescription.patient['name']
                data['billing_customer_mobile_no'] = props.transmittedPrescription.patient['mobile_number']
                data['patient_account_number'] = props.transmittedPrescription['patient_account_number']
                data['ip_number'] = props.transmittedPrescription.patient['ip_number'] ? props.transmittedPrescription.patient['ip_number'] : 0
            }

            if (props.transmittedPrescription.prescription_lines && props.transmittedPrescription.prescription_lines.length > 0) {
                billRows = []
                let temp = props.transmittedPrescription.prescription_lines;
                let len = temp.length;

                for (let i = 0; i < len; i++) {

                    let dummyRow = JSON.parse(JSON.stringify(tempRows))
                    dummyRow['drug_id'] = temp[i].drug_id
                    dummyRow['brand_name'] = temp[i].brand_name
                    dummyRow['dosage_strength'] = temp[i].dosage_strength
                    dummyRow['dosage_type'] = temp[i].dosage_type
                    // for transmitted details alone
                    dummyRow['is_transmitted_drug'] = true
                    
                    billRows.push(dummyRow)
                }
              
            } else {
                billRows = []
            }
           
            // get PO number
            this.setState({

                is_editable: true,
                is_patient_details_editable: false,
                data,
                billRows,
                selectedTopMenu: ""
            }, () => {
                this.props.discardDataReset(true)
                this.props.discardChanges("",false)
                if (props.transmittedPrescription.prescription_lines && props.transmittedPrescription.prescription_lines.length > 0) {
                let temp = props.transmittedPrescription.prescription_lines;
                let len = temp.length;
                for (let i = 0; i < len; i++) {
                    this._getBatchDetailsbyDrugId(i)
                }
                }
                // var brand_name = billRows && billRows.length > 0 && billRows.map((item)=>item.brand_name )
                // this.props.discardChanges(brand_name[0], this.state.is_complete_bill)
                // this._loadBillNumber();
                    // this._clearBill()    
            })
        }

            // get bill details function 
        } else if (props.patientAppointment && this.state.selectedTopMenu === "appointmentListFlag" && !this.state.isDiscardChanges && (data.patient_id != props.patientAppointment.patient_id)) {
            var drugId = []
            let is_patient_details_editable = true
            var i = 0

            for(i=0; i<billRows.length; i++){

                if(billRows[i].drug_id != ""){
                   let drug_id = billRows[i].drug_id
                   drugId.push(drug_id)
                }   
            }        
            if (props.patientAppointment && drugId.length > 0 && this.state.selectedTopMenu === "appointmentListFlag" && !this.state.isDiscardChanges) {
                      this.setState({
                          conformationFlag : true
                      })
            } else {
                data['doctor_name'] = props.patientAppointment['doctor_name'] ? props.patientAppointment['doctor_name'] : 0 
                data['billing_customer_name'] = props.patientAppointment['patient_name']
                data['billing_customer_mobile_no'] = props.patientAppointment['patient_mobile_number']
                data['patient_account_number'] = props.patientAppointment['patient_account_number']
                data['ip_number'] = props.patientAppointment['ip_number'] ? props.patientAppointment['ip_number'] : 0
                data['invoice_number'] = ""
                data['received_amount'] = ""
                data['bill_summary_id'] = ''
                data['patient_id'] = props.patientAppointment['patient_id']
                data["doctor_reg_no"] = props.patientAppointment['doctor_reg_no'] ? props.patientAppointment['doctor_reg_no'] : ""
            }


            // get PO number
            this.setState({
                patientAppointment: props.patientAppointment,
                is_editable: true,
                is_patient_details_editable: is_patient_details_editable,
                data,
                billRows,
                // selectedTopMenu: ""
            }, () => {
                this.props.discardDataReset(true)
                this.props.discardChanges("", false)
                this._loadBillNumber();
            })
            /*
            } 
            else if(!props.selectedSavedBill && data.bill_summary_id) {
    
                // for clear on delete bill
                this.setState({
                    data: JSON.parse(JSON.stringify(tempData)),
                    is_saved_bill: false
                })
                */
        }
       
    }

    _checkGSTnumber = async () => {

        let { gstin_number } = this.state
        let store_gstin_number = await AsyncStorage.getItem("gstin_number")

        if (!gstin_number) {
            if (store_gstin_number) {
                this.setState({ gstin_number: store_gstin_number })
            }
        }
    }

    _onChangeDate = (stateKey, value, type) => {

        let { data } = this.state;

        data[stateKey] = value;

        this.setState({ 
            data,
            isOnChange: true
        })
    }

    _updateState = (stateKey, value, type) => {

        let states = this.state;
        let stateArr = [];
        stateArr = stateKey.split(",");

        if (stateArr.length === 1) {

            states[stateArr[0]] = value;
        } else if (stateArr.length === 2) {

            states[stateArr[0]][stateArr[1]] = value;
        }

        this.setState({ states })

    }
    _doctorNameAutoSearch = (value) =>{

       let tempDoctorList = this.state.doctorsList
       if(tempDoctorList && tempDoctorList.length > 0) {
        var searchListTemp = [];
        for (let i = 0; i < tempDoctorList.length; i++) {
  
          if (tempDoctorList && tempDoctorList.length > 0 && value && value.length > 0 && tempDoctorList[i].doctor_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            searchListTemp.push(tempDoctorList[i].name);
          }
        }
        this.setState({
            searchDoctorsList :searchListTemp
        })
      }
    }
    _updateState2 = (stateKey, value, type) => {

        let { data, isSelected, flagShowDoctorList } = this.state;

        let grand_total = parseFloat(data.grand_total) || 0;
        let current_entry = parseFloat(value) || 0;

        if (stateKey === "billing_customer_name" && data['billing_customer_name'] != "" && data['patient_id'] != ""){
            data['billing_customer_name'] = value
            data["billing_customer_mobile_no"] = ""
            data['patient_account_number'] = ""
            data['patient_id'] = ""
            data['ip_number'] = 0
        }
        if(stateKey == "billing_customer_mobile_no"){
         if (value.length < 11 && data['patient_id'] != ""){
            data["billing_customer_mobile_no"] = value
            data['billing_customer_name'] = ""
            data['patient_account_number'] = ""
            data['patient_id'] = ""
            data['ip_number'] = 0
            if(value.length > 4) {
                this._geAppointmentList(value);
                this.setState({
                    dropDownViewFlag:true
                })
            }
                this._clearDropdowns()    
            }
            else {
                if (value.length < 11) {
                    data["billing_customer_mobile_no"] = value
                    if (value.length > 4) {
                        this._geAppointmentList(value);
                        this.setState({
                            dropDownViewFlag: true
                        })
                    }
                    this._clearDropdowns()
                }
            }
        } else if (stateKey === "doctor_reg_no") {
           if(value.length < 8 ) {
            data['doctor_reg_no'] = value
           }
        } else{
            data[stateKey] = value;
        }

        if (stateKey === "card_amount") {
            data['cash_amount'] = (grand_total - current_entry).toFixed(2)
        }

        if (stateKey === "cash_amount") {
            data['card_amount'] = (grand_total - current_entry).toFixed(2)
        }

        this.setState({ data, isSelected, flagShowDoctorList: flagShowDoctorList }, () => {
            if (stateKey === "received_amount") {
                // update calculation
                this._updateBillAmounts()
            }
        })

    }

    _updateBillAmounts() {

        let { data, is_initiate_return, billRows } = this.state;

        let received_amt = 0;
        let bal_amt = 0;
        data['general_discount_percentage'] = parseFloat(data['general_discount_percentage']) 

        let grand_amt = is_initiate_return ? parseFloat(data['grand_return_total']).toFixed(2) : parseFloat(data['grand_total']).toFixed(2);
        if (data["payment_mode"] == "1" ) {
            received_amt = parseFloat(data['received_amount']) || 0;
            bal_amt = (received_amt - parseFloat(grand_amt)).toFixed(2);
            data['balance_amount'] = bal_amt;
            if(received_amt >= grand_amt){
                this.setState({
                    is_complete_disable_action: false
                })
            }else{
                this.setState({
                    is_complete_disable_action: true
                }) 
            }
        } else {
            this.setState({
                is_complete_disable_action: false
            })
            data['received_amount'] = 0;
            data['balance_amount'] = 0;
        }

        this.setState({ data },()=>{
            this._drugListTaxableAmountUpdate( billRows, data)
        })
    }

    _updateBillState = (stateKey, value, type, index) => {

        let { data, billRows } = this.state;

        // set data
       if(stateKey === "discount_percentage" && value.length < 3) {

        billRows[index]["discount_percentage"] = value;

       } else if(stateKey != "discount_percentage"){

        billRows[index][stateKey] = value;
        
       }
        // clear batch popups
        billRows = this._updateBatchPopup(billRows, 'false');

        // process data based on keyname
        if (stateKey === "batch_no" || stateKey === "quantity" || stateKey === "discount_percentage") {

            // set total_cost
            let total_cost = 0;
            let cost = parseFloat(billRows[index]["cost_per_quantity"]) || 0;
            let qty = parseInt(billRows[index]["quantity"]) || 0;    //parseInt(value);

            // total
            total_cost = parseFloat((cost * qty).toFixed(2)) || 0;

            // discount
            let discount_percentage = parseFloat(billRows[index]["discount_percentage"]) || 0;    // parseFloat(value) || 0;
            let discount_amount = parseFloat((total_cost * discount_percentage / 100).toFixed(2)) || 0;
            // for calc purpose 
            billRows[index]["discount_amount"] = discount_amount
            billRows[index]["discount_amount_return"] = 0

            // assign
            billRows[index]['total_cost'] = total_cost
            billRows[index]['net_total_amount'] = (total_cost - discount_amount).toFixed(2);
        }

        // set total cost, grand total
        data = this._drugListTaxableAmountUpdate(billRows, data)

        /*
        let taxable_amount = 0;
        let grand_total = 0;
        let gross_amount = 0;

        for(let i=0; i<billRows.length; i++) {

            let row_total = parseFloat(billRows[i]['net_total_amount']) || 0;

            taxable_amount = taxable_amount + row_total;
        }

        grand_total = taxable_amount - parseFloat(data.discount_amount) || 0;

        // update amount
        data['taxable_amount'] = taxable_amount.toFixed(2); 
        data['gross_amount'] = grand_total.toFixed(2); 
        data['grand_total'] = grand_total.toFixed(2);
        */
        data = this._updateRoundOff(data);

        this.setState({ billRows, data }, () => {

            // update calculation
            if (stateKey === "quantity" || stateKey === "discount_percentage") {
                //if( stateKey==="return_quantity" ) {

                this._updateBillAmounts()
            }
        })

    }

    _drugListTaxableAmountUpdate(billRows, data) {

        let { is_initiate_return } = this.state;

        // set total cost, grand total
        let taxable_amount = 0;
        let grand_total = 0;
        let gross_amount = 0;
        let itemwise_discount_amount = 0

        // for return
        let taxable_return_amount = 0;
        let grand_return_total = 0;

        for (let i = 0; i < billRows.length; i++) {

            let row_total = 0;

            if (is_initiate_return) {
                row_total = parseFloat(billRows[i]['net_return_total_amount']) || 0;
                taxable_return_amount = taxable_return_amount + row_total;

                // add discount amount
                itemwise_discount_amount = itemwise_discount_amount + this._setDataFormat(billRows[i]['discount_amount_return'], 'decimal');
            } else {
                row_total = parseFloat(billRows[i]['net_total_amount']) || 0;
                taxable_amount = taxable_amount + row_total;

                // add discount amount
                itemwise_discount_amount = itemwise_discount_amount + this._setDataFormat(billRows[i]['discount_amount'], 'decimal');
            }
        }

        // assign discount amount
        data['discount_amount'] = itemwise_discount_amount

        // general disc amount update
        let general_discount_percentage = data['general_discount_percentage'] || 0

        let general_discount = ((taxable_amount * general_discount_percentage) / 100 || 0)
        let general_discount_return = ((taxable_return_amount * general_discount_percentage) / 100 || 0)

        // grand_total = taxable_amount - parseFloat(data.discount_amount) || 0;
        // grand_return_total = taxable_return_amount - parseFloat(data.discount_amount) || 0;

        grand_total = taxable_amount - parseFloat(general_discount) || 0;
        grand_return_total = taxable_return_amount - parseFloat(general_discount_return) || 0;

        this.setState({ subAmount : taxable_amount })
        if (!is_initiate_return) {
            // update amount
            data['taxable_amount'] = parseFloat(taxable_amount).toFixed(2);
            data['gross_amount'] = grand_total.toFixed(2);
            data['grand_total'] = grand_total.toFixed(2);
            data['general_discount'] = parseFloat(general_discount).toFixed(2)

        } else {
            // update return
            data['taxable_return_amount'] = taxable_return_amount.toFixed(2);
            data['grand_return_total'] = grand_return_total.toFixed(2);
            data['gross_return_amount'] = grand_return_total.toFixed(2);
            data['general_discount'] = general_discount_return;
            data['received_amount'] = grand_return_total;
            data['balance_amount'] = 0;

        }
        this._updateRoundOff(data)
        return data;
    }

    renderTextBox(stateKey, placeholder, width, center, type, value, error = false, disable = false) {
        return (
            <View style={{ width: width, justifyContent: 'center', marginRight: 15, marginVertical: 15 }}>
                <Text style={{ zIndex: 2, fontSize: 12, position: "absolute", marginTop: -25, marginLeft: 10, backgroundColor: type == "IDP" ? color.themeShade : "white", paddingHorizontal: 5, color: "#888888" }}>{placeholder}</Text>

                {
                    disable ?
                        <Text style={[Style.commonTextBoxStyles, { zIndex: -3, marginTop: 8, color: color.labelColor }]} >
                            {value}
                        </Text> :
                        <TextInput
                            style={{
                                borderColor: error ? "red" : "#CDD1D5",
                                borderRadius: 4,
                                borderWidth: 1,
                                width: "100%",
                                height: 35,
                                padding: 15,
                                marginTop: 8
                            }} value={value}
                            onChangeText={(text) => {
                                this._updateState(stateKey, text, type);
                            }}
                        />
                }
            </View>
        )
    }
    _onChangeDoctorName = (stateKey, value, type = '') => {

            let states = this.state;
            let { doctorsList, data } = this.state;
            if(value) {
                data["doctor_name"] = value;
                data['doctor_id'] = null;
                data['doctor_reg_no'] = "";
                states['isSelected'] = false;
                states["flagShowDoctorList"] = true;
                states["isDoctorSelected"] = false
                states['searchDoctorsList'] = this._sortListData(doctorsList, value)
            } else if(value === ""){
                data["doctor_reg_no"] = "";
                data["doctor_name"] = "";
                states["isDoctorSelected"] = false;
                states['flagShowDoctorList'] = false;
            }
            
            this.setState({
              states,
              data,
              flagShowDoctorList: this.state.flagShowDoctorList
            })
    }
    getBillDetails() {
        let { is_patient_details_editable, data, gstin_number, is_complete_bill } = this.state;
        let manitory = this.state.toggleSwitchFlag ? " *" : ""
        return (
            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", maxHeight: '8vh' }}>
                <View style={{flexDirection: "row", justifyContent: "flex-start", alignContent: "center", margin: '0.97vw' }}>
                    {is_complete_bill ?
                        <TouchableOpacity
                            onPress={() =>{ 
                                this.props.changeScreen("invoice")
                                this.setState({
                                    billRows: []
                                })
                            }}
                            style={{ backgroundColor: color.themeBackgroud, borderRadius: 5, alignSelf: "center", paddingVertical: '0.32vw', paddingHorizontal: '0.52vw', marginRight: '0.52vw' }} >
                            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                <Image
                                    // style={style.BackIcon}
                                    style={{ width: "1.3vw", height: "1.3vw" }}
                                    source={require('../../assets/Icon/Back.png')}
                                />
                                <Text style={{ color: "#ffffff", fontSize:'0.9vw' }}>{"Back"}</Text>
                            </View>
                        </TouchableOpacity> : null}
                        {
                        this.state.pharmacyType != "Standalone Pharmacy" ?
                        <View style={{ width: '13vw', flexDirection: 'row', alignItems:'center'}}>             
                            <Text style={{ marginRight: '0.65vw', fontSize: "0.91vw",color:"#888888" }}>{"Cash Bill"}</Text>

                                <Switch
                                    style={{ width:'1vw', height:'1.2vw' }}
                                    // trackColor={{ false: "#767577", true: color.themeDark }}
                                    thumbColor={this.state.toggleSwitchFlag ? "#f5dd4b" : "#f4f3f4"}
                                    ios_backgroundColor="#3e3e3e"
                                    onValueChange={() => {
                                        this.setState({
                                        toggleSwitchFlag: !this.state.toggleSwitchFlag
                                        }, () => {
                                        var states = this.state.data;
                                        data["bill_type"] = this.state.toggleSwitchFlag?"Credit": "Bill";
                                        this.setState({ states });
                                        console.log("TYPE::", this.state.data);
                                        })
                                    }}
                                    value={this.state.toggleSwitchFlag}
                                />
                                <Text style={{ marginLeft: '0.65vw', fontSize: "0.91vw",color:"#888888" }}>{"Credit Bill"}</Text>
                        </View>
                        : null}

                        <CommonTextBox disable={!is_patient_details_editable} width={"7.8vw"} placeholder={"Patient Mobile" + manitory} type={"MI"} stateKey={"billing_customer_mobile_no"} value={data.billing_customer_mobile_no} onChangeText={this._updateState2.bind(this)} />
                        
                        {this.state.data.bill_type !== 'Credit' ?
                        <CommonTextBox disable={!is_patient_details_editable} width={"9.7vw"} placeholder={"Patient Name *"} type={"MI"} stateKey={"billing_customer_name"} value={data.billing_customer_name} onChangeText={this._updateState2.bind(this)} />: null
                        }
                        {(this.state.data.bill_type !== 'Credit' && this.state.pharmacyType != "Standalone Pharmacy") ?
                    <CommonTextBox disable={data.patient_id ? true : !is_patient_details_editable} width={data.ip_number ?'9.7vw': '7.8vw'} placeholder={data.ip_number ? "UHID/IP No" : "UHID"} type={"MI"} stateKey={"patient_account_number"} value={data.patient_account_number && data.ip_number ? `${data.patient_account_number} / ${data.ip_number}` : data.patient_account_number} onChangeText={this._updateState2.bind(this)} />: null
                        }
                    {this.state.data.bill_type === 'Credit' ?
                        <CommonTextBox disable={true} width={'9.7vw'} placeholder={"Patient Name *"} type={"MI"} stateKey={"billing_customer_name"} value={data.billing_customer_name} onChangeText={this._updateState2.bind(this)} />: null
                    }
                    {this.state.data.bill_type === 'Credit' ?
                        <CommonTextBox disable={true} placeholder={data.ip_number ? "UHID/IP No" + manitory : "UHID" + manitory } width={data.ip_number ?'9.7vw': '7.8vw'} type={"MI"} stateKey={"patient_account_number"} value={data.patient_account_number && data.ip_number ? `${data.patient_account_number} / ${data.ip_number}` : data.patient_account_number} onChangeText={this._updateState2.bind(this)} />: null
                    }  
                    {/* <CommonTextBox disable={!is_patient_details_editable} width={200} placeholder={"Doctor Name"} type={"MI"} stateKey={"doctor_name"} value={data.doctor_name} onChangeText={this._updateState2.bind(this)} /> */}
                    <CommonAutoSuggestionFull
                        width={'12vw'} type={""} align={""}
                        placeholder={"Doctor Name & Qualification"} disable={!is_patient_details_editable}
                        stateKey={"doctor_name"} value={data.doctor_name ? data.doctor_name : ""}
                        list={this.state.searchDoctorsList}
                        isSelected={this.state.isSelected}
                        showList={this.state.flagShowDoctorList}
                        onChangeText={this._onChangeDoctorName.bind(this)}
                        itemSelected={this._itemSelected.bind(this)}
                        reference={this.refDoctorName}
                        objFormat={{ keyId: "doctor_id", keyName: "name"}}
                    />
                    <CommonTextBox disable={(!is_patient_details_editable || this.state.isDoctorSelected)} width={"7.8vw"} placeholder={"Doctor Reg No"} type={""} stateKey={"doctor_reg_no"} value={data.doctor_reg_no ? data.doctor_reg_no : ""} onChangeText={this._updateState2.bind(this)} />
                </View>
                <View style={{ flexDirection: "row", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", zIndex: -10 }}>
                    <View style={{ flexDirection: "column", justifyContent: "center", alignContent: "center", margin: '0.65vw' }}>

                        <View style={{ flexDirection: "row", alignItems:'center' }}>
                            <View ><CommonLabelView  label={"Invoice Date"} /></View>
                            <CommonDatePicker placeholder={"Date"} type={"MI"} stateKey={"invoice_date"} value={moment(data.invoice_date) || null}
                                onChangeText={this._onChangeDate.bind(this)} nolabel={true} noborder={true} />
                        </View>

                        <View style={{ flexDirection: "row" }}>
                        <View style={{marginTop:'0.5vw'}}><CommonLabelView label={"Invoice Number"} /></View>
                            <View style={{marginTop:'0.5vw'}}><CommonLabelTextView label={data.invoice_number || "-"} /></View>
                        </View>
                    </View>
                    <View style={{ flexDirection: "column", justifyContent: "center", alignContent: "center", margin: '0.65vw'}}>
                        <CommonLabelView label={"GSTIN"} minWidth={'9.7vw'} />
                        <View style={{marginTop:'0.5vw'}}><CommonLabelTextView label={gstin_number || "-"} /></View>
                    </View>
                </View>
            </View>
        )
    }

    getBillTable() {
        let { billRows, is_complete_bill, is_initiate_return } = this.state;
        let zIndexTitle = 10 * -1;
        let zIndexData = (1) * (-100);

        if (billRows.length < 5) {
            for (let i = billRows.length; i < 5; i++) {
                billRows.push(JSON.parse(JSON.stringify(tempRows)))
            }
        }

        return (
            <View style={{  flexDirection: "column", justifyContent: "flex-start", alignContent: "center", margin: '0.9vw', zIndex: zIndexTitle, padding: '0.6vw', maxHeight: '48vh' }}>
               <View style={{  flexDirection: "column", justifyContent: "flex-start", alignContent: "center", height: '8vh' }}>
                  <View style={{ flex: 1, flexDirection: "row" , justifyContent:"center", backgroundColor: color.tableHeaderBG, padding: '0.52vw', borderRadius: 4, marginBottom :"0.1vw"}}>
                     
                     <View style={{flex:0.30, justifyContent:"center",  alignItems: "center" }}>
                        <Text style={{fontSize:"0.88vw",fontWeight:"600"}}>Brand / Generic Name Information</Text>
                     </View>

                     <View style={{flex:0.50, justifyContent:"center",  alignItems: "center" }}>
                        <Text style={{fontSize:"0.88vw",fontWeight:"600"}}>Stock Information</Text>
                     </View>

                     <View style={{flex:0.20, justifyContent:"center",  alignItems: "center" }}>
                       <Text style={{fontSize:"0.88vw",fontWeight:"600"}}>Billing Information</Text>
                     </View>

                 </View>

                 <View style={{ flex: 1,
                    flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
                    backgroundColor: color.tableHeaderBG, padding: '0.52vw', borderRadius: 4, zIndex: zIndexTitle,}}>
                    
                    <View style={{flex: 0.30, flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                    {is_complete_bill ? null : this.getBillTableTitle('', 0.1,"0.8vw")}
                    {this.getBillTableTitle('Brand / Generic Name ', 0.45,"0.8vw")}
                    {this.getBillTableTitle('Strength & Type', 0.40,"0.8vw")}
                    {/* {this.getBillTableTitle('Dosage Type', 0.24,"0.8vw")} */}
                    </View>
                    
                    <View style={{flex: 0.50,flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                    {this.getBillTableTitle('Batch No - Avl Qty', 0.31,"0.8vw")}
                    {/* {this.getBillTableTitle('HSN Code', 0.15,"0.8vw")} */}
                    {this.getBillTableTitle('Expiry Date', 0.16, "0.8vw")}
                    {this.getBillTableTitle('Box No', 0.15,"0.8vw")}
                    {this.getBillTableTitle('Cost/Qty', 0.15,"0.8vw")}
                    {this.getBillTableTitle('Quantity', is_complete_bill ? 0.19 : 0.18,"0.8vw")}
                    </View>
                    <View style={{flex: 0.20,flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                    {this.getBillTableTitle('Total Cost', 0.33,"0.8vw")}
                    {this.getBillTableTitle('Discount %', 0.33,"0.8vw")}
                    {this.getBillTableTitle('Total', is_complete_bill ? 0.34 : 0.33,"0.8vw")}
                    </View>
                    
                   
                    {/* {is_complete_bill ? null : this.getBillTableTitle('Action', 0.04,"0.8vw")} */}
                </View>
                </View>
               
            
                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: "40vh"}}>
                {billRows.map((item, index) => {
                    let sno = index + 1;
                    zIndexData = (index + 1) * (-100);
                    return (
                            <View key={index} style={{
                                flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
                                backgroundColor: "#F9F9F9", padding: 6, borderRadius: 4, marginTop: 5,
                                borderBottomWidth: 1, borderColor: color.tableHeaderBG, zIndex: zIndexData 
                            }}>
                                {/* {this.getBillTableData('', sno, 0.03, 'label')} */}
                                <View style={{flex: 0.30,flexDirection: "row", justifyContent: "flex-start", alignItems:"center"}}>
                                {is_complete_bill ? null : this.getBillTableData('', 'X', 0.1, 'icon', index)}
                                {this.getBillTableData('brand_name', item.brand_name, 0.45, '', index)}
                                {/* {this.getBillTableData('dosage_strength', item.dosage_strength , 0.23, 'label', index)} */}
                                {this.getBillTableData('dosage_type', item.dosage_strength ? (item.dosage_strength + " / " + item.dosage_type) : "" , 0.40, 'label', index)}
                                </View>

                                <View style={{flex: 0.50,flexDirection: "row", justifyContent: "flex-start", alignItems:"center" }}>
                                {this.getBillTableData('batch_no', item.batch_no, 0.31, 'select', index)}
                                {/* {this.getBillTableData('hsn_code', item.hsn_code, 0.15, '', index)} */}
                                {this.getBillTableData('expiry_date', item.expiry_date ? moment(item.expiry_date).format('YYYY-MM') : null, 0.16, '', index)}
                                {this.getBillTableData('physical_box_no', item.physical_box_no, 0.15, '', index)}
                                {this.getBillTableData('cost_per_quantity', item.cost_per_quantity, 0.15, '', index)}
                                {this.getBillTableData('quantity', item.quantity, is_complete_bill ? 0.19 : 0.18, '', index)}
                                </View>

                                <View style={{flex: 0.20,flexDirection: "row", justifyContent: "flex-start", alignItems:"center"}}> 
                                {this.getBillTableData('total_cost', item.total_cost, 0.33, '', index)}
                                {this.getBillTableData('discount_percentage', item.discount_percentage, 0.33, '', index)}
                                {this.getBillTableData('net_total_amount', item.net_total_amount, is_complete_bill ? 0.34 : 0.33, '', index)}
                              </View>
                            </View>
                    )
                })}
                           {
                    !is_complete_bill ?
                        <View style={{ flexDirection: "row", justifyContent: "flex-end", marginTop: 10, zIndex: zIndexData - 100 }}>
                            <ActionButton label={"Add Item"} keyIndex={"addNextRos"}
                                // source={require('../../assets/Icon/NextBill.png')}
                                onPressAction={this._addNextRows.bind(this, false)} />
                        </View> : null
                }
                 </ScrollView>
               
            </View>
        )
    }

    getBillTableTitle(title, flexWidth = 0.05, fontsize="0.7vw") {
        return (
            <View style={{ flex: flexWidth}}>
                <Text style={{ fontSize: fontsize }}>{title}</Text>
            </View>)
    }

    getBillTableData(stateKey, title, flexWidth = 0.05, type = 'textBox', index = null) {

        let { is_editable, is_complete_bill, is_initiate_return, billRows } = this.state;
        let zIndex = (index + 1) * -10;

        title = this._parseString(title)      
        return (
            <View style={{ flex: flexWidth, zIndex: zIndex }}>
                {type === 'label' ? <Text style={{fontSize: stateKey === 'dosage_type' ? '0.8vw' : '1vw'}}>{title}</Text>

                    : stateKey === 'brand_name' ?
                        (this._handleDrugSuggestion(type, index, stateKey))
                        : (stateKey === 'dosage_type' || stateKey === 'dosage_strength') ?
                            (this._handleDrugDetailSuggestion(type, index, stateKey))
                            : type === 'select' ?
                                (this._handleDrugBatchSelection(type, index, stateKey))
                                : type === 'icon' ?
                                    (<TouchableOpacity onPress={() => { billRows[index]["brand_name"] ? this._handleItemRemove(index) : null }} style={{ alignSelf: "center" }}>
                                        <Icon name="minus-circle" size={'1.60vw'} color={"#9c9898"} />
                                    </TouchableOpacity>)
                                    : stateKey === "quantity" ?
                                        <View style={{ flexDirection: "row", justifyContent: "flex-start", maxWidth: is_complete_bill ? 80 : 60 }}>

                                            {is_initiate_return && billRows[index]['id']?
                                                <View style={{ flex: 0.5 }}>
                                                    <TextInput
                                                        style={{
                                                            // flex: 1,
                                                            height: '2vw', // 35
                                                            padding: 6,
                                                            marginRight: 5,
                                                            backgroundColor: billRows[index]['id'] ? color.white : color.tableHeaderBG,
                                                            borderColor:  (billRows[index]["return_quantity"] > billRows[index]["quantity"]) && this.state.is_complete_bill ? color.red : "#CDD1D5",
                                                            borderRadius: 4,
                                                            borderWidth: 1,
                                                            fontSize: '0.71vw'
                                                        }}
                                                        keyboardType = "numeric"
                                                        editable={billRows[index]['id'] ? true : false}
                                                        value={billRows[index]['return_quantity'] || ""}
                                                        onChangeText={(text) => {
                                                            this._calculateReturnBill('return_quantity', text, type, index)
                                                        }}
                                                    /></View> : null}

                                            <View style={{ flex: is_initiate_return ? 0.5 : 1 }}>
                                                <TextInput
                                                    style={{
                                                        // flex: 1,
                                                        height: '2vw', // 35
                                                        padding: 6,
                                                        marginRight: 5,
                                                        backgroundColor: !is_editable ? color.tableHeaderBG : color.white,
                                                        borderColor: "#CDD1D5",
                                                        borderColor: ( ((billRows[index]["quantity"] > billRows[index]["stock_in_quantity"]) && !this.state.is_complete_bill)
                                                        ) ? color.red : "#CDD1D5" ,
                                                        borderRadius: 4,
                                                        borderWidth: 1,
                                                        fontSize: '0.71vw',
                                                    }}
                                                    keyboardType = "numeric"
                                                    editable={is_editable}
                                                    value={title || ""}
                                                    onChangeText={(text) => { this._updateBillState(stateKey, text, type, index) }}
                                                /></View>

                                        </View>
                                        : stateKey === "net_total_amount" ?
                                            <View style={{ flexDirection: "row", justifyContent: "flex-start", maxWidth: is_initiate_return && billRows[index]['id'] ? 120 : 60 }}>
                                                {is_initiate_return ? <View style={{ flex: 0.5 }}>
                                                    <TextInput
                                                        style={{
                                                            // flex: 1,
                                                            height: '2vw', // 35
                                                            padding: 6,
                                                            marginRight: 5,
                                                            backgroundColor: color.tableHeaderBG,
                                                            borderColor: "#CDD1D5",
                                                            borderRadius: 4,
                                                            borderWidth: 1,
                                                            fontSize: '0.71vw'
                                                        }}
                                                        editable={false}
                                                        value={billRows[index]['net_return_total_amount'] || ""}
                                                    /></View> : null}

                                                <View style={{ flex: is_initiate_return && billRows[index]['id'] ? 0.5 : 1 }}>
                                                    <TextInput
                                                        style={{
                                                            // flex: 1,
                                                            height: '2vw', // 35
                                                            padding: 6,
                                                            marginRight: 5,
                                                            backgroundColor: color.tableHeaderBG,
                                                            borderColor: "#CDD1D5",
                                                            borderRadius: 4,
                                                            borderWidth: 1,
                                                            fontSize: '0.71vw'
                                                        }}
                                                        editable={false}
                                                        value={title || ""}
                                                    /></View>

                                            </View>
                                            : (<LineItemTextBox
                                                disable={!is_editable || (is_editable && (stateKey === "hsn_code" || stateKey === "quantity" || (stateKey === "discount_percentage" && this.state.is_discount_edittable)) ? false : true)}
                                                value={title}
                                                stateKey={stateKey}
                                                type={type}
                                                index={index}
                                                keyboardType = {stateKey == "discount_percentage" ? "numeric" : null}
                                                onChangeText={this._updateBillState} />)

                }
            </View>)
    }

    _handleItemRemove(i) {

        let { billRows, suggestions, data } = this.state;

        billRows = billRows.slice(0, i).concat(billRows.slice(i + 1, billRows.length))
        // clear suggestion
        suggestions = suggestions.slice(0, i).concat(suggestions.slice(i + 1, suggestions.length))

        this.setState({ billRows: billRows, suggestions: suggestions }, () => {

            data = this._drugListTaxableAmountUpdate(billRows, data)

            data = this._updateRoundOff(data)

            this.setState({
                data: data
            }, () => {
                this.props.discardChanges("", this.state.is_complete_bill)
                this._updateBillAmounts()
            })
        })

    }

    _updateBatchPopup = (billRows, index = '') => {

        let len = billRows.length || 0;

        for (let i = 0; i < len; i++) {

            if (i == index) {
                billRows[i]['showPickerDropDownFlag'] = true;
            } else {
                billRows[i]['showPickerDropDownFlag'] = false;
            }
        }
        return billRows;
    }

    _handleDrugBatchSelection = (type, index, stateKey) => {

        let { is_editable, billRows } = this.state;
        let zIndex = (index + 1) * (-10);
        let title = billRows[index][stateKey] || '';

        // only for batch
        title = stateKey === 'batch_no' && !title ? 'select' : title;

        return (
            <View style={{ zIndex: zIndex }}>
                <TouchableOpacity onPress={() => {

                    if (is_editable) {
                        // billRows[index].showPickerDropDownFlag = true; 
                        billRows = this._updateBatchPopup(billRows, index)
                         this.setState({
                            billRows,
                            typedIndex: index
                        }, () => {
                            this._getBatchDetailsbyDrugId(index)
                        })
                    }
                }
                }>
                    <TextInput
                        style={{
                            // width: "100%",
                            // flex: 1,
                            height: '2vw',
                            padding: 6,
                            marginRight: 15,
                            backgroundColor: is_editable ? color.white : color.tableHeaderBG,
                            borderColor: "#CDD1D5",
                            borderRadius: 4,
                            borderWidth: 1,
                            fontSize: '0.71vw'
                        }}
                        value={title}
                        onChangeText={(text) => {
                            // this._updateBillState(text, stateKey, type, index);
                        }}
                        editable={is_editable}
                    ></TextInput>
                </TouchableOpacity>
                {type === 'select' && billRows[index].showPickerDropDownFlag && is_editable ?
                    <DrugBatchDetails
                        closePopup={this.closePopup.bind(this, index)}
                        selectBatch={this._selectBatchFromPopup.bind(this)}
                        data={billRows[index].availBatchDatas || []} /> : null}
            </View>
        )
    }

    _getBatchDetailsbyDrugId = (index) => {

        let { billRows } = this.state;
        let drug_id = billRows[index].drug_id || null;
        let drug_name = billRows[index].brand_name || null;
        let dosage_type = billRows[index].dosage_type || null;
        let dosage_strength = billRows[index].dosage_strength || null;

        if (drug_name) {
            dosage_strength = dosage_strength == null ?  null : dosage_strength.replaceAll("+","%2B")
            //let url = Constants.BILL_DRUG_DETAILS_BY_DRUGID + "?drug_id=" + drug_id;
            let url = Constants.BILL_DRUG_DETAILS_BY_DRUGID + "?drug_name=" + drug_name + "&dosage_type=" + dosage_type + "&dosage_strength=" + dosage_strength;

            this.service.getApiCall(
                url,
                this._getBatchSuccess,
                this._getDrugFailure,
                "",
                index,
            )

        }

        // billRows[index].showPickerDropDownFlag = true;
        // this.setState({
        //     billRows
        // })
    }

    _getBatchSuccess =( response, type, index )=> {

        let { billRows } = this.state;

        if (response.status === "success") {

            let parsedData = JSON.parse(JSON.stringify(response.data))

            billRows[index]['availBatchDatas'] = parsedData;
            billRows[index]["batch_no"] = parsedData[0].batch_no;
            billRows[index]["expiry_date"] = parsedData[0].expiry_date;
            billRows[index]["cost_per_quantity"] = parsedData[0].cost_per_quantity;
            billRows[index]["physical_box_no"] = parsedData[0].physical_box_no;
            billRows[index]["stock_in_quantity"] = parsedData[0].stock_in_quantity;
            billRows[index]["drug_id"] = parsedData[0].drug_id;
            billRows[index]["schedule"] = parsedData[0].schedule;

            // set hsn code
            if (parsedData && parsedData.length > 0) {
                if (parsedData[0]['hsn_code']) {
                    billRows[index]['hsn_code'] = parsedData[0]['hsn_code']
                    billRows[index]['schedule'] = parsedData[0]['schedule']
                }
            }
            let total_cost = 0;
            let cost = parseFloat(billRows[index]["cost_per_quantity"]) || 0;
            let qty = parseInt(billRows[index]["quantity"]) || 0;    //parseInt(value);

            // total
            total_cost = parseFloat((cost * qty).toFixed(2)) || 0;

            // discount
            let discount_percentage = parseFloat(billRows[index]["discount_percentage"]) || 0;    // parseFloat(value) || 0;
            let discount_amount = parseFloat((total_cost * discount_percentage / 100).toFixed(2)) || 0;
            // for calc purpose 
            billRows[index]["discount_amount"] = discount_amount
            billRows[index]["discount_amount_return"] = 0

            // assign
            billRows[index]['total_cost'] = total_cost
            billRows[index]['net_total_amount'] = (total_cost - discount_amount).toFixed(2);

            this.setState({ billRows })
        }
        
    };

    _handleDrugSuggestion = (type, index, key) => {

        let { is_editable, typedIndex, billRows, suggestions } = this.state;

        typedIndex = typedIndex ? typedIndex : 0;

        let value = billRows[index]['brand_name'];
        let isSelected = billRows[index]['isDrugSelected'];
        let drugSuggestionList = suggestions[typedIndex] ? suggestions[typedIndex]['drugSuggestionList'] : [];
        let drugDetailsSuggestionList = suggestions[typedIndex] ? suggestions[typedIndex]['drugDetailsSuggestionList'] : [];
        
        // for transmitted alone
        let is_transmitted_drug = billRows[index] ? (billRows[index]['is_transmitted_drug'] ? billRows[index]['is_transmitted_drug'] : false) : false

        return (
            <View style={{ flexDirection: "column" }}>
                <TextInput
                    style={{
                        // flex: 1,s
                        height: '2vw',
                        padding: '0.39vw',
                        marginRight: '0.97vw',
                        backgroundColor: is_editable && !is_transmitted_drug && !billRows[index]['schedule'] ? color.white : billRows[index]['schedule'] ? color.maroon: color.tableHeaderBG,
                        borderColor: billRows[index]['schedule'] ? color.magenda : "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        fontSize: '0.71vw',
                        zIndex: -100,
                        color:billRows[index]['schedule'] ? color.magenda : color.darkBlack
                    }}
                    // disable={!is_editable }
                    value={value}
                    onChangeText={(text) => {
                      
                        // this.updateState(text, key,fieldName);
                       if(text != "") { 
                        billRows[index]['brand_name'] = text;
                        billRows[index]['isDrugSelected'] = false;

                        billRows = this._updateBatchPopup(billRows, 'false')

                        this.setState({
                            typedIndex: index,
                            billRows
                        }, () => {
                            this.props.discardChanges(this.state.billRows[index]['brand_name'], this.state.is_complete_bill)
                            this._getDrugSuggestion(text)
                        })
                      } else {
                        billRows[index]['brand_name'] = text;
                        billRows[index]["dosage_type"] = "";
                        billRows[index]["dosage_strength"] = "";
                        billRows[index]["batch_no"] = "";
                        billRows[index]["expiry_date"] = "";
                        billRows[index]["cost_per_quantity"] = "";
                        billRows[index]["schedule"] = "";
                        suggestions[index]['drugSuggestionList'] = []
                        suggestions[index]['drugDetailsSuggestionList'] = []
                        billRows[index]['physical_box_no'] = ""
                        billRows[index]['quantity'] = ""
                        billRows[index]['total_cost'] = ""
                        billRows[index]['discount_percentage'] = ""
                        billRows[index]['net_total_amount'] = ""
                        this.setState({
                            billRows,
                            suggestions
                        }, () => {
                            this.props.discardChanges(this.state.billRows[index]['brand_name'], this.state.is_complete_bill)
                            this._calculateReturnBill('return_quantity', '', '', index)
                        })

                      }
                    }}
                    editable={is_editable && !is_transmitted_drug}
                ></TextInput>
                {/* </TouchableOpacity> */}
                {/* {typedIndex===index && !isSelected && (drugSuggestionList && drugSuggestionList.length>0)? */}
                {typedIndex === index && !isSelected && (drugDetailsSuggestionList && drugDetailsSuggestionList.length > 0) ?
                    <View style={{

                        // maxHeight: this.state.newItem ? "" : 0,
                        borderRadius: 10,
                        width: '19.5vw', //'90%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        padding: '0.65vw',
                        marginLeft: '0.5vw',
                        marginTop: '2.2vw',
                        position: 'absolute',
                        backgroundColor: color.themeBlue
                    }}>
                        <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: screenHeight - (400 + index * 50) }}>
                            <FlatList
                                data={drugDetailsSuggestionList}
                                renderItem={({ item }) => (
                                    <View style={{
                                        flexDirection: "row",
                                        bottom: '0.65vw',
                                        marginBottom: '0.65vw',
                                        justifyContent: "space-evenly",
                                        width: "100%",
                                        alignItems: "center",
                                        marginTop: '0.65vw',
                                       
                                    }}
                                    >
                                        <View style={{ width: '100%' }}>
                                            <TouchableOpacity
                                                onPress={() => {

                                                    if (item.in_stock !== undefined && item.in_stock == 0) {
                                                    } else {
                                                        /*
                                                        // var data = {"label":item,"value":item}
                                                        billRows[index]['brand_name']=item;
                                                        billRows[index]['isDrugSelected']=true;
                                                        
                                                        this.setState({ 
                                                            billRows,
                                                            // drugSuggestionList: []
                                                        }, ()=>{
                                                            this._getDrugDetails(item)
                                                        })
                                                        */

                                                        billRows[index]['brand_name'] = item.drug_name;
                                                        billRows[index]['isDrugSelected'] = true;
                                                        billRows[index]['drug_id'] = item.drug_id;
                                                        billRows[index]['dosage_type'] = item.dosage_type;
                                                        billRows[index]['dosage_strength'] = item.dosage_strength;
                                                        // other popup close, current open
                                                        // billRows = this._updateBatchPopup(billRows, index)

                                                        // update suggestion
                                                        suggestions[index]["dosageSuggestionList"] = []
                                                        suggestions[index]["strengthSuggestionList"] = []

                                                        suggestions[index]["dosageSuggestionList"].push(item.dosage_type)
                                                        suggestions[index]["strengthSuggestionList"].push(item.dosage_strength)

                                                        // clear batch
                                                        // billRows[index]['availBatchDatas'] = []

                                                        this.setState({
                                                            billRows,
                                                            suggestions
                                                        }, () => {
                                                            this._getBatchDetailsbyDrugId(index)
                                                        })
                                                    }
                                                }}
                                            >
                                                <Text style={{ color: item.in_stock !== undefined && item.in_stock == 0 ? color.labelColor : color.white,  fontSize:'0.91vw' }}>{item.display_name}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )}
                                enableEmptySections={true}
                            />
                        </ScrollView>
                    </View> : null}
            </View>
        )
    }

    _getDrugSuggestion = (searchKey) => {

        let { suggestions, typedIndex } = this.state;
        let drugSuggestionList = suggestions[typedIndex]['drugSuggestionList'] || [];
        let drugName = searchKey;

        if (drugName !== "") {

            let sameInList = false;
            let isEnableDrugList = false;
            let len = drugSuggestionList.length;

            for (var i = 0; i < len; i++) {

                let value = drugSuggestionList[i];

                if (drugName == value) {
                    sameInList = true;
                    return;
                }
            }

            if (sameInList) {

                isEnableDrugList = true;

                // exist, so no need call service
                // this._getDrugDetails(drugName);

            } else {

                if (drugName.length >= 2) {
                    // let url = Constants.SEARCH_DRUG + "?brand_name=" + drugName;
                    let url = Constants.BILL_DRUG_SEARCH + "?search=" + drugName;

                    this.service.getApiCall(
                        url,
                        this._getDrugSuccess,
                        this._getDrugFailure
                    )
                }
            }

        } else {
            // clear suggestion
            suggestions[typedIndex]['drugSuggestionList'] = []
            suggestions[typedIndex]['drugDetailsSuggestionList'] = []

            this.setState({
                suggestions
            })
        }
    }

    _getDrugSuccess = response => {

        let { typedIndex, suggestions } = this.state;

        if (response.status === "success") {

            if(response.message === "Kindly Enable Search Configuration"){
                this.setState({ 
                    responseMessage : response.message,
                    showErrorResponse : true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            showSuccessResponse: false,
                            showErrorResponse: false,
                            responseMessage: ""
                        })
                    }, 2000)
                })
            }

            let data = JSON.parse(JSON.stringify(response.data == undefined ? "" : response.data));

            // suggestions[typedIndex]['drugSuggestionList'] = data;
            // this.setState({ suggestions })

            // new bill flow data format
            let tempSuggestData = []

            data && data.map((item, index) => {
                return tempSuggestData.push(item.display_name)
            })

            suggestions[typedIndex]['drugDetailsSuggestionList'] = data;
            suggestions[typedIndex]['drugSuggestionList'] = tempSuggestData;

            this.setState({ suggestions })
        }
    };

    _getDrugFailure = error => {

        console.log("GET error response");
        console.log(error);

    };

    _getDrugDetails = (drugName) => {

        if (drugName !== "") {

            this.setState({ isDrugSelected: true })

            let url = Constants.BILL_DRUG_DETAILS_BY_DRUGNAME + "?brand_name=" + drugName;

            this.service.getApiCall(
                url,
                this._getDrugDetailsSuccess,
                this._getDrugFailure
            )

        } else {

            this.setState({
                drugDetailsSuggestionList: []
            });
        }
    }

    _getDrugDetailsSuccess = response => {

        if (response.status === "success") {

            // response list - obj format
            // {
            //     "drug_id":1662,
            //     "generic_name":"CROCIN",
            //     "dosage_strength":"120 ml",
            //     "dosage_type_id":5,
            //     "dosage_type":"Suspension"
            // }

            // {
            //     "drug_id": 1,
            //     "drug_strength": "50mg",
            //     "dosage_type": "Tablet"
            // },

            var { billRows, typedIndex, suggestions } = this.state;
            let strength_unique_name = [];
            let list = JSON.parse(JSON.stringify(response.data));
            let dataLen = list.length;

            // if have only one, this will be default
            if (dataLen === 1) {

                billRows[typedIndex]['drug_id'] = list[0]['drug_id'];
                billRows[typedIndex]['dosage_type'] = list[0]['dosage_type'];
                billRows[typedIndex]['dosage_strength'] = list[0]['drug_strength'];
            }

            for (var i = 0; i < dataLen; i++) {

                let value = list[i].drug_strength;

                if (!strength_unique_name.includes(value)) {

                    strength_unique_name.push(value)
                }
            }

            suggestions[typedIndex]['strengthSuggestionList'] = strength_unique_name;
            suggestions[typedIndex]['drugDetailsSuggestionList'] = list;

            // update
            this.setState({

                suggestions,
                billRows
            });
        }
    };

    _handleDrugDetailSuggestion = (type, index, key) => {

        let { is_editable, typedIndex, typedKey, suggestions, billRows, drugDosageTypes } = this.state;

        typedIndex = typedIndex ? typedIndex : 0;

        let value = null;
        let suggestData = [];
        let isSelected = true;
        let strengthSuggestionList = suggestions[typedIndex] ? suggestions[typedIndex]['strengthSuggestionList'] : null;

        drugDosageTypes = suggestions[typedIndex] ? suggestions[typedIndex]['dosageSuggestionList'] : [];
        
        if (key === 'dosage_type') {
            value = billRows[index]['dosage_type'] || billRows[index]['dosage_type_id'];
            isSelected = billRows[index]['isDosageSelected'];
            suggestData = drugDosageTypes;
        }
        if (key === 'dosage_strength') {
            value = billRows[index]['dosage_strength'];
            isSelected = billRows[index]['isStrengthSelected'];
            suggestData = strengthSuggestionList;
        }
     
        if (value == undefined || value == null) {

            value = ""

        } else {
            value = value.toString()
        }

        // for transmitted alone
        let is_transmitted_drug = billRows[index] ? (billRows[index]['is_transmitted_drug'] ? billRows[index]['is_transmitted_drug'] : false) : false

        return (
            <View style={{ flexDirection: "column" }}>
                {/* <TouchableOpacity onPress={()=>{}} > */}
                <TextInput
                    style={{
                        // flex: 1,
                        height: '2vw',
                        padding: 6,
                        marginRight: 15,
                        backgroundColor: is_editable && !is_transmitted_drug ? color.white : color.tableHeaderBG,
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        fontSize: '0.71vw',
                        zIndex: -10
                    }}
                    disabled = {true} 
                    value={value}
                    onChangeText={(text) => {

                        // this.updateState(text, key,fieldName);

                        billRows = this._updateBatchPopup(billRows, 'false')

                        if (key === 'dosage_type') {
                            billRows[index]['dosage_type'] = text;
                            billRows[index]['isDosageSelected'] = false;
                        }
                        if (key === 'dosage_strength') {
                            billRows[index]['dosage_strength'] = text;
                            billRows[index]['isStrengthSelected'] = false;
                        }

                        this.setState({
                            typedKey: key,
                            typedIndex: index,
                            billRows
                        }, () => {

                            // this._handleDosageType(text)
                        })

                    }}
                    editable={is_editable && !is_transmitted_drug}
                ></TextInput>
                {/* </TouchableOpacity> */}
                {typedIndex === index && typedKey === key && !isSelected && (suggestData && suggestData.length > 0) ?
                    <View style={{

                        // maxHeight: this.state.newItem ? "" : 0,
                        borderRadius: 10,
                        width: '90%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        padding: 10,
                        marginLeft: 10,
                        marginTop: 30,
                        position: 'absolute',
                        backgroundColor: color.themeBlue
                    }}>
                        <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                            <FlatList
                                data={suggestData}
                                renderItem={({ item }) => (
                                    <View style={{
                                        flexDirection: "row",
                                        bottom: 10,
                                        marginBottom: 10,
                                        justifyContent: "space-evenly",
                                        width: "100%",
                                        alignItems: "center",
                                        marginTop: 10
                                    }}
                                    >
                                        <View style={{ width: '100%' }}>
                                            <TouchableOpacity
                                                onPress={() => {

                                                    // type==='select_generic'?billRows[index]['generic_name']=item.generic_name:billRows[index]['dosage_type']=item;

                                                    if (key === 'dosage_type') {
                                                        billRows[index]['dosage_type'] = item;
                                                        billRows[index]['isDosageSelected'] = true;
                                                    }
                                                    if (key === 'dosage_strength') {
                                                        billRows[index]['dosage_strength'] = item;
                                                        billRows[index]['isStrengthSelected'] = true;
                                                    }

                                                    this.setState({
                                                        billRows
                                                    }, () => {

                                                        if (key === 'dosage_strength') {

                                                            this._onChangeStrength(typedIndex, item)
                                                        }
                                                        if (key === 'dosage_type') {

                                                            this._setDrugId()
                                                        }
                                                    })
                                                }}
                                            >
                                                <Text style={{ color: color.white }}>
                                                    {/* {key==='generic_name'?item.generic_name:item} */}
                                                    {item}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )}
                                enableEmptySections={true}
                            />
                        </ScrollView>
                    </View> : null}
            </View>
        )
    }

    _onChangeStrength = (index, value) => {

        let { suggestions, billRows } = this.state;
        let drugList = suggestions[index]['drugDetailsSuggestionList'];
        let dosageData = [];  //suggestions[index]['strengthSuggestionList'];
        let currentRowData = billRows[index];

        if (currentRowData) {

            // let cDrug = billRows[index].brand_name;
            let cStrength = billRows[index].drug_strength;

            for (let i = 0; i < drugList.length; i++) {

                if (drugList[i].dosage_strength === cStrength) {
                    let dosage_type = drugList[i].dosage_type;

                    if (!dosageData.includes(dosage_type)) {

                        dosageData.push(dosage_type)
                    }
                }
            }

            // strengthData.sort(function(a, b){ return a-b})

            suggestions[index]['dosageSuggestionList'] = dosageData;

            this.setState({
                suggestions
            });
        }
    }

    _setDrugId() {

        let { suggestions, billRows, typedIndex } = this.state;

        let drugList = suggestions[typedIndex]['drugDetailsSuggestionList'];
        let currentRowData = billRows[typedIndex];
        let drug_id = "";

        if (currentRowData) {

            // let cDrug = billRows[index].brand_name;
            // let cStrength = billRows[index].dosage_strength;

            let isFirstSet = false;

            for (let i = 0; i < drugList.length; i++) {

                if (
                    drugList[i].drug_strength === billRows[typedIndex].dosage_strength
                    && drugList[i].dosage_type === billRows[typedIndex].dosage_type) {

                    if (!isFirstSet) {

                        drug_id = drugList[i].drug_id;
                        isFirstSet = true;
                    }
                }
            }

            // strengthData.sort(function(a, b){ return a-b})

            billRows[typedIndex]['drug_id'] = drug_id;

            this.setState({
                billRows
            });
        }
    }

    closePopup(index) {

        let { billRows } = this.state;
        if (billRows[index]) {

            billRows[index].showPickerDropDownFlag = false;
            this.setState({
                billRows
            })
        }
    }

    _selectBatchFromPopup(item) {


        let { billRows, typedIndex } = this.state;

        if (billRows[typedIndex]) {

            billRows[typedIndex].showPickerDropDownFlag = false;
            billRows[typedIndex]['batch_no'] = item['batch_no'];
            billRows[typedIndex]['drug_id'] = item['drug_id'];
            // excess
            billRows[typedIndex]['batch_details'] = item['batch_no'] + " - " + item['stock_in_quantity'];
            billRows[typedIndex]['stock_in_quantity'] = item['stock_in_quantity'];
            // display data
            billRows[typedIndex]['expiry_date'] = item['expiry_date'];
            billRows[typedIndex]['physical_box_no'] = item['physical_box_no'];
            billRows[typedIndex]['cost_per_quantity'] = item['cost_per_quantity'];

            billRows[typedIndex]['hsn_code'] = item['hsn_code']
            billRows[typedIndex]['schedule'] = item['schedule']
            
            this.setState({
                billRows,
                totalAvailableQuantity: item.stock_in_quantity
            }, () => {
                this._updateBillState('batch_no', item['batch_no'], '', typedIndex)
                this._updateBillAmounts()
            })
        }
    }
    renderBillTypeTextBoxRow(label, key, value) {
            return (
              <View style={{ marginVertical: '0.52vw', marginRight: '0.97vw' }}>
                <View style={{ flex: 0.4, justifyContent: "center" }}>
                  <Text style={{ fontSize: '0.78vw', fontWeight: "500", marginBottom: '0.65vw' }}>{label}</Text>
                </View>
                <View style={{}}>
                  {
                    key ?
                      <TextInput
                       style={{ textAlign: 'center', height: '1.82vw', width:  key == "company_name" ? '1.30vw' : '5.86vw', borderRadius: 4, borderWidth: 1, borderColor: "#91939F" }}
                       value={value}
                        onChangeText={(text) => {
                          if (key != "") {
                            var { data } = this.state;
                            data[key] = text;
                            this.setState({
                             data
                            })
                          }
                        }}
                      />
                      : null
                  }
        
                </View>
        
             </View>
            )
          }
         
            renderBillTypeView=() =>{
                var listOfBillType = [
                 { label: "Corporate", value: "Corporate" },
                 { label: "Credit", value: "Credit" }
                ]
                let {data} = this.state
                return (
                  <View style={{ flex: 1, flexDirection: "column" }}>
                    <View>
                    <Text style={{fontWeight:"500",fontSize:'1.43vw'}}>{"Bill Type"}</Text>
                    </View>
                    <View style={[styles.emed_content_container]}>
                      {
                       listOfBillType.map((item, index) => {
                          return (
                            <TouchableOpacity onPress={() => {
                                if( data.bill_type === item.value) {
                                    data['bill_type'] = ""
                                    this.setState({ 
                                        data,
                                        is_bill_type_disable : false
                                    })
                                } else {
                                   data["bill_type"] = item.value
                                   this.setState({
                                     data,
                                     is_bill_type_disable : true
                                   })
                               }
                              }} style={[styles.emed_radio_btn_touchablearea]} key={index}>
                                <View style={[{
                                 backgroundColor: data.bill_type == item.value ? "green" : ""
                                }, styles.emed_radio_btn]}></View>
                                <Text style={[styles.emed_radio_btn_txt]}>{item.label}</Text>
                              </TouchableOpacity>
                           )
                        })
                     }
                    </View>
                    {data.bill_type == "Corporate" ? 
                      <View style={styles.billTypeTextBox}>
                        {this.renderBillTypeTextBoxRow("Company Name", "company_name", data.company_name)}
                        {this.renderBillTypeTextBoxRow("Employee ID", "employee_id", data.employee_id)}
                      </View>
                      :
                      null
                    }
                  </View>
                )
              }

    checkFloat = (num) => {
        return Number(num) === num && num % 1 != 0
    }  
    onFocus = () =>{
        this.setState({
            isOnFocus : true
        })
    }  
    onBlur = () =>{
        this.setState({
            isOnFocus : false
        })
    }
    onFocusPer = () =>{
        this.setState({
            isOnFocusPer : true
        })
    }  
    onBlurPer = () =>{
        this.setState({
            isOnFocusPer : false
        })
    } 
    getBillAmountDetails() {

        let { data, billRows, is_editable, is_initiate_return, is_complete_bill, bill_type } = this.state;

        let rowLen = billRows.length || 0;

        let zIndex = (rowLen + 1) * (-100);
        // data['net_total_amount'] = net_total_amount.toFixed(2)


        return (
            <View style={{ marginBottom: 10, zIndex: zIndex, marginTop: 15, maxHeight: '30vh' }}>
                <View style={{ flex: 1, flexDirection: "row-reverse" }}>
                    <View style={{ flex: 0.3 }}>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ flex: 0.6 }}>
                                {/* <Text>{"Sub Amount"}</Text> */}
                                <CommonLabelTextMView label={"Sub Amount"} />
                            </View>
                            <View style={{ flex: 0.4, flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                <Image
                                    style={{ width: '0.97vw', height: "0.65vw", marginRight: 5, tintColor: "grey" }}
                                    source={require('../../assets/Icon/Rupee.png')}
                                    resizeMode={"cover"}
                                />
                                <CommonLabelTextMView label={is_initiate_return ? data.taxable_return_amount : parseFloat(this.state.subAmount).toFixed(2)} align={'right'} padRight={40} />
                            </View>
                        </View>
                        { !this.state.toggleSwitchFlag  ?
                        <View style={{ flexDirection: "row", justifyContent: "center", alignContent: "center", marginVertical: '0.52vw' }}>
                            <View style={{ flex: 0.3, alignSelf: "center" }}>
                                {/* <Text>{"Discount %"}</Text> */}
                                <CommonLabelTextMView label={"Discount %"} />
                            </View>
                            <View style={{ flex: 0.3, alignSelf: "center" }}>

                                <TextInput
                                    style={{
                                        borderColor: "#CDD1D5",
                                        borderRadius: 4,
                                        borderWidth: 1,
                                        width: "80%",
                                        height: '1.62vw',
                                        paddingVertical: 3,
                                        paddingHorizontal: 8,
                                        // marginTop: 5 
                                        textAlign: "right",
                                        fontSize:'0.9vw'
                                    }}
                                    value={(this.checkFloat(parseFloat(data.general_discount_percentage)) && ! this.state.isOnFocusPer ) ? parseFloat(data.general_discount_percentage).toFixed(2) : parseFloat(data.general_discount_percentage)}
                                    editable={is_editable}
                                    onFocus={ ()=>{ this.onFocusPer()}}
                                    onBlur = {() => {this.onBlurPer()}}
                                    keyboardType = "numeric"
                                    onChangeText={(text) => {
                                        if (text.length < 3) {
                                            let { data } = this.state
                                            let taxable_amount = parseFloat(data.taxable_amount) || 0
                                            let general_discount_percentage = parseFloat(text) || 0
                                            data['general_discount_percentage'] = text
                                            data['general_discount'] = ((taxable_amount * general_discount_percentage) / 100 || 0)

                                            data = this._updateRoundOff(data)

                                            this.setState({
                                                data
                                            }, () => {
                                                this._updateBillAmounts()
                                                this._updateRoundOff(data)

                                            })
                                        } 
                                    }}
                                />
                            </View>
                            <View style={{ flex: 0.4, flexDirection: "row", justifyContent: "flex-end", alignSelf: "center", paddingRight: 40 }}>
                                {/* {this.getBillAmountColumns(data.general_discount_amount || 0)} */}
                                <Image
                                    style={{ width: '0.97vw', height: '0.65vw', marginRight: 5, tintColor: "grey" }}
                                    source={require('../../assets/Icon/Rupee.png')}
                                    resizeMode={"cover"}
                                />
                                <TextInput
                                    style={{
                                        borderColor: "#CDD1D5",
                                        borderRadius: 4,
                                        borderWidth: 1,
                                        width: "50%",
                                        height: '1.62vw',
                                        paddingVertical: 3,
                                        paddingHorizontal: 8,
                                        // marginTop: 5 
                                        textAlign: "right",
                                        fontSize:'0.9vw'
                                    }}
                                    keyboardType ="numeric"
                                    onFocus={ ()=>{ this.onFocus()}}
                                    onBlur = {() => {this.onBlur()}}
                                    value={(this.checkFloat(parseFloat(data.general_discount)) && !this.state.isOnFocus) ? parseFloat(data.general_discount).toFixed(2) : parseFloat(data.general_discount)}
                                    editable={is_editable}
                                    onChangeText={(text) => {
                                       if( parseFloat(text) > parseFloat(this.state.subAmount) ) { 
                                           this.setState({
                                             responseMessage: "The discount amount is greater than total amount",
                                             showErrorResponse: true,
                                             is_disable_action: false
                                           }, () => {
                                             this._clearNotification()
                                           })
                                       } else {
                                           let { data } = this.state
                                           let general_discount = parseFloat(text) || 0;
                                           // let general_discount_percent = 0;
                                           let taxable_amount = parseFloat(data['taxable_amount']) || 0;

                                           data['general_discount'] = text
                                           data['general_discount_percentage'] = ((general_discount * 100 / taxable_amount) || 0)

                                           data = this._updateRoundOff(data)

                                           this.setState({
                                               data
                                           }, () => {
                                               this._updateBillAmounts()
                                           })
                                       }
                                    }}
                                />
                            </View>

                        </View> : null }
                        { !this.state.toggleSwitchFlag  ?
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ flex: 0.6 }}>
                                {/* <Text>{"Round Off"}</Text> */}
                                <CommonLabelTextMView label={"Round Off"} />
                            </View>
                            {/* <View style={{ flex: 0.4 }}>
                                <Text>{data.round_off}</Text>
                            </View> */}
                            {this.getBillAmountColumns(data.round_off)}
                        </View> : null }
                        { !this.state.toggleSwitchFlag  ?
                        <View style={{ flexDirection: "row", marginTop:10 }}>
                            <View style={{ flex: 0.6 }}>
                                {/* <Text>{"Net Amount"}</Text> */}
                                <CommonLabelTextMView label={"Net Amount"} />
                            </View>
                            {/* <View style={{ flex: 0.4}}>
                                <Text>{data.grand_total}</Text>
                            </View> */}
                            {this.getBillAmountColumns(is_initiate_return ? "-" + data.grand_return_total : data.grand_total)}
                        </View> : null }
                    </View>
                    {/* <View style={{ flex: 1, flexDirection: "row-reverse", marginTop: 20 }}>
                    <View style={{ flex: 0.3 }}>
                        
                    </View>
                </View> */}
                {!this.state.toggleSwitchFlag?
                    <View style={{ flex: 0.7, marginRight: '0.97vw', marginTop: '0.65vw' }}>
                        {/* <View style={{marginLeft:100}}>
                        {this.renderBillTypeView()}
                       </View> */}
                        <Text style={{fontWeight:"500",fontSize:'1.43vw',marginLeft:'3.5vw'}}>{"Payment Type"}</Text>
                        <View style={{ flexDirection: "row", marginLeft: '3vw' }}>
                            { is_initiate_return ? null :
                               <View style={{ marginRight: '0.97vw' , alignItems:'center'}}>{this.getCashTypes("Card", "2")}</View>
                            }
                            <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("Cash", "1")}</View>
                            { is_initiate_return  ? null :
                              <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("Card & Cash", "3")}</View> 
                            }
                            <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("UPI", "4")}</View>
                            <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("Bank Transfer", "5")}</View>
                            { is_initiate_return  ? null :
                              <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("Insurance", "6")}</View> 
                            }

                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "flex-start", marginLeft: '3.5vw',flex:1, marginTop:'1.3vw' }}>
                            {data.payment_mode == "2" || data.payment_mode == "3" ?
                                <View style={{}}>{this.getCashBalance("Card Number", "card_number", data.card_number, !is_editable, "100%")}</View>
                                : null}
                            <View style={{}}>{this.getCashBalance("Total", "grand_total", data.grand_total, true)}</View>

                            {data.payment_mode == "1" ? <View style={{}}>{this.getCashBalance("Received Amount", "received_amount", this.state.is_complete_bill ? Math.round(data.received_amount) : data.received_amount, is_initiate_return ? true : !is_editable, "100%")}</View> : null}
                            {data.payment_mode == "1" ? <View style={{}}>{this.getCashBalance("Balance", "balance_amount", data.balance_amount || 0, true, "100%")}</View> : null}

                            {data.payment_mode == "3" ? <View style={{}}>{this.getCashBalance("Card", "card_amount", data.card_amount, !is_editable, "100%")}</View> : null}
                            {data.payment_mode == "3" ? <View style={{}}>{this.getCashBalance("Cash", "cash_amount", data.cash_amount, !is_editable, "100%")}</View> : null}

                            {data.payment_mode == "4" ? <View style={{}}>{this.getCashBalance("Transaction ID", "transaction_id", data.transaction_id, is_initiate_return ? false : !is_editable, "100%")}</View> : null}
                            {data.payment_mode == "5" ? <View style={{}}>{this.getCashBalance("Transaction ID", "transaction_id", data.transaction_id, is_initiate_return ? false : !is_editable, "100%")}</View> : null}

                            {data.payment_mode == "6" && !is_initiate_return ? <View style={{flex:0.32}}>{this.getCashBalance("Company Name", "insurance_company_name", data.insurance_company_name, false, '13vw')}</View> : null}
                            {data.payment_mode == "6" && !is_initiate_return ? <View style={{flex:0.2}}>{this.getCashBalance("Policy Number", "policy_number", data.policy_number, !is_editable, "100%")}</View> : null}
                            {/* {data.payment_mode == "6" ? <View style={{flex:0.18}}>{this.getCashBalance("Validity", "validity", data.validity, !is_editable, "100%")}</View> : null} */}
                            {data.payment_mode == "6" && !is_initiate_return ? <View style={{flex:0.32}}>{this.getCashBalance("Transaction Number", "transaction_number", data.transaction_number, !is_editable, "100%")}</View> : null}
                        </View>
                    </View>: null
                }
                </View>
                <View style={{ marginVertical: '0.32vw', }}></View>
                
            </View>
        )
    }

    getBillAmountColumns(amt) {
        return (
            <View style={{ flex: 0.4, flexDirection: "row", justifyContent: "flex-end", alignContent: "center", alignItems: "center" }}>
                <Image
                    style={{ width: '0.97vw', height: '0.65vw', marginRight: 5, tintColor: "grey" }}
                    source={require('../../assets/Icon/Rupee.png')}
                    resizeMode={"cover"}
                />
                <Text style={{ textAlign: "right", paddingRight: 40 , fontSize:'1vw'}}>{amt}</Text>
            </View>
        )
    }

    getCashTypes(typeLabel, value) {
        let { is_editable, data, is_bill_type_disable, is_return_editable } = this.state;
        return (
            <View style={{flexDirection: "row", justifyContent: "flex-start", alignContent: "center"}}>
                <RadioButton
                style={{height: '1.3vw', width:'1.3vw',flex:0.2 }}
                    disabled = { is_bill_type_disable ? true : false }
                    value={typeLabel}
                    color="green"
                    status={data.payment_mode == value ? 'checked' : 'unchecked'}
                    onPress={() => {

                        if (is_editable || is_return_editable) {

                            data['payment_mode'] = value;

                            // clear data
                            data['received_amount'] = 0
                            data['card_number'] = ""
                            data['card_amount'] = 0
                            data['cash_amount'] = 0

                            data = this._updateRoundOff(data);

                            this.setState({ data }, () => {
                                this._updateBillAmounts()
                            })
                        }
                    }}
                />
                {/* <Text style={{ paddingTop: 10 }}>{typeLabel}</Text> */}
                <View style={{alignItems:'center', display:'flex', justifyContent:'center'}}><CommonLabelTextView label={typeLabel} /></View>
            </View>
        )
    }

    getCashBalance(subLabel, stateName, value, isDisable = false, textBoxWidth) {
        let { data, is_bill_type_disable } = this.state
        return (
            <View style={{
                flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start",
                marginHorizontal: "0.65vw", width: '6.51vw'
            }}>

                {this.getBillTableTitle(subLabel, 1)}
                {isDisable ?
                    <Text style={[Style.commonTextBoxStyles, {
                        zIndex: -3,
                        color: color.labelColor, padding: 3, height: '1.95vw', marginTop: 5, fontSize:'0.91vw'
                    }]} >
                        {value}
                    </Text>
                    : <TextInput
                        style={{
                            borderColor: "#CDD1D5",
                            borderRadius: 4,
                            borderWidth: 1,
                            width: textBoxWidth,
                            height: '1.95vw',
                            padding: 5,
                            marginTop: 5,
                            fontSize:'0.91vw'
                        }} value={value}
                        keyboardType = {data.payment_mode == 1 || data.payment_mode == 3 || stateName == "card_number" ? "numeric" : null}
                        // editable = { is_bill_type_disable  ? false : true }
                        onChangeText={(text) => {
                            this._updateState2(stateName, text, '')
                        }}
                    />}
            </View>
        )
    }
    _savedBillPrint() {
        let { data } = this.state;

        // call save API
        let url = "";

        if (data.bill_summary_id) {

            url = Constants.PHARMACY_BILLING + "?id=" + data.bill_summary_id + "&export=pdf";

            this.service.getInstance().documentUploadGet(
                url,
                this._printSuccessCallBack,
                this.errorCallBack,
                "pdf"
            );
        } else {
            console.log(" summary id not available")
        }

    }

    _printSuccessCallBack = (response) => {

        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            // console.log(file)
            window.open(fileURL);
        }
    }


    getBillActions() {

        let { data, is_editable, is_complete_bill, is_patient_details_editable, is_disable_action, is_initiate_return, bill_type, is_complete_disable_action, is_bill_type_disable } = this.state;
        if(!this.props.selectedBillToView &&!this.props.selectedSavedBill){
            data = JSON.parse(JSON.stringify(tempData))
            is_editable = true;
            is_complete_bill = this.state.is_complete_bill;
            is_patient_details_editable = true;
            // is_disable_action = false;
            is_initiate_return = false;
            bill_type = "";
            is_complete_disable_action = false;
        }else{
            console.log("HI THIS IS NOT NEW BILL....");
        }
        return (
            <View style={{ flexDirection: "row", marginBottom: 30, maxHeight: '10vh' }}>
                <View style={{ flex: 0.3 }}>

                </View>
                {!this.state.toggleSwitchFlag?
                <View style={{ flex: 0.4, flexDirection: "row", justifyContent: "center", alignContent: "center" }}>
                    {/* <CommonButton /> */}
                    <View>
                        {data.bill_summary_id || is_complete_bill || !is_patient_details_editable ?
                            <ActionButton label={"Next Bill"} keyIndex={"nextBill"}
                                source={require('../../assets/Icon/NextBill.png')}
                                onPressAction={this._clearBill.bind(this, false)} /> : null}
                    </View>
                    <View>
                        {is_editable && !is_complete_bill ?
                            <ActionButton label={"Save"} keyIndex={"saveBill"}
                                isDisable={is_disable_action}
                                imgPath={require("../../assets/Icon/SavedBills.png")}
                                onPressAction={this._createBill.bind(this, false)} />
                            : null}
                    </View>
                    <View>
                        {is_complete_bill && !is_initiate_return && ( bill_type == "Bill" || bill_type == "Credit") ?
                            <ActionButton label={"Return"} keyIndex={"returnBill"}
                                imgPath={require("../../assets/Icon/Return.png")}
                                onPressAction={this._returnBill.bind(this)}
                            />
                            : null}
                    </View>
                    <View>
                        {(is_complete_bill && is_initiate_return) ?
                            <ActionButton label={"Save"} keyIndex={"saveReturnBill"}
                                imgPath={require("../../assets/Icon/SavedBills.png")}
                                isDisable={is_disable_action}
                                onPressAction={this._saveReturnBill.bind(this)}
                            />
                            : null}
                    </View>
                    <View>
                        {is_complete_bill ?
                            <ActionButton label={"Print"} keyIndex={"printBill"}
                                imgPath={require("../../assets/Icon/Print.png")}
                                onPressAction={this._printBill.bind(this)}
                            />
                            : null}
                    </View>
                    <View>
                        {(data.bill_summary_id) && !is_complete_bill && this.props.selectedSavedBill ?
                            <ActionButton label={"Print"} keyIndex={"savedBillPrint"}
                                imgPath={require("../../assets/Icon/Print.png")}
                                onPressAction={this._savedBillPrint.bind(this)} /> : null}
                    </View>
                    {!is_complete_bill ?
                        <View>
                            <ActionButton label={"Payment Received"} keyIndex={"createBill"}
                                // isDisable={!is_editable}  
                                isDisable={is_disable_action || is_complete_disable_action || is_bill_type_disable}
                                onPressAction={this._createBill.bind(this, true)}
                                imgPath={require("../../assets/Icon/Rupee.png")} />
                        </View> : null}
                </View>: 
                <View style={{ flex: 0.4,flexDirection:'row',justifyContent:"center", alignContent: "center" }}>
                <ActionButton label={"Add to Credit"} keyIndex={"saveBill"}
                                isDisable={is_disable_action}
                                imgPath={require("../../assets/Icon/SavedBills.png")}
                                onPressAction={this._createBill.bind(this, false)} />

                                <ActionButton label={"Clear"} keyIndex={"saveBill"}
                                isDisable={is_disable_action}
                                imgPath={require("../../assets/Icon/SavedBills.png")}
                                onPressAction={this._clearBill.bind(this, false)} />
                                </View>
            }
            </View>
        )
    }

    async changePickerDropDownValue(flag, selectedValue, pickerKey) {

        var states = this.state;
        var valueChanged = false;
        /*
        var selectedDR_Clinic={};
        var selectedClinic=states["selectedClinic"];
        
        if(selectedValue){
          if(!selectedClinic || (selectedClinic && selectedClinic.id!==selectedValue.id)){
            valueChanged=true;
          }
        }
        */

        // if(pickerKey == "Doctor"){

        //   states["showPickerDropDownFlag"] = flag
        //   if(selectedValue){
        //     await AsyncStorage.setItem("Selected_Clinic_Doctor",JSON.stringify(selectedValue));
        //     states["selectedClinic"]=selectedValue;
        //     selectedDR_Clinic=selectedValue;
        //   }
        // }else if(pickerKey == "FO"){

        //   states["showPickerDropDownFlag"] = flag
        //   if(selectedValue){
        //     await AsyncStorage.setItem("Selected_Clinic_Doctor",JSON.stringify(selectedValue));
        //     states['selectedDoctor']=selectedValue;
        //     selectedDR_Clinic=selectedValue;
        //   }
        // }



        this.setState({
            states
        }, () => {
            /*
          this.props.changeStateValues({}, {}, "", this.state.selectedClinic,selectedDR_Clinic,this.state.clinicData)
          // this.props.changeScreen("home", {}, "", this.state.selectedClinic)
    
          var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
          
          
          // on change stats data retrieve
          if(valueChanged){
            this.getAppointments(selectedDateAndTime);
            this.getAppointmentStat();
          }
          */
        })
    }
    onPressYesButton = () => {
        let suggestions = [];
        var is_patient_details_editable = false
        let { data, patientAppointment, billRows, transmittedPrescription } = this.state
       if(this.props.patientAppointment){  
        data['doctor_name'] = this.props.patientAppointment['doctor_name'] ? this.props.patientAppointment['doctor_name'] : ""
        data['billing_customer_name'] = this.props.patientAppointment['patient_name']
        data['billing_customer_mobile_no'] = this.props.patientAppointment['patient_mobile_number']
        data['patient_account_number'] = this.props.patientAppointment['patient_account_number']
        data['ip_number'] = this.props.patientAppointment['ip_number'] ? this.props.patientAppointment['ip_number'] : 0
        data["doctor_reg_no"] = this.props.patientAppointment["doctor_reg_no"] ? this.props.patientAppointment["doctor_reg_no"] : ""
        data['invoice_number'] = ""
        data['received_amount'] = ""
        data['bill_summary_id'] = ''
        data['general_discount'] = 0
        data['general_discount_percentage'] = 0
        data['grand_total'] = 0
        data['round_off'] = 0
        data['payment_mode'] = "1"
        data['transaction_number'] = ""
        data['policy_number'] = ""
        data['insurance_company_name'] = ""
        data['validity'] = ""
        let suggestions = []
        for (let i = 0; i < 10; i++) {
            suggestions.push(tempSuggestion);
        }
        this.setState({
            patientAppointment: this.props.patientAppointment,
            is_editable: true,
            is_patient_details_editable: is_patient_details_editable,
            data ,
            suggestions: suggestions,
            billRows : [],
            subAmount : 0.00,
            is_complete_bill : false,
            // suggestions : []
        }, () => {
            this._loadBillNumber();
            this.setState({
                conformationFlag : false
            },()=>{
                this.props.discardDataReset(true)
            })
        })
      }else if (this.props.transmittedPrescription && this.state.selectedTopMenu == "transmittedPrescriptionFlag"){
        data = JSON.parse(JSON.stringify(tempData))

        if (this.props.transmittedPrescription.doctor) {
            data['doctor_name'] = this.props.transmittedPrescription.doctor['name'] ? this.props.transmittedPrescription.doctor['name'] : ""
            data["doctor_reg_no"] = this.props.transmittedPrescription.doctor["doctor_reg_no"] ? this.props.transmittedPrescription.doctor["doctor_reg_no"] : ""
        }
        if (this.props.transmittedPrescription.patient) {
            data['billing_customer_name'] = this.props.transmittedPrescription.patient['name']
            data['billing_customer_mobile_no'] = this.props.transmittedPrescription.patient['mobile_number']
            data['patient_account_number'] = this.props.transmittedPrescription['patient_account_number']
            data['ip_number'] = this.props.transmittedPrescription.patient['ip_number'] ? this.props.transmittedPrescription.patient['ip_number'] : 0
        }

        if (this.props.transmittedPrescription.prescription_lines && this.props.transmittedPrescription.prescription_lines.length > 0) {
        
            billRows = []

            let temp = this.props.transmittedPrescription.prescription_lines;
            let len = temp.length;

            for (let i = 0; i < len; i++) {

                let dummyRow = JSON.parse(JSON.stringify(tempRows))
                dummyRow['drug_id'] = temp[i].drug_id
                dummyRow['brand_name'] = temp[i].brand_name
                dummyRow['dosage_strength'] = temp[i].dosage_strength
                dummyRow['dosage_type'] = temp[i].dosage_type
                // for transmitted details alone
                dummyRow['is_transmitted_drug'] = true

                billRows.push(dummyRow)
                this.props.discardDataReset(true)

            }

        } else {
            billRows = []
            this.props.discardDataReset(true)

        }

        // get PO number
        this.setState({

            is_editable: true,
            is_patient_details_editable: false,
            data,
            billRows,
            subAmount : 0.00,
            is_complete_bill : false,
            suggestions: JSON.parse(JSON.stringify(suggestions)),
        }, () => {
            // this._loadBillNumber();
            this.props.discardDataReset(true)
            this.setState({
                conformationFlag : false,
                billRows
            })
        })
      }else if(this.props.selectedSavedBill && this.props.selectedSavedBill.bill_summary_id !== data.bill_summary_id){
        data['bill_summary_id'] = this.props.selectedSavedBill.bill_summary_id
        // states['is_saved_bill'] = true;
        data['invoice_number'] = ""
        data['received_amount'] = ""
        // data["doctor_reg_no"] = null

        // clear suggestion
        let suggestions = []
        for (let i = 0; i < 10; i++) {
            suggestions.push(tempSuggestion);
        }

        this.setState({

            // states,
            is_saved_bill: true,
            is_complete_bill: false,
            is_editable: true,
            is_patient_details_editable: true,
            data: data,
            suggestions: suggestions
        }, () => {

            this._loadSavedBill(this.props.selectedSavedBill.bill_summary_id),
            this.setState({
                conformationFlag : false,
            },() => {
                this.props.discardDataReset(true)
            })
        })

      } 
    }
    setSelectedPatientDetails(selectedPatient){
        let {data ,pharmacyType} = this.state;
        data['billing_customer_name'] = selectedPatient['patient_name']
        data['billing_customer_mobile_no'] = pharmacyType === "Standalone Pharmacy" ? selectedPatient['mobile_number'] : selectedPatient['patient_mobile_number'] 
        data['patient_account_number'] = selectedPatient['patient_account_number']
        data['ip_number'] = selectedPatient['ip_number'] ? selectedPatient['ip_number'] : 0
        data['patient_id'] = pharmacyType === "Standalone Pharmacy" ? selectedPatient['id'] : selectedPatient['patient_id']
        this.setState({data:data, searchPatientData:[]});
    }
    render() {
        let { showSuccesResponse, showErrorResponse, responseMessage, data } = this.state;
        return (
            <TouchableWithoutFeedback
                onPress={() => {
                    this._clearDropdowns()
                }}
                onBlur={() => {
                    // this._clearDropdowns()
                }}
            >
              <View style={{maxHeight: screenHeight - 65}}> 
                <View style={{ zIndex: -10, maxHeight: screenHeight - 65 }}>
                    {this.getBillDetails()}
                    {this.getBillTable()}
                    {this.getBillAmountDetails()}
                    {this.getBillActions()}

                    {
                        showSuccesResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }
                </View>
                    {this.state.conformationFlag && !this.state.is_complete_bill?
                        <View style={{ justifyContent: "center", alignItems: "center", height: '100%', width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute' }}>
                           <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: '0.25vw', padding: '1.30vw' }}>
                              <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: "10%" }}>{"Are you sure to discard ?"}</Text>
                                <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "space-around", width: "50%", height:"20%", flexWrap: "wrap-reverse" }}>
                                    <TouchableOpacity
                                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw' }}
                                        onPress={() => { 
                                          this.onPressYesButton()
                                         }}>
                                        <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"Yes"}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', width: "35%", paddingVertical: '0.52vw' }}
                                        onPress={() => { 
                                            this.setState({ 
                                                conformationFlag : false 
                                            },()=>{
                                                // this.props.discardDataReset(true)
                                            }) 
                                        }}
                                    >
                                        <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"No"}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                        : null
                    }
                    {/* <View style={{ zIndex: 1, position: 'absolute', width: '100%' }}> */}
                    {
                        this.state.searchPatientData && this.state.searchPatientData.length > 0 && data.billing_customer_mobile_no ?
                        <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute',top:"4.2vw", width: "96vw", height:"80vh", alignItems: 'flex-start', paddingLeft:10,paddingBottom: 30 }}>
                             <View style={{ width: "32vw", backgroundColor: '#E2E2E2', borderBottomRightRadius: 10, borderBottomLeftRadius: 10, paddingBottom: 10 }}>
                                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: screenHeight / 2  }}>
                                {
                                    this.state.searchPatientData.map((item, index) => {
                                        return(
                                            <View key={index} style={{ flexDirection: 'row', justifyContent: 'flex-start', paddingTop: '0.32vw', paddingBottom: "0.32vw",marginLeft:'1vw',marginRight:'1vw', borderBottomColor: "white", borderBottomWidth: 1, paddingRight:'10vw' }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center', width:'25vw'}}>
                                                <Image source={item.patient_photo_url ? item.patient_photo_url : require('../../assets/images/sample_profile.png')}  style={{ height: '1.95vw', width: '1.95vw', 
                                                       marginLeft: " 0.85vw", borderRadius: 25}}resizeMode='stretch' />
                                                <Text style={{ fontSize:'0.91vw', color: 'black', marginLeft: "0.65vw" , fontWeight: "500" }} >{item.patient_name}</Text>
                                                <Text style={{ fontSize: '0.85vw', marginLeft: "0.65vw", marginTop: ' 0.13vw', color: color.notesTableData}}> {this.state.pharmacyType === "Standalone Pharmacy"? item.mobile_number : item.patient_mobile_number} {item.patient_mobile_number ? "|" : null} {item.patient_account_number}</Text>
                                                </View>
                                                <TouchableOpacity style={{ paddingLeft: '1.20vw', paddingRight: '1.20vw', alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 10, marginLeft: '0.5vw' }}
                                                onPress={() => {
                                                    this.setSelectedPatientDetails(item)
                                                    }}
                                                >
                                                            <Text style={{ color: 'white', fontWeight: 'bold', fontSize: '0.85vw', }}>{"Add"}</Text>
                                                </TouchableOpacity>
                                            </View>
                                        )
                                    })
                                }

                                <View style={{alignItems:"center"}}>
                                <TouchableOpacity style={{ paddingLeft: 30, paddingRight: 30, alignItems: 'center', justifyContent: 'center', backgroundColor: '#313131', borderRadius: 10, paddingBottom: 10, paddingTop: 5, marginTop: 10 }}
                                    onPress={() => {
                                        this.setState({
                                            searchPatientData: []
                                        })
                                    }}
                                >
                                    <Text style={{ color: 'white', fontWeight: 'bold', fontSize: '0.85vw', }}>{"Skip"}</Text>
                                </TouchableOpacity>
                                </View>
                                 
                                </ScrollView>
                             </View>
                        </View>
                      

                            // <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: "100%", alignItems: 'flex-start', paddingLeft: 10 }}>

                            //     <View style={{
                            //         position: "absolute",
                            //         marginTop: '6.51vw',
                            //         backgroundColor: color.white,
                            //         border: "1px solid lightgrey",
                            //         justifyContent: "center",
                            //         alignItems: "center",
                            //         alignSelf: "center",
                            //         alignContent: "center",
                            //         flex: 1,
                            //         borderRadius: 10,
                            //         padding: '1.95vw'
                            //     }}>
                            //         <ScrollView showsHorizontalScrollIndicator={true} showsVerticalScrollIndicator={true} style={{ maxHeight: screenHeight / 2 }}>
                            //             {
                            //                 this.state.searchPatientData.map((item, index) => {
                            //                     return (
                            //                         <View key={index} style={{ backgroundColor: "#E2E2E2", flexDirection: 'row', justifyContent: 'space-between', padding: 10, borderRadius: 4 }}>
                            //                             <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            //                                 <Image source={item.patient_photo_url ? item.patient_photo_url : require('../../assets/images/sample_profile.png')}
                            //                                     style={{ height: '1.95vw', width: '1.95vw', marginLeft: " 0.85vw", borderRadius: 25}}
                            //                                     resizeMode='stretch' />
                            //                                 <View>
                            //                                   <Text style={{ fontSize:'0.91vw', color: 'black', marginLeft: '0.65vw', fontWeight: "500" }} >{item.patient_name}</Text>
                            //                                   <Text style={{ fontSize: '0.78vw', marginLeft: '0.52vw', marginTop: ' 0.13vw', color: color.notesTableData}}> {item.patient_mobile_number}</Text>
                            //                                 </View>    
                            //                                 <Text style={{ color: color.notesTableData, marginLeft: '0.65vw', fontSize:'0.91vw' }}> {item.patient_account_number}{item.patient_account_number ? " | " : null}</Text>
                            //                             </View>
                            //                             <TouchableOpacity style={{ paddingLeft: '1.30vw', paddingRight: '1.30vw', alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: 10, marginLeft: '1.30vw' }}
                            //                                 onPress={() => {
                            //                                     this.setSelectedPatientDetails(item)
                            //                                     // this.setState({
                            //                                     //     data: item,
                            //                                     // }, () => {
                            //                                     //     //this.setModalVisible(true);
                            //                                     // })
                            //                                 }}
                            //                             >
                            //                                 <Text style={{ color: 'white', fontWeight: 'bold', fontSize: '0.85vw', }}>{"Create Bill"}</Text>
                            //                             </TouchableOpacity>

                            //                         </View>
                            //                     );
                            //                 })

                            //             }
                            //             {/* <View style={{ alignItems: 'center' }}>
                            //                 <TouchableOpacity style={{ paddingLeft: 30, paddingRight: 30, alignItems: 'center', justifyContent: 'center', backgroundColor: '#313131', borderRadius: 10, paddingBottom: 10, paddingTop: 5, marginTop: 10 }}
                            //                     onPress={() => {
                            //                         this.setState({
                            //                             searchPatientData: []
                            //                         })
                            //                     }}
                            //                 >
                            //                     <Text style={{ color: 'white', fontWeight: 'bold', fontSize: 18, }}>{"Skip"}</Text>
                            //                 </TouchableOpacity>
                            //             </View> */}
                            //         </ScrollView>
                            //         <TouchableOpacity style={{position: "absolute", top: 2, right: 2}} onPress={() =>{
                            //             this.setState({
                            //                 searchPatientData:[],
                            //             })
                            //         }
                            //         }>
                            //         <Icon name="close" size={'1.43vw'} color={color.black} />
                            //         </TouchableOpacity>

                            //     </View>

                            // </View>
                            : null
                    }
                {/* </View> */}
                  </View>
            </TouchableWithoutFeedback>
        )
    }

    _clearBill = () => {

        let { billRows } = this.state;

        // Suggestion list
        let suggestions = [];
        for (let i = 0; i < billRows.length; i++) {
            suggestions.push(JSON.parse(JSON.stringify(tempSuggestion)))
        }

        this.setState({
            showPickerDropDownFlag: false,
            is_saved_bill: false,
            is_complete_bill: false,
            is_editable: true,
            is_initiate_return: false,
            is_disable_action: false,
            data: JSON.parse(JSON.stringify(tempData)),
            billRows: [],
            transmittedPrescription: null,
            bill_type: "",
            viewBill: null,
            patientAppointment: null,
            suggestions: JSON.parse(JSON.stringify(suggestions)),
            is_bill_type_disable : false,
            subAmount : 0,
            is_patient_details_editable: true,
            patientSearchData: null
        }, () => {
            this.props.initiateNewBill()
            this.props.discardDataReset(true)
        })
    }

    _createBill = (is_action_for_complete_bill) => {
            this.setState({
            is_action_for_complete_bill: is_action_for_complete_bill,
            is_disable_action: true
        }, () => {
            // nxt process
            this._saveBill();
        })
    }

    _saveReturnBill() {

        this.setState({
            is_action_for_complete_bill: true,
            is_disable_action: true
        }, () => {
            // nxt process
            this._saveBill();
        })
    }

    _saveBill() {

        let errorMessage = [];
        let isValid = true;
        let { billRows, data, isTransmitted, is_action_for_complete_bill, is_saved_bill, is_initiate_return,is_bill_type_disable } = this.state;

        if(this.state.data.billing_customer_name==""){
            isValid = false; errorMessage.push("Enter Patient Name");
            this.setState({
                responseMessage: errorMessage ? errorMessage.join() : "",
                showErrorResponse: true,
                is_disable_action: false
            }, () => {
                this._clearNotification()
            })

            return;
        }

        let saveObj = JSON.parse(JSON.stringify(data));

        // Remove extra objects
        // saveObj = (delete saveObj['bill_type'], saveObj);
        saveObj = (delete saveObj['gross_return_amount'], saveObj);
        saveObj = (delete saveObj['grand_return_total'], saveObj);
        saveObj = (delete saveObj['taxable_return_amount'], saveObj);
        saveObj = (delete saveObj['general_discount_percentage'], saveObj);


        if (!is_initiate_return) {
            saveObj = (delete saveObj['invoice_number'], saveObj);
        }


        // set invoice date
        // if(!is_initiate_return)
        // {
        //     saveObj['invoice_date'] = this._setDataFormat(data['invoice_date'], 'date')
        // }
        // else{
        //     // set current date as return date
        //     saveObj['invoice_date'] = this._setDataFormat(new Date(), 'date')
        // }

        // remove param if not from transmitted
        if (isTransmitted) {
            // format
            saveObj['patient_id'] = this._setDataFormat(data['patient_id'], 'int');
            saveObj['doctor_id'] = this._setDataFormat(data['doctor_id'], 'int');
            saveObj['clinic_id'] = this._setDataFormat(data['clinic_id'], 'int');

            saveObj['transmitted_id'] = this._setDataFormat(data['transmitted_id'], 'int');
        } else {
            // remove
            // saveObj = (delete saveObj['patient_id'], saveObj);
            saveObj = (delete saveObj['doctor_id'], saveObj);
            saveObj = (delete saveObj['clinic_id'], saveObj);
            saveObj = (delete saveObj['transmitted_id'], saveObj);
            // saveObj = (delete saveObj['patient_account_number'], saveObj);
            // saveObj['patient_account_number'] = null;

            // if(is_action_for_complete_bill) {
            if (!is_saved_bill && !saveObj['bill_summary_id']) {

                saveObj = (delete saveObj['bill_summary_id'], saveObj);
            }
        }

        // format data
        saveObj['discount_amount'] = this._setDataFormat(data['discount_amount'], 'decimal');
        
        saveObj['cgst_amount'] = this._setDataFormat(data['cgst_amount'], 'decimal');

        saveObj['sgst_amount'] = this._setDataFormat(data['sgst_amount'], 'decimal');
        saveObj['igst_amount'] = this._setDataFormat(data['igst_amount'], 'decimal');
        saveObj['tax_amount'] = this._setDataFormat(data['tax_amount'], 'decimal');
        saveObj['round_off'] = this._setDataFormat(data['round_off'], 'decimal');
        saveObj['delivery_charge'] = this._setDataFormat(data['delivery_charge'], 'decimal');

        if(this.state.data.bill_type === 'Credit'){
            saveObj['received_amount'] = 0
            saveObj['general_discount'] = 0
            saveObj['general_discount_percentage'] = 0
        }else{
        if (data["payment_mode"] == "4" || data["payment_mode"] == "5" || data["payment_mode"] == "6" || data["payment_mode"] == "2" ) {
            saveObj['received_amount'] = this._setDataFormat(data['grand_total'], 'decimal');
        }
        else if(data["payment_mode"] == "3"){
            if((parseFloat(data['cash_amount'])<0) || (parseFloat(data['card_amount'])<0)){
                isValid = false 
                this.setState({
                    responseMessage: "Invalid Amount",
                    showErrorResponse: true,
                    is_disable_action: false
                }, () => {
                    this._clearNotification()
                })
                return;
            }
            saveObj['received_amount'] = this._setDataFormat(data['grand_total'], 'decimal');
        } else {
            if(parseFloat(data["received_amount"]) <= parseFloat(data["grand_total"])){
                saveObj['received_amount'] = this._setDataFormat(data['received_amount'], 'decimal')
            }else {
                saveObj['received_amount'] = this._setDataFormat(data['grand_total'], 'decimal')
            }

        }
        saveObj['general_discount'] = this._setDataFormat(data['general_discount'], 'decimal');
        saveObj['general_discount_percentage'] = parseFloat(data['general_discount_percentage']  ? data['general_discount_percentage'] : 0);
        }

        saveObj['balance_amount'] = this._setDataFormat(data['balance_amount'], 'decimal');

        // current bill or replace return details
        if (!is_initiate_return) {
            if(this.state.data.bill_type !== 'Credit'){
            saveObj['payment_mode'] = this._setDataFormat(data['payment_mode'], 'int');
            }
            if(this.state.data.bill_type === 'Credit'){
               // saveObj = (delete saveObj['billing_customer_acc_no'], saveObj);
                saveObj['patient_id'] = this._setDataFormat(data['patient_id'], 'int');
                saveObj['patient_account_number'] = this.state.data['patient_account_number'];
                saveObj['ip_number'] = this.state.data['ip_number'] ? this.state.data['ip_number'] : 0;
                saveObj['grand_total'] = this._setDataFormat(data['taxable_amount'], 'decimal');
            }else {
                saveObj['grand_total'] = this._setDataFormat(data['grand_total'], 'decimal');
            }
            // Remove extra objects
            saveObj = (delete saveObj['invoice_number'], saveObj);

            // set invoice date
            saveObj['invoice_date'] = this._setDataFormat( this.state.isOnChange ? data['invoice_date'] :  moment(new Date()).format("YYYY-MM-DD"), 'date')

            saveObj['taxable_amount'] = this._setDataFormat(data['taxable_amount'], 'decimal');
            saveObj["doctor_id"] = this._setDataFormat(data['doctor_id'], 'int');
        } else if(is_initiate_return) {
                saveObj['payment_mode'] = this._setDataFormat(data["payment_mode"], 'int');
            saveObj['card_amount'] = null;

            // set current date as return date
            saveObj['invoice_date'] = this._setDataFormat(new Date(), 'date')

            saveObj['taxable_amount'] = this._setDataFormat(data['taxable_return_amount'], 'decimal');
            saveObj['grand_total'] = this._setDataFormat(data['grand_return_total'], 'decimal');
            saveObj['received_amount'] = this._setDataFormat(data['grand_return_total'], 'decimal');
            saveObj["doctor_id"] = this._setDataFormat(data['doctor_id'], 'int');

        }

        let bill_line_items = [];
        let bill_line_items_no_quantity = []
        let schedule_item = [];
        let schedule_drug = "";
        for (let i = 0; i < billRows.length; i++) {

            let temp = {
                "drug_id": this._setDataFormat(billRows[i].drug_id, 'int'),
                "hsn_code": billRows[i].hsn_code,
                "batch_no": billRows[i].batch_no,
                // "quantity": this._setDataFormat(billRows[i].quantity, 'int'),
                "expiry_date": this._setDataFormat(billRows[i].expiry_date, 'date'),
                "physical_box_no": billRows[i].physical_box_no,
                "cost_per_quantity": this._setDataFormat(billRows[i].cost_per_quantity, 'decimal'),
                "total_cost": this._setDataFormat(billRows[i].total_cost, 'decimal'),
                "discount_percentage": this._setDataFormat(billRows[i].discount_percentage, 'decimal'),
                "gst_percentage": this._setDataFormat(billRows[i].gst_percentage, 'decimal'),
                // "net_total_amount": this._setDataFormat(billRows[i].net_total_amount, 'decimal')
            }

            if (is_initiate_return) {
                // replace return details
                temp["quantity"] = this._setDataFormat(billRows[i].return_quantity, 'int')
                temp["net_total_amount"] = this._setDataFormat(billRows[i].net_return_total_amount, 'decimal')
            } else {
                temp["quantity"] = this._setDataFormat(billRows[i].quantity, 'int')
                temp["net_total_amount"] = this._setDataFormat(billRows[i].net_total_amount, 'decimal')
            }

            if (temp.drug_id && temp.quantity) {

                schedule_drug = billRows[i]['schedule'] ? billRows[i]['schedule'] : ""
                bill_line_items.push(temp);
                schedule_item.push(schedule_drug)
            }
            else if (temp.drug_id && !temp.quantity) {
                bill_line_items_no_quantity.push(temp)
            }

            // else if(temp.drug_id && temp.return_quantity && is_initiate_return) {

            //     bill_line_items.push(temp);
            // }
        }
        let isSchedule = schedule_item && schedule_item.length > 0 && schedule_item.some(item => item !== "")
       
        if (bill_line_items.length > 0 || !is_initiate_return) {

            if (!is_initiate_return) {
                let cash_amount = parseFloat(saveObj["cash_amount"])
                let card_amount = parseFloat(saveObj["card_amount"])
                let Total_cash_card_amount = parseFloat(saveObj["cash_amount"]) + parseFloat(saveObj["card_amount"])

                let grand_total = this._setDataFormat(data['grand_total'], 'decimal')
                let received_amount = this._setDataFormat(data['received_amount'], 'decimal')
                let net_total_amount = this._setDataFormat(data["net_total_amount"],'decimal') 

                if (is_action_for_complete_bill && saveObj['payment_mode'] == 1 && !saveObj['received_amount'] && data.bill_type == "Bill") {
                    isValid = false; errorMessage.push("Enter received amount");
                }
                else if (is_action_for_complete_bill && saveObj['payment_mode'] == 1 && received_amount < grand_total && data.bill_type == "Bill") {
                    isValid = false; errorMessage.push("Received amount should greater than bill amount");
                }
                else if (is_action_for_complete_bill && saveObj['payment_mode'] == 3 && Total_cash_card_amount != grand_total && data.bill_type == "Bill") {
                    isValid = false; errorMessage.push("Received amount should match with bill amount");
                }
                else if (is_action_for_complete_bill && saveObj['payment_mode'] == 3 && !cash_amount && data.bill_type == "Bill") {
                    isValid = false; errorMessage.push("Enter cash amount");
                }
                else if (is_action_for_complete_bill && saveObj['payment_mode'] == 3 && !card_amount && data.bill_type == "Bill") {
                    isValid = false; errorMessage.push("Enter card amount");
                } else if( isSchedule && data.doctor_name === "" ){
                    isValid = false; errorMessage.push("This is an schedule drug so please enter the doctor name");
                }
                
            }
        } else if (is_initiate_return && saveObj['payment_mode'] == 1 && saveObj['received_amount'] == 0 ) {
            isValid = false; errorMessage.push("Please enter received amount");
       }
        else {

            isValid = false;

            if (bill_line_items_no_quantity.length > 0) {
                errorMessage.push("Add quantity for added drugs")
            }
            else if (!is_initiate_return) {
                errorMessage.push("No drugs added")
            } else {
                errorMessage.push("No return drug quantity added")
            }
        }

        if (bill_line_items.length > 0 && isValid) {

            saveObj['bill_line_items'] = bill_line_items;
            saveObj['doctor_reg_no'] = data.doctor_reg_no ? data.doctor_reg_no : 0

            // call save API
            let url = "";

            if (is_initiate_return) {

                url = Constants.BILL_RETURN_BILLING;
            }
            else if (is_action_for_complete_bill) {

                url = Constants.BILL_COMPLETE_BILLING;
            }
            else if(this.state.data.bill_type === "Credit"){
                url = Constants.CREDIT_BILL_SAVE;
            }
            else {
                url = Constants.BILL_PARTIAL_BILLING;
            }

            this.service.postApiCall(
                url,
                saveObj,
                this.successCallBack,
                this.errorCallBack
            )
        } else {

            this.setState({
                responseMessage: errorMessage ? errorMessage.join() : "",
                showErrorResponse: true,
                is_disable_action: false
            }, () => {
                this._clearNotification()
            })
        }
    }

    successCallBack = response => {

        let states = this.state;
        let { is_action_for_complete_bill, data,
            responseMessage, showSuccesResponse,
            is_editable, is_complete_bill, is_saved_bill, bill_summary_id, is_disable_action, is_initiate_return
        } = this.state;

        // let declaration
        let flagClearBill = false;
        let responseData = response.data ? response.data : null;  

        if (response.status === "success") {

            if(response.message.includes("Credit Limit Exceeded,")){
            
                states['responseMessage'] = "Credit Limit Exceeded";
                states['showErrorResponse'] = true;
                states['showSuccesResponse'] = false;
                setTimeout(() => {
                    this.setState({
                        responseMessage:  response.message,
                        showSuccesResponse: true,
                        showErrorResponse: false
                        },() => {
                            this._clearNotification()
                        })
                }, 3500);
            }else{
                states['responseMessage'] = response.message;
                states['showErrorResponse'] = false;
                states['showSuccesResponse'] = true;
            }
            
            this.props._getDrugAndStockCount();

            states['toggleSwitchFlag'] = false;
            if (!is_initiate_return) {

                let response_id = responseData && responseData.id ? responseData.id : "";
                let invoice_number = responseData && responseData.invoice_number ? responseData.invoice_number : "";

                // on create save bill 
                // on save bill, direct complete bill - we get 'id' -> bill_summary_id
                if (response_id) {
                    data["bill_summary_id"] = response_id;
                }
                // update invoice number
                data['invoice_number'] = invoice_number;


                if (!is_saved_bill && is_action_for_complete_bill) {
                    // direct bill 
                    // assign bill id, invoice number
                    states['is_editable'] = false;
                    states["is_saved_bill"] = false;
                    states["is_complete_bill"] = true;
                    this.props.updatePopupStatus("savedBillFlag", true)
                    this.props.updatePopupStatus("savedBillFlag", false)
                }
                else if (!is_saved_bill && !is_action_for_complete_bill) {
                    /*
                    // save bill
                    // assign bill id, not invoice number 
                    states['is_editable'] = true;
                    states["is_saved_bill"] = true;
                    states["is_complete_bill"] = false;
                    */
                    flagClearBill = true;
                    this.props.updatePopupStatus("savedBillFlag", true)
                    this.props.updatePopupStatus("savedBillFlag", false)
                }
                else if (is_saved_bill && !is_action_for_complete_bill) {
                    /*
                    // again save bill
                    // don't assign bill id and invoice number
                    states['is_editable'] = true;
                    states["is_saved_bill"] = true;
                    states["is_complete_bill"] = false;
                    */
                    flagClearBill = true;
                    this.props.updatePopupStatus("savedBillFlag", true)
                    this.props.updatePopupStatus("savedBillFlag", false)
                }
                else if (is_saved_bill && is_action_for_complete_bill) {
                    // create bill from saved bill
                    // assign not bill id, only invoice number
                    states['is_editable'] = false;
                    states["is_saved_bill"] = false;
                    states["is_complete_bill"] = true;
                    this.props.updatePopupStatus("savedBillFlag", true)
                    this.props.updatePopupStatus("savedBillFlag", false)
                }
            }

        } else {

            states['showSuccesResponse'] = false;
            states['showErrorResponse'] = true;
        }

        if (flagClearBill == false) {

            this.setState({
                is_disable_action: false,
                states,
                data,
                responseMessage: response.message
            }, () => {
                this.props.discardChanges("", false)
                if (is_initiate_return) {
                    if (states['showSuccesResponse']) {
                        this._clearNotification(true, 'invoice')
                    }
                } else {
                    this._clearNotification()
                }
            })
        } else {
            this._clearBill(this)
            this._clearNotification()
        }
    }

    errorCallBack = response => {

        this.setState({
            responseMessage: response.message,
            showErrorResponse: true
        }, () => {
            this._clearNotification()
        })
    }

    _clearNotification(do_navigate = false, next_page = '') {
        // clear after 2 seconds
        setTimeout(() => {
            this.setState({
                showSuccesResponse: false,
                showErrorResponse: false,
                responseMessage: ""
            }, () => {

                if (do_navigate) {
                    this.props.changeScreen(next_page)
                }
            });
        }, 2000)
    }

    _loadSavedBill(id) {

        let url = Constants.BILL_PARTIAL_BILLING + "?id=" + id;

        this.service.getApiCall(
            url,
            this._getSavedBillSuccess,
            this.errorCallBack
        )
    }

    _getSavedBillSuccess = (response) => {

        // let { billRows } = this.state;

        if (response.status === "success") {

            let responseData = JSON.parse(JSON.stringify(response.data));
            var savedLineItem = response.data.bill_line_items

            var brand_name = savedLineItem && savedLineItem.length > 0 && savedLineItem.map((item)=>item.brand_name )
            this.props.discardChanges(brand_name[0], this.state.is_complete_bill)

            let Bill_len = response.data.bill_line_items.length

            if (Bill_len > 10) {
                let suggestions = []
                for (let i = 0; i < Bill_len; i++) {
                    suggestions.push(tempSuggestion);
                }

                this.setState({
                    suggestions: suggestions
                })
            }

            // for calculation purpose used 'gross  amount'
            responseData['gross_amount'] = (parseFloat(responseData['grand_total']) || 0) - (parseFloat(responseData['round_off']) || 0)

            let general_discount = parseFloat(responseData['general_discount']) || 0
            let taxable_amount = parseFloat(responseData["taxable_amount"]) || 0

            // let totalAmount = parseFloat(responseData['general_discount']) + parseFloat(responseData['grand_total'])
            // // for temporary, bcz not maintaining in server
            // responseData['general_discount_percentage'] = ((general_discount * 100 / totalAmount) || 0)
            responseData['invoice_date'] = new Date()
            let doctor_name = responseData['doctor_name'] ? responseData['doctor_name'] : ""
            let {data} = this.state;
            data = responseData;
            data['doctor_name'] = doctor_name;
            this.setState({
                data,
                billRows: responseData.bill_line_items ? responseData.bill_line_items : [],
                is_disable_action: false
            }, () => {

                let updatedData = this._updateRoundOff(this.state.data);
                this.setState({
                    data: updatedData
                }, () => {
                    this._updateBillAmounts()
                })
            })
        }
    }

    _viewBillDetails() {

        let { viewBill } = this.state;


        if (Object.keys(viewBill).length > 0 && viewBill.id) {

            let url = Constants.BILL_VIEW + "?id=" + viewBill.id;

            this.service.getApiCall(
                url,
                this._getViewBillSuccess,
                this.errorCallBack
            )

        } else {

            this._loadBillNumber()
        }

    }

    _getViewBillSuccess = (response) => {

        let { data, is_complete_bill } = this.state;

        if (response.status === "success") {

            let responseData = JSON.parse(JSON.stringify(response.data));

            let bill_summary_id = responseData.id ? responseData.id : ""
            let ip_number = responseData.ip_number ? responseData.ip_number : 0
            let doctor_reg_no = responseData.doctor_reg_no? responseData.doctor_reg_no : ""
            let doctor_name = responseData.doctor_name ? responseData.doctor_name : ""
            // update summary id
            data = responseData;
            data['bill_summary_id'] = bill_summary_id;
            data['ip_number'] = ip_number;
            data['doctor_reg_no'] = doctor_reg_no;
            data['doctor_name'] = doctor_name;

            let bill_line_items = responseData.bill_line_items ? responseData.bill_line_items : []
            let len = responseData.bill_line_items ? (responseData.bill_line_items).length : 0

            // for return
            if (is_complete_bill) {
                for (let i = 0; i < len; i++) {
                    bill_line_items[i]['net_return_total_amount'] = 0;//bill_line_items[i]['net_total_amount']
                }
            }
            data['grand_return_total'] = 0;

            //for genereal disc % calculation
            let general_discount = parseFloat(data['general_discount'] || 0)
            let taxable_amount = parseFloat(data['taxable_amount'] || 0)

            // let totalAmount = parseFloat(data['general_discount']) + parseFloat(data['grand_total']) - parseFloat(data["round_off"])
           // data['general_discount_percentage'] = ((general_discount * 100 / totalAmount) || 0)

            this.setState({
                bill_type: data ? data['bill_type'] ? data['bill_type'] : "" : "",
                data: data,
                billRows: bill_line_items
            }, () => {
                this._updateBillAmounts()
                this.props.discardChanges("", true)

            });
        }
    }

    _setDataFormat = (data, desiredFormat = "string") => {

        let inString = data;

        if (desiredFormat === "int" || desiredFormat === "integer") {

            inString = parseInt(inString) || 0
        }
        if (desiredFormat === "float" || desiredFormat === "decimal") {

            let parsedVal = parseFloat(inString) || 0;
            let formattedVal = parsedVal.toFixed(2);
            // assign
            inString = parseFloat(formattedVal);
        }
        if (desiredFormat === "date") {

            let date = inString;
            if (date) {

                inString = date ? moment(date).format('YYYY-MM-DD') : null
            } else {
                inString = ""
            }
        }

        return inString;
    }

    _parseString(str) {

        return str != null && str != undefined ? str.toString() : ""

    }

    _updateRoundOff(data) {

        let { is_initiate_return } = this.state;

        let payment_mode = parseFloat(data['payment_mode']) || 0
        let gross_amount = !is_initiate_return ? (parseFloat(data['gross_amount']) || 0) : (parseFloat(data['gross_return_amount']) || 0)
        let round_off = 0
        let grand_total = 0

        let taxable_amount = !is_initiate_return ? (parseFloat(data['taxable_amount']) || 0) : (parseFloat(data['taxable_return_amount']) || 0)
        let general_discount = parseFloat(data['general_discount']) || 0

        // general discount calculation 
        // calc
        gross_amount = taxable_amount - general_discount
        let net_total_amount = gross_amount

        if ( payment_mode ) {

            grand_total = parseFloat((Math.round(gross_amount)).toFixed(2)) || 0 // 18.55 - 18.85

            round_off = grand_total - gross_amount    // 0.45 - 0.15

            data['round_off'] = round_off.toFixed(2);

            if (!is_initiate_return) {
                data['grand_total'] = grand_total.toFixed(2)
            } else {
                data['grand_return_total'] = grand_total.toFixed(2)
            }

        }
        //  else {

        //     if (!is_initiate_return) {
        //         data['grand_total'] = gross_amount.toFixed(2)
        //     } else {
        //         data['grand_return_total'] = gross_amount.toFixed(2)
        //     }

        // }

        return data;
    }

    _printBill() {

        let { data } = this.state;

        // call save API
        let url = "";

        if (data.bill_summary_id) {

            url = Constants.PHARMACY_BILLING + "?id=" + data.bill_summary_id + "&export=pdf";

            this.service.getInstance().documentUploadGet(
                url,
                this._printSuccessCallBack,
                this.errorCallBack,
                "pdf"
            );
        } else {
            console.log(" summary id not available")
        }

    }

    _printSuccessCallBack = (response) => {

        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            location.reload()
        }
    }

    _returnBill() {
        var {data} = this.state
        data["payment_mode"] = "1"
        this.setState({
            is_initiate_return: true,
            data
        },() => {
            this.setState({
                is_return_editable: true
            })
        })
    }
    _calculateReturnBill = (stateKey, value, type, index) => {

        let { data, billRows } = this.state;

        // set data
        billRows[index][stateKey] = value;

        // process data based on keyname
        if (stateKey === "return_quantity") {

            // set total_cost
            let total_cost = 0;
            let cost = parseFloat(billRows[index]["cost_per_quantity"]) || 0;
            let qty = parseInt(billRows[index][stateKey]) || 0;    //parseInt(value);

            // total
            total_cost = parseFloat((cost * qty).toFixed(2)) || "";

            // discount
            let discount_percentage = parseFloat(billRows[index]["discount_percentage"]) || 0;    // parseFloat(value) || 0;
            let discount_amount = parseFloat((total_cost * discount_percentage / 100).toFixed(2)) || 0;
            // for calc purpose 
            billRows[index]["discount_amount_return"] = discount_amount

            // assign
            billRows[index]['total_cost'] = total_cost
            billRows[index]['net_return_total_amount'] = (total_cost - discount_amount).toFixed(2);
        }

        // set total cost, grand total
        data = this._drugListTaxableAmountUpdate(billRows, data)

        data = this._updateRoundOff(data);

        this.setState({ billRows, data }, () => {

            // update calculation
            if (stateKey === "return_quantity") {

                this._updateBillAmounts()
            }
        })
    }

    _loadDoctorsList = () => {
        let url = ''

        if(this.state.pharmacyType === "Standalone Pharmacy"){
            url = Constants.REFERRED_DOCTOR;
        }else {
            url = Constants.LIST_DOCTORS;
        }

        this.service.getApiCall(
            url,
            this._setDoctorList,
            this.errorCallBack
        )
    }

    _setDoctorList = (response) => {

        let states = this.state;

        if (response.status === "success") {
            var Data = response.data
            if(this.state.pharmacyType === "Standalone Pharmacy" && (Data).length > 0){
                var Doc_list = []
                for(let i = 0; i < (Data).length; i++){
                    Doc_list.push({doctor_id: Data[i].id, doctor_reg_no: Data[i].reg_no, name: Data[i].doctor_name })
                }
                this.setState({ 
                    doctorsList: Doc_list ? Doc_list : [],
                    searchDoctorsList: Doc_list ? Doc_list : [] 
    
                });
            }else{
                this.setState({ 
                    doctorsList: response.data ? response.data : [],
                    searchDoctorsList: response.data ? response.data : [] 
    
                });
            }  

        } else if (response.detail && response.detail === "Token is expired") {

            //   this.props.navigation.navigate( "loginNavigator" );
            this.props.logout()
        }
    }

    _itemSelected = (item, key, objFormat = {}) => {
        let states, { data, isSelected, flagShowDoctorList,} = this.state;
        if (item) {
            if (key === "doctor_name" && objFormat ) {
                let doctor_name = item[objFormat["keyName"]];
                let doctor_id = item[objFormat["keyId"]];
                data["doctor_name"] = doctor_name;
                data["doctor_id"] = item.doctor_id;
                data["doctor_reg_no"] = item.doctor_reg_no;
                isSelected = true
                flagShowDoctorList = false
            }

            this.setState({
                states, data, isSelected: isSelected, flagShowDoctorList: flagShowDoctorList, isDoctorSelected: true
            })
        }
    }

    _onFocusInput = (stateKey, refInp) => {

        let { isSelected, flagShowDoctorList } = this.state

        if (stateKey == "doctor_name") {

            // if(!isSelected) {
            if (!flagShowDoctorList) {
                this.setState({ flagShowDoctorList: false })
            }

            // if( document.activeElement === refInp ) {
            //     this.setState({ flagShowDoctorList: true })
            // }
        }
    }

    _onBlurInput = (stateKey) => {

        let { isSelected } = this.state

        if (stateKey == "doctor_name") {

            if (isSelected) {
                // this.setState({ flagShowDoctorList: false })
            }
        }
    }

    _addNextRows = () => {

        let { billRows, suggestions } = this.state

        let current_row = billRows.length;
        let bill_line_items = [];
        let bill_line_items_no_quantity = [];
        let add_flag = false;

        for (let i = 0; i < current_row; i++) {

            if (billRows[i].drug_id && billRows[i].quantity) {

                bill_line_items.push(billRows[i]);
            }
            else if (billRows[i].drug_id && !billRows[i].quantity) {
                bill_line_items_no_quantity.push(billRows[i])
            }
        }

        if ((current_row - bill_line_items.length) === 0) {
            add_flag = true;
        }

        if (add_flag) {

            let new_row = billRows.length + 5

            for (let i = current_row; i < new_row; i++) {
                billRows.push(JSON.parse(JSON.stringify(tempRows)));
                suggestions.push(JSON.parse(JSON.stringify(tempSuggestion)));
            }

            this.setState({
                billRows,
                suggestions
            })
        }
        else if (bill_line_items_no_quantity.length > 0) {
            this.setState({
                responseMessage: "Add quantity for added drugs",
                showErrorResponse: true,
                is_disable_action: false
            }, () => {
                this._clearNotification()
            })
        }
    }

    _clearDropdowns = () => {

        let { flagShowDoctorList ,dropDownViewFlag} = this.state

        if (flagShowDoctorList && (document.activeElement !== this.refDoctorName.current._node)) {
            // on focus out
            this.setState({
                flagShowDoctorList: false
            })
        }
        if(dropDownViewFlag){
            this.setState({
                dropDownViewFlag: false, 
                searchPatientData: []
            })
        }

    }

    _loadBillNumber = async () => {
        /*
        let { gstin_number } = this.state

        let url = Constants.BILL_NEW_INVOICE_NO;
        let store_gstin_number = await AsyncStorage.getItem("gstin_number")

        if( !gstin_number )
        {
            if(!store_gstin_number)
            {
                this.service.getApiCall(
                    url,
                    this._getBillNoSuccess,
                    this.errorCallBack
                )
            } else {

                this.setState({ gstin_number: store_gstin_number })

                // await AsyncStorage.setItem("gstin_number", gstin_number);
            }
        }
        */
    }

    _getBillNoSuccess = async (response) => {

        let states = this.state;

        if (response.status === "success") {

            let responseData = JSON.parse(JSON.stringify(response.data));

            // data["invoice_number"] = responseData?responseData.invoice_number:""

            let gstin_number = responseData ? responseData.gstin_number : ""

            states["gstin_number"] = gstin_number

            await AsyncStorage.setItem("gstin_number", gstin_number);

            this.setState({ states });
        }
    }
    _geAppointmentList = (key) => {
        let url = ""
        if( key) {
            if(this.state.pharmacyType === "Standalone Pharmacy"){
                url = Constants.GET_PATIENT_LIST + "?search_key=" + key
            }else{
                 url = Constants.GET_APPOINTMENT_LIST + "?&search_key=" + key
            }
        }

        this.service.getApiCall(
            url,
            this._geAppointmentListSuccess,
            this._getFailure
        )
    }

    _geAppointmentListSuccess = response => {
    
        if (response.status === "success") {
     
            let list = JSON.parse(JSON.stringify(response.data));
            let data = response;
            if(response.data.length==0){
                let state_data = this.state;
                state_data['doctor_name'] = "";
                state_data['billing_customer_name'] = ""
                state_data['billing_customer_mobile_no'] = state_data.data['billing_customer_mobile_no'];
                state_data['patient_account_number'] = ""
                state_data['ip_number'] = 0
                this.setState({
                    searchPatientData:[], toggleSwitchFlag: false
                //   commented to hide error msg responseMessage: "Entered Mobile Number not registered",showErrorResponse: true
                },()=>{this._clearNotification()});  
            }else{
                // update
                this.setState({ 
                    //appointmentList: list,
                    //appointmentData: data,
                    searchPatientData: response.data
                });
            }
        }
    };

}


{/* <TouchableOpacity disabled={this.state.isLocked}
        onPress={() => {
            // this.saveMedicationTemplate()
        }}
        style={{marginRight: 15, backgroundColor: color.themeDark, 
            paddingHorizontal:18, paddingVertical:8, borderRadius: 4
        }}>
        <Text style={{ color: color.white, textAlign: 'center' }}>{"Next Bill"}</Text>
    </TouchableOpacity> */}
    const styles = StyleSheet.create({
           
                buttonrowView: {
                  flexDirection: 'row', justifyContent: 'center', alignContent: "center", marginVertical: 10
                },
                emed_IPbill_sideheader: {
                  fontWeight: "500", fontSize: 14, marginVertical: 10
                },
                emed_content_container: {
                  flexDirection: "row", marginVertical: 15
                },
                emed_radio_btn_txt: {
                  fontWeight: "500", fontSize: 13
                },
                emed_radio_btn: {
                  marginRight: 8, height: 16, width: 16, borderRadius: 50, borderWidth: 1, borderColor: "#91939F"
                },
                emed_radio_btn_touchablearea: {
                  marginRight: 20, flexDirection: "row", alignItems: "center",marginLeft:10
                },
                emed_input_box: {
                  paddingLeft: 10, marginLeft: 15, borderWidth: 1, height: 35, width: 150, marginVertical: 20, borderRadius: 4, borderColor: "#888888",
              },
              billTypeTextBox : {
                flexDirection: "row",marginLeft:10
              }
              });