import React, { Component } from 'react';
import { Dimensions, View, Text, StyleSheet, Image, TouchableOpacity, TextInput, FlatList, ScrollView, TouchableWithoutFeedback, Picker } from 'react-native';
// import { TouchableOpacity } from 'react-native-gesture-handler';
import { color } from "../../styles/Color";
import { CommonReportSectionHeader, CommonDateFilter, SuccessAlert, ErrorAlert, CommonDatePicker, CommonAutoSuggestionFull } from '../../common/BaseFunctionComponent'
import BaseComponent from "../../common/BaseComponent"
import {Tooltip} from 'antd'
import SalesOverview from './salesOverview'
import PurchaseOverview from './purchaseOverview'
import SalesDetailed from './salesDetailed'
import StockOverview from './stockOverview'
import PurchaseDetailed from './purchaseDetailed'
import MisReport from './MisReport'
import PurchaseGst from './PurchaseGst'
import DayEndOverview from './dayEnd'
import PurchaseMonthly from './PurchaseMonthly'
import SalesGST from './SalesGST'
import SalesReturn from './SalesReturn'
import SalesMonthly from './SalesMonthly'
import PurchaseReturn from './PurchaseReturn'
import LowStockReport from './lowStock'
import StockMovements  from './stockmovements';
import ExpiryDrugs from './expirydrugs';
import GSTSplitUps from './GSTSplitUps';
import ConsolidatedSales from './ConsolidatedSales';
import ConsolidateSplitUps from './ConsolidateSplitUps';

import Constants from '../../networks/Constants';
import Service from '../../networks/service';

import moment from "moment"
import Style from '../../styles/Style';
import AsyncStorage from '../../AsyncStorage';

import { TimePicker, DatePicker } from 'antd';
import ReferralDocReport from './referralDocReport';
import PatientReport from './patientReport';

const { RangePicker } = DatePicker

const screenHeight = Dimensions.get("window").height;

const dateFormat = 'YYYY-MM-DD';
const dateFormatDisplay = 'DD-MM-YYYY';
const monthFormat = 'YYYY/MM';

// define page name and key
const PAGE_LOWSTOCK = "Low Stock Drugs"
const PAGE_LOWSTOCK_KEY = "lowStockDrugs"

const PAGE_EXPIRY_STOCK = "Expiry Stock" 
const PAGE_EXPIRY_STOCK_KEY = "expiryStock"
"Expiry Stock"

const PAGE_SLOWMOVEMENT = "Slow Movement Drugs";
const PAGE_SLOWMOVEMENT_KEY = "slowmovementdrugs";

const PAGE_NOMOVEMENT = "No Movement Drugs";
const PAGE_NOMOVEMENT_KEY = "nomovementdrugs";

const CONSOLIDATE_SALES = "Consolidated Sales";
const CONSOLIDATE_SALES_KEY = "consolidatedSales"

const CONSOLIDATE_SPLITUP = "Consolidated Sales Splitups"

var searchData = [
    { label: "Patient Name", value: "Patient Name" },
    { label: "Drug Name", value: "Drug Name" },
    { label: "Schedule", value: "Schedule" },
  ]
  var vendorData = [
    { label: "Vendor Name", value: "Vendor Name" },
    { label: "Drug Name", value: "Drug Name" },
  ]

  let fulldate = new Date();
  let converted_date = moment(fulldate).format("YYYY-MM-DD");
export class Reports extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedScreen: "",
            startTime: converted_date,
            endTime: converted_date,
            filterDate: "",
            TotalSalesReturnAmt: "",
            TotalReferralDocAmt: "",
            heading: "",
            cardAmt: "",
            cashAmt: "",
            upiAmt: "",
            banktransferAmt: "",
            insuranceAmt: "",
            retrunAmt:"",
            totalAmt: "",
            isclear: false,
            monthvalue: "",
            yearValue: "",
            monthlyTotalAmount: "",
            monthlyTotalReturn:"",
            PurchaseSGST: "",
            PurchaseCGST: "",
            purchasetotalAmt: "",
            PurchaseDiscount: "",
            purchaseNetAmount: "",
            purchasetotal_price_per_quantity: "",
            vendorName1: "",
            vendorName1ID: "",

            vendorName2: "",
            vendorName2ID: "",

            vendorName3: "",
            vendorName3ID: "",

            genricName: "",
            brandName: "",
            VendorList1: [],
            VendorList2: [],
            VendorList3: [],

            vendorTotal1: "",
            vendorTotal2: "",
            vendorTotal3: "",

            startTimeDayEndReport: "",
            endTimeDayEndReport: "",

            currentDate: "",
            isGstSplitupSelected: false,
            selectedMonth: "",

            flagShowVendorList: false,
            isSelected: false,
            vendor_ID:'',
            Vendor_Data: '',
            Vendorlist: [],
            SearchVendorlist: [],
            purchaseTotal : "",
            salesTotal : "",
            profitTotal : "",
            dropDownViewFlag : false,
            selectedYear : "",

            selectedSalesSearchOption: "Patient Name",
            selectedPurchaseSearchOption: "Vendor Name",
            isSearchOPtion : true,
            salesDetailedSearchData : {},
            purchaseDetailedSearchData: {},
            patientName: "",
            drugName: "",
            vendorName: "",
            drugsName: "",
            selectedSalesFilterYear: new Date().getFullYear(),
            brandNameSuggest: [],
            genericNameSuggest: [],
            vendorList: [],
            onFocusKey: "",
            drugNameSearch:"",
            patientReportSearch: "",
            fillterDate: [],
            selectedSalesFilterYear: new Date().getFullYear(),

            isScheduleSearch:false,
            ScheduleFilter:"",
            isAdminAccess: false
        };
        this.service = new Service();

    }

   async componentDidMount (){
    let login_user = await AsyncStorage.getItem("login_result");
        login_user = JSON.parse(login_user)
        this.setState({
            isAdminAccess: login_user.data.is_admin_access
        })

        this.getVendorlist()
    }

    getVendorlist = () =>{
        let url = Constants.LIST_VENDOR  + "?from=purchase_order"
        this.service.getApiCall(
            url,
            this.getvendorlistsuccess,
            this.getvendorlistFailure
        )   
    }    
        
    getvendorlistsuccess = (response) => {
        if (response.status === "success") {

            this.setState({ 
                Vendorlist: response.data ? response.data : [],
                SearchVendorlist: response.data ? response.data : [] 
            })
        }      
      }
      
    getvendorlistFailure = error => {
        console.log("Get Tag list error response");
      };

    PrintReport = (type = 'pdf') => {
        var today = new Date();
        var year = today.getFullYear();
        var url = ""
        if (this.state.heading == "Sales Overview") {
            
            var startTime = this.state.startTime ? this.state.startTime : ""

            var endTime = this.state.endTime ? this.state.endTime : ""

            url = Constants.GET_SALES_OVERVIEW + "?export=" + type + "&from_date=" + startTime + "&to_date=" + endTime;
          
        }
        else if (this.state.heading == "Sales Detailed") {

            var startTime = this.state.startTime ? this.state.startTime : ""

            var endTime = this.state.endTime ? this.state.endTime : ""

            if ((this.state.startTime && this.state.endTime) || this.state.patientName || this.state.drugName || this.state.ScheduleFilter ) {

                url = Constants.GET_SALES_DETAILED + "?export=" + type + "&from_date=" + startTime + "&to_date=" + endTime + "&patient_name=" + this.state.patientName + "&drug_name=" + this.state.drugName + "&is_scheduled=" + this.state.isScheduleSearch + "&schedule="+ this.state.ScheduleFilter;  
            } else {
                url = Constants.GET_SALES_DETAILED + "?export=" + type;

            }
        } else if (this.state.heading == "Sales GST") {
                let startDate = this.state.startTime ? this.state.startTime : ""
                let endDate = this.state.endTime ? this.state.endTime : ""
                url = Constants.GET_SALES_GST + "?export=" + type + "&from_date=" + startDate + "&to_date=" + endDate + "&year=" + this.state.selectedSalesFilterYear;
           
        } else if (this.state.heading == "Sales Return") {
            if (this.state.startTime && this.state.endTime) {

                url = Constants.GET_SALES_RETURN + "?export=" + type + "&from_date=" + this.state.startTime + "&to_date=" + this.state.endTime;
            } else {
                url = Constants.GET_SALES_RETURN + "?export=" + type;

            }
        } else if (this.state.heading == "Day End") {
            if (this.state.startTime && this.state.endTime) {

                url = Constants.GET_DAYEND_REPORT + "?export=" + type + "&from_date=" + this.state.startTime + "&to_date=" + this.state.endTime;
            } else {
                url = Constants.GET_DAYEND_REPORT + "?export=" + type;

            }
        } else if (this.state.heading == "Sales Monthly") {
            if (this.state.monthvalue && this.state.yearValue) {
                
                url = Constants.GET_SALES_MONTH + "?export=" + type + "&month=" + this.state.monthvalue + "&year=" + this.state.yearValue;
            // } else {
            //     url = Constants.GET_SALES_MONTH + "?export=" + type;

            }
        } else if (this.state.heading == "Purchase Overview") {
            if (this.state.startTime && this.state.endTime) {

                url = Constants.GET_PURCHASE_OVERVIEW + "?export=" + type + "&from_date=" + this.state.startTime + "&to_date=" + this.state.endTime;
            } else {
                url = Constants.GET_PURCHASE_OVERVIEW + "?export=" + type;

            }
        } 
        else if (this.state.heading == "Purchase Detailed") {

            var startTime = this.state.startTime ? this.state.startTime : ""

            var endTime = this.state.endTime ? this.state.endTime : ""

            if ((this.state.startTime && this.state.endTime) || this.state.vendorName || this.state.drugsName ) {

                url = Constants.GET_PURCHASE_DETAILED + "?export=" + type + "&from_date=" + startTime + "&to_date=" + endTime  + "&drug_name=" + this.state.drugsName + "&vendor_name=" + this.state.vendorName;
            } else {
                url = Constants.GET_PURCHASE_DETAILED + "?export=" + type;

            }
        } else if (this.state.heading == "Purchase GST") {
            if (this.state.startTime && this.state.endTime) {

                url = Constants.GET_PURCHASE_GST + "?export=" + type + "&from_date=" + this.state.startTime + "&to_date=" + this.state.endTime;
            } else {
                url = Constants.GET_PURCHASE_GST + "?export=" + type;

            }
        } else if (this.state.heading == "Purchase Return") {
            if (this.state.startTime && this.state.endTime) {

                url = Constants.GET_PURCHASE_RETURN + "?export=" + type + "&from_date=" + this.state.startTime + "&to_date=" + this.state.endTime;
            } else {
                url = Constants.GET_PURCHASE_RETURN + "?export=" + type;

            }
        } else if (this.state.heading == "Purchase Monthly") {
            if (this.state.monthvalue && this.state.yearValue &&  !this.state.vendor_ID) {

                url = Constants.GET_PURCHASE_MONTHLY + "?export=" + type + "&month=" + this.state.monthvalue + "&year=" + this.state.yearValue;
            } else {
                url = Constants.GET_PURCHASE_MONTHLY + "?export=" + type + "&month=" + this.state.monthvalue + "&year=" + this.state.yearValue+"&vendor_id="+ this.state.vendor_ID;

            }
        } else if (this.state.heading == "Stock Report") {
            if (this.state.startTime && this.state.endTime) {

                url = Constants.GET_STOCKS_REPORT + "?export=" + type + "&from_date=" + this.state.startTime + "&to_date=" + this.state.endTime;
            } else {
                url = Constants.GET_STOCKS_REPORT + "?export=" + type;

            }
        } else if (this.state.heading == "MIS Report") {
            let vendorName1ID = this.state.vendorName1 ? this.state.vendorName1ID : ""
            let vendorName2ID = this.state.vendorName2 ? this.state.vendorName2ID : ""
            let vendorName3ID = this.state.vendorName3 ? this.state.vendorName3ID : ""
            url = Constants.GET_MIS_REPORT + "?export=" + type + "&vendor1=" + vendorName1ID + "&vendor2=" + vendorName2ID + "&vendor3=" + vendorName3ID + "&brand_name="+this.state.brandName+"&generic_name="+ this.state.genricName;

        } else if(this.state.heading == "Sales GST Splitups"){

            url = Constants.GET_SALES_GST_SPLITUPS + "?export="+ type + "&invoice_date=" + this.state.selectedMonth

        } else if(this.state.heading === "Consolidated Sales" ){

            var year = this.state.selectedYear != "" ? this.state.selectedYear : year
            url = Constants.CONSOLIDATED_REPORT_PRINT + "?invoice_year=" + year +"&export_type=" + type 

        } else if (this.state.heading === "Consolidated Sales Splitups"){

            url = Constants.CONSOLIDATED_REPORT_PRINT + "?invoice_date=" + this.state.selectedMonth +"&export_type=" + type 

        } else if(this.state.heading === "Expiry Stock") {

            var fromDate = this.state.fillterDate[0] ?  moment(this.state.fillterDate[0]).format('YYYY-MM-DD') : converted_date
            var toDate = this.state.fillterDate[1] ?  moment(this.state.fillterDate[1]).format('YYYY-MM-DD') : converted_date

            url = Constants.GET_EXPIRY_DRUG_REPORT + "?export_type=pdf" + "&brand_name=" + this.state.drugNameSearch   + "&from_date=" + fromDate + "&to_date=" + toDate

        } else if(this.state.heading === "Low Stock Drugs") {

            url = Constants.GET_REORDER_THRESHOLD_REPORT + "?export_type=pdf" + "&brand_name=" + this.state.drugNameSearch  

        } else if(this.state.heading === "Slow Movement Drugs") {

            var fromDate = this.state.fillterDate[0] ?  moment(this.state.fillterDate[0]).format('YYYY-MM-DD') : converted_date
            var toDate = this.state.fillterDate[1] ? moment(this.state.fillterDate[1]).format('YYYY-MM-DD') : converted_date

            url = Constants.GET_SLOWMOVEMENT_DRUG_REPORT + "?export_type=pdf" + "&brand_name=" + this.state.drugNameSearch + "&from_date=" + fromDate + "&to_date=" + toDate 

        } else if(this.state.heading === "No Movement Drugs") {

            var fromDate = this.state.fillterDate[0] ? moment(this.state.fillterDate[0]).format('YYYY-MM-DD') : converted_date
            var toDate = this.state.fillterDate[1] ? moment(this.state.fillterDate[1]).format('YYYY-MM-DD') : converted_date

            url = Constants.GET_NOMOVEMENT_DRUG_REPORT + "?export_type=pdf" + "&brand_name=" + this.state.drugNameSearch + "&from_date=" + fromDate + "&to_date=" + toDate

        } else if (this.state.heading == "Referral Doctor Report") {
            if (this.state.startTime && this.state.endTime) {

                url = Constants.REFERRED_DOCTOR_REPORT + "?export_type=" + type + "&from_date=" + this.state.startTime + "&to_date=" + this.state.endTime;
            } else {
                url = Constants.REFERRED_DOCTOR_REPORT + "?export_type=" + type;

            }
        }

        if( url ) {
            if (type == 'pdf') {
                this.service.getInstance().documentUploadGet(
                    url,
                    this.PrintReportSuccess,
                    this.PrintReportFailure,
                    "pdf"
                );
            } else {
                this.service.getInstance().documentUploadGet(
                    url,
                    this.exportCSVSuccessCallBack,
                    this.exportCSVSuccessCallBack,
                    "excel"
                );
            }
        }
    };

    PrintReportSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            // console.log(file)
            window.open(fileURL);
        }
    };

    PrintReportFailure = error => {
        // console.log("Get clinic list error response");
        console.log(error);
    };

    exportCSVSuccessCallBack = response => {
        if (response) {

            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
        }
    }

    exportCSVErrorCallBack = error => { }
    
    renderComponents() {
        var selectedScreen = this.state.selectedScreen;
        var components = {
            salesOverview: (<SalesOverview
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
            />),
            purchaseOverview: (<PurchaseOverview
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
            />),
            DayEndOverview: (<DayEndOverview
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                startTimeDayEndReport={this.state.startTimeDayEndReport}
                endTimeDayEndReport={this.state.endTimeDayEndReport}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)} />),
            SalesDetailed: (<SalesDetailed
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                patientName={this.state.patientName}
                drugName={this.state.drugName}
                ScheduleFilter={this.state.ScheduleFilter}
                isSchedule={this.state.isScheduleSearch}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                selectedSalesSearchOption = { this.state.selectedSalesSearchOption }
            />),
            salesGST: (<SalesGST
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                GstSplitupSelected = {this.GstSplitupSelected.bind(this)}
                isGstSplitupScreenSelected= {this.state.isGstSplitupSelected}
                selectedMonth = {this.state.selectedMonth}
                selectedSalesFilterYear={this.state.selectedSalesFilterYear}
            />),
            salesreturn: (<SalesReturn
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
            />),
            salesmonthly: (<SalesMonthly
                // startTime={this.state.startTime}
                // endTime={this.state.endTime}
                monthvalue={this.state.monthvalue}
                yearValue={this.state.yearValue}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
            />),
            stockOverview: (<StockOverview
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                delivery_date={this.state.currentDate}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
            />),
            purchaseDetailed: (<PurchaseDetailed
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                vendorName={this.state.vendorName}
                drugsName={this.state.drugsName}
                selectedPurchaseSearchOption={this.state.selectedPurchaseSearchOption}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}


            />),
            misReport: (<MisReport
                vendorName1ID={this.state.vendorName1ID}
                vendorName2ID={this.state.vendorName2ID}
                vendorName3ID={this.state.vendorName3ID}
                vendorName3={this.state.vendorName3}
                vendorName2={this.state.vendorName2}
                vendorName1={this.state.vendorName1}
                brandName={this.state.brandName}
                genericName={this.state.genricName}

                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)}

            />),
            purchaseGst: (<PurchaseGst
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
            />),
            purchaseReturn: (<PurchaseReturn
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
            />),
            purchaseMonthly: (<PurchaseMonthly
                monthvalue={this.state.monthvalue}
                yearValue={this.state.yearValue}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                VenderselectedID = {this.state.vendor_ID}
            />),
            lowStockDrugs: (<LowStockReport 
                monthvalue={this.state.monthvalue}
                yearValue={this.state.yearValue}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}  
                drugNameSearch = {this.state.drugNameSearch}           
            />),
            slowmovementdrugs: (<StockMovements 
                monthvalue={this.state.monthvalue}
                yearValue={this.state.yearValue}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                typeofReport = {this.state.selectedScreen} 
                drugNameSearch = {this.state.drugNameSearch}   
                fillterDate = {this.state.fillterDate}                   
            />),
            nomovementdrugs: (<StockMovements 
                monthvalue={this.state.monthvalue}
                yearValue={this.state.yearValue}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                typeofReport = {this.state.selectedScreen}   
                drugNameSearch = {this.state.drugNameSearch}     
                fillterDate = {this.state.fillterDate}                                 
            />),

            expiryStock: (<ExpiryDrugs 
                monthvalue={this.state.monthvalue}
                yearValue={this.state.yearValue}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                drugNameSearch = {this.state.drugNameSearch}    
                fillterDate = {this.state.fillterDate}                        
            />),
            GSTSplitUps: (<GSTSplitUps
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                selectedMonth = {this.state.selectedMonth}
            />),
            consolidatedSales: (<ConsolidatedSales
                selectedYear={this.state.selectedYear}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                consolidatedSelected={this.consolidatedSelected.bind(this)}
                isGstSplitupScreenSelected= {this.state.isGstSplitupSelected}
            />),
            ConsolidateSplitUps: (<ConsolidateSplitUps
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                selectedMonth = {this.state.selectedMonth}
            />),
            referralDocReport: (<ReferralDocReport
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
            />),
            patientReport: (<PatientReport
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                isclear={this.state.isclear}
                gettotalscoreValues={this.gettotalscoreValues.bind(this)}
                handlepropsvalueclear={this.handlepropsvalueclear.bind(this)}
                patientReportSearch= {this.state.patientReportSearch}
            />),
        }

        return components[selectedScreen];
    }


    gettotalscoreValues(key, total = {}) {
        var states = this.state
        if (key == "Sales Return") {
            states["TotalSalesReturnAmt"] = total
            states["heading"] = key
        } else if (key == "Referral Doctor Report") {
            states["TotalReferralDocAmt"] = total
            states["heading"] = key
        } else if (key == "Patient Report") {
            states["TotalPatientBillAmt"] = total
            states["heading"] = key
        } else if (key == "Day End") {
            states["cardAmt"] = total.card_total
            states["cashAmt"] = total.cash_total
            states["upiAmt"] = total.upi_total
            states["banktransferAmt"] = total.bank_transfer_total
            states["insuranceAmt"] = total.insurance_total
            states["returnAmt"] = total.return_total
            states["totalAmt"] = total.total_amount
            states["heading"] = key
        } else if (key == "Sales Monthly" || key == "Purchase Monthly") {
            if(key == "Purchase Monthly"){
                states["monthlyTotalAmount"] = total.total_amount ? parseFloat(total.total_amount).toFixed(2) : 0
                states["monthlyTotalReturn"] = total.return_amount ? parseFloat(total.return_amount).toFixed(2) : 0
            }else{
                states["monthlyTotalAmount"] = total ? parseFloat(total).toFixed(2) : 0
            }
            states["heading"] = key
        } else if (key == "Purchase Detailed" || key == "Sales Detailed") {
            states["PurchaseSGST"] = total.total_sgst_amount ? parseFloat(total.total_sgst_amount).toFixed(2) : 0
            states["PurchaseCGST"] = total.total_cgst_amount ? parseFloat(total.total_cgst_amount).toFixed(2) : 0
            states["purchasetotalAmt"] = total.total_amount ? parseFloat(total.total_amount).toFixed(2) : 0
            states["PurchaseDiscount"] = total.total_discount_amount ? parseFloat(total.total_discount_amount).toFixed(2) : 0
            states["purchaseNetAmount"] = total.total_net_total ? parseFloat(total.total_net_total).toFixed(2) : 0
            states["heading"] = key
        } else if (key == "Purchase Overview" || key == "Sales Overview") {
            states["PurchaseSGST"] = total.total_sgst_amount ? parseFloat(total.total_sgst_amount).toFixed(2) : 0
            states["PurchaseCGST"] = total.total_cgst_amount ? parseFloat(total.total_cgst_amount).toFixed(2) : 0
            states["PurchaseGST"] = total.total_gst_amount ? parseFloat(total.total_gst_amount).toFixed(2) : 0
            states["purchasetotalAmt"] = total.total_amount ? parseFloat(total.total_amount).toFixed(2) : 0
            states["PurchaseDiscount"] = total.total_discount_amount ? parseFloat(total.total_discount_amount).toFixed(2) : 0
            states["purchaseNetAmount"] = total.total_net_total ? parseFloat(total.total_net_total).toFixed(2) : 0
            states["heading"] = key
        } else if (key == "Purchase GST" || key == "Sales GST") {
            states["PurchaseSGST"] = total.total_sgst_amount ? parseFloat(total.total_sgst_amount).toFixed(2) : 0
            states["PurchaseCGST"] = total.total_cgst_amount ? parseFloat(total.total_cgst_amount).toFixed(2) : 0
            states["purchasetotalAmt"] = total.total_amount ? parseFloat(total.total_amount).toFixed(2) : 0
            states["heading"] = key
        } else if (key == "Purchase Return") {
            states["purchasetotal_price_per_quantity"] = total.total_price_per_quantity ? parseFloat(total.total_price_per_quantity).toFixed(2) : 0
            states["purchasetotalAmt"] = total.total_price ? parseFloat(total.total_price).toFixed(2) : 0
            states["heading"] = key
        } else if (key == "Stock Report") {
            states["heading"] = key
        } else if (key == "MIS Report") {
            states["vendorTotal1"] = total.vendor1_total ? parseFloat(total.vendor1_total).toFixed(2) : 0
            states["vendorTotal2"] = total.vendor2_total ? parseFloat(total.vendor2_total).toFixed(2) : 0
            states["vendorTotal3"] = total.vendor3_total ? parseFloat(total.vendor3_total).toFixed(2) : 0
            states["heading"] = key
        } else if( key == PAGE_LOWSTOCK ) {
            states["heading"] = key
        } else if( key == PAGE_EXPIRY_STOCK ) {
            states["heading"] = key
        } else if(key == PAGE_SLOWMOVEMENT) {
            states["heading"] = key;
        } else if(key == PAGE_NOMOVEMENT){
            states["heading"] = key;
        } else if(key == "Sales GST Splitups"){
            states["PurchaseCGST"] = total.total_cgst_amount ? parseFloat(total.total_cgst_amount).toFixed(2) : 0
            states["purchasetotalAmt"] = total.total_amount ? parseFloat(total.total_amount).toFixed(2) : 0
            states["heading"] = key;
        }else if (key == "Consolidated Sales") {
            states["heading"] = key
            states["purchaseTotal"] = total.purchase_total ? parseFloat(total.purchase_total).toFixed(2) : 0
            states["salesTotal"] = total.sales_total ? parseFloat(total.sales_total).toFixed(2) : 0
            states["profitTotal"] = total.profit_total ? parseFloat(total.profit_total).toFixed(2) : 0

        } else if(key == "Consolidated Sales Splitups") {
            states["heading"] = key
            states["purchaseTotal"] = total.purchase_total ? parseFloat(total.purchase_total).toFixed(2) : 0
            states["salesTotal"] = total.sales_total ? parseFloat(total.sales_total).toFixed(2) : 0
            states["profitTotal"] = total.profit_total ? parseFloat(total.profit_total).toFixed(2) : 0
        }


        this.setState({
            states
        },() => {
            this.props.reportCurrentPage(this.state.heading)
        })
    }

    handlepropsvalueclear(key) {
        this.setState({
            isclear: key
        })

    }
    GstSplitupSelected(key, month){
        this.setState({
            isGstSplitupSelected: false,
            selectedScreen : "GSTSplitUps",
            selectedMonth: month
        })
    }
    consolidatedSelected(key, month){
        this.setState({
            isGstSplitupSelected: false,
            selectedScreen : "ConsolidateSplitUps",
            selectedMonth: month
        })
    }


    // Back button and report row function
    renderBackbuttonAndText(selectedScreen) {
        var today = new Date();
        var year = today.getFullYear();  
        return (

            <View style={styles.BackbuttonContainer}>
                {selectedScreen ?
                    <TouchableOpacity onPress={() => {
                        this.setState({
                            startTime: "",
                            endTime: "",
                            filterDate: "",
                            TotalSalesReturnAmt: "",
                            TotalReferralDocAmt: "",
                            TotalPatientBillAmt: "",
                            heading: "",
                            cardAmt: "",
                            cashAmt: "",
                            totalAmt: "",
                            isclear: false,
                            monthvalue: "",
                            yearValue: "",
                            monthlyTotalAmount: "",
                            monthlyTotalReturn:"",
                            PurchaseSGST: "",
                            PurchaseCGST: "",
                            purchasetotalAmt: "",
                            PurchaseDiscount: "",
                            purchaseNetAmount: "",
                            purchasetotal_price_per_quantity: "",
                            vendorName1: "",
                            vendorName1ID: "",

                            vendorName2: "",
                            vendorName2ID: "",

                            vendorName3: "",
                            vendorName3ID: "",

                            genricName: "",
                            brandName: "",
                            VendorList1: [],
                            VendorList2: [],
                            VendorList3: [],

                            vendorTotal1: "",
                            vendorTotal2: "",
                            vendorTotal3: "",
                            selectedScreen: this.state.selectedScreen === "GSTSplitUps" ? "salesGST" : this.state.selectedScreen === "ConsolidateSplitUps" ? "consolidatedSales" : "",

                            Vendor_Data: "",
                            vendor_ID: "",
                            flagShowVendorList: false,
                            isSelected: false,
                            SearchVendorlist : this.state.Vendorlist,
                            selectedYear : year,

                            drugName: "",
                            patientName: "",
                            vendorName: "",
                            drugsName: "",
                            selectedSalesSearchOption: "Patient Name",
                            selectedPurchaseSearchOption: "Vendor Name",
                            vendorList: [],
                            brandNameSuggest: [],
                            genericNameSuggest: [],
                            fillterDate: [],
                            selectedSalesFilterYear: new Date().getFullYear(),
                            drugNameSearch: "",
                            heading: ""
                        },()=>{
                            this.props.reportCurrentPage(this.state.heading)
                        })
                    }} style={styles.BackbuttonWrapper}>
                        <View style={styles.BackIconContainer}>
                            <Image
                                style={styles.BackIcon}
                                source={require('../../../assets/images/Return.png')}
                            />
                            <Text style={styles.BackIconText}>{"Back"}</Text>
                        </View>
                    </TouchableOpacity> : null}
                <View>
                    <Text style={styles.reportIcontext}>{"Reports"} </Text>
                </View>
            </View>
        )
    }
    // Common History Section Header function
    renderHeader() {
        return (

            <View style={{ marginHorizontal: "1.30vw", marginTop: ".65vw" }}>
                <CommonReportSectionHeader
                    heading1={"Sales"}
                    heading2={"Purchase"}
                    heading3={"Inventory"}
                    columnSize={[0.37, 0.38, 0.25]}  //total value is == of 1
                    noOfColumn={3}
                    headerKey={"reportHeader"}
                />
            </View>
        )
    }
    // Touchable Opacity function
    renderReportOnpress=(type, key)=>{

        let { monthvalue, yearValue } = this.state;
        let {isExpiredrug, expiredCount, isLowStock, stockListCount} = this.props
        let todayYMD = moment().format(dateFormat)
        let start_time = converted_date
        let end_time = converted_date
        let defaultSelectedDateFilter = "All"
        if( key == "DayEndOverview" ) {

            start_time = todayYMD;
            end_time = todayYMD;
            defaultSelectedDateFilter = "Today"
        } 
        if( key == "salesmonthly" || key == "purchaseMonthly" ) {
            
            monthvalue = moment().format("MM")
            yearValue = moment().format("YYYY")
        }

        var countLabel = ""
    
        if(type === "Expiry Stock" && isExpiredrug){
            countLabel = `( ${expiredCount} )` 
        }else if(type === "Low Stock Drugs" && isLowStock ){
            countLabel = `( ${stockListCount} )`
        }
            
        

        return (
            <TouchableOpacity
                onPress={() => {

                    this.setState({
                        selectedScreen: key,
                        startTime: start_time,
                        endTime: end_time,
                        defaultSelectedDateFilter: defaultSelectedDateFilter,
                        monthvalue: monthvalue,
                        yearValue: yearValue
                    })
                }}
            >
                <Text style={{ fontWeight: "500", fontSize:'1vw' }}>{type + " "}
                <Text style={{color: color.red}}>{countLabel}</Text>
                </Text>
            </TouchableOpacity>
        )
    }
    // Render Content Row function Key and 
    renderContentRow(sales, salseKey, purchase, purchaseKey, inventory, inventoryKey) {
        return (
            <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: ".1vw", paddingVertical: "1.3vw" }}>
                <View style={[{ flex: 0.37, marginLeft: "3.25vw", marginHorizontal: "1.3vw" }]}>{this.renderReportOnpress(sales, salseKey)}</View>
                <View style={[{ flex: 0.38 }]}>{this.renderReportOnpress(purchase, purchaseKey)}</View>
                <View style={[[{ flex: 0.25 }]]}>{this.renderReportOnpress(inventory , inventoryKey)}</View>
            </View>
        )
    }
    // Report List Views Function
    renderContent() {
        return (
            // Report List Views
            <View style={{ marginHorizontal: "1.3vw", backgroundColor: "#F9F9F9", marginTop: "1vw" }}>
                {this.renderContentRow(
                    "Sales Overview", "salesOverview",
                    "Purchase Overview", "purchaseOverview",
                    "Stock Overview", "stockOverview")}
                {this.renderContentRow(
                    "Sales Detailed", "SalesDetailed",
                    "Purchase Detailed", "purchaseDetailed",
                    "MIS Report", "misReport")}
                {this.renderContentRow(
                    "Sales GST", "salesGST",
                    "Purchase GST", "purchaseGst",
                    PAGE_EXPIRY_STOCK, PAGE_EXPIRY_STOCK_KEY
                )}
                {this.renderContentRow(
                    "Sales Return", "salesreturn",
                    "Purchase Return", "purchaseReturn",
                    PAGE_LOWSTOCK, PAGE_LOWSTOCK_KEY
                )}
                {this.renderContentRow(
                    "Day End", "DayEndOverview",
                    "Purchase Monthly", "purchaseMonthly",
                    PAGE_SLOWMOVEMENT, PAGE_SLOWMOVEMENT_KEY
                )}
                {this.renderContentRow(
                    "Sales Monthly", "salesmonthly",
                    CONSOLIDATE_SALES, CONSOLIDATE_SALES_KEY,
                    PAGE_NOMOVEMENT, PAGE_NOMOVEMENT_KEY

                )}
                {this.props.pharmacyType === "Standalone Pharmacy" ? this.renderContentRow(
                    "Referral Doctor Report", "referralDocReport",
                    "Patient Report", "patientReport",
                    "", ""

                ) : null}

                {/* End Report List View */}

            </View>
        )
    }
    // Color Card View  function:
    renderCard(color, icon, value, placehold) {
        return (
            <View style={[styles.paymentCardContainer,{backgroundColor: color}]}>
                <View style={styles.paymentCardWrapper}>
                    <View style={styles.cardTooltipDiv} >
                      { value && value.length > 11 ?
                       <Tooltip placement="topLeft" title={value}>               
                        <Text style={styles.TooltipText}> 
                         { value && value.toString().length > 11 ? value && (value.toString()).slice(0,11) + ".." : ''}
                        </Text> 
                       </Tooltip>  : 
                       <Text style={{ color: "white", fontSize: value && value.toString().length > 7 ? ".8vw" : "1.01vw", fontWeight: "bold" }}>{value}</Text>     
                      }
                    </View>
                    <View style={{ marginRight: ".3vw" }}>
                        <Image
                            style={[styles.Image, { width: this.state.heading == "Purchase Detailed" || this.state.heading == 'Purchase Overview' || this.state.heading == 'Sales Overview' || this.state.heading == "Sales Detailed" ? "1.30vw" : "1.30vw", height: this.state.heading == "Purchase Detailed" || this.state.heading == 'Purchase Overview' || this.state.heading == 'Sales Overview' || this.state.heading == "Sales Detailed" ? "1.30vw" : "1.30vw" }]}
                            source={icon}
                        />
                    </View>
                </View>
                <View style={styles.cardPlaceholderDiv}>
                    <Text style={styles.cardPlaceholderText}>{placehold}</Text>
                </View>
            </View>
        )
    }

    onFocusInput = (stateKey) =>{
        let {flagShowVendorList} = this.state

        if (stateKey == "Vendor_Name") {
            if (!flagShowVendorList) {
                this.setState({ flagShowVendorList: true })
            }
        }
    }
    onBlurInput = (stateKey) => {
        let { isSelected } = this.state;

        if (stateKey == "Vendor_Name") {
            if (!isSelected) {
                this.setState({ 
                    flagShowVendorList: false })
            }                      
        }
               
    }
    itemSelected = (item, key) => {
        if (item) {
            if (key === "Vendor_Name") {
                this.setState({
                    vendor_ID: item.vendor_id,
                    Vendor_Data: item.name,
                    isSelected: true,
                    flagShowVendorList: false,
                    isclear:true
                })
            }            
        }
    }
    onChangeVendor = (stateKey, value) =>{
        if (stateKey == "Vendor_Name") {
            this.setState({
                Vendor_Data: value,
                isSelected: false,
                flagShowVendorList: true
            },
            ()=>{
                if(this.state.Vendor_Data != ""){
                    this.VendorNameAutoSearch(this.state.Vendor_Data)
                }
                else {
                    this.setState({
                        SearchVendorlist : this.state.Vendorlist
                    })
                }
            }) }
    }
    VendorNameAutoSearch =(item)=>{

        let tempVendorList = this.state.Vendorlist
        if(tempVendorList.length > 0) {
         let searchListTemp = [];
         for (let i = 0; i < tempVendorList.length; i++) {
           if (tempVendorList[i].name.toLowerCase().indexOf(item.toLowerCase()) > -1) {
             searchListTemp.push(tempVendorList[i]);
           }
         }
         this.setState({
            SearchVendorlist :searchListTemp
         })}                 
    }
    onChange = ( dateString ) => {
        
        this.setState({
            fillterDate: dateString,
            isclear: true
        })

    
      }
    renderSalesOverViewHeader() {
        var getdate = [];
        var today = new Date();
        var year = today.getFullYear();
        for (var i = year; i > 2009; i--) {
          getdate.push(i);
        };
        return (
            <View style={{ flex: 1, flexDirection: "row" }}>
                {/* <View style={{ }}>
                    </View> */}
             <View style={{ flex: 0.9, flexDirection: "row", justifyContent: "flex-end", alignSelf: "flex-end" }}>          
                <View style={{ flexDirection: "row" }}>

                {this.state.heading == "Purchase Monthly" ? 
                <View style={{ fontSize: '1vw', marginTop:'.3vw', marginRight: '.7vw', alignSelf: "center"}}>
                <CommonAutoSuggestionFull
                        width={'14.5vw'} type={""} align={""}
                        placeholder={"Vendor Name"} 
                        stateKey={"Vendor_Name"} value ={this.state.Vendor_Data}
                        isSelected={this.state.isSelected} showList={this.state.flagShowVendorList}
                        list={this.state.SearchVendorlist}
                        objFormat={{keyName: "name"}}
                        onFocusInput={this.onFocusInput} onBlurInput={this.onBlurInput}                        
                        itemSelected={this.itemSelected}
                        onChangeText={this.onChangeVendor}
                    />
                </View>
                : null}

                    {this.state.heading == 'Sales Return' ?
                        <View>
                            {this.renderCard(color.cardOrange, require('../../../assets/images/Rupee.png'), this.state.TotalSalesReturnAmt ? this.state.TotalSalesReturnAmt : 0, "Returned Amount")}
                        </View>
                        : this.state.heading === "Referral Doctor Report" ? 
                        <View>
                            {this.renderCard(color.cardOrange, require('../../../assets/images/Rupee.png'), this.state.TotalReferralDocAmt ? this.state.TotalReferralDocAmt : 0, "Total Amount")}
                        </View>
                        : this.state.heading === "Patient Report" ?
                        <View>
                            {this.renderCard(color.cardOrange, require('../../../assets/images/Rupee.png'), this.state.TotalPatientBillAmt ? this.state.TotalPatientBillAmt : 0, "Total Amount")}
                        </View>
                        :
                        this.state.heading == 'Day End' ?
                            <View style={{ flexDirection: "row", alignItems: 'center' }}>
                                {this.renderCard("#DE1B1B", require('../../../assets/images/Rupee.png'), this.state.totalAmt, "Total")}
                                {this.renderCard("#E54900", require('../../../assets/images/Rupee.png'), this.state.cashAmt, "Cash")}
                                {this.renderCard("#9D358C", require('../../../assets/images/Rupee.png'), this.state.cardAmt, "Card")}
                                {this.renderCard("#1890ff", require('../../../assets/images/Rupee.png'), this.state.upiAmt, "UPI")}
                                {this.renderCard("#00b257", require('../../../assets/images/Rupee.png'), this.state.banktransferAmt, "Bank Transfer")}
                                {this.renderCard("#FF0084", require('../../../assets/images/Rupee.png'), this.state.insuranceAmt, "Insurance")}
                                {this.renderCard("#687a8b", require('../../../assets/images/Rupee.png'), this.state.returnAmt, "Return")}
                                {/* <View style={{ flexDirection: "column", justifyContent: "space-evenly" }}>
                                    <TimePicker use12Hours format="h:mm a" placeholder="From Time" /> 
                                    <TimePicker use12Hours format="h:mm a" placeholder="End Time" />
                                </View> */}
                                <View >
                                    <View style={{flexDirection: 'column'}}>
                                <View style={{ width: '8.51vw', fontSize: '0.78vw', marginRight: '0.32vw'}}>
                                    <TimePicker use12Hours format="h:mm a" placeholder="From Time"
                                        value={this.state.startTimeDayEndReport ? moment(this.state.startTimeDayEndReport, "HH:mm:ss") : null}
                                        onSelect={(value) => {
                                            const timeString = value ? moment(value).format("HH:mm:ss") : null;
                                            // setSelectedTime(timeString);
                                            console.log(timeString);
                                            this.setState({
                                                startTimeDayEndReport: timeString
                                            })
                                        }}
                                        onChange={(value) => {
                                            const timeString = value ? moment(value).format("HH:mm:ss") : null;
                                            // setSelectedTime(timeString);
                                            console.log(timeString);
                                            this.setState({
                                                startTimeDayEndReport: timeString
                                            })
                                        }}
                                    /></View>
                                <View style={{ width: '8.51vw', fontSize: '0.78vw' }}>
                                    <TimePicker use12Hours format="h:mm a" placeholder="End Time"
                                        value={this.state.endTimeDayEndReport ? moment(this.state.endTimeDayEndReport, "HH:mm:ss") : null}
                                        onSelect={(value) => {
                                            const timeString = value ? moment(value).format("HH:mm:ss") : null;
                                            // setSelectedTime(timeString);
                                            console.log(timeString);
                                            this.setState({
                                                endTimeDayEndReport: timeString
                                            })
                                        }}
                                        onChange={(value) => {
                                            const timeString = value ? moment(value).format("HH:mm:ss") : null;
                                            // setSelectedTime(timeString);
                                            console.log(timeString);
                                            this.setState({
                                                endTimeDayEndReport: timeString
                                            })
                                        }} /></View>
                                        </View>
                                        </View>
                            </View>
                            :
                            this.state.heading == 'Sales Monthly' || this.state.heading == 'Purchase Monthly' ?
                                <View style={{ flexDirection: "row" }}>
                                    {this.renderCard("#0482BE", require('../../../assets/images/Rupee.png'), this.state.monthlyTotalAmount, "Total")}
                                    {this.state.heading == 'Purchase Monthly' ? 
                                    <View>{this.renderCard(color.cardOrange, require('../../../assets/images/Rupee.png'), this.state.monthlyTotalReturn ? this.state.monthlyTotalReturn : 0, "Returned Amount")}</View> 
                                    : null}
                                </View>
                                :
                                this.state.heading == "Low Stock Drugs" || this.state.heading == "Expiry Stock" || this.state.heading == "Slow Movement Drugs" || this.state.heading == "No Movement Drugs" ?
                                <View style={{ flexDirection: "row"}}>
                                 { this.state.heading != "Low Stock Drugs" ? 
                                  <RangePicker
                                  style={{fontSize:'1vw', width:'20vw', height:'2.3vw'}}
                                        onChange={this.onChange} 
                                        value = {[moment(this.state.fillterDate[0]), moment(this.state.fillterDate[1]) ]}
                                        format = {"YYYY-MM-DD"}
                                        defaultValue = {[moment(this.state.fillterDate[0]), moment(this.state.fillterDate[1])]}
                                        clearIcon = {false}
                                        disabledDate={this.state.heading != "Expiry Stock"?current => {
                                           return current > moment()
                                        }: null}
                                        
                                   /> : null
                                 }
                                   <TextInput
                                   value={this.state.drugNameSearch}
                                   placeholder={"Brand name search ..." }
                                   onChangeText={(text) => {
                                           this.setState({
                                               drugNameSearch: text,
                                               isclear: true
                                           })
                                   }}
                                   style={[styles.searchTextBox,{backgroundColor: color.white,borderColor: color.lightGray}]}
                               />
                               </View>
                                // :
                                // this.state.heading == "Low Stock Drugs" || this.state.heading == "Expiry Stock" || this.state.heading == "Slow Movement Drugs" || this.state.heading == "No Movement Drugs" ?
                                //         <TextInput
                                //         value={this.state.drugNameSearch}
                                //         placeholder={"Brand name search ..." }
                                //         onChangeText={(text) => {
                                //                 this.setState({
                                //                     drugNameSearch: text,
                                //                     isclear: true
                                //                 })
                                //         }}
                                //         style={{
                                //             backgroundColor: color.white, color: "black", padding: 5, borderWidth: 1, borderColor: color.lightGray, borderRadius: 4
                                //         }}
                                //     />
                                : this.state.heading == 'Purchase Detailed' || this.state.heading == 'Sales Overview' || this.state.heading == 'Purchase Overview' || this.state.heading == "Sales Detailed" ?
                                      <View style={{ flexDirection: "row" }}>
                                        {this.renderCard("#0482BE", require('../../../assets/images/Rupee.png'), this.state.purchasetotalAmt ? this.state.purchasetotalAmt : 0, "Taxable Amount")}

                                        {this.state.heading == 'Purchase Overview' ?
                                            <View>{this.renderCard("#9D358C", require('../../../assets/images/Tax.png'), this.state.PurchaseGST ? this.state.PurchaseGST : 0, "GST")}</View> :
                                            <View>{this.renderCard("#9D358C", require('../../../assets/images/Tax.png'), this.state.PurchaseCGST ? this.state.PurchaseCGST : 0, "CGST")}</View>}

                                        {this.state.heading !== 'Purchase Overview' ?
                                            <View>
                                                {this.renderCard("#E54900", require('../../../assets/images/Tax.png'), this.state.PurchaseSGST ? this.state.PurchaseSGST : 0, "SGST")}</View>
                                            : null}

                                        {this.renderCard("#00B257", require('../../../assets/images/Rupee.png'), this.state.PurchaseDiscount ? this.state.PurchaseDiscount : 0, "Discount Amount")}
                                        {this.renderCard("#687A8B", require('../../../assets/images/Rupee.png'), this.state.purchaseNetAmount ? Math.round(this.state.purchaseNetAmount) : 0, "Net Amount")}

                                        <View>
                                            {this.state.heading == "Sales Detailed" ?
                                                <View style= {{ padding: '0.65vw' }}>
                                                   <TextInput
                                                        value={ this.state.isSearchOPtion ? this.state.patientName : this.state.isScheduleSearch ? this.state.ScheduleFilter :  this.state.drugName }
                                                        placeholder={ this.state.isSearchOPtion ? "Patient name search..." : this.state.isScheduleSearch ? "Schedule Search..." : "Drug name search..."  }
                                                        onChangeText={(text) => { 
                                                            if(this.state.isSearchOPtion) {
                                                                this.setState({
                                                                    patientName: text
                                                                })
                                                            } else if(this.state.isScheduleSearch){
                                                                this.setState({
                                                                    ScheduleFilter: text
                                                                })
                                                            }
                                                            else {
                                                                this.setState({
                                                                    drugName: text
                                                                })
                                                            }                                                        }}
                                                        style={[styles.SearchInputText,{ height:'3vw', width:'21vw'}]}
                                                    />                                                  
                                                    <View style={styles.FilterRadioView}>
                                                        {searchData.map((item, index) => {
                                                            return <TouchableOpacity
                                                                onPress={() => {
                                                                    if (item.value == "Patient Name") {
                                                                        this.setState({
                                                                            isSearchOPtion: true,
                                                                            isScheduleSearch:false,
                                                                            selectedSalesSearchOption: item.value,
                                                                            drugName: "",
                                                                            ScheduleFilter: "",
                                                                        })
                                                                    } 
                                                                    else if(item.value == "Schedule"){
                                                                        this.setState({
                                                                            isScheduleSearch: true,
                                                                            isSearchOPtion: false,
                                                                            selectedSalesSearchOption: item.value,
                                                                            drugName: "",
                                                                            patientName: ""
                                                                        })
                                                                    }
                                                                    else {
                                                                        this.setState({
                                                                            isSearchOPtion: false,
                                                                            isScheduleSearch:false,
                                                                            selectedSalesSearchOption: item.value,
                                                                            ScheduleFilter: "",
                                                                            patientName: ""
                                                                        })
                                                                    }
                                                                }}
                                                                style={styles.RadioButtonClick}
                                                                key={index} value={item.value == "Patient Name" ? this.state.isPatientName : this.state.isDrugName}
                                                            >
                                                                <View style={[
                                                                    {backgroundColor: (this.state.selectedSalesSearchOption == item.value) ? color.themeDark : ""},
                                                                    styles.RadioButton]}>
                                                                </View>
                                                                <Text style={styles.RadioLabelText}>{item.label}</Text>

                                                            </TouchableOpacity>
                                                        })}
                                                    </View>
                                                </View> : null
                                            }
                                        </View>
                                        <View>
                                            { this.state.heading == 'Purchase Detailed' ?
                                                <View style= {{ padding: 10 }}>
                                                    <TextInput
                                                        value={ this.state.isSearchOPtion ? this.state.vendorName : this.state.drugsName }
                                                        placeholder={ this.state.isSearchOPtion ? "Vendor name search..." : "Drug name search..."  }
                                                        onChangeText={(text) => { 
                                                            if(this.state.isSearchOPtion) {
                                                                this.setState({
                                                                    vendorName: text
                                                                })
                                                            } else {
                                                                this.setState({
                                                                    drugsName: text
                                                                })
                                                            }                                                        }}
                                                            style={styles.SearchInputText}
                                                    />
                                                    <View style={styles.FilterRadioView}>
                                                        {vendorData.map((item, index) => {
                                                            return <TouchableOpacity
                                                                onPress={() => {
                                                                    if (item.value == "Vendor Name") {
                                                                        this.setState({
                                                                            isSearchOPtion: true,
                                                                            selectedPurchaseSearchOption: item.value,
                                                                            drugsName: ""
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            isSearchOPtion: false,
                                                                            selectedPurchaseSearchOption: item.value,
                                                                            vendorName: ""
                                                                        })
                                                                    }
                                                                }}
                                                                style={styles.RadioButtonClick}
                                                                key={index} value={ this.state.isSearchOPtion }
                                                            >
                                                                <View style={[
                                                                    {backgroundColor: (this.state.selectedPurchaseSearchOption == item.value) ? color.themeDark : ""},
                                                                    styles.RadioButton]}>
                                                                </View>
                                                                <Text style={styles.RadioLabelText}>{item.label}</Text>

                                                            </TouchableOpacity>
                                                        })}
                                                    </View>
                                                </View> : null
                                            }
                                        </View>
                                    </View> :
                                    this.state.heading == 'Purchase GST' || this.state.heading == 'Sales GST' ?
                                     <View style={{ flexDirection: "row" }}>
                                       
                                            {this.renderCard("#0482BE", require('../../../assets/images/Rupee.png'), this.state.purchasetotalAmt ? this.state.purchasetotalAmt : 0, this.state.heading == 'Purchase GST' ? "Total GST Amount" : "Total GST Amount")}
                                            {this.renderCard("#9D358C", require('../../../assets/images/Tax.png'), this.state.PurchaseCGST ? this.state.PurchaseCGST : 0, "CGST")}
                                            {this.renderCard("#E54900", require('../../../assets/images/Tax.png'), this.state.PurchaseSGST ? this.state.PurchaseSGST : 0, "SGST")}
                                            <View>
                                            {  this.state.heading == "Sales GST" ?
                                                <Picker
                                                    selectedValue={this.state.selectedSalesFilterYear}
                                                    style={[Style.pickerRoundCornerView, styles.YearDropDown ]}
                                                    itemStyle={Style.pickerFont}
                                                    onValueChange={(itemvalue) => {
                                                        var states = this.state;
                                                        states["isclear"] = true;
                                                        states["selectedSalesFilterYear"] = itemvalue;
                                                        this.setState({ states });
                                                    }}>
                                                    {
                                                        getdate.map((value, index) => {
                                                            return <Picker.Item label={value} value={value} />
                                                        })
                                                    }
                                                </Picker>: null
                                               }
                                            </View>
                                        </View> :
                                        this.state.heading == 'Purchase Return' ?
                                            <View style={{ flexDirection: "row" }}>
                                                {this.renderCard("#9D358C", require('../../../assets/images/Rupee.png'), this.state.purchasetotal_price_per_quantity, "Purchase Price/Quantity")}
                                                {this.renderCard("#0482BE", require('../../../assets/images/Rupee.png'), this.state.purchasetotalAmt, "Total")}
                                            </View> :
                                            this.state.heading == 'MIS Report' ?
                                                <View style={{ flexDirection: "row" }}>
                                                    {this.renderCard("#0482BE", require('../../../assets/images/Rupee.png'), this.state.vendorTotal1 ? this.state.vendorTotal1 : 0, "Vendor 1")}
                                                    {this.renderCard("#9D358C", require('../../../assets/images/Rupee.png'), this.state.vendorTotal2 ? this.state.vendorTotal2 : 0, "Vendor 2")}
                                                    {this.renderCard("#E54900", require('../../../assets/images/Rupee.png'), this.state.vendorTotal3 ? this.state.vendorTotal3 : 0, "Vendor 3")}

                                                </View>:
                                                 this.state.heading == "Consolidated Sales" || this.state.heading == "Consolidated Sales Splitups" ?
                                                  <View style={{ flexDirection: "row",justifyContent : "center", alignSelf: "center", flex: 1 }}>
                                                  {this.renderCard("#9D358C", require('../../../assets/images/Rupee.png'), this.state.purchaseTotal, "Purchase Total")}
                                                  {this.renderCard("#0482BE", require('../../../assets/images/Rupee.png'), this.state.salesTotal, "Sales Total")}
                                                  {this.renderCard(this.state.profitTotal > 0 ? color.cardGreen : color.red, require('../../../assets/images/Rupee.png'), this.state.profitTotal, "Profit Total")}
                                                </View> : null
                                            }



                </View>


                <View style={{ flexDirection: 'row', justifyContent: "center", alignContent: "center", alignSelf: "center" }}>
                    <View style={{ alignSelf: "center" }}>
                        {this.state.heading == 'Sales Monthly' || this.state.heading == 'Purchase Monthly'?


                            <DatePicker
                                disabledDate={current => {
                                    return current && current > moment().add(0, "days");
                                }}
                                defaultValue={this.state.monthvalue ? moment(this.state.monthvalue, "MM-YYYY") : moment()}
                                onChange={(date) => {
                                    this.setState({
                                        yearValue: moment(date).format('YYYY'),
                                        monthvalue: moment(date).format('MM'),
                                        isclear: true
                                    }, () => {
                                    })
                                }}
                                clearIcon={true}
                                placeholder={""}
                                format="MM-YYYY"
                                picker="month"
                                style={{
                                    zIndex: -1,
                                    width: "85%",
                                    borderColor: "#CDD1D5",
                                    height: '2.3vw',
                                    borderRadius: '.7vw',
                                    borderWidth: 1,
                                    marginTop: '.3vw',
                                    fontSize: '1.2vw'
                                }}
                            />
                            :
                            this.state.heading == "Consolidated Sales" ?
                                <View>
                                    <Picker
                                        enabled={!this.state.isLocked}
                                        selectedValue={this.state.selectedYear}
                                        style={[Style.pickerRoundCornerView, { height: 35,marginTop: 10, borderRadius: 4 }]}
                                        itemStyle={Style.pickerFont}
                                        onValueChange={(itemvalue) => {
                                            var states = this.state;
                                            states["selectedYear"] = itemvalue;
                                            states["isclear"] = true
                                            this.setState({ states });
                                        }}>
                                        {
                                            getdate.map((value, index) => {
                                                return <Picker.Item label={value} value={value} />
                                            })
                                        }
                                    </Picker>
                                </View> : 
    
                            this.state.heading == "Stock Report" || this.state.heading == "MIS Report" || this.state.heading == PAGE_LOWSTOCK || this.state.heading == PAGE_EXPIRY_STOCK || this.state.heading === PAGE_NOMOVEMENT || this.state.heading == PAGE_SLOWMOVEMENT || this.state.heading == "Sales GST Splitups" || this.state.heading == "Consolidated Sales" || this.state.heading == CONSOLIDATE_SPLITUP || this.state.heading == "Sales GST" ? null :
                                <CommonDateFilter
                                    removeObject = {!this.state.isAdminAccess ? Constants.remove_Obj_user : ""}
                                    startDate={this.state.startTime}
                                    endDate={this.state.endTime}
                                    filterDate={this.state.filterDate}
                                    showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                                    defaultSelectedDateFilter={"Today"} 
                                    // on outside focus, close popup
                                    showDateFilter={this.state.showDateFilter} 
                                    disableDateFilter={this._disableDateFilter.bind(this)}
                                />

                        }
                        {this.state.heading == "Stock Report" ?
                            <View style={{flexDirection: 'row'}}><CommonDatePicker showPlaceHolder={true} placeholder={"Select Date"}  stateKey={"delivery_date"} value={this.state.currentDate} defaultSelectedDateFilter={"Today"} 
                               onChangeText={this._onChangeDate.bind(this)} nolabel={true} noborder={true} allowClear={false} />
                            <TouchableOpacity onPress={() => {
                                    this.ClearDate()
                                }}><Image style={styles.StockExportIcon} source={require('../../../assets/Icon/Export – 1.png')}></Image></TouchableOpacity>
                            </View>: null
                        }


                    </View>
                </View>
              </View>  
            { this.state.heading === "Patient Report" ?
            <View>
                <TextInput
                value={this.state.patientReportSearch}
                placeholder={"Patient search ..." }
                onChangeText={(text) => {
                        this.setState({
                            patientReportSearch: text,
                            isclear: true
                        })
                }}
                style={[styles.searchTextBox,{backgroundColor: color.white,borderColor: color.lightGray}]}
                />
            </View>
            : null}
                <View style={{ flex: 0.1,justifyContent: "flex-end", flexDirection: "row" }}>
                    {!(this.state.heading == PAGE_LOWSTOCK || this.state.heading == PAGE_EXPIRY_STOCK || this.state.heading === PAGE_NOMOVEMENT || this.state.heading == PAGE_SLOWMOVEMENT || this.state.heading === CONSOLIDATE_SALES || this.state.heading === CONSOLIDATE_SPLITUP || this.state.heading === "Referral Doctor Report" || this.state.heading === "Patient Report")?
                        <View style={styles.printIconContainer} >
                            <TouchableOpacity
                                onPress={() => {
                                    this.PrintReport('excel')
                                }}
                                style={styles.printIconWrapper}>
                                <Image style={styles.Image}
                                    source={require('../../../assets/images/HomeScreenImages/Export.png')} />
                            </TouchableOpacity>
                        </View>
                    :null}
                    
                    {(this.state.heading == PAGE_LOWSTOCK || this.state.heading == PAGE_EXPIRY_STOCK || this.state.heading === PAGE_NOMOVEMENT || this.state.heading == PAGE_SLOWMOVEMENT || this.state.heading == "Sales Overview" || this.state.heading == "Referral Doctor Report")?
                        <View style={styles.printIconContainer} >
                            <TouchableOpacity
                                onPress={() => {
                                    this.PrintReport()
                                }}
                                style={styles.printIconWrapper}>
                                <Image style={styles.Image}
                                    source={require('../../../assets/images/HomeScreenImages/Print.png')} />
                            </TouchableOpacity>
                        </View>
                    :null}
                {(this.state.heading === PAGE_NOMOVEMENT || this.state.heading == PAGE_SLOWMOVEMENT)?
                        <View style={[styles.printIconContainer, {display: 'none' }]} >
                        <TouchableOpacity
                            onPress={() => {
                                this.PrintReport()
                            }}
                            style={styles.printIconWrapper}>
                            <Image style={styles.Image}
                                source={require('../../../assets/images/HomeScreenImages/Print.png')} />
                        </TouchableOpacity>
                    </View> : null
                    }

                {(this.state.heading === "Day End"  || this.state.heading == "Sales GST" || this.state.heading === CONSOLIDATE_SALES || this.state.heading === CONSOLIDATE_SPLITUP )?
                        <View style={[styles.printIconContainer, {display: 'block' }]} >
                        <TouchableOpacity
                            onPress={() => {
                                this.PrintReport()
                            }}
                            style={styles.printIconWrapper}>
                            <Image style={styles.Image}
                                source={require('../../../assets/images/HomeScreenImages/Print.png')} />
                        </TouchableOpacity>
                    </View> : null
                    }
                    {(this.state.heading === "Sales GST Splitups") ?
                    <View style={[styles.printIconContainer, {display: 'none' }]} >
                    <TouchableOpacity
                        onPress={() => {
                            this.PrintReport()
                        }}
                        style={styles.printIconWrapper}>
                        <Image style={styles.Image}
                            source={require('../../../assets/images/HomeScreenImages/Print.png')} />
                    </TouchableOpacity>
                </View> : null 
                    }

                </View>
            </View>

        )
    }
    showSelectedFilteredDate(date) {
        this.setState({
            isclear: true,
            startTime: date.fromDate,
            endTime: date.toDate,
            showDateFilter: false
        })
    }
    _disableDateFilter=(flag)=>{
        this.setState({
            showDateFilter: flag
        })
    }
    _getMisBrandName = (brandName) =>{
        let url = Constants.SEARCH_DRUG + "?brand_name=" + brandName;
        this.service.getApiCall(
            url,
            this._getBrandNameSuccess,
            this._getBrandFailure
        )
    }
    _getBrandNameSuccess = (response) => {
       if(response.status == "success"){
           this.setState({
            brandNameSuggest: response.data
           })
       }
    }
    onPressBrandName = () => {
        let url = Constants.PO_DRUG_DETAILS_BY_DRUGNAME + "?brand_name=" + this.state.brandName ;
        this.service.getApiCall(
            url,
            this._genericNameSuccess,
            this._getBrandFailure
        )
    }
    _genericNameSuccess = (response) => {
       if(response.status == "success"){
           this.setState({
              genericNameSuggest: response.data,
              brandNameSuggest: []
           })
       }
    }
    _getVendorList = (item) => {
        let url = Constants.VENDOR_LIST + "?brand_name=" + this.state.brandName + "&generic_name=" + this.state.genricName;
        this.service.getApiCall(
            url,
            this._getVendorNameSuccess,
            this._getBrandFailure
        )
    }
    _getVendorNameSuccess = (response) => {
        if(response.status == "success"){
            this.setState({
                vendorList: response.data,
                genericNameSuggest: []
            })
        }
     }
    _onFocus = (selectedVendor) => {
       this.setState({
        selectedVendor: selectedVendor,
        isOnFocus: true
       })
    }
    _onBlur = () => {
        this.setState({
            isOnFocus: false,
            selectedVendor: ""
        })
    }
    getDisabled =(textBoxkey) =>{
        if((textBoxkey =="vendorName2" || textBoxkey =="vendorName3")&& this.state.vendorName1==""){
            return true;
        }
    }
    renderCommonTextBox(placeholder, value, textBoxkey, contect) {
        return (
            <View style={styles.CommonTextboxContainer}>
              <View style= {{ flex: 0.2 }}>  
                <Text style={styles.CommonTextboxplaceholder}>{placeholder}</Text>

                <TextInput
                    style={[styles.CommonTextboxTextInput,{backgroundColor: color.white}]}
                    disabled={this.getDisabled(textBoxkey)}
                    onFocus = {() => {
                        if(this.state.vendorName2==""){
                            this.setState({vendorName2ID: ""})
                        }
                        if(this.state.vendorName3==""){
                            this.setState({vendorName3ID: ""})
                        }
                       if(textBoxkey == "vendorName1" || textBoxkey == "vendorName2" || textBoxkey == "vendorName3") { 
                        this.setState({
                            onFocusKey: textBoxkey
                        })
                       }
                    }}
                    onBlur = {() => {
                        this.setState({
                            onFocusKey: ""
                        })
                    }}
                    value={value}
                    onChangeText={(text) => {

                        var states = this.state;
                        states[textBoxkey] = text;

                        this.setState({ states }, () => {
                           if(textBoxkey == "brandName") {
                            this._getMisBrandName(text)
                           } 
                        })
                    }}
                />
                </View>
                <View style={{ flex: 0.2 }}>
                 { textBoxkey == "brandName" && this.state.brandNameSuggest && this.state.brandNameSuggest && this.state.brandNameSuggest.length > 0 ?
                        <View style={[styles.Textboxkey,{backgroundColor: color.white}]}>
                            <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: "13vw" }}>
                                <FlatList
                                    data={this.state.brandNameSuggest}
                                    renderItem={({ item }) => (
                                        <View style={styles.FlatList}
                                        >
                                            <View style={{ width: '100%' }}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        this.setState({
                                                            brandName: item
                                                        },()=>{
                                                            this.onPressBrandName()
                                                        })
                                                    }
                                                    }>
                                                    <Text style={{fontSize:".91vw"}}>{item}</Text>
                                                </TouchableOpacity>
                                            </View>

                                        </View>
                                    )}
                                    enableEmptySections={true}
                                />
                            </ScrollView>
                        </View>
                        : null}
                </View>
                <View style={{ flex: 0.2 }}>

                 { textBoxkey == "genricName" && this.state.genericNameSuggest && this.state.genericNameSuggest && this.state.genericNameSuggest.length > 0 ?
                        <View style={[styles.Textboxkey,{backgroundColor: color.white}]}>
                            <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: "13vw" }}>
                                <FlatList
                                    data={this.state.genericNameSuggest}
                                    renderItem={({ item }) => (
                                        <View style={styles.FlatList}
                                        >
                                            <View style={{ width: '100%' }}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        this.setState({
                                                            genricName: item.generic_name,
                                                        },()=>{
                                                            this._getVendorList(item)
                                                        })
                                                    }
                                                    }>
                                                    <Text style={{fontSize:'.91vw'}}>{item.generic_name}</Text>
                                                </TouchableOpacity>
                                            </View>

                                        </View>
                                    )}
                                    enableEmptySections={true}


                                />
                            </ScrollView>
                        </View>
                        : null}
                </View> 
                <View style={{ flex: 0.2 }}>
                    { this.state.onFocusKey == textBoxkey && this.state.vendorList && this.state.vendorList.length > 0  ?
                        <View style={[styles.Textboxkey,{backgroundColor: color.white}]}>
                            <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: "13vw" }}>
                                <FlatList
                                    data={this.state.vendorList}
                                    renderItem={({ item }) => (
                                        <View style={styles.FlatList}
                                        >
                                            <View style={{ width: '100%' }}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        var states = this.state
                                                       if(this.state.onFocusKey != textBoxkey && ( (textBoxkey == "vendorName1" && (this.state.vendorName2ID == item.vendor_id || this.state.vendorName3ID == item.vendor_id ) ) || (textBoxkey == "vendorName2" && (this.state.vendorName1ID == item.vendor_id || this.state.vendorName3ID == item.vendor_id ) ) || (textBoxkey == "vendorName3" && (this.state.vendorName2ID == item.vendor_id || this.state.vendorName1ID == item.vendor_id ) ))) {
                                                        this.responseSuccessErrorMessage("The vendor is already selected", false)
                                                        setTimeout(() => {
                                                            this.responseSuccessErrorMessage("", false)
                                                          }, 3000)
                                                       }else{
                                                        states[textBoxkey] = item.vendor_name  
                                                        if(states[textBoxkey] == item.vendor_name){
                                                            states[textBoxkey + "ID"] = item.vendor_id
                                                            this.setState({ states })
                                                        } else {
                                                            states[textBoxkey + "ID"] = ""
                                                            this.setState({ states })
                                                        }
                                                        this.setState({
                                                            states
                                                            
                                                        })
                                                       }
                                                    }
                                                    }>
                                                    <Text style={{fontSize:".91vw"}}>{item.vendor_name}</Text>
                                                </TouchableOpacity>
                                            </View>

                                        </View>
                                    )}
                                    enableEmptySections={true}


                                />
                            </ScrollView>
                        </View>
                        : null}
                </View>
            </View>
        )
    }

    renderMISVendorSearch() {
        return (
            <View style={styles.vendorSearchContainer}>
               <View style={styles.vendorSearchWrapper}>
                {this.renderCommonTextBox("Brand Name", this.state.brandName, "brandName")}
                {this.renderCommonTextBox("Generic Name", this.state.genricName, "genricName")}
                {this.renderCommonTextBox("Vendor 1", this.state.vendorName1, "vendorName1")}
                {this.renderCommonTextBox("Vendor 2", this.state.vendorName2, "vendorName2")}
                {this.renderCommonTextBox("Vendor 3", this.state.vendorName3, "vendorName3")}
               </View> 
                <View style={styles.searchButtonContainer}>
                    <TouchableOpacity onPress={() => {
                        this.setState({
                            isclear: true
                        })
                    }} style={[styles.searchButtonWrapper,{backgroundColor: color.themeBackgroud}]}>
                        <View style={styles.searchTextdiv}>
                            <Text style={styles.searchText}>{"Search"}</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    onPressVendor(key, item) {
        if (key == "vendor1") {
            this.setState({
                vendorName1: item.vendor_name ,
                vendorName1ID: item.vendor_id,
            }, () => {

            })
        } else if (key == "vendor2") {
            this.setState({
                vendorName2: item.vendor_name,
                vendorName2ID: item.vendor_id,
            }, () => {

            })
        } else if (key == "vendor3") {
            this.setState({
                vendorName3: item.vendor_name,
                vendorName3ID: item.vendor_id,
            }, () => {

            })
        }
    }

    render() {
        let { showResponse, showErrorResponse, responseMessage } = this.state
        return ( 
            <TouchableWithoutFeedback onPress={() => { this._clearDropdowns() }} >
                <View style={{ minHeight: screenHeight - 65 }}>
                    {/* first Row Back and Report  */}
                    <View style={{ flexDirection: "row", marginTop: 15, marginBottom: 5, alignItems: 'center' }}>
                        {/* <View style={{ width: this.state.heading == "Day End" ? "15%" : "20%" }}>
                            <View style={{ flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center' }}>
                                {this.renderBackbuttonAndText(this.state.selectedScreen)}
                                {
                                    this.state.selectedScreen ?
                                        <View><Text style={{ fontWeight: 500, color: color.themeBackgroud, fontSize: 16, alignSelf: "center" }}
                                        >{"-"}{this.state.heading}</Text></View> : null
                                }
                            </View>
                        </View> */}
                        <View style={{ width: "100%", paddingRight: 20 }}>
                            {
                                this.state.selectedScreen ?
                                    this.renderSalesOverViewHeader() : null

                            }
                        </View>

                    </View>
                    { this.state.heading == "MIS Report" ?
                        <View>
                            {this.renderMISVendorSearch()}
                        </View> : null}


                    <View style={{ zIndex: -1 }}>
                        {
                            this.state.selectedScreen ?
                                this.renderComponents() :
                                <View>
                                    {this.renderHeader()}
                                    <View style={{ zIndex: -1 }}>
                                        {this.renderContent()}
                                    </View>
                                </View>

                        }
                    </View>

                    {
                        showResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }

                </View>
            </TouchableWithoutFeedback>
        );
    }

    _clearDropdowns=()=>{
        if(this.state.heading == "Purchase Monthly" && !this.state.Vendor_Data && !this.state.flagShowVendorList) {
            this.setState({
                vendor_ID:'',
                isclear:true
            })
        }   

        let { showDateFilter } = this.state

        if( showDateFilter ) {

            this.setState({ showDateFilter: false })
        }
    }
    _onChangeDate=(stateKey, value, type)=>{
        this.setState({ currentDate: moment(value).format("YYYY-MM-DD") })
        //this.renderComponents();
    }
    ClearDate=()=>{
        this.setState({currentDate: ""})
    }

}

const styles = StyleSheet.create({
    BackbuttonContainer:{ 
        marginLeft: "1.30vw", 
        flexDirection: "row" 
    },
    BackbuttonWrapper:{ 
        backgroundColor: color.themeBackgroud, 
        width: "4.55vw", 
        height: "1.95vw", 
        borderRadius: ".3vw" 
    },
    BackIconContainer:{ 
        flexDirection: "row", 
        justifyContent: "center", 
        marginTop: ".3vw" 
    },
    BackIcon: {
        width: "1.30",
        height: "1.30",
    },
    BackIconText:{ 
        color: "#ffffff", 
        fontSize:".91vw" 
    },
    Image: {
        width: "1.95vw",
        height: "1.95vw",
    },
    searchTextBox:{ 
        color: "black", 
        padding: ".5vw", 
        borderWidth: ".1vw", 
        borderRadius: ".2vw", 
        marginLeft: ".65vw", 
        fontSize:'1vw',
        height:'2.3vw'
    },
    reportIcontext:{ 
        fontWeight: 500, 
        marginLeft: ".65vw", 
        marginTop: ".3vw" 
    },
    printIconContainer:{ 
        marginLeft: ".65vw", 
        alignSelf: "center", 
        alignItems: "center" 
    },
    printIconWrapper:{ 
        width: "2.60", 
        height: "2.3vw", 
        borderRadius: ".25vw", 
        backgroundColor: color.themeBackgroud, 
        paddingHorizontal: ".3vw", 
        paddingVertical: ".15vw" 
    },
    StockExportIcon:{
        width:"1.30vw", 
        height:"1.30vw", 
        backgroundColor: color.themeBackgroud
    },
    SearchInputText:{
        backgroundColor: color.white, color: "black", padding: '0.32vw', borderWidth: 1, borderColor : color.lightGray, borderRadius : 4, fontSize:'0.91vw', width:'17vw'
    },
    FilterRadioView:{
        flexDirection: "row", padding : '0.65vw' 
    },
    RadioButtonClick:{
        marginRight: '1.30vw', flexDirection: "row", alignItems: "center" 
    },
    RadioLabelText:{
       fontWeight: "500", fontSize: '0.84vw' 
    },
    RadioButton:{
        marginRight: '0.52vw', height: '1.04vw', width: '1.04vw', borderRadius: 50, borderWidth: 1, borderColor: "#91939F"
    },
    paymentCardContainer: {  
        marginHorizontal: ".65vw", 
        borderRadius: ".25vw", 
        paddingVertical: ".25vw", 
        maxHeight: "3vw", 
        marginTop: ".65vw" 
    },
    paymentCardWrapper:{ 
        flexDirection: "row", 
        justifyContent: "flex-end", 
        minWidth: "7.15vw" 
    },
    cardTooltipDiv:{ 
        marginRight: ".3vw", 
        paddingLeft: ".3vw" 
    },
    TooltipText:{ 
        color: "white", 
        fontSize: ".7vw", 
        fontWeight: "bold" 
    },
    cardPlaceholderDiv:{ 
        flexDirection: "row", 
        justifyContent: "flex-end", 
        marginRight: ".3vw" 
    },
    cardPlaceholderText:{ 
        color: "white", 
        fontSize: ".6vw" 
    },
    CommonTextboxContainer:{ 
        flex: 1, 
        margin: ".65vw" 
    },
    CommonTextboxplaceholder:{ 
        zIndex: ".15vw", 
        fontSize: ".8vw", 
        position: "absolute", 
        marginLeft: ".65vw", 
        backgroundColor: "white", 
        paddingLeft: ".3vw", 
        paddingRight: ".3vw", 
        color: "#888888" 
    },
    CommonTextboxTextInput:{
        borderColor: "#CDD1D5",
        borderRadius: ".25vw",
        borderWidth: ".1vw",
        width: "100%",
        height: "2.3vw",
        paddingLeft: ".65vw",
        marginTop: ".5vw",
        fontSize:'.95vw'
    },
    Textboxkey:{
        maxHeight: "13vw",
        borderRadius: ".65vw",
        width: '90%',
        justifyContent: 'center',
        alignContent: 'center',
        padding: ".65vw",
        marginLeft: ".65vw",
        marginTop: ".65vw",
        position: 'absolute',
    },
    FlatList:{
        flexDirection: "row",
        bottom: ".65vw",
        marginBottom: ".65vw",
        justifyContent: "space-evenly",
        width: "100%",
        alignItems: "center",
        marginTop: ".65vw",
        zIndex: 10,
    },
    vendorSearchContainer:{ 
        flexDirection: 'row', 
        paddingHorizontal: ".65vw", 
        alignItems: 'center', 
        flex: 1 
    },
    vendorSearchWrapper:{ 
        flex: 0.96, 
        flexDirection: "row", 
        alignItems: 'center' 
    },
    searchButtonContainer:{ 
        flex: 0.04, 
        margin: ".65vw" 
    },
    searchButtonWrapper:{
        width: "4.55vw", 
        height: "2.3vw", 
        borderRadius: ".3vw", 
        alignItems: 'center',
        alignSelf: "center", 
        justifyContent: "center", 
        marginTop: ".3vw" 
    },
    searchTextdiv:{ 
        flexDirection: "row", 
        justifyContent: "center", 
        padding: ".5vw" 
    },
    searchText:{ 
        color: "#ffffff", 
        fontSize:'.95vw' 
    },
    SearchInputText:{
        backgroundColor: color.white, color: "black", padding: '0.32vw', borderWidth: 1, borderColor : color.lightGray, borderRadius : '0.26vw', fontSize:'0.91vw', width:'17vw'
    },
    FilterRadioView:{
        flexDirection: "row", padding : '0.65vw' 
    },
    RadioButtonClick:{
        marginRight: '1.30vw', flexDirection: "row", alignItems: "center" 
    },
    RadioLabelText:{
       fontWeight: "500", fontSize: '0.84vw' 
    },
    RadioButton:{
        marginRight: '0.52vw', height: '1.04vw', width: '1.04vw', borderRadius: 50, borderWidth: 1, borderColor: "#91939F"
    },
    searchTextBox:{ 
        color: "black", 
        padding: ".5vw", 
        borderWidth: ".1vw", 
        borderRadius: ".2vw", 
        marginLeft: ".65vw", 
        fontSize:'1vw',
        height:'2.3vw'
    },
    YearDropDown:{
        width:"100%", 
        height: '2.60vw', 
        marginRight: '0.65vw', 
        borderRadius: '0.26vw', 
        marginTop: "0.65vw" 
    }
});
