import React from "react"
import { View } from "react-native";
import BaseComponent from "../../common/BaseComponent";
import Constants from "../../networks/Constants";
import Service from '../../networks/service';

import { CommonTextBox, SectionTitle, MultiLineCommonTextBox, ErrorAlert, ActionButton } from "../../common/BaseFunctionComponent";

export default class ReturnDetailsRight extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            return_quantity: "0",
            return_reason: "",
            stock_id: this.props.stock_id,
            pack_of_drugs: this.props.pack_of_drugs,
            sales_unit: this.props.sales_unit,
            stock_in_quantity: this.props.stock_in_quantity
        }
        this.service = new Service();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.stock_in_quantity && nextProps.stock_in_quantity !== prevState.stock_in_quantity) {
            return { stock_in_quantity: nextProps.stock_in_quantity };
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.stock_in_quantity !== this.props.stock_in_quantity) {
            this.setState({ stock_in_quantity: this.props.stock_in_quantity });
        }
    }

    render() {
        let { return_quantity, return_reason } = this.state
        return (
            <View>
                <View style={{ zIndex: -7 }}>
                    <SectionTitle label={"Return Details"} flexWidth={1} />
                </View>
                <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -7 }}>
                    <CommonTextBox width={"95%"} disable={false} placeholder={"Quantity"} type={""} align={""}
                        stateKey={"return_quantity"} value={return_quantity} onChangeText={this._updateState} />
                </View>
                <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: 15, zIndex: -1 }}>
                    <MultiLineCommonTextBox width={"95%"} disable={false} placeholder={"Reason"} type={""} align={""}
                        stateKey={"return_reason"} value={return_reason} onChangeText={this._updateState} numberOfLines={4} />
                </View>
                <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 15, zIndex: -1 }}>
                    <ActionButton label={"Save"} keyIndex={"save_stock_return"} onPressAction={this._onReturnStockItem.bind(this)} />
                </View>
            </View>
        )
    }

    _updateState = (stateKey, text, type = '') => {
        let states = this.state;
        // number validation
        if (stateKey === "return_quantity") {
            if (!(Constants.REG_EX_NUMBERS.test(text) || text == "")) {
                // no change
                return
            }
        }
        states[stateKey] = text;
        this.setState({
            states
        })
    }

    _onReturnStockItem = () => {
        let { stock_in_quantity, return_quantity, return_reason } = this.state
        stock_in_quantity = this._setDataFormat(stock_in_quantity, "integer")
        return_quantity = this._setDataFormat(return_quantity, "integer")
        if (stock_in_quantity) {
            if (return_quantity > 0) {
                if (return_quantity > stock_in_quantity) {
                    // qty too much error
                    this.props.errorClientCallBack("Return quantity is exceed than available quantity", false)
                } else {
                    // process
                    this._onProcessReturnStock()
                }
            } else {
                // return qty missing 
                this.props.errorClientCallBack("Please enter return quantity", false)
            }
        } else {
            //stock in qty missing error
            this.props.errorClientCallBack("Stock-in-Quantity not available", false)
        }
    }

    _onProcessReturnStock = () => {
        let url = Constants.MARK_RETURN;
        let data = {
            "stock_id": this.state.stock_id,
            "quantity": this._setDataFormat(this.state.return_quantity, "integer"),
            "pack_of_drugs": this.state.pack_of_drugs,
            "sales_unit": this.state.sales_unit,
            "reason": this.state.return_reason
        }
        this.service.postApiCall(
            url,
            data,
            this.successCallBack,
            this.errorCallBack
        )
    }

    successCallBack = response => {
        if (response.status == "success") {
            this.props.successClientCallBack(response.message, true)
        } else {
            this.props.errorClientCallBack(response.message, false)
        }
    }

    errorCallBack = response => {
        this.props.errorClientCallBack(response.message, false)
    }
}