import React, { Component } from 'react';
import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack'; 
import { Platform } from 'react-native'

import RootComponent from './src/RootComponent'
import { Authentication } from './src/common/Authentication';
import { Login } from './src/common/Login';
// import { DashBoard } from './src/common/DashBoard';

const HomeNavigator = createStackNavigator(
  {
    dashBoard: {
      screen: RootComponent,
      navigationOptions: () => ({
        header: false
      })
    // },
    // dashBoard: {
    //   screen: DashBoard,
    //   navigationOptions: () => ({
    //     header: false
    //   })
    }
  },
  {
    initialRouteName: 'dashBoard'
  }
);

const LoginNavigator = createStackNavigator(
  {
    login: {
      screen: Login,
      navigationOptions: () => ({
        header: false
      })
    }
  },
  {
    initialRouteName: 'login'
  }
);

const AuthNavigator = createStackNavigator(
  {
    authCall: {
      screen: Authentication,
      navigationOptions: () => ({
        header: false
      })
    }
  },
  {
    initialRouteName: 'authCall'
  }
);

const AppContainer = createAppContainer(
  createSwitchNavigator(
    {
      loginNavigator: LoginNavigator,
      homeNavigator: HomeNavigator,
      auth: AuthNavigator
    }, 
    {
      initialRouteName: "auth"
    }
  )
)

export default class App extends Component {
  render() {
    console.disableYellowBox = true;
    return <AppContainer />;
  }
}

/* Block Focus highlight in login page  */
export const injectWebCss = f => {

	// Only on web
	if ( !Platform.OS == 'web' ) return

	// Inject style
	const style = document.createElement('style')
	style.textContent = `textarea, input, button { outline: none!important; }`
	return document.head.append(style)

}

// 👉 And this in the App.js file
injectWebCss()