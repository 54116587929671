import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity,StyleSheet, ScrollView } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';

const PAGE_LOWSTOCK = "Expiry Stock"

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");
export default class StockOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expiryDrugList: [],
      startTime: "",
      endTime: "",
      billingListAfterPagination: [],
      // for pagination
      current_page: 1,
      records_per_page: 10,
    
      expiryDrugListAfterPagination: [],
      drugNameSearch: "",
      fillterDate: []

    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 

  }

  componentDidMount() {
    
    this._getExpiryDrugList()
    this.props.gettotalscoreValues(PAGE_LOWSTOCK, "")

  }

  componentWillReceiveProps(props) {
    if ((props.startTime && props.endTime && props.isclear) || (props.isclear && props.drugNameSearch) || (props.fillterDate && props.isclear)) {
      this.setState({
        startTime: props.startTime,
        endTime: props.endTime,
        drugNameSearch: props.drugNameSearch,
        fillterDate: props.fillterDate
      }, () => {
        this._getExpiryDrugList()
        props.handlepropsvalueclear(false)

      })
    }
  }

  // Get Api 
  _getExpiryDrugList = () => {
    if(this.state.drugNameSearch != "") {
      this.setState({
        expiryDrugList: [],
        expiryDrugListAfterPagination:[],
        current_page: 1,
        records_per_page: 10
      })
    }
    let url = ""
    let fromDate =this.state.fillterDate[0] ?  moment(this.state.fillterDate[0]).format('YYYY-MM-DD') : converted_date
    let toDate = this.state.fillterDate[1] ?  moment(this.state.fillterDate[1]).format('YYYY-MM-DD') : converted_date

    url = Constants.GET_EXPIRY_DRUG_REPORT + "?brand_name=" + this.state.drugNameSearch + "&from_date=" + fromDate + "&to_date=" + toDate
    
    this.service.getApiCall(
      url,
      this._getExpiryDrugListSuccess,
      this._getExpiryDrugListFailure
    )

  }

  _getExpiryDrugListSuccess = response => {

    let { expiryDrugList } = this.state;

    if (response.status === "success") {

      expiryDrugList = response.data

      this.setState({
        expiryDrugList,
        current_page: 1,
      }, () => {
        this.props.gettotalscoreValues(PAGE_LOWSTOCK, "")

      })
    }else{
      this.props.gettotalscoreValues(PAGE_LOWSTOCK, "")

    }
  };

  _getExpiryDrugListFailure = error => {
    this.props.gettotalscoreValues(PAGE_LOWSTOCK, "")

    console.log(error);

  };

  renderHeader() {
    return (

      <View style={styles.renderHeaderDiv}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Vendor Name"}
          heading3={"Batch No"}
          heading4={"Brand Name"}
          heading5={"DT"}
          heading6={"DS"}
          heading7={"Expiry Date"}
          heading8={"Avl Stock"}
          heading9={""}
          columnSize={[0.05, 0.25, 0.1, 0.25, 0.1, 0.1, 0.075, 0.07, 0.005]}  //total value is == of 1
          noOfColumn={9}

        />
      </View>
    )
  }


  _paginationChangedValues(data) {
       this.setState({
        expiryDrugListAfterPagination: data
       })
  }
  paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
      current_page: current_page,
      records_per_page: records_per_page
    })
  }

  render() {

    
    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}
        {
          this.state.expiryDrugList && this.state.expiryDrugList.length > 0 ?
          <View style={{ marginLeft: "1.30vw" }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
               totalClientItems={this.state.expiryDrugList}
               paginationCurrentPage = { this.paginationCurrentPage.bind(this) }
            /></View>
            : null
        }
      </View>
    );
  }
  renderPaginateContent() { 

    let { current_page, records_per_page } = this.state

    if(this.state.expiryDrugListAfterPagination && this.state.expiryDrugListAfterPagination.length > 0) {
      return (
        // Report List Views
        <ScrollView style={styles.bodyContainer}>   
          {this.state.expiryDrugListAfterPagination && this.state.expiryDrugListAfterPagination.length > 0 && this.state.expiryDrugListAfterPagination.map((item, index) => {
              
              let sno = (current_page-1)*records_per_page + index + 1;

              return (
                <View key={index} style={styles.bodyWrapper}>
                  <View style={[{ flex: 0.05 }]}><Text style={[styles.Bodylabel,{ marginLeft: "1.30vw" }]}>{sno}</Text></View>
                  <View style={[{ flex: 0.25 }]}><Text style={styles.Bodylabel}>{item.vendor_name}</Text></View>
                  <View style={[{ flex: 0.1 }]}><Text style={styles.Bodylabel}>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.25 }]}><Text style={styles.Bodylabel}>{item.brand_name}</Text></View>
                  <View style={[{ flex: 0.1 }]}><Text style={styles.Bodylabel}>{item.dosage_type}</Text></View>
                  <View style={[{ flex: 0.1 }]}><Text style={styles.Bodylabel}>{item.dosage_strength}</Text></View>
                  <View style={[{ flex: 0.075 }]}><Text style={styles.Bodylabel}>{item.expiry_date}</Text></View>
                  <View style={[{ flex: 0.07 }]}><Text style={[styles.Bodylabel,{ flex: 1, textAlign: 'center', paddingRight: '.65vw' }]}>{item.available_quantity}</Text></View>
                  <View style={[{ flex: 0.005 }]}><Text style={styles.Bodylabel}>{""}</Text></View>
                </View>
              )

            })
          }
        </ScrollView>
      )
    }else{
      return(
        <View style={styles.PaginationDiv}>
          <Text style={styles.PaginationText}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }

}

const styles = StyleSheet.create({
  Bodylabel:{
    fontSize:'1vw'
  },
  renderHeaderDiv:{ 
    marginHorizontal: "1.30vw", 
    marginTop: ".65vw" 
  },
  bodyContainer:{ 
    marginHorizontal: "1.30vw", 
    backgroundColor: "#F9F9F9", 
    marginTop: "1vw"
  },
  bodyWrapper:{ 
    flexDirection: 'row', 
    borderBottomColor: "#888888", 
    borderBottomWidth: ".1vw", 
    paddingVertical: "1.30vw", 
    marginHorizontal: 0 
  },
  PaginationDiv:{ 
    flex: 1, 
    flexDirection: "row", 
    justifyContent: "center", 
    alignContent: "center", 
    marginTop: "9.8vw" 
  },
  PaginationText:{ 
    color: "#8888888", 
    fontSize:'1vw' 
  }
});