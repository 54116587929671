import React, { Component } from "react";
import { Text, View, Switch, TouchableOpacity, ScrollView, Dimensions, TextInput, Image } from "react-native";
import { color } from "../../styles/Color";
import Constants from "../../networks/Constants";
import Service from "../../networks/service";
import { SuccessAlert, ErrorAlert } from "../../common/BaseFunctionComponent";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import AsyncStorage from "../../AsyncStorage";



export default class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drug_individual_discount: false,
            patient_demographics: false,   //adding patient demographics 
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            pharmacyType: "",
            drug_brand_name_search: false,
            drug_generic_name_search: false,
            direct_po_available: true
        }
        this.service = new Service()
    }
    async componentDidMount() {
        let pharmacy_type = await AsyncStorage.getItem("pharmacy_type");
        this.configStatusGet();
        this.setState({
            pharmacyType: pharmacy_type,
        })
    }
    configStatusGet = () => {
        var url = Constants.CONFIG_UPDATE
        this.service.getApiCall(
            url,
            this.getConfigSuccess,
            this.getConfigError
        )
    }
    getConfigSuccess = (response) => {
        if (response.status === "succes") {
            this.setState({
                drug_individual_discount: response.data.drug_individual_discount,
                patient_demographics: response.data.patient_demographics,
                drug_brand_name_search: response.data.drug_brand_name_search,
                drug_generic_name_search: response.data.drug_generic_name_search,
                direct_po_available: response.data.direct_po_available
            })
        } 
    }

    getConfigError = (error) => {
        this.setState({
            showErrorResponse: true,
            responseMessage: error.message
        }, () => {
            setTimeout(() => {
                this.setState({
                    showSuccessResponse: false,
                    showErrorResponse: false,
                    responseMessage: ""
                })
            }, 2000)
        })
    }
    settingStatusPost = (stateKey) => {
        var states = this.state
        var url = Constants.CONFIG_UPDATE
        var data = {}
        data[stateKey] = states[stateKey]
        this.service.postApiCall(
            url,
            data,
            this.configSuccess,
            this.configError
        )
    }

    configSuccess = (response) => {
        if (response.status === "success") {
            this.props.reportConfigChange()
            this.setState({
                showSuccessResponse: true,
                responseMessage: response.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showSuccessResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            })
        }else{
            this.setState({
                showSuccessResponse: false,
                showErrorResponse: false,
                 responseMessage: ""
            }) 
        }
    }

    configError = (error) => {
        this.setState({
            showErrorResponse: true,
            responseMessage: error.message
        }, () => {
            setTimeout(() => {
                this.setState({
                    showSuccessResponse: false,
                    showErrorResponse: false,
                    responseMessage: ""
                })
            }, 2000)
        })
    }

    renderView = (label, stateKey = "") => {
        var states = this.state
        return (
            <View style={{
                flex: 1, flexDirection: "row", marginHorizontal: "1.5vw", border: "1px solid #E2E2E2", padding: "0.5vw", borderRadius: "5px",
                marginBottom: "1vw", shadowOffset: { width: 1, height: 1 }, shadowColor: color.lightGray, shadowOpacity: 0.6, shadowRadius: 2, alignItems:"center", height:50
            }}>

                <View style={{ flex: 0.80, justifyContent: "flex-start"}}>

                    <Text style={{ fontSize: "1vw" }}>{label}</Text>

                </View>

                <View style={{ flex: 0.20, flexDirection: 'row', justifyContent: "flex-end", alignItems: 'center'}}>
                    <Text style={{ marginRight: '0.65vw', fontSize: "0.91vw", color: "#888888" }}>{"No"}</Text>

                    <Switch
                        style={{ width: '1.5vw', height: '1.2vw' }}
                        thumbColor={this.state.stateKey ? "#f5dd4b" : "#f4f3f4"}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={() => {
                            states[stateKey] = !states[stateKey]
                            this.setState({
                                states
                            }, () => {
                                this.settingStatusPost(stateKey)
                            })
                        }}


                        value={states[stateKey]}
                    />
                    <Text style={{ marginLeft: '0.65vw', fontSize: "0.91vw", color: "#888888" }}>{"Yes"}</Text>
                </View>
            </View>
        )
    }
    render() {
        var { showSuccessResponse, showErrorResponse , responseMessage, pharmacyType} = this.state;
        return (
            <TouchableWithoutFeedback onPress={() => {
                this.setState({
                    showErrorResponse: false,
                    showSuccessResponse: false
                })
            }}>
            <View style={{ width: "100%" }}>

                <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: "75vh", marginTop: 50 }}>
                    {this.renderView(" Discount % Editable access ?", "drug_individual_discount")}
                    {this.renderView(" Billing Search Using Brand Name ?", "drug_brand_name_search")}
                    {this.renderView(" Billing Search Using Generic Name ?", "drug_generic_name_search")}
                    {pharmacyType === "Standalone Pharmacy" ? this.renderView("Can add patient Demographics  ?", "patient_demographics") : null}
                    {this.renderView(" Can Show Direct Po Button ?", "direct_po_available")}
                </ScrollView>
                <View style={{marginHorizontal:"2vw",marginVertical:"20vw"}}>
                {
                        showSuccessResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }
                </View>
                   
            </View>
            </TouchableWithoutFeedback>
        )
    }
}