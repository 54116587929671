import React, { Component } from "react";
import { View, Text, Switch, TextInput, Image, TouchableOpacity, ScrollView, FlatList, TouchableWithoutFeedback, Dimensions,StyleSheet } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { RadioButton } from 'react-native-paper';
import moment from "moment";

import {
    CommonLabelView, CommonLabelTextMView, CommonLabelTextView, DrugBatchDetails, CommonTextBox, CommonDatePicker,
    LineItemTextBox, ActionButton, SuccessAlert, ErrorAlert, CommonAutoSuggestionFull
} from "../common/BaseFunctionComponent";

import { color } from "../styles/Color";
import Style from "../styles/Style";
import Constants from '../networks/Constants';
import Service from '../networks/service';
import AsyncStorage from "../AsyncStorage";
import BaseComponent from '../common/BaseComponent';

let screenHeight = Dimensions.get("window").height;

const tempRows = {
    "brand_name": "",
    "dosage_type": "",
    "dosage_strength": "",
    // flags
    "isDrugSelected": false,
    "isDosageSelected": false,
    "isStrengthSelected": false,
    // needed
    "line_item_id": "",
    "drug_id": "",
    "hsn_code": "",
    "schedule": "",
    "batch_no": "",
    "quantity": "",
    "expiry_date": "",
    "physical_box_no": "",
    "cost_per_quantity": "",
    "total_cost": "",
    "discount_percentage": "",
    "gst_percentage": "",
    "net_total_amount": "",
    // extra
    "batch_details": "select",
    "stock_in_quantity": "0"
};

const tempData = {
    "bill_summary_id": "",
    "invoice_number": "",
    "invoice_date": new Date(),
    "billing_customer_name": "",
    "billing_customer_mobile_no": "",
    "doctor_name": "",
    "patient_id": "",
    "doctor_id": "",
    "clinic_id": "",
    "patient_account_number": "",
    "ip_number":0,
    "transmitted_id": "",
    "card_number": "",
    "payment_mode": "1",
    "discount_amount": "0",
    "taxable_amount": "0",
    "general_discount": "0",
    "cgst_amount": "0",
    "sgst_amount": "0",
    "igst_amount": "0",
    "tax_amount": "0",
    "round_off": "0",
    "delivery_charge": "0",
    "gross_amount": "0",
    "grand_total": "0",
    "net_amount" : "0",
    "received_amount": "0",
    "balance_amount": "0",
    "bill_line_items": [],
    // excess
    "general_discount": "0",
    "transaction_id": "",
    "employee_id":'',
    "company_name":"",
    "transaction_number":"",
    "policy_number":"",
    "insurance_company_name":'',
    "validity":'',
    "bill_type":"Bill",
    "general_discount_percentage" : "0",
    "doctor_reg_no": "",

};

const tempSuggestion = {
    "drugSuggestionList": [],
    "dosageSuggestionList": [],
    "strengthSuggestionList": [],
    "drugDetailsSuggestionList": []
}

export default class ModifyBill extends BaseComponent {
    constructor(props) {
        super(props);
        this.refDoctorName = React.createRef()

        let suggestions = [];
        for (let i = 0; i < 10; i++) {
            suggestions.push(tempSuggestion);
        }

        this.state = {
            data: tempData,
            doctorsList: [],
            searchDoctorsList: [],
            flagShowDoctorList: false,
            billRows: [],
            suggestions: JSON.parse(JSON.stringify(suggestions)),
            is_editable: true,
            typedIndex: 0,
            is_complete_bill: false,
            drugDosageTypes: [],
            is_initiate_return: false,
            isOnFocusPer: false,
            subAmount : 0.00,
            viewBill: this.props.selectedBillToView ? this.props.selectedBillToView : null,
        }

        this.service = new Service();
        this._clearNotification = this._clearNotification.bind(this);
    }

    async componentDidMount(){
        let pharmacy_type = await AsyncStorage.getItem("pharmacy_type");
        this.setState({
            pharmacyType: pharmacy_type,
        }, () => {
            this._loadDoctorsList()
        })
        
        if(this.state.viewBill){
            this._viewBillDetails()
        }
    }

    _viewBillDetails = () => {
        let { viewBill } = this.state;


        if (Object.keys(viewBill).length > 0 && viewBill.id) {

            let url = Constants.BILL_VIEW + "?id=" + viewBill.id;

            this.service.getApiCall(
                url,
                this._getViewBillSuccess,
                this.errorCallBack
            )

        }
    }

    _getViewBillSuccess = (response) => {

        let { data } = this.state;

        if (response.status === "success") {

            let responseData = JSON.parse(JSON.stringify(response.data));

            let bill_summary_id = responseData.id ? responseData.id : ""
            let ip_number = responseData.ip_number ? responseData.ip_number : 0
            let doctor_name = responseData.doctor_name ? responseData.doctor_name : ""
            
            // update summary id
            data = responseData;
            data['bill_summary_id'] = bill_summary_id;
            data['ip_number'] = ip_number;
            data['doctor_name'] = doctor_name;

            let bill_line_items = responseData.bill_line_items ? responseData.bill_line_items : []
            let len = responseData.bill_line_items ? (responseData.bill_line_items).length : 0

            data['grand_return_total'] = 0;

            this.setState({
                bill_type: data && data['bill_type'] ? data['bill_type'] : "" ,
                data: data,
                billRows: bill_line_items
            }, () => {
                this._updateBillAmounts()
                this.props.discardChanges("", true)

            });
        }
    }

    errorCallBack = response => {

    
    }

    _loadDoctorsList = () => {

        let url = ""

        if(this.state.pharmacyType === "Standalone Pharmacy"){
            url = Constants.REFERRED_DOCTOR;
        }else {
            url = Constants.LIST_DOCTORS;
        }

        this.service.getApiCall(
            url,
            this._setDoctorList,
            this.errorCallBack
        )
    }

    _setDoctorList = (response) => {

        let states = this.state;

        if (response.status === "success") {

            var Data = response.data
            if(this.state.pharmacyType === "Standalone Pharmacy" && (Data).length > 0){
                var Doc_list = []
                for(let i = 0; i < (Data).length; i++){
                    Doc_list.push({doctor_id: Data[i].id, doctor_reg_no: Data[i].reg_no, name: Data[i].doctor_name })
                }
                this.setState({ 
                    doctorsList: Doc_list ? Doc_list : [],
                    searchDoctorsList: Doc_list ? Doc_list : [] 
    
                });
            }else{
                this.setState({ 
                    doctorsList: response.data ? response.data : [],
                    searchDoctorsList: response.data ? response.data : [] 
    
                });
            }  

        } else if (response.detail && response.detail === "Token is expired") {

            //   this.props.navigation.navigate( "loginNavigator" );
            this.props.logout()
        }
    }

    _itemSelected = (item, key, objFormat = {}) => {

        let states, { data, isSelected, flagShowDoctorList } = this.state;

        if (item) {
            if (key === "doctor_name" && objFormat) {

                data["doctor_name"] = item.name
                data["doctor_id"] = item.doctor_id;
                data["doctor_reg_no"] = item.doctor_reg_no;
                isSelected = true
                flagShowDoctorList = false
            }

            this.setState({
                states, data, isSelected: isSelected, flagShowDoctorList: flagShowDoctorList
            })
        }
    }

    _updateState2 = (stateKey, value, type) => {

        let { data, isSelected, flagShowDoctorList } = this.state;

        let grand_total = parseFloat(data.grand_total) || 0;
        let current_entry = parseFloat(value) || 0;

        if (stateKey == "doctor_name") {
            isSelected = false
            flagShowDoctorList = true
        }
        data[stateKey] = value;

        if (stateKey === "card_amount") {
            data['cash_amount'] = (grand_total - current_entry).toFixed(2)
        }

        if (stateKey === "cash_amount") {
            data['card_amount'] = (grand_total - current_entry).toFixed(2)
        }

        this.setState({ data, isSelected: isSelected, flagShowDoctorList: flagShowDoctorList }, () => {
            if(data.doctor_name != ""){
                // this._doctorNameAutoSearch(data.doctor_name)
            }else {
                this.setState({
                    searchDoctorsList: this.state.doctorsList
                })
            }
            if (stateKey === "received_amount") {
                // update calculation
                this._updateBillAmounts()
            }
        })

    }

    _onChangeDoctorName = (stateKey, value, type = '') => {

        let states = this.state;
        let { doctorsList, data } = this.state;
        if(value) {
            data["doctor_name"] = value;
            data['doctor_id'] = null;
            data['doctor_reg_no'] = "";
            states['isSelected'] = false;
            states["flagShowDoctorList"] = true;
            states["isDoctorSelected"] = false
            states['searchDoctorsList'] = this._sortListData(doctorsList, value)
        } else if(value === ""){
            data["doctor_reg_no"] = "";
            data["doctor_name"] = "";
            data['doctor_id'] = "";
            states["isDoctorSelected"] = false;
            states['flagShowDoctorList'] = false;
        }
        
        this.setState({
          states,
          data,
          flagShowDoctorList: this.state.flagShowDoctorList
        })
    }

    _doctorNameAutoSearch = (value) =>{

        let tempDoctorList = this.state.doctorsList
        if(tempDoctorList && tempDoctorList.length > 0) {
         var searchListTemp = [];
         for (let i = 0; i < tempDoctorList.length; i++) {
   
           if (tempDoctorList && tempDoctorList.length > 0 && value && value.length > 0 && tempDoctorList[i].toLowerCase().indexOf(value.toLowerCase()) > -1) {
             searchListTemp.push(tempDoctorList[i]);
           }
         }
         this.setState({
             searchDoctorsList :searchListTemp
         })
       }
    }

    _onFocusInput = (stateKey, refInp) => {

        let { flagShowDoctorList } = this.state

        if (stateKey == "doctor_name") {
            if (!flagShowDoctorList) {
                this.setState({ flagShowDoctorList: true })
            }
        }
    }

    _onChangeDate = (stateKey, value, type) => {

        let { data } = this.state;

        data[stateKey] = value;

        this.setState({ 
            data,
        })
    }


    getBillDetails = () => {
        var {data} = this.state
        return(
            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
                <View style={{flexDirection: "row", justifyContent: "flex-start", alignContent: "center", margin: 15, flexWrap: "wrap" }}>
                <TouchableOpacity
                    onPress={() =>{ 
                        this.props.changeScreen("invoice")
                        this.setState({
                            // billRows: []
                        })
                    }}
                    style={{ backgroundColor: color.themeBackgroud, borderRadius: 5, alignSelf: "center", paddingVertical: 5, paddingHorizontal: 8, marginRight: 60 }} >
                    <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                        <Image
                            // style={style.BackIcon}
                            style={{ width: '1.3vw', height: '1.3vw' }}
                            source={require('../../assets/Icon/Back.png')}
                        />
                        <Text style={{ color: "#ffffff", fontSize:'0.9vw'  }}>{"Back"}</Text>
                    </View>
                </TouchableOpacity>
                <CommonTextBox disable={true} width={'9.76vw'} placeholder={"Patient Mobile"} type={"MI"} stateKey={"billing_customer_mobile_no"} value={data.billing_customer_mobile_no} onChangeText={() => {}} />
                <CommonTextBox disable={true} width={'11.7vw'} placeholder={"Patient Name *"} type={"MI"} stateKey={"billing_customer_name"} value={data.billing_customer_name} onChangeText={() => {}} />
                <CommonTextBox disable={true} width={'13vw'} placeholder={data.ip_number ? "UHID/IP No" : "UHID"} type={"MI"} stateKey={"patient_account_number"} value={data.patient_account_number && data.ip_number ? `${data.patient_account_number} / ${data.ip_number}` : data.patient_account_number} onChangeText={() => {}} />
                <CommonAutoSuggestionFull
                    width={'16.27vw'} type={""} align={""}
                    placeholder={"Doctor Name"} disable={false}
                    stateKey={"doctor_name"} value={data.doctor_name}
                    list={this.state.searchDoctorsList}
                    isSelected={this.state.isSelected}
                    showList={this.state.flagShowDoctorList}
                    onChangeText={this._onChangeDoctorName.bind(this)}
                    itemSelected={this._itemSelected.bind(this)}
                    // onFocusInput={this._onFocusInput}
                    reference={this.refDoctorName}
                    objFormat={{ keyId: "doctor_id", keyName: "name"}}
                />
                </View>
                <View style={{ flexDirection: "row", justifyContent: "flex-end", alignContent: "center", flexWrap: "wrap", zIndex: -10, marginLeft:'4vw' }}>
                    <View style={{ flexDirection: "column", justifyContent: "center", alignContent: "center", margin: 10 }}>

                        <View style={{ flexDirection: "row", alignItems:'center' }}>
                            <View ><CommonLabelView label={"Invoice Date"} /></View>
                            <CommonDatePicker placeholder={"Date"} type={"MI"} stateKey={"invoice_date"} value={moment(data.invoice_date) || null}
                                onChangeText={this._onChangeDate.bind(this)} nolabel={true} noborder={true} />
                        </View>

                        <View style={{ flexDirection: "row" }}>
                            <View style={{marginTop:'0.5vw'}}><CommonLabelView label={"Invoice Number"} /></View>
                            <View style={{marginTop:'0.5vw'}}><CommonLabelTextView label={data.invoice_number || "-"} /></View>
                        </View>
                    </View>
                </View>
            </View>
        )
    }

    getBillTableTitle(title, flexWidth = 0.05, fontsize="0.7vw") {
        return (
            <View style={{ flex: flexWidth}}>
                <Text style={{ fontSize: fontsize }}>{title}</Text>
            </View>)
    }

    _parseString(str) {

        return str != null && str != undefined ? str.toString() : ""

    }

    _updateBatchPopup = (billRows, index = '') => {

        let len = billRows.length || 0;

        for (let i = 0; i < len; i++) {

            if (i == index) {
                billRows[i]['showPickerDropDownFlag'] = true;
            } else {
                billRows[i]['showPickerDropDownFlag'] = false;
            }
        }
        return billRows;
    }

    _getDrugSuggestion = (searchKey) => {

        let { suggestions, typedIndex } = this.state;
        let drugSuggestionList = suggestions[typedIndex]['drugSuggestionList'] ? suggestions[typedIndex]['drugSuggestionList'] : [] ;
        let drugName = searchKey;

        if (drugName !== "") {

            let sameInList = false;
            let isEnableDrugList = false;
            let len = drugSuggestionList.length;

            for (var i = 0; i < len; i++) {

                let value = drugSuggestionList[i];

                if (drugName == value) {
                    sameInList = true;
                    return;
                }
            }

            if (sameInList) {

                isEnableDrugList = true;

                // exist, so no need call service
                // this._getDrugDetails(drugName);

            } else {

                if (drugName.length >= 2) {
                    // let url = Constants.SEARCH_DRUG + "?brand_name=" + drugName;
                    let url = Constants.BILL_DRUG_SEARCH + "?search=" + drugName;

                    this.service.getApiCall(
                        url,
                        this._getDrugSuccess,
                        this._getDrugFailure
                    )
                }
            }

        } else {
            // clear suggestion
            suggestions[typedIndex]['drugSuggestionList'] = []
            suggestions[typedIndex]['drugDetailsSuggestionList'] = []

            this.setState({
                suggestions
            })
        }
    }

    _getDrugSuccess = response => {

        let { typedIndex, suggestions } = this.state;

        if (response.status === "success") {

            let data = JSON.parse(JSON.stringify(response.data));

            // new bill flow data format
            let tempSuggestData = []

            data && data.map((item, index) => {
                return tempSuggestData.push(item.display_name)
            })

            suggestions[typedIndex]['drugDetailsSuggestionList'] = data;
            suggestions[typedIndex]['drugSuggestionList'] = tempSuggestData;

            this.setState({ suggestions })
        }
    };

    _getDrugFailure = error => {

        console.log("GET error response");
        console.log(error);

    };

    _getBatchDetailsbyDrugId = (index) => {

        let { billRows } = this.state;
        let drug_id = billRows[index].drug_id || null;
        let drug_name = billRows[index].brand_name || null;
        let dosage_type = billRows[index].dosage_type || null;
        let dosage_strength = billRows[index].dosage_strength || null;

        if (drug_name) {
            dosage_strength = dosage_strength == null ?  null :  dosage_strength.replaceAll("+","%2B")
            //let url = Constants.BILL_DRUG_DETAILS_BY_DRUGID + "?drug_id=" + drug_id;
            let url = Constants.BILL_DRUG_DETAILS_BY_DRUGID + "?drug_name=" + drug_name + "&dosage_type=" + dosage_type + "&dosage_strength=" + dosage_strength;

            this.service.getApiCall(
                url,
                this._getBatchSuccess,
                this._getDrugFailure
            )

        }
    }

    _getBatchSuccess = response => {

        let { typedIndex, billRows } = this.state;

        if (response.status === "success") {

            let parsedData = JSON.parse(JSON.stringify(response.data))

            billRows[typedIndex]['availBatchDatas'] = parsedData;
            billRows[index]["schedule"] = parsedData[0].schedule;
            // set hsn code
            if (parsedData && parsedData.length > 0) {
                if (parsedData[0]['hsn_code']) {
                    billRows[typedIndex]['hsn_code'] = parsedData[0]['hsn_code']
                    billRows[typedIndex]['schedule'] = parsedData[0]['schedule']
                }
            }

            this.setState({ billRows })
        }
    };

    _handleDrugSuggestion = (type, index, key) => {

        let { is_editable, typedIndex, billRows, suggestions } = this.state;

        typedIndex = typedIndex ? typedIndex : 0;

        let value = billRows[index]['brand_name'];
        let isSelected = billRows[index]['isDrugSelected'];
        let drugSuggestionList = suggestions[typedIndex] ? suggestions[typedIndex]['drugSuggestionList'] : [];
        let drugDetailsSuggestionList = suggestions[typedIndex] ? suggestions[typedIndex]['drugDetailsSuggestionList'] : [];

        // for transmitted alone
        let is_transmitted_drug = billRows[index] ? (billRows[index]['is_transmitted_drug'] ? billRows[index]['is_transmitted_drug'] : false) : false

        return (
            <View style={{ flexDirection: "column" }}>
                <TextInput
                    style={{
                        // flex: 1,
                        height: '2vw',
                        padding: '0.39vw',
                        marginRight: '0.97vw',
                        backgroundColor: is_editable && !is_transmitted_drug && !billRows[index]['schedule'] ? color.white : billRows[index]['schedule'] ? color.maroon : color.tableHeaderBG,
                        borderColor: billRows[index]['schedule'] ? color.magenda : "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        fontSize: '0.71vw',
                        zIndex: -10,
                        color:billRows[index]['schedule'] ? color.magenda : color.darkBlack
                    }}
                    // disable={!is_editable }
                    value={value}
                    onChangeText={(text) => {
                      
                        // this.updateState(text, key,fieldName);
                       if(text != "") { 
                        billRows[index]['brand_name'] = text;
                        billRows[index]['isDrugSelected'] = false;

                        billRows = this._updateBatchPopup(billRows, 'false')

                        this.setState({
                            typedIndex: index,
                            billRows
                        }, () => {
                            this.props.discardChanges(this.state.billRows[index]['brand_name'], this.state.is_complete_bill)
                            this._getDrugSuggestion(text)
                        })
                      } else {
                        billRows[index]['brand_name'] = text;
                        billRows[index]["dosage_type"] = " ";
                        billRows[index]["dosage_strength"] = "";
                        billRows[index]["batch_no"] = "";
                        billRows[index]['hsn_code'] = "";
                        billRows[index]["expiry_date"] = "";
                        billRows[index]['physical_box_no'] = "";
                        billRows[index]["cost_per_quantity"] = "";
                        billRows[index]["schedule"] = "";
                        suggestions[typedIndex]['drugSuggestionList'] = []
                        suggestions[typedIndex]['drugDetailsSuggestionList'] = []
                        billRows[index]['quantity'] = ""
                        billRows[index]['total_cost'] = ""
                        billRows[index]['discount_percentage'] = ""
                        billRows[index]['net_total_amount'] = ""
                        this.setState({
                            billRows,
                            suggestions
                        }, () => {
                            this.props.discardChanges(this.state.billRows[index]['brand_name'], this.state.is_complete_bill)
                            this._updateBillState('return_quantity', '', '', index)
                        })

                      }
                    }}
                    editable={is_editable && !is_transmitted_drug}
                ></TextInput>
                {/* </TouchableOpacity> */}
                {/* {typedIndex===index && !isSelected && (drugSuggestionList && drugSuggestionList.length>0)? */}
                {typedIndex === index && !isSelected && (drugDetailsSuggestionList && drugDetailsSuggestionList.length > 0) ?
                    <View style={{

                        // maxHeight: this.state.newItem ? "" : 0,
                        borderRadius: 10,
                        width: '19.5vw', //'90%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        padding: '0.65vw',
                        marginLeft: '0.5vw',
                        marginTop: '2.2vw',
                        position: 'absolute',
                        backgroundColor: color.themeBlue
                    }}>
                        <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: screenHeight - (150 + index * 25 + 25) }}>
                            <FlatList
                                data={drugDetailsSuggestionList}
                                renderItem={({ item }) => (
                                    <View style={{
                                        flexDirection: "row",
                                        bottom: '0.65vw',
                                        marginBottom: '0.65vw',
                                        justifyContent: "space-evenly",
                                        width: "100%",
                                        alignItems: "center",
                                        marginTop: '0.65vw'
                                    }}
                                    >
                                        <View style={{ width: '100%' }}>
                                            <TouchableOpacity
                                                onPress={() => {

                                                    if (item.in_stock !== undefined && item.in_stock == 0) {
                                                    } else {

                                                        /*
                                                        // var data = {"label":item,"value":item}
                                                        billRows[index]['brand_name']=item;
                                                        billRows[index]['isDrugSelected']=true;
                                                        
                                                        this.setState({ 
                                                            billRows,
                                                            // drugSuggestionList: []
                                                        }, ()=>{
                                                            this._getDrugDetails(item)
                                                        })
                                                        */

                                                        billRows[index]['brand_name'] = item.drug_name;
                                                        billRows[index]['isDrugSelected'] = true;
                                                        billRows[index]['drug_id'] = item.drug_id;
                                                        billRows[index]['dosage_type'] = item.dosage_type;
                                                        billRows[index]['dosage_strength'] = item.dosage_strength;

                                                        // other popup close, current open
                                                        billRows = this._updateBatchPopup(billRows, index)

                                                        // update suggestion
                                                        suggestions[index]["dosageSuggestionList"] = []
                                                        suggestions[index]["strengthSuggestionList"] = []

                                                        suggestions[index]["dosageSuggestionList"].push(item.dosage_type)
                                                        suggestions[index]["strengthSuggestionList"].push(item.dosage_strength)

                                                        // clear batch
                                                        billRows[index]['availBatchDatas'] = []

                                                        this.setState({
                                                            billRows,
                                                            suggestions
                                                        }, () => {
                                                            this._getBatchDetailsbyDrugId(index)
                                                        })
                                                    }
                                                }}
                                            >
                                                <Text style={{ color: item.in_stock !== undefined && item.in_stock == 0 ? color.labelColor : color.white,  fontSize:'0.91vw' }}>{item.display_name}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )}
                                enableEmptySections={true}
                            />
                        </ScrollView>
                    </View> : null}
            </View>
        )
    }

    _handleDrugDetailSuggestion = (type, index, key) => {

        let { is_editable, typedIndex, typedKey, suggestions, billRows, drugDosageTypes } = this.state;

        typedIndex = typedIndex ? typedIndex : 0;

        let value = null;
        let suggestData = [];
        let isSelected = true;
        let strengthSuggestionList = suggestions[typedIndex] ? suggestions[typedIndex]['strengthSuggestionList'] : null;

        drugDosageTypes = suggestions[typedIndex] ? suggestions[typedIndex]['dosageSuggestionList'] : [];
        
        if (key === 'dosage_type') {
            value = billRows[index]['dosage_type'] || billRows[index]['dosage_type_id'];
            isSelected = billRows[index]['isDosageSelected'];
            suggestData = drugDosageTypes;
        }
        if (key === 'dosage_strength') {
            value = billRows[index]['dosage_strength'];
            isSelected = billRows[index]['isStrengthSelected'];
            suggestData = strengthSuggestionList;
        }
     
        if (value == undefined || value == null) {

            value = ""

        } else {
            value = value.toString()
        }

        // for transmitted alone
        let is_transmitted_drug = billRows[index] ? (billRows[index]['is_transmitted_drug'] ? billRows[index]['is_transmitted_drug'] : false) : false

        return (
            <View style={{ flexDirection: "column" }}>
                {/* <TouchableOpacity onPress={()=>{}} > */}
                <TextInput
                    style={{
                        // flex: 1,
                        height: '2vw',
                        padding: 6,
                        marginRight: 15,
                        backgroundColor: is_editable && !is_transmitted_drug ? color.white : color.tableHeaderBG,
                        borderColor: "#CDD1D5",
                        borderRadius: 4,
                        borderWidth: 1,
                        fontSize: '0.71vw',
                        zIndex: -10
                    }}
                    disabled = {true} 
                    value={value}
                    onChangeText={(text) => {

                        // this.updateState(text, key,fieldName);

                        billRows = this._updateBatchPopup(billRows, 'false')

                        if (key === 'dosage_type') {
                            billRows[index]['dosage_type'] = text;
                            billRows[index]['isDosageSelected'] = false;
                        }
                        if (key === 'dosage_strength') {
                            billRows[index]['dosage_strength'] = text;
                            billRows[index]['isStrengthSelected'] = false;
                        }

                        this.setState({
                            typedKey: key,
                            typedIndex: index,
                            billRows
                        }, () => {

                            // this._handleDosageType(text)
                        })

                    }}
                    editable={is_editable && !is_transmitted_drug}
                ></TextInput>
                {/* </TouchableOpacity> */}
                {typedIndex === index && typedKey === key && !isSelected && (suggestData && suggestData.length > 0) ?
                    <View style={{

                        // maxHeight: this.state.newItem ? "" : 0,
                        borderRadius: 10,
                        width: '90%',
                        justifyContent: 'center',
                        alignContent: 'center',
                        padding: 10,
                        marginLeft: 10,
                        marginTop: 30,
                        position: 'absolute',
                        backgroundColor: color.themeBlue
                    }}>
                        <ScrollView showsHorizontalScrollIndicator={false} style={{ maxHeight: 200 }}>
                            <FlatList
                                data={suggestData}
                                renderItem={({ item }) => (
                                    <View style={{
                                        flexDirection: "row",
                                        bottom: 10,
                                        marginBottom: 10,
                                        justifyContent: "space-evenly",
                                        width: "100%",
                                        alignItems: "center",
                                        marginTop: 10
                                    }}
                                    >
                                        <View style={{ width: '100%' }}>
                                            <TouchableOpacity
                                                onPress={() => {

                                                    // type==='select_generic'?billRows[index]['generic_name']=item.generic_name:billRows[index]['dosage_type']=item;

                                                    if (key === 'dosage_type') {
                                                        billRows[index]['dosage_type'] = item;
                                                        billRows[index]['isDosageSelected'] = true;
                                                    }
                                                    if (key === 'dosage_strength') {
                                                        billRows[index]['dosage_strength'] = item;
                                                        billRows[index]['isStrengthSelected'] = true;
                                                    }

                                                    this.setState({
                                                        billRows
                                                    }, () => {

                                                        if (key === 'dosage_strength') {

                                                            this._onChangeStrength(typedIndex, item)
                                                        }
                                                        if (key === 'dosage_type') {

                                                            this._setDrugId()
                                                        }
                                                    })
                                                }}
                                            >
                                                <Text style={{ color: color.white }}>
                                                    {/* {key==='generic_name'?item.generic_name:item} */}
                                                    {item}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )}
                                enableEmptySections={true}
                            />
                        </ScrollView>
                    </View> : null}
            </View>
        )
    }

    _onChangeStrength = (index, value) => {

        let { suggestions, billRows } = this.state;
        let drugList = suggestions[index]['drugDetailsSuggestionList'];
        let dosageData = [];  //suggestions[index]['strengthSuggestionList'];
        let currentRowData = billRows[index];

        if (currentRowData) {

            // let cDrug = billRows[index].brand_name;
            let cStrength = billRows[index].drug_strength;

            for (let i = 0; i < drugList.length; i++) {

                if (drugList[i].dosage_strength === cStrength) {
                    let dosage_type = drugList[i].dosage_type;

                    if (!dosageData.includes(dosage_type)) {

                        dosageData.push(dosage_type)
                    }
                }
            }

            // strengthData.sort(function(a, b){ return a-b})

            suggestions[index]['dosageSuggestionList'] = dosageData;

            this.setState({
                suggestions
            });
        }
    }

    _setDrugId() {

        let { suggestions, billRows, typedIndex } = this.state;

        let drugList = suggestions[typedIndex]['drugDetailsSuggestionList'];
        let currentRowData = billRows[typedIndex];
        let drug_id = "";

        if (currentRowData) {

            // let cDrug = billRows[index].brand_name;
            // let cStrength = billRows[index].dosage_strength;

            let isFirstSet = false;

            for (let i = 0; i < drugList.length; i++) {

                if (
                    drugList[i].drug_strength === billRows[typedIndex].dosage_strength
                    && drugList[i].dosage_type === billRows[typedIndex].dosage_type) {

                    if (!isFirstSet) {

                        drug_id = drugList[i].drug_id;
                        isFirstSet = true;
                    }
                }
            }

            // strengthData.sort(function(a, b){ return a-b})

            billRows[typedIndex]['drug_id'] = drug_id;

            this.setState({
                billRows
            });
        }
    }

    _handleDrugBatchSelection = (type, index, stateKey) => {

        let { is_editable, billRows } = this.state;
        let zIndex = (index + 1) * (-10);
        let title = billRows[index][stateKey] || '';

        // only for batch
        title = stateKey === 'batch_no' && !title ? 'select' : title;

        return (
            <View style={{ zIndex: zIndex }}>
                <TouchableOpacity onPress={() => {

                    if (is_editable) {
                        // billRows[index].showPickerDropDownFlag = true; 
                        billRows = this._updateBatchPopup(billRows, index)

                        this.setState({
                            billRows,
                            typedIndex: index
                        }, () => {
                            this._getBatchDetailsbyDrugId(index)
                        })
                    }
                }
                }>
                    <TextInput
                        style={{
                            // width: "100%",
                            // flex: 1,
                            height: '2vw',
                            padding: 6,
                            marginRight: 15,
                            backgroundColor: is_editable ? color.white : color.tableHeaderBG,
                            borderColor: "#CDD1D5",
                            borderRadius: 4,
                            borderWidth: 1,
                            fontSize: '0.71vw'
                        }}
                        value={title}
                        onChangeText={(text) => {
                            // this._updateBillState(text, stateKey, type, index);
                        }}
                        editable={is_editable}
                    ></TextInput>
                </TouchableOpacity>
                {type === 'select' && billRows[index].showPickerDropDownFlag && is_editable ?
                    <DrugBatchDetails
                        closePopup={this.closePopup.bind(this, index)}
                        selectBatch={this._selectBatchFromPopup.bind(this)}
                        data={billRows[index].availBatchDatas || []} /> : null}
            </View>
        )
    }

    closePopup(index) {

        let { billRows } = this.state;
        if (billRows[index]) {

            billRows[index].showPickerDropDownFlag = false;
            this.setState({
                billRows
            })
        }
    }

    _selectBatchFromPopup(item) {


        let { billRows, typedIndex } = this.state;

        if (billRows[typedIndex]) {

            billRows[typedIndex].showPickerDropDownFlag = false;
            billRows[typedIndex]['batch_no'] = item['batch_no'];
            billRows[typedIndex]['drug_id'] = item['drug_id'];
            // excess
            billRows[typedIndex]['batch_details'] = item['batch_no'] + " - " + item['stock_in_quantity'];
            billRows[typedIndex]['stock_in_quantity'] = item['stock_in_quantity'];
            // display data
            billRows[typedIndex]['expiry_date'] = item['expiry_date'];
            billRows[typedIndex]['physical_box_no'] = item['physical_box_no'];
            billRows[typedIndex]['cost_per_quantity'] = item['cost_per_quantity'];

            billRows[typedIndex]['hsn_code'] = item['hsn_code']
            billRows[typedIndex]['schedule'] = item['schedule']
            
            this.setState({
                billRows,
                totalAvailableQuantity: item.stock_in_quantity
            }, () => {
                this._updateBillState('batch_no', item['batch_no'], '', typedIndex)
                this._updateBillAmounts()
            })
        }
    }

    _setDataFormat = (data, desiredFormat = "string") => {

        let inString = data;

        if (desiredFormat === "int" || desiredFormat === "integer") {

            inString = parseInt(inString) || 0
        }
        if (desiredFormat === "float" || desiredFormat === "decimal") {

            let parsedVal = parseFloat(inString) || 0;
            let formattedVal = parsedVal.toFixed(2);
            // assign
            inString = parseFloat(formattedVal);
        }
        if (desiredFormat === "date") {

            let date = inString;
            if (date) {

                inString = date ? moment(date).format('YYYY-MM-DD') : null
            } else {
                inString = ""
            }
        }

        return inString;
    }

    _drugListTaxableAmountUpdate(billRows, data) {

        let { is_initiate_return } = this.state;

        // set total cost, grand total
        let taxable_amount = 0;
        let grand_total = 0;
        let gross_amount = 0;
        let itemwise_discount_amount = 0

        // for return
        let taxable_return_amount = 0;
        let grand_return_total = 0;

        for (let i = 0; i < billRows.length; i++) {

            let row_total = 0;

            if (is_initiate_return) {
                row_total = parseFloat(billRows[i]['net_return_total_amount']) || 0;
                taxable_return_amount = taxable_return_amount + row_total;

                // add discount amount
                itemwise_discount_amount = itemwise_discount_amount + this._setDataFormat(billRows[i]['discount_amount_return'], 'decimal');
            } else {
                row_total = parseFloat(billRows[i]['net_total_amount']) || 0;
                taxable_amount = taxable_amount + row_total;

                // add discount amount
                itemwise_discount_amount = itemwise_discount_amount + this._setDataFormat(billRows[i]['discount_amount'], 'decimal');
            }
        }

        // assign discount amount
        data['discount_amount'] = itemwise_discount_amount

        // general disc amount update
        let general_discount_percentage = data['general_discount_percentage'] || 0

        let general_discount = ((taxable_amount * general_discount_percentage) / 100 || 0)
        let general_discount_return = ((taxable_return_amount * general_discount_percentage) / 100 || 0)

        // grand_total = taxable_amount - parseFloat(data.discount_amount) || 0;
        // grand_return_total = taxable_return_amount - parseFloat(data.discount_amount) || 0;

        grand_total = taxable_amount - parseFloat(general_discount) || 0;
        grand_return_total = taxable_return_amount - parseFloat(general_discount_return) || 0;

        this.setState({ subAmount : taxable_amount })
        if (!is_initiate_return) {
            // update amount
            data['taxable_amount'] = parseFloat(taxable_amount).toFixed(2);
            data['gross_amount'] = grand_total.toFixed(2);
            data['grand_total'] = grand_total.toFixed(2);
            data['general_discount'] = parseFloat(general_discount).toFixed(2)

        } else {
            // update return
            data['taxable_return_amount'] = taxable_return_amount.toFixed(2);
            data['grand_return_total'] = grand_return_total.toFixed(2);
            data['gross_return_amount'] = grand_return_total.toFixed(2);
            data['general_discount'] = general_discount_return;
            data['received_amount'] = grand_return_total;
            data['balance_amount'] = 0;

        }
        this._updateRoundOff(data)
        return data;
    }

    _updateRoundOff(data) {

        let { is_initiate_return } = this.state;

        let payment_mode = parseFloat(data['payment_mode']) || 0
        let gross_amount = !is_initiate_return ? (parseFloat(data['gross_amount']) || 0) : (parseFloat(data['gross_return_amount']) || 0)
        let round_off = 0
        let grand_total = 0

        let taxable_amount = !is_initiate_return ? (parseFloat(data['taxable_amount']) || 0) : (parseFloat(data['taxable_return_amount']) || 0)
        let general_discount = parseFloat(data['general_discount']) || 0

        // general discount calculation 
        // calc
        gross_amount = taxable_amount - general_discount
        let net_total_amount = gross_amount

        if ( payment_mode ) {

            grand_total = parseFloat((Math.round(gross_amount)).toFixed(2)) || 0 // 18.55 - 18.85

            round_off = grand_total - gross_amount    // 0.45 - 0.15

            data['round_off'] = round_off.toFixed(2);

            if (!is_initiate_return) {
                data['grand_total'] = grand_total.toFixed(2)
            } else {
                data['grand_return_total'] = grand_total.toFixed(2)
            }

        }

        return data;
    }

    _updateBillAmounts() {

        let { data, is_initiate_return, billRows } = this.state;

        let received_amt = 0;
        let bal_amt = 0;
        data['general_discount_percentage'] = parseFloat(data['general_discount_percentage']) 

        let grand_amt = is_initiate_return ? parseFloat(data['grand_return_total']).toFixed(2) : parseFloat(data['grand_total']).toFixed(2);
        if (data["payment_mode"] == "1" ) {
            received_amt = parseFloat(data['received_amount']) || 0;
            bal_amt = (received_amt - parseFloat(grand_amt)).toFixed(2);
            data['balance_amount'] = bal_amt;
            if(received_amt >= grand_amt){
                this.setState({
                    is_complete_disable_action: false
                })
            }else{
                this.setState({
                    is_complete_disable_action: true
                }) 
            }
        } else {
            this.setState({
                is_complete_disable_action: false
            })
            data['received_amount'] = 0;
            data['balance_amount'] = 0;
        }

        this.setState({ data },()=>{
            this._drugListTaxableAmountUpdate( billRows, data)
        })
    }



    _handleItemRemove(i) {

        let { billRows, suggestions, data } = this.state;

        billRows = billRows.slice(0, i).concat(billRows.slice(i + 1, billRows.length))
        // clear suggestion
        suggestions = suggestions.slice(0, i).concat(suggestions.slice(i + 1, suggestions.length))

        this.setState({ billRows: billRows, suggestions: suggestions }, () => {

            data = this._drugListTaxableAmountUpdate(billRows, data)

            data = this._updateRoundOff(data)

            this.setState({
                data: data
            }, () => {
                this.props.discardChanges("", this.state.is_complete_bill)
                this._updateBillAmounts()
            })
        })

    }


    _updateBillState = (stateKey, value, type, index) => {

        let { data, billRows } = this.state;

        // set data
       if(stateKey === "discount_percentage" && value.length < 3) {

        billRows[index]["discount_percentage"] = value;

       } else if(stateKey != "discount_percentage"){

        billRows[index][stateKey] = value;
        
       }
        // clear batch popups
        billRows = this._updateBatchPopup(billRows, 'false');

        // process data based on keyname
        if (stateKey === "batch_no" || stateKey === "quantity" || stateKey === "discount_percentage") {

            // set total_cost
            let total_cost = 0;
            let cost = parseFloat(billRows[index]["cost_per_quantity"]) || 0;
            let qty = parseInt(billRows[index]["quantity"]) || 0;    //parseInt(value);

            // total
            total_cost = parseFloat((cost * qty).toFixed(2)) || "";

            // discount
            let discount_percentage = parseFloat(billRows[index]["discount_percentage"]) || 0;    // parseFloat(value) || 0;
            let discount_amount = parseFloat((total_cost * discount_percentage / 100).toFixed(2)) || 0;
            // for calc purpose 
            billRows[index]["discount_amount"] = discount_amount
            billRows[index]["discount_amount_return"] = 0

            // assign
            billRows[index]['total_cost'] = total_cost
            billRows[index]['net_total_amount'] = (total_cost - discount_amount).toFixed(2);
        }

        // set total cost, grand total
        data = this._drugListTaxableAmountUpdate(billRows, data)

        data = this._updateRoundOff(data);

        this.setState({ billRows, data }, () => {

            // update calculation
            if (stateKey === "quantity" || stateKey === "discount_percentage") {

                this._updateBillAmounts()
            }
        })

    }

    getBillTableData(stateKey, title, flexWidth = 0.05, type = 'textBox', index = null) {

        let { is_editable, billRows } = this.state;
        let zIndex = (index + 1) * -10;

        title = this._parseString(title)

        return (
            <View style={{ flex: flexWidth, zIndex: zIndex }}>
                {type === 'label' ? <Text style={{fontSize:'1vw'}}>{title}</Text>

                    : stateKey === 'brand_name' ?
                        (this._handleDrugSuggestion(type, index, stateKey))
                        : (stateKey === 'dosage_type' || stateKey === 'dosage_strength') ?
                            (this._handleDrugDetailSuggestion(type, index, stateKey))
                            : type === 'select' ?
                                (this._handleDrugBatchSelection(type, index, stateKey))
                                : type === 'icon' ?
                                    (<TouchableOpacity onPress={() => { billRows[index]["brand_name"] ? this._handleItemRemove(index) : null }} style={{ alignSelf: "center" }}>
                                        <Icon name="close" size={'1.17vw'} color={"#000000"} />
                                    </TouchableOpacity>)
                                    : stateKey === "quantity" ?
                                        <View style={{ flexDirection: "row", justifyContent: "flex-start", maxWidth: 60 }}>

                                            <View style={{ flex: 1 }}>
                                                <TextInput
                                                    style={{
                                                        // flex: 1,
                                                        height: '2vw', // 35
                                                        padding: 6,
                                                        marginRight: 5,
                                                        backgroundColor: !is_editable ? color.tableHeaderBG : color.white,
                                                        borderColor: "#CDD1D5",
                                                        borderRadius: 4,
                                                        borderWidth: 1,
                                                        fontSize: '0.71vw',
                                                    }}
                                                    keyboardType = "numeric"
                                                    editable={is_editable}
                                                    value={title || ""}
                                                    onChangeText={(text) => { this._updateBillState(stateKey, text, type, index) }}
                                                /></View>

                                        </View>
                                        : stateKey === "net_total_amount" ?
                                            <View style={{ flexDirection: "row", justifyContent: "flex-start", maxWidth: 60 }}>

                                                <View style={{ flex: 1 }}>
                                                    <TextInput
                                                        style={{
                                                            // flex: 1,
                                                            height: '2vw', // 35
                                                            padding: 6,
                                                            marginRight: 5,
                                                            backgroundColor: color.tableHeaderBG,
                                                            borderColor: "#CDD1D5",
                                                            borderRadius: 4,
                                                            borderWidth: 1,
                                                            fontSize: '0.71vw'
                                                        }}
                                                        editable={false}
                                                        value={title || ""}
                                                    /></View>

                                            </View>
                                            : (<LineItemTextBox
                                                disable={!is_editable || (is_editable && (stateKey === "hsn_code" || stateKey === "quantity" || stateKey === "discount_percentage") ? false : true)}
                                                value={title}
                                                stateKey={stateKey}
                                                type={type}
                                                index={index}
                                                keyboardType = {stateKey == "discount_percentage" ? "numeric" : null}
                                                onChangeText={this._updateBillState} />)

                }
            </View>)
    }

    getBillTable = () => {
        let { billRows } = this.state;
        let zIndexTitle = 10 * -1;
        let zIndexData = (1) * (-100);

        if (billRows.length < 10) {
            for (let i = billRows.length; i < 10; i++) {
                billRows.push(JSON.parse(JSON.stringify(tempRows)))
            }
        }

        return (
            <View style={{ flex: 1, flexDirection: "column", justifyContent: "flex-start", alignContent: "center", margin: '0.9vw', zIndex: zIndexTitle }}>
                <View style={{
                    flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
                    backgroundColor: color.tableHeaderBG, padding: '0.52vw', borderRadius: 4, zIndex: zIndexTitle, marginTop:'-1vw'
                }}>
                    {this.getBillTableTitle('S.No', 0.03,"0.8vw")}
                    {this.getBillTableTitle('Brand Name', 0.16,"0.8vw")}
                    {this.getBillTableTitle('Dosage', 0.07,"0.8vw")}
                    {this.getBillTableTitle('Dosage Type', 0.08,"0.8vw")}
                    {this.getBillTableTitle('Batch No - Avl Qty', 0.1,"0.8vw")}
                    {this.getBillTableTitle('HSN Code', 0.07,"0.8vw")}
                    {this.getBillTableTitle('Expiry Date', 0.06,"0.8vw")}
                    {this.getBillTableTitle('Box No', 0.06,"0.8vw")}
                    {this.getBillTableTitle('Cost/Qty', 0.06,"0.8vw")}
                    {this.getBillTableTitle('Quantity', 0.07,"0.8vw")}
                    {this.getBillTableTitle('Total Cost', 0.07,"0.8vw")}
                    {this.getBillTableTitle('Discount %', 0.06,"0.8vw")}

                    {this.getBillTableTitle('Total', 0.07,"0.8vw")}
                    {this.getBillTableTitle('Action', 0.04,"0.8vw")}
                </View>
                {/* <ScrollView> */}
                {billRows.map((item, index) => {
                    let sno = index + 1;
                    zIndexData = (index + 1) * (-100);
                    return (
                        
                            <View key={index} style={{
                                 flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
                                backgroundColor: "#F9F9F9", padding: 8, borderRadius: 4, marginTop: 5,
                                borderBottomWidth: 1, borderColor: color.tableHeaderBG, zIndex: zIndexData
                            }}>
                                {this.getBillTableData('', sno, 0.03, 'label')}
                                {this.getBillTableData('brand_name', item.brand_name, 0.16, '', index)}
                                {this.getBillTableData('dosage_strength', item.dosage_strength, 0.07, '', index)}
                                {this.getBillTableData('dosage_type', item.dosage_type, 0.08, '', index)}
                                {this.getBillTableData('batch_no', item.batch_no, 0.1, 'select', index)}
                                {this.getBillTableData('hsn_code', item.hsn_code, 0.07, '', index)}
                                {this.getBillTableData('expiry_date', item.expiry_date ? moment(item.expiry_date).format('YYYY-MM') : null, 0.06, '', index)}
                                {this.getBillTableData('physical_box_no', item.physical_box_no, 0.06, '', index)}
                                {this.getBillTableData('cost_per_quantity', item.cost_per_quantity, 0.06, '', index)}
                                {this.getBillTableData('quantity', item.quantity, 0.07, '', index)}
                                {this.getBillTableData('total_cost', item.total_cost, 0.07, '', index)}
                                {this.getBillTableData('discount_percentage', item.discount_percentage, 0.06, '', index)}

                                {this.getBillTableData('net_total_amount', item.net_total_amount, 0.07, '', index)}
                                { item.returned ? null : this.getBillTableData('', 'X', 0.04, 'icon', index)}
                            </View>
                    )
                })}
                {/* </ScrollView> */}
                {
                        <View style={{ flexDirection: "row", justifyContent: "flex-end", marginTop: 15, zIndex: zIndexData - 100 }}>
                            <ActionButton label={"Add Item"} keyIndex={"addNextRos"}
                                // source={require('../../assets/Icon/NextBill.png')}
                                onPressAction={this._addNextRows.bind(this, false)} />
                        </View>
                }
            </View>
        )
    }

    _addNextRows = () => {

        let { billRows, suggestions } = this.state

        let current_row = billRows.length;
        let bill_line_items = [];
        let bill_line_items_no_quantity = [];
        let add_flag = false;

        for (let i = 0; i < current_row; i++) {

            if (billRows[i].drug_id && billRows[i].quantity) {

                bill_line_items.push(billRows[i]);
            }
            else if (billRows[i].drug_id && !billRows[i].quantity) {
                bill_line_items_no_quantity.push(billRows[i])
            }
        }

        if ((current_row - bill_line_items.length) === 0) {
            add_flag = true;
        }

        if (add_flag) {

            let new_row = billRows.length + 5

            for (let i = current_row; i < new_row; i++) {
                billRows.push(JSON.parse(JSON.stringify(tempRows)));
                suggestions.push(JSON.parse(JSON.stringify(tempSuggestion)));
            }

            this.setState({
                billRows,
                suggestions
            })
        }
        else if (bill_line_items_no_quantity.length > 0) {
            this.setState({
                responseMessage: "Add quantity for added drugs",
                showErrorResponse: true,
                is_disable_action: false
            }, () => {
                this._clearNotification()
            })
        }
    }

    _clearNotification(do_navigate = false, next_page = '') {
        // clear after 2 seconds
        setTimeout(() => {
            this.setState({
                showSuccesResponse: false,
                showErrorResponse: false,
                responseMessage: ""
            }, () => {

                if (do_navigate) {
                    this.props.changeScreen(next_page)
                }
            });
        }, 2000)
    }

    _clearDropdowns = () => {

        let { flagShowDoctorList } = this.state

        if (flagShowDoctorList && (document.activeElement !== this.refDoctorName.current._node)) {
            // on focus out
            this.setState({
                flagShowDoctorList: false
            })
        }

    }

    checkFloat = (num) => {
        return Number(num) === num && num % 1 != 0
    }

    onFocusPer = () =>{
        this.setState({
            isOnFocusPer : true
        })
    }  
    onBlurPer = () =>{
        this.setState({
            isOnFocusPer : false
        })
    }

    getBillAmountColumns(amt) {
        return (
            <View style={{ flex: 0.4, flexDirection: "row", justifyContent: "flex-end", alignContent: "center", alignItems: "center" }}>
                <Image
                    style={{ width: '0.97vw', height: '0.65vw', marginRight: 5, tintColor: "grey" }}
                    source={require('../../assets/Icon/Rupee.png')}
                    resizeMode={"cover"}
                />
                <Text style={{ textAlign: "right", paddingRight: 40 , fontSize:'1vw'}}>{amt}</Text>
            </View>
        )
    }

    getCashTypes(typeLabel, value) {
        let { is_editable, data, is_return_editable } = this.state;
        return (
            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
                <RadioButton
                    disabled = { false }
                    value={typeLabel}
                    color="green"
                    status={data.payment_mode == value ? 'checked' : 'unchecked'}
                    onPress={() => {

                        if (is_editable || is_return_editable) {

                            data['payment_mode'] = value;

                            // clear data
                            data['received_amount'] = 0
                            data['card_number'] = ""
                            data['card_amount'] = 0
                            data['cash_amount'] = 0

                            data = this._updateRoundOff(data);

                            this.setState({ data }, () => {
                                this._updateBillAmounts()
                            })
                        }
                    }}
                />
                {/* <Text style={{ paddingTop: 10 }}>{typeLabel}</Text> */}
                <View style={{ paddingTop: 10 }}><CommonLabelTextView label={typeLabel} /></View>
            </View>
        )
    }

    getCashBalance(subLabel, stateName, value, isDisable = false, textBoxWidth) {
        let { data } = this.state
        return (
            <View style={{
                flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start",
                marginHorizontal: "0.65vw", width: '6.51vw'
            }}>

                {this.getBillTableTitle(subLabel, 1)}
                {isDisable ?
                    <Text style={[Style.commonTextBoxStyles, {
                        zIndex: -3,
                        color: color.labelColor, padding: 3,height: '1.95vw', marginTop: 5, fontSize:'0.91vw'
                    }]} >
                        {value}
                    </Text>
                    : <TextInput
                        style={{
                            borderColor: "#CDD1D5",
                            borderRadius: 4,
                            borderWidth: 1,
                            width: textBoxWidth,
                            height: '1.95vw',
                            padding: 5,
                            marginTop: 5,
                            fontSize:'0.91vw'
                        }} value={value}
                        keyboardType = {data.payment_mode == 1 || data.payment_mode == 3 || stateName == "card_number" ? "numeric" : null}
                        // editable = { is_bill_type_disable  ? false : true }
                        onChangeText={(text) => {
                            this._updateState2(stateName, text, '')
                        }}
                    />}
            </View>
        )
    }

    getBillAmountDetails() {

        let { data, billRows, is_editable, is_initiate_return} = this.state;

        let rowLen = billRows.length || 0;

        let zIndex = (rowLen + 1) * (-100);
        // data['net_total_amount'] = net_total_amount.toFixed(2)


        return (
            <View style={{ marginBottom: 10, zIndex: zIndex, marginTop: 15 }}>
                <View style={{ flex: 1, flexDirection: "row-reverse" }}>
                    <View style={{ flex: 0.3 }}>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ flex: 0.6 }}>
                                {/* <Text>{"Sub Amount"}</Text> */}
                                <CommonLabelTextMView label={"Sub Amount"} />
                            </View>
                            <View style={{ flex: 0.4, flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                                <Image
                                    style={{ width:'0.97vw', height: "0.65vw", marginRight: 5, tintColor: "grey" }}
                                    source={require('../../assets/Icon/Rupee.png')}
                                    resizeMode={"cover"}
                                />
                                <CommonLabelTextMView label={is_initiate_return ? data.taxable_return_amount : parseFloat(this.state.subAmount).toFixed(2)} align={'right'} padRight={40} />
                            </View>
                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "center", alignContent: "center", marginVertical: 8 }}>
                            <View style={{ flex: 0.3, alignSelf: "center" }}>
                                {/* <Text>{"Discount %"}</Text> */}
                                <CommonLabelTextMView label={"Discount %"} />
                            </View>
                            <View style={{ flex: 0.3, alignSelf: "center" }}>

                                <TextInput
                                    style={{
                                        borderColor: "#CDD1D5",
                                        borderRadius: 4,
                                        borderWidth: 1,
                                        width: "80%",
                                        height: '1.62vw',
                                        paddingVertical: 3,
                                        paddingHorizontal: 8,
                                        // marginTop: 5 
                                        textAlign: "right",
                                        fontSize:'0.9vw'
                                    }}
                                    value={(this.checkFloat(parseFloat(data.general_discount_percentage)) && ! this.state.isOnFocusPer ) ? parseFloat(data.general_discount_percentage).toFixed(2) : parseFloat(data.general_discount_percentage)}
                                    editable={is_editable}
                                    onFocus={ ()=>{ this.onFocusPer()}}
                                    onBlur = {() => {this.onBlurPer()}}
                                    keyboardType = "numeric"
                                    onChangeText={(text) => {
                                        if (text.length < 3) {
                                            let { data } = this.state
                                            let taxable_amount = parseFloat(data.taxable_amount) || 0
                                            let general_discount_percentage = parseFloat(text) || 0
                                            data['general_discount_percentage'] = text
                                            data['general_discount'] = ((taxable_amount * general_discount_percentage) / 100 || 0)

                                            data = this._updateRoundOff(data)

                                            this.setState({
                                                data
                                            }, () => {
                                                this._updateBillAmounts()
                                                this._updateRoundOff(data)

                                            })
                                        } 
                                    }}
                                />
                            </View>
                            <View style={{ flex: 0.4, flexDirection: "row", justifyContent: "flex-end", alignSelf: "center", paddingRight: 40 }}>
                                {/* {this.getBillAmountColumns(data.general_discount_amount || 0)} */}
                                <Image
                                    style={{ width: '0.97vw', height: '0.65vw', marginRight: 5, tintColor: "grey" }}
                                    source={require('../../assets/Icon/Rupee.png')}
                                    resizeMode={"cover"}
                                />
                                <TextInput
                                    style={{
                                        borderColor: "#CDD1D5",
                                        borderRadius: 4,
                                        borderWidth: 1,
                                        width: "50%",
                                        height: '1.62vw',
                                        paddingVertical: 3,
                                        paddingHorizontal: 8,
                                        // marginTop: 5 
                                        textAlign: "right",
                                        fontSize:'0.9vw'
                                    }}
                                    keyboardType ="numeric"
                                    onFocus={ ()=>{ this.onFocus()}}
                                    onBlur = {() => {this.onBlur()}}
                                    value={(this.checkFloat(parseFloat(data.general_discount)) && !this.state.isOnFocus) ? parseFloat(data.general_discount).toFixed(2) : parseFloat(data.general_discount)}
                                    editable={is_editable}
                                    onChangeText={(text) => {
                                       if( parseFloat(text) > parseFloat(this.state.subAmount) ) { 
                                           this.setState({
                                             responseMessage: "The discount amount is greater than total amount",
                                             showErrorResponse: true,
                                             is_disable_action: false
                                           }, () => {
                                             this._clearNotification()
                                           })
                                       } else {
                                           let { data } = this.state
                                           let general_discount = parseFloat(text) || 0;
                                           // let general_discount_percent = 0;
                                           let taxable_amount = parseFloat(data['taxable_amount']) || 0;

                                           data['general_discount'] = text
                                           data['general_discount_percentage'] = ((general_discount * 100 / taxable_amount) || 0)

                                           data = this._updateRoundOff(data)

                                           this.setState({
                                               data
                                           }, () => {
                                               this._updateBillAmounts()
                                           })
                                       }
                                    }}
                                />
                            </View>

                        </View>
                        <View style={{ flexDirection: "row" }}>
                            <View style={{ flex: 0.6 }}>
                                {/* <Text>{"Round Off"}</Text> */}
                                <CommonLabelTextMView label={"Round Off"} />
                            </View>
                            {/* <View style={{ flex: 0.4 }}>
                                <Text>{data.round_off}</Text>
                            </View> */}
                            {this.getBillAmountColumns(data.round_off)}
                        </View>
                        <View style={{ flexDirection: "row", marginTop:10 }}>
                            <View style={{ flex: 0.6 }}>
                                {/* <Text>{"Net Amount"}</Text> */}
                                <CommonLabelTextMView label={"Net Amount"} />
                            </View>

                            {this.getBillAmountColumns(is_initiate_return ? "-" + data.grand_return_total : data.grand_total)}
                        </View>
                    </View>
                    <View style={{ flex: 0.7, marginRight: '0.97vw', marginTop: '0.65vw' }}>

                        <Text style={{fontWeight:"500",fontSize:'1.43vw',marginLeft:'3.5vw'}}>{"Payment Type"}</Text>
                        <View style={{ flexDirection: "row", marginLeft: '3.5vw' }}>
                            { is_initiate_return ? null :
                               <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("Card", "2")}</View>
                            }
                            <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("Cash", "1")}</View>
                            { is_initiate_return  ? null :
                              <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("Card & Cash", "3")}</View> 
                            }
                            <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("UPI", "4")}</View>
                            <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("Bank Transfer", "5")}</View>
                            { is_initiate_return  ? null :
                              <View style={{ marginRight: '0.97vw' }}>{this.getCashTypes("Insurance", "6")}</View> 
                            }

                        </View>
                        <View style={{ flexDirection: "row", justifyContent: "flex-start", marginLeft: '3.5vw',flex:1,  marginTop:'1.3vw' }}>
                            {data.payment_mode == "2" || data.payment_mode == "3" ?
                                <View style={{}}>{this.getCashBalance("Card Number", "card_number", data.card_number, !is_editable, "100%")}</View>
                                : null}
                            <View style={{}}>{this.getCashBalance("Total", "grand_total", data.grand_total, true)}</View>

                            {data.payment_mode == "1" ? <View style={{}}>{this.getCashBalance("Received Amount", "received_amount", this.state.is_complete_bill ? Math.round(data.received_amount) : data.received_amount, is_initiate_return ? true : !is_editable, "100%")}</View> : null}
                            {data.payment_mode == "1" ? <View style={{}}>{this.getCashBalance("Balance", "balance_amount", data.balance_amount || 0, true, "100%")}</View> : null}

                            {data.payment_mode == "3" ? <View style={{}}>{this.getCashBalance("Card", "card_amount", data.card_amount, !is_editable, "100%")}</View> : null}
                            {data.payment_mode == "3" ? <View style={{}}>{this.getCashBalance("Cash", "cash_amount", data.cash_amount, !is_editable, "100%")}</View> : null}

                            {data.payment_mode == "4" ? <View style={{}}>{this.getCashBalance("Transaction ID", "transaction_id", data.transaction_id, is_initiate_return ? false : !is_editable, "100%")}</View> : null}
                            {data.payment_mode == "5" ? <View style={{}}>{this.getCashBalance("Transaction ID", "transaction_id", data.transaction_id, is_initiate_return ? false : !is_editable, "100%")}</View> : null}

                            {data.payment_mode == "6" && !is_initiate_return ? <View style={{flex:0.32}}>{this.getCashBalance("Company Name", "insurance_company_name", data.insurance_company_name, false, '13vw')}</View> : null}
                            {data.payment_mode == "6" && !is_initiate_return ? <View style={{flex:0.2}}>{this.getCashBalance("Policy Number", "policy_number", data.policy_number, !is_editable, "100%")}</View> : null}
                            {/* {data.payment_mode == "6" ? <View style={{flex:0.18}}>{this.getCashBalance("Validity", "validity", data.validity, !is_editable, "100%")}</View> : null} */}
                            {data.payment_mode == "6" && !is_initiate_return ? <View style={{flex:0.32}}>{this.getCashBalance("Transaction Number", "transaction_number", data.transaction_number, !is_editable, "100%")}</View> : null}
                        </View>
                    </View>
                </View>
                <View style={{ marginVertical: '0.32vw', }}></View>
                
            </View>
        )
    }

    _printBill() {

        let { data } = this.state;

        // call save API
        let url = "";

        if (data.bill_summary_id) {

            url = Constants.PHARMACY_BILLING + "?id=" + data.bill_summary_id + "&export=pdf";

            this.service.getInstance().documentUploadGet(
                url,
                this._printSuccessCallBack,
                this.errorCallBack,
                "pdf"
            );
        } else {
            console.log(" summary id not available")
        }

    }

    _printSuccessCallBack = (response) => {

        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }

    getBillActions() {
        return(
            <View style={{ flexDirection: "row", marginBottom: 30, justifyContent: "center" }}>
                <ActionButton label={"Update"} keyIndex={"modifyBill"}
                isDisable={false}
                imgPath={require("../../assets/Icon/SavedBills.png")}
                onPressAction={() => {this.onPressUpdateBill()}} />
                <ActionButton label={"Print"} keyIndex={"printBill"}
                    imgPath={require("../../assets/Icon/Print.png")}
                    onPressAction={this._printBill.bind(this)}
                />
            </View>
        )
    }

    onPressUpdateBill = () => {
        let errorMessage = [];
        let isValid = true;
        let { billRows, data } = this.state;

        let saveObj = JSON.parse(JSON.stringify(data));

        // Remove extra objects
        saveObj = (delete saveObj['gross_return_amount'], saveObj);
        saveObj = (delete saveObj['grand_return_total'], saveObj);
        saveObj = (delete saveObj['taxable_return_amount'], saveObj);
        saveObj = (delete saveObj['general_discount_percentage'], saveObj);
        // saveObj = (delete saveObj['doctor_id'], saveObj);
        saveObj = (delete saveObj['clinic_id'], saveObj);
        saveObj = (delete saveObj['transmitted_id'], saveObj);

        // format data
        saveObj['discount_amount'] = this._setDataFormat(data['discount_amount'], 'decimal');
        
        saveObj['cgst_amount'] = this._setDataFormat(data['cgst_amount'], 'decimal');

        saveObj['sgst_amount'] = this._setDataFormat(data['sgst_amount'], 'decimal');
        saveObj['igst_amount'] = this._setDataFormat(data['igst_amount'], 'decimal');
        saveObj['tax_amount'] = this._setDataFormat(data['tax_amount'], 'decimal');
        saveObj['round_off'] = this._setDataFormat(data['round_off'], 'decimal');
        saveObj['delivery_charge'] = this._setDataFormat(data['delivery_charge'], 'decimal');
        saveObj["doctor_id"] = this._setDataFormat(data['doctor_id'], '');

        if (data["payment_mode"] == "4" || data["payment_mode"] == "5" || data["payment_mode"] == "6" || data["payment_mode"] == "2" ) {
            saveObj['received_amount'] = this._setDataFormat(data['grand_total'], 'decimal');
        }
        else if(data["payment_mode"] == "3"){
            if((parseFloat(data['cash_amount'])<0) || (parseFloat(data['card_amount'])<0)){
                isValid = false 
                this.setState({
                    responseMessage: "Invalid Amount",
                    showErrorResponse: true,
                    is_disable_action: false
                }, () => {
                    this._clearNotification()
                })
                return;
            }
            saveObj['received_amount'] = this._setDataFormat(data['grand_total'], 'decimal');
        } else {
            if(parseFloat(data["received_amount"]) <= parseFloat(data["grand_total"])){
                saveObj['received_amount'] = this._setDataFormat(data['received_amount'], 'decimal')
            }else {
                saveObj['received_amount'] = this._setDataFormat(data['grand_total'], 'decimal')
            }

        }
        saveObj['general_discount'] = this._setDataFormat(data['general_discount'], 'decimal');
        saveObj['general_discount_percentage'] = parseFloat(data['general_discount_percentage'] ? data['general_discount_percentage'] : 0);

        saveObj['balance_amount'] = this._setDataFormat(data['balance_amount'], 'decimal');
        saveObj['payment_mode'] = this._setDataFormat(data['payment_mode'], 'int');
        saveObj['grand_total'] = this._setDataFormat(data['grand_total'], 'decimal');

        // set invoice date
        saveObj['invoice_date'] = this._setDataFormat( data['invoice_date'] , 'date')

        saveObj['taxable_amount'] = this._setDataFormat(data['taxable_amount'], 'decimal');

        let bill_line_items = [];
        let bill_line_items_no_quantity = []
        let schedule_item = [];
        let schedule_drug = "";

        for (let i = 0; i < billRows.length; i++) {

            let temp = {
                "drug_id": this._setDataFormat(billRows[i].drug_id, 'int'),
                "hsn_code": billRows[i].hsn_code,
                "batch_no": billRows[i].batch_no,
                // "quantity": this._setDataFormat(billRows[i].quantity, 'int'),
                "expiry_date": this._setDataFormat(billRows[i].expiry_date, 'date'),
                "physical_box_no": billRows[i].physical_box_no,
                "cost_per_quantity": this._setDataFormat(billRows[i].cost_per_quantity, 'decimal'),
                "total_cost": this._setDataFormat(billRows[i].total_cost, 'decimal'),
                "discount_percentage": this._setDataFormat(billRows[i].discount_percentage, 'decimal'),
                "gst_percentage": this._setDataFormat(billRows[i].gst_percentage, 'decimal'),
                // "net_total_amount": this._setDataFormat(billRows[i].net_total_amount, 'decimal')
            }

            temp["quantity"] = this._setDataFormat(billRows[i].quantity, 'int')
            temp["net_total_amount"] = this._setDataFormat(billRows[i].net_total_amount, 'decimal')

            if(billRows[i].id){
                temp["line_item_id"] = billRows[i].id
            }

            if (temp.drug_id && temp.quantity) {
                schedule_drug = billRows[i]['schedule'] ? billRows[i]['schedule'] : ""
                bill_line_items.push(temp);
                schedule_item.push(schedule_drug);
            }
            else if (temp.drug_id && !temp.quantity) {
                bill_line_items_no_quantity.push(temp)
            }
        }
        let isSchedule = schedule_item && schedule_item.length > 0 && schedule_item.some(item => item !== "")
        if (bill_line_items.length > 0 ){
            let cash_amount = parseFloat(saveObj["cash_amount"])
            let card_amount = parseFloat(saveObj["card_amount"])
            let Total_cash_card_amount = parseFloat(saveObj["cash_amount"]) + parseFloat(saveObj["card_amount"])

            let grand_total = this._setDataFormat(data['grand_total'], 'decimal')
            let received_amount = this._setDataFormat(data['received_amount'], 'decimal')
            let net_total_amount = this._setDataFormat(data["net_total_amount"],'decimal') 

            if ( saveObj['payment_mode'] == 1 && !saveObj['received_amount']) {
                isValid = false; errorMessage.push("Enter received amount");
            }
            else if (saveObj['payment_mode'] == 1 && received_amount < grand_total) {
                isValid = false; errorMessage.push("Received amount should greater than bill amount");
            }
            else if (saveObj['payment_mode'] == 3 && Total_cash_card_amount != grand_total && data.bill_type == "Bill") {
                isValid = false; errorMessage.push("Received amount should match with bill amount");
            }
            else if (saveObj['payment_mode'] == 3 && !cash_amount ) {
                isValid = false; errorMessage.push("Enter cash amount");
            }
            else if (saveObj['payment_mode'] == 3 && !card_amount) {
                isValid = false; errorMessage.push("Enter card amount");
            }else if( isSchedule && data.doctor_name === "" ){
                isValid = false; errorMessage.push("This is an schedule drug so please enter the doctor name");
            }
        }else {

            isValid = false;

            if (bill_line_items_no_quantity.length > 0) {
                errorMessage.push("Add quantity for added drugs")
            }
            else {
                errorMessage.push("No drugs added")
            }
        }

        if (bill_line_items.length > 0 && isValid) {
            saveObj['bill_line_items'] = bill_line_items;
            saveObj['doctor_reg_no'] = data.doctor_reg_no ? data.doctor_reg_no : ""

            let url = "";

            url = Constants.BILL_COMPLETE_BILLING;

            this.service.putApiCall(
                url,
                saveObj,
                this.successCallBack,
                this.errorCallBack
            )
        }else {

            this.setState({
                responseMessage: errorMessage ? errorMessage.join() : "",
                showErrorResponse: true,
                is_disable_action: false
            }, () => {
                this._clearNotification()
            })
        }
    }

    successCallBack = (response) => {
        if (response.status === "success") {
            this.setState({
                responseMessage: response.message,
                showSuccesResponse: true
            },() => {
                this._clearNotification(true, "invoice")
            })
            
        }else {
            this.setState({
                responseMessage: response.message,
                showErrorResponse: true
            }, () => {
                this._clearNotification()
            })
        }

    }

    render(){
        var {showSuccesResponse, showErrorResponse, responseMessage} = this.state
        return(
            <TouchableWithoutFeedback onPress={() => {this._clearDropdowns()}}>
                <View>
                    <View>
                    {this.getBillDetails()}
                    {this.getBillTable()}
                    {this.getBillAmountDetails()}
                    {this.getBillActions()}
                    {
                        showSuccesResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }
                    </View>
                </View>
            </TouchableWithoutFeedback>
        )
    }
}