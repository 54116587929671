import React, { Component } from "react";
import { Text, View, TouchableOpacity, ScrollView, Dimensions, TextInput, Image, StyleSheet } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { RadioButton } from 'react-native-paper';
import { Tooltip } from "antd";
import { ActionButton, CommonLabelTextView, CommonReportSectionHeader, CommonTextBox, MultiLineCommonTextBox, SuccessAlert, ErrorAlert, CommonDatePicker } from "../../../common/BaseFunctionComponent";
import Pagination from "../../../common/Pagination";
import Constants from "../../../networks/Constants";
import Service from "../../../networks/service";
import { color } from "../../../styles/Color";
import { Picker } from '@react-native-picker/picker';
import moment from "moment";

const screenHeight = Dimensions.get("window").height;
const tempRow = {
    drug_name: "",
    generic_name: "",
    drug_strength:"",
    dosage_type: "",
    schedule: "",
    group: "",
    hsn_code: "",
    batch_no: "",
    expiry_date: "",
    reorder_threshold: "",
    free_quantity:"",
    mrp_per_quantity:0,
    expiry_alert_period:"6",
    physical_box_no:"",
};
export default class StockAdjustment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_page: 1,
            records_per_page: 10,
            stockDataList: [],
            stockDataListAfterPagination: [],
            stockDataList_suggest: [],
            editStockDetail: {},
            beforeStockEdit: tempRow,
            isEditStock: false,
            editStockQty: 0,
            selectedReason: "",
            update_comment: "",
            isHistroryClick: false,
            isDeleteClick: false,
            StockId:"",
            selectedHistoryDrug: "",
            selectedHistoryID: null,
            selectedBatchNO: "",
            historyStockDetail: [],
            searchBrandName: "",
            dosageTypeList: [],
            dosageTypeListOriginal: [],
            forceUpdate: false,
            helperText: false,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",

        }

        this.service = new Service()
    }


    componentDidMount(){
        this.getStockList()
        this._getDosageTypes()
    }

    _getDosageTypes = () => {
        let url = Constants.LIST_DOSAGE;
        this.service.getApiCall(
            url,
            this._getDosageTypeSuccess,
            this._getDrugFailure
        )
    }

    _getDosageTypeSuccess = response => {
        if (response.status === "success") {
            this.setState({
                dosageTypeList: response.data || []
            }, () => {
                var list = this.removeDuplicate(this.state.dosageTypeList)
                this.setState({
                    dosageTypeListOriginal: list
                })
            });
        }
    };

    _getDrugFailure = error => {}
    removeDuplicate(data) {
        var nameListJsonObject = data.map(JSON.stringify);
        var nameListUniqueSet = new Set(nameListJsonObject);
        var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    getStockList = (searchkey = "") => {
        this.setState({
            stockDataList: [],
            current_page: 1,
            stockDataListAfterPagination: [],
        });
        let url = Constants.STOCK_ADJUSTMENT + "?brand_name=" + searchkey;
        this.service.getApiCall(
        url,
        this.getStockListSuccess,
        this.getStockListFailure
        )
    }

    getStockListSuccess = (response) => {
        if (response.status === "Success") {

            this.setState({
                stockDataList: response.data.stock_list_items,
            });
        }
    }

    getStockListFailure =(response) => {

    }

    onPressExport = () => {
        let url = Constants.STOCK_ADJUSTMENT + "?export_type=excel" + "&brand_name=" + this.state.searchBrandName;
        this.service.getInstance().documentUploadGet(
        url,
        this.onPressExportSuccess,
        this.onPressExportFailure,
        "excel"
        )
    }

    onPressExportSuccess = response => {
        if (response) {

            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
        }
    }

    onPressExportFailure = error => { }

    paginationCurrentPage = (current_page, records_per_page) => {
    this.setState({
        current_page: current_page,
        records_per_page: records_per_page
    })
    }
    renderPaginationView() {
    return (
        <View style={{ marginHorizontal: "0.3vw" }}>
        {
            this.state.stockDataList && this.state.stockDataList.length > -1 ?
            <Pagination
                paginationChangedValues={this.paginationChangedValues.bind(this)}
                totalClientItems={this.state.stockDataList}
                paginationCurrentPage = { this.paginationCurrentPage.bind(this) }
                recordsPerPage= {this.state.records_per_page}
                current_page={this.state.current_page}
            /> : null
        }
        </View>
    )
    }
    paginationChangedValues = (data) => {
    this.setState({
        stockDataListAfterPagination: data
    })
    }
    editStockDetail = (stateKey, text) => {
        var {editStockDetail} = this.state
       

          // number validation
          if (stateKey === "free_quantity" || stateKey === "reorder_threshold") {

            if (!(Constants.REG_EX_NUMBERS.test(text) || text == "")) {
                // no change
                return
            }
        }

        //update
        
        editStockDetail[stateKey] = text
        this.setState({ editStockDetail })
    }
    renderTextBox = (placeholder, key, value) => {
        return(
            key === "expiry_date" ?
            <View style={{marginLeft: "3.13vw", marginBottom: "0.625vw", marginTop:"0.313vw"}}>
                <CommonDatePicker disable={true} width={300} placeholder={placeholder} type={"expiry-date"} stateKey={key} value={value} onChangeText={this.editStockDetail.bind(this)}  />
            </View>
            : key === "expiry_alert_period" ?
            <View style={{ width: "16.5vw",marginLeft: "1.88vw",marginBottom: "0.625vw" }}>
                                    <Text style={[styles.floatingLabelText,{fontSize:'0.7vw'}]}>{"Expiry Alert Period"}</Text>
                                    <Picker
                                        selectedValue={value}
                                        itemStyle = {{ fontSize:"0.7.vw" }}
                                        onValueChange={(itemValue) => {
                                            let states = this.state;
                                            states['editStockDetail']['expiry_alert_period'] = itemValue;
                                            this.setState({
                                                states
                                            })
                                        }}
                                        style={[styles.pickerView, {
                                            marginTop: 5, borderColor: '#CDD1D5',borderRadius:4
                                        }]}
                                    >
                                        <Picker.Item label="-" value=""/>
                                        <Picker.Item label="1 month" value="1" />
                                        <Picker.Item label="2 months" value="2" />
                                        <Picker.Item label="3 months" value="3" />
                                        <Picker.Item label="6 months" value="6" />
                                    </Picker>
            </View>
            : key === "dosage_type" ?  
             <View style={{ width: "16.5vw",marginLeft: "1.88vw",marginBottom: "0.625vw",marginTop:-5 }}>
                                <Text style={[styles.floatingLabelText,{fontSize:'0.7vw'}]}>{"Dosage Type *"}</Text>
                                <Picker
                                selectedValue={value}
                                style={ [styles.pickerView, {
                                        marginTop: 5, borderColor: '#CDD1D5',borderRadius:4}]
                                      } 
                            itemStyle={{}}
                            onValueChange={(itemvalue) => {
                                var states = this.state;
                                states['editStockDetail']['dosage_type'] = itemvalue;
                                this.setState({ states });
                            }}
                                >
                                <Picker.Item label='-' value='' />
                                {(this.state.dosageTypeListOriginal.length > 0 && key === "dosage_type") ?
                                this.state.dosageTypeListOriginal.map((list, index) => (
                                    <Picker.Item key={index} label={list } value={list} />
                                ))
                                : null
                                }
                                </Picker>
            </View>
                                :
                                <View style={{marginLeft: "1.88vw",marginBottom:"0.625vw"}}>
                                    <CommonTextBox disable={ (key === "physical_box_no" ||  key === "batch_no" || key === "mrp_per_quantity") ? true : false } width={"16vw"} placeholder={placeholder} type={""} stateKey={key} value={value} onChangeText={this.editStockDetail.bind(this)}  />
                                </View>
        )
    }

    updateQty = (stateKey, value) => {
        var data = this.state
        if(value.length > 0){
            data[stateKey] = parseInt(value);
            this.setState({
                data
            })
        }else{
            data[stateKey] = 0;
            this.setState({
                data
            })
        }
    }

    renderRadioBtn = (text, value) => {
        return(
        <View style={{ flexDirection: "row", justifyContent: "flex-start", alignContent: "center", marginRight: "0.9vw" }}>
            <RadioButton
                value={value}
                color="green"
                status={this.state.selectedReason == value ? 'checked' : 'unchecked'}
                onPress={() => {
                    this.setState({
                        selectedReason: value
                    })
                }}
            />
            <View style={{ paddingTop: "0.625vw" }}><CommonLabelTextView label={text} /></View>
        </View>
        )
    }

    updateComment = (stateKey, text) => {
        let states = this.state;

        if(text.length < 200){
            states[stateKey] = text;
            this.setState({
                states
            })
        }
    }

    updateStock = () => {
        var {editStockQty, editStockDetail, selectedReason, update_comment, forceUpdate}= this.state
        var data= editStockDetail 
        data["available_quantity"] = editStockQty
        data["comments"] = update_comment
        data["reason"] = parseInt(selectedReason)
        data["force_update"] = forceUpdate

        let url = Constants.STOCK_ADJUSTMENT

        this.service.putApiCall(
            url,
            data,
            this.updateStockSuccess,
            this.updateStockFailure
        )
    }

    updateStockSuccess = (response) => {
        if (response.status === "Success") {
            this.setState({
                editStockDetail: {},
                isEditStock: false,
                editStockQty: 0,
                update_comment: "",
                selectedReason: "",
                forceUpdate: false,
                helperText: false,
                showSuccessResponse: true,
                responseMessage: response.message
            },() => {
                setTimeout(() => {
                    this.setState({
                        showSuccessResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
                this.getStockList(this.state.searchBrandName)
                this.props._getDrugAndStockCount() ;
            })
        }else{

            if(response.message === "Drug already exists"){
                this.setState({
                    forceUpdate : true,
                    helperText : true
                })
            }else{
            this.setState({
                forceUpdate : false,
                helperText : false,
                showErrorResponse: true,
                responseMessage: response.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showSuccessResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            })
            }
        }
    }

    updateStockFailure = (response) => {

    }

    reset = () => {
        var states = this.state
        states["editStockQty"] = states.editStockDetail.stock_quantity
        states["selectedReason"] = ""
        states["update_comment"] = ""
        states["forceUpdate"] = false
        states["helperText"] = false
        states["editStockDetail"]["drug_name"] = states.beforeStockEdit['drug_name']
        states["editStockDetail"]['schedules'] = states.beforeStockEdit['schedules']
        states["editStockDetail"]['batch_no']  = states.beforeStockEdit['batch_no']
        states["editStockDetail"]['drug_strength'] = states.beforeStockEdit['drug_strength']
        states["editStockDetail"]['generic_name'] = states.beforeStockEdit['generic_name']
        states["editStockDetail"]['dosage_type'] = states.beforeStockEdit['dosage_type']
        states["editStockDetail"]['grouping'] = states.beforeStockEdit['grouping']
        states["editStockDetail"]['hsn_code'] = states.beforeStockEdit['hsn_code']
        states["editStockDetail"]['expiry_date'] = states.beforeStockEdit['expiry_date']
        states["editStockDetail"]['reorder_threshold'] = states.beforeStockEdit['reorder_threshold']
        states["editStockDetail"]['free_quantity'] = states.beforeStockEdit['free_quantity']
        states["editStockDetail"]['mrp_per_quantity'] = states.beforeStockEdit['mrp_per_quantity']
        states["editStockDetail"]['expiry_alert_period'] = states.beforeStockEdit['expiry_alert_period']
        states["editStockDetail"]['physical_box_no'] =  states.beforeStockEdit['physical_box_no']

        this.setState({
            states
        })
    }

    getStockUpdateHistory = (drugId, StockId) => {
        let url = Constants.STOCK_ADJUSTMENT_HISTORY + "?drug_id=" + drugId + "&stock_id=" + StockId;
        this.service.getApiCall(
        url,
        this.getStockUpdateHistorySuccess,
        this.getStockUpdateHistoryFailure
        )
    }

    getStockUpdateHistorySuccess = (response) => {
        if (response.status === "Success") {

            this.setState({
                historyStockDetail: response.data,
      
            });
        }
    }
    
    getStockUpdateHistoryFailure = (response) => {
    }
    printStockAdjustment = () => {
        let url = "";
        url = Constants.STOCK_ADJUSTMENT+ "?brand_name=" + this.state.searchBrandName + "&export_type=pdf";
        this.service.getInstance().documentUploadGet(
            url,
            this.printStockAdjustmentSuccess,
            this.printStockAdjustmentFailure,
            "pdf"
        );
   

    }

    printStockAdjustmentSuccess = (response) => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }
    printStockAdjustmentFailure = () => {

    }
    printStockHistory = () => {
        let url = "";
        url = Constants.STOCK_ADJUSTMENT_HISTORY+ "?drug_id=" + this.state.selectedHistoryID + "&stock_id=" + this.state.selectedBatchNO + "&export_type=pdf";
        this.service.getInstance().documentUploadGet(
            url,
            this.printStockHistorySuccess,
            this.printStockHistoryFailure,
            "pdf"
        );
   

    }

    printStockHistorySuccess = (response) => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }
    printStockHistoryFailure = () => {

    }

    deleteStock=(id)=>{
        this.setState({
            isDeleteClick: false
        },() => {this.DeleteStock(id)})
    }

    DeleteStock = (id) => {
        var url = Constants.STOCK_ADJUSTMENT_DELETE+ "?stock_id=" + id;
        var data = {
            "id": id
        }
        this.service.deleteApiCall(
            url,
            data,
            this.DeleteStockSuccess,
            this.DeleteStockFailure
        )
    }
    DeleteStockSuccess = (response) => {
        if (response.status == 'success') {
            this.setState({
                showSuccessResponse: true,
                responseMessage: response.message
            },() => {
                this.getStockList(this.state.searchBrandName)
                setTimeout(() => {
                    this.setState({
                        showSuccessResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            })
        }else {
            this.setState({
                showErrorResponse: true,
                responseMessage: response.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showSuccessResponse: false,
                        showErrorResponse: false,
                        responseMessage: ""
                    })
                }, 2000)
            })
        }
    }
    DeleteStockFailure = (error) => {
        if (error.status == 'fail') {
        this.setState({
            showErrorResponse: true,
            responseMessage: error.message
        }, () => {
            setTimeout(() => {
                this.setState({
                    showSuccessResponse: false,
                    showErrorResponse: false,
                    responseMessage: ""
                })
            }, 2000)
        })
    }
}

    render(){
        var {current_page, records_per_page, editStockDetail, historyStockDetail, searchBrandName, editStockQty, selectedReason, update_comment,beforeStockEdit} = this.state
        var isUpdateValid = false
        if((( JSON.stringify(beforeStockEdit) != JSON.stringify(editStockDetail) )||editStockDetail.stock_quantity != editStockQty) && selectedReason != "" && update_comment != ""){
            isUpdateValid = true
        }
        return(
            <View style={{height: screenHeight - 120}}>
            <View style={{flex: 1, flexDirection: "row", paddingHorizontal: "1.25vw", marginVertical: "0.63vw", maxHeight: "3.75vw"}}>
                <View style={{flex: 0.3}}>
                    {/* <Text style={{fontSize: 20, fontWeight: "500"}}>Stock Adjustment</Text> */}
                </View>
                <View style={{flex: 0.7, flexDirection: "row", justifyContent: "flex-end"}}>
                    <View style={{flexDirection: "row"}}>
                        <TextInput
                        value = {searchBrandName}
                        onChangeText={(text) => {
                            this.setState({
                                searchBrandName: text
                            })
                        }}
                        style={{borderWidth: 1, height: "2.2vw", width: "10.62vw", paddingHorizontal: "0.19vw", borderColor: color.lightGray, fontSize:"1vw" }}
                        placeholder="Search drug..."
                        />
                        <TouchableOpacity 
                        style={{height: "2.2vw", width: "2.2vw", backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                        onPress={() => {
                            this.getStockList(searchBrandName)
                        }}>
                            <Icon name='search' size={"0.93vw"} color={color.white}></Icon>
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity
                    onPress={() => { this.printStockAdjustment() }}
                    style={{marginHorizontal: "0.62vw", width: "2.5vw", height: "2.19vw", backgroundColor: color.themeDark, paddingHorizontal: "0.313vw", paddingVertical: "0.16vw", borderRadius: "0.25vw"}}>
                    <Image style={{width: "1.8vw", height: "1.8vw"}}
                        source={require('../../../../assets/images/HomeScreenImages/Print.png')} />
                    </TouchableOpacity>
                    <TouchableOpacity
                    onPress={() => {this.onPressExport()}}
                    style={{marginHorizontal: "0.93vw", width: "2.5vw", height: "2.19vw", backgroundColor: color.themeDark, paddingHorizontal: "0.313vw", paddingVertical: "0.16vw", borderRadius: "0.25vw"}}>
                    <Image style={{width: "1.8vw", height: "1.8vw"}}
                        source={require('../../../../assets/images/HomeScreenImages/Export.png')} />
                    </TouchableOpacity>
                </View>
            </View>
            <ScrollView style={{maxHeight: screenHeight - 180, marginTop: "3.12vw"}}>
            <View style={{ paddingHorizontal: "0.63vw", flexDirection: "row", width: "100vw"}}>
                <View style={{width: "20vw"}}>
                    <CommonReportSectionHeader 
                    heading1={"S.No"}
                    heading2={"Brand Name"}
                    columnSize={[0.30, 0.70]}
                    noOfColumn={2}  
                    fontSize={"1vw"}        
                    />
                    {this.state.stockDataListAfterPagination && this.state.stockDataListAfterPagination.length > 0 && this.state.stockDataListAfterPagination.map((item, index) => {
                    let sno = (current_page-1)*records_per_page + index + 1;
                    return(
                    <View style={{ justifyContent: 'center', width: "100%", maxHeight: "3.75vw", minHeight: "3.75vw" }}>
                    <View style={{flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, maxHeight: "3.75vw", paddingVertical: "1.25vw",}} >
                        <View style={{flex: 0.30, marginLeft: "1.25vw" }}><Text style={{fontSize: "1vw"}}>{sno}</Text></View>
                        <View style={{flex: 0.70}}>
                            <Tooltip title={item.drug_name && (item.drug_name).length > 30 ? item.drug_name : null}>
                            <Text style={{fontSize: "1vw"}}>{item.drug_name && (item.drug_name).length > 30 ? (item.drug_name).slice(0,28) + ".." : item.drug_name}</Text>
                            </Tooltip>
                            </View>
                    </View>
                    </View>
                    )})}
                </View>
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}  style={{maxWidth: "65vw", flex: 1}}>
                <View style={{width: "120vw"}}>
                <CommonReportSectionHeader 
                heading1={"Generic Name"}
                heading2={"Dosage Type"}
                heading3={"Strength"}
                heading4={"Schedule"}
                heading5={"Batch No"}
                heading6={"Expiry Date"}
                heading7={"Avail Qty"}
                heading8={"Free Qty"}
                heading9={"MRP/Qty"}
                heading10={"Buying Price"}
                heading11={"Hsn code"}
                heading12={"Reorder Threshold"}
                heading13={"Expiry Alert"}
                heading14={"Box No"}
                heading15={"Grouping"}
                columnSize={[0.18, 0.15, 0.13, 0.13, 0.13, 0.13, 0.13, 0.10, 0.10, 0.13, 0.13, 0.19, 0.15, 0.12, 0.12]}
                noOfColumn={15} 
                fontSize={"1vw"}
                />
                {this.state.stockDataListAfterPagination && this.state.stockDataListAfterPagination.length > 0 && this.state.stockDataListAfterPagination.map((item, index) => {
                return(
                <View style={{ width:"100%", justifyContent: 'center', maxHeight: "3.75vw", minHeight: "3.75vw" }}>
                    <View style={{flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, maxHeight: "3.75vw", paddingVertical: "1.25vw"}}>
                        <View style={{flex: 0.18, marginLeft: '1.3vw'}}>
                            <Tooltip title={item.generic_name}>
                            <Text style={{ maxHeight: "1.25vw" ,fontSize: "1vw"}}>{item.generic_name && (item.generic_name).length > 10 ? ((item.generic_name).slice(0,10) + "..." ): item.generic_name}</Text>
                            </Tooltip>
                        </View>
                        <View style={{flex: 0.15}}><Text style={{fontSize: "1vw"}}>{item.drug_type}</Text></View>
                        <View style={{flex: 0.13}}><Text style={{fontSize: "1vw"}}>{item.drug_strength}</Text></View>
                        <View style={{flex: 0.13}}><Text style={{fontSize: "1vw"}}>{item.schedules}</Text></View>
                        <View style={{flex: 0.13}}><Text style={{fontSize: "1vw"}}>{item.batch_no}</Text></View>
                        <View style={{flex: 0.13}}><Text style={{fontSize: "1vw"}}>{item.expiry_date ? moment(item.expiry_date).format("MM-YYYY") : ""}</Text></View>
                        <View style={{flex: 0.13}}><Text style={{fontSize: "1vw"}}>{item.stock_quantity}</Text></View>
                        <View style={{flex: 0.10}}><Text style={{fontSize: "1vw"}}>{item.free_quantity}</Text></View>
                        <View style={{flex: 0.10}}><Text style={{fontSize: "1vw"}}>{item.mrp_per_quantity}</Text></View>
                        <View style={{flex: 0.13}}><Text style={{fontSize: "1vw"}}>{item.buying_price}</Text></View>
                        <View style={{flex: 0.13}}><Text style={{fontSize: "1vw"}}>{item.hsn_code}</Text></View>
                        <View style={{flex: 0.19}}><Text style={{fontSize: "1vw"}}>{item.reorder_threshold}</Text></View>
                        <View style={{flex: 0.15}}><Text style={{fontSize: "1vw"}}>{item.expiry_alert_period}</Text></View>
                        <View style={{flex: 0.12}}><Text style={{fontSize: "1vw"}}>{item.physical_box_no}</Text></View>
                        <View style={{flex: 0.12}}>
                        <Tooltip title={item.grouping}><Text style={{fontSize: "1vw"}}>{item.grouping && item.grouping.length > 10 ?((item.grouping).slice(0,8) + "...") : item.grouping }</Text></Tooltip>
                        </View>
                    </View>
                </View>
                )})}
                {this.state.stockDataListAfterPagination.length === 0 ? 
                <View style={{alignItems: "center", width: "70%", marginTop: "15%" }}>
                    <Text style={{fontSize: "1vw" }}>No Records Found</Text>
                </View>
                : null }
                </View>
                </ScrollView>
                <View style={{width: "15vw"}}>
                <View>    
                <CommonReportSectionHeader 
                heading1={"Action"}
                columnSize={[1.5]}
                noOfColumn={1}  
                fontSize={"1vw"}   
                />
                </View>
                {this.state.stockDataListAfterPagination && this.state.stockDataListAfterPagination.length > 0 && this.state.stockDataListAfterPagination.map((item, index) => {
                return(
                <View style={{ justifyContent: 'center', maxHeight: "3.75vw", minHeight: "3.75vw" }}>
                    <View style={{flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: "1.25vw", flex: 1}}>
                        <TouchableOpacity onPress={() => {
                            var {beforeStockEdit} = this.state
                            beforeStockEdit['schedules'] = item.schedules
                            beforeStockEdit['batch_no'] = item.batch_no
                            beforeStockEdit['drug_name'] = item.drug_name
                            beforeStockEdit['drug_strength'] = item.drug_strength
                            beforeStockEdit['generic_name'] = item.generic_name
                            beforeStockEdit['dosage_type'] = item.dosage_type
                            beforeStockEdit['grouping'] = item.grouping
                            beforeStockEdit['hsn_code'] = item.hsn_code
                            beforeStockEdit['expiry_date'] = item.expiry_date
                            beforeStockEdit['reorder_threshold'] = item.reorder_threshold
                            beforeStockEdit['free_quantity'] = item.free_quantity
                            beforeStockEdit['mrp_per_quantity'] = item.mrp_per_quantity
                            beforeStockEdit['expiry_alert_period'] = item.expiry_alert_period
                            beforeStockEdit['physical_box_no'] = item.physical_box_no
                            this.setState({
                                beforeStockEdit,
                                editStockDetail: item,
                                isEditStock: true,
                                editStockQty: item.stock_quantity
                            })
                        }} style={{marginLeft: "1.25vw" }}>
                            <Icon name='pencil' size={"1.30vw"} color={color.themeDark}></Icon>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            this.setState({
                                isHistroryClick: true,
                                selectedHistoryDrug: item.drug_name,
                                selectedHistoryID: item.drug_id,
                                selectedBatchNO: item.stock_id
                            })
                            this.getStockUpdateHistory(item.drug_id, item.stock_id)
                        }} style={{marginLeft: "1.25vw" }}>
                            <Icon name='eye' size={"1.30vw"} color={color.themeDark}></Icon>
                        </TouchableOpacity>
                        {item.stock_quantity == 0 ?<TouchableOpacity onPress={() => {this.setState({StockId : item.stock_id, isDeleteClick : true, selectedHistoryDrug: item.drug_name})}} style={{marginLeft: "1.25vw" }}>
                            <Icon name='trash' size={"1.30vw"} color={color.red}></Icon>
                        </TouchableOpacity>:null}
                    </View>
                </View>
                )})}
                </View>
            </View>
            </ScrollView>
            {this.renderPaginationView()}
            {this.state.isEditStock ? 
                <View style={{height: "100%", zIndex:1, position:'absolute', width:'100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center"}}>
                    <View style={{ paddingHorizontal: "1.9vw", paddingVertical: "1.25vw", backgroundColor: color.white,  width: "90%", height: "85%", borderRadius: "0.5vw",  borderWidth: 1, }}> 
                        <View style={{flexDirection: "row", maxHeight: "10%", minHeight: "10%", width: "100%", justifyContent: "space-between"}}>
                        <Text style={{fontSize: "1.2vw", fontWeight: "500", color: color.themeDark,}}>Stock Correction</Text>
                        <TouchableOpacity style={{}} onPress={() => {
                            this.setState({
                                editStockDetail: {},
                                beforeStockEdit:{},
                                isEditStock: false,
                                editStockQty: 0,
                                update_comment: "",
                                selectedReason: "",
                                forceUpdate: false,
                                helperText: false
                            })
                        }}>
                        <Image style={{height: "1.8vw", width: "1.8vw"}}
                        source={require('../../../../assets/images/HomeScreenImages/Cancel.svg')} />
                        </TouchableOpacity>
                        </View>
                        <ScrollView  showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{maxHeight: "20vh", minHeight: "20vh", width: "100%"}}>
                        <View style={{ width: "100%",flexDirection:"row", flexWrap: "wrap", justifyContent: "flex-start",marginVertical:"0.3vw"}}>
                            {this.renderTextBox("Brand Name *", "drug_name", editStockDetail.drug_name)}
                            {this.renderTextBox("Generic Name *","generic_name", editStockDetail.generic_name)}
                            {this.renderTextBox("Dosage Strength *","drug_strength",editStockDetail.drug_strength)}
                            {this.renderTextBox("Dosage Type *", "dosage_type",editStockDetail.dosage_type)}
                            {this.renderTextBox("Batch Number *", "batch_no", editStockDetail.batch_no)}
                            {this.renderTextBox("Schedule", "schedules", editStockDetail.schedules)}
                            {this.renderTextBox("Grouping","grouping",editStockDetail.grouping)}
                            {this.renderTextBox("Reorder threshold", "reorder_threshold", editStockDetail.reorder_threshold)}
                            {this.renderTextBox("Physical Box No","physical_box_no", editStockDetail.physical_box_no)}
                            {this.renderTextBox("Expiry Alert Period", "expiry_alert_period",editStockDetail.expiry_alert_period)}
                            

                            <View style={{flexDirection: "row", marginLeft: "1.9vw",marginVertical:"0.3vw"}}>
                                <TouchableOpacity style={{width: "2.5vw", height: "2.5vw", borderWidth: 1, borderRadius: "0.25vw", borderColor: color.lightGray, justifyContent: "center", alignItems: "center"}}
                                onPress={() => {
                                    var {editStockQty} = this.state
                                    if(parseInt(editStockQty) > 0){
                                        this.setState({
                                            editStockQty: parseInt(editStockQty) - 1
                                        })
                                    }
                                }}
                                >
                                    <Icon name="minus" size={"1.30vw"} color={color.themeDark} />
                                </TouchableOpacity>
                                <View style={{}}>
                                    <CommonTextBox width={"11.5vw"} placeholder={"Qty"} type={""} stateKey={"editStockQty"} value={this.state.editStockQty} onChangeText={this.updateQty.bind(this)}  />
                                </View>
                                <TouchableOpacity style={{width: "2.5vw", height: "2.5vw", borderWidth: 1, borderRadius: 4, borderColor: color.lightGray, justifyContent: "center", alignItems: "center"}}
                                onPress={() => {
                                    var {editStockQty} = this.state
                                    this.setState({
                                        editStockQty: parseInt(editStockQty) + 1
                                    })
                                }}
                                >
                                    <Icon name="plus" size={"1.30vw"} color={color.themeDark} />
                                </TouchableOpacity>
                            </View>
                            {this.renderTextBox("Expiry Date", "expiry_date", editStockDetail.expiry_date)}
                        </View>
                        </ScrollView>
                        <View style={{maxHeight: "30%", minHeight: "30%", width: "100%"}}>
                            <Text style={{fontWeight: "bold", fontSize: "0.9vw"}}>Enter Reason</Text>
                            <View style={{flexDirection: "row"}}>
                            {this.renderRadioBtn("Breakage", "1")}
                            {this.renderRadioBtn("Loss", "2")}
                            {this.renderRadioBtn("Expired", "3")}
                            {this.renderRadioBtn("Miscellaneous", "4")}
                            {this.renderRadioBtn("Others", "5")}
                            </View>
                            <View style={{ flexDirection: "row", justifyContent: "flex-start", marginTop: "0.63vw", }}>
                                <MultiLineCommonTextBox width={"50%"} disable={false} placeholder={"Comment"} type={""} align={""}
                                    stateKey={"update_comment"} value={this.state.update_comment} onChangeText={this.updateComment} numberOfLines={3} />
                            </View>
                        </View>

                        {
                        this.state.forceUpdate && this.state.helperText ?  
                        <View style={{maxHeight: "10%", width: "100%", justifyContent: "center", alignItems: "center", marginTop: "5%"}}>
                            <Text style={{ fontSize: "1vw", color:color.red, fontWeight: 500}}>The Drug already exists are you willing to update non mandatory fields</Text>
                        </View>
                        : null
                        }
                
                        <View style={{maxHeight: "15%", width: "100%", justifyContent: "center", flexDirection: "row", marginTop: "5%"}}>
                            <ActionButton width={"10%"} isDisable = {!isUpdateValid } label={"Update"} keyIndex={"stock_update"} onPressAction={this.updateStock} />
                            <ActionButton width={"10%"} label={"Reset"} keyIndex={"stock_reset"} onPressAction={this.reset} />
                        </View>
                    </View>

                </View>
                : null }
                {this.state.isHistroryClick ? 
                <View style={{height: "100%", zIndex:1, position:'absolute', width:'100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center"}}>
                    <View style={{ paddingHorizontal: " 1.9vw", paddingVertical: "1.25vw", backgroundColor: color.white,  width: "90%", height: "85%", borderRadius: "0.5vw",  borderWidth: 1, }}>
                        <View style={{flexDirection: "row", maxHeight: "10%", minHeight: "10%", width: "100%", justifyContent: "space-between"}}>
                              <View style={{ flexDirection: "row" }}>
                                <Text style={{ fontSize: "1.2vw", fontWeight: "500", color: color.themeDark, }}>History - {this.state.selectedHistoryDrug}</Text>
                                <TouchableOpacity
                                 onPress={() => { this.printStockHistory() }}
                                 style={{ marginHorizontal: "0.9vw", width: "2.5vw", height: "2.18vw", backgroundColor: color.themeDark, paddingHorizontal: "0.3vw", paddingVertical: "0.15vw", borderRadius: "0.25vw" }}>
                                 <Image style={{ width: "1.9vw", height: "1.9vw" }}
                                   source={require('../../../../assets/images/HomeScreenImages/Print.png')} />
                                </TouchableOpacity>
                              </View>  
                                <TouchableOpacity style={{}} onPress={() => {
                            this.setState({
                                isHistroryClick: false,
                                selectedHistoryDrug: "",
                                selectedHistoryID: null
                            })
                        }}>
                        <Image style={{height: "1.9vw", width: "1.9vw"}}
                        source={require('../../../../assets/images/HomeScreenImages/Cancel.svg')} />
                        </TouchableOpacity>
                        </View>
                        <ScrollView horizontal={true} showsHorizontalScrollIndicator={historyStockDetail && historyStockDetail.length > 0 ? true : false} style={{maxHeight: "25vw", width: "70vw", marginHorizontal:"5%"}} >
                           <View style={{ maxHeight: "90%", width: "120vw"}}>
                            <CommonReportSectionHeader 
                            heading1={"Updated Date"}
                            heading2={"Updated By"}
                            heading3={"Brand Name"}
                            heading4={"Generic Name"}
                            heading5={"Dosage Type"}
                            heading6={"Schedule"}
                            heading7={"Batch no"}
                            heading8={"Expiry Date"}
                            heading9={"Prev Qty"}
                            heading10={"Adj Qty"}
                            heading11={"Avail Qty"}
                            heading12={"Free Qty"}
                            heading13={"MRP/Qty"}
                            heading14={"Hsn code"}
                            heading15={"Reorder Threshold"}
                            heading16={"Expiry Alert"}
                            heading17={"Box No"}
                            heading18={"Grouping"}
                            heading19={"Reason"}
                            columnSize={[0.35, 0.45, 0.30, 0.35, 0.30, 0.25, 0.25, 0.30, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.40, 0.30, 0.20, 0.25, 0.30 ]}
                            noOfColumn={19}  
                            fontSize={"1vw"}        
                            />  
                            {historyStockDetail && historyStockDetail.length > 0 ? historyStockDetail.map((item, index) => {
                            return(
                            <View style={{ justifyContent: 'center', maxHeight: "3.75vw", minHeight: "3.75vw", width: "100%" }}>
                                <View style={{flexDirection: 'row', justifyContent: 'center', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, maxHeight: 60}} >
                                    <View style={{flex: 0.35, marginLeft: "1.25vw"}}><Text style={{fontSize: "1vw" }}>{item.modified_date}</Text></View>
                                    <View style={{flex: 0.45}}>
                                        <Tooltip title={item.modified_by}>
                                        <Text style={{fontSize: "1vw"}}>{item.modified_by && (item.modified_by).length > 30 ? ((item.modified_by).slice(0,28) + ".." ): item.modified_by}</Text>
                                        </Tooltip>
                                    </View>
                                    <View style={{flex: 0.30}}>
                                        <Tooltip title={item.drug_name}>
                                        <Text style={{fontSize: "1vw"}}>{item.drug_name && (item.drug_name).length > 10 ? (item.drug_name).slice(0,8) + ".." : item.drug_name}</Text>
                                    </Tooltip>
                                    </View>
                                    <View style={{flex: 0.35}}><Text style={{fontSize: "1vw"}}>{item.generic_name}</Text></View>
                                    <View style={{flex: 0.30}}>
                                    <Tooltip title={item.dosage_type}>
                                        <Text style={{fontSize: "1vw"}}>{item.dosage_type && (item.dosage_type).length > 10 ? (item.dosage_type).slice(0,8) + ".." : item.dosage_type}</Text>
                                    </Tooltip>
                                        </View>
                                    <View style={{flex: 0.25}}><Text style={{fontSize: "1vw"}}>{item.schedule}</Text></View>
                                    <View style={{flex: 0.25}}><Text style={{fontSize: "1vw"}}>{item.batch_no}</Text></View>
                                    <View style={{flex: 0.30}}><Text style={{fontSize: "1vw"}}>{item.expiry_date ? moment(item.expiry_date).format("MM-YYYY") : ""}</Text></View>
                                    <View style={{flex: 0.25}}><Text style={{fontSize: "1vw"}}>{item.from_quantity}</Text></View>
                                    <View style={{flex: 0.25}}><Text style={{fontSize: "1vw"}}>{item.adjusted_quantity}</Text></View>
                                    <View style={{flex: 0.25}}><Text style={{fontSize: "1vw"}}>{item.to_quantity}</Text></View>
                                    <View style={{flex: 0.25}}><Text style={{fontSize: "1vw"}}>{item.free_quantity}</Text></View>
                                    <View style={{flex: 0.25}}><Text style={{fontSize: "1vw"}}>{item.mrp_per_quantity}</Text></View>
                                    <View style={{flex: 0.25}}><Text style={{fontSize: "1vw"}}>{item.hsn_code}</Text></View>
                                    <View style={{flex: 0.40}}><Text style={{fontSize: "1vw"}}>{item.reorder_threshold}</Text></View>
                                    <View style={{flex: 0.30}}><Text style={{fontSize: "1vw"}}>{item.expiry_alert_period}</Text></View>
                                    <View style={{flex: 0.20}}><Text style={{fontSize: "1vw"}}>{item.physical_box_no}</Text></View>
                                    <View style={{flex: 0.25}}><Text style={{fontSize: "1vw"}}>{item.group}</Text></View>
                                    <View style={{flex: 0.30}}>
                                        <Tooltip title={item.comments}>
                                        <Text style={{ maxHeight: "1.25vw",fontSize: "1vw"}}>{item.comments && (item.comments).length > 30 ? ((item.comments).slice(0,28) + ".." ): item.comments}</Text>
                                        </Tooltip>
                                    </View>
                                </View>
                            </View>
                            )
                            }) 
                            :
                            <View style={{ marginHorizontal:"20%",marginVertical:"10%", padding: "0.5vw",  height: "65vh"}}>
                                    <Text style={{fontSize: "1vw" }}>No Records Found</Text>

                                </View>
                        }     
                        </View>
                        </ScrollView>
                    </View>
                </View>
                : null }
                {this.state.isDeleteClick ? 
                <View style={{height: "100%", zIndex:1, position:'absolute', width:'100%', backgroundColor: "rgba(0, 0, 0, 0.5)", justifyContent: "center", alignItems: "center"}}>
                    <View style={{ paddingHorizontal: "1.9vw", paddingVertical: "1.25vw", backgroundColor: color.white,  width: "30%", height: "20%", borderRadius: "0.5vw" }}>
                        <View style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <Text style={{fontSize:"1vw", fontWeight: 500}}>Are you sure You Want To Delete?</Text>
                            <Text style={{color:"red", fontSize:"1vw",fontWeight: 500}}>{this.state.selectedHistoryDrug}</Text>
                        <View style={{maxHeight: "15%", width: "100%", justifyContent: "center", flexDirection: "row", marginTop: ".8vw"}}>
                            <TouchableOpacity style={{width:"2.5vw", backgroundColor:color.themeDark, height:"2vw", marginRight:".85vw", alignItems:"center", justifyContent:"center", display:"flex", borderRadius:".2vw"}} onPress={() => {this.deleteStock(this.state.StockId)}}>
                                <Text style={{fontSize:".9vw", color:color.white}}>Yes</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{width:"2.5vw", backgroundColor:color.themeDark, height:"2vw", alignItems:"center", justifyContent:"center", display:"flex", borderRadius:".2vw"}} onPress={() => {this.setState({isDeleteClick : false})}}>
                                <Text style={{fontSize:".9vw", color:color.white}}>No</Text>
                            </TouchableOpacity>
                        </View>
                        </View>
                    </View>
                </View>
                : null}
                {
                    this.state.showSuccessResponse ?
                        <SuccessAlert message={this.state.responseMessage} /> : null
                }
                {
                    this.state.showErrorResponse ?
                        <ErrorAlert message={this.state.responseMessage} /> : null
                }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    floatingLabelText: {
        position: 'absolute', 
        marginLeft: '0.65vw', 
        backgroundColor: "white", 
        fontSize: '0.78vw', 
        paddingHorizontal: '0.32vw', 
        color: "#888888"
    },
    pickerView: {
        backgroundColor: color.white,
        borderRadius: '0.65vw', 
        width:"16vw",
        height: '2.27vw',  
        fontSize: '0.78vw',
        paddingLeft: '0.65vw',
        marginRight: '0.65vw'
    }
})