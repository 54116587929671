import React, { Component } from 'react';
import { View, Text, TextInput, Image, TouchableOpacity } from 'react-native';
import Service from '../networks/service';
import theme from '../styles/theme';
import Constant from '../networks/Constants'
import Icon from "react-native-vector-icons/EvilIcons";
import SelectIcon from "react-native-vector-icons/Ionicons";
import Exclamation from "react-native-vector-icons/AntDesign";
import AsyncStorage from "../AsyncStorage";
import EyeIcon from "react-native-vector-icons/MaterialCommunityIcons";

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            userName: "",
            rememberMe: true,
            errorMessageForUserName: "",
            errorMessageForPassword: "",
            showPassword: false
        };
        this.service = new Service()
    }


    _doLogin() {
        var userNameIsEmpty = "Please Enter The User Name..."
        var passwordIsEmpty = "Please Enter The Password..."
        if (this.state.userName !== "" && this.state.password !== "") {
            var data = {
                "username": this.state.userName,
                "password": this.state.password
            }
            var url = Constant.LOG_IN;
            this.service.postApiCall(
                url,
                data,
                this.successCallBack,
                this.errorCallBack,
                "login"
            )
        } else if (this.state.userName == "") {
            if (this.state.password == "") {
                this.setState({
                    errorMessageForUserName: userNameIsEmpty,
                    errorMessageForPassword: passwordIsEmpty
                })
            } else {
                this.setState({
                    errorMessageForUserName: userNameIsEmpty,
                })
            }
        } else if (this.state.password == "") {
            this.setState({
                errorMessageForPassword: passwordIsEmpty,
            })
        }
    }
    successCallBack = async (response) => {
        if (response.status == "success") {
            await AsyncStorage.setItem("loggedIn", "true");
            await AsyncStorage.setItem("token", response.data.token);
            await AsyncStorage.setItem("service_type", response.data.service_type);
            await AsyncStorage.setItem("pharmacy_type", response.data.pharmacy_type);
            await AsyncStorage.setItem("user_name", response.data.employee_name);
            await AsyncStorage.setItem("is_emed_pharma", response.data.is_emed_pharma);
            await AsyncStorage.setItem("gstin_number", response.data.gstn_number ? response.data.gstn_number : "");
            await AsyncStorage.setItem("login_result", JSON.stringify(response))
            var loggedUserData = ""
            loggedUserData = JSON.stringify(response.data)
            await AsyncStorage.setItem("loggedInUserData",loggedUserData)          
            this.props.navigation.navigate("dashBoard")
        } else if (response.status == "fail") {
            this.setState({
                errorMessageForPassword: response.message
            })
        }
        var superAdmindata = response.data
        if (superAdmindata && superAdmindata.is_password_reset){
            await AsyncStorage.setItem("loggedIn", "true");
            await AsyncStorage.setItem("user_name",  this.state.userName + "");
    }
      
    }

    errorCallBack = (error) => {
    }

    onChangeValues(key, value) {
        var states = this.state;
        if (key == "userName") {
            states["userName"] = value;
            states["errorMessageForUserName"] = "";
            states["errorMessageForPassword"] = "";
        }
        else if (key == "password") {
            states["password"] = value
            states["errorMessageForPassword"] = "";
            states["errorMessageForUserName"] = "";
        }
        this.setState({ states })
    }

    renderTextBox(placeholder, key, value, lefticon, rightIcon = "") {
        return (
            <View style={{ width: "32vw" }}>
                <View style={{ flexDirection: 'row' }}>
                    <View style={theme.inputPutFieldIcons}>
                        <Icon name={lefticon} size={'3vw'} style={theme.rememberMe_User_Password_Icon} />
                    </View>
                    <TextInput
                        secureTextEntry={key == "password" && !this.state.showPassword ? true : false}
                        style={[rightIcon ? theme.loginPageTextBoxRightIcon : theme.loginPageTextBox, { flex: rightIcon ? 0.8 : 0.9, fontSize: '1vw' }]}
                        placeholder={placeholder}
                        value={value}
                        onChangeText={(text) => {
                            this.onChangeValues(key, text);
                        }}
                        onSubmitEditing={() => { this._doLogin() }}
                    />
                    {
                        rightIcon ?
                            <View style={[theme.inputPutFieldRightIcons, {flex: 0.10, justifyContent: 'center' }]}>
                                <TouchableOpacity onPress={() => { this.setState({ showPassword: !this.state.showPassword }) }}>
                                    <EyeIcon name={this.state.showPassword ? "eye-outline" : "eye-off-outline"} size={'2vw'} style={theme.rememberMe_User_Password_Icon} />
                                </TouchableOpacity>
                            </View> : null
                    }
                </View>
            </View>
        );
    }

    showValidationMessage(data) {
        return (
            <View>
                {
                    data !== "" ?
                        <View style={theme.errorMessageView}>
                            <Exclamation name={"exclamationcircleo"} size={16} style={theme.exclamationIcon} />
                            <Text style={theme.errorMessageText}>{data}</Text>
                        </View>
                        : null
                }
            </View>
        )
    }

    render() {
        var loginContent = "Lorem ipsum dolor sit amet, ex cibo molestie vis. Vix ut nominavi erroribus. Solet voluptatibus et pro. No duo legimus lucilius insolens, vix an elit inani mentitum, ad albucius probatus sea."
        return (
            <View style={{ backgroundColor: 'white' }}>
                <Text style={theme.LoginPageProductName}>{"eMedHub"}</Text>
                <View style={theme.loginPage}>

                    <View style={theme.loginPageLeftView}>
                        <Image style={theme.loginPageBackgroundImage} source={require('../../assets/loginPageImage.svg')} />
                    </View>
                    <View style={theme.loginPageRightView}>
                        <View><Text style={theme.loginHeader}>{"Welcome to eMedHub"}</Text></View>
                        <View style={theme.LoginPageTextBoxContainer}>
                            {this.renderTextBox("Username", "userName", this.state.userName, "user")}
                            {this.showValidationMessage(this.state.errorMessageForUserName)}
                        </View>
                        <View style={theme.LoginPageTextBoxContainer}>
                            {this.renderTextBox("Password", "password", this.state.password, "lock", "eye-off-outline")}
                            {this.showValidationMessage(this.state.errorMessageForPassword)}
                        </View>
                        <View style={theme.rememberAndForgotPasswordView}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <TouchableOpacity onPress={() => this.setState({ rememberMe: !this.state.rememberMe })}>
                                    <SelectIcon size={'2vw'} style={theme.rememberMe_User_Password_Icon} name={this.state.rememberMe ? "ios-checkmark-circle-outline" : "ios-radio-button-off"} />
                                </TouchableOpacity>
                                <Text style={theme.rememberMeText}>{"Remember Me"}</Text>
                            </View>
                            <View>
                                <TouchableOpacity>
                                    <Text style={theme.forgotPasswordText}>{"Forgot Password?"}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={theme.signinView}>
                            <TouchableOpacity style={theme.singinTouchableView} onPress={() => this._doLogin()}>
                                <Text style={theme.signinText}>{"Sign in"}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}
