export const color = {
    
    black: "#1a1917",
    themeBlue: "#262941",
    themePink: "#F25364",
    white: "#ffffff",
    red: "#FF0000",
    themeOpthamology: "#262941",
    lightGray: "#ccccb3",
    
    unselectedSectionTitle: "#D9D9D9",
    drNotesUnSelectedTitle: "#1a1917",
    notesTableTitle: "#848484",
    notesTableData: "#2F2F2F",
    sectionItemLine: "#E0E0E0",

    appointmentTableHead: "#EEEEEE",
    appointmentTableHeadText: "#4F5A69",


    darkBlack:"#292929",
    textBoxBorderColor: "#CDD1D5",

    // side menu Unselected Color
    themeUnselectIcon: "#B3D9FE",
    rightPanelInputBorder: "#B3D9FE",

    // blue theme
    //themeDark------ 2F92F5
    themeDark: "#04b7b1",
    themeBackgroud: "#04b7b1",
    // themeShade: "#F3F8FD",
    // themeShade: "#edf4f3", // DR Right side view back ground
    themeShade: "#F8FCFF", // DR Right side view back ground
    themeShadeBackground: "#F3F8FD",        // DR notes  right side button background

    rightPanelLabel: "#04b7b1",

    //search text
    labelColor: "#888888",
    textcolor:"#363636",
    subtextcolor:"#898989",
    placeholder:"#F8F8F8",
    boxColor:"#898989",
    themeBackgroudMessage:"B3D9FE",
    searchBarBackground:"#E8ECF1",
    rightPanelLabel: "#04b7b1",

    disableComponentColor:'#EAEAEA',

    // Report card color
    cardBlue:"#0482BE",
    cardPurple:"#9D358C",
    cardOrange:"#E54900",
    cardGreen:"#00B257",

    // Bill
    tableHeaderBG: "#E2E2E2",
    gray :"#C8C8C8",
    magenda:"#960D0B",
    maroon:"#F5E7E7"
};