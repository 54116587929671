import React, { Component } from "react";
import { View, Text, TextInput, Dimensions, StyleSheet } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { color } from "../../styles/Color";
import { SuccessAlert, ErrorAlert } from '../../common/BaseFunctionComponent'
import Style from "../../styles/Style";
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import ValCon from '../../networks/ValidationConstants';
import { Formik } from 'formik';
import * as yup from 'yup';
import { TouchableOpacity } from "react-native";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const screenHeight = Dimensions.get("window").height;

const vendorValidationSchema = yup.object().shape({
    name: yup.string().required(ValCon.MSG_VENDOR_NAME).nullable(),
    gstin_number: yup.string().required(ValCon.MSG_GSTIN_NO).matches(ValCon.GSTIN_NO, ValCon.MSG_FOR_SPECIAL_CHAR).nullable(),
    area_name: yup.string().required(ValCon.MSG_AREA_NAME).matches(ValCon.ALPHA_NUMERIC, ValCon.MSG_FOR_SPECIAL_CHAR).nullable(),
    dl_no: yup.string().required(ValCon.MSG_DL_NO).matches(ValCon.RG_DL_NO, ValCon.MSG_INVALID_DL_NO).nullable(),
    pincode: yup.string(),
    mobile_number: yup.string().max(10).required(ValCon.MSG_MOBILE_NUMBER).nullable(),
    address_line_1: yup.string().required(ValCon.MSG_ADDRESS).matches(ValCon.ADDRESS, ValCon.MSG_FOR_SPECIAL_CHAR).nullable(),
    address_line_2: yup.string().matches(ValCon.ADDRESS, ValCon.MSG_FOR_SPECIAL_CHAR).nullable(),
    city : yup.string().required(ValCon.MSG_CITY).matches(ValCon.ALPHA_NUMERIC, ValCon.MSG_FOR_SPECIAL_CHAR).nullable(),
    pincode: yup.string().required(ValCon.MSG_PINCODE).nullable()
})

export default class VendorDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedScreen: this.props.selectedScreen,
            vendor_id: this.props.edit_id ? this.props.edit_id : null,
            login_user_is_admin: this.props.login_user_is_admin ? this.props.login_user_is_admin : false,
            data: {
                "name": "",
                "gstin_number": "",
                "dl_no": "",
                "dl_no_2": "",
                "dl_no_3": "",
                "dl_no_4": "",
                "dl_no_5": "",
                "dl_no_6": "",
                "address_line_1": "",
                "address_line_2": "",
                "area_name": "",
                "pincode": "",
                "city": "",
                "country": "India",
                "state": "Tamil Nadu",
                "mobile_number": "",
                "phone_number": "",
                "email": ""
            },
            name: "",
            isSaveDisable: false,
            showResponse: false,
            showErrorResponse: false,
            responseMessage: "",
        }
        this.service = new Service();
        this.successCallBack = this.successCallBack.bind(this)
    }

    componentDidMount() {

        if (this.state.vendor_id) {

            this._doLoadVendor()
        }
    }
    //get api of vendor details
    _doLoadVendor = () => {

        let { vendor_id } = this.state;
        let url = null;

        if (vendor_id) {
            url = Constants.GET_VENDOR + "?vendor_id=" + vendor_id;

            this.service.getApiCall(
                url,
                this.successOnGet,
                this.errorOngetCallBack
            )
        }
    }

    successOnGet = async (response) => {

        if (response.status === "success") {

            this.setState({
                "data": response.data
            })

        } else if (response.status === "fail") {

            this.setState({
                responseMessage: response.message
            }, () => {

            })
        }
    }

    errorOngetCallBack = (error) => { }

    //put and post api 
    _doSaveVendor = (data) => {



        let { vendor_id } = this.state;
        let url = Constants.SAVE_VENDOR;

        // add save function      

        let flag = true

        if (vendor_id && flag) {

            data['vendor_id'] = parseInt(vendor_id);
            this.setState({ isSaveDisable: true })
            this.service.putApiCall(
                url,
                data,
                this.successCallBack,
                this.errorCallBack
            )

        } else if (flag) {
            this.setState({ isSaveDisable: true })
            this.service.postApiCall(
                url,
                data,
                this.successCallBack,
                this.errorCallBack
            )
        }
    }

    successCallBack = async (response) => {
        if (response.status == "success") {
            this.setState({ isSaveDisable: true })
            this.responseSuccessErrorMessage(response.message, true)
            setTimeout(() => {
                this.responseSuccessErrorMessage("", true)
                this.props.NavigateScreen("vendors")
            }, 3000)


        } else if (response.status == "fail") {
            this.setState({ isSaveDisable: false })
            this.responseSuccessErrorMessage(response.message, false)

            setTimeout(() => {
                this.responseSuccessErrorMessage("", false)
            }, 3000)

        }
    }

    errorCallBack = (error) => {
        if (error) {
            this.setState({ isSaveDisable: false })
        }
    }

    selectRegion = (val) => {
        var states = this.state.data
        states["state"] = val
        this.setState({ states })
    }
    selectCountry = (val) => {
        var states = this.state.data
        states["country"] = val
        this.setState({
            states
        })
    }

    renderTextBox(placeholder, width, center, type, stateKey, value = '', error = false, disable = false, handleChange, errors) {

        return (
            <View style={{
                width: width,
                justifyContent: 'center',
                marginRight: '1vw',
                marginTop: '1vw'
            }}>
                <Text style={[styles.TextBoxPlaceholder, { backgroundColor: type == "IDP" ? color.themeShade : "white", }]}>{placeholder}</Text>
                {
                    disable ?
                        <View>
                            <Text style={[Style.commonTextBoxStyles, { zIndex: -3, marginTop: '.7vw', color: color.labelColor, paddingTop: '.4vw', fontSize: '1vw', backgroundColor : disable ? color.themeShade : "white"}]} >
                                {value}
                            </Text>
                            <View style={{ height: '1vw' }}></View>
                        </View>

                        :
                        <View style={{ flexDirection: "column", justifyContent: "flex-start" }}>
                            {stateKey == "state" ? 
                                <RegionDropdown
                                    style={{ height: "2.3vw", fontSize: "1vw", marginTop: "0.5vw", paddingLeft: "1vw", borderColor: '#CDD1D5', borderRadius: "0.3vw" }}
                                    country={"India"}
                                    value={value}
                                    onChange={(val) => this.selectRegion(val)} />
                                :
                                stateKey === "country" ?
                                    <CountryDropdown
                                        style={{ height: "2.3vw", fontSize: "1vw", marginTop: "0.5vw", paddingLeft: "0.5vw", borderColor: '#CDD1D5', borderRadius: "0.3vw" }}
                                        value={value}
                                        onChange={(val) => this.selectCountry(val)}
                                        whitelist={["IN"]}
                                    />
                                    :

                                    <TextInput
                                        style={[styles.TextBoxTextInput, { borderColor: error ? "red" : "#CDD1D5" }]}
                                        keyboardType={stateKey == "mobile_number"|| stateKey === "pincode" || stateKey === "phone_number" ? "numeric" : null}
                                        value={value ? value : ''}
                                        onChangeText={handleChange(stateKey)}


                                    />}
                            <View style={{ height: '1vw' }}>
                                {errors[stateKey] &&
                                    <Text style={styles.TextBoxerror}>{errors[stateKey]}</Text>
                                }
                            </View>
                        </View>
                }
            </View>
        )
    }

    render() {

        let { data, selectedScreen, showResponse, showErrorResponse, responseMessage,login_user_is_admin } = this.state;
        let isDisable = (login_user_is_admin && selectedScreen === "update_vendor") ||  selectedScreen === "add_vendor" ? false : true

        return (
            <View style={{ maxHeight: screenHeight - 65, margin: 15 }}>

                <Formik
                    enableReinitialize
                    validationSchema={vendorValidationSchema}
                    initialValues={data}
                    onSubmit={values => {
                        this._doSaveVendor(values)
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, values, errors, isValid }) => (
                        <View>

                            <View style={{ flexDirection: "row", marginHorizontal: 10, height:"2vw", padding: "3px" }}>
                                <TouchableOpacity
                                    style={[styles.ButtonStyle, { marginRight: "0.8vw" }]}
                                    onPress={() => this.props.NavigateScreen("vendors")}
                                >
                                    <Text style={styles.ButtonText}>Back</Text>
                                </TouchableOpacity>

                                <Text style={{ color: color.themeDark, padding: "0.4vw", fontSize: "1.2vw", fontWeight: "500" }}>{ this.state.selectedScreen === "update_vendor" ? "Update Vendor" : "Add Vendor"}</Text>
                                <TouchableOpacity
                                    style={[styles.ButtonStyle, { marginLeft: "68vw" }]}
                                    disabled={this.state.isSaveDisable}
                                    onPress={handleSubmit}
                                >
                                    <Text style={styles.ButtonText}>Save</Text>
                                </TouchableOpacity>
                            </View>



                            <View style={styles.TextBoxItemView}>

                                {this.renderTextBox("Vendor Name", "26vw", false, "MI", "name", values.name, "", isDisable, handleChange, errors)}
                                {this.renderTextBox("GSTIN", "26vw", false, "MI", "gstin_number", values.gstin_number, "", isDisable, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no", values.dl_no, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_2", values.dl_no_2, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_3", values.dl_no_3, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_4", values.dl_no_4, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_5", values.dl_no_5, "", false, handleChange, errors)}
                                {this.renderTextBox("DL. No", "26vw", false, "MI", "dl_no_6", values.dl_no_6, "", false, handleChange, errors)}
                            </View>
                            <View style={styles.SubTitleTextView}><Text style={styles.SubTitleText}>{"Address"}</Text></View>
                            <View style={styles.TextBoxItemView}>
                                {this.renderTextBox("Address Line 1", "26vw", false, "MI", "address_line_1", values.address_line_1, "", false, handleChange, errors)}
                                {this.renderTextBox("Address Line 2", "26vw", false, "MI", "address_line_2", values.address_line_2, "", false, handleChange, errors)}
                                {this.renderTextBox("Area Name", "26vw", false, "MI", "area_name", values.area_name, "", false, handleChange, errors)}
                                {this.renderTextBox("City", "26vw", false, "MI", "city", values.city, "", false, handleChange, errors)}
                                {this.renderTextBox("State", "26vw", false, "MI", "state", values.state, "", false, handleChange, errors)}
                                {this.renderTextBox("Country", "26vw", false, "MI", "country", values.country, "", false, handleChange, errors)}
                                {this.renderTextBox("Pincode", "26vw", false, "MI", "pincode", values.pincode, "", false, handleChange, errors)}
                            </View>
                            <View style={[styles.SubTitleTextView, {marginTop: 15}]}><Text style={styles.SubTitleText}>{"Contact Details"}</Text></View>
                            <View style={styles.TextBoxItemView}>
                                {this.renderTextBox("Mobile Number", "26vw", false, "MI", "mobile_number", values.mobile_number, "", false, handleChange, errors)}
                                {this.renderTextBox("Landline", "26vw", false, "MI", "phone_number", values.phone_number, "", false, handleChange, errors)}
                                {this.renderTextBox("Email Id", "26vw", false, "MI", "email", values.email, "", false, handleChange, errors)}
                            </View>

                        </View>
                    )}
                </Formik>
                <View style={{ position: "absolute", width: "100%", flex: 1, top: screenHeight - 140, zIndex: 4 }}>
                    {
                        showResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }
                </View>
            </View >
        )
    }

    responseSuccessErrorMessage = (message, type) => {
        if (message != "") {
            if (type) {
                this.setState({
                    showResponse: true,
                    responseMessage: message,
                    showErrorResponse: false
                })
            } else {
                this.setState({
                    showResponse: false,
                    showErrorResponse: true,
                    responseMessage: message
                })
            }
        } else {
            this.setState({
                showResponse: false,
                showErrorResponse: false,
            })
        }
    }
}

const styles = StyleSheet.create({
    TextBoxPlaceholder: {
        zIndex: 2,
        fontSize: '0.78vw',
        position: "absolute",
        marginTop: '-2.5vw',
        marginLeft: '.6vw',
        paddingHorizontal: '.2vw',
        color: "#888888"
    },
    TextBoxTextInput: {
        borderRadius: 4,
        borderWidth: 1,
        width: "26vw",
        height: '2.3vw',
        padding: '1vw',
        marginTop: '.7vw',
        fontSize: '1vw'
    },
    TextBoxerror: {
        fontSize: '1vw',
        color: 'red',
        marginTop: '.3vw'
    },
    TextBoxItemView: {
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "center",
        marginLeft: '1vw'
    },
    SubTitleTextView: {
        marginLeft: '1vw'
    },
    SubTitleText: {
        fontSize: '1vw'
    },
    ButtonStyle: {
        width: '5vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.4vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize: "1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    }
})