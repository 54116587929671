import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image, Dimensions, TextInput, ScrollView, TouchableWithoutFeedback } from 'react-native'
import { color } from '../../styles/Color';
import Constants from "../../networks/Constants";
import Service from '../../networks/service';
import { CommonReportSectionHeader, CommonDateFilter, SuccessAlert, ErrorAlert } from '../../common/BaseFunctionComponent'
import Pagination from '../../common/Pagination';
import Icon from "react-native-vector-icons/FontAwesome";
import { Tooltip } from 'antd';
import moment from "moment"
const screenHeight = Dimensions.get("window").height;

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

export default class NewDrugApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getNewDrugRequest: [],
            paginationNewDrugRequestList: [],
            current_page: 1,
            records_per_page: 10,
            startDate: converted_date,
            endDate: converted_date,
            filterDate: "",
            showDateFilter: false,
            selectedIndex: "",
            statusUpdateData: {},
            isStatusComment: "",
            showResponse: "",
            showErrorResponse: "",
            responseMessage: "",
            declinedComment: "",
            searchDrug: ""
        }
        this.service = new Service();
    }
    componentDidMount() {
        this.getNewDrugRequest()
    }
    getNewDrugRequest = (searchText) => {
        var searchText = searchText ? searchText : ""
        let url = Constants.DRUG_DETAILS_POST + "?status=Pending" + "&from_date=" + this.state.startDate + "&to_date=" + this.state.endDate + "&drug_name=" + searchText;
        this.setState({
            getNewDrugRequest: [],
            paginationNewDrugRequestList: [],
            current_page: 1,
            records_per_page: 10
        })
        this.service.getApiCall(
            url,
            this.getNewDrugRequestSuccess,
            this.getNewDrugRequestFailure
        )
    }

    getNewDrugRequestSuccess = response => {
        if (response.status === "Success") {
            this.setState({
                getNewDrugRequest: response.data
            })
        }
    };
    getNewDrugRequestFailure = (error) => {

    }
    renderNewDrugHeader = () => {
        return (
            <View style={{ zIndex: 0, marginTop: 10, maxHeight: "60%" }}>
                <CommonReportSectionHeader
                    heading1={"S.No"}
                    heading2={"Date"}
                    heading3={"Brand Name"}
                    heading4={"Generic Name"}
                    heading5={"Dosage Strength"}
                    heading6={"Dosage Type"}
                    heading7={"Schedule"}
                    heading8={"Grouping"}
                    heading9={"Action"}
                    columnSize={[0.05, 0.1, 0.15, 0.15, 0.1, 0.15, 0.1, 0.1, 0.1]}
                    noOfColumn={9}
                    fontSize={15}
                />
            </View>
        )
    }
    paginationChangedValues(data) {
        this.setState({
            paginationNewDrugRequestList: data
        })
    }
    paginationCurrentPage = (current_page, records_per_page) => {
        this.setState({
            current_page: current_page,
            records_per_page: records_per_page
        })
    }
    onPressActions = (item, status) => {
        var url = Constants.DRUG_DETAILS_POST;
        var updateItem = {
            "drug_name": item.drug_name,
            "dosage_strength": item.dosage_strength,
            "dosage_type": item.dosage_type,
            "generic_name": item.generic_name,
            "grouping": item.grouping,
            "id": item.id,
            "schedule": item.schedule,
        }
        updateItem['status'] = status === "Approved" ? "Accepted" : "Declined";
        if(this.state.declinedComment) {
            updateItem['comments'] = this.state.declinedComment;
        } 
        if(status === 'Declined' && this.state.declinedComment == "") {
            this.responseSuccessErrorMessage("Please enter the declined comment", false)

            setTimeout(() => {
                this.responseSuccessErrorMessage("", false)
            }, 3000)
        } else { 
        var data = updateItem
         this.service.patchApiCall(
            url,
            data,
            this._onPressActionSuccess,
            this._onPressActionFailure
         )
        }
    }
    _onPressActionSuccess = (response) => {
        if (response.status == 'Success') {
            this.setState({
                statusUpdateData: {},
                isStatusComment: false     
            })
            this.getNewDrugRequest()
            this.responseSuccessErrorMessage(response.message, true)

            setTimeout(() => {
                this.responseSuccessErrorMessage("", true)
            }, 3000)
        } else {
            this.responseSuccessErrorMessage(response.message, false)

            setTimeout(() => {
                this.responseSuccessErrorMessage("", false)
            }, 3000)
        }
    }
    _onPressActionFailure = (error) => {
        this.responseSuccessErrorMessage(error.message, false)
        setTimeout(() => {
            this.responseSuccessErrorMessage("", false)
        }, 3000)
    }

    renderTableData = () => {
        return (
            <View style={{ zIndex: 0, maxHeight: "90%" }}>
                {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                    let sno = (parseInt(this.state.current_page)-1)*parseInt(this.state.records_per_page) + (index + 1)
                    var date = moment(item.created_date__date).format("DD-MM-YYYY")
                    return (
                        <View style={{ flexDirection: "row", flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, paddingVertical: 20 }}>
                            <Text style={{ flex: 0.05, marginLeft: 20 }}>{sno}</Text>
                            <Text style={{ flex: 0.1 }}>{date}</Text>
                            <Text style={{ flex: 0.15, marginLeft: 20 }}>{item.drug_name}</Text>
                            <Text style={{ flex: 0.15 }}>{item.generic_name}</Text>
                            <Text style={{ flex: 0.1 }}>{item.dosage_strength}</Text>
                            <Text style={{ flex: 0.15 }}>{item.dosage_type}</Text>
                            <Text style={{ flex: 0.1 }}>{item.schedule}</Text>
                            <Text style={{ flex: 0.1 }}>{item.grouping}</Text>
                            <View key={index} style={{ flex: 0.1, flexDirection: "row" }}>
                                <TouchableOpacity onPress={() => {
                                    this.setState({
                                        selectedIndex: index,
                                        statusUpdateData: item,
                                    })
                                    this.onPressActions(item, "Approved")
                                }}>
                                    <Tooltip placement="topLeft" title={'Approved'}>
                                        <Image style={{ height: 25, width: 25, tintColor: color.themeDark }} source={require('../../../assets/Icon/complete.png')} />
                                    </Tooltip>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => {
                                    this.setState({
                                        selectedIndex: index,
                                        statusUpdateData: item,
                                        isStatusComment: true
                                    })
                                }} style={{ marginLeft: 20 }}>
                                    <Tooltip placement="topLeft" title={'Declined'}>
                                       <Image style={{ height: 25, width: 25, tintColor: color.themeDark }} source={require('../../../assets/Icon/cancel.png')} />
                                    </Tooltip>
                                </TouchableOpacity>
                            </View>
                        </View>
                    )
                })}
            </View>
        )

    }
    showSelectedFilteredDate(date) {
        this.setState({
            startDate: date.fromDate,
            endDate: date.toDate,
            showDateFilter: false
        }, () => {
            this.getNewDrugRequest()
        })
    }
    _disableDateFilter = (flag) => {

        this.setState({
            showDateFilter: flag
        })
    }
    renderDateFilter = () => {
        return (
            <View style={{ justifyContent: "flex-end", alignItems: "flex-end", flexDirection: "row", zIndex: 10, maxHeight: 60 }} 
                onLayout={(event) => {
                  const layout = event.nativeEvent.layout 
                }}
                >
                 <View style={{flexDirection: "row", zIndex: 0, margin: 10}}>
                        <TextInput
                        value = {this.state.searchDrug}
                        onChangeText={(text) => {
                            this.setState({
                                searchDrug: text
                            })
                        }}
                        style={{borderWidth: 1, height: 35, width: 170, paddingHorizontal: 3, borderColor: color.lightGray }}
                        placeholder="Search drug..."
                        />
                        <TouchableOpacity 
                        style={{height: 35, width: 35, backgroundColor: color.themeDark, justifyContent: "center", alignItems: "center" }}
                        onPress={() => {
                            this.getNewDrugRequest(this.state.searchDrug)
                        }}>
                            <Icon name='search' size={15} color={color.white}></Icon>
                        </TouchableOpacity>
                    </View>
                <View style={{ zIndex: 10 }}>   
                <CommonDateFilter
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    filterDate={this.state.filterDate}
                    showSelectedFilteredDate={this.showSelectedFilteredDate.bind(this)}
                    defaultSelectedDateFilter={"Today"}
                    // on outside focus, close popup
                    showDateFilter={this.state.showDateFilter}
                    disableDateFilter={this._disableDateFilter.bind(this)}
                />
                </View> 
            </View>
        )
    }
    render() {
        let { showResponse, showErrorResponse, responseMessage, current_page, records_per_page } = this.state
        return (
          <TouchableWithoutFeedback onPress={() => { 
                this.setState({
                    showDateFilter: false
                })
           }}>
            <View style={{ padding: 10, zIndex: 0, height: screenHeight-120 }}>

                {this.renderDateFilter()}
                <ScrollView style={{ maxHeight: screenHeight- 200 }}>
                  {/* {this.renderNewDrugHeader()}
                  {this.renderTableData()} */}
                <View style={{ paddingHorizontal: 20, flexDirection: "row", width: "100vw"}}>
                <View style={{width: "5vw"}}>
                <CommonReportSectionHeader 
                heading1={"S.No"}
                columnSize={[1]}
                noOfColumn={2}  
                />
                {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                let sno = (current_page-1)*records_per_page + index + 1;
                return(
                <View style={{flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0,  paddingVertical: 20,  width: "100%", maxHeight: 60, minHeight: 60 }}>
                <Text style={{ flex: 1, marginHorizontal: "1.30vw" }}>{sno}</Text>
                </View>
                )})}
                </View>
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={(this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0) ? true : false}  style={{maxWidth: "70vw", flex: 1}}>
                <View style={{width: "150vw"}}>
                <CommonReportSectionHeader
                heading1={"Date"}
                heading2={"Drug Name"}
                heading3={"Generic Name"}
                heading4={"Dosage Strength"}
                heading5={"Dosage Type"}
                heading6={"GST %"}
                heading7={"Manufacture Name"}
                heading8={"HSN code"}
                heading9={"Phy box No"}
                heading10={"Reorder Threshold"}
                heading11={"Expiry Alert Period"}
                heading12={"Recommended by"}
                heading13={"UPC/EAN"}
                heading14={"Grouping"}
                heading15={"Schedule"}
                columnSize={[0.11, 0.15, 0.15, 0.14, 0.15, 0.1, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15]}  
                noOfColumn={15}
                />
                {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                    return(
                        <View style={{ justifyContent: 'center', maxHeight: 60, minHeight: 60 }}>
                        <View style={{flexDirection: 'row', flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, alignItems: 'center' }}>
                        <Text style={{ flex: 0.11, marginLeft: '1.3vw' }}>{item.created_date}</Text>
                        <Tooltip placement="topLeft" title={item.drug_name}>               
                        <Text style={{flex: 0.15 }}> {item.drug_name && item.drug_name.length > 12 ? item.drug_name.slice(0,12) + ".." :( item.drug_name != null && item.drug_name != "") ? item.drug_name : "-"} </Text> 
                        </Tooltip>
                        <Tooltip placement="topLeft" title={item.generic_name}>               
                        <Text style={{flex: 0.15 }}> {item.generic_name && item.generic_name.length > 12 ? item.generic_name.slice(0,12) + ".." :( item.generic_name != null && item.generic_name != "") ? item.generic_name : "-"} </Text> 
                        </Tooltip>
                        <Text style={{ flex: 0.14 }}>{item.dosage_strength}</Text>
                        <Text style={{ flex: 0.15 }}>{item.dosage_type}</Text>
                        <Text style={{ flex: 0.1 }}>{item.gst_percentage}</Text>
                        <Text style={{ flex: 0.15 }}>{item.manufacturer_name}</Text>
                        <Text style={{ flex: 0.15}}>{item.hsn_code}</Text>
                        <Text style={{ flex: 0.15 }}>{item.physical_box_no}</Text>
                        <Text style={{ flex: 0.15 }}>{item.reorder_threshold}</Text>
                        <Text style={{ flex: 0.15 }}>{item.expiry_alert_period}</Text>
                        <Text style={{ flex: 0.15 }}>{item.recommended_by}</Text>
                        <Text style={{ flex: 0.15 }}>{item.bar_code}</Text>
                        <Text style={{ flex: 0.15 }}>{item.grouping}</Text>
                        <Text style={{ flex: 0.15}}>{item.schedule}</Text>                         
                        </View>
                        </View>
                    )})}
                </View>
                </ScrollView>
                <View style={{width: "15vw"}}>
                <CommonReportSectionHeader 
                heading1={"Action"}
                columnSize={[1]}
                noOfColumn={1}  
                />
                {this.state.paginationNewDrugRequestList && this.state.paginationNewDrugRequestList.length > 0 && this.state.paginationNewDrugRequestList.map((item, index) => {
                return(
                    <View style={{ justifyContent: 'center', maxHeight: 60, minHeight: 60 }}>
                    <View style={{flexDirection: 'row', flex: 1, borderBottomWidth: 1, borderBottomColor: color.lightGray, zIndex: 0, alignItems: 'center'}}>
                    <TouchableOpacity onPress={() => {
                        this.setState({
                            selectedIndex: index,
                            statusUpdateData: item,
                        })
                        this.onPressActions(item, "Approved")
                    }}
                    style={{ marginLeft: '1.3vw' }}
                    >
                        <Tooltip placement="topLeft" title={'Approved'}>
                            <Image style={{ height: 25, width: 25, tintColor: color.themeDark }} source={require('../../../assets/Icon/complete.png')} />
                        </Tooltip>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        this.setState({
                            selectedIndex: index,
                            statusUpdateData: item,
                            isStatusComment: true
                        })
                    }} style={{ marginLeft: '1.3vw' }}>
                        <Tooltip placement="topLeft" title={'Declined'}>
                            <Image style={{ height: 25, width: 25, tintColor: color.themeDark }} source={require('../../../assets/Icon/cancel.png')} />
                        </Tooltip>
                    </TouchableOpacity>
                    </View>
                    </View>
                )})}
                </View>
                </View>
                </ScrollView>

                <View>
                    {
                        this.state.getNewDrugRequest && this.state.getNewDrugRequest.length > 0 ?
                            <View style={{ marginLeft: 20 }}>
                                <Pagination paginationChangedValues={this.paginationChangedValues.bind(this)}
                                    totalClientItems={this.state.getNewDrugRequest}
                                    paginationCurrentPage={this.paginationCurrentPage.bind(this)}
                                />
                            </View> : null
                    }
                </View>
                <View style={{ position: "absolute", width: "100%", flex: 1, top: screenHeight-65, zIndex: 12 }}>
                    {
                        showResponse ?
                            <SuccessAlert message={responseMessage} /> : null
                    }
                    {
                        showErrorResponse ?
                            <ErrorAlert message={responseMessage} /> : null
                    }
                </View>
                {this.state.isStatusComment ?
                <View style={{ justifyContent: "center", alignItems: "center", height: screenHeight-156, width: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', zIndex: 10 }}>
                 <View style={{ justifyContent: "center", alignItems: "center", backgroundColor: color.white, borderRadius: 4, marginVertical: 10, height: "26%", width: "32%" }}>
                       <Text style={{ fontWeight: 500, fontSize: 15, marginTop: 10 }}>{"Enter your comment"}</Text>
                       <TextInput onChangeText={(text) => {
                          this.setState({
                            declinedComment: text
                          })
                       }}
                       style={{ width: "80%", height: "40%", padding: 10, borderWidth: 1, borderRadius: 4, margin: 10, borderColor: color.lightGray }}
                       />
                         <View style={{ flexDirection: "row", height: 30, flex: 1, width: "40%", justifyContent: "space-around", flexWrap: "wrap-reverse", marginBottom: '0.5vw' }}>
                            <TouchableOpacity onPress={()=>{
                               this.onPressActions(this.state.statusUpdateData,"Declined")
                            }}
                            style={{ backgroundColor: color.themeDark, height: 30, justifyContent: "center", alignSelf: "center", paddingHorizontal: 10, borderRadius: 4}}
                            ><Text style={{ fontSize: 12, color: color.white }}>{"Submit"}</Text></TouchableOpacity>
                             <TouchableOpacity onPress={()=>{
                                 this.setState({
                                    isStatusComment: false
                                 })
                              }}
                              style={{ backgroundColor: color.themeDark, height: 30, justifyContent: "center", alignSelf: "center", paddingHorizontal: 10, borderRadius: 4,  }}
                            ><Text  style={{ fontSize: 12, color: color.white }}>{"Cancel"}</Text></TouchableOpacity>
                         </View>    
                        </View>

                    </View>
                    : null
                }
            </View>
           </TouchableWithoutFeedback> 
        )
    }
    responseSuccessErrorMessage(message, type) {
        if (message != "") {
            if (type) {
                this.setState({
                    showResponse: true,
                    responseMessage: message,
                    showErrorResponse: false
                })
            } else {
                this.setState({
                    showResponse: false,
                    showErrorResponse: true,
                    responseMessage: message
                })
            }
        } else {
            this.setState({
                showResponse: false,
                showErrorResponse: false,
            })
        }
    }
}    