import Item from 'antd/lib/list/Item';
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent'
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment";
import Pagination from '../../common/Pagination';
import { Tooltip } from 'antd';

let fulldate = new Date();
let converted_date = moment(fulldate).format("YYYY-MM-DD");

export default class PurchaseReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesReportList: [],
      startTime: converted_date,
      endTime: converted_date,
      billingListAfterPagination: [],
      // for pagination
      current_page: 1,
      records_per_page: 10,
      // for pagination, from get data
      total_count: 0,
      no_of_pages: 0

    };

    this.service = new Service();
    this._paginationChangedValues = this._paginationChangedValues.bind(this) 
  }

  componentDidMount() {
    this._getSalesDetailed()
  }

  componentWillReceiveProps(props) {
    
    if (props.isclear && (props.startTime!=this.state.startTime || props.endTime!=this.state.endTime)) 
    {
      this.setState({
        startTime: props.startTime,
        endTime: props.endTime,
        current_page: 1,
        records_per_page: 10
      }, () => {
        this._getSalesDetailed()
        props.handlepropsvalueclear(false)

      })
    }
  }

  // Get Api 
  _getSalesDetailed = () => {
    
    var url = "" 
    let { current_page, records_per_page } = this.state;

    if (this.state.startTime && this.state.endTime) {
      url = Constants.GET_PURCHASE_RETURN + "?from_date=" + this.state.startTime + "&to_date=" + this.state.endTime + "&page="+current_page+"&page_size="+records_per_page;
    }
    else {
      url = Constants.GET_PURCHASE_RETURN + "?page="+current_page+"&page_size="+records_per_page;
    }

    this.service.getApiCall(
      url,
      this._getSalesDetailedSuccess,
      this._getSalesDetailedFailure
    )
  }

  _getSalesDetailedSuccess = response => {

    let { salesReportList } = this.state;

    if (response.status === "success") {

      salesReportList = response.data.result
      this.setState({
        salesReportList, 
        no_of_pages: response.data?response.data.no_of_pages:0,
        total_count: response.data?response.data.total_count:0
      }, () => {
      var  data ={
          "total_price":response.data.total_price,
          "total_price_per_quantity":response.data.total_price_per_quantity,

        }
        this.props.gettotalscoreValues("Purchase Return", data)
      })
    }
    else {
      this.props.gettotalscoreValues("Purchase Return", "")

    }
  };

  _getSalesDetailedFailure = error => {
    this.props.gettotalscoreValues("Purchase Return", "")

    console.log(error);

  };
  // Report List Views Function
  renderContent() {
    return (
      // Report List Views
      <View style={{ marginHorizontal: 20, backgroundColor: "#F9F9F9", marginTop: 15 }}>
        {this.renderContentRow()}



        {/* End Report List View */}

      </View>
    )
  }

  paginationChangedValues(data) {
    this.setState({
      billingListAfterPagination: data
    })
  }


  // Common History Section Header function
  renderHeader() {
    return (

      <View style={styles.TableHead}>
        <CommonReportSectionHeader
          heading1={"S.No"}
          heading2={"Return Date"}
          heading3={"Vendor Name"}
          heading4={"Batch No"}
          heading5={"Brand Name"}
          heading6={"Generic Name"}
          heading7={"Dosage Type"}
          heading8={"Dosage Strength"}
          heading9={"Quantity"}
          heading10={"PP/Qty"}
          heading11={"Gst Amt"}
          heading12={"Total Price"}
          heading13={"EPR Value"}
          columnSize={[0.05, 0.08, 0.08, 0.06, 0.08, 0.11, 0.08, 0.10, 0.08, 0.08, 0.08, 0.08,0.04]}  //total value is == of 1
          noOfColumn={13}

        />
      </View>
    )
  }


  // Render Content Row function Key and 
  renderContentRow() {
    if (this.state.salesReportList &&this.state.salesReportList.length>0&& this.state.salesReportList.length < 11) {

      return (
        <View style={{}}>
          {
            this.state.salesReportList && this.state.salesReportList.map((item, index) => {
              return (
                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 20, justifyContent: 'center', alignItems: 'center' }}>
                  <View style={[{ flex: 0.05 }]}><Text >{index + 1}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text>{item.date ? moment(item.date).format("DD-MM-YYYY") : null}</Text></View>
                  <View style={[[{ flex: 0.08 }]]}><Text>{item.vendor_name}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text>{item.brand_name}</Text></View>
                  <View style={[{ flex: 0.11 }]}><Text>{item.generic_name}</Text></View>
                  <View style={[{ flex: 0.06 }]}><Text>{item.dosage_type}</Text></View>
                  <View style={[{ flex: 0.10 }]}><Text>{item.dosage_strength}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text>{item.quantity}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text>{item.price_per_quantity}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text>{item.total_price}</Text></View>
                </View>
              )
            })}



        </View>
      )

    }
    else if (this.state.salesReportList && this.state.salesReportList.length > 0) {

      return (
        <View style={{}}>
          {
            this.state.billingListAfterPagination && this.state.billingListAfterPagination.map((item, index) => {
              return (
                <View style={{ flexDirection: 'row', borderBottomColor: "#888888", borderBottomWidth: 1, paddingVertical: 20, marginHorizontal: 20, justifyContent: 'center', alignItems: 'center' }}>
                  <View style={[{ flex: 0.09 }]}><Text >{index + 1}</Text></View>
                  <View style={[{ flex: 0.09 }]}><Text>{item.date ? moment(item.date).format("DD-MM-YYYY") : null}</Text></View>
                  <View style={[[{ flex: 0.09 }]]}><Text>{item.invoice_number}</Text></View>
                  <View style={[{ flex: 0.09 }]}><Text>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.09 }]}><Text>{item.brand_name}</Text></View>
                  <View style={[{ flex: 0.1 }]}><Text>{item.generic_name}</Text></View>
                  <View style={[{ flex: 0.09 }]}><Text>{item.dosage_type}</Text></View>
                  <View style={[{ flex: 0.12 }]}><Text>{item.dosage_strength}</Text></View>
                  <View style={[{ flex: 0.09 }]}><Text>{item.quantity_bought}</Text></View>
                  <View style={[{ flex: 0.09 }]}><Text>{item.returned_quantity}</Text></View>
                  <View style={[{ flex: 0.09 }]}><Text>{item.returned_amount}</Text></View>
                </View>
              )
            })}



        </View>
      )

    }
    else {
      return <View style={styles.NoRecordView}>
        <Text style={{ color: "#8888888" }}>{"No records to be shown"}</Text>
      </View>
    }
  }
  _paginationChangedValues(next_page, records_per_page_change) {

    let { current_page, records_per_page } = this.state

    if( current_page != next_page  || records_per_page!=records_per_page_change) {
        
        this.setState({
            records_per_page: records_per_page_change,
            current_page: next_page
        }, ()=>{
            this._getSalesDetailed()
        })
    }
  }
  render() {

    let { total_count, no_of_pages } = this.state

    total_count = parseInt(total_count) || 0

    return (
      <View>
        {this.renderHeader()}
        {this.renderPaginateContent()}
        {
          total_count > 0 ?
          <View style={{ marginLeft: 20 }}>
            <Pagination paginationChangedValues={this._paginationChangedValues.bind(this)}  
              type={Constants.PAGINATION_TYPE_SERVER} 
              totalServerRecordsCount={total_count} 
              totalServerPagesCount={no_of_pages}/></View>
            : null
        }
      </View>
    );
  }
  renderPaginateContent() { 
// 0.05, 0.08, 0.13, 0.06, 0.12, 0.16, 0.09, 0.12, 0.06, 0.06, 0.09
    let { current_page, records_per_page } = this.state

    if(this.state.salesReportList && this.state.salesReportList.length > 0) {
      return (
        // Report List Views
        <View style={styles.TableContent}>   
          {this.state.salesReportList && this.state.salesReportList.length > 0 && this.state.salesReportList.map((item, index) => {
              let sno = (current_page-1)*records_per_page + index + 1;
              return (
                <View key={sno} style={styles.TableContentView}>
                  <View style={[{ flex: 0.05 ,marginLeft: '1.30vw'}]}><Text style={[styles.TableContentText]} >{sno}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text style={styles.TableContentText}>{item.date ? moment(item.date).format("DD-MM-YYYY") : null}</Text></View>
                  <View style={[{ flex: 0.08 }]}>
                    <Tooltip placement="topLeft" title={item.vendor_name}>               
                     <Text style={styles.TableContentText}> {item.vendor_name && item.vendor_name.length > 12 ? item.vendor_name.slice(0,12) + ".." : item.vendor_name} </Text> 
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.06 }]}><Text style={styles.TableContentText}>{item.batch_no}</Text></View>
                  <View style={[{ flex: 0.08 }]}>
                    <Tooltip placement="topLeft" title={item.brand_name}>               
                     <Text style={styles.TableContentText}> {item.brand_name && item.brand_name.length > 12 ? item.brand_name.slice(0,12) + ".." : item.brand_name} </Text> 
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.11 }]}>
                    <Tooltip placement="topLeft" title={item.generic_name}>               
                     <Text style={styles.TableContentText}> {item.generic_name && item.generic_name.length > 12 ? item.generic_name.slice(0,12) + ".." : item.generic_name} </Text> 
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.08 }]}>
                    <Tooltip placement="topLeft" title={item.dosage_type}>               
                     <Text style={styles.TableContentText}> {item.dosage_type && item.dosage_type.length > 12 ? item.dosage_type.slice(0,12) + ".." : item.dosage_type} </Text> 
                    </Tooltip>
                  </View>
                  <View style={[{ flex: 0.10 }]}><Text style={styles.TableContentText}>{item.dosage_strength}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text style={styles.TableContentText}>{item.quantity}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text style={styles.TableContentText}>{(item.price_per_quantity).toFixed(2)}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text style={styles.TableContentText}>{(item.taxable_amount).toFixed(2)}</Text></View>
                  <View style={[{ flex: 0.08 }]}><Text style={styles.TableContentText}>{(item.total_price).toFixed(2)}</Text></View>
                  <View style={[{ flex: 0.04 }]}><Text style={styles.TableContentText}>{item.epr_value}</Text></View>
                </View>
              )
              
            })
          }
        </View>
      )
    }else{
      return(
        <View style={styles.NoRecordView}>
          <Text style={styles.NoRecordText}>{"No records to be shown"}</Text>
        </View>
      )
    }
  }
}
const styles = StyleSheet.create({
      TableHead: { 
        marginHorizontal: '1.2vw', 
        marginTop: '.5vw' 
      },
      TableContent: { 
        marginHorizontal: '1.2vw', 
        backgroundColor: "#F9F9F9", 
        marginTop: '1vw' 
      },
      TableContentView: { 
        flexDirection: 'row', 
        borderBottomColor: "#888888", 
        borderBottomWidth: 1, 
        paddingVertical: '1.2vw', 
        marginHorizontal: 0 
      },
      TableContentText: { fontSize: '1vw'},
      NoRecordText: { color: "#8888888", fontSize: '1vw' },
      NoRecordView: { 
        flex: 1, 
        flexDirection: "row", 
        justifyContent: "center", 
        alignContent: "center", 
        minHeight: '7vw', 
        alignItems: 'center' 
      }
})