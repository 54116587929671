import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CommonReportSectionHeader } from '../../common/BaseFunctionComponent';
import Pagination from '../../common/Pagination';
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import moment from "moment"
import { color } from '../../styles/Color';

var today = new Date();
var year = today.getFullYear();
export default class consolidatedSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consolidateData: [],
            selectedYear: year
        };
        this.service = new Service();

    }
    componentDidMount() {
        this._getConsolidateReport()
    }
    componentWillReceiveProps(props) {
        if (props.selectedYear && props.isclear) {
            this.setState({
                selectedYear: props.selectedYear,
            }, () => {
                this._getConsolidateReport()
                props.handlepropsvalueclear(false)
            })
        }
    }
    _getConsolidateReport() {
        var url = Constants.GET_CONSOLIDATED_REPORT + "?invoice_year=" + this.state.selectedYear
        this.service.getApiCall(
            url,
            this._getConsolidateSuccess,
            this._getConsolidateFailure
        )

    }
    _getConsolidateSuccess = response => {
        if (response.status === "success") {
            this.setState({
                consolidateData: response.data.result

            })
            var data = {
                "profit_total": response.data.profit_total,
                "purchase_total": response.data.purchase_total,
                "sales_total": response.data.sales_total,
            }
            this.props.gettotalscoreValues("Consolidated Sales", data)

        }
    };

  
    _getConsolidateFailure = error => {
    
        console.log(error);

    };
    renderHeader() {
        return (

            <View style={styles.TableHead}>
                <CommonReportSectionHeader
                    heading1={"S.NO"}
                    heading2={"Month"}
                    heading3={"Purchase Amount ( ₹ )"}
                    heading4={"Sales Amount ( ₹ )"}
                    heading5={"Profit Amount ( ₹ )"}
                    columnSize={[0.2, 0.2, 0.2, 0.2, 0.2]}  //total value is == of 1
                    noOfColumn={5}

                />
            </View>
        )
    }

    render() {
        return (
            <View>
                {this.renderHeader()}
                {this.renderPaginateContent()}
            </View>
        );
    }
    renderPaginateContent() {

        let { current_page, records_per_page } = this.state

        if (this.state.consolidateData && this.state.consolidateData.length > 0) {
            return (
                <View style={styles.TableContent}>
                    {this.state.consolidateData && this.state.consolidateData.length > 0 && this.state.consolidateData.map((item, index) => {
                        return (
                            <TouchableOpacity onPress={() => { this.props.consolidatedSelected(true, item.date) }} style={styles.TableContentView}>
                                <View style={{ flex: 0.2, marginLeft:10 }}><Text style={styles.TableContentText}>{index + 1}</Text></View>
                                <View style={{ flex: 0.2}}><Text style={styles.TableContentText}>{item.date}</Text></View>
                                <View style={{ flex: 0.08 }}><Text style={[styles.TableContentText, { textAlign : "right" }]}>{parseFloat(item.purchase_amount).toFixed(2)}</Text></View>
                                <View style={{ flex: 0.175 }}><Text style={[styles.TableContentText, { textAlign : "right" }]}>{parseFloat(item.sales_amount).toFixed(2)}</Text></View>
                                <View style={{ flex: 0.195 }}><View style={{minWidth : 10}}><Text style={[styles.TableContentText, { textAlign : "right",color : item.profit_amount > 0 ? color.cardGreen : color.red }]}>{parseFloat(item.profit_amount).toFixed(2)}</Text></View></View>

                            </TouchableOpacity>
                        )

                    })
                    }
                </View>
            )
        } else {
            return (
                <View style={styles.NoRecordView}>
                    <Text style={styles.NoRecordText}>{"No records to be shown"}</Text>
                </View>
            )
        }
    }
    
}
const styles = StyleSheet.create({
        TableHead: { 
            marginHorizontal: '1.2vw', 
            marginTop: '.5vw' 
        },
        TableContent: { 
            marginHorizontal: '1.2vw', 
            backgroundColor: "#F9F9F9", 
            marginTop: '1vw'
        },
        TableContentView: { 
            flexDirection: 'row', 
            borderBottomColor: "#888888", 
            borderBottomWidth: 1, 
            paddingVertical: '1.2vw', 
            marginHorizontal: 0 
        },
        TableContentText: { fontSize: '1vw'},
        NoRecordView: { 
            flex: 1, 
            flexDirection: "row", 
            justifyContent: "center", 
            alignContent: "center", 
            marginTop: '9.5vw' 
        },
        NoRecordText: { 
            color: "#8888888", 
            fontSize: '1vw' 
        }

})