import React, { Component } from "react";
import { View, Dimensions, ScrollView, TouchableOpacity, Text } from "react-native";
import { CommonButton, SuccessAlert, ErrorAlert } from "../../common/BaseFunctionComponent";
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import { color } from "../../styles/Color";
import VendorDetails from "./VendorDetails";
import PurchaseDetails from "./PurchaseDetails";
import StockDetails from "./StockDetails";
import NewDrugEntry from './AddNewDrug'
import NewDrugRequest from './NewDrugRequest'
import List from "./List";
import OTIndentRequestList from '../admin/OTindentRequest/OTIndentRequestList'
import OTIndentDetails from "../admin/OTindentRequest/OTIndentDetails";
import OrderList from "./orderlist";
import ConfirmOrderList from "./confirmOrderList";
import StockList from "./stockList";
import DrugList from "./DrugList";

export default class Inventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedScreen: this.props.subMenu ? this.props.subMenu : "stock_list",
            showPickerDropDownFlag: false,
            selectedBatch: [],
            batchData: [],
            billRows: [],   //[{ invoice_no: "123456"},{ invoice_no: "123456"},{ invoice_no: "123456"},{ invoice_no: "123456"},{ invoice_no: "123456"},{ invoice_no: "123456"},{ invoice_no: "123456"},{ invoice_no: "123456"},{ invoice_no: "123456"},{ invoice_no: "123456"}]
            searchValue: "",
            statusDelivery: "",
            loginUserData: this.props.loginUserData,
            resportActions: true,
            SelectedIndent:{},
            pharmacyType:this.props.loginUserData.data.pharmacy_type
        }
        this.renderComponent = this.renderComponent.bind(this)
        this.service = new Service();
    }

    componentWillReceiveProps(props) {
        if (props.searchValue != this.state.searchValue) {
            this.setState({
                searchValue: props.searchValue
            })
        }else{
            // this.setState({
            //     searchValue: ""
            // })
        }
    }

    render() {
        const navigationHeaderList =this.state.pharmacyType === "Standalone Pharmacy" ?[
            { label:"Stock List", value:'stock_list' },
            { label:"Drug List", value:"drug_list" },
            { label: "Inactive Drug List", value: "inactive_list" },
        ] : [
            { label:"Stock List", value:'stock_list' },
            { label:"Drug List", value:"drug_list" },
            { label: "Inactive Drug List", value: "inactive_list" },
            { label: "OT Indent Request", value: 'OTindentRequest' }
        ]

        let data = { 'label': 'Back', 'value': 'Back' };
        let { selectedScreen, showResponse, showErrorResponse, responseMessage, resportActions } = this.state;
        console.log("PROPS", this.props);
        console.log("STATE", this.state);
        console.log("scrreenn", this.state.selectedScreen);
        return (
            <View style={{ maxHeight: "100vh" }}>
                <View style={[{ backgroundColor: color.white, borderBottomLeftRadius: 40, borderBottomRightRadius: 40, height: 45 }, { marginBottom: 10, shadowColor: color.grayShade, shadowOffset: { width: 1, height: 1 }, shadowOpacity: 1, shadowRadius: 2 }]}>
                    <ScrollView style={{ flexDirection: 'row', marginHorizontal: 15 }} horizontal={true} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                        {
                            navigationHeaderList && navigationHeaderList.length > 0 && navigationHeaderList.map((item, index) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.setState({
                                                selectedScreen: item.value
                                            })
                                        }} style={{ paddingHorizontal: 10, justifyContent: "center" }} key={index}>
                                        <Text style={[{ fontSize: "1vw", fontWeight: "500" },
                                        this.state.selectedScreen === item.value ? { color: color.themeDark } :
                                            this.state.selectedScreen === "new_drug_entry" && item.value === "stock_list" ? { color: color.themeDark } :
                                                this.state.selectedScreen === "new_drug_request" && item.value === "stock_list" ? { color: color.themeDark } :
                                                    this.state.selectedScreen === "view_stock" && item.value === "stock_list" ? { color: color.themeDark } :
                                                        this.state.selectedScreen === "OTDetails" && item.value == "OTindentRequest" ? { color: color.themeDark } : {}
                                        ]}>

                                            {item.label}</Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </ScrollView>
                </View>

                <ScrollView
                    ref={scrollView => this.scrollView = scrollView}
                    onContentSizeChange={() => {
                    }}
                    showsVerticalScrollIndicator={false}
                    style={{ width: "100%", height:'100%' }}
                    contentContainerStyle={{ flex: 1 }}
                >
                    {this.renderComponent(selectedScreen)}
                </ScrollView>
                {
                    showResponse ?
                        <SuccessAlert message={responseMessage} /> : null
                }
                {
                    showErrorResponse ?
                        <ErrorAlert message={responseMessage} /> : null
                }
            </View>
        )
    }
    onPressButton(key, item) {
        this.setState({ selectedScreen: key })
    }

    renderComponent(selectedComponent) {      
        const default_label = this.state.pharmacyType === "Standalone Pharmacy" ? [
            { label: "Inventory -", isLink: false, key: '' }, { label: "Stock List", isLink: true, key: 'stock_list' },
            // { label: "/", isLink: false, key: '' }, { label: "Wanted List", isLink: true, key: 'wanted_list' },
            { label: "/", isLink: false, key: '' }, { label: "Purchase Order", isLink: true, key: 'purchase_orders' }
        ]:[
            { label: "Inventory -", isLink: false, key: '' }, { label: "Stock List", isLink: true, key: 'stock_list' },
            // { label: "/", isLink: false, key: '' }, { label: "Wanted List", isLink: true, key: 'wanted_list' },
            // { label: "/", isLink: false, key: '' }, { label: "Purchase Order", isLink: true, key: 'purchase_orders' },
            { label: "/", isLink: false, key: '' }, { label: "OT Indent Request", isLink: true, key: 'OTindentRequest' },
        ];
        
        const title_label = {
            "purchase_orders": default_label,
            "stock_list": default_label,
            "wanted_list": default_label,
            "OTindentRequest": default_label,
            // "add_vendor": [{ label: "Vendor Management", isLink: true, key: 'vendors' },
            // { label: "/", isLink: false, key: '' }, { label: "Add Vendor", isLink: true, key: 'add_vendor' }],
            // "update_vendor": [{ label: "Vendor Management", isLink: true, key: 'vendors' },
            // { label: "/", isLink: false, key: '' }, { label: "Update Vendor", isLink: true, key: 'update_vendor' }],
            "add_purchase_order": [{ label: "Purchase Order", isLink: true, key: 'purchase_orders' },
            { label: "/", isLink: false, key: '' }, { label: "Add Purchase Order", isLink: true, key: 'add_purchase_order' }],
            "update_purchase_order": [{ label: "Purchase Order", isLink: true, key: 'purchase_orders' },
            { label: "/", isLink: false, key: '' }, { label: "Update Purchase Order", isLink: true, key: 'update_purchase_order' }],
            "add_stock": [{ label: "Purchase Order", isLink: true, key: 'purchase_orders' }, { label: "-", isLink: false, key: '' }, { label: "Add to Stock", isLink: false, key: '' }],
            "view_stock": [{ label: "Stock or Drug List", isLink: true, key: 'stock_list' }, { label: "-", isLink: false, key: '' }, { label: "Stock Details", isLink: false, key: '' }],
            "new_drug_entry": [{ label: "Drug List", isLink: true, key: 'stock_list' }, { label: "/", isLink: false, key: '' }, { label: "Add New Drug", isLink: true, key: 'new_drug_entry'}, { label: "/", isLink: false, key: '' }, { label: "Request", isLink: true, key: 'new_drug_request' }],
            "new_drug_request": [{ label: "Drug List", isLink: true, key: 'stock_list' }, { label: "/", isLink: false, key: '' }, { label: "Add New Drug", isLink: true, key: 'new_drug_entry' }, { label: "/", isLink: false, key: '' }, { label: "Request", isLink: true, key: 'new_drug_request' }],
            "order_list": [{ label: "Wanted List", isLink: true, key: 'wanted_list' }, { label: "/", isLink: false, key: '' }, { label: "Order List", isLink: true, key: 'order_list'}, { label: "/", isLink: false, key: '' }, { label: "Confirm Order List", isLink: true, key: 'confirm_order_list' }],
            "confirm_order_list": [{ label: "Wanted List", isLink: true, key: 'wanted_list' }, { label: "/", isLink: false, key: '' }, { label: "Order List", isLink: true, key: 'order_list' }, { label: "/", isLink: false, key: '' }, { label: "Confirm Order List", isLink: true, key: 'confirm_order_list' }],
        
        };
        var componentLookup = {
            vendors: (<List menu={title_label[selectedComponent]} selectedScreen={selectedComponent} changeScreen={this.changeScreen.bind(this)} openVendorDetails={this.openVendorDetails.bind(this)} logout={this.props.logout} screenType={this.state.screenType} searchValue={this.state.searchValue} />),
            add_vendor: (<VendorDetails menu={title_label[selectedComponent]} selectedScreen={selectedComponent} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} />),
            update_vendor: (<VendorDetails menu={title_label[selectedComponent]} selectedScreen={selectedComponent} changeScreen={this.changeScreen.bind(this)} edit_id={this.state.vendor_id} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} />),
            purchase_orders: (<List menu={title_label[selectedComponent]} selectedScreen={selectedComponent} changeScreen={this.changeScreen.bind(this)} searchValue={this.state.searchValue}/>),
            add_purchase_order: (<PurchaseDetails menu={title_label[selectedComponent]} selectedScreen={selectedComponent} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} />),
            update_purchase_order: (<PurchaseDetails menu={title_label[selectedComponent]} selectedScreen={selectedComponent} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} />),
            stock_list: (<StockList  selectedScreen={selectedComponent} changeScreen={this.changeScreen.bind(this)} searchValue={this.state.searchValue} generateReport={this._generateReport} isDisable={!this.state.resportActions}/>),
            add_stock: (<StockDetails menu={title_label[selectedComponent]} selectedScreen={selectedComponent} screenType={this.state.screenType} purchase_id={this.state.purchase_id} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} statusDelivery = {this.state.statusDelivery} _getDrugAndStockCount = {this.props._getDrugAndStockCount}/>),
            wanted_list: (<List menu={title_label[selectedComponent]} selectedScreen={selectedComponent} changeScreen={this.changeScreen.bind(this)} searchValue={this.state.searchValue} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} />),
            view_stock: (<StockDetails  selectedScreen={selectedComponent} screenType={this.state.screenType} stocklist_id={this.state.stocklist_id} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} />),
            new_drug_entry: (<NewDrugEntry  selectedScreen={selectedComponent} screenType={this.state.screenType} stocklist_id={this.state.stocklist_id} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} loginUserData = {this.state.loginUserData} />),
            new_drug_request: (<NewDrugRequest  selectedScreen={selectedComponent} screenType={this.state.screenType} stocklist_id={this.state.stocklist_id} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} loginUserData = {this.state.loginUserData} />),
            OTindentRequest:(<OTIndentRequestList   screenType={this.state.screenType} selectedScreen={selectedComponent} loginUserData = {this.state.loginUserData} FromInventory={true} NavigateScreen={this.NavigateScreen.bind(this)} changeScreen={this.changeScreen.bind(this)} />),
            OTDetails:(<OTIndentDetails  selectedScreen={selectedComponent} screenType={this.state.screenType} loginUserData = {this.state.loginUserData} FromInventory={true} IndentDetails={this.state.SelectedIndent} NavigateScreen={this.NavigateScreen.bind(this)}  ApproveAlert={this.ApproveAlertOTIndent.bind(this)}/>),
            order_list: (<OrderList menu={title_label[selectedComponent]} selectedScreen={selectedComponent} screenType={this.state.screenType} stocklist_id={this.state.stocklist_id} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} loginUserData = {this.state.loginUserData} />),
            confirm_order_list: (<ConfirmOrderList menu={title_label[selectedComponent]} selectedScreen={selectedComponent} screenType={this.state.screenType} stocklist_id={this.state.stocklist_id} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} loginUserData = {this.state.loginUserData} />),
            drug_list: (<DrugList selectedScreen={selectedComponent} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} loginUserData = {this.state.loginUserData}/>),
            inactive_list: (<DrugList selectedScreen={selectedComponent} changeScreen={this.changeScreen.bind(this)} responseSuccessErrorMessage={this.responseSuccessErrorMessage.bind(this)} loginUserData = {this.state.loginUserData}/>)
        }
        return (<View>
            {componentLookup[selectedComponent] ? componentLookup[selectedComponent] : null}
        </View>);
    }

    NavigateScreen( pagename, indent = {}) {
        this.setState({
            selectedScreen: pagename,
            SelectedIndent: indent
        })
    }

    changeScreen(selectedScreen, pageType = "", item_id = null, statusDelivery) {
        let states = this.state;
        let letUpdate = true
        // clear ids
        states["statusDelivery"] = statusDelivery;
        states["purchase_id"] = null;
        states["stocklist_id"] = null;
        states["vendor_id"] = null;
        if (selectedScreen === "add_stock") {
            if (item_id) {
                states["purchase_id"] = item_id;
            }
        }
        else if (selectedScreen === "view_stock") {
            if (item_id) {

                states["stocklist_id"] = item_id;
            } else {
                letUpdate = false
            }
        }

        // assign
        states["selectedScreen"] = selectedScreen;
        states["screenType"] = pageType;

        if (selectedScreen && letUpdate) {
            this.setState({
                states
            }, () => {
            });
            this.props.subSelectedScreen(selectedScreen)
        }
    }
    _generateReport = (type = '') => {

        let url = Constants.LIST_STOCK + "?export=" + type+"&search="+ this.state.searchValue;
        

        if (type) {

            this.setState({ resportActions: false }, () => {
                if (type == 'pdf') {
                    this.service.getInstance().documentUploadGet(
                        url,
                        this.PrintReportSuccess,
                        this.PrintReportFailure,
                        "pdf"
                    );
                } else if (type == 'excel') {
                    this.service.getInstance().documentUploadGet(
                        url,
                        this.exportCSVSuccessCallBack,
                        this.exportCSVSuccessCallBack,
                        "excel"
                    );
                }
            })
        }
    };

    PrintReportSuccess = response => {
        if (response) {
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            // enable actions
            this._enablePrintActions()

        } else {
            // enable actions
            this._enablePrintActions()
        }
    };

    PrintReportFailure = error => {
        // enable actions
        this._enablePrintActions()
    };

    exportCSVSuccessCallBack = response => {
        if (response) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            // enable actions
            this._enablePrintActions()
        } else {
            // enable actions
            this._enablePrintActions()
        }
    }

    exportCSVErrorCallBack = error => {
        // enable actions
        this._enablePrintActions()
    }

    _enablePrintActions = () => {
        this.setState({ resportActions: true })
    }

    openVendorDetails(vendor_id) {
        if (vendor_id) {
            this.setState({
                vendor_id: vendor_id,
                selectedScreen: "update_vendor"
            })
        }
    }
    responseSuccessErrorMessage(message, type) {
        this.setState({
            showResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }
    ApproveAlertOTIndent = (message)=>{
        this.responseSuccessErrorMessage(message, true)
        setTimeout(() => {
            this.responseSuccessErrorMessage("", true)
        }, 3000)
    }
}
