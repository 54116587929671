import React, { Component } from "react";
import { View, Text, TextInput, TouchableOpacity, StyleSheet,Image } from "react-native";
import { BillTableTitle, BillTableData } from "../../common/BaseFunctionComponent";
import Pagination from "../../common/Pagination";
import Constants from '../../networks/Constants';
import Service from '../../networks/service';
import { color } from "../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import { Tooltip } from "antd";

export default class StockList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: this.props.searchValue ? this.props.searchValue : "",
            data: {
                total_count: 0,
                no_of_pages: 0,
                result: []
            },
            current_page: 1,
            records_per_page: 10,
        }
        this.service = new Service();
    }

    componentDidMount() {
        this._getStockList()
    }
    _getStockList = () => {
        let { current_page, records_per_page, searchValue } = this.state;
        let url = `${Constants.LIST_STOCK}?page=${current_page}&page_size=${records_per_page}&search=${searchValue}`;
        this.service.getApiCall(
            url,
            this.successCallBack,
            this.errorCallBack
        )
    }

    successCallBack = async (response) => {

        let { data } = this.state;

        if (response.detail && response.detail === "Token is expired") {
            this.props.logout();
        } else if (response.status == "success") {
            data['result'] = response.data ? response.data.result : [];
            data['total_count'] = response.data ? response.data.total_count : 0;
            data['no_of_pages'] = response.data ? response.data.no_of_pages : 0;

            data = JSON.parse(JSON.stringify(data))
            this.setState({
                data
            })
        } else if (response.status == "fail") {
            this.setState({
                responseMessage: response.message
            })
        }
    }

    errorCallBack = (error) => {}

    renderTopHeader() {
        let isDisable = this.props.isDisable ? this.props.isDisable : false;
        return (
            <View style={{ flexDirection: "row", justifyContent: "flex-end", marginHorizontal: "1.25vw", marginBottom: "0.625vw" }}>
                <View style={styles.SearchBoxView}>
                        <TextInput
                            value={this.state.searchValue}
                            onChangeText={(text) => {
                                    this.setState({ searchValue: text })
                            }}
                            style={styles.SearchBoxTxtInput}
                            placeholder="Search Drug..."
                        />
                        <TouchableOpacity
                            style={styles.SearchBtn}
                            onPress={() => {
                                this._getStockList();
                            }}>
                            <Icon name='search' size={'0.97vw'} color={color.white}></Icon>
                        </TouchableOpacity>
                    </View>
                <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                        style={[styles.ButtonStyle, { marginRight: " 0.625vw" }]}
                        onPress={() => this.props.changeScreen("new_drug_entry", "")}
                    >
                        <Text style={styles.ButtonText}>+New Drug</Text>
                    </TouchableOpacity>
                    
                    <TouchableOpacity
                        onPress={() => {
                            !isDisable ? this.props.generateReport('excel') : null
                        }}
                        style={{ width: "2.60vw", height: "2vw", borderRadius: "0.26vw", backgroundColor: isDisable ? color.lightGray : color.themeBackgroud, paddingHorizontal: ".32vw", paddingVertical: "0.4vw" }}>
                        <Image style={{ width: "1.95vw", height: "1.62vw" }}
                            source={require('../../../assets/images/HomeScreenImages/Export.png')} />
                    </TouchableOpacity>

                    <TouchableOpacity
                        onPress={() => {
                            !isDisable ? this.props.generateReport('pdf') : null
                        }}
                        style={{ width: "2.60vw", height: "2vw", borderRadius: ".26vw", backgroundColor: isDisable ? color.lightGray : color.themeBackgroud, paddingHorizontal: ".3vw", paddingVertical: "0.4vw", marginLeft: "1vw" }}>
                        <Image style={{ width: "1.95vw", height: "1.62vw" }}
                            source={require('../../../assets/images/HomeScreenImages/Print.png')} />
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    getStockTable() {

        let { data, current_page, records_per_page } = this.state;
        let prev_record_count = (parseInt(current_page) - 1) * parseInt(records_per_page)

        return (
            <View style={styles.TableContainer}>
                <View style={styles.TableWrapper}>
                    <BillTableTitle label={'S.No'} flexWidth={0.04} />
                    <BillTableTitle label={'Stock Id'} flexWidth={0.06} />
                    <BillTableTitle label={'Brand Name'} flexWidth={0.14} />
                    <BillTableTitle label={'Generic Name'} flexWidth={0.13} />
                    <BillTableTitle label={'Dosage Type'} flexWidth={0.10} />
                    <BillTableTitle label={'Dosage Strength'} flexWidth={0.09} />
                    <BillTableTitle label={'Batch No'} flexWidth={0.1} />
                    <BillTableTitle label={'Quantity'} flexWidth={0.09} />
                    <BillTableTitle label={'Expiry Date'} flexWidth={0.1} />
                    <BillTableTitle label={'MRP/Quantity'} flexWidth={0.1} />
                    <BillTableTitle label={'Box No'} flexWidth={0.05} />
                </View>
                {data.result && data.result.map((item, index) => {

                    let sno = prev_record_count + (index + 1);
                    // MRP
                    let mrp_per_quantity = (parseFloat(item.mrp_per_quantity) || 0).toFixed(2)

                    return (
                        <TouchableOpacity key={index} onPress={() => this.props.changeScreen('view_stock', '', item.id)}>
                            <View style={styles.TableBody}>
                                <BillTableData label={sno} labelType={'label'} flexWidth={0.04} />
                                <BillTableData label={item.id} labelType={''} flexWidth={0.06} />
                                <View style={{ flex: 0.14 }}>
                                            <Tooltip title={item.brand_name && (item.brand_name).length > 15 ? item.brand_name : null}>
                                                <Text style={{ fontSize: "1vw" }}>{item.brand_name && (item.brand_name).length > 15 ? (item.brand_name).slice(0, 13) + ".." : item.brand_name}</Text>
                                            </Tooltip>
                                </View>
                                <View style={{ flex: 0.13 }}>
                                            <Tooltip title={item.generic_name && (item.generic_name).length > 15 ? item.generic_name : null}>
                                                <Text style={{ fontSize: "1vw" }}>{item.generic_name && (item.generic_name).length > 15 ? (item.generic_name).slice(0, 13) + ".." : item.generic_name}</Text>
                                            </Tooltip>
                                </View>
                                <BillTableData label={item.dosage_type} labelType={''} flexWidth={0.10} />
                                <BillTableData label={item.dosage_strength} labelType={''} flexWidth={0.09} />
                                <BillTableData label={item.batch_no} labelType={''} flexWidth={0.1} />
                                <BillTableData label={item.stock_in_quantity} labelType={''} flexWidth={0.1} />
                                <BillTableData label={item.expiry_date} labelType={''} flexWidth={0.1} />
                                <BillTableData label={mrp_per_quantity} labelType={''} flexWidth={0.1} />
                                <BillTableData label={item.physical_box_no} labelType={''} flexWidth={0.05} />
                            </View>
                        </TouchableOpacity>
                    )
                })}
            </View>
        )
    }

    _paginationChangedValues(next_page, records_per_page_change) {

        let { current_page, records_per_page } = this.state

        if (current_page != next_page || records_per_page != records_per_page_change) {

            this.setState({
                records_per_page: records_per_page_change,
                current_page: next_page
            }, () => {
                this._getStockList()
            })
        }
    }

    render() {
        let { data } = this.state;
        return (
            <View style={{ paddingHorizontal: "1.25vw" }}>
                {this.renderTopHeader()}
                {this.getStockTable()}
                {data.total_count && parseInt(data.total_count) > 0 ?
                    <Pagination
                        paginationChangedValues={this._paginationChangedValues.bind(this)}
                        totalClientItems={""}
                        type={Constants.PAGINATION_TYPE_SERVER}
                        totalServerRecordsCount={data.total_count}
                        totalServerPagesCount={data.no_of_pages}
                    /> : null}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    TableContainer: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center"
    },
    TableWrapper: {
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#E2E2E2", padding: ".52vw", borderRadius: ".25vw"
    },
    TableBody: {
        flex: 1, flexDirection: "row", justifyContent: "flex-start", alignContent: "center",
        backgroundColor: "#F9F9F9", padding: ".52vw", borderRadius: ".25vw", marginTop: ".32vw",
        borderBottomWidth: 1, borderColor: "#E2E2E2"
    },
    ButtonStyle: {
        width: '8vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.4vw',
        paddingVertical: '0.10vw',
    },
    ButtonText: {
        fontSize: "1vw",
        color: color.white,
        textAlign: "center",
        padding: "0.4vw"
    },
    printContainer: {
        width: '2.60vw',
        height: '2.27vw',
        borderRadius: '0.26vw',
        backgroundColor: color.themeBackgroud,
        paddingHorizontal: '0.32vw',
        paddingVertical: '0.16vw'
    }, 
    SearchBoxView: {
        flexDirection: "row",
        marginRight: '1.2vw',

    },
    SearchBoxTxtInput: {
        borderWidth: '0.06vw',
        height: '2.28vw',
        width: '11.06vw',
        paddingHorizontal: '0.19vw',
        borderColor: color.lightGray,
        fontSize: '0.91vw',
        borderBottomStartRadius: '0.26vw',
        borderTopLeftRadius: '0.26vw',
    },
    SearchBtn: {
        height: '2.28vw',
        width: '2.28vw',
        backgroundColor: color.themeDark,
        justifyContent: "center",
        alignItems: "center",
        borderTopEndRadius: '0.26vw',
        borderBottomRightRadius: '0.26vw',
    },
});